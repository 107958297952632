import React from 'react'

import PrismRobotViewWithStatus from 'components/PrismRobotViewWithStatus/PrismRobotViewWithStatus'

import Styles from './PlcControl.module.scss'

/**
 * Renders a single robot card with a status dot on the lower right corner.
 *
 * @param robotId - The current robot ID
 * @param active - Whether the current robot is selected or not
 * @param onSelect - Handler for when clicking on the current robot
 */
function RobotCard({ robotId, active, onSelect }: { robotId: string; active: boolean; onSelect: () => any }) {
  return (
    <div onClick={onSelect} className={`${Styles.camItemContainer} ${active ? Styles.camItemActive : ''}`}>
      <PrismRobotViewWithStatus robotId={robotId} className={Styles.robotWithStatus} />
    </div>
  )
}

export default RobotCard
