import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Anomaly Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3678%3A27
 *
 * @param hasHover - Fill the icon with $light_100 when hovering the icon.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismAnomalyToolIcon = ({ isActive, hasHover }: PrismIconProps) => {
  const className = `${Styles.icon} ${hasHover ? Styles.hoverInBlue : ''} ${isActive ? Styles.active : ''}`
  return (
    <svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 13.5V19.5H13.5V13.5H19.5ZM19.5 4.5V10.5H13.5V4.5H19.5ZM10.5 4.5V10.5H4.5V4.5H10.5ZM10.5 13.5V19.5H4.5V13.5H10.5Z"
        fillOpacity="0.04"
        stroke="currentColor"
      />
      <path d="M19.4375 4.53125L13.6406 10.3281" stroke="currentColor" />
    </svg>
  )
}
