import React from 'react'

import { History } from 'history'
import { clamp, isArray, isNil, isNumber, isPlainObject, maxBy, sum, uniq } from 'lodash'
import sortBy from 'lodash/sortBy'
import throttle from 'lodash/throttle'
import uniqBy from 'lodash/uniqBy'
import moment, { isMoment, Moment } from 'moment'
import momentTz from 'moment-timezone'
import qs from 'qs'
import { batch } from 'react-redux'
import { Dispatch } from 'redux'
import { Options, Response } from 'request-dot-js'

import {
  backendErrorCodes,
  EventSubsData,
  FlatInspectionData,
  getAllPages,
  GetterData,
  getterKeys,
  query,
  SendToApiResponse,
  service,
  wsKeys,
} from 'api'
import { getRobotIdsFromEdgeParams } from 'components/OnMountApp'
import { error, loading, success, warning } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import {
  info as infoNotification,
  warning as warningNotification,
} from 'components/PrismNotification/PrismNotification'
import { LabelButtonSeverity } from 'components/PrismResultButton/PrismResultButton'
import { CLOUD_FASTAPI_URL, CLOUD_FASTAPI_WS_URL, IS_QA } from 'env'
import { LabelFormFields } from 'pages/RoutineOverview/LabelingScreen/AddLabelModal'
import { ThresholdByRoutineParentId } from 'pages/RoutineOverview/Train/TrainingReport/TrainingReport'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import typedStore, { TypedStore } from 'rdx/store'
import {
  AnalyzeTab,
  analyzeTabs,
  AreaOfInterestConfiguration,
  AreaOfInterestExpanded,
  BackendThreshold,
  BaseRoutine,
  Box,
  CameraSettings,
  CameraStatus,
  Capabilities,
  Component,
  Confusion,
  ConfusionMatrix,
  CreateUpdateDeleteSubsBody,
  Dataset,
  DateValue,
  DeploymentStatus,
  EventSub,
  EventTargets,
  EventType,
  ExperimentState,
  FallbackImagePicture,
  FlatInspection,
  GARToolThreshold,
  Inspection,
  Item,
  ItemExpanded,
  ItemsByRobotId,
  LabelingProgressObject,
  LocationHistory,
  MaxBounds,
  MetadataPredictionLabel,
  NonTrainableToolLabel,
  Outcome,
  PartialToolLabel,
  PictureAreaOfInterest,
  PinnedCameraByStation,
  PreprocessingOptions,
  PreprocessingOptionsAlt,
  QsFilters,
  RecipeExpanded,
  RecipeParent,
  Robot,
  RootState,
  Routine,
  RoutineLinkedToRobotResponse,
  RoutineSettings,
  RoutineWithAois,
  Site,
  Station,
  StationDetailMode,
  stationDetailModes,
  StationForSite,
  StreamReadMessage,
  SuccessResponseOnlyData,
  Threshold,
  ThresholdConfusion,
  TimeSeriesDatePeriod,
  TimeSeriesResult,
  Tool,
  ToolFlat,
  ToolLabel,
  ToolLabelBody,
  ToolLabelImage,
  ToolLabelLabelingStatus,
  ToolLabelSeverity,
  ToolLabelsMilestoneData,
  ToolParent,
  ToolResult,
  ToolResultBatchLabelChild,
  ToolResultCount,
  ToolResultEmptyOutcome,
  ToolResultEmptyPredictionOutcome,
  ToolResultOutcome,
  Toolset,
  ToolsetMappedArray,
  Toolsets,
  ToolSpecificationName,
  ToolThreshold,
  TrainingMetrics,
  TrainingResultFlat,
  TriggerMode,
  UpdateLiveSettingsCommand,
  User,
  UserDateFormats,
  UserFacingThreshold,
  UserLabelSet,
  UserRole,
  UserTimeFormats,
  VpStatusStreamMessage,
  WithStatus,
} from 'types'
import {
  DAY_PERIOD_DAY_DIFFERENCE,
  getMessages,
  HOUR_PERIOD_DAY_DIFFERENCE,
  THIRTY_MIN_PERIOD_DAY_DIFFERENCE,
  WEEK_PERIOD_DAY_DIFFERENCE,
} from 'utils'

import {
  ANOMALY_DEFECT_TOOL_LABELS,
  DERIVATIVE_LABELS,
  FILTER_KEYS_TO_PROXY,
  GRADED_ANOMALY_TOOL_LABELS,
  LABEL_FALLBACK_IMAGES_TO_USE,
  MINIMUM_LABELS_COUNT_FOR_DEFECT_AND_MATCH_TRAINING,
  QS_FILTER_KEYS,
  QUALITY_EVENTS_ALLOWED_TARGET_TABLES,
  SECONDS_IN_DAY,
  SECONDS_IN_HOUR,
  SECONDS_IN_MINUTE,
  STATUS_PRIORITY,
  TOOLS_WITH_SCORE_INVERTED,
  TOOLS_WITH_SCORE_ZERO_TO_ONE,
  TRAINABLE_TOOL_SPECIFICATION_NAMES,
  TRAINING_STATES,
  UNUSED_LABELS,
} from './constants'
import defaultLabelDescriptions from './defaultLabelDescriptions'
import { getterAddPage } from './getter'
import {
  CORRECT_MATCH_LABEL,
  CRITICAL_ANOMALY_LABEL,
  DISCARD_LABEL,
  GOOD_NORMAL_LABEL,
  MINOR_ANOMALY_LABEL,
  TEST_SET_LABEL,
  UNCERTAIN_LABEL,
  WRONG_MATCH_LABEL,
} from './labels'
import { promptDeepCopy } from './promptDeepCopy/promptDeepCopy'
import renderAndUploadFallbackImagesFromToolResults from './renderAndUploadFallbackImagesFromToolResult/renderAndUploadFallbackImagesFromToolResults'

/**
 * `console.log` wrapper to make sure we log relevant errors caught in QA, while
 * at the same time not logging anything in production. Stringifies messages so
 * that they can be read even if they are nested objects, arrays, etc.
 */
export function log(file: string, description: string, ...messages: any[]) {
  if (!IS_QA) return
  const logs = messages.map(arg => JSON.stringify(arg))
  console.log(`CHROME LOGGING: ${file}, ${description}  -  ${logs}`) // eslint-disable-line
}

/**
  Perform the action to log out the user from our web app, due to the fact that his auth
  session has expired. Show a notification to indicate that to the user.
 */
export function logoutSessionExpired(store: TypedStore = typedStore) {
  service.logout({ retry: { retries: 3, delay: 5000 } })
  store.dispatch(Actions.authUnset('sessionExpired'))
  infoNotification({
    id: 'session-expired-notification',
    title: 'Your session expired',
    description: 'You were logged out because of your security settings. Log in to start a new session.',
    position: 'bottom-left',
    duration: 0,
  })
}

/**
 * Awaitable sleep for `ms`.
 *
 * @param ms - number of ms to wait
 *
 * @returns Promise that resolves after ms has passed
 */
export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export interface ObjectWithCreatedAt {
  created_at: string
}

export interface ObjectWithName {
  name: string
}

/**
 * Sort callback for sorting DB items by oldest first.
 *
 * @param a - First object to compare
 * @param b - Second object to compare
 *
 * @returns Sort comparator integer
 */
export function sortByOldestFirst(a: ObjectWithCreatedAt, b: ObjectWithCreatedAt) {
  if (a.created_at < b.created_at) return -1
  if (a.created_at > b.created_at) return 1
  return 0
}

/**
 * Sort callback for sorting DB items by newest first.
 *
 * @param a - First object to compare
 * @param b - Second object to compare
 *
 * @returns Sort comparator integer
 */
export function sortByNewestFirst(a: ObjectWithCreatedAt, b: ObjectWithCreatedAt) {
  if (a.created_at < b.created_at) return 1
  if (a.created_at > b.created_at) return -1
  return 0
}

/**
 * Sort callback for sorting DB items by timestamp newest first. The user supplies the key for the timestamp value
 *
 * @param a - First object to compare
 * @param b - Second object to compare
 *
 * @returns Sort comparator integer
 */
export function sortByTimestampKeyFirst<T extends object, U extends keyof T>(a: T, b: T, timestampKey: U) {
  if (a[timestampKey] < b[timestampKey]) return 1
  if (a[timestampKey] > b[timestampKey]) return -1
  return 0
}

/**
 * Sort callback for sorting DB items by name.
 *
 * @param a - First object to compare
 * @param b - Second object to compare
 *
 * @returns Sort comparator integer
 */
export function sortByName(a: ObjectWithName, b: ObjectWithName) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
  return 0
}

export const sortByStatus = <T extends {}>(stationA: WithStatus<T>, stationB: WithStatus<T>) => {
  if (!stationB?.status || !stationA?.status) return 0
  return STATUS_PRIORITY[stationB.status] - STATUS_PRIORITY[stationA.status]
}

type WithNameAndStatus = { name: string; status: CameraStatus | undefined }

export const sortByStatusAndName = (a: WithNameAndStatus, b: WithNameAndStatus) => {
  return sortByStatus(a, b) || sortByName(a, b)
}

const DEFAULT_LABEL_VALUES_ORDER = [TEST_SET_LABEL.value, UNCERTAIN_LABEL.value, DISCARD_LABEL.value]
const SEVERITIES_ORDER: ToolLabelSeverity[] = ['minor', 'critical', 'neutral']

/**
 * Sort callback for sorting tool labels by the following rules:
 * - Good severity is always on top
 * - The rest is sorted by label value
 *   - If two labels have the same name, they are sorted by severity.
 * - TestSet, Uncertain and Discard are always at the bottom, in that order
 *
 * @param a - First ToolLabel to compare
 * @param b - Second ToolLabel to compare
 * @param severitiesOrder - Optional severities order to sort by, only use if a custom severities order is required
 *
 * @returns Sort comparator integer
 */
export const sortByValueAndSeverity = (
  toolLabelA: ToolLabel,
  toolLabelB: ToolLabel,
  severitiesOrder: ToolLabelSeverity[] = SEVERITIES_ORDER,
) => {
  if (isNonTrainingLabel(toolLabelA) && !isNonTrainingLabel(toolLabelB)) return 1
  if (isNonTrainingLabel(toolLabelB) && !isNonTrainingLabel(toolLabelA)) return -1

  for (const labelValue of DEFAULT_LABEL_VALUES_ORDER) {
    if (toolLabelA.value === labelValue && toolLabelB.value !== labelValue) return -1
    if (toolLabelA.value !== labelValue && toolLabelB.value === labelValue) return 1
  }

  if (toolLabelA.severity === 'good' && toolLabelB.severity !== 'good') return -1
  if (toolLabelA.severity !== 'good' && toolLabelB.severity === 'good') return 1

  if (toolLabelA.value > toolLabelB.value) return 1
  if (toolLabelA.value < toolLabelB.value) return -1

  for (const severity of severitiesOrder) {
    if (toolLabelA.severity === severity && toolLabelB.severity !== severity) return -1
    if (toolLabelA.severity !== severity && toolLabelB.severity === severity) return 1
  }

  return 0
}

/**
 * Sort callback for sorting Labels by kind.
 * - Custom label will display first
 * - Followed by default labels
 *
 * @param a - First object to compare
 * @param b - Second object to compare
 *
 * @returns Sort comparator integer
 */
export const sortByLabelKind = (a: ToolLabel, b: ToolLabel) => {
  if (a.kind === 'default' && b.kind !== 'default') return 1
  if (a.kind === 'custom' && b.kind !== 'custom') return -1

  if (a.kind === b.kind) {
    if (a.value > b.value) return 1
    if (a.value < b.value) return -1
    return 0
  }
  return 0
}

/**
 * TypeScript-specific improvement to Array.includes, that raises a TS error if
 * value can't possibly be a member of array.
 *
 * @param array - Array of values
 * @param value - Value that might be in array
 *
 * @returns Is value in array?
 */
export function includes<T extends U, U>(array: T[], value: U) {
  return array.includes(value as T)
}

/**
 * Resolves an array of outcomes to a single outcome.
 *
 * @param outcomes - Array of outcomes
 *
 * @returns Outcome
 */
export function evaluateOutcomes(outcomes: (ToolResultOutcome | 'empty')[]): Outcome {
  if (outcomes.length === 0) return 'unknown'
  const noEmptyOutcomes = outcomes.filter(outcome => outcome !== 'empty')
  if (noEmptyOutcomes.some(outcome => outcome === 'fail')) return 'fail'
  if (noEmptyOutcomes.some(outcome => includes(['unknown', 'error', 'needs-data'], outcome))) return 'unknown'
  return 'pass'
}

/**
 * Calculates the horizontal offset x, vertical offset y, width, height of a
 * rectangle so that it is contained and centered in a rectangle with width
 * `cWidth` and height `cHeight`. The contained rectangle keeps its original
 * aspect ratio.
 *
 * @param oWidth - Width of the original rectangle
 * @param oHeight - Height of the original rectangle
 * @param cWidth - Width of the container
 * @param cHeight - Height of the container
 *
 * @returns Object with x, y, width, height of the contained (translated and
 *     resized) rectangle
 */
export function contain(oWidth: number, oHeight: number, cWidth: number, cHeight: number) {
  if ([oHeight, oWidth, cWidth, cHeight].some(v => v <= 0)) return

  const oAspectRatio = oWidth / oHeight
  const cAspectRatio = cWidth / cHeight

  let height, width, x, y
  height = width = x = y = 0

  if (oAspectRatio <= cAspectRatio) {
    height = cHeight
    width = height * oAspectRatio
    x = (cWidth - width) / 2
    y = 0
  } else {
    width = cWidth
    height = width / oAspectRatio
    x = 0
    y = (cHeight - height) / 2
  }

  return {
    width,
    height,
    x,
    y,
  } as Box
}

/**
 * This function constrains the dimension and x,y values to the provided max bounds
 *
 * @param box - box to constrain
 * @param bounds - Max bounds
 */
export const constrainBoxInBounds = <T extends Box>(box: T, bounds?: MaxBounds) => {
  if (!bounds) return box

  const { maxWidth, maxHeight } = bounds

  if (box.width > maxWidth) box.width = maxWidth
  if (box.height > maxHeight) box.height = maxHeight

  if (box.x < 0 || box.x + box.width > maxWidth) box.x = 0
  if (box.y < 0 || box.y + box.height > maxHeight) box.y = 0
  return box
}

/**
 * This function clips a box in bounds.
 *
 * It basically cuts the section of the box that is out of bounds, so the section of the box that is in bounds
 * remains unmodified.
 *
 * A detailed explanation:
 * https://github.com/elementary-ml/cloud-frontend/pull/3286#discussion_r1206018061
 *
 * @param box - Box to be clipped
 * @param bounds - Max bounds
 */
export const clipBoxInBounds = <T extends Box>(box: T, bounds: MaxBounds) => {
  const { maxWidth, maxHeight } = bounds
  const totalWidth = box.x + box.width
  const totalHeight = box.y + box.height

  const clippedBox = { ...box }

  if (box.x < 0) {
    clippedBox.x = 0
    // x will be negative, so we are actually substracting x from width
    clippedBox.width = box.width + box.x
  }
  if (box.y < 0) {
    clippedBox.y = 0
    // y will be negative, so we are actually substracting y from height
    clippedBox.height = box.height + box.y
  }

  if (totalWidth > maxWidth) {
    const deltaWidth = totalWidth - maxWidth
    clippedBox.width = box.width - deltaWidth
  }
  if (totalHeight > maxHeight) {
    const deltaHeight = totalHeight - maxHeight
    clippedBox.height = box.height - deltaHeight
  }

  return clippedBox
}

interface DeepCopyConfig {
  history: History
  routineParentId?: string
  recipe: RecipeExpanded
  onBeforeCreate?: () => void
  onCreate?: (newRecipe: RecipeExpanded, newRoutine?: RoutineWithAois) => Promise<void> | void
  onCancel?: () => void
  store?: TypedStore
}

/**
 * Determine if response status and code indicate that the user is trying to
 * edit a protected routine. If this is the case, show a modal prompting the
 * user to create a "new version" of this routine. If they choose to do this,
 * create a deep copy of the routine and redirect the user to the routine detail
 * screen for the newly created deep copy.
 *
 * @param res - Response object
 * @param deepCopyConfig - See promptDeepCopy
 *
 * @returns Does response indicate that this routine was protected?
 */
export function isRecipeOrRoutineResponseProtected(res: Response, deepCopyConfig?: DeepCopyConfig): boolean {
  const isProtected =
    res.type !== 'exception' && res.status === 400 && res.data?.code === backendErrorCodes.recipeIsProtected

  if (!isProtected) return false
  if (!deepCopyConfig) return isProtected

  promptDeepCopy(deepCopyConfig)
  return true
}

/**
 * Given an array of objects and a selected object id, find the previous or next
 * object in the array that passes filter condition.
 *
 * @param direction - Previous or next object
 * @param objects - Objects
 * @param selectedObjId - Selected object id
 * @param filter - Object must pass filter to be considered adjacent
 * @param fallBackToFirstObject - Should we go back to first obj in array if
 *     current obj can't be found?
 *
 * @returns The next object that passes filter or undefined if none was found
 */
export function getAdjacent<T extends { id: string }>(
  direction: 'prev' | 'next',
  objects: T[],
  selectedObjId: string,
  filter: (obj: T) => boolean = () => true,
  fallBackToFirstObject: boolean = true,
): T | undefined {
  objects = [...objects]
  if (direction === 'prev') objects.reverse()

  const currentIdx = objects.findIndex(obj => obj.id === selectedObjId)
  // TODO: The fallBackToFirstObject parameter should not be used, but AoiLayout has a weird implementation that depends on this behavior (we should fix this)
  if (!fallBackToFirstObject && currentIdx === -1) return undefined
  return objects.slice(currentIdx + 1).filter(filter)[0]
}

/**
 * Returns boolean that determines if user has permission for an action given
 * his role.
 *
 * @param me - The current logged in user
 * @param requiredRole - Role level to check against user's role
 * @param allowHigherRole - Should a higher ranked user be allowed, defaults to true
 *
 * @returns Does he have permission?
 */
export function matchRole(
  me: User | undefined,
  requiredRole: UserRole,
  options?: { allowHigherRole: boolean },
): boolean {
  const userRole = me?.role

  if (!userRole) return false

  if (userRole === requiredRole) return true

  const { allowHigherRole } = options || { allowHigherRole: true }
  if (!allowHigherRole) return false

  // Roles in order of hierarchy
  const allowedRoles: UserRole[] = ['member', 'inspector', 'manager', 'owner']

  const maxAllowedIndex = allowedRoles.findIndex(role => role === userRole)
  if (maxAllowedIndex === -1) return false

  // If role is within the allowed roles, permit access
  return includes(allowedRoles.slice(0, maxAllowedIndex), requiredRole)
}

/**
 * Converts radians to degrees.
 *
 * @param radians - Angle in radians
 *
 * @returns Angle in degrees
 */
export function toDegrees(radians: number) {
  return radians * (180 / Math.PI)
}

/**
 * Converts degrees to radians.
 *
 * @param degrees - Angle in degrees
 *
 * @returns Angle in radians
 */
export function toRadians(degrees: number) {
  return degrees * (Math.PI / 180)
}

export function getDefaultCameraSettings(capabilities: Capabilities) {
  if (capabilities.cam_max_width_pixels === 0 || capabilities.cam_max_height_pixels === 0) return

  const defaultCameraSettings: CameraSettings = {
    routine: {
      camera_trigger_mode: 'manual',
      grayscale: false,
      exposure_ms: 25,
      trigger_delay_ms: 1,
      trigger_input: 'Line1',
      trigger_edge: true,
      trigger_debounce_ms: 1,
      interval_ms: 200,
      image_flip_vertical: false,
      exposure_output_invert: capabilities.lineselector_values_out.length ? true : false,
      exposure_output:
        capabilities.lineselector_values_out.find(line => line === 'Line2') || capabilities.lineselector_values_out[0],
      image_rotation_degrees: 0,
      sensor_aoi: {
        x: 0,
        y: 0,
        width: capabilities.cam_max_width_pixels,
        height: capabilities.cam_max_height_pixels,
      },
      gain: 0,
      gamma: 1,
      camera_properties: {
        cam_model: capabilities.cam_model,
        cam_max_height_pixels: capabilities.cam_max_height_pixels,
        cam_max_width_pixels: capabilities.cam_max_width_pixels,
      },
    },
  }

  return defaultCameraSettings
}

export async function captureFrame(robotId: string) {
  // Read the last frame published to a given stream
  const res = await service.atomSendCommandRaw('transcoder', 'transcode_frame', robotId, {
    command_args: { element: 'basler', stream: 'image' },
  })
  if (res.type !== 'success') return

  return await res.data.blob()
}

/**
 * This function takes any number and returns a string formated like 10k, 25M,
 * 812B, up to billion (10^9).
 *
 * PD: this is very similair to the interview question we run, and I love the
 * fact that we do what's not allowed in the interview here hahaha
 *
 * @param number - The big number to be converted
 *
 * @returns Formatted number
 */
export const renderLargeNumber = (number: number, startAt?: number) => {
  if (number < (startAt || 99999)) return number.toLocaleString()
  if (number < 999999) return `${(number / 1000).toFixed(1)}k`
  if (number < 999999999) return `${(number / 1000000).toFixed(1)}M`
  return `${(number / 1000000000).toFixed(1)}B`
}

export const commaSeparatedNumbers = (number: number) => {
  let toReturn = ''
  const iterableNumber = number.toString().split('')
  iterableNumber.reverse().forEach((num, index) => {
    if (index % 3 !== 0 || index === 0) toReturn = num + toReturn
    else toReturn = num + ',' + toReturn
  })
  return toReturn
}

// TODO: refactor to be a hook that also returns a stopping state. Use this also in StationDetailSidebar
export function stopRobotBatch({
  inspectionId,
  dispatch,
  onComplete,
  batchName,
}: {
  inspectionId: string
  dispatch: Dispatch
  onComplete?: () => any
  batchName?: string
}) {
  const batchLabel = batchName || 'this batch'
  modal.confirm({
    id: 'stop-batch-confirmation',
    danger: true,
    header: 'Are you sure?',
    content: `Are you sure you want to stop ${batchLabel}?`,
    okText: 'Yes, stop',
    onOk: async close => {
      const stopBatchId = 'stop-batch'
      loading({
        id: stopBatchId,
        title: 'Stopping batch...',
        duration: 0,
      })

      // Ensure task is unloaded before ending inspection
      close()

      const inspectionRes = await service.getInspection(inspectionId)

      if (inspectionRes.type !== 'success') return
      const inspection = inspectionRes.data
      const resPromises = inspection.inspection_routines.map(inspectionRoutine => {
        if (!inspectionRoutine.robot_id) return undefined
        return service.atomSendCommand('vision-processing', 'stop_inspection', inspectionRoutine.robot_id, {
          command_args: {},
        })
      })

      const responses = await Promise.all(resPromises)

      // Don't await this, just try to stop this inspection, and retry if there's a connection error; this is a rare case of retrying a request with side effects, and is a workaround for not being able to atomically unload and end an inspection
      if (responses.every(res => res?.type === 'success') && inspectionId) {
        service.stopInspection(inspectionId, { retry: { retries: 4, delay: 2000 } })
        success({
          id: stopBatchId,
          title: 'Batch stopped',
        })
      } else {
        warning({
          id: stopBatchId,
          title: "Couldn't stop the inspection",
        })
      }

      // Refetch automatically all stations so that we don't have to wait until next poll to update the state after stopping a batch
      await query(
        getterKeys.stations('all-with-robots'),
        () => service.getStations({ has_robots: true, order_by: 'name' }),
        {
          dispatch,
        },
      )

      if (onComplete) onComplete()
    },
  })
}

/**
 * This function concatenates a number at the end of a string which corresponds
 * to the last element with the same name.
 *
 * @param name - Name we'd like to return if there are no collisions
 * @param names - Names we don't want to collide with
 *
 * @returns - Name that doesn't collide with names
 */
export function getUniqueName(name: string, names: string[]) {
  let suffix = 2
  const baseName = name
    .split(' ')
    .filter(part => isNaN(Number(part)))
    .join(' ')

  while (true) {
    if (!names.includes(name)) return name
    name = `${baseName} ${suffix}`
    suffix += 1
  }
}

/**
 * Duplicates a tool, and does some wacky stuff that will soon be deprecated.
 *
 * @param routineId - Routine the tool belongs to
 * @param toolId - Tool to duplicate
 * @param history - history object to enable navigation from this function.
 * @param dispatch - dispatch caller to persist data to redux.
 */
export async function duplicateTool({
  routineId,
  routineParentId,
  toolId,
  toolParentId,
  recipe,
  history,
  dispatch,
}: {
  routineId: string
  routineParentId: string
  toolId: string
  toolParentId: string
  recipe: RecipeExpanded
  history: History
  dispatch: Dispatch
}): Promise<Tool | undefined> {
  const handleDuplicate = async (toolId: string, routineId: string, newRecipeCreated?: boolean) => {
    const res = await service.deepCopyTool(toolId, { routine_id: routineId })

    if (
      isRecipeOrRoutineResponseProtected(res, {
        routineParentId,
        recipe,
        history,
        onCreate: async (_, routine) => {
          if (!routine) return
          const toolToDuplicate = routine.aois.flatMap(aoi => aoi.tools).find(tool => tool.parent_id === toolParentId)
          if (!toolToDuplicate) return
          await handleDuplicate(toolToDuplicate.id, routine.id, true)
        },
      })
    )
      return

    if (!newRecipeCreated) {
      query(getterKeys.recipeParent(recipe.parent_id), () => service.getRecipeParent(recipe.parent_id), { dispatch })
    }

    if (res.type === 'success') {
      const routineRes = await query(getterKeys.routine(routineId), () => service.getRoutine(routineId), {
        dispatch,
      })

      query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), { dispatch })

      success({ title: 'Tool duplicated' })
      if (routineRes?.type === 'success') {
        const { tools } = getAoisAndToolsFromRoutine(routineRes.data)
        const newTool = tools.find(tool => tool.id === res.data.id)
        return newTool
      }
    } else error({ title: 'There was an error duplicating the tool' })
  }

  return await handleDuplicate(toolId, routineId)
}

/**
 * Deletes the AOIs that link a tool to a routine. After Shared Tools, we never want to actually delete tools.
 *
 * @param routineId id of the routine that holds the tool
 * @param aoiId id of the aoi that points to the tool to be deleted
 * @param toolId id of the tool. Only used to find the aoi to remove if user has to create a new routine version.
 * @param history history to navigate after deletion
 * @param dispatch dispatch to mutate redux state with change
 * @param onDelete callback for the client to be able to execute code on completion.
 */
export async function deleteTool({
  routineId,
  recipe,
  toolId,
  history,
  dispatch,
  close,
  onDelete,
}: {
  routineId: string
  recipe: RecipeExpanded
  toolId: string
  history: History
  dispatch: Dispatch
  close: () => any
  onDelete?: () => any
}) {
  const handleDelete = async (routineId: string, toolId: string) => {
    // We don't ever want to actually delete tools, only the aois that link them to a routine.
    // This way the tool can always be brought back on a new routine in the future via the Add Tool Modal
    const body = {
      aois: [],
      routine_id: routineId,
      tool_id: toolId,
    }
    const res = await service.batchCreateUpdateDeleteAois(body)

    const routineParentId = recipe.recipe_routines.find(({ routine }) => routine.id === routineId)?.routine.parent.id

    if (
      isRecipeOrRoutineResponseProtected(res, {
        routineParentId,
        recipe,
        history,
        onCancel: () => close(),
        onCreate: async (_, routine) => {
          if (!routine) return
          const toolInNewRoutine = routine.aois.find(aoi => aoi.tools.find(tool => tool.id === toolId))
          if (toolInNewRoutine) await handleDelete(routine.id, toolId)
        },
      })
    )
      return

    if (res.type === 'error') {
      if (res.data.code === backendErrorCodes.aoiHasToolResults)
        return error({ title: 'This tool already produced results, which means it cannot be deleted.' })
      return error({ title: 'There was an error deleting the tool, please try again.' })
    }
    if (res.type === 'exception') {
      return error({ title: 'There was an error deleting the tool, please try again.' })
    }

    await query(getterKeys.routine(routineId), () => service.getRoutine(routineId), { dispatch })
    success({ title: 'Tool deleted' })

    close()
    onDelete?.()
  }

  return handleDelete(routineId, toolId)
}

/**
 * To remain consistent around the app.
 *
 * @param tool - The tool.
 *
 * @returns - Name for tool
 */
export function renderToolName(tool?: Tool | null) {
  if (!tool) return ''

  return tool.parent_name || tool.specification_display_name || tool.specification_name
}

/**
 * To remain consistent around the app.
 *
 * @param tool - The tool.
 * @param aoi - The aoi.
 *
 * @returns - Name for tool and AOI
 */
export function renderToolNameWithAoi(tool?: Tool, aoi?: AreaOfInterestExpanded | PictureAreaOfInterest) {
  return `${renderToolName(tool)}${tool && aoi ? ' - ' : ''}${aoi?.parent?.name}`
}

/**
 * This function converts an array of hsv values into a single value in the inference args field. This is necessary because the form library used does a shallow equal, which means it won't go into the array elements to figure out if it's dirty. That's why we decided to use this format instead.
 * @param inferenceArgs The inference args as received from Django
 */
export const convertPreprocessingOptionsToAlt = (
  inferenceArgs?: PreprocessingOptions,
): PreprocessingOptionsAlt | undefined => {
  if (!inferenceArgs) return
  const { hsv_min, hsv_max, pixel_info, ...rest } = inferenceArgs

  return {
    ...rest,
    hsv_min_h: hsv_min[0] || 0,
    hsv_min_s: hsv_min[1] || 0,
    hsv_min_v: hsv_min[2] || 0,
    hsv_max_h: hsv_max[0] || 0,
    hsv_max_s: hsv_max[1] || 0,
    hsv_max_v: hsv_max[2] || 0,
    pixel_count_min: pixel_info?.pixel_count[0],
    pixel_count_max: pixel_info?.pixel_count[1],
  }
}

export const convertAltToPreprocessingOptions = (inferenceArgs: PreprocessingOptionsAlt): PreprocessingOptions => {
  const {
    hsv_min_h,
    hsv_min_s,
    hsv_min_v,
    hsv_max_h,
    hsv_max_s,
    hsv_max_v,
    pixel_count_min,
    pixel_count_max,
    ...rest
  } = inferenceArgs

  return {
    ...rest,
    hsv_min: [hsv_min_h, hsv_min_s, hsv_min_v],
    hsv_max: [hsv_max_h, hsv_max_s, hsv_max_v],
    pixel_info:
      pixel_count_min !== undefined && pixel_count_max !== undefined
        ? { pixel_count: [pixel_count_min, pixel_count_max] }
        : undefined,
  }
}

/**
 * Labeling Utils
 */

const LABELING_GOAL_MULTIPLIER = 2
const GOOD_LABELS_GOAL_MULTIPLIER = 1.2
const CRITICAL_MINOR_NEUTRAL_BASE_LABEL_GOAL = 25
const NON_GOOD_SEVERITIES = ['critical', 'minor', 'neutral']
const OPTIMAL_LABEL_THRESHOLD = 100
const OFF_BALANCE_LABEL_THRESHOLD = 80

export const defaultEmptyLabelingProgressReturn: LabelingProgressObject = {
  labeledCount: 0,
  labelsStatusById: {},
  goalsForThisLevelById: {},
}

// Gives us a summary of the current status of the tool, regarding current labeling progress and previous train count.
export function getMilestoneProgress(
  toolSpecificationName: ToolSpecificationName | undefined,
  countByLabel: { [labelNameOrId: string]: number },
  labels?: ToolLabel[],
): LabelingProgressObject {
  if (!labels) return defaultEmptyLabelingProgressReturn

  const countByLabelCopy = { ...countByLabel }

  const filteredLabels = labels.filter(toolLabel => !isNonTrainingLabel(toolLabel))

  const labelCounts = Object.values(countByLabelCopy)

  const currentLabelingLevel = getCurrentLabelingLevel({
    toolSpecificationName,
    toolLabels: filteredLabels,
    countByLabel,
  })

  const labeledCount = labelCounts.reduce((val, aggr) => val + aggr, 0)

  const nonGoodLabelsGoalsForThisLevelById = getNonGoodLabelsGoalsForLevelById(filteredLabels, currentLabelingLevel)

  const goodLabels = filteredLabels.filter(toolLabel => toolLabel.severity === 'good')

  const goodLabelsGoalsForThisLevelById = getGoodLabelsGoalForThisLevelById(
    nonGoodLabelsGoalsForThisLevelById,
    goodLabels,
  )

  const goalsForThisLevelById = { ...nonGoodLabelsGoalsForThisLevelById, ...goodLabelsGoalsForThisLevelById }

  const labelsStatusById = getToolLabelStatus(filteredLabels, countByLabel, goalsForThisLevelById)

  return {
    labeledCount,
    labelsStatusById,
    goalsForThisLevelById,
  }
}

const getLabelsCounts = (toolLabels: ToolLabel[], countsByLabel: Record<string, number>) =>
  toolLabels.reduce<number[]>((allCounts, toolLabel) => {
    const count = countsByLabel[toolLabel.id]

    if (isNil(count)) return allCounts

    return [...allCounts, count]
  }, [])

const getLabelCountsByLabelId = (toolResultCounts: ToolResultCount['results']) => {
  const countsByLabelIdToReturn: Record<string, number> = {}

  toolResultCounts.forEach(countObj => {
    const labelId = countObj.active_user_label_set__tool_labels__id

    countsByLabelIdToReturn[labelId] ??= 0
    countsByLabelIdToReturn[labelId] += countObj.count
  })

  return countsByLabelIdToReturn
}

const getNeededForBalance = (maxLabeled: number, count: number) => {
  const labeledPercentage = calculatePercentage(count, maxLabeled)
  return Math.ceil(((OFF_BALANCE_LABEL_THRESHOLD - labeledPercentage) * count) / labeledPercentage)
}

const getToolLabelStatus = (
  toolLabels: ToolLabel[],
  countsByLabel: Record<string, number>,
  goalsByLabelId: Record<string, number>,
) => {
  const criticalMinorNeutralLabels = toolLabels.filter(toolLabel => NON_GOOD_SEVERITIES.includes(toolLabel.severity))
  const criticalMinorNeutralCounts = getLabelsCounts(criticalMinorNeutralLabels, countsByLabel)

  const maxCriticalMinorNeutralLabeledCount = Math.max(...criticalMinorNeutralCounts)

  const totalCriticalMinorNeutralCount = sum(criticalMinorNeutralCounts)

  return toolLabels.reduce<Record<string, { status: ToolLabelLabelingStatus; neededForBalance: number }>>(
    (all, toolLabel) => {
      const goal = goalsByLabelId[toolLabel.id]
      const count = countsByLabel[toolLabel.id]
      if (isNil(goal) || isNil(count) || count < 1)
        return { ...all, [toolLabel.id]: { status: 'below-minimum', neededForBalance: 1 } }

      if (
        NON_GOOD_SEVERITIES.includes(toolLabel.severity) &&
        calculatePercentage(count, maxCriticalMinorNeutralLabeledCount) < OFF_BALANCE_LABEL_THRESHOLD
      ) {
        return {
          ...all,
          [toolLabel.id]: {
            status: 'off-balance',
            neededForBalance: getNeededForBalance(maxCriticalMinorNeutralLabeledCount, count),
          },
        }
      }

      if (
        toolLabel.severity === 'good' &&
        totalCriticalMinorNeutralCount > 0 &&
        calculatePercentage(count, totalCriticalMinorNeutralCount) < OFF_BALANCE_LABEL_THRESHOLD
      ) {
        return {
          ...all,
          [toolLabel.id]: {
            status: 'off-balance',
            neededForBalance: getNeededForBalance(totalCriticalMinorNeutralCount, count),
          },
        }
      }

      // The optimal rule should be after the off-balanes rules, as those take precedence.
      if (calculatePercentage(count, goal) >= OPTIMAL_LABEL_THRESHOLD) {
        return { ...all, [toolLabel.id]: { status: 'optimal', neededForBalance: 0 } }
      }

      const optimalTrainingCount = Math.ceil((goal * OPTIMAL_LABEL_THRESHOLD) / 100)

      return { ...all, [toolLabel.id]: { status: 'in-progress', neededForBalance: optimalTrainingCount - count } }
    },
    {},
  )
}

const getNonGoodLabelGoal = (labelingLevel: number) =>
  CRITICAL_MINOR_NEUTRAL_BASE_LABEL_GOAL * LABELING_GOAL_MULTIPLIER ** (labelingLevel - 1)

const getGoodLabelGoal = (nonGoodLabelsGoalsSum: number) => nonGoodLabelsGoalsSum * GOOD_LABELS_GOAL_MULTIPLIER

/**
 * Returns an object containing the goal for the current labeling level for each label id
 *
 * @param toolLabels - current tool labels
 * @param labelingForLevel - current labeling level
 */
const getNonGoodLabelsGoalsForLevelById = (toolLabels: ToolLabel[], labelingForLevel: number) => {
  const goalsForLevelById: { [labelId: string]: number } = {}
  toolLabels.forEach(toolLabel => {
    // Good labels goals are calculated based on the other labels goals
    if (toolLabel.severity === 'good') return
    goalsForLevelById[toolLabel.id] = getNonGoodLabelGoal(labelingForLevel)
  })

  return goalsForLevelById
}

const getGoodLabelsGoalForThisLevelById = (nonGoodLabelsGoals: Record<string, number>, goodLabels: ToolLabel[]) => {
  const allGoals = Object.values(nonGoodLabelsGoals)

  const goalsSum = sum(allGoals)

  const goal = getGoodLabelGoal(goalsSum)

  return goodLabels.reduce<Record<string, number>>((all, toolLabel) => {
    return { ...all, [toolLabel.id]: goal }
  }, {})
}

const includeLabelForLevelCalculation = (toolLabel: ToolLabel, count: number) => {
  const isAnomalyLabel = isLabelAnomaly(toolLabel)

  if (isAnomalyLabel) return count > 0

  return true
}

/**
 * Gets the current labeling level for the provided Tool Labels.
 *
 * @param labels - tool labels to get the data from
 * @param countByLabel - object containing the labeled count by label id
 */
const getCurrentLabelingLevel = ({
  toolSpecificationName,
  toolLabels,
  countByLabel,
}: {
  toolSpecificationName: ToolSpecificationName | undefined
  toolLabels: ToolLabel[]
  countByLabel: { [labelName: string]: number }
}) => {
  const milestoneData: ToolLabelsMilestoneData = {
    labelingLevels: [],
    countBySeverity: { good: 0, neutral: 0, minor: 0, critical: 0 },
  }

  const toolHasRCALabels = areSomeRCALabels(toolLabels)

  // If tool has RCA labels, we ignore some labels for level calculation
  const filteredLabels = toolHasRCALabels
    ? toolLabels.filter(toolLabel => includeLabelForLevelCalculation(toolLabel, countByLabel[toolLabel.id] || 0))
    : toolLabels

  filteredLabels.forEach(toolLabel => {
    const count = countByLabel[toolLabel.id] || 0
    // Good labels labeling level gets calculated after
    if (toolLabel.severity !== 'good') {
      let nonGoodLabelingLevel = 1
      while (count > getNonGoodLabelGoal(nonGoodLabelingLevel)) {
        nonGoodLabelingLevel++
      }
      milestoneData.labelingLevels.push(nonGoodLabelingLevel)
    }

    milestoneData.countBySeverity[toolLabel.severity]! += count
  })

  // Consider all non good labels (including anomaly labels) to determine good labels goals
  const nonGoodLabelsCount = toolLabels.filter(toolLabel => toolLabel.severity !== 'good').length

  // Match tools don't have `good` labels
  if (toolSpecificationName !== 'match-classifier') {
    let currentGoodLabelingLevel: number = 1
    while (true) {
      const nonGoodLabelsGoalsSum = nonGoodLabelsCount * getNonGoodLabelGoal(currentGoodLabelingLevel)
      if ((milestoneData.countBySeverity.good || 0) <= getGoodLabelGoal(nonGoodLabelsGoalsSum)) break
      currentGoodLabelingLevel++
    }
    milestoneData.labelingLevels.push(currentGoodLabelingLevel)
  }

  if (milestoneData.labelingLevels.length) return Math.min(...milestoneData.labelingLevels)
  return 0
}

// Gets you the count of actual trainable labels for a tool
export function getToolTotalCount(count: { [labelName: string]: number }) {
  // As the dataset now includes counts for label ids, we want to remove pass and fail to avoid count dupplication.
  const { fail, pass, unknown, ...restCountByLabel } = count
  count = restCountByLabel
  return Object.values(count).reduce((prevVal, curVal) => prevVal + curVal, 0)
}

/**
 * Adds an existing label to a tool.
 *
 * @param toolLabels - The tool labels to add to the tool. If the value or id is found within the current tool labels, there's no need to add it
 * @param currentToolLabels - All current labels belonging to a tool
 * @param toolParentId - Tool Parent id to add the label to
 * @returns 'skipped', 'success' or 'error'. 'skipped' means the label already exists in the tool, or not enough data was supplied, so no need to run the process.
 *
 */
export const addExistingLabelsToTool = async ({
  toolLabels,
  currentToolLabels,
  toolParentId,
}: {
  toolLabels: ToolLabel[]
  currentToolLabels?: ToolLabel[]
  toolParentId: string
}): Promise<'skipped' | 'success' | 'error'> => {
  if (!currentToolLabels || !toolLabels) return 'skipped'

  const unarchivePromises = []
  for (const archivedLabel of toolLabels.filter(lbl => lbl.is_deleted)) {
    unarchivePromises.push(service.patchToolLabel(archivedLabel.id, { is_deleted: false }))
  }

  await Promise.all(unarchivePromises)

  if (toolLabels.every(lbl => currentToolLabels.find(toolLabel => lbl.id === toolLabel.id))) return 'skipped'

  const toolLabelsToAdd = toolLabels.filter(lbl => !currentToolLabels.find(toolLabel => lbl.id === toolLabel.id))

  const toolLabelIds = toolLabelsToAdd.map(lbl => lbl.id)

  const currentToolLabelIds = currentToolLabels.filter(tl => tl.kind === 'custom').map(tl => tl.id)
  const toolParentRes = await service.patchToolParent(toolParentId, {
    labels: [...currentToolLabelIds, ...toolLabelIds],
  })

  if (toolParentRes.type !== 'success') return 'error'

  return 'success'
}

/**
 * Add label to a tool, right now this is only relevant to match tools. This will either append an existing label to a match tool or create a new one for it.
 *
 * @param labelBody - The body of the label to be created
 * @param labels - The list of custom labels currently attached to the tool parent
 * @param tool - The tool for which to append the label to
 * @param dispatch - Dispatch
 *
 * @returns - Display name for robot
 */
export async function addLabelToTool({
  labelBody,
  labels,
  dispatch,
  toolParentId,
}: {
  labelBody: ToolLabelBody
  labels: ToolLabel[]
  dispatch: Dispatch
  toolParentId: string
}): Promise<{ status: 'success' | 'failure'; addedLabel?: ToolLabel }> {
  const currentLabelIds = labels.map(lbl => lbl.id)
  const toolLabelRes = await service.createToolLabel(labelBody)
  let labelToReturn: ToolLabel | undefined = undefined

  // New label created, append to tool parent
  if (toolLabelRes.type === 'success') {
    const toolParentRes = await service.patchToolParent(toolParentId, {
      labels: [...currentLabelIds, toolLabelRes.data.id],
    })

    if (toolParentRes.type !== 'success') return { status: 'failure' }
    labelToReturn = toolLabelRes.data
  }

  if (toolLabelRes.type === 'error') {
    if (toolLabelRes.data.code !== backendErrorCodes.labelAlreadyExists) return { status: 'failure' }

    // Handle appending existing label to tool
    const existingLabelsRes = await service.getToolLabels({ value: labelBody.value, severity__in: labelBody.severity })
    if (existingLabelsRes.type === 'success') {
      const toolLabel = existingLabelsRes.data.results.find(
        label => label.value.toLowerCase() === labelBody.value?.toLowerCase(),
      )
      if (!toolLabel || toolLabel.kind === 'default') return { status: 'failure' }

      const res = await addExistingLabelsToTool({
        toolLabels: [toolLabel],
        currentToolLabels: labels,
        toolParentId,
      })

      if (res === 'error') return { status: 'failure' }
      labelToReturn = toolLabel
    }
  }

  await query(getterKeys.toolLabels(toolParentId), () => service.getToolLabels({ tool_parent_id: toolParentId }), {
    dispatch,
  })

  return { status: 'success', addedLabel: labelToReturn }
}

export function getLabelsTrainCountsFromDataset(dataset?: Dataset, derivativeLabelIds?: string[]) {
  const toReturn: { [labelName: string]: number } = {}

  let labelsUsedInDataset = 0
  let labelsAvailableForDataset = 0

  if (!dataset || !derivativeLabelIds) return { labelsUsedInDataset, labelsAvailableForDataset }

  dataset.result_counts.label_counts?.forEach(count => {
    // We just want to consider `tool_label` for counts, here we don't care about
    // counts by outcome
    if (count.type !== 'tool_label' || !count.id || derivativeLabelIds.includes(count.id)) return
    const key = count.id || count.name
    if (key) {
      const availableCount = isNil(count.available_count) ? count.count : count.available_count
      toReturn[key] = isNil(count.available_count) ? count.count : count.available_count

      labelsUsedInDataset += count.name !== 'test_set' ? count.count : 0
      labelsAvailableForDataset += availableCount
    }
  })

  return { labelsUsedInDataset, labelsAvailableForDataset }
}

/**
 * End labeling utils
 */

/**
 * Get display name for robot.
 *
 * @param robot - The robot
 *
 * @returns - Display name for robot
 */
export function getRobotDisplayName(
  robot: Robot | undefined,
  options?: {
    showModelAndSerial: boolean
  },
) {
  if (!robot) return ''
  const state = typedStore.getState()
  const discoveries = state.robotDiscoveriesById[robot.id]

  const robotName = robot.name || robot.serial_number || ''
  let robotDisplayName: string

  if (discoveries?.basler?.capabilities) {
    const capabilities = discoveries.basler.capabilities
    const camPixels = Math.floor((capabilities.cam_max_width_pixels * capabilities.cam_max_height_pixels) / 1000000)

    robotDisplayName = `${robotName} - ${camPixels} MP ${capabilities.is_color ? 'Color' : 'Mono'}`
  } else if (discoveries && 'basler' in discoveries) {
    // Finished fetching discoveries and we don't have any info for that robot
    robotDisplayName = robotName
  } else {
    // Waiting for FastAPI to return the discoveries for all cameras
    robotDisplayName = `${robotName} - camera_MP color_mode`
  }

  if (options?.showModelAndSerial) {
    const parts = [robotDisplayName, robot.serial_number, discoveries?.basler?.capabilities?.cam_model]
    return parts.filter(p => !!p).join(' - ')
  }

  return robotDisplayName
}

/**
 * Same as `text-transform: capitalize;` in CSS.
 *
 * @param s - the string
 * @param firstWordOnly - Option to only capitalize first word
 *
 * @returns - Title-cased string
 */
export function titleCase(s: string, firstWordOnly?: boolean) {
  return s
    .split(' ')
    .map((word, idx) => {
      if (firstWordOnly && idx > 0) return word
      return word.charAt(0).toUpperCase() + word.substring(1, word.length)
    })
    .join(' ')
}

/**
 * Formats a date with an optional period
 *
 * @param ts - the timestamp in unix seconds
 * @param timeFormat - user time format
 * @param timezone - user timezone
 * @param period - optional, equals to "hour" or "10m"
 *
 * @returns - Human readable date
 */
export function formatGraphTooltipDate({
  ts,
  timeFormat,
  timeZone,
  period,
}: {
  ts: number
  timeFormat: 'H:mm' | 'h:mma'
  timeZone: string
  period?: TimeSeriesDatePeriod
}) {
  let formatString = 'MMM D, YYYY'
  if (period === 'hour' || period === '30m') formatString = `MMM D ${timeFormat}`
  const currentTimezone = timeZone || momentTz.tz.guess()
  const momentString = momentTz.unix(ts).tz(currentTimezone).format(formatString)
  return `${momentString} (${getTimezoneAbbr(currentTimezone)})`
}

type TimePeriod = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year'

const timePeriodAbbr: { [key in TimePeriod]: string } = {
  second: 's',
  minute: 'm',
  hour: 'h',
  day: 'd',
  week: 'w',
  month: 'mo',
  year: 'y',
}
/**
 * Calculates a text to be shown according to a comparison of dates. The following breakpoints will be used:
 *
 * min - [0, 60) mins ago
 * hours - [1, 24) hours ago
 * days - [1, 7) days ago
 * weeks - between 7 days and a month ago
 * months - more than a month ago
 *
 * @param oldDate - Either a string or a moment object to compare with new date
 * @param oldDate - Another string or moment object, or default to current date
 */
export function getTimeAgoFromDate(
  oldDate: Moment | string,
  newDate?: Moment | string,
): { val: number; period: TimePeriod; text: string } {
  if (!newDate) newDate = moment()
  if (typeof newDate === 'string') newDate = moment(newDate)
  if (typeof oldDate === 'string') oldDate = moment(oldDate)
  const differenceMs = newDate.diff(oldDate, 'ms')

  const differenceMins = differenceMs / (1000 * 60)
  if (differenceMins < 1) return { val: differenceMs / 1000, period: 'second', text: 'just now' }

  const getText = (val: number, period: TimePeriod) => `${Math.floor(val)}${timePeriodAbbr[period]} ago`

  const differenceHrs = differenceMins / 60
  if (differenceHrs < 1) return { val: differenceMins, period: 'minute', text: getText(differenceMins, 'minute') }

  const differenceDays = differenceHrs / 24
  if (differenceDays < 1) return { val: differenceHrs, period: 'hour', text: getText(differenceHrs, 'hour') }

  const differenceWeeks = differenceDays / 7
  if (differenceWeeks < 1) return { val: differenceDays, period: 'day', text: getText(differenceDays, 'day') }

  const differenceMonths = newDate.diff(oldDate, 'months')
  if (differenceMonths < 1) return { val: differenceWeeks, period: 'week', text: getText(differenceWeeks, 'week') }

  const differenceYears = newDate.diff(oldDate, 'years')
  if (differenceYears < 1) return { val: differenceMonths, period: 'month', text: getText(differenceMonths, 'month') }

  return { val: differenceYears, period: 'year', text: getText(differenceYears, 'year') }
}

/**
 * Get regex from string, or null if string isn't valid regex.
 *
 * @param regexString - Regex string
 *
 * @returns - Regex or null
 */
export function getRegex(regexString: string) {
  if (!regexString?.trim()) return null
  try {
    return new RegExp(regexString, 'g')
  } catch (error) {
    return null
  }
}

/**
 * Prepare request body for create/update/delete all AOIs linked to tool.
 *
 * @param regexString - Regex string
 *
 * @returns Request body
 */
export function prepareBatchCreateUpdateDeleteBody(aois: AreaOfInterestConfiguration[], tool?: ToolFlat) {
  const aoiParentId = aois[0]?.parent?.id

  const metadataThreshold =
    aoiParentId && tool ? getThresholdFromToolAoiThresholdMetadata(tool, aoiParentId) : undefined

  const getInferenceUserArgs = () => {
    if (!tool) return {}

    const inference_user_args: { [key: string]: any } = { ...tool.inference_user_args }

    if (!isNil(metadataThreshold)) {
      if (isThresholdFromGARTool(metadataThreshold)) {
        inference_user_args.lower_threshold = metadataThreshold.lowerThreshold
        inference_user_args.upper_threshold = metadataThreshold.upperThreshold
      } else {
        inference_user_args.threshold = metadataThreshold.threshold
      }
    }

    return { inference_user_args }
  }

  const newAois = aois.map(aoi => {
    // Tools with a shape type but no shape data are rejected by the backend. Force the null shape to make it
    // an unrotated rectangle if we hit this edge case
    if (!!aoi.shape?.type && !aoi.shape.data) aoi.shape = null
    return {
      ...aoi,
      parent_name: aoi.parentName,
      ...getInferenceUserArgs(),
    }
  })

  return {
    aois: newAois,
    routine_id: aois[0]?.routine_id,
    tool_id: tool?.id || aois[0]?.toolIds[0] || '',
  }
}

/**
 * Gets `aois` and `tools` from routine.
 *
 * @param routine Routine to get aois and tools from
 *
 * @returns \{aois, tools}
 */
export const getAoisAndToolsFromRoutine = (routine: RoutineWithAois | undefined) => {
  if (!routine) return { aois: [], tools: [] }
  const { aois } = routine
  const tools = aois.flatMap(aoi => aoi.tools)
  // This is needed for the cases where we have multiple aois on a single tool
  const uniqueTools = uniqBy(tools, tool => tool.id)
  return { aois, tools: uniqueTools }
}

/**
 * Gets `aois` from routine and tool.
 *
 * @param routine Routine to get aois from
 * @param toolId Tool which aois belong to
 *
 * @returns AOIs
 */
export const getToolAoisFromRoutine = (routine: RoutineWithAois, toolId: string): AreaOfInterestConfiguration[] => {
  const routineAois = getAoisAndToolsFromRoutine(routine).aois.filter(aoi =>
    aoi.tools?.find(tool => tool.id === toolId),
  )

  return routineAois.map(aoi => ({
    ...aoi,
    parentName: aoi.parent?.name || '',
    toolIds: aoi.tools[0] ? [aoi.tools[0]?.id] : [],
  }))
}

/** Get the regex text from a set regex
 *
 * @param regexString - Regex string
 * @param toolName - Dependingon the tool name, copy has a slight variation
 *
 * @returns - Copy used depending on the regex
 */
export function getRegexCopy(regexString: string, toolName: 'detect-barcode' | 'ocr') {
  if (regexString === '.*' && toolName === 'detect-barcode')
    return 'The result will Pass if any barcode is found. It will fail if no barcode is found.'
  if (regexString !== '.*' && toolName === 'detect-barcode')
    return 'The result will only Pass if a barcode matching your regex is found.'
  if (regexString === '.*' && toolName === 'ocr')
    return 'The result will Pass if any text is found. It will fail if no text is found.'
  if (regexString !== '.*' && toolName === 'ocr')
    return 'The result will only Pass if text matching your regex is found.'
}

/**
 * Fetch robot toolsets and throw them into Redux.
 *
 * @param robotId - Robot id
 * @param dispatch - Dispatch function
 */
export function fetchRobotToolsets(robotId: string, dispatch: Dispatch, retry?: Options['retry']) {
  return query(
    getterKeys.robotToolsets(robotId),
    () =>
      service.atomSendCommandExtractData<Toolsets>(
        'asset-management',
        'status',
        robotId,
        {
          command_args: { all: true },
        },
        { retry },
      ),
    { dispatch },
  )
}

export const sortAndFilterToolsets = (toolsets: Toolsets): ToolsetMappedArray => {
  const loadedToolsets = Object.keys(toolsets)
    .map(routineId => ({ routineId, toolset: toolsets[routineId] }))
    .filter(({ toolset }) => toolset?.recipe_status.state === 'LOADED')
    .map(({ toolset, routineId }) => ({
      id: routineId,
      deployed_at: toolset?.recipe_status.metadata?.deployed_at || 0,
    }))

  // Get most recently deployed routines first, which makes it unlikely, e.g., that we're fetching archived routines first
  return sortBy(loadedToolsets, toolset => toolset.deployed_at).reverse()
}

/**
 * Returns a string with a certain format for rendering accuracy given two parameters
 *
 * @param imagesUsed - A given number of images used
 * @param correctLabels - A given number of correct labels
 * @returns - A string with the accuracy given those parameters
 */
export const renderAccuracy = (
  { imagesUsed, correctLabels }: { imagesUsed: number; correctLabels: number },
  trainedStatus?: 'failed' | 'in_progress' | 'successful' | 'never' | 'canceled',
) => {
  if (imagesUsed === 0 || trainedStatus !== 'successful') return '--'
  return `${correctLabels} of ${imagesUsed} (${Math.round(100 * (correctLabels / imagesUsed))}%)`
}

export const createCompressedJpegFromPngFile = (file: File) => {
  const url = window.URL.createObjectURL(file)
  const img = new Image()
  img.src = url

  return new Promise<File>(resolve => {
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0, img.width, img.height)
      const dataUrl = canvas.toDataURL('image/jpeg')
      resolve(dataURLtoFile(dataUrl, file.name))
    }
  })
}

/*
 * Converts Data URL to File Object
 *
 * https://www.programmersought.com/article/9361741366/
 */
export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',')
  const mime = arr[0]!.match(/:(.*?);/)?.[1]
  const bstr = atob(arr[1]!)

  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export function isToolUntrained(tool: Tool | undefined) {
  if (tool && !TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(tool.specification_name)) return false
  return tool?.state !== 'successful'
}

/**
 * Get HTTP URL for robot's FastAPI server.
 *
 * @param edge - edge branch of state tree
 * @param robotId - robot id
 *
 * @returns HTTP URL for robot's FastAPI server
 */
export function fastApiUrl(edge: RootState['edge'], robotId: string) {
  const edgeUrl = Object.keys(edge.paramsByEdgeUrl).find(url => {
    const params = edge.paramsByEdgeUrl[url]
    return params?.enabled && params.robot_id === robotId
  })
  return edgeUrl || CLOUD_FASTAPI_URL
}

/**
 * Get WS URL for robot's FastAPI server.
 *
 * @param edge - edge branch of state tree
 * @param robotId - robot id
 *
 * @returns WS URL for robot's FastAPI server
 */
export function fastApiWsUrl(edge: RootState['edge'], robotId: string) {
  const params = Object.values(edge.paramsByEdgeUrl).find(obj => {
    return obj.enabled && obj.robot_id === robotId
  })
  return params?.wsUrl || CLOUD_FASTAPI_WS_URL
}

export function getStateUpdateMessagesByRobotId(state: RootState, robotIds: string[]) {
  const dict: { [robotId: string]: VpStatusStreamMessage[] | undefined } = {}
  robotIds.forEach(robotId => {
    dict[robotId] = getMessages(state.events, wsKeys.vpStatus(robotId))
  })
  return dict
}

/**
 *  Pass in a path in the `/path/:mode` format and get the mode
 * @param path - String in `/path/:mode` format
 * @returns `:mode` string
 */
export function getStationDetailModeFromPath(path: string) {
  return path.split('/')?.[2]
}

/**
 *  Pass in a path in the `/path/:mode/:stationId` format and get the station Id
 * @param path - String in `/path/:mode/:stationId` format
 * @returns `:stationId` string
 */
export function getStationDetailStationIdFromPath(path: string) {
  return path.split('/')?.[3]
}

/**
 * Gets the last station detail path that includes `robotId`
 * @param stationId - station id
 * @param locationHistory - location history
 * @returns last station detail that includes `robotId`
 */
const getLastStationDetailPathByStationId = (locationHistory: LocationHistory, stationId: string) => {
  const lastStationDetailPath = searchLocationHistory(locationHistory.history, historyEntry => {
    const isStationDetailPath = historyEntry.pathname.includes(paths.stationDetail(''))
    if (!isStationDetailPath) return false
    const pathStationId = getStationDetailStationIdFromPath(historyEntry.pathname)
    return stationId === pathStationId
  })

  return lastStationDetailPath
}

/**
 * Gets last Station Detail Mode for a given robotId
 * @param locationHistory - location history
 * @param robotId - robot id
 */
export function getLastStationDetailMode(
  locationHistory: LocationHistory,
  stationId: string | undefined,
): StationDetailMode {
  const defaultMode = 'overview'
  if (!stationId) return defaultMode
  const lastStationDetailPath = getLastStationDetailPathByStationId(locationHistory, stationId)

  if (!lastStationDetailPath) return defaultMode

  const lastMode = getStationDetailModeFromPath(lastStationDetailPath.pathname) as StationDetailMode
  return stationDetailModes.includes(lastMode) ? lastMode : defaultMode
}

export function getLastAnalyzeTab(locationHistory: LocationHistory): AnalyzeTab {
  const lastAnalyzePath = searchLocationHistory(locationHistory.history, historyEntry => {
    return historyEntry.pathname.startsWith(paths.analyze({ onlyPrefix: true }))
  })

  if (lastAnalyzePath) {
    const [, , tab] = lastAnalyzePath.pathname.split('/')
    const typedTab = tab as AnalyzeTab
    if (analyzeTabs.includes(typedTab)) return typedTab
  }

  return 'overview'
}

/**
 * Wrapper around `find` method, used to set a limit of how many items should we iterate when searching for an element
 * @param locationHistory - location history
 * @param cb - Function to execute for each element in locationHistory. Returns a boolean to indicate if a matching element has been found
 * @param maxSearchItems - Maximum number of items we want to search
 * @returns - Matching element or `undefined`
 */

export function searchLocationHistory<T>(
  locationHistory: T[],
  cb: (value: T, index: number, obj: T[]) => boolean,
  maxSearchItems: number = 1000000,
): T | undefined {
  return locationHistory.slice(0, maxSearchItems).find(cb)
}

/**
 * Gets the last selected AOI id from the navigation history
 * @param locationHistory - location history
 * @returns - last selected AOI id
 */
export function getLastSelectedAoiId(locationHistory: LocationHistory): string | undefined {
  const lastSelectedAoiId = searchLocationHistory(
    locationHistory.history,
    historyEntry => historyEntry.search.includes('aoiId') && !historyEntry.search.includes('historicInspectionId'),
  )

  if (lastSelectedAoiId) {
    const urlParams = qs.parse(lastSelectedAoiId.search, { ignoreQueryPrefix: true })
    if (urlParams && typeof urlParams.aoiId === 'string') return urlParams.aoiId
  }

  return undefined
}

const throttledPromptDeepCopy = throttle(promptDeepCopy, 1000, { trailing: false })

/**
 * This is a wrapper around an onChange call that allows us to "protect" an input.
 * This wrapper should be used on every field that is uneditable when a routine is protected.
 * This wrapper should NOT be used on fields that the user can change even if the routine is protected (user_args).
 *
 * @param onChange - The same function you would be passing into the onChange
 * @param config.routine - Routine to check for is_protected
 * @param config.history - History object to navigate if user creates a new version
 */
export function protectedOnChange<T = void>(
  onChange: (args: T) => void | Promise<any>,
  {
    routine,
    recipe,
    history,
    ...rest
  }: { routine: RoutineWithAois; recipe: RecipeExpanded; history: History } & Omit<
    DeepCopyConfig,
    'routineId' | 'recipeParentId'
  >,
) {
  return async function (args: T) {
    if (routine.is_protected || recipe.is_protected)
      return throttledPromptDeepCopy({ ...rest, routineParentId: routine.id, recipe, history })
    await onChange(args)
  }
}

/**
 * This function is used to fetch the correct set of results according to the inspection's duration. If the inspection
 * is still running, we just return the complete set.
 * The business rules used for this filtering are the following:
 * - RTS keeps data in the '10m' bucket for a single inspection for as long as 7d
 * - RTS keeps data in the '60m' bucket for a single inspection for as long as 30d
 * - RTS keeps data in the '1440m' bucket for a single inspection for one year, therefore, any inspections running
 *    for more than a week will have to fetch data from this bucket.
 *
 * @param inspection - The currently selected inspection
 * @param itemsData - The RTS result data fetched
 * @param aoiData - The AOI RTS results
 * @param isHistoricBatch - Whether we're currently looking at a historical batch, or a live batch
 * @returns - An array of two elements:  the items filtered by agg_type, and the aois filtered by agg_type
 */
export const getFilteredHistoricSeriesData = (
  inspection: Inspection | undefined,
  itemsData: TimeSeriesResult[] | undefined,
  aoiData: TimeSeriesResult[] | undefined,
  isHistoricBatch: boolean,
) => {
  if (!isHistoricBatch) return [itemsData, aoiData]
  const timeDifference = moment(inspection?.ended_at).diff(moment(inspection?.started_at))

  // Inspection lasted less than a day
  if (timeDifference <= 1000 * 60 * 60 * 24) {
    return [
      itemsData?.filter(item => item.labels.agg_type === '10m'),
      aoiData?.filter(item => item.labels.agg_type === '10m'),
    ]
  } else if (timeDifference < 1000 * 60 * 60 * 24 * 7) {
    // Inspection lasted less than a week
    return [
      itemsData?.filter(item => item.labels.agg_type === '60m'),
      aoiData?.filter(item => item.labels.agg_type === '60m'),
    ]
  } else {
    return [
      itemsData?.filter(item => item.labels.agg_type === '1440m'),
      (aoiData = aoiData?.filter(item => item.labels.agg_type === '1440m')),
    ]
  }
}

/** Appends the keys of the provided newData object as query string variables
 *
 * @param history - History object
 * @param newData - Object whose keys are going to appended to the query string, if key is undefined, it will be deleted.
 * @param state - State object to be passed to history.replace
 */
export const appendDataToQueryString = (
  history: History,
  newData: { [key: string]: any },
  state: { [key: string]: any } = {},
) => {
  const updatedParams = {
    ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
    ...newData,
  }
  history.replace({
    pathname: history.location.pathname,
    search: qs.stringify(updatedParams),
    state: state,
  })

  return updatedParams
}

/**
 * Make sure string written to local storage is valid JSON.
 *
 * @param json - String (or null) read from local storage
 *
 * @returns Does string parse to valid, truthy JSON?
 */
export function validateLocalStorage(json: string | null) {
  if (json === null) return true
  let parsed
  try {
    parsed = JSON.parse(json)
  } catch (e) {
    return false
  }
  return !!parsed
}

/**
 * Returns the abbreviation for the given timezone.
 *
 * @param timezone - moment timezone name
 *
 * @returns string - timezone abbreviation
 */
export function getTimezoneAbbr(timezone: string) {
  return momentTz().tz(timezone).format('z')
}

/**
 * Returns a moment time format for the given user preference.
 *
 * @param userTimeFormat - user time format preference
 *
 * @returns string - moment time format
 */
export const getTimeFormat = (userTimeFormat?: UserTimeFormats) => {
  if (userTimeFormat === '12hr') return 'h:mma'
  return 'H:mm'
}

/**
 * Returns a moment short year date format for the given user preference.
 *
 * @param userDateFormat - user time format preference
 *
 * @returns string - moment date format
 */
export const getShortYearDateFormat = (userDateFormat?: UserDateFormats) => {
  if (userDateFormat === 'd/m/y') return 'DD/MM/YY'
  return 'MM/DD/YY'
}

/**
 *
 * @param history - History object to fetch path and search and append detail data
 * @param itemId - Item ID to redirect to
 * @param toolResultId - ToolResult ID to redirect to
 * @param pictureId - Item Picture ID to redirect to
 */
export const appendItemOrToolResultIdPictureIdOrLastSelectedToQs = (
  history: History,
  {
    itemId,
    toolResultId,
    pictureId,
    lastSelectedId,
  }: { itemId?: string; toolResultId?: string; pictureId?: string; lastSelectedId?: string },
) => {
  const {
    location: { pathname, search },
  } = history
  const params = qs.parse(search, { ignoreQueryPrefix: true })

  // Clear any left over item or toolResult params
  params.detail_item_id = undefined
  params.detail_tool_result_id = undefined
  params.detail_picture_id = undefined
  params.lastSelectedId = undefined

  if (itemId) params.detail_item_id = itemId
  if (toolResultId) params.detail_tool_result_id = toolResultId
  if (pictureId) params.detail_picture_id = pictureId
  if (lastSelectedId) params.lastSelectedId = lastSelectedId

  const newSearch = qs.stringify(params, { addQueryPrefix: true })
  history.replace(pathname + newSearch)
}

export function sortPicturesByRobot<T extends { robot_id: string | null; taken_at: string }>(pictures: T[]): T[] {
  // We must make a copy as .sort mutates the array and this may not be the desired behaviour
  return [...pictures].sort((picA, picB) => {
    if (!picA?.robot_id || !picB?.robot_id) return 0
    if (picA.robot_id === picB.robot_id) {
      // We must have a secondary sort by the picture's taken_at timestamp, since we allow for multiple images per camera to belong to the same item.
      if (picA.taken_at > picB.taken_at) return 1
      else return -1
    }
    if (picA.robot_id > picB.robot_id) return 1
    else return -1
  })
}
export const getImageFromItem = (
  item: Item | ItemExpanded,
  { preferThumbnail, forceThumbnail }: { preferThumbnail?: boolean; forceThumbnail?: boolean } = {},
) => {
  const picturesToUse = 'pictures' in item ? item.pictures : item.fallback_images?.pictures
  if (!picturesToUse) return

  const orderedPictures = sortPicturesByRobot(picturesToUse)

  return getImageFromPicture(orderedPictures[0], { preferThumbnail, forceThumbnail })
}

export const getImageThumbnailFromRoutine = (routine?: Routine | BaseRoutine) => {
  const image =
    routine?.fallback_images?.image_thumbnail ||
    routine?.fallback_images?.image ||
    routine?.image_thumbnail ||
    routine?.image

  return image
}

export const getImageFromRoutine = (routine?: Routine) => {
  const image = routine?.image || routine?.fallback_images?.image
  return image
}

export const getImageFromPicture = (
  picture?: Partial<FallbackImagePicture>,
  { preferThumbnail, forceThumbnail }: { preferThumbnail?: boolean; forceThumbnail?: boolean } = {},
) => {
  if (forceThumbnail) {
    return picture?.image_thumbnail
  }
  if (preferThumbnail) {
    return picture?.image_thumbnail || picture?.image
  }
  return picture?.image || picture?.image_thumbnail
}

/**
 * Queue the callback for asynchronous execution after the current call stack completes
 * @param callback - Function to run
 */
export function executeNextTick(callback: () => any) {
  setTimeout(callback)
}

type TypeOfOption = 'object' | 'array' | 'boolean' | 'number' | 'string' | 'undefined' | 'null'

/**
 * The frontend can't trust the types we assume `prediction_metadata` to have, since this field doesn't go
 * through JSON schema validation on the DB, it can theoritically have any shape and values.
 * This function ensures that if a value isn't in its expected form, the frontend will handle it as undefined.
 * @param predictionMetadataField - The field to parse. E.g: `toolResult.prediction_metadata.codes`
 * @param isAn - The supposed type the field should be
 */
export function parsePredictionMetadata<T>(predictionMetadataField: T | undefined, isAn: TypeOfOption): T | undefined {
  if (!predictionMetadataField) return

  const notifyQA = () => {
    if (!IS_QA) return undefined

    warningNotification({
      id: `prediction_metadata_notification_${isAn}`,
      title: 'Illegal Prediction Metadata',
      description: `The frontend tried to parse prediction_metadata field "${predictionMetadataField}" into a ${isAn}. But the field is in an unsupported value`,
      position: 'bottom-right',
    })
  }

  if (isAn === 'object' && isPlainObject(predictionMetadataField)) return predictionMetadataField

  if (isAn === 'array' && isArray(predictionMetadataField)) return predictionMetadataField

  if (isAn === 'boolean' && typeof predictionMetadataField === 'boolean') return predictionMetadataField

  if (isAn === 'number' && typeof predictionMetadataField === 'number') return predictionMetadataField

  if (isAn === 'string' && typeof predictionMetadataField === 'string') return predictionMetadataField

  if (isAn === 'undefined' && predictionMetadataField === undefined) return predictionMetadataField

  if (isAn === 'null' && predictionMetadataField === null) return predictionMetadataField

  return notifyQA()
}

/**
 * Returns 'pass' or 'fail' based on severity or tool inference user args
 *
 * @param toolLabel - Labels to calculate outcome from
 * @param toolResult - ToolResult being labeled
 *
 * @returns pass | fail
 */
export const getToolLabelsOutcome = (labels: ToolLabel[], toolResult: ToolResultEmptyOutcome): Outcome => {
  if (labels.every(lbl => isNonTrainingLabel(lbl))) return 'unknown'
  if (labels.every(lbl => lbl.severity === 'good')) return 'pass'
  if (labels.some(lbl => lbl.severity === 'critical')) return 'fail'
  if (labels.some(lbl => lbl.severity === 'minor') && toolResult.tool?.specification_name === 'graded-anomaly') {
    if (toolResult.inference_user_args.amber_is_pass) return 'pass'
    else return 'fail'
  }

  if (toolResult.tool?.specification_name === 'match-classifier') {
    const expectedLabels = toolResult.inference_user_args.expected_classes as string[] | undefined
    if (!expectedLabels?.length) return 'unknown'

    const matchLabels = filterOutDerivativeLabels(labels).filter(label => !isLabelTestSet(label))
    // Check if all the labels, excluding the standard ones, are in the expected classes
    return matchLabels.every(lbl => expectedLabels.includes(lbl.id)) ? 'pass' : 'fail'
  }

  // Since the user is labeling a picture, it's safe to default to pass
  return 'pass'
}

/**
 * This is a util that solves the issue with floating-point arithmetic, where sometimes simple js operations result in long
 * undesirable decimals.
 * https://floating-point-gui.de/
 * @param value the value to round
 * @param precision number of decimals to use for rounding
 * @returns Rounded number
 */
export const preciseDecimal = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision)
  return Math.round(value * multiplier) / multiplier
}

type SessionLengthUnit = 'minute' | 'hour' | 'day'

/**
 * This function takes seconds passed in and determined whether to display
 * minutes, hours, or days
 * @param seconds
 * @returns `{unit, time}`
 */
export function getSessionLengthUnitsAndTime(seconds: number | undefined) {
  // If user is being created, fall back to default of 4 hrs
  if (!seconds) return { unit: 'hour', time: 4 } as const
  if (seconds % SECONDS_IN_DAY === 0) return { unit: 'day', time: seconds / SECONDS_IN_DAY } as const
  if (seconds % SECONDS_IN_HOUR === 0) return { unit: 'hour', time: seconds / SECONDS_IN_HOUR } as const
  return { unit: 'minute', time: seconds / SECONDS_IN_MINUTE } as const
}

export function getSessionLengthDisplayText(time: number, units: SessionLengthUnit) {
  if (time !== 1) return `${time} ${units}s`
  return `${time} ${units}`
}

/** For use with Inputs, takes a value and transforms it to a percentage
 * @param value - Numerical input
 */
export const percentageFormatter = (value: string | number | undefined, decimals: number = 0) => {
  return `${(parseFloat(value?.toString() || '0') * 100).toFixed(decimals)}%`
}

/** For use with Inputs, takes a value and transforms it from percentage to number
 * @param value - Percentage formated string
 */
export const percentageParser = (value: string | undefined) => {
  return parseFloat(value?.replace('%', '') || '0') / 100
}

export const xAxisFormatter = (
  ts: number,
  {
    startTime,
    endTime,
    timeFormat,
    timeZone,
  }: { startTime: number | undefined; endTime: number | undefined; timeFormat: string; timeZone: string },
) => {
  if (startTime && endTime) {
    const timeFrame = endTime - startTime
    // less than one day
    if (timeFrame <= 1000 * 60 * 60 * 24) return momentTz.unix(ts).tz(timeZone).format(timeFormat)
    // less than one month
    if (timeFrame <= 1000 * 60 * 60 * 24 * 30) return moment.unix(ts).format('MMM DD')

    return moment.unix(ts).format('MMM YYYY')
  }

  return moment.unix(ts).format('MMM DD')
}

/**
 * This function updates vp settings for a given array of robot IDs. This is necessary to change tool settings mid inspection.
 *
 * @param robotIdsToUpdate Array of robot IDs to update
 * @param command command name and arguments to be updated.
 * @returns whether the update was succesful or not
 */
export const updateLiveSettings = async (robotIdsToUpdate: string[], command: UpdateLiveSettingsCommand) => {
  const promises = []

  for (const robotId of robotIdsToUpdate) {
    const { commandName, commandArgs } = command
    promises.push(
      service.atomSendCommand('vision-processing', commandName, robotId, {
        command_args: commandArgs,
      }),
    )
  }

  const results = await Promise.all(promises)

  if (results.some(res => res.type !== 'success')) return false
  return true
}

/**
 * This function calculates a percentage given an amount and a total.
 *
 * @param amount The numerator of the percentage to calculate. In other words the "part" of the "whole".
 * Should always be less than or equal to the total
 * @param total The denominator of the percentage to calculate. In other words the "whole"
 * @returns A number between 0 - 100 corresponding to a percentage
 */
export const calculatePercentage = (amount: number, total: number | undefined = 1) => {
  if (!amount || !total) return 0
  return (amount / total) * 100
}

/**
 * This function returns a string representing the hotkey for a given Label.
 *
 * @param labelOptionsLength - The length of the labeling options array
 * @param labelIdx - The index of the current ToolLabel
 * @param specialOption - A string representing a special option for labeling.
 *
 * @returns A string representing the provided label hotkey
 */
export const getToolLabelHotkey = ({ labelIdx }: { labelIdx?: number }) => {
  if (labelIdx === undefined) return

  if (labelIdx < 0 || labelIdx > 9) return
  if (labelIdx === 9) return '0'
  return `${1 + labelIdx}`
}

/**
 * This function is a handler for the keypress event in the labeling screens.
 * It invokes the handleToolResultsLabel callback with the corresponding label for the key pressed.
 * @param key - The key from the keypressed event
 * @param handleToolResultsLabel - The callback to be executed when a matching key is pressed
 * @param toolLabels - The labeling options for the current tool
 */
export type handleToolResultsLabelType = (
  toolResultLabel: { toolLabel: ToolLabel } | { nonTrainableToolLabel: NonTrainableToolLabel },
) => Promise<void>

export const handleLabelingKeydown = ({
  key,
  handleToolResultsLabel,
  toolLabels,
}: {
  key: string
  handleToolResultsLabel: handleToolResultsLabelType
  toolLabels?: (ToolLabel | NonTrainableToolLabel)[]
}) => {
  toolLabels?.forEach((toolLabel, labelIdx) => {
    const hotkey = getToolLabelHotkey({ labelIdx })
    if (key === hotkey) {
      if ('value' in toolLabel) {
        handleToolResultsLabel({ toolLabel })
      } else {
        handleToolResultsLabel({ nonTrainableToolLabel: toolLabel })
      }
    }
  })
}

/**
 * Evaluates if images need to be uploaded, and sends command to save them
 *
 * @param toolLabel - Tool label to save images to
 * @param updatedToolResults - Updated tool results
 * @param tool - Tool to use
 * @param dispatch - redux dispatch function
 */
const saveFallbackImagesIfNeeded = ({
  toolLabel,
  updatedToolResults,
  toolParentId,
  dispatch,
}: {
  toolLabel: ToolLabel
  updatedToolResults: ToolResultEmptyOutcome[]
  dispatch: Dispatch
  toolParentId: string
}) => {
  const newLabelWasApplied = updatedToolResults.every(tr =>
    tr.active_user_label_set?.tool_labels.includes(toolLabel.id),
  )

  if (
    newLabelWasApplied &&
    !toolLabel.user_images.length &&
    toolLabel.fallback_images.length < LABEL_FALLBACK_IMAGES_TO_USE
  ) {
    const fallbackCount = toolLabel.fallback_images.length

    const missingFallbackImages = clamp(LABEL_FALLBACK_IMAGES_TO_USE - fallbackCount, 0, LABEL_FALLBACK_IMAGES_TO_USE)

    const labeledToolResults = updatedToolResults.filter(tr => tr.active_user_label_set?.tool_labels[0])

    const cropsToUpload = labeledToolResults.slice(0, missingFallbackImages)

    renderAndUploadFallbackImagesFromToolResults({ toolLabel, toolResults: cropsToUpload, toolParentId, dispatch })
  }
}

const areAllLabelsGoalsMetWithLabeledToolResults = ({
  goalsByLabelId,
  countsBeforeLabeling,
  countsAfterLabeling,
  currentToolLabels,
  toolSpecificationName,
}: {
  goalsByLabelId: Record<string, number>
  countsBeforeLabeling: Record<string, number>
  countsAfterLabeling: Record<string, number>
  currentToolLabels: ToolLabel[]
  toolSpecificationName: ToolSpecificationName
}) => {
  const labelsWithGoals = currentToolLabels.filter(toolLabel => !isNonTrainingLabel(toolLabel))

  // If match tool label count is below minimum, we can consider goals as "not met".
  if (
    toolSpecificationName === 'match-classifier' &&
    labelsWithGoals.length < MINIMUM_LABELS_COUNT_FOR_DEFECT_AND_MATCH_TRAINING
  ) {
    return false
  }
  const toolHasRCALabels = areSomeRCALabels(labelsWithGoals)

  const labelGoalsMet = labelsWithGoals.reduce<Record<string, { before: boolean; after: boolean }>>(
    (all, toolLabel) => {
      const goal = goalsByLabelId[toolLabel.id]
      const countBeforeLabeling = countsBeforeLabeling[toolLabel.id] || 0
      const countAfterLabeling = countsAfterLabeling[toolLabel.id] || 0

      // We don't want to show the notification if tool has RCA labels and anomaly counts are exactly 0
      if (toolHasRCALabels && isLabelAnomaly(toolLabel) && countAfterLabeling === 0) {
        // Special Case: if previous count was > 0, then we want to show the notification as now it is ready to train
        if (countBeforeLabeling > 0) {
          return { ...all, [toolLabel.id]: { before: false, after: true } }
        }

        return all
      }

      // If we don't have goal data, we consider it as "not met"
      if (isNil(goal)) return { ...all, [toolLabel.id]: { before: false, after: false } }

      const goalMetBeforeLabeling = countBeforeLabeling >= goal
      const goalMetAfterLabeling = countAfterLabeling >= goal

      return { ...all, [toolLabel.id]: { before: goalMetBeforeLabeling, after: goalMetAfterLabeling } }
    },
    {},
  )

  const goalsData = Object.values(labelGoalsMet)

  const someGoalsNotMetBefore = goalsData.some(data => !data.before)
  const allGoalsMetAfter = goalsData.every(data => data.after)

  return someGoalsNotMetBefore && allGoalsMetAfter
}

/**
 * This function handles the labeling logic for the provided ToolResults. It is in charge of knowing, based on the provided label and the current state of the provided ToolResults,
 * when to apply a new label, remove the current label or when to delete and undelete the Tool Result.
 *
 * It also handles the logic to update the labeling metrics if needed.
 *
 * @param toolLabels - The selected tool labels to apply or remove, based on the tool results current state.
 * @param toolResults - The tool results that need to be updated.
 * @param deleteToolResults - Wheter we should delete the tool results or not.
 * @param unknown - Whether we are labeling the tool results as unknown
 * @param metrics - The current labeling metrics.
 * @param setMetrics - The callback to be executed when the metrics need to be updated.
 * @param tool - The current Tool.
 * @param toolParentId - The current tool parent id
 * @param nonTrainableToolLabel - A representation of a ToolLabel used for non-trainable tools.
 * @param dispatch - Redux dispatch
 */
export const updateToolResultBatchLabels = async ({
  toolLabel,
  toolResults,
  toolParentId,
  nonTrainableToolLabel,
  dispatch,
  defaultLabels,
  goalsByLabelId,
  currentToolLabels,
  onAllLabelGoalsMet,
  countsByLabelId,
  toolSpecificationName,
}: {
  toolLabel?: ToolLabel
  toolResults: ToolResultEmptyOutcome[]
  toolParentId: string
  nonTrainableToolLabel?: NonTrainableToolLabel
  dispatch: Dispatch
  defaultLabels: ToolLabel[]
  goalsByLabelId?: Record<string, number>
  currentToolLabels?: ToolLabel[]
  onAllLabelGoalsMet?: () => void
  countsByLabelId?: Record<string, number>
  toolSpecificationName: ToolSpecificationName
}): Promise<undefined | { [toolResultId: string]: ToolResult }> => {
  if (!currentToolLabels) return

  const allResultsToUpdate: ToolResultEmptyOutcome[] = []
  let newBatchUserLabelSets: UserLabelSet[] | undefined = undefined

  if (toolLabel || nonTrainableToolLabel) {
    let batchBodyToolResults
    let toolResultsToUpdate
    if (toolLabel && !nonTrainableToolLabel) {
      ;({ batchBodyToolResults, toolResultsToUpdate } = getToolResultsBatchBody(
        toolResults,
        toolLabel,
        defaultLabels,
        currentToolLabels,
      ))
    }
    if (nonTrainableToolLabel && !toolLabel) {
      ;({ batchBodyToolResults, toolResultsToUpdate } = getNonTrainableToolResultsBatchBody(
        toolResults,
        nonTrainableToolLabel,
      ))
    }

    if (!batchBodyToolResults || !toolResultsToUpdate) return

    const body = { tool_results: batchBodyToolResults }

    const res = await service.toolResultBatchLabel(body)

    if (res.type !== 'success') {
      error({ title: "Couldn't label results, try again" })
      return
    }

    allResultsToUpdate.push(...toolResultsToUpdate)
    newBatchUserLabelSets = res.data.user_label_sets

    const toolResultsCountsRes = await query(
      getterKeys.toolResultCounts(toolParentId, 'training-labels'),
      () => service.countLabeledToolResults(toolParentId, { is_test_set: false }),
      {
        dispatch,
      },
    )

    if (toolResultsCountsRes?.type === 'success') {
      const updatedCountsByLabelId = getLabelCountsByLabelId(toolResultsCountsRes.data.results)

      if (countsByLabelId && updatedCountsByLabelId && goalsByLabelId) {
        const allLabelsGoalsMet = areAllLabelsGoalsMetWithLabeledToolResults({
          goalsByLabelId,
          countsBeforeLabeling: countsByLabelId,
          countsAfterLabeling: updatedCountsByLabelId,
          currentToolLabels,
          toolSpecificationName,
        })

        if (allLabelsGoalsMet) onAllLabelGoalsMet?.()
      }

      query(
        getterKeys.toolResultCounts(toolParentId, 'test-set'),
        () => service.countLabeledToolResults(toolParentId, { is_test_set: true }),
        {
          dispatch,
        },
      )
    }

    if (allResultsToUpdate.length <= 0) return

    // Batch label endpoint returns an array of user label sets created, so we need to match them with its corresponding toolResult.
    const activeUserLabelSetsByToolResultIdDict = newBatchUserLabelSets?.reduce((map, userLabelSet) => {
      map[userLabelSet.tool_result_id] = userLabelSet
      return map
    }, {} as { [toolResultId: string]: UserLabelSet })

    const updatedToolResultsDict = allResultsToUpdate.reduce((map, toolResult) => {
      const updatedToolResult = {
        ...(toolResult as ToolResult),
      }
      const activeUSerLabelSetForTool = activeUserLabelSetsByToolResultIdDict?.[toolResult.id]
      if (activeUSerLabelSetForTool) {
        updatedToolResult.active_user_label_set = activeUSerLabelSetForTool
        updatedToolResult.calculated_outcome = evaluateOutcomes([updatedToolResult.active_user_label_set.outcome])
      }

      map[toolResult.id] = updatedToolResult

      return map
    }, {} as { [toolResultId: string]: ToolResult })

    if (toolLabel) {
      if (toolLabel.kind === 'custom') {
        saveFallbackImagesIfNeeded({
          toolLabel,
          updatedToolResults: Object.values(updatedToolResultsDict),
          toolParentId,
          dispatch,
        })
      }
    }

    return updatedToolResultsDict
  }
}
const getLabelIdsToAssign = ({
  toolResult,
  selectedLabel,
  defaultLabels,
  removeSelectedLabel,
}: {
  toolResult: ToolResult
  selectedLabel: ToolLabel
  defaultLabels: ToolLabel[]
  removeSelectedLabel: boolean
}): string[] => {
  const selectedLabelIsTestSet = isLabelTestSet(selectedLabel)
  const selectedLabelIsUncertainOrDiscard = isLabelUncertainOrDiscard(selectedLabel)
  const currentLabels = toolResult.active_user_label_set?.tool_labels || []

  const testSetLabel = findSingleToolLabelFromPartialData(defaultLabels, TEST_SET_LABEL)

  if (removeSelectedLabel) {
    if (selectedLabelIsTestSet) return currentLabels.filter(labelId => labelId !== selectedLabel.id)

    if (!testSetLabel) return []
    // If ToolResult is labeled as TestSet and we are removing another ToolLabel,
    // we need to keep the TestSet label
    if (currentLabels.includes(testSetLabel.id)) {
      return [testSetLabel.id]
    }
    return []
  }

  const someCurrentLabelIsUncertainOrDiscard = currentLabels.some(toolLabelId => {
    const toolLabel = defaultLabels.find(toolLabel => toolLabelId === toolLabel.id)
    if (!toolLabel) return false

    return isLabelUncertainOrDiscard(toolLabel)
  })

  if (selectedLabelIsTestSet && !someCurrentLabelIsUncertainOrDiscard) {
    return [...currentLabels, selectedLabel.id]
  }

  const labelsToApply = [selectedLabel.id]
  // We need to keep test set label if it is already present, except if selected label is Discard or Uncertain.
  if (testSetLabel && currentLabels.includes(testSetLabel.id) && !selectedLabelIsUncertainOrDiscard) {
    labelsToApply.push(testSetLabel.id)
  }

  if (toolResult.tool?.specification_name !== 'match-classifier') return labelsToApply

  const addDerivativeLabel = !isNonTrainingLabel(selectedLabel)

  if (!addDerivativeLabel) return labelsToApply

  const expectedLabels = toolResult.inference_user_args.expected_classes as string[] | undefined
  if (!expectedLabels?.length) return labelsToApply

  if (expectedLabels.includes(selectedLabel.id)) {
    const correctMatchLabel = findSingleToolLabelFromPartialData(defaultLabels, CORRECT_MATCH_LABEL)

    if (!correctMatchLabel) return labelsToApply

    return uniq([...labelsToApply, correctMatchLabel.id])
  }
  const incorrectMatchLabel = findSingleToolLabelFromPartialData(defaultLabels, WRONG_MATCH_LABEL)

  if (!incorrectMatchLabel) return labelsToApply

  return uniq([...labelsToApply, incorrectMatchLabel.id])
}

/**
 * This function returns an object with two keys.
 * batchBodyToolResults - The toolResult data that is going to be sent to the batch label endpoint,
 * toolResultsToUpdate - including all the data of the toolResults that are going to be updated.
 *
 * @param toolResults - The selected toolResults object
 * @param label - The tool label that we are selecting, used to determine if we are going to label or unlabel the tool results.
 * @param tool - The tool to which the results belong
 */
const getToolResultsBatchBody = (
  toolResults: ToolResultEmptyOutcome[],
  toolLabel: ToolLabel,
  defaultLabels: ToolLabel[],
  toolToolLabels: ToolLabel[],
): { toolResultsToUpdate: ToolResultEmptyOutcome[]; batchBodyToolResults: ToolResultBatchLabelChild[] } => {
  let removeSelectedLabel = false

  // We just need to update the tool results that are not already labeled as the desired label.
  let toolResultsToUpdate = toolResults.filter(toolResult => {
    return !toolResult.active_user_label_set?.tool_labels.includes(toolLabel.id)
  })

  // If there's nothing to update it means every tool result matched to the label, which means the user wants to unlabel all of them
  if (toolResultsToUpdate.length === 0) {
    removeSelectedLabel = true
    toolResultsToUpdate = toolResults
  }

  return {
    toolResultsToUpdate,
    batchBodyToolResults: toolResultsToUpdate.map(toolResult => {
      const labelsIdsToAssign = getLabelIdsToAssign({
        toolResult: toolResult as ToolResult,
        selectedLabel: toolLabel,
        defaultLabels,
        removeSelectedLabel: removeSelectedLabel,
      })

      const labelsToAssign: ToolLabel[] = []

      labelsIdsToAssign.forEach(labelIdToAssign => {
        const foundLabel = toolToolLabels.find(toolLabel => toolLabel.id === labelIdToAssign)
        if (foundLabel) labelsToAssign.push(foundLabel)
      })

      return {
        id: toolResult.id,
        outcome: getToolLabelsOutcome(labelsToAssign, toolResult),
        tool_label_ids: labelsIdsToAssign,
        set_null: removeSelectedLabel && !labelsIdsToAssign.length,
      }
    }),
  }
}

export const areToolResultsLabelsEqual = (toolResults: ToolResultEmptyOutcome[], labelIdsToIgnore?: string[]) => {
  const firstToolResult = toolResults[0]
  const firstToolResultToolLabelsString = firstToolResult?.active_user_label_set?.tool_labels
    .filter(lblId => (labelIdsToIgnore ? !labelIdsToIgnore.includes(lblId) : true))
    .sort()
    .join()

  // TODO: if we ever allow user to label more than 100 tool results at a time, this needs to be refactored
  const toolResultsHaveSameLabels = toolResults.every(toolResult => {
    const toolResultToolLabelsString = toolResult?.active_user_label_set?.tool_labels
      .filter(lblId => (labelIdsToIgnore ? !labelIdsToIgnore.includes(lblId) : true))
      .sort()
      .join()
    return toolResultToolLabelsString === firstToolResultToolLabelsString
  })
  return toolResultsHaveSameLabels
}

/** Use this function to filter out results with empty calculated_outcome,
 * which means they have been created to label old images with new tools,
 * AND not labeled.
 * We don't actually want them anywhere in the app until they have been labeled.
 */
export function getNonEmptyToolResults(toolResultsEmpty: ToolResultEmptyOutcome[] | undefined) {
  return (toolResultsEmpty?.filter(toolResult => toolResult.calculated_outcome !== 'empty') ||
    []) as ToolResultEmptyPredictionOutcome[]
}

/**
 * This function returns an object with two keys.
 * batchBodyToolResults - The toolResult data that is going to be sent to the batch label endpoint,
 * toolResultsToUpdate - including all the data of the toolResults that are going to be updated.
 *
 * @param toolResults - The selected toolResults object
 * @param label - a NonTrainableToolLabel, used to determine if we want to set the outcome or unlabel the tool results
 */
const getNonTrainableToolResultsBatchBody = (
  toolResults: ToolResultEmptyOutcome[],
  label: NonTrainableToolLabel,
): { toolResultsToUpdate: ToolResultEmptyOutcome[]; batchBodyToolResults: ToolResultBatchLabelChild[] } => {
  let outcome: Outcome | '' = ''
  let set_null = true

  const toolResultsHaveSameLabelSetOutcome = toolResults.every(
    toolResult => toolResult.active_user_label_set?.outcome === toolResults[0]?.active_user_label_set?.outcome,
  )

  // If all toolResults have the same label set outcome, we check if we need aplly the new outcome or unlabel them, if we need to label them, we set outcome and set_null, otherwise we use the default values
  if (toolResultsHaveSameLabelSetOutcome) {
    const currentOutcome = toolResults[0]?.active_user_label_set?.outcome
    if (currentOutcome !== label.outcome) {
      outcome = label.outcome
      set_null = false
    }
  } else {
    // If current labels are mixed, we apply the selected label to all toolResults
    outcome = label.outcome
    set_null = false
  }

  let toolResultsToUpdate = toolResults
  // If we need to set a new outcome, we only update the tool results which current label set outcome is different than the new outcome to apply
  if (outcome) {
    toolResultsToUpdate = toolResults.filter(tr => tr.active_user_label_set?.outcome !== label.outcome)
  }

  return {
    toolResultsToUpdate,
    batchBodyToolResults: toolResultsToUpdate.map(toolResult => {
      return {
        id: toolResult.id,
        outcome,
        tool_label_ids: [],
        set_null,
      }
    }),
  }
}

/**
 * Compute confusion matrix(es) for tool with these training results.
 *
 * @param trainingResults - All training results for tool
 * @param specName - Tool spec name
 * @param options - Thresholds and default tool labels for org
 * @param options.thresholdByRoutine - Object including current threshold by routine,
 * used to calculate predictions for overriden threholds
 *
 * @returns confusion matrix for this tool based on thresholds
 */

export const computeConfusionMatrix = (
  trainingResults: TrainingResultFlat[],
  specName: ToolSpecificationName,
  options: {
    threshold: BackendThreshold
    defaultLabels: ToolLabel[] | undefined
    allToolLabels: ToolLabel[] | undefined
    backendThresholdByRoutine?: ThresholdByRoutineParentId
  },
): ConfusionMatrix => {
  const { defaultLabels = [], backendThresholdByRoutine: thresholdByRoutine, threshold, allToolLabels = [] } = options
  const confusion: Confusion = {}
  const thresholdConfusion: ThresholdConfusion = {}
  for (const trainingResult of trainingResults) {
    const { isAboveThreshold, predictedLabel } = calculateTrainingResultPrediction({
      trainingResult,
      specName,
      threshold,
      defaultLabels,
      allToolLabels,
      thresholdByRoutine,
    })
    if (!predictedLabel) continue

    const componentId = String(trainingResult.component_id)
    // TODO: we want to revisit this once we have more than one calculated label
    const labeled = extractLabelIdFromCalculatedLabels(allToolLabels, trainingResult.calculated_labels) || ''
    confusion[labeled] ??= {}
    confusion[labeled]![componentId] ??= {}
    confusion[labeled]![componentId]![predictedLabel.id] ??= 0
    confusion[labeled]![componentId]![predictedLabel.id] += 1

    if (specName === 'match-classifier') {
      const countType = isAboveThreshold ? 'gte' : 'lt'
      thresholdConfusion[labeled] ??= {}
      thresholdConfusion[labeled]![componentId] ??= {}
      thresholdConfusion[labeled]![componentId]![predictedLabel.id] ??= { gte: 0, lt: 0 }
      thresholdConfusion[labeled]![componentId]![predictedLabel.id]![countType] += 1
    }
  }

  return { all_data_confusion: confusion, threshold_confusion: thresholdConfusion }
}

/**
 * Returns a ToolLabel that matches with the provided label data.
 * @param toolLabels - The tool labels to search from.
 * @param labelData - Partial ToolLabel data used to search.
 */
export const findSingleToolLabelFromPartialData = (
  toolLabels: ToolLabel[] | undefined,
  labelData: Partial<ToolLabel>,
) => toolLabels?.find(lbl => lbl.severity === labelData.severity && lbl.value === labelData.value)

/**
 * Finds the ToolLabels that matches the provided partialLabels, used to return the labels for the tool.
 *
 * @param toolLabels - Tool Labels
 * @param partialLabels - PartialLabels to use as find criteria
 */
export const findMultipleToolLabelsByPartialData = (toolLabels: ToolLabel[], partialLabels: PartialToolLabel[]) => {
  const labels = [] as ToolLabel[]

  partialLabels.forEach(partialLabel => {
    const foundLabel = findSingleToolLabelFromPartialData(toolLabels, partialLabel)
    if (foundLabel) labels.push(foundLabel)
  })

  return labels
}

/**
 * Get the tool labels with additional info for the given tool.
 *
 * @param tool - Tool to get labels for
 * @param defaultLabels - Default tool labels
 *
 */
export const findToolLabelsByTool = (tool: Tool, defaultLabels: ToolLabel[]) => {
  if (tool.specification_name === 'graded-anomaly') {
    return findMultipleToolLabelsByPartialData(defaultLabels, GRADED_ANOMALY_TOOL_LABELS)
  }

  if (tool.specification_name === 'classifier' || tool.specification_name === 'deep-svdd') {
    return findMultipleToolLabelsByPartialData(defaultLabels, ANOMALY_DEFECT_TOOL_LABELS)
  }
}

export const isAnyQsFilterActive = (params: QsFilters) =>
  !!(
    params.user_outcome ||
    params.user_label_id__in ||
    params.prediction_label_id__in ||
    params.calculated_outcome__in ||
    params.inspection_id ||
    params.recipe_parent_id ||
    params.component_id ||
    params.start ||
    params.end ||
    params.prediction_score_max ||
    params.prediction_score_min
  )

export const getRoutineTrainingState = (routine?: RoutineWithAois) => {
  if (!routine) return
  const { tools } = getAoisAndToolsFromRoutine(routine)
  if (tools.some(tool => tool.state && TRAINING_STATES.includes(tool.state))) return 'training'
  if (tools.some(tool => tool.state === 'failed')) return 'failed'
  if (tools.some(tool => tool.state === 'canceled')) return 'canceled'

  return
}

/**
 * Waits for a condition to be met.
 * @param condition Function that evaluates a condition, must eventually return true.
 * @param timeoutMs Interval in milliseconds to check the condition
 * @param limitMs Maximum time to wait for the condition to be met
 * @returns Resolves to true when condition is met
 */
export async function waitFor(condition: () => boolean, timeoutMs = 100, limitMs = 10000) {
  return new Promise<boolean>(resolve => {
    function checkCondition() {
      const conditionResult = condition()
      if (conditionResult) {
        resolve(conditionResult)
      } else {
        if (limitMs > timeoutMs) {
          limitMs -= timeoutMs
          setTimeout(checkCondition, timeoutMs)
        } else {
          resolve(false)
        }
      }
    }
    checkCondition()
  })
}

/**
 * Returns a string with an user facing representation of the toolResult label.
 * It can be a standard Outcome or a custom label for match tool.
 *
 * @param toolResult The current toolResult
 * @param specification The current tool specification name
 * @param allToolLabels all tool labels, including custom and default
 */
export const getLabels = (
  toolResult: ToolResultEmptyOutcome,
  tool: Pick<Tool, 'specification_name'>,
  allToolLabels?: ToolLabel[],
): { value?: string; icon?: string }[] => {
  if (toolResult.active_user_label_set?.outcome === 'unknown') return [{ value: 'Unknown' }]

  if (TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(tool.specification_name)) {
    const labelIds = toolResult.active_user_label_set?.tool_labels
    const toolLabels = allToolLabels?.filter(label => labelIds?.includes(label.id))
    if (toolLabels?.length) {
      return toolLabels.map(toolLabel => ({ value: getLabelName(toolLabel) }))
    }
  }
  return toolResult.active_user_label_set ? [{ value: toolResult.active_user_label_set?.outcome }] : []
}

/**
 * Sorts list of elements by severity
 *
 * @param elements List of elements, most likely Labels, to sort
 * @returns sorted list of elements by severity
 */
export const sortBySeverity = <T extends { severity: ToolLabelSeverity }>(
  elements: T[],
  reverseOrder?: boolean,
): T[] => {
  const orderedSeverities: ToolLabelSeverity[] = ['critical', 'minor', 'good', 'neutral']
  if (reverseOrder) orderedSeverities.reverse()

  // We don't want to alter the original array, so we make a copy
  return [...elements].sort(
    (lblA, lblB) => orderedSeverities.indexOf(lblA.severity) - orderedSeverities.indexOf(lblB.severity),
  )
}

/**
 * Calculates the labels to use for a given tool result
 *
 * @param toolResult Tool result to get calculated labels from
 * @param toolLabels List of all possible labels
 * @returns the calculated labels
 */
export const getToolResultLabels = (
  toolResult: Pick<ToolResult, 'active_user_label_set' | 'prediction_labels'>,
  toolLabels: ToolLabel[] | undefined,
): ToolLabel[] | undefined => {
  if (toolResult.active_user_label_set?.tool_labels.length) {
    return toolLabels?.filter(toolLabel => toolResult.active_user_label_set!.tool_labels.includes(toolLabel.id))
  }
  if (toolResult.prediction_labels.length) {
    return toolLabels?.filter(toolLabel => toolResult.prediction_labels.includes(toolLabel.id))
  }
}

/**
 * Resizes an image to a given maximum width and height.
 *
 * @param imageSrc source of image file to resize
 * @param resizeW Maximum width we want the new image to have
 * @param resizeH Maximum height we want the new image to have
 * @param extension Whether the received image is jpg or png file
 * @returns A promise that resolves to the new resized file
 */
export function resizeImageFileFromUrl(
  imageSrc: string,
  resizeW: number = 160,
  resizeH: number = 120,
  extension: 'png' | 'jpg' = 'jpg',
) {
  var image = new Image()
  const isJpeg = extension === 'jpg'
  return new Promise<File | null>(resolve => {
    image.onload = function () {
      // Resize the image
      const canvas = document.createElement('canvas')
      let imgWidth = image.width
      let imgHeight = image.height

      // Get desired aspect ratio
      if (imgWidth > imgHeight) {
        if (imgWidth > resizeW) {
          imgHeight *= resizeW / imgWidth
          imgWidth = resizeW
        }
      } else {
        if (imgHeight > resizeH) {
          imgWidth *= resizeH / imgHeight
          imgHeight = resizeH
        }
      }
      canvas.width = imgWidth
      canvas.height = imgHeight
      canvas.getContext('2d')?.drawImage(image, 0, 0, imgWidth, imgHeight)
      canvas.toBlob(blob => {
        resolve(blob && new File([blob], `resized.${isJpeg ? 'jpg' : 'png'}`))
      }, 'image/jpeg')
    }
    image.crossOrigin = 'anonymous'
    image.src = imageSrc
  })
}

/**
 * Wrapper that receives an image file and resizes it
 *
 * @param imageSrc image file to resize
 * @param resizeW Maximum width we want the new image to have
 * @param resizeH Maximum height we want the new image to have
 * @param extension Whether the received image is jpg or png file
 * @returns A promise that resolves to the new resized file
 */
export function resizeImageFile(
  imageFile: File,
  resizeW: number = 160,
  resizeH: number = 120,
  extension: 'png' | 'jpg' = 'jpg',
) {
  const reader = new FileReader()
  return new Promise<File | null>(resolve => {
    reader.onload = async function () {
      const file = await resizeImageFileFromUrl(reader.result as string, resizeW, resizeH, extension)
      resolve(file)
    }
    reader.readAsDataURL(imageFile)
  })
}

/**
 *
 * Returns the label images to show, first, if no user_images are present, it returns fallback_images.
 * Then, if preferred image is 'full' return the full image, otherwise return the thumbnail.
 *
 * @param toolLabel - The tool label to get the images for
 * @param preferredImage - The preferred image to return, default to 'thumbnail'
 */
export const getToolLabelImagesToShow = (toolLabel: ToolLabel, preferredImage: 'full' | 'thumbnail' = 'thumbnail') => {
  let images: ToolLabelImage[]
  if (toolLabel.user_images.length) images = toolLabel.user_images
  else images = toolLabel.fallback_images

  // We just allow 3 user or fallback images, this is just an extra safety check
  const slicedImages = images.slice(0, LABEL_FALLBACK_IMAGES_TO_USE)

  return slicedImages.map(img => {
    if (preferredImage === 'full') return img.url
    return img.thumbnail_url || img.url
  })
}

/**
 * Helper function to upload a single file to a given URL
 *
 * @param url - the url to which the file will be uploaded
 * @param file - File to upload
 * @returns original url
 */
export const uploadSingleFile = async (url: string, file: File) => {
  const fileUploadRes = await service.uploadFile(file, url, {
    // We need to set the content-type to nothing, otherwise S3 complains
    headers: { 'content-type': '' },
  })
  if (fileUploadRes.type !== 'success') return 'error'
  return url
}

/**
 * Helper function for uploading an image and a thumbnail image to given presigned urls
 * @param imagePresignedUrl - regular size image presigned url
 * @param imageFile - regular size image file
 * @param thumbnailPresignedUrl - thumbnail size image presigned url
 * @param thumbnailFile - thumbnail size image file
 * @returns
 */
const uploadImageAndThumbnail = async ({
  imagePresignedUrl,
  imageFile,
  thumbnailPresignedUrl,
  thumbnailFile,
}: {
  imagePresignedUrl: string
  imageFile: File
  thumbnailPresignedUrl: string
  thumbnailFile: File | null
}) => {
  const url = await uploadSingleFile(imagePresignedUrl, imageFile)
  const thumbnail_url = thumbnailFile && (await uploadSingleFile(thumbnailPresignedUrl, thumbnailFile))
  return { url, thumbnail_url: thumbnail_url || undefined }
}

/**
 * Updates a string which contains a url. Removes the query parameters
 * @param url - full url to upload
 * @returns the same url which was sent, but without query parameters
 */
export const removeQsFromUrl = (url: string) => {
  return url.split(/[?#]/)[0] || url
}

export const generateThumbnails = async (images: File[]) => {
  return await Promise.all(
    images.map(async file => {
      let imageJPEG = file
      if (file.type !== 'image/jpeg') {
        imageJPEG = await createCompressedJpegFromPngFile(file)
      }
      const thumbnail = await resizeImageFile(imageJPEG)
      return { image: imageJPEG, thumbnail }
    }),
  )
}

export const uploadAndGenerateOnlyThumbnails = async (images: File[]) => {
  const presignedUrlsRes = await service.getBatchPresignedUrls('picture_image', 'jpg', images.length)
  if (presignedUrlsRes.type !== 'success') return

  const imagesToUpload = await generateThumbnails(images)

  const uploadPromises: Promise<string>[] = []
  imagesToUpload.forEach((image, idx) => {
    const presignedUrl = presignedUrlsRes.data.results[idx]?.url

    if (presignedUrl && image.thumbnail) {
      uploadPromises.push(uploadSingleFile(presignedUrl, image.thumbnail))
    }
  })

  return await Promise.all(uploadPromises)
}

/**
 * Generates thumbnails for the provided images, then uploads them to S3.
 *
 * @param images - The images to upload and generate thumbnails for
 * @returns An array of {url: string, thumbnail_url: string} with the urls of the images
 */
export const uploadImagesAndGenerateThumbnails = async (images: File[]) => {
  // we get presigned urls for regular and thumbnail images
  const presignedUrlsRes = await service.getBatchPresignedUrls('picture_image', 'jpg', images.length * 2)

  if (presignedUrlsRes.type !== 'success') return

  const imageUploadPromises: Promise<{ url: string; thumbnail_url: string | undefined }>[] = []

  const filesToUpload = await generateThumbnails(images)

  filesToUpload.forEach((files, idx) => {
    const imagePresignedUrl = presignedUrlsRes.data.results[idx]?.url
    const thumbnailPresignedUrl = presignedUrlsRes.data.results[idx + 1]?.url
    if (imagePresignedUrl && thumbnailPresignedUrl) {
      imageUploadPromises.push(
        uploadImageAndThumbnail({
          imagePresignedUrl,
          imageFile: files.image,
          thumbnailPresignedUrl,
          thumbnailFile: files.thumbnail,
        }),
      )
    }
  })

  const fileUploadResponses = await Promise.all(imageUploadPromises)

  return fileUploadResponses.filter(res => res.url !== 'error' && res.thumbnail_url !== 'error')
}

/**
 * Uploads tool label user images and return data ready to send to tool label update endpoint
 *
 * @param userImages - User images form field that includes current images, and the ones that need to be uploaded
 */
export const uploadToolLabelUserImages = async (userImages: LabelFormFields['user_images']) => {
  if (!userImages?.toUpload.length)
    return userImages?.current.map(urls => ({
      url: removeQsFromUrl(urls.url),
      thumbnail_url: urls.thumbnail_url && removeQsFromUrl(urls.thumbnail_url),
    }))

  const uploadedImages = await uploadImagesAndGenerateThumbnails(userImages.toUpload)

  if (!uploadedImages) return

  const updatedUrls = [...userImages.current, ...uploadedImages]

  return updatedUrls.map(urls => ({
    url: removeQsFromUrl(urls.url),
    thumbnail_url: urls.thumbnail_url ? removeQsFromUrl(urls.thumbnail_url) : undefined,
  }))
}

/**
 * Finds the description for a default tool label since those won't necessarily be correct in the DB
 *
 * @param toolLabelToFind - The default ToolLabel
 */
export const findDefaultDescription = (toolLabelToFind: ToolLabel) => {
  if (toolLabelToFind.kind !== 'default') return ''
  return findSingleToolLabelFromPartialData(defaultLabelDescriptions as ToolLabel[], toolLabelToFind)?.description || ''
}

/**
 * Used to get the value, severity and labels that will be passed to PrismResultButton or LabelList for a given ToolResult
 * @param toolResult - the current tool result
 * @param allToolLabels - all the org Tool Labels
 */
export const getToolResultValueSeverityAndLabels = (
  toolResult: ToolResultEmptyOutcome,
  allToolLabels?: ToolLabel[],
): { value: string | undefined; severity: LabelButtonSeverity | undefined; labels: ToolLabel[] | undefined } => {
  const activeToolLabels = toolResult.active_user_label_set
    ? allToolLabels?.filter(lbl => toolResult.active_user_label_set?.tool_labels.includes(lbl.id))
    : undefined

  if (activeToolLabels?.length) {
    const labels = filterOutDerivativeLabels(activeToolLabels)

    const activeToolLabel = labels[0]
    return { value: activeToolLabel ? getLabelName(activeToolLabel) : '', severity: activeToolLabel?.severity, labels }
  }

  return { value: undefined, severity: undefined, labels: undefined }
}

/**
 * Replace all ocurrence in a string.
 *
 * Native String.replaceAll method is not supported by our chrome gui version.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replaceAll#browser_compatibility
 *
 * @param str - string to be modified
 * @param find - string that will be replaced by `replace`
 * @param replace - the string that replaces `find`
 */
export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(find, 'g'), replace)
}

/**
 * Some of the labels applied should be hidden in certain parts of the UX. This function
 * extracts only the necessary labels to guarantee we only display what's necessary
 *
 * @param labels - Full list of tool labels
 * @returns Filtered list of labels to display
 */
export const filterOutDerivativeLabels = (labels: ToolLabel[]): ToolLabel[] => {
  return labels.filter(
    lbl =>
      !DERIVATIVE_LABELS.find(
        derivativeLabel => derivativeLabel.value === lbl.value && derivativeLabel.severity === lbl.severity,
      ),
  )
}

/**
 * We want to hide some labels that aren't currently used, so as to not confuse the user
 *
 * @param labels - Full list of tool labels
 * @returns Filtered list of labels to display
 */
export const filterOutUnusedLabels = (labels: ToolLabel[]): ToolLabel[] => {
  return labels.filter(
    lbl => !UNUSED_LABELS.find(unusedLabel => unusedLabel.value === lbl.value && unusedLabel.severity === lbl.severity),
  )
}

/**
 * Filter out deleted labels
 *
 * @param labels - Full list of tool labels
 * @returns Filtered list of labels to display
 */
export const filterOutDeletedLabels = (labels: ToolLabel[]): ToolLabel[] => {
  return labels.filter(lbl => !lbl.is_deleted)
}

/**
 * Extracts specifically the default Correct Match and Wrong Match labels from a list of labels
 *
 * @param labels - List of match tool labels
 * @returns - An array of tool labels containing only Correct Match or Incorrect Match
 */
export const extractDerivativeLabels = (labels: ToolLabel[]): ToolLabel[] => {
  return labels.filter(lbl =>
    DERIVATIVE_LABELS.find(
      derivativeLabel => derivativeLabel.value === lbl.value && derivativeLabel.severity === lbl.severity,
    ),
  )
}

/**
 * Calculates the label name to use, removes underscores from default labels.
 *
 * @param label - Label to extract name from
 * @returns - the label name to be displayed
 */
export const getLabelName = (label: ToolLabel) => {
  return label.kind === 'default' ? replaceAll(label.value, '_', ' ') : label.value
}

/**
 * Returns the EventType display name.
 */
export const getEventTypeName = (eventType: EventType) => {
  if (eventType.kind === 'default') return replaceAll(eventType.name, '_', ' ')

  return eventType.name.charAt(0).toUpperCase() + eventType.name.slice(1).toLowerCase()
}

/**
 * Refresh the item if it is being currently inspected
 *
 * @param itemId - Id from the item to be refreshed
 * @param inspectionItemsByRobot - ItemsByRobot data, used to know if item is currently being inspected
 * @param dispatch - Redux dispatch
 */
export const refreshInspectionItem = async ({
  itemId,
  inspectionItemsByRobot,
  dispatch,
}: {
  itemId: string
  inspectionItemsByRobot: ItemsByRobotId | undefined
  dispatch: Dispatch
}) => {
  if (!inspectionItemsByRobot) return
  const foundEntries = Object.entries(inspectionItemsByRobot).filter(([, item]) => {
    return item.id === itemId
  })

  if (!foundEntries.length) return

  const itemRes = await service.getItem(itemId)

  if (itemRes.type !== 'success') return

  const updatedData = { ...inspectionItemsByRobot }

  foundEntries.forEach(([robotId]) => {
    updatedData[robotId] = itemRes.data
  })

  dispatch(
    Actions.getterSave({
      key: getterKeys.inspectionItemsByRobot(itemRes.data.inspection),
      data: { data: updatedData },
    }),
  )
}
/**
 * Returns the connection status of the app
 *
 * @param store - Optional to use client store instead of global redux store
 */
export function getConnectionStatus(store: TypedStore = typedStore) {
  const state = store.getState()
  return state.connectionStatus.status
}

/**
 * Returns the colocated status of the app
 *
 * @param store - Optional to use client store instead of global redux store
 * @param state - Optional to use client state instead of global redux state, will be prefered over store state
 */
export function getIsColocated({ store, state }: { store?: TypedStore; state?: RootState } = { store: typedStore }) {
  const instantState = store?.getState()
  const stateToUse = state || instantState

  if (!stateToUse) return { isColocated: false, colocatedRobotIds: [] }

  const robotIds = getRobotIdsFromEdgeParams(stateToUse.edge)
  return { isColocated: robotIds.length > 0, colocatedRobotIds: robotIds }
}

/**
 * Given the offline mode designs in https://www.figma.com/file/bJmWHlYXuZVaCJZF1MZ0mk/Station-Detail-(main)?node-id=6594%3A43045
 * Boxes, cards and other repeated components have an opacity starting with 1, then 0.6, 0.3, 0.15 and so on
 * This function extracts the opacity value according to the index of the element.
 *
 * @param index - index of current element
 * @returns - Opacity value to use
 */
export const calculateOpacityByIndex = (index: number) => {
  if (!index) return 1
  return 0.6 / Math.pow(2, index - 1)
}

/**
 * This function is in charge of fetching inspections ordered by created_at DESC, that will be used as a proxy for the provided filters.
 * If they aren't active filters that need to be proxied, the function returns early.
 *
 * @param filterKeysToProxy - array of strings cotaining the filters that need to be proxied
 * @param proxyGetterKey - string to be used as key of inspections proxy getter branch
 * @param paramFilters - current param filters object
 * @param additionalFilters - Extra filters that don't fall in the proxy list, but we want to filter the inspections by this.
 * @param dispatch - redux dispatch
 *
 */
export const fetchInspectionsForProxy = async ({
  proxyGetterKey,
  paramFilters,
  additionalFilters,
  dispatch,
  next,
}: {
  proxyGetterKey: string
  additionalFilters?: { [key: string]: string }
  paramFilters: { [key: string]: string | number | undefined }
  dispatch: Dispatch
  next?: string
}) => {
  // filtering items by serial number ignores any other type of filter, so it's pointless to fetch any inspections
  if ('serial_number' in paramFilters) return

  let inspectionsRes: SendToApiResponse<FlatInspectionData>

  if (next) {
    inspectionsRes = await service.getNextPage<FlatInspectionData>(next)
  } else {
    const { filtersToProxy, filtersCount } = getFiltersToProxyInspections(paramFilters)
    if (!filtersCount) return

    const inspectionFilters = {
      ...filtersToProxy,
      ...additionalFilters,
    }

    const { end, start } = paramFilters
    // We need to fetch inspections whose started_at is less than the "end" timestamp specified by the user
    // and whose ended_at is greater than the "start" timestamp specified by the user.
    if (end) inspectionFilters.started_at_end = end
    if (start) inspectionFilters.ended_at_start_or_null = start
    // We want to exclude inspections without start date
    inspectionFilters.started_at_isnull = 'false'

    if (paramFilters.inspection_id) {
      inspectionFilters.id__in = paramFilters.inspection_id
    }

    inspectionsRes = await service.getFlatInspections(inspectionFilters)
  }

  if (inspectionsRes.type !== 'success') return

  dispatch(
    Actions.getterUpdate({
      key: getterKeys.inspectionsForFiltersProxy(proxyGetterKey),
      updater: prev => {
        if (prev && next) return getterAddPage(prev as SuccessResponseOnlyData, inspectionsRes.data)

        return inspectionsRes
      },
    }),
  )
  return inspectionsRes
}

/**
 * This function fetches tool results with null prediction score if needed
 *
 * @param nullPredictionFechedRef - A boolean ref used to track if we already fetched tool results with null prediction score
 * @param setIsLoadingMore - function to set if more results are being loaded
 * @param getterKey - getter key to be used to store the fetched results
 * @param resultsFetcher - funtion in charge of fetching results
 * @param inspectionsForProxy - inspections used as proxy to fetch results
 * @param dispatch - redux dispatch
 * @param predictionScoreSortingActive - Whether prediction score sorting is active
 * @param predictionScoreRangeFiltersActive - Whether prediction score range filters are active
 */
export const fetchToolResultsWithNullPredictionScore = async <
  T extends ReturnType<(typeof getterKeys)['analyticsItems' | 'toolResults' | 'toolParentToolResults']>,
  U extends GetterData<T>,
>({
  nullPredictionsFetchedRef,
  setIsLoadingMore,
  getterKey,
  resultsFetcher,
  inspectionsForProxy,
  dispatch,
  predictionScoreSortingActive,
  predictionScoreRangeFiltersActive,
}: {
  nullPredictionsFetchedRef?: React.MutableRefObject<boolean>
  setIsLoadingMore?: (loading: boolean) => void
  getterKey: T
  resultsFetcher: (
    scanInspectionsIds?: string[],
    additionalParams?: { [key: string]: string | undefined },
  ) => Promise<SendToApiResponse<U>>
  inspectionsForProxy: FlatInspection[] | undefined
  dispatch: Dispatch
  predictionScoreSortingActive?: boolean
  predictionScoreRangeFiltersActive?: boolean
}) => {
  if (
    !nullPredictionsFetchedRef ||
    nullPredictionsFetchedRef.current ||
    !predictionScoreSortingActive ||
    predictionScoreRangeFiltersActive
  )
    return

  setIsLoadingMore?.(true)

  // fetch tool results with prediction_score=null
  const nullPredictionFilters = {} as { [key: string]: string | undefined }

  nullPredictionFilters.prediction_score_min = undefined
  nullPredictionFilters.prediction_score_max = undefined
  nullPredictionFilters.prediction_score_lte = undefined
  nullPredictionFilters.prediction_score_gte = undefined
  nullPredictionFilters.prediction_score_isnull = 'true'

  const res = await resultsFetcher(
    inspectionsForProxy?.map(i => i.id),
    nullPredictionFilters,
  )

  if (res.type === 'success') {
    nullPredictionsFetchedRef.current = true
    dispatch(
      Actions.getterUpdate({
        key: getterKey,
        updater: prevRes => {
          return getterAddPage(prevRes, res.data)
        },
      }),
    )
  }
  setIsLoadingMore?.(false)
}

/**
 * This function is in charge of fetching results proxied by Inspections (eg, Items or Tool Results) when list end is reached.
 * It should only be used along with the `useInspectionsProxyResults` hook.
 *
 * @param isLoadingMoreRef - ref indicating if more results are being loaded
 * @param inspectionsForProxy - inspections used as proxy to fetch results
 * @param setIsLoadingMore - function to set if more results are being loaded
 * @param dispatch - redux dispatch
 * @param next - next page of current results response
 * @param lastScannedInspectionId - last scanned Inspection id of current results response
 * @param getterKey - getter key to be used to store the fetched results
 * @param nextInspectionsPage - next page of inspections
 * @param resultsFetcher - funtion in charge of fetching results, used when results from new page of inspections is needed
 * @param nullPredictionFechedRef - A boolean ref used to track if we already fetched tool results with null prediction score
 * @param predictionScoreSortingActive - Whether prediction score sorting is active
 * @param predictionScoreRangeFiltersActive - Whether prediction score range filters are active
 */
export const handleInspectionsProxyResultsEndReached = async <
  T extends ReturnType<(typeof getterKeys)['analyticsItems' | 'toolResults' | 'toolParentToolResults']>,
  U extends GetterData<T>,
>({
  isLoadingMoreRef,
  paramFilters,
  inspectionsForProxy,
  setIsLoadingMore,
  dispatch,
  nextResultsPage,
  proxyGetterKey,
  lastScannedInspectionId,
  getterKey,
  nextInspectionsPage,
  resultsFetcher,
  nullPredictionsFetchedRef,
  predictionScoreSortingActive,
  predictionScoreRangeFiltersActive,
}: {
  isLoadingMoreRef?: React.MutableRefObject<boolean>
  paramFilters: { [key: string]: string | number | undefined }
  inspectionsForProxy: FlatInspection[] | undefined
  setIsLoadingMore?: (loading: boolean) => void
  proxyGetterKey: string
  dispatch: Dispatch
  nextResultsPage?: string | null
  lastScannedInspectionId?: string
  getterKey: T
  nextInspectionsPage?: string | null
  resultsFetcher: (scanInspectionsIds?: string[], additionalParams?: {}) => Promise<SendToApiResponse<U>>
  nullPredictionsFetchedRef?: React.MutableRefObject<boolean>
  predictionScoreSortingActive?: boolean
  predictionScoreRangeFiltersActive?: boolean
}) => {
  if (isLoadingMoreRef?.current) return

  // To fetch results with inspections as proxy, we need to provide an array of inspection ids to scan from.
  // as we page next pages, we need to remove inspections that have already being full scanned, we make use of the
  // `last_inspection_id` property from the api response
  const fetchNextResultsWithInspectionsProxy = async () => {
    if (!nextResultsPage || !inspectionsForProxy) return
    setIsLoadingMore?.(true)
    const lastScannedInspectionIdx = inspectionsForProxy.findIndex(
      inspection => inspection.id === lastScannedInspectionId,
    )
    // we remove the inspections that were already scanned
    const remainingInspectionsToScan =
      lastScannedInspectionIdx >= 0 ? inspectionsForProxy.slice(lastScannedInspectionIdx) : inspectionsForProxy

    const res = await service.getNextPage<U>(nextResultsPage, {
      body: { scan_inspection_ids: remainingInspectionsToScan.map(inspection => inspection.id) },
      method: 'POST',
    })
    setIsLoadingMore?.(false)

    if (res.type !== 'success') return

    dispatch(
      Actions.getterUpdate({
        key: getterKey,
        updater: prevRes => getterAddPage(prevRes, res.data),
      }),
    )

    return res
  }

  const fetchNextInspections = async () => {
    if (!nextInspectionsPage) return
    const res = await service.getNextPage<FlatInspectionData>(nextInspectionsPage)
    if (res.type === 'success') {
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.inspectionsForFiltersProxy(proxyGetterKey),
          updater: prevRes => {
            if (!prevRes) return
            const newResults = uniqBy(
              [...prevRes.data.results, ...res.data.results],
              flatInspection => flatInspection.id,
            )
            return { ...prevRes, data: { ...res.data, results: newResults } }
          },
        }),
      )
      return res.data.results
    }
  }

  const fetchResultsWithNextInspectionsPage = async () => {
    setIsLoadingMore?.(true)
    const nextInspections = await fetchNextInspections()
    if (!nextInspections?.length) {
      setIsLoadingMore?.(false)
      return
    }

    const res = await resultsFetcher(nextInspections.map(flatInspection => flatInspection.id))
    if (res.type !== 'success') return
    setIsLoadingMore?.(false)
    dispatch(
      Actions.getterUpdate({ key: getterKey, updater: prevRes => getterAddPage(prevRes, res.data as GetterData<T>) }),
    )
    return res
  }

  const { filtersCount } = getFiltersToProxyInspections(paramFilters)

  if (filtersCount) {
    if (nextResultsPage) return fetchNextResultsWithInspectionsProxy()
    if (nextInspectionsPage) return fetchResultsWithNextInspectionsPage()
    return fetchToolResultsWithNullPredictionScore({
      nullPredictionsFetchedRef,
      setIsLoadingMore,
      getterKey,
      resultsFetcher,
      inspectionsForProxy,
      dispatch,
      predictionScoreSortingActive,
      predictionScoreRangeFiltersActive,
    })
  }

  if (nextResultsPage && !filtersCount) {
    setIsLoadingMore?.(true)
    const res = await service.getNextPage<U>(nextResultsPage)
    if (res.type === 'success') {
      dispatch(
        Actions.getterUpdate({
          key: getterKey,
          updater: prevRes => getterAddPage(prevRes, res.data),
        }),
      )

      if (!res.data.next) {
        fetchToolResultsWithNullPredictionScore({
          nullPredictionsFetchedRef,
          setIsLoadingMore,
          getterKey,
          resultsFetcher,
          inspectionsForProxy,
          dispatch,
          predictionScoreSortingActive,
          predictionScoreRangeFiltersActive,
        })
      }
    }
    setIsLoadingMore?.(false)
  }
}

/**
 * Converts threshold value from the format used on the backend into a format used by the frontend.
 *
 * @param threshold threshold value from backend to convert
 * @param specificationName Tool specification name to use for conversion
 * @returns threshold number value converted to displayable format
 */
export const getConvertedThreshold = (threshold: number, specificationName: ToolSpecificationName) => {
  if (specificationName === 'deep-svdd' || specificationName === 'graded-anomaly') return 100 - threshold
  // We default to what's currently being used for match tool, if in the future we have a different conversion, we
  // must add it here
  return threshold * 100
}

/**
 * Converts threshold value from the format used on the backend into a string with fixed decimals that can be displayed on the frontend.
 * This util should only be used when we want to display the threshold value diretly as text or JSX. Do not use this function for
 * anything that involves calculations as it rounds the threshold value and converts it to a string. To obtain the converted value
 * without reformatting use getConvertedThreshold.
 *
 * @param threshold threshold value from backend to convert
 * @param specificationName Tool specification name to use for conversion
 * @returns threshold string value converted to displayable format
 */
export const getDisplayThreshold = (
  threshold: number | null | undefined,
  specificationName: ToolSpecificationName,
  fixedDecimals: number = 2,
) => {
  if (isNil(threshold)) {
    return '--'
  }
  return getConvertedThreshold(threshold, specificationName).toFixed(fixedDecimals)
}

/**
 * Converts threshold value from the backend format to a Threshold object which contains both the backend format and the format we use on the frontend.
 *
 * @param threshold threshold value from backend to convert
 * @param specificationName Tool specification name to use for conversion
 * @returns threshold value converted to backend format
 */
export const getThresholdFromBackendValues = (
  threshold: BackendThreshold,
  specificationName: ToolSpecificationName,
  userFacingValuesDecimals: number = 2,
): Threshold => {
  if (TOOLS_WITH_SCORE_INVERTED.includes(specificationName)) {
    if (isBackendThresholdFromGARTool(threshold)) {
      // Note that the graded anomaly tool inverts the threshold values
      return {
        ...threshold,
        userFacingLowerThreshold: +(100 - threshold.upperThreshold).toFixed(userFacingValuesDecimals),
        userFacingUpperThreshold: +(100 - threshold.lowerThreshold).toFixed(userFacingValuesDecimals),
      }
    } else {
      return {
        ...threshold,
        userFacingThreshold: +(100 - threshold.threshold).toFixed(userFacingValuesDecimals),
      }
    }
  }
  threshold = threshold as Pick<ToolThreshold, 'threshold'>
  return {
    ...threshold,
    userFacingThreshold: +(threshold.threshold * 100).toFixed(userFacingValuesDecimals),
  }
}

/**
 * Converts threshold value from the format used on the frontend to a Threshold object which contains both the backend format and the format we use on the frontend.
 *
 * @param threshold threshold value from backend to convert
 * @param specificationName Tool specification name to use for conversion
 * @returns threshold value converted to backend format
 */
export const getThresholdFromUserFacingValues = (
  threshold: UserFacingThreshold,
  specificationName: ToolSpecificationName,
): Threshold => {
  if (TOOLS_WITH_SCORE_INVERTED.includes(specificationName)) {
    if (isUserFacingThresholdFromGARTool(threshold)) {
      // Note that the graded anomaly tool inverts the threshold values, so if we set for instance
      // lowerThreshold: 20 and upperThreshold: 40 in the UX, the backend must receive them as
      // lower threshold: 60 (corresponding to the inverse of the upper threshold) and
      // upper threshold: 80 (corresponding to the inverse of the lower threshold)
      return {
        ...threshold,
        lowerThreshold: 100 - (threshold.userFacingUpperThreshold || 0),
        upperThreshold: 100 - (threshold.userFacingLowerThreshold || 0),
      }
    } else {
      return {
        ...threshold,
        threshold: 100 - (threshold.userFacingThreshold || 0),
      }
    }
  }

  threshold = threshold as Pick<ToolThreshold, 'userFacingThreshold'>
  return {
    ...threshold,
    threshold: (threshold.userFacingThreshold || 0) / 100,
  }
}

/**
 * Returns a threshold from inference or user args
 * @param tool Tool to fetch threshold from
 */
export const getThresholdFromTool = (tool: ToolFlat): Threshold => {
  if (tool.specification_name === 'graded-anomaly') {
    let lowerThreshold = 0
    let upperThreshold = 0
    if (
      tool.inference_user_args.lower_threshold !== undefined &&
      tool.inference_user_args.upper_threshold !== undefined
    ) {
      lowerThreshold = tool.inference_user_args.lower_threshold as number
      upperThreshold = tool.inference_user_args.upper_threshold as number
    } else if (tool.metadata.lower_threshold !== undefined && tool.metadata.upper_threshold !== undefined) {
      lowerThreshold = tool.metadata.lower_threshold as number
      upperThreshold = tool.metadata.upper_threshold as number
    }
    return getThresholdFromBackendValues({ lowerThreshold, upperThreshold }, tool.specification_name)
  } else {
    let threshold = 0
    if (tool.inference_user_args.threshold !== undefined) {
      threshold = tool.inference_user_args.threshold
      return getThresholdFromBackendValues({ threshold }, tool.specification_name)
    }
    threshold = tool.metadata.threshold
    return getThresholdFromBackendValues({ threshold }, tool.specification_name)
  }
}

export const getThresholdFromToolAoiThresholdMetadata = (
  tool: ToolFlat,
  aoiParentId: string,
): Threshold | undefined => {
  if (tool.specification_name === 'graded-anomaly') {
    if (
      tool.metadata.aoi_thresholds?.[aoiParentId]?.user_override?.lower_threshold !== undefined &&
      tool.metadata.aoi_thresholds?.[aoiParentId]?.user_override?.upper_threshold !== undefined
    ) {
      const lowerThreshold = tool.metadata.aoi_thresholds?.[aoiParentId]?.user_override?.lower_threshold as number
      const upperThreshold = tool.metadata.aoi_thresholds?.[aoiParentId]?.user_override?.upper_threshold as number
      return getThresholdFromBackendValues({ lowerThreshold, upperThreshold }, tool.specification_name)
    }
  } else {
    if (tool.metadata.aoi_thresholds?.[aoiParentId]?.user_override?.threshold !== undefined) {
      const threshold = tool.metadata.aoi_thresholds?.[aoiParentId]?.user_override?.threshold as number
      return getThresholdFromBackendValues({ threshold }, tool.specification_name)
    }
  }
}
export function getUserFacingRecommendedValueFromTool(tool: ToolFlat | undefined): string | undefined {
  if (tool?.specification_name === 'graded-anomaly') {
    const lowerValue = isNumber(tool.metadata.upper_threshold)
      ? getDisplayThreshold(tool.metadata.upper_threshold, tool.specification_name)
      : 0

    const upperValue = isNumber(tool.metadata.lower_threshold)
      ? getDisplayThreshold(tool.metadata.lower_threshold, tool.specification_name)
      : 0
    return `${lowerValue} - ${upperValue}`
  }

  if (tool?.specification_name === 'deep-svdd') {
    return isNumber(tool.metadata.threshold)
      ? getDisplayThreshold(tool.metadata.threshold, tool.specification_name)
      : '0'
  }

  if (tool?.specification_name === 'match-classifier') return '0'
  if (tool?.specification_name === 'classifier') return '50'
}

/**
 * Returns an Experiment state used by the frontend to render loading states
 *
 * @param state - The current ExperimentState
 */
export const getExperimentState = (state?: ExperimentState): ExperimentState | undefined => {
  if (state === 'invoked' || state === 'generating_dataset' || state === 'in_progress') return 'in_progress'
  return state
}

/**
 * Returns an array of Tool Labels obtained from the prediction metadata.
 *
 * @param labels - tool labels array to search for the prediction labels on
 * @param predictionLabels - metadata.prediction_labels from a tool
 * @returns ToolLabel[] - Array of tool labels that match to the prediction labels field
 */
export function getLabelsFromPredictionLabels(labels: ToolLabel[], predictionLabels: MetadataPredictionLabel[]) {
  const result: ToolLabel[] = []
  predictionLabels.forEach(label => {
    if ('id' in label) {
      const foundLabel = labels.find(lbl => lbl.id === label.id)
      if (foundLabel) result.push(foundLabel)
    } else {
      const foundLabel = labels.find(lbl => lbl.value === label.value && lbl.severity === label.severity)
      if (foundLabel) result.push(foundLabel)
    }
  })

  return result
}

/**
 * Returns whether "Prediction Score" filters are active, based on qs params.
 * @param params query params object
 * @returns boolean
 */
export const arePredictionScoreRangeFiltersActive = (params: QsFilters) => {
  return 'prediction_score_min' in params || 'prediction_score_max' in params
}

/**
 * Returns whether "Prediction Score" sort filters are active, based on qs params.
 * @param params query params object
 * @returns boolean
 */
export const arePredictionScoreSortFiltersActive = (params: QsFilters) => {
  return ['prediction_score', '-prediction_score'].includes(params.ordering || '')
}

/**
 * Returns whether "Prediction Score" has any filters active, based on qs params.
 * @param params query params object
 * @returns boolean
 */
export const arePredictionScoreFiltersActive = (params: QsFilters) => {
  return arePredictionScoreRangeFiltersActive(params) || arePredictionScoreSortFiltersActive(params)
}

/**
 * Returns whether "Date" filters are active, based on qs params.
 * @param params query params object
 * @returns boolean
 */
export const areDateFiltersActive = (params: QsFilters) => {
  return 'start' in params || 'end' in params
}

/**
 * Wrapper around multiple functions that return whether a certain filter is active.
 * @param params - The current query string params object
 *
 */
export const areFiltersActive = (params: QsFilters) => {
  return {
    dateFiltersActive: areDateFiltersActive(params),
    predictionScoreFiltersActive: arePredictionScoreFiltersActive(params),
    predictionScoreSortingActive: arePredictionScoreSortFiltersActive(params),
    predictionScoreRangeFiltersActive: arePredictionScoreRangeFiltersActive(params),
  }
}

/**
 * Gets the backend params needed to apply the prediction score filters
 * @param toolSpecificationName - The current tool specification name
 * @param predictionScore - An array of the min and max prediction score
 *
 */
export const convertPredictionScoreToBackendQueryParams = ({
  toolSpecificationName,
  predictionScore,
}: {
  toolSpecificationName: ToolSpecificationName
  predictionScore: number[]
}) => {
  const params: { [key: string]: number } = {}
  if (TOOLS_WITH_SCORE_ZERO_TO_ONE.includes(toolSpecificationName)) {
    if (predictionScore[0]) params['prediction_score_gte'] = +((predictionScore[0] || 0) / 100).toFixed(3)
    if (predictionScore[1]) params['prediction_score_lte'] = +((predictionScore[1] || 0) / 100).toFixed(3)
  }
  if (TOOLS_WITH_SCORE_INVERTED.includes(toolSpecificationName)) {
    if (predictionScore[0]) params['prediction_score_lte'] = +(100 - (predictionScore[0] || 0)).toFixed(3)
    if (predictionScore[1]) params['prediction_score_gte'] = +(100 - (predictionScore[1] || 0)).toFixed(3)
  }

  return params
}

/**
 * This function will convert the provided filters to valid backend query string params.
 *
 * @param filters - An object containing the filters that will be converted
 */
export const convertAllFiltersToBackendQueryParams = (filters: { [key: string]: any }) => {
  // For request that fetch inspections or use inspections as a proxy we need to use `started_at_start` and `started_at_end`
  // Since these filters have no effect on other queries, and `start` and `end` have no effect on inspection queries we just send both to all requests.
  if (filters.start) filters.started_at_start = filters.start
  if (filters.end) filters.started_at_end = filters.end

  let params: { [key: string]: string | number } = {}

  for (let key in filters) {
    const value = filters[key as keyof typeof filters]

    if (key === 'predictionScore' && value) {
      const { tool_specification } = filters
      const predictionScoreParams = convertPredictionScoreToBackendQueryParams({
        predictionScore: value,
        toolSpecificationName: tool_specification,
      })

      params = { ...params, ...predictionScoreParams }

      if (!filters.ordering) {
        // If you don't pass this ordering param when filtering by prediction score, the api won't filter by prediction score
        params['ordering'] = 'prediction_score'
      }
      continue
    }

    if (key === 'prediction_label_id' || key === 'user_label_id') {
      if (Array.isArray(value)) {
        params[`${key}__in`] = value.join()
      }
      continue
    }

    // Filtering only by outcome doesn't work, so we must filter by cached outcome
    if (key === 'outcome') key = 'calculated_outcome'

    if (key === 'calculated_outcome' && Array.isArray(value) && value.includes('unknown')) {
      params['calculated_outcome__in'] = [...value, 'error', 'needs-data'].join()

      continue
    }

    if (key === 'ordering' && TOOLS_WITH_SCORE_INVERTED.includes(filters.tool_specification)) {
      // We need to flip the values when sorting by `prediction_score` with tools that have the score inverted
      if (value === 'prediction_score') {
        params['ordering'] = '-prediction_score'
        continue
      } else if (value === '-prediction_score') {
        params['ordering'] = 'prediction_score'
        continue
      }
    }

    if (isMoment(value)) params[key] = value.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
    if (typeof value === 'string') params[key] = value
    if (Array.isArray(value)) params[`${key}__in`] = value.join()
  }

  return {
    ...params,
    ...getSiteAndLinesBackendParams(filters, false),
  }
}

export const toolLabelMatchesPartial = (toolLabel: ToolLabel, partialLabel: PartialToolLabel) => {
  return toolLabel.severity === partialLabel.severity && toolLabel.value === partialLabel.value
}

export const isLabelAnomaly = (toolLabel: ToolLabel) =>
  toolLabelMatchesPartial(toolLabel, CRITICAL_ANOMALY_LABEL) || toolLabelMatchesPartial(toolLabel, MINOR_ANOMALY_LABEL)

export const isLabelUncertain = (label: ToolLabel): boolean => {
  return toolLabelMatchesPartial(label, UNCERTAIN_LABEL)
}

export const isLabelDiscard = (label: ToolLabel): boolean => {
  return toolLabelMatchesPartial(label, DISCARD_LABEL)
}

export const isLabelTestSet = (label: ToolLabel): boolean => {
  return toolLabelMatchesPartial(label, TEST_SET_LABEL)
}

export const isLabelUncertainOrDiscard = (label: ToolLabel): boolean => {
  return isLabelUncertain(label) || isLabelDiscard(label)
}

export const isNonTrainingLabel = (toolLabel: ToolLabel) =>
  isLabelUncertainOrDiscard(toolLabel) || isLabelTestSet(toolLabel)

export const getDisplaySeverity = (label: ToolLabel) => {
  if (isLabelUncertain(label)) return 'unknown'
  if (isLabelDiscard(label)) return 'discard'
  if (isLabelTestSet(label)) return 'test_set'
  return label.severity
}

export const areSomeRCALabels = (toolLabels: ToolLabel[]) =>
  toolLabels.some(toolLabel => toolLabel.kind === 'custom' && ['critical', 'minor'].includes(toolLabel.severity))

/**
 * Refreshes Routine and Recipe on redux
 *
 * @param routine_id - Routine Id we want to refresh.
 * @param recipe_id - Recipe Id we want to refresh
 * @param dispatch - Redux dispatch
 */
export const refreshRoutineAndRecipe = async ({
  routineId,
  recipeId,
  recipeParentId,
  dispatch,
}: {
  routineId: string
  recipeId: string
  recipeParentId?: string
  dispatch: Dispatch
}) => {
  await query(getterKeys.routine(routineId), () => service.getRoutine(routineId), { dispatch })
  await query(getterKeys.recipe(recipeId), () => service.getRecipe(recipeId), { dispatch })
  if (recipeParentId)
    await query(getterKeys.recipeParent(recipeParentId), () => service.getRecipeParent(recipeParentId), { dispatch })
}

/**
 * Refreshes Recipe and the Routines linked to it on redux
 *
 * @param recipe - Recipe we want to refresh
 * @param dispatch - Redux dispatch
 */
export const refreshRecipeAndRoutinesLinked = async ({
  recipe,
  dispatch,
}: {
  recipe: RecipeExpanded
  dispatch: Dispatch
}) => {
  const res = await query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), { dispatch })

  if (res?.type === 'success') {
    res.data.recipe_routines.forEach(recipeRoutine => {
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.routine(recipeRoutine.routine.id),
          updater: prevRes => {
            return {
              ...prevRes,
              data: { ...prevRes?.data, ...recipeRoutine.routine },
            }
          },
        }),
      )
    })
  }
}

/**
 * Links a Recipe, Routine and Robot. It checks if they already have links, if so, it unlinks them first.
 *
 * @param routineId - Routine Id we want to link.
 * @param robotId - Robot Id we want to link
 * @param recipe - Recipe we want to link
 * @param robotCapabilites - Capabilites of the robot we want to link
 * @param errorMsg - What error message we should show
 * @param dispatch - Redux dispatch
 * @param onOk - Callback if we linked Recipe, Routine and Robot successfully.
 */
export const linkRoutineRobotAndRecipe = async ({
  routineId,
  robotId,
  recipe,
  robotCapabilites,
  errorMsg,
  dispatch,
  onOk,
  history,
}: {
  routineId: string
  robotId: string
  recipe: RecipeExpanded
  robotCapabilites?: Capabilities
  errorMsg: string
  dispatch: Dispatch
  onOk?: (params: RoutineLinkedToRobotResponse) => void
  history: History
}) => {
  const recipeRoutineDefinition = recipe.recipe_routines.find(recipe_routine => recipe_routine.routine.id === routineId)

  const isRecipeOrRoutineProtectedWrapper = (res: Response) => {
    if (
      isRecipeOrRoutineResponseProtected(res, {
        recipe,
        routineParentId: routineParentId,
        history,
        onCreate: async (newRecipe, newRoutine) => {
          if (!newRoutine) return
          await linkRoutineRobotAndRecipe({
            routineId: newRoutine.id,
            robotId,
            recipe: newRecipe,
            robotCapabilites,
            errorMsg,
            dispatch,
            onOk,
            history,
          })
        },
      })
    ) {
      return true
    }

    return false
  }

  const routineParentId = recipe.recipe_routines.find(r => r.routine.id === routineId)?.routine.parent.id

  // We check if the Routine we are trying to link to another Robot, has an existing link already
  if (recipeRoutineDefinition?.robot_id) {
    const unlinkRoutineRes = await service.unlinkRoutineToRobot(routineId, recipe.id, recipeRoutineDefinition.robot_id)

    if (isRecipeOrRoutineProtectedWrapper(unlinkRoutineRes)) {
      return
    }

    if (unlinkRoutineRes.type !== 'success') {
      return error({ title: errorMsg })
    } else {
      warning({
        title: `${unlinkRoutineRes.data.routine_parent_name} unlinked from ${unlinkRoutineRes.data.robot_name}`,
        'data-testid': 'routine-unlinked-warning-notification',
      })
    }
  }

  const robotAlreadyLinked = recipe.recipe_routines.find(recipe_routine => recipe_routine.robot_id === robotId)

  // Then, we check if the Robot we are trying to link to has a Routine linked already
  if (robotAlreadyLinked) {
    const unlinkRobotRes = await service.unlinkRoutineToRobot(robotAlreadyLinked.routine.id, recipe.id, robotId)

    if (isRecipeOrRoutineProtectedWrapper(unlinkRobotRes)) {
      return
    }

    if (unlinkRobotRes.type !== 'success') {
      return error({ title: errorMsg })
    } else {
      warning({
        title: `${unlinkRobotRes.data.routine_parent_name} unlinked from ${unlinkRobotRes.data.robot_name}`,
        'data-testid': 'robot-unlinked-warning-notification',
      })
    }
  }

  // Finally, we link Recipe, Routine and Robot
  const linkRoutineRes = await service.linkRoutineToRobot(routineId, recipe.id, robotId)

  if (isRecipeOrRoutineProtectedWrapper(linkRoutineRes)) {
    return
  }

  if (linkRoutineRes.type !== 'success') {
    return error({ title: errorMsg })
  } else {
    const { routine_parent_name, robot_name, routine_id } = linkRoutineRes.data
    success({
      title: `${routine_parent_name} linked to ${robot_name}`,
      'data-testid': `${routine_parent_name}-linked-success-notification`,
    })

    // Set routine settings to the default robot settings
    const robotSettings = robotCapabilites && getDefaultCameraSettings(robotCapabilites)

    if (robotSettings) {
      const updateRoutineRes = await service.patchRoutine(routine_id, {
        settings: robotSettings.routine,
      })
      if (updateRoutineRes.type !== 'success') error({ title: 'Error updating view settings' })
    }

    refreshRoutineAndRecipe({
      routineId: linkRoutineRes.data.routine_id,
      recipeId: linkRoutineRes.data.recipe_id,
      recipeParentId: linkRoutineRes.data.recipe_parent_id,
      dispatch,
    })
    onOk && onOk({ ...linkRoutineRes.data })
  }
}

/**
 * Returns the Tool Parents Tools with its corresponding Experiment
 *
 * @param toolParent - Tool Parent to get the Tools with Experiments from
 *
 */
export const getToolParentToolsWithExperiments = (toolParent: ToolParent) =>
  toolParent.tools.map(tool => {
    const currentExperiment = toolParent.experiments.find(experiment => experiment.id === tool.experiment_id)
    return { ...tool, currentExperiment }
  })

/**
 * Returns the most recent valid tool.
 * The first valid Tool is either the most recent with a succesful experiment or a model without experiment id
 * This covers the cases where we have models with canceled, in_progress or failed experiments.
 * In that scenario the model without experiment_id is the "original" model
 *
 * @param toolParent - Tool Parent to get the most recent Tool from
 *
 */
export const getToolParentMostRecentValidTool = (toolParent: ToolParent) => {
  const toolsWithExperiments = getToolParentToolsWithExperiments(toolParent)

  toolsWithExperiments.sort(sortByNewestFirst)

  return toolsWithExperiments.find(tool => tool.currentExperiment?.state === 'successful' || !tool.experiment_id)
}

/**
 * Gets severerity radio button options
 * @param toolSpecificationName - tool specification name to get severity options from
 * @returns array of tool severities indicating which should be shown
 */
export const getSeverityOptionsByTool = (toolSpecificationName: ToolSpecificationName): ToolLabelSeverity[] => {
  if (toolSpecificationName === 'deep-svdd' || toolSpecificationName === 'classifier') return ['critical']
  if (toolSpecificationName === 'match-classifier') return ['neutral']
  if (toolSpecificationName === 'graded-anomaly') return ['minor', 'critical']

  return []
}

/**
 * Calculates if we should show causes and actions
 *
 * @param severity - Tool severity to calculate
 * @returns boolean - whether to show causes and actions or not
 */
export const showCausesAndActions = (severity?: ToolLabelSeverity) => {
  return severity === 'minor' || severity === 'critical'
}

/**
 * This function transforms degrees into radians
 */
export const getRad = (degrees: number) => {
  return (degrees * Math.PI) / 180
}

/**
 * This function will rotate a point by a certain angle (in radians)
 */
export const rotatePoint = ({ x, y }: { x: number; y: number }, rad: number) => {
  const rcos = Math.cos(rad)
  const rsin = Math.sin(rad)
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin }
}

/**
 * Returns a string representing the filter included in the qs
 *
 * @param params query string parms
 */
export const getLabelingScreenFiltersBranch = (params: { [key: string]: string | undefined }) => {
  return QS_FILTER_KEYS.reduce((string, key) => {
    // We ignore these filters as we don't need to refetch anything if they change.
    if (['group_id', 'showPredictionScore', 'expanded'].includes(key)) return string

    if (params[key]) return `${string}${key}=${params[key]}/`

    return string
  }, 'filters/')
}

/**
 * Returns a string representing the current toolResults branch.
 *
 * @param params - query string params object
 * @param toolParentId - the tool parent id
 * @param groupId - the current group id
 * @param smartGroupActive - wheter the smart groups are active.
 */
export const getToolResultsBranch = ({
  params,
  toolParentId,
  groupId,
  smartGroupActive,
}: {
  params: { [key: string]: string | undefined }
  toolParentId: string
  groupId?: string
  smartGroupActive?: boolean
}) => {
  const filtersBranch = getLabelingScreenFiltersBranch(params)

  if (smartGroupActive) {
    return `smart-group/${groupId}/${toolParentId}/${filtersBranch}`
  }
  if (groupId) {
    return `${groupId}/${toolParentId}/${filtersBranch}`
  }
  return `${toolParentId}/${filtersBranch}`
}

/**
 * Returns the deployment status for the given recipeId based on the provided toolsets
 *
 * @param toolsets - Current robot toolsets
 * @param recipeId - Recipe id to get the state from
 */
export const getToolsetsDeploymentStatus = (toolsets: Toolset[] | undefined, recipeId: string): DeploymentStatus => {
  if (!toolsets) return 'not-deployed'

  const recipeToolset = toolsets.find(toolset => toolset.recipe.id === recipeId)

  if (!recipeToolset) return 'not-deployed'

  if (recipeToolset.recipe_status.state === 'LOADED') return 'deployed'

  return 'not-deployed'
}

/**
 * Returns the accumulated status of the provided robot deployment status
 *
 * @param robotsDeploymentStatus - all station robots deploymen status
 */
export const getAccumulatedDeploymentStatus = (robotsDeploymentStatus: DeploymentStatus[]) => {
  if (!robotsDeploymentStatus.length) return 'not-deployed'

  if (robotsDeploymentStatus.every(status => status === 'deployed')) {
    return 'deployed'
  }

  if (robotsDeploymentStatus.some(status => status === 'deployed')) {
    return 'semi-deployed'
  }

  return 'not-deployed'
}

/**
 * Un-archives a recipe.
 *
 * @param recipeParent - Recipe Parent to unarchive
 * @param onSuccess - Callback if we successfully unarchived the recipe
 */
export const unarchiveRecipe = async ({
  recipeParentId,
  onSuccess,
}: {
  recipeParentId: string
  onSuccess?: () => void
}) => {
  const res = await service.updateRecipeParent(recipeParentId, { is_deleted: false })
  if (res.type !== 'success') {
    return error({ title: 'There was an issue unarchiving the recipe, try again.' })
  }
  onSuccess?.()
  success({ title: 'Recipe unarchived', 'data-testid': 'unarchive-recipe-success-notification' })
}

/**
 * This function returns a Promise that waits to resolve until the scroll for the provided element is finished.
 *
 * Implementation: this function works by attaching a scroll event listener to the provided element, and then,
 * on each scroll event, a new timeout is created, deleting the previous one if present, this way, the timeout callback
 * will be executed until the scrol events are not dispatched anymore, at that point we can asume the scroll ended.
 * The event listener is removed as soon as that timeout callback is executed.
 *
 * @param element - The HTML element for which we will wait for the scroll to end.
 *
 */
export const waitForScrollToEnd = async (element: HTMLElement) => {
  return new Promise<void>(resolve => {
    let scrollTimeout: NodeJS.Timeout
    function handler() {
      clearTimeout(scrollTimeout)
      scrollTimeout = setTimeout(function () {
        // Remove listener once scroll finishes
        element.removeEventListener('scroll', handler)
        resolve()
      }, 100)
    }
    element.addEventListener('scroll', handler)
  })
}

/**
 * Returns the first image or thumbnail from the provided RecipeParent.
 *
 * @param recipeParent - Recipe Parent to get an image from.
 * @param options.preferThumbnail - If true, the thumbnail_image is prefered, it will fallback to image if no thumbnail is found.
 *
 */
export const getRecipeParentImage = (
  recipeParent: Pick<RecipeParent, 'fallback_images'>,
  { preferThumbnail }: { preferThumbnail?: boolean },
) => {
  let recipeParentImage: string | undefined
  const sortedFallbackImages = [...recipeParent.fallback_images].sort(sortByOldestFirst)
  if (preferThumbnail) {
    recipeParentImage = sortedFallbackImages.find(images => !!images.image_thumbnail)?.image_thumbnail
  }

  if (!recipeParentImage) {
    recipeParentImage = sortedFallbackImages.find(images => !!images.image)?.image
  }

  return recipeParentImage
}

/**
 * Returns wether the camera resolution is compatible with the provided Routine or not.
 *
 * @param capabilities - Camera capabilites
 * @param routineSettings - Routine settings we want to compare to
 */
export const cameraResolutionIsCompatibleWithRoutine = (
  capabilities?: Capabilities | null,
  routineSettings?: RoutineSettings | null,
) => {
  // If routine hasn't been setup, any camera is compatible with it
  if (!routineSettings?.sensor_aoi) return true

  if (!capabilities) return false

  return (
    capabilities.cam_max_width_pixels >= routineSettings.sensor_aoi.x + routineSettings.sensor_aoi.width &&
    capabilities.cam_max_height_pixels >= routineSettings.sensor_aoi.y + routineSettings.sensor_aoi.height
  )
}

/**
 * Returns user readable string of their camera trigger mode.
 *
 * @param triggerMode - The camera trigger mode
 */
export const renderTriggerMode = (triggerMode?: TriggerMode) => {
  if (triggerMode === 'manual') return 'Manual'
  if (triggerMode === 'hardware') return 'Hardware'
  if (triggerMode === 'automatic') return 'Continuous'
  return '--'
}

export const momentToString = (value: DateValue, type: 'start' | 'end') => {
  if (!value) return ''
  let newDate
  if (type === 'start') newDate = value.startOf('day') // clone date value so it doesn't get mutated by call to add
  if (type === 'end') newDate = value.endOf('day')
  return newDate?.toISOString(true) || ''
}

/**
 * This function takes in a start and end dates and a period and calculates
 * the values of the new selected period.
 *
 * @param start - Moment type start date
 * @param end - Moment type end date
 * @param period - Current selected period
 */
export const computeCurrentPeriodForDateRange = (start: DateValue, end: DateValue, period: TimeSeriesDatePeriod) => {
  if (!start && ['10m', 'hour'].includes(period)) {
    return 'day'
  }
  const differenceMs = (end || moment()).diff(start) // If no end is specified, end defaults to now
  const dayDifference = Math.round(differenceMs / 1000 / 60 / 60 / 24)

  // Change selected period if necessary based on date range
  if (dayDifference > 3 && period === '30m') period = 'hour'
  if (dayDifference > HOUR_PERIOD_DAY_DIFFERENCE && period === 'hour') period = 'day'
  if (dayDifference > DAY_PERIOD_DAY_DIFFERENCE && period === 'day') period = 'week'
  if (dayDifference > WEEK_PERIOD_DAY_DIFFERENCE && period === 'week') period = 'month'

  if (dayDifference <= 30 && period === 'month') period = 'week'
  if (dayDifference <= HOUR_PERIOD_DAY_DIFFERENCE && period === 'week') period = 'day'
  if (dayDifference <= THIRTY_MIN_PERIOD_DAY_DIFFERENCE && period === 'day') period = 'hour'

  return period
}

// The following functions extract arrays into string and viceversa so they can be set on the qs
const divider = '|'

export const convertArrayToString = (array?: (number | string)[] | string): string => {
  if (Array.isArray(array)) return array.join(divider)
  return array || ''
}

export const convertStringFromArray = (str?: string) => {
  if (str?.includes(divider)) return str.split(divider)
  return str ? [str] : undefined
}
/**
 * Returns item unique calculated labels
 *
 * @param newItem - The item we want to get the calculated labels
 */
export const getItemCalculatedLabels = (newItem: ItemExpanded) => {
  const allToolResults = newItem.pictures.flatMap(p => p.tool_results)
  const allLabels = allToolResults.flatMap(tr => {
    if (tr.active_user_label_set?.tool_labels?.length) return tr.active_user_label_set?.tool_labels
    if (tr.prediction_labels.length) return tr.prediction_labels
    return []
  })
  return uniq(allLabels)
}

/**
 * Blurs the focused element, whatever it may be.
 */
export const windowBlur = () => {
  // Wait for a brief delay to try to get this event to the bottom of the event tree. Else it'll happen before clicks are executed which could end up not
  // bluring the element
  setTimeout(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }, 100)
}

export const isToolMuted = (tool?: Tool) => {
  return !!tool?.inference_user_args.is_muted
}

// This util can be simplified/removed when ToolResult.muted removed, https://elementary.atlassian.net/browse/ERS-5322
export const wasToolResultMuted = (toolResult: ToolResultEmptyOutcome) => {
  return !!(toolResult.muted || toolResult.inference_user_args.is_muted)
}

/**
 * Verify if the threshold values we have in the backend belongs to a GAR tool
 */
export function isBackendThresholdFromGARTool(
  threshold: BackendThreshold,
): threshold is Pick<GARToolThreshold, 'lowerThreshold' | 'upperThreshold'> {
  return 'lowerThreshold' in threshold && 'upperThreshold' in threshold
}

/**
 * Verify if the user facing threshold values belong to a GAR tool
 */
export function isUserFacingThresholdFromGARTool(
  threshold: UserFacingThreshold,
): threshold is Pick<GARToolThreshold, 'userFacingLowerThreshold' | 'userFacingUpperThreshold'> {
  return 'userFacingLowerThreshold' in threshold && 'userFacingUpperThreshold' in threshold
}

/**
 * Verify if the threshold values belong to a GAR tool
 */
export function isThresholdFromGARTool(threshold: Threshold): threshold is GARToolThreshold {
  return isBackendThresholdFromGARTool(threshold) && isUserFacingThresholdFromGARTool(threshold)
}

/**
 * Calculate whether the TrainingResult predicted correctly the outcome or not.
 * Rules:
 *  - For the `match-classifier` tool, we need to check if the training result predicted the correct label
 *    and if the prediction score is above the threshold.
 *  - For the rest of tools, we only need to compare the prediction score to the threshold provided
 *
 * @param trainingResult - TrainingResult we want to calculate whether the prediction is correct or not
 * @param specName - Toolspecification name
 * @param threshold - - Tool threshold
 * @param defaultLabels - Default labels. Only used when we are dealing with a `match-classifier`
 * @param allLabels - All labels. Only used when we are dealing with a `match-classifier`
 * @param thresholdByRoutine - Thresholds by each routine
 */
export function calculateTrainingResultPrediction({
  trainingResult,
  specName,
  threshold,
  defaultLabels,
  allToolLabels,
  thresholdByRoutine,
}: {
  trainingResult: TrainingResultFlat
  specName: ToolSpecificationName
  threshold: BackendThreshold
  defaultLabels: ToolLabel[] | undefined
  allToolLabels: ToolLabel[] | undefined
  thresholdByRoutine?: ThresholdByRoutineParentId
}) {
  const getThresholdData = (routineParentId: string): BackendThreshold => {
    if (!thresholdByRoutine?.[routineParentId]?.overriden) {
      return threshold
    }

    const overridenThresholds = thresholdByRoutine[routineParentId]
    if (overridenThresholds) return overridenThresholds.threshold

    return threshold
  }

  const thresholdToUse = getThresholdData(trainingResult.routine_parent_id)
  const predictionScore = trainingResult.prediction_score || 0

  let predictedLabel: ToolLabel | undefined
  let isAboveThreshold: boolean | undefined

  // Anomaly tools inverts their threshold values, so we need to calculate it differently for those tools
  if (specName === 'graded-anomaly' && isBackendThresholdFromGARTool(thresholdToUse)) {
    isAboveThreshold = predictionScore <= thresholdToUse.lowerThreshold
    if (isAboveThreshold) predictedLabel = findSingleToolLabelFromPartialData(defaultLabels, GOOD_NORMAL_LABEL)
    else if (predictionScore <= thresholdToUse.upperThreshold)
      predictedLabel = findSingleToolLabelFromPartialData(defaultLabels, MINOR_ANOMALY_LABEL)
    else predictedLabel = findSingleToolLabelFromPartialData(defaultLabels, CRITICAL_ANOMALY_LABEL)
  }

  if (specName === 'deep-svdd') {
    isAboveThreshold = predictionScore <= (thresholdToUse as ToolThreshold).threshold
    predictedLabel = isAboveThreshold
      ? findSingleToolLabelFromPartialData(defaultLabels, GOOD_NORMAL_LABEL)
      : findSingleToolLabelFromPartialData(defaultLabels, CRITICAL_ANOMALY_LABEL)
  }

  if (specName === 'classifier') {
    isAboveThreshold = predictionScore >= (thresholdToUse as ToolThreshold).threshold
    predictedLabel = isAboveThreshold
      ? findSingleToolLabelFromPartialData(defaultLabels, GOOD_NORMAL_LABEL)
      : findSingleToolLabelFromPartialData(defaultLabels, CRITICAL_ANOMALY_LABEL)
  }

  if (specName === 'match-classifier') {
    predictedLabel = allToolLabels?.find(
      label =>
        trainingResult.prediction_labels?.includes(label.id) &&
        !DERIVATIVE_LABELS.find(
          derivativeLabel => derivativeLabel.value === label.value && derivativeLabel.severity === label.severity,
        ),
    )
    isAboveThreshold = predictionScore >= (thresholdToUse as ToolThreshold).threshold
  }

  return { isAboveThreshold, predictedLabel }
}

const DEFAULT_TRAINING_METRICS: TrainingMetrics = {
  succesful: 0,
  falseNegative: 0,
  falsePositive: 0,
  falseMinorOrCritical: 0,
  total: 0,
  byLabelId: {} as TrainingMetrics['byLabelId'],
}

const MINOR_OR_CRITICAL = ['minor', 'critical']

export function calculateTrainingMetrics(
  confusionMatrix: ConfusionMatrix,
  allToolLabels: ToolLabel[] | undefined,
  tool: ToolFlat | undefined,
  labelsToIncludeIds?: string[],
): TrainingMetrics {
  if (!confusionMatrix.all_data_confusion || !tool) return DEFAULT_TRAINING_METRICS

  return Object.entries(confusionMatrix.all_data_confusion).reduce(
    (
      { succesful, falseNegative, falsePositive, falseMinorOrCritical, total, byLabelId },
      [labelId, confusionLabelsByComponent],
    ) => {
      if (labelsToIncludeIds && !labelsToIncludeIds.includes(labelId))
        return { succesful, falseNegative, falsePositive, falseMinorOrCritical, total, byLabelId }

      const updatedByLabelId = { ...byLabelId }

      Object.entries(confusionLabelsByComponent).forEach(([componentId, confusionLabels]) => {
        let currentLabelSuccessful = 0
        let currentLabelFalseNegative = 0
        let currentLabelFalsePositive = 0
        let currentLabelFalseMinorOrCritical = 0

        if (tool?.specification_name === 'match-classifier') {
          const belowThreshold = confusionMatrix.threshold_confusion?.[labelId]?.[componentId]?.[labelId]?.lt || 0
          currentLabelSuccessful = confusionLabels[labelId] || 0
          Object.entries(confusionLabels).forEach(([confusionLabelId, confusionLabelCount]) => {
            if (confusionLabelId !== labelId) currentLabelFalsePositive += confusionLabelCount
          })
          currentLabelSuccessful -= belowThreshold
          currentLabelFalsePositive += belowThreshold

          falsePositive += currentLabelFalsePositive
          succesful += currentLabelSuccessful
        } else {
          const labelSeverity = allToolLabels?.find(label => label.id === labelId)?.severity

          // A training report prediction is considered correct if it was predicted the correct severity.
          Object.keys(confusionLabels).forEach(confusionLabelId => {
            const confusionLabelSeverity = allToolLabels?.find(label => label.id === confusionLabelId)?.severity
            const confusionLabelCount = confusionLabels[confusionLabelId] || 0
            // Correct Prediction
            if (labelSeverity === confusionLabelSeverity) {
              currentLabelSuccessful += confusionLabelCount
              succesful += confusionLabelCount
            }
            // False negative: good images predicted minor or critical
            else if (labelSeverity === 'good' && MINOR_OR_CRITICAL.includes(confusionLabelSeverity || '')) {
              currentLabelFalseNegative += confusionLabelCount
              falseNegative += confusionLabelCount
            }
            // False positive: minor or critical images predicted good
            else if (MINOR_OR_CRITICAL.includes(labelSeverity || '') && confusionLabelSeverity === 'good') {
              currentLabelFalsePositive += confusionLabelCount
              falsePositive += confusionLabelCount
            }
            // False minor or critical: Can only happen on GAR tools if a minor image gets predicted critical and viceversa
            else {
              currentLabelFalseMinorOrCritical += confusionLabelCount
              falseMinorOrCritical += confusionLabelCount
            }
          })
        }
        updatedByLabelId[labelId] ??= {}
        updatedByLabelId[labelId]![componentId] = {
          succesful: currentLabelSuccessful,
          falseNegative: currentLabelFalseNegative,
          falsePositive: currentLabelFalsePositive,
          falseMinorOrCritical: currentLabelFalseMinorOrCritical,
          total:
            currentLabelSuccessful +
            currentLabelFalseNegative +
            currentLabelFalsePositive +
            currentLabelFalseMinorOrCritical,
        }
      })

      const currentLabelTotal = Object.values(confusionLabelsByComponent).reduce((prev, v) => {
        let subTotal = prev
        Object.values(v).forEach(v => (subTotal += v))

        return subTotal
      }, 0)
      total += currentLabelTotal
      return { succesful, falseNegative, falsePositive, falseMinorOrCritical, total, byLabelId: updatedByLabelId }
    },
    DEFAULT_TRAINING_METRICS,
  )
}

export const getAllUserRoles = (): UserRole[] => ['member', 'inspector', 'manager', 'owner']

export function getPredictionText(toolResult: ToolResultEmptyOutcome) {
  if (!toolResult.tool) return
  const specificationName = toolResult.tool.specification_name

  if (specificationName === 'alignment') {
    // TODO: This will eventually come from a default label set by the tool. For now, we use the predicted outcome
    return `${toolResult.prediction_outcome === 'pass' ? 'correct' : 'wrong'} alignment`
  }

  if (specificationName === 'color-check') {
    const pixels = parsePredictionMetadata(toolResult.prediction_metadata?.num_pixels, 'number')
    return pixels ? `${commaSeparatedNumbers(pixels)} pixels in target color range` : undefined
  }

  if (specificationName === 'ocr') {
    const primaryText = parsePredictionMetadata(toolResult.prediction_metadata?.text, 'string')
    const secondaryText = parsePredictionMetadata(toolResult.prediction_metadata?.raw_result?.text, 'string')
    return primaryText || secondaryText
  }

  if (specificationName === 'detect-barcode') {
    const codes = parsePredictionMetadata(toolResult.prediction_metadata?.codes, 'array')

    return codes
      ?.filter(codeDict => !!codeDict.code)
      .map(codeDict => `"${codeDict.code}" ${codeDict.format ? `from  ${titleCase(codeDict.format)}` : ''}`)
      .join(', ')
  }
}

export function getUsername(user: User): string {
  if (user.first_name && user.last_name) return `${user.first_name} ${user.last_name}`
  return user.email
}

/*
 * Return the latest valid deployed toolset
 */
export const getLatestActiveToolset = (toolsets: Toolset[]) => {
  return maxBy(
    toolsets.filter(toolset => includes(['LOADED', 'DOWNLOADING', 'QUEUED'], toolset.recipe_status.state)),
    toolset => toolset.recipe_status.metadata.deployed_at || 0,
  )
}

/*
 * Delete org-wide EventSubs. This should only happend when first deploying E&N v2
 *
 * @param CRUDBody - Payload of the EventSubListCreate endpoint.
 * @param orgEventSubs - Org wide EventSubs
 * @param stations - All stations in the organization
 * @param components - All components in the organization
 * @param filterTargetIdToCreate - TargetId that we don't want to create when recreating
 *        EventSubs for station/products
 * @param overrideEventSubArgs - EventSubs fields we want to override when creating new ones
 */
export function deleteOrgEventSubs({
  CRUDBody,
  orgEventSubs,
  stations,
  components,
  filterTargetIdToCreate,
  overrideEventSubArgs,
}: {
  CRUDBody: CreateUpdateDeleteSubsBody
  orgEventSubs: EventSub[]
  stations: Station[]
  components: Component[]
  filterTargetIdToCreate?: string
  overrideEventSubArgs?: [string, Partial<EventSub>]
}) {
  // @TODO - revisit this when we can subscribe to tables besides station and component
  orgEventSubs.forEach(orgEventSub => {
    CRUDBody.delete?.push({
      id: orgEventSub.id,
    })
    stations.forEach(station => {
      if (filterTargetIdToCreate === station.id) return
      CRUDBody.create?.push({
        ...orgEventSub,
        ...(station.id === overrideEventSubArgs?.[0] ? overrideEventSubArgs[1] : {}),
        targets: { station_id: station.id },
      })
    })
    components.forEach(component => {
      if (filterTargetIdToCreate === component.id) return
      CRUDBody.create?.push({
        ...orgEventSub,
        ...(component.id === overrideEventSubArgs?.[0] ? overrideEventSubArgs[1] : {}),
        targets: { component_id: component.id },
      })
    })
  })
}

/*
 * Util function to check if the provided Unix timestamp is less than the current Unix timestamp
 *
 * @param unixTimeUntilMuted - Event Unix timestamp
 * @param timeZone - User preferred timezone
 */
export function isEventMuted(unixTimeUntilMuted: number | null | undefined, timeZone: string) {
  const mutedUntilMoment = unixTimeUntilMuted ? moment.unix(unixTimeUntilMuted) : undefined
  const currentTimeMoment = moment()
  const isMuted = !!mutedUntilMoment?.isAfter(currentTimeMoment)

  let timeFormat
  if (currentTimeMoment.isSame(mutedUntilMoment, 'date')) timeFormat = 'HH:mm A'
  else timeFormat = 'MM/DD/YYYY @ HH:mm A'

  const tooltipMessage =
    mutedUntilMoment?.diff(currentTimeMoment, 'years') || 0 > 1
      ? 'Muted indefinitely'
      : `Muted until ${mutedUntilMoment?.tz(timeZone).format(timeFormat)}`

  return { isMuted, tooltipMessage }
}

/*
 * Util function to only retrieve dirty values
 *
 * @param dirtyFields - Dirty fields of the form
 * @param values - All values of the form
 */
export function getDirtyValues<DF extends Record<string, unknown>, V extends Record<keyof DF, unknown>>(
  dirtyFields: DF,
  values: V,
): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'boolean' ? values[key] : getDirtyValues(dirtyFields[key] as DF, values[key] as V),
    }
  }, {})

  return dirtyValues
}

/*
 * Gets the payload for adding a susbscription
 * We will create a new EventSub for each `EventTargets` and current `EventSubs` the user has. If there isn't any `EventSub`
 * we default to create an EventSub for every EventType available
 *
 * @param eventTargetsToSubscribeTo - What event targets we want to subscribe
 * @param eventTypes - Event types belonging to the org. This will only get used if we dont have active eventSubs
 * @param eventSubs - Current EventSubs of the user.
 */
export function getAddSubscriptionPayload({
  eventTargetsToSubscribeTo,
  eventSubTypeTemplates,
}: {
  eventTargetsToSubscribeTo: EventTargets[]
  eventSubTypeTemplates: EventSub[] | undefined
}) {
  const payload: CreateUpdateDeleteSubsBody = {
    create: [],
  }
  eventTargetsToSubscribeTo.forEach(eventTarget => {
    // Create a record for each eventSubTypeTemplate record
    if (eventSubTypeTemplates?.length) {
      eventSubTypeTemplates.forEach(eventSub => {
        payload.create?.push({
          ...eventSub,
          targets: eventTarget,
          disabled_until_s: null, // In case the EventSubTypeTemplate had a `disabled_until_s` field
        })
      })
    }
    // We aren't subscribed to anything, we don't even have a EventSubTypeTemplate, so we create a EventSubTargetTemplate
    else {
      payload.create?.push({
        targets: eventTarget,
        disabled_until_s: null,
        type_id: null,
        via_sms: false,
        via_app: false,
        via_email: true,
      })
    }
  })
  return payload
}

/*
 * Create EventSubTargetTemplates for a given payload. See Notify component for a detailed definition of `EventSubTargetTemplate`
 */
export function getEventSubTargetTemplatesPayload(createPayload: CreateUpdateDeleteSubsBody['create']) {
  const payloadUniqByTarget = uniqBy(createPayload, payloadA => Object.values(payloadA.targets).sort().join())

  const eventSubTargetTemplates: CreateUpdateDeleteSubsBody['create'] = []
  payloadUniqByTarget?.forEach(payload => {
    // don't create another template if it already exists
    if (payload.type_id === null) return
    eventSubTargetTemplates.push({
      ...payload,
      type_id: null,
    })
  })

  return eventSubTargetTemplates
}

export const getStreamReadMessage = (payload: StreamReadMessage['payload']): StreamReadMessage => {
  const ts = Date.now()

  return {
    payload,
    new: true,
    message_id: `${ts}-0`,
    receivedMs: ts,
    messageMs: ts,
  }
}

export function getFiltersToProxyInspections(paramFilters: { [key: string]: string | number | undefined }) {
  const filtersToProxy = Object.fromEntries(
    Object.entries(paramFilters).filter(([key, value]) => FILTER_KEYS_TO_PROXY.includes(key) && value),
  )
  return { filtersToProxy, filtersCount: Object.keys(filtersToProxy).length }
}

export const isItemDetailModalOpen = (params: Record<string, any>) => {
  return !!params.detail_item_id || !!params.detail_tool_result_id
}

export function extractLabelIdFromCalculatedLabels(allToolLabels: ToolLabel[], calculatedLabelIds: string[]) {
  const toolLabelsToExclude = findMultipleToolLabelsByPartialData(allToolLabels, [
    TEST_SET_LABEL,
    CORRECT_MATCH_LABEL,
    WRONG_MATCH_LABEL,
  ])

  return calculatedLabelIds.filter(calcLabelId => {
    return !toolLabelsToExclude.map(toolLabel => toolLabel.id).includes(calcLabelId)
  })?.[0]
}

export async function refreshEventSubsBranches({ dispatch }: { dispatch: Dispatch }) {
  const promises: Promise<SendToApiResponse<EventSubsData>>[] = [
    getAllPages(service.getCurrentEventSubs({ has_targets: true, has_type: true })),
    getAllPages(service.getCurrentEventSubs({ has_targets: false })),
    getAllPages(service.getCurrentEventSubs({ has_type: false })),
  ]

  const eventSubsApiResponses = await Promise.allSettled(promises)

  batch(() => {
    if (eventSubsApiResponses[0]?.status === 'fulfilled' && eventSubsApiResponses[0].value.type === 'success') {
      dispatch(
        Actions.getterSave({
          key: getterKeys.eventSubs('realSub'),
          data: eventSubsApiResponses[0].value,
        }),
      )
    }

    if (eventSubsApiResponses[1]?.status === 'fulfilled' && eventSubsApiResponses[1].value.type === 'success') {
      dispatch(
        Actions.getterSave({
          key: getterKeys.eventSubs('typeTemplate'),
          data: eventSubsApiResponses[1].value,
        }),
      )
    }

    if (eventSubsApiResponses[2]?.status === 'fulfilled' && eventSubsApiResponses[2].value.type === 'success') {
      dispatch(
        Actions.getterSave({
          key: getterKeys.eventSubs('targetTemplate'),
          data: eventSubsApiResponses[2].value,
        }),
      )
    }
  })
}

export const getTimezoneDetails = (timezone: string) => {
  const zone = moment.tz(timezone)
  const gmtOffset = zone.format('Z')
  const abbr = zone.zoneAbbr()
  return { gmtOffset, abbr }
}

type SiteId = string
type StationSubsiteTypeId = string
type LineId = string
type StationId = string

export type SiteAndLineCascaderLocation =
  | [`${SiteId}_${StationSubsiteTypeId}`]
  | [`${SiteId}_${StationSubsiteTypeId}`, LineId | null]
  | [`${SiteId}_${StationSubsiteTypeId}`, LineId | null, StationId]

export function getSiteName(site: Site) {
  if (site.address.state) return `${site.address.state}, ${site.address.countryName}`
  return site.address.countryName
}

export const setPinnedRobot = ({
  stationId,
  robotId,
  dispatch,
  currentPinnedCameraData,
}: {
  stationId: string
  robotId: string | undefined
  dispatch: Dispatch
  currentPinnedCameraData: PinnedCameraByStation
}) => {
  const updatedPinnedCameraData = { ...currentPinnedCameraData }
  updatedPinnedCameraData[stationId] = robotId
  dispatch(Actions.localStorageUpdate({ key: 'pinnedCameraByStation', data: updatedPinnedCameraData }))
}

export function getDisplayStationLocation(siteName: string, lineName?: string) {
  return lineName ? `${siteName} / ${lineName}` : siteName
}

export function pluralize({ wordCount, word }: { wordCount: number; word: string }) {
  const suffix = wordCount === 1 ? '' : 's'
  return word + suffix
}

export const filterEventTypesByAllowedTargets = (eventType: EventType) => {
  return eventType.event_scopes
    .flatMap(scope => scope.targets)
    .every(target => QUALITY_EVENTS_ALLOWED_TARGET_TABLES.includes(target.table))
}

export function sortStationsByOrdering<T extends Pick<StationForSite, 'belongs_to_id' | 'ordering' | 'name'>>(
  stationA: T,
  stationB: T,
) {
  if (stationA.belongs_to_id && stationB.belongs_to_id && isNumber(stationA.ordering) && isNumber(stationB.ordering)) {
    return stationA.ordering - stationB.ordering
  }
  if (!stationA.ordering && !stationB.ordering) {
    return stationA.name.toLowerCase().localeCompare(stationB.name.toLowerCase())
  }

  if (!stationA.ordering) return 1
  if (!stationB.ordering) return -1
  return 0
}

export function getSiteAndLinesBackendParams(filters: Record<string, any>, paramsForMetrics: boolean) {
  let params: { [key: string]: string | undefined } = {
    site_id: undefined,
    subsite_id: undefined,
    subsite_type_id: undefined,
    station_id: undefined,
    station_subtype_id: undefined,
  }

  if (filters.station_id) {
    params = {
      ...params,
      station_id: filters.station_id,
    }
  } else if (filters.subsite_id) {
    params = {
      ...params,
      ...(paramsForMetrics
        ? { [`subsite_type_id-${filters.subsite_type_id}`]: filters.subsite_id }
        : { subsite_id: filters.subsite_id }),
    }
  } else if (filters.site_id) {
    params = {
      ...params,
      site_id: filters.site_id,
    }
  }

  return params
}
