import React from 'react'

import { useHistory } from 'react-router-dom'

import { Button } from 'components/Button/Button'
import { PrismArrowIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { useConnectionStatus } from 'hooks'

import Styles from './Breadcrumbs.module.scss'

type LinkBreadcrumb = { label: string; path: string; 'data-testid'?: string }
type MenuBreadcrumb = { menu: React.ReactElement; 'data-testid'?: string }
type CallbackBreadcrumb = { label: string; onClick?: () => any; 'data-testid'?: string }

export type Breadcrumb = LinkBreadcrumb | MenuBreadcrumb | CallbackBreadcrumb

/**
 * Special "token" for breadcrumb, useful if you only want its styles
 *
 * Used by Breadcrumbs to render the label for breadcrumbs
 *
 * @param children – Text to be rendered
 */
export const BreadcrumbLabel = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => {
  return (
    <PrismOverflowTooltip
      content={children}
      tooltipPlacement="bottom"
      className={`${Styles.prismBreadcrumb_label} ${className}`}
      canBeResized
    />
  )
}

/**
 * Special "token" for breadcrumb's Menu
 *
 * Used by Breadcrumbs to render the label for breadcrumbs menu
 *
 * @param title – Text to be rendered
 */
export const BreadcrumbMenu = ({
  title,
  className = '',
  'data-testid': dataTestId,
}: {
  title: string
  className?: string
  'data-testid'?: string
}) => {
  return (
    <div className={`${Styles.split} ${className}`} data-testid={dataTestId}>
      <div className={Styles.breadcrumbMenuLabel}>{title}</div>
      <PrismArrowIcon direction="down" isActive className={Styles.breadcrumbArrow} />
    </div>
  )
}

/**
 * Renders a list of breadcrumbs
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=53%3A290
 *
 * @param crumbs – Array of { label, path } where label is the name of the link and path where it links to. If path is empty string it won't link to anything.
 * @param className – classname to use for each breadcrumb item, in case they're displayed as a button
 * @param wrapperClassName – classname to use for breadcrumb container
 */
export const Breadcrumbs = ({
  crumbs,
  className,
  wrapperClassName,
}: {
  crumbs: Breadcrumb[]
  className?: string
  wrapperClassName?: string
}) => {
  const history = useHistory()

  const connectionStatus = useConnectionStatus()

  let oneCrumbIsMenu = false
  const links = crumbs.map((crumb, i) => {
    const isLastElement = crumbs.length - 1 === i
    if ('menu' in crumb) oneCrumbIsMenu = true

    return (
      <React.Fragment key={i}>
        {'menu' in crumb && crumb.menu}

        {'label' in crumb && (
          <div className={Styles.breadcrumbWidth}>
            <Button
              type="default"
              onClick={() => {
                'onClick' in crumb && crumb.onClick?.()
                'path' in crumb && history.push(crumb.path)
              }}
              disabled={connectionStatus !== 'online'}
              data-testid={crumb['data-testid']}
              className={`${Styles.prismBreadcrumb} ${className ?? ''} ${
                isLastElement ? Styles.prismBreadcrumb_active : ''
              } ${connectionStatus !== 'online' ? Styles.disabled : ''} `}
            >
              <BreadcrumbLabel>{crumb.label}</BreadcrumbLabel>
            </Button>
          </div>
        )}

        {!isLastElement && <PrismArrowIcon direction="right" isActive className={Styles.breadcrumbArrow} />}
      </React.Fragment>
    )
  })

  // The ellipsis class, its a helper in case there's no external menu, this prevents the container to overflow.
  return (
    <nav className={`${Styles.prismBreadcrumbs} ${!oneCrumbIsMenu ? Styles.ellipsis : ''} ${wrapperClassName ?? ''}`}>
      {links}
    </nav>
  )
}
