import React from 'react'

import { PrismAddIcon } from 'components/prismIcons'

import Styles from './PlcControl.module.scss'

type Props = {
  invisible?: boolean
  showSettings: boolean
  onClick: () => any
}

/**
 * Renders a blank box for when no layout item has been set for the current index.
 *
 * @param invisible - Whether we should show the borders and the box content as empty, or just render
 *  an invisible box
 * @param showSettings - Whether the settings are active and we should show a plis sign
 * @param onClick - Handler for when the user clicks on the current box, when settings are active
 */
function BlankBox({ invisible, showSettings, onClick }: Props) {
  return (
    <>
      <div
        onClick={() => showSettings && onClick()}
        className={`${Styles.plcBox} ${invisible && Styles.plcInvisible} ${
          showSettings ? Styles.plcAdd : Styles.plcEmpty
        }`}
      >
        {showSettings && <PrismAddIcon />}

        {!invisible && !showSettings && <div className={Styles.plcEmpty}>--</div>}
      </div>
    </>
  )
}

export default BlankBox
