import React, { useEffect, useMemo } from 'react'

import { Control, Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-redux-query'

import { getterKeys, SendToApiResponse, service } from 'api'
import { Button } from 'components/Button/Button'
import Card from 'components/Card/Card'
import { IconButton } from 'components/IconButton/IconButton'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import { Alert } from 'components/PrismAlert/PrismAlert'
import { PrismInfoIcon, PrismResetIcon } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { SearchableSelect } from 'components/SearchableSelect/SearchableSelect'
import { Token } from 'components/Token/Token'
import { useData } from 'hooks'
import { CameraStatus, RoutineParent, RoutineSlot, Tool, ToolSlot } from 'types'
import { getAoisAndToolsFromRoutine, getLatestActiveToolset, renderToolName } from 'utils'

import { EmptyStateSlots } from './CommunicationsList'
import Styles from './CommunicationsList.module.scss'

const ERROR_MSG = 'Unknown error saving recipe mapping. Please try again'

const cancelModalDefaultOptions = {
  header: 'Cancel Changes?',
  content: "If you cancel, you'll lose your changes. Please confirm.",
  okText: 'Confirm',
  id: 'cancel-slot-changes-confirmation',
} as const

const restoreSlotModalDefaultOptions = {
  header: 'Are you sure you want to reset this Recipe Slot?',
  content: 'If you reset, you will lose your current configurations. Please confirm.',
  okText: 'Confirm',
  id: 'reset-slot-confirmation',
} as const

type SetSlotCommandArgs = { index: number; routine_slot: RoutineSlot }

/**
 * Renders the routine tool slot mapping for the currently selected routine slot.
 *
 * @param routineSlot - Currently selected routine slot
 * @param onRefresh - when we save, reset or cancel, the refresh callback that will refetch everything
 */
function RoutineToolMapping({
  routineSlot,
  robotId,
  onRefresh,
  robotStatus,
  showEmptyState,
  selectedRoutineParentIds,
}: {
  routineSlot: RoutineSlot | undefined
  robotId: string
  onRefresh: () => Promise<void>
  robotStatus: CameraStatus
  showEmptyState: boolean
  selectedRoutineParentIds: string[] | undefined
}) {
  const defaultValues = getDefaultFormValues(routineSlot)
  const {
    reset,
    formState: { isDirty },
    control,
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm({
    defaultValues,
    mode: 'onChange',
  })

  // Reset form whenever we get a new routine slot
  useEffect(() => {
    reset(getDefaultFormValues(routineSlot))
  }, [reset, routineSlot])

  const routineParentsKey = routineSlot ? `routineParentsSlots-${robotId}-${routineSlot.index}` : undefined

  const routineParents = useData(getterKeys.routineParents(routineParentsKey))
  const routine_parent_id = watch('routine_parent_id')
  const formToolSlots = watch()

  const currentRoutineParent = useMemo(() => {
    if (!routineParents) return
    return routineParents.results.find(routineParent => routineParent.id === routine_parent_id)
  }, [routineParents, routine_parent_id])

  const handleResetSlotConfirm = () => {
    if (!routineSlot) return
    modal.confirm({
      ...restoreSlotModalDefaultOptions,
      onOk: async close => {
        // This function will reset the selected routine slot and its tools
        const commandRes = await service.atomSendCommand('integration-framework', 'clear_slot', robotId, {
          command_args: { index: routineSlot.index },
        })

        if (commandRes.type === 'success' && commandRes.data.success) {
          await onRefresh()
          success({
            title: `Recipe slot ${routineSlot.index} reset correctly`,
            'data-testid': 'recipe-slot-reset-successful',
          })
        } else {
          error({
            title: 'Unknown error resetting recipe slot. Please try again',
            'data-testid': 'recipe-slot-reset-not-successful',
          })
        }
        close()
      },
      'data-testid': 'restore-slot-modal',
    })
  }

  const cancelChanges = () => {
    modal.confirm({
      ...cancelModalDefaultOptions,
      onOk: close => {
        reset(getDefaultFormValues(routineSlot))
        close()
      },
    })
  }

  const { latestDeployedRoutineId, latestDeployedRecipeId, toolSlotOptions } = useToolSlotOptions({
    routineId: currentRoutineParent?.working_version_id,
    robotId,
    recipeParentId: currentRoutineParent?.recipe_parent_id,
  })

  const saveChanges = async () => {
    const valid = await trigger()
    if (!valid || !routineSlot) return

    const { recipe_routine_id, ...rest } = getValues()

    const routineSetSlotCommandArg: SetSlotCommandArgs = {
      index: routineSlot.index,
      routine_slot: {
        ...routineSlot,
        routine_parent_id: currentRoutineParent?.id || null,
        // NOTE: we could end up with tool slots that are still not deployed so they would not match with the last deployed routineId.
        // It is ok as it will be "autocorrected" when a new routine version is deployed
        routine_id: latestDeployedRoutineId || currentRoutineParent?.working_version_id || null,
        recipe_id: latestDeployedRecipeId || currentRoutineParent?.recipe_parent.working_version_id || null,
        tool_slots: [],
      },
    }

    // We need to send all the tool_slots for the set_slot command, otherwise they could be removed
    for (const [key, toolParentId] of Object.entries(rest)) {
      const toolSlotIndex = key.split('__')[1]!
      const foundToolSlot = routineSlot.tool_slots.find(toolSlot => toolSlot.index.toString() === toolSlotIndex)
      const typedToolParentId = toolParentId as string | undefined
      const toolIdToSave = toolParentId
        ? toolSlotOptions?.find(tool => tool.parent_id === typedToolParentId)?.id
        : undefined
      if (foundToolSlot) {
        routineSetSlotCommandArg.routine_slot.tool_slots.push({
          index: foundToolSlot.index,
          tool_id: toolIdToSave || null,
          tool_parent_id: typedToolParentId || null,
        })
      }
    }

    const commandRes = await service.atomSendCommand('integration-framework', 'set_slot', robotId, {
      command_args: routineSetSlotCommandArg,
    })

    if (commandRes.type !== 'success' || !commandRes.data.success) {
      return error({ title: ERROR_MSG })
    }

    await onRefresh()
    success({ title: 'Saved', 'data-testid': 'slot-mapping-success' })
  }

  const selectedToolParentIds = Object.values(formToolSlots).filter(
    (toolParentId): toolParentId is string => !!toolParentId,
  )

  return (
    <Card
      className={Styles.slotRight}
      headerClassName={Styles.cardHeader}
      bodyClassName={Styles.cardBody}
      footerClassName={Styles.cardFooter}
      title={`Slot ${routineSlot?.index} Mapping`}
      footer={
        <div className={Styles.actionFooter}>
          <IconButton
            icon={<PrismResetIcon />}
            size="small"
            type="secondary"
            onClick={handleResetSlotConfirm}
            disabled={
              (!routineSlot?.routine_id && !routineSlot?.tool_slots.some(toolSlot => toolSlot.tool_id)) ||
              robotStatus !== 'connected'
            }
            data-testid="communications-restore-button"
          />
          <div className={Styles.buttonsContainer}>
            <Button
              type="secondary"
              size="small"
              onClick={cancelChanges}
              disabled={!isDirty}
              className={Styles.actionButton}
            >
              Cancel
            </Button>
            <Button
              size="small"
              disabled={!isDirty || !routine_parent_id}
              className={`${Styles.actionButton} ${Styles.saveButton}`}
              onClick={saveChanges}
              data-testid="slot-mapping-save-button"
            >
              Save
            </Button>
          </div>
        </div>
      }
    >
      <LeavePagePrompt when={isDirty} />
      <section className={Styles.slotMappingSection}>
        {robotStatus === 'running' && (
          <Alert
            icon={<PrismInfoIcon />}
            description="You cannot edit mappings while an inspection is running"
            className={Styles.runningAlert}
          />
        )}
        {!showEmptyState && routineSlot && (
          <>
            <Token label="Recipe & View" labelClassName={Styles.slotTitle}>
              <Controller
                control={control}
                name="routine_parent_id"
                render={props => (
                  <SearchableSelect
                    key={routineParentsKey}
                    {...props}
                    preventFetch={!routineParentsKey}
                    fetcher={searchValue =>
                      service.getRoutineParents({
                        robot_id: robotId,
                        component_recipe_routine_name: searchValue,
                        is_deleted: false,
                      })
                    }
                    onChange={routineParentId => {
                      for (const slot of routineSlot?.tool_slots || []) {
                        setValue(`toolSlot__${slot.index}`, undefined)
                      }
                      props.onChange(routineParentId)
                    }}
                    missingOptionFetcher={async id => {
                      const res = await service.getRoutineParent(id)
                      return res as SendToApiResponse<RoutineParent>
                    }}
                    getterKey={getterKeys.routineParents(routineParentsKey)}
                    formatter={routineParent =>
                      `${routineParent.component_name} - ${routineParent.recipe_parent.name} - ${routineParent.name}`
                    }
                    placeholder="Select a view"
                    showArrow
                    clearable
                    maxTagCount="responsive"
                    disabled={robotStatus === 'running'}
                    isOptionDisabled={routineParent => {
                      if (routineParent.id === props.value) return false
                      return !!selectedRoutineParentIds?.includes(routineParent.id)
                    }}
                    data-testid="slot-mapping-select-view"
                    data-test="slot-mapping-select-view-name"
                  />
                )}
              />
            </Token>

            {routineSlot?.tool_slots.map((slot: ToolSlot) => (
              <ToolSlotSelect
                key={slot.index}
                control={control}
                toolSlot={slot}
                disabled={!routine_parent_id || robotStatus === 'running'}
                currentRoutineParentTools={toolSlotOptions}
                selectedToolParentIds={selectedToolParentIds}
              />
            ))}
          </>
        )}
        {(showEmptyState || !routineSlot) && <EmptyStateSlots />}
      </section>
    </Card>
  )
}

export default RoutineToolMapping

type defaultVaulesInterface = {
  [key: string]: string | object | null
  routine_parent_id: string | null
}

const getDefaultFormValues = (routineSlot: RoutineSlot | undefined) => {
  // We set a dictionary which contains the routine_parent_id and its value, as well as a key for each tool slot and each value. The tool slots will be indexed by index in the way toolSlot__[index].
  const defaultValues: defaultVaulesInterface = {
    routine_parent_id: routineSlot?.routine_parent_id || null,
  }
  for (const slot of routineSlot?.tool_slots || []) {
    defaultValues[`toolSlot__${slot.index}`] = slot.tool_parent_id
  }
  return defaultValues
}

const ToolSlotSelect = ({
  control,
  toolSlot,
  disabled,
  currentRoutineParentTools,
  selectedToolParentIds,
}: {
  control: Control
  toolSlot: ToolSlot
  disabled: boolean
  currentRoutineParentTools: Tool[] | undefined
  selectedToolParentIds: string[] | undefined
}) => {
  const selectOptions = (value: any) => {
    const initialValue = { value: '', content: 'None' }
    if (!currentRoutineParentTools) return
    return [
      initialValue,
      // TODO: as soon as we allow more than one tool for an AOI this needs to be refactored
      ...currentRoutineParentTools.map(tool => {
        const disabled = value === tool.parent_id ? false : selectedToolParentIds?.includes(tool.parent_id)
        return {
          value: tool.parent_id,
          content: renderToolName(tool),
          disabled: disabled,
          key: tool.parent_id,
          dataTest: 'slot-mapping-select-a-tool-name',
        }
      }),
    ]
  }
  return (
    <Token label={`Slot ${toolSlot.index + 1}`} labelClassName={Styles.slotTitle}>
      <Controller
        control={control}
        name={`toolSlot__${toolSlot.index}`}
        render={({ onChange, value }) => (
          <PrismSelect
            disabled={disabled}
            loading={!currentRoutineParentTools && !disabled}
            onChange={(toolParentId: string) => {
              onChange(toolParentId)
            }}
            placeholder="Select a tool"
            className={Styles.toolSlotSelect}
            popupClassName={Styles.dropdownList}
            value={!currentRoutineParentTools && !disabled ? 'Loading...' : value}
            data-testid="slot-mapping-select-a-tool"
            options={selectOptions(value)}
          />
        )}
      />
    </Token>
  )
}

/**
 * This hook returns the tool options available for the routine slot.
 * It is a combination of the deployed routine and the current working version, prefering the deployed tools.
 *
 * Note: This hook expects that toolset for the given robot are already fetched, eg. by RobotToolsetsFetcher
 */
const useToolSlotOptions = ({
  routineId,
  recipeParentId,
  robotId,
}: {
  routineId: string | null | undefined
  recipeParentId: string | null | undefined
  robotId: string
}) => {
  const toolsets = useData(getterKeys.robotToolsets(robotId))
  const routine = useQuery(routineId ? getterKeys.routine(routineId) : undefined, () => service.getRoutine(routineId!))
    .data?.data

  const latestDeployedRecipe = useMemo(() => {
    if (!recipeParentId || !toolsets) return
    const toolsetValues = Object.values(toolsets)

    return getLatestActiveToolset(toolsetValues.filter(toolset => toolset.recipe.parent_id === recipeParentId))
  }, [recipeParentId, toolsets])

  const latestDeployedRoutineId = useMemo(() => {
    if (!latestDeployedRecipe) return

    return latestDeployedRecipe.recipe.recipe_routines.find(recipeRoutine => recipeRoutine.robot_id === robotId)
      ?.routine_id
  }, [latestDeployedRecipe, robotId])

  const shouldFetchDeployedRoutine = !!latestDeployedRoutineId && !!routineId && latestDeployedRoutineId !== routineId

  const latestDeployedRoutine = useQuery(
    shouldFetchDeployedRoutine ? getterKeys.routine(latestDeployedRoutineId) : undefined,
    () => service.getRoutine(latestDeployedRoutineId!),
  ).data?.data

  const toolsFromRoutineWorkingVersion = useMemo(() => {
    if (!routine) return

    return getAoisAndToolsFromRoutine(routine).tools
  }, [routine])

  const toolsFromLatestDeployedRoutine = useMemo(() => {
    if (!shouldFetchDeployedRoutine) return []

    if (!latestDeployedRoutine) return

    return getAoisAndToolsFromRoutine(latestDeployedRoutine).tools
  }, [latestDeployedRoutine, shouldFetchDeployedRoutine])

  const toolOptionsByParentId = useMemo(() => {
    if (!toolsFromRoutineWorkingVersion || !toolsFromLatestDeployedRoutine) return
    const toReturn: Record<string, Tool> = {}

    // This are our initial options by ToolParentId, we start with the working version
    toolsFromRoutineWorkingVersion.forEach(toolFromWorkingVersion => {
      toReturn[toolFromWorkingVersion.parent_id] = toolFromWorkingVersion
    })

    // If a tool is in the latest deployed routine, we want to set that in the tool slot,
    // so this will override the tool from the routine working version
    toolsFromLatestDeployedRoutine.forEach(toolFromDeployedRoutine => {
      toReturn[toolFromDeployedRoutine.parent_id] = toolFromDeployedRoutine
    })

    return toReturn
  }, [toolsFromLatestDeployedRoutine, toolsFromRoutineWorkingVersion])

  return {
    latestDeployedRoutineId,
    latestDeployedRecipeId: latestDeployedRecipe?.recipe.id,
    toolSlotOptions: toolOptionsByParentId ? Object.values(toolOptionsByParentId) : undefined,
  }
}
