import React, { ReactElement } from 'react'

import ReactPDF, { Document, Link, Page, Text, View } from '@react-pdf/renderer'

import PDFCalendar from './PdfSVG/PdfCalendar'
import PDFLink from './PdfSVG/PdfLink'
import PDFLogo from './PdfSVG/PdfLogo'
import styles from './styles'

interface PrismPdfProps {
  title: string
  date: any
  link: string
  children?: React.ReactNode
  orientation?: 'portrait' | 'landscape'
  tableBodyWrapper?: boolean
  nowString: string
}

/**
 * Renders the base container, header and footer for the PDF components to be rendered.
 * Currently react-pdf supports .png, .jpg and base64 images, but not .svg extension
 *
 * @param title - PDF title to display
 * @param date - Current date to display on PDF
 * @param link - link to use in PDF header
 * @param children - Content to render inside PDF. Should respect the format of @react-pdf/renderer.
 *  Should ideally be one of PdfGallery, PdfTable or PdfDashboard.
 * @param orientation - set the orientation of the page the default value is portrait
 * @param tableBodyWrapper - adds a special style to the body container
 */
const PrismPdf = ({
  title,
  date,
  link,
  children,
  orientation = 'portrait',
  tableBodyWrapper,
  nowString,
}: PrismPdfProps): ReactElement<ReactPDF.Document> => {
  return (
    <Document author="Elementary ML" title={title} creator="Elementary ML" subject="">
      <Page
        size="LETTER"
        style={orientation === 'landscape' ? styles.pageInLandscape : styles.page}
        orientation={orientation}
      >
        {/* Header */}
        <PdfHeader title={title} date={date} link={link} />

        {/* Body */}
        <View style={{ ...styles.bodyWrapper, ...(tableBodyWrapper ? styles.tableBodyWrapper : {}) }}>{children}</View>

        {/* Footer */}
        <PdfFooter nowString={nowString} />
      </Page>
    </Document>
  )
}

export default PrismPdf

const PdfHeader = ({ title, date, link }: Pick<PrismPdfProps, 'title' | 'date' | 'link'>) => {
  return (
    <View style={styles.headerWrapper}>
      <View style={styles.headerLeftBox}>
        <View style={styles.headerTitle}>
          <Text style={styles.documentTitle}>Analyze: {title}</Text>
        </View>
        <View style={styles.headerDescriptionWrapper}>
          <View style={styles.headerIconAndDescriptionLeftContainer}>
            <PDFCalendar />

            <Text style={styles.headerDescriptionDate}>{date}</Text>
          </View>

          <View style={styles.headerIconAndDescriptionRightContainer}>
            <PDFLink />

            <Text style={styles.headerDescriptionLink}>
              <Link src={link} style={styles.headerLink}>
                View Live
              </Link>
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.headerRightBox}>
        <Text>Elementary Report</Text>
      </View>
    </View>
  )
}

const PdfFooter = ({ nowString }: { nowString: string }) => {
  return (
    // fixed renders the component in all wrapped pages
    <View style={styles.footerWrapper} fixed>
      <Text>Generated {nowString}</Text>
      <View style={styles.footerIcon}>
        <PDFLogo />
      </View>

      <View style={styles.urlAndPageNumber}>
        <Text style={styles.urlContainer}>
          <Link src="https://elementaryml.com/" style={styles.footerLink}>
            elementaryml.com
          </Link>
        </Text>
        <Text render={({ pageNumber }) => `0${pageNumber}`} />
      </View>
    </View>
  )
}
