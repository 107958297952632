import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { debounce, Dictionary, keyBy } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { getterKeys, query, service, useQuery } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import DeployedVersion from 'components/DeployedVersion/DeployedVersion'
import { Divider } from 'components/Divider/Divider'
import GridTableHeader from 'components/GridTableHeader/GridTableHeader'
import ImgFallback from 'components/Img/ImgFallback'
import PrismAccordion from 'components/PrismAccordion/PrismAccordion'
import PrismAddButton from 'components/PrismAddBtn/PrismAddBtn'
import { PrismElementaryCube, PrismSearchIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import RecipeNameWithThumbnail from 'components/RecipeNameWithThumbnail/RecipeNameWithThumbnail'
import RobotToolsetsFetcher from 'components/RobotToolsetsFetcher'
import { useData, useOnScreen, useStationDeployedRecipe } from 'hooks'
import { RecipeOptionMenu } from 'pages/RecipesList/RecipesList'
import AddOrEditProductModal from 'pages/StationDetail/Components/EntityModals/AddOrEditProductModal'
import AddOrEditRecipeModal from 'pages/StationDetail/Components/EntityModals/AddOrEditRecipeModal'
import paths from 'paths'
import { Component, StatusCommandRecipe } from 'types'
import {
  appendDataToQueryString,
  getRecipeParentImage,
  getTimeAgoFromDate,
  matchRole,
  pluralize,
  sortByName,
  sortByTimestampKeyFirst,
} from 'utils'

import { ArchivedProducts } from '../Inspect/ArchivedProducts'
import EntityOverflowMenu from '../Inspect/EntityOverflowMenu'
import InspectEmptyState from '../InspectEmptyState/InspectEmptyState'
import { SectionHeader } from '../InspectSites'
import Styles from './InspectProducts.module.scss'

const RECIPE_COLUMNS_TITLES = [
  { title: 'recipe' },
  { title: 'station' },
  { title: 'version' },
  { title: 'edited' },
  { title: 'deployed' },
]

const ProductsList = ({ searchText, products }: { searchText: string | undefined; products: Component[] }) => {
  // we fetch stations on login
  const stations = useData(getterKeys.stations('all-with-robots'))?.results
  const history = useHistory()
  const { state: locationState } = useLocation<{ productIdToExpand?: string }>()
  const productIdToExpand = locationState?.productIdToExpand

  const [filteredProducts, setFilteredProducts] = useState<Component[]>()
  const [productsOpen, setProductsOpen] = useState<Set<string>>(new Set())

  const robotIds = useMemo(() => {
    return stations?.flatMap(station => station.robots.map(robot => robot.id))
  }, [stations])
  const { deployedRecipes } = useStationDeployedRecipe(robotIds || [])
  const deployedRecipesByParentId = useMemo(() => {
    return keyBy(deployedRecipes, recipe => recipe.parent_id)
  }, [deployedRecipes])

  function handleProductOpenState(productId: string, isOpen: boolean) {
    if (!productsOpen) return
    if (isOpen) return setProductsOpen(openProducts => new Set(openProducts.add(productId)))
    return setProductsOpen(openProducts => {
      openProducts.delete(productId)
      return new Set(openProducts)
    })
  }

  const getFilteredProducts = useCallback(
    (searchText: string | undefined) => {
      if (!searchText) {
        const productsWithFilteredRecipes = products
          ?.map(product => ({
            ...product,
            recipe_parents: product.recipe_parents
              .filter(recipeParent => !recipeParent.is_deleted)
              .sort((a, b) => sortByTimestampKeyFirst(a, b, 'updated_at')),
          }))
          .sort(sortByName)
        setFilteredProducts(productsWithFilteredRecipes)

        if (productIdToExpand) setProductsOpen(new Set([productIdToExpand]))

        // We don't want to persist this state after expanding the line, so we need to remove it
        return appendDataToQueryString(history, {}, { productIdToExpand: undefined })
      }

      const lowerCasedSearch = searchText.toLowerCase()
      const matchedProducts: Component[] = []
      const openProducts: string[] = []

      products?.forEach(product => {
        const productName = product.name.toLowerCase()
        const matchedRecipes = product.recipe_parents
          .filter(
            recipeParent => !recipeParent.is_deleted && recipeParent.name.toLowerCase().includes(lowerCasedSearch),
          )
          .sort((a, b) => sortByTimestampKeyFirst(a, b, 'updated_at'))
        const productMatches = productName.includes(lowerCasedSearch)

        if (!matchedRecipes.length && !productMatches) return

        if (productMatches) return matchedProducts.push(product)

        if (matchedRecipes) {
          openProducts.push(product.id)
          return matchedProducts.push({ ...product, recipe_parents: matchedRecipes })
        }
      })
      setProductsOpen(prevProds => new Set([...prevProds, ...openProducts]))
      matchedProducts.sort(sortByName)
      setFilteredProducts(matchedProducts)
    },
    [history, productIdToExpand, products],
  )

  useEffect(() => {
    getFilteredProducts(searchText)
    // eslint-disable-next-line
  }, [products, searchText])

  return (
    <>
      {robotIds?.map(robotId => (
        <RobotToolsetsFetcher key={robotId} robotId={robotId} intervalMs={10 * 1000} />
      ))}

      {filteredProducts?.map(product => (
        <ProductAccordion
          dataTestId={`product-${product.name}`}
          key={product.id}
          product={product}
          deployedRecipesByParentId={deployedRecipesByParentId}
          isOpen={productsOpen.has(product.id)}
          setIsOpen={handleProductOpenState}
        />
      ))}

      {filteredProducts?.length === 0 && searchText && (
        <GenericBlankStateMessage
          dataTestId="empty-results-state"
          description="No results match your search"
          header={<PrismSearchIcon />}
          className={Styles.productsArchivedBlankState}
        />
      )}
    </>
  )
}

/**
 * Render the Product accordion header, it displays an image, a label, optionMenu and an arrow
 *
 * @param product - contains the proudct image and label
 * @param list - displays a counter for the number of recipes in the product
 * @returns
 */
const ProductAccordionHeader = ({ product }: { product: Component }) => {
  const me = useData(getterKeys.me())
  const dispatch = useDispatch()
  const headerRef = useRef<HTMLElement>(null)

  const isOnScreen = useOnScreen(headerRef as unknown as RefObject<HTMLDivElement>)

  return (
    <>
      <figure ref={headerRef} className={Styles.imageContainer}>
        {isOnScreen && product.image ? (
          <ImgFallback loaderType="skeleton" src={product.image} />
        ) : (
          <PrismElementaryCube />
        )}
      </figure>
      <div className={Styles.productTitle}>
        <PrismOverflowTooltip content={product.name} textClassName={Styles.productName} />

        <p className={Styles.productSubtitle}>{`${product.recipe_parents.length} ${pluralize({
          wordCount: product.recipe_parents.length,
          word: 'Recipe',
        })}
        
        `}</p>
      </div>

      {matchRole(me, 'manager') && (
        <EntityOverflowMenu
          entityType="product"
          entity={product}
          onArchive={async () => {
            await query(getterKeys.components('all-unarchived'), () => service.getComponents({ is_deleted: false }), {
              dispatch,
            })
          }}
          renderWithPortal={false}
          className={Styles.overflowMenuIcon}
        />
      )}
    </>
  )
}

/**
 * Renders the Product accordion body
 *
 * @param list -
 * @returns
 */
type RecipeParentWithDeployedRecipe = Component['recipe_parents'][number] & {
  deployedRecipe: StatusCommandRecipe | undefined
}

const RecipeRow = ({
  recipeParent,
  componentId,
  recipeParentsFetcher,
  dataTestId,
}: {
  recipeParent: RecipeParentWithDeployedRecipe
  componentId: string
  recipeParentsFetcher: () => void
  dataTestId?: string
}) => {
  const me = useData(getterKeys.me())
  const history = useHistory()
  const ref = useRef<HTMLLIElement>(null)

  const isOnScreen = useOnScreen(ref as unknown as RefObject<HTMLDivElement>, { delayMs: 0 })

  return (
    <li ref={ref} className={Styles.recipeListItem} data-testid={dataTestId}>
      <Button
        type="default"
        childrenClassName={`${Styles.recipeGridLayout} ${Styles.recipeRowButton}`}
        className={Styles.recipeRow}
        onClick={() => {
          history.push(paths.settingsRecipe(recipeParent.id, 'capture'))
        }}
      >
        <>
          <RecipeNameWithThumbnail
            image={isOnScreen ? getRecipeParentImage(recipeParent, { preferThumbnail: true }) : undefined}
            recipeName={recipeParent.name}
          />

          <PrismOverflowTooltip
            content={recipeParent.station_name}
            className={Styles.recipeStation}
            tooltipPlacement="bottom"
          />

          <p className={Styles.recipeVersion}>v{recipeParent.working_version?.version}</p>
          <p className={Styles.recipeEdit}>{getTimeAgoFromDate(recipeParent.updated_at).text}</p>
          <DeployedVersion
            latestRecipeVersion={recipeParent.working_version?.version}
            latestDeployedVersion={recipeParent.deployedRecipe?.version}
            className={Styles.recipeDeployedVersion}
          />
          {matchRole(me, 'manager') && isOnScreen && (
            <RecipeOptionMenu
              recipeParent={recipeParent}
              refreshRecipes={recipeParentsFetcher}
              iconButtonClassName={Styles.overflowMenuIcon}
              componentId={componentId}
            />
          )}
        </>
      </Button>
    </li>
  )
}

const ProductAccordionBody = ({
  recipeParents,
  recipeParentsFetcher,
  componentId,
  dataTest,
}: {
  recipeParents: RecipeParentWithDeployedRecipe[] | undefined
  recipeParentsFetcher: () => void
  componentId: string
  dataTest?: string
}) => {
  const [showAddRecipeModal, setShowAddRecipeModal] = useState(false)
  return (
    <>
      {recipeParents?.length === 0 && (
        <PrismAddButton description="Start by adding a Recipe" onClick={() => setShowAddRecipeModal(true)} />
      )}

      {!!recipeParents?.length && (
        <>
          <li>
            <GridTableHeader
              columns={RECIPE_COLUMNS_TITLES}
              size="small"
              className={`${Styles.recipeGridLayout} ${Styles.recipeTitles}`}
            />
          </li>
          {recipeParents.map(recipeParent => (
            <RecipeRow
              key={recipeParent.id}
              dataTestId={`${dataTest}-${recipeParent.name}-recipe`}
              recipeParent={recipeParent}
              recipeParentsFetcher={recipeParentsFetcher}
              componentId={componentId}
            />
          ))}
        </>
      )}
      {showAddRecipeModal && (
        <AddOrEditRecipeModal
          isEditMode={false}
          defaultProductId={componentId}
          onClose={() => setShowAddRecipeModal(false)}
        />
      )}
    </>
  )
}

/**
 * Renders a Product According, when open displays a recipe list
 *
 * @param label - The line label or name
 */
const ProductAccordion = ({
  product,
  deployedRecipesByParentId,
  isOpen,
  setIsOpen,
  dataTestId,
}: {
  product: Component
  deployedRecipesByParentId: Dictionary<StatusCommandRecipe>
  isOpen: boolean
  dataTestId?: string
  setIsOpen: (productId: string, open: boolean) => void
}) => {
  const location = useLocation<{ productIdToExpand?: string }>()
  const productIdToExpand = location.state?.productIdToExpand
  const dispatch = useDispatch()

  const [scrolled, setScrolled] = useState(false)

  const ref = useRef<HTMLLIElement>(null)

  const recipeParentsWithLastDeployedVersion = useMemo(() => {
    return product.recipe_parents?.map(recipeParent => {
      const deployedRecipe = deployedRecipesByParentId[recipeParent.id]
      return { ...recipeParent, deployedRecipe }
    })
  }, [deployedRecipesByParentId, product.recipe_parents])

  useEffect(() => {
    if (scrolled) return
    if (productIdToExpand === product.id) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
    setScrolled(true)
  }, [product.id, productIdToExpand, scrolled])

  return (
    <li ref={ref}>
      <PrismAccordion
        dataTestId={dataTestId}
        header={<ProductAccordionHeader product={product} />}
        body={
          <ProductAccordionBody
            dataTest={product.name}
            recipeParentsFetcher={async () => {
              await query(getterKeys.components('all-unarchived'), () => service.getComponents({ is_deleted: false }), {
                dispatch,
              })
            }}
            recipeParents={recipeParentsWithLastDeployedVersion}
            componentId={product.id}
          />
        }
        isOpen={isOpen}
        setIsOpen={open => setIsOpen(product.id, open)}
        headerClassName={`${Styles.productAccordionGrid} ${Styles.productAccordionHeader}`}
        iconContainerClassName={Styles.productAccordionArrowIcon}
        bodyClassName={Styles.productAccordionBody}
      />
    </li>
  )
}

/**
 * Renders the Products library main view
 * @returns
 */
const InspectProducts = ({ showArchive }: { showArchive: boolean }) => {
  const [searchText, setSearchText] = useState('')
  const [showCreateProductModal, setShowCreateProductModal] = useState(false)
  const me = useData(getterKeys.me())

  const products = useQuery(
    !showArchive ? getterKeys.components('all-unarchived') : undefined,
    !showArchive ? () => service.getComponents({ is_deleted: false }) : undefined,
  ).data?.data.results
  const archivedProducts = useQuery(
    showArchive ? getterKeys.components('archived') : undefined,
    // We need all components to check for archived recipes
    showArchive ? () => service.getComponents() : undefined,
  ).data?.data.results

  const isLoading = showArchive ? !archivedProducts : !products

  const setSearchTextDebounced = useMemo(() => {
    return debounce((searchValue: string) => {
      setSearchText(searchValue)
    }, 500)
  }, [])

  const productsMainDetails = () => {
    if (isLoading) return <PrismLoader />

    if (products?.length === 0)
      return (
        <InspectEmptyState
          mode="Product"
          canAddEntity={matchRole(me, 'manager')}
          onClick={() => setShowCreateProductModal(true)}
        />
      )

    return (
      <>
        <SectionHeader
          title={showArchive ? 'Archive' : 'Product Library'}
          icons={
            <>
              <PrismSearchInput
                inputDataTestId="product-search-input"
                buttonDataTestId="product-search-button"
                onSearchButtonClick={() => setSearchTextDebounced('')}
                onInputChange={e => setSearchTextDebounced(e.target.value)}
                addAnimation
                className={Styles.productsSearchField}
              />
            </>
          }
        />

        <Divider />
        <ul className={`${Styles.productsList} ${showArchive ? Styles.productsArchiveView : ''}`}>
          {!showArchive && products && products.length > 0 && (
            <ProductsList searchText={searchText} products={products} />
          )}

          {showArchive && archivedProducts && <ArchivedProducts searchText={searchText} products={archivedProducts} />}
        </ul>
      </>
    )
  }

  return (
    <div className={`${Styles.inspectProductsWrapper} ${products?.length === 0 ? Styles.removeTopPadding : ''}`}>
      {productsMainDetails()}

      {showCreateProductModal && (
        <AddOrEditProductModal isEditMode={false} onClose={() => setShowCreateProductModal(false)} />
      )}
    </div>
  )
}

export default InspectProducts
