import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { FixedSizeGrid, GridChildComponentProps, GridOnItemsRenderedProps } from 'react-window'
import { GridElementScrollerChildrenProps, ReactWindowElementScroller } from 'react-window-element-scroller'

import { getterKeys, service } from 'api'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { LabelCard } from 'components/LabelCard/LabelCard'
import LabelsList from 'components/LabelList/LabelsList'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import { Tag } from 'components/Tag/Tag'
import {
  useAllToolLabels,
  useConnectionStatus,
  useContainerDimensions,
  useData,
  useDateTimePreferences,
  useGridDimensions,
  useQueryParams,
  useThrottleOrDebounce,
} from 'hooks'
import { MemoDetailModal } from 'pages/ItemDetail/DetailModal'
import * as Actions from 'rdx/actions'
import { AreaOfInterestExpanded, Inspection, Outcome, Tool, ToolLabel, ToolResult } from 'types'
import {
  appendItemOrToolResultIdPictureIdOrLastSelectedToQs,
  getImageFromPicture,
  getterAddOrUpdateResultsAndSort,
  isToolUntrained,
  sortByLabelKind,
  sortByNewestFirst,
  sortBySeverity,
  wasToolResultMuted,
} from 'utils'
import { MAX_RECENT_RESULTS } from 'utils/constants'

import { CONTENT_BEFORE_LATEST_CLASSIFICATIONS_HEIGHT, TimeFilters } from './StationDetailTools'
import Styles from './StationDetailTools.module.scss'
import { ToolsEmptyStateCard } from './ToolStationBlankState'

const PAGINATION_THRESHOLD = 2
const GRID_GAP = 16
const TOOl_TAB_CONTAINER_LEFT_PADDING = 20
const GRID_VIEW_CARD_MIN_WIDTH = 202
const GRID_VIEW_CARD_HEIGHT = 230

interface Props {
  thresholdSliderIsOpen: boolean
  aoi: AreaOfInterestExpanded
  inspection: Inspection | undefined
  timeFilters: TimeFilters | undefined
  outerContainerRef: React.RefObject<HTMLDivElement>
  setShowNewUnitsButton: React.Dispatch<React.SetStateAction<boolean>>
  isLiveModeRef: React.MutableRefObject<boolean>
  liveModeWaitingForToolResult: React.MutableRefObject<boolean>
  enableLiveMode: () => any
  disableLiveMode: () => any
  scrollTo: (options: ScrollToOptions) => any
  isHistoricBatch: boolean
  isAutoScrollingRef: React.MutableRefObject<boolean>
  recentToolResults: ToolResult[] | undefined
  filteredRecentToolResults: ToolResult[] | undefined
  activePredictionLabelIds: string[] | undefined
  shouldResetLiveMode: React.MutableRefObject<boolean>
}

/**
 * This renders the lower part of the main section of the Tools tab in Station Detail,
 * where toolResults are listed with their outcome and time created.
 *
 * It fetches toolResults filtered by props and has pagination.
 *
 * @param thresholdSliderIsOpen - Whether the threshold slider modal is open. Necessary to prevent rendering of detail modal, as there can be conflicting toolResult lists
 * @param aoi - Selected aoi, filter toolResults by this aoi
 * @param inspectionId - Inspection id to filter toolResults by
 * @param timeFilters - Time filters to filter toolResults by
 * @param outerContainerRef - Container in which this component lives, required for pagination logic, since it's based off scroll position of this container
 * @param setShowNewUnitsButton - Sets whether to show the "Live Results" button at the top of this component
 * @param liveModeWaitingForToolResult - Tells us if we're at the top of the list of toolResults.
 *    If we are and a new toolResult comes in via websocket, then toggle live mode on.
 * @param enableLiveMode - Function that enables live mode and toggles related state/refs
 * @param disableLiveMode - Function that disables live mode and toggles related state/refs
 * @param scrollTo - Function that scrolls to position of the outer container ref
 * @param isAutoScrollingRef - Ref that specifies whether we are currently scrolling programatically
 * @param recentToolResults - List of recent toolResults, which came in via websockets
 * @param filteredRecentToolResults  - List of recent toolResults, but filtered by pass/fail
 * @param currentToolLabels - List of tool labels for the current tool
 * @param activePredictionLabelIds - List of which prediction labels are active
 */
export default function StationDetailToolsLatestToolResults({
  thresholdSliderIsOpen,
  aoi,
  inspection,
  timeFilters,
  outerContainerRef,
  setShowNewUnitsButton,
  isLiveModeRef,
  liveModeWaitingForToolResult,
  enableLiveMode,
  disableLiveMode,
  scrollTo,
  isHistoricBatch,
  isAutoScrollingRef,
  recentToolResults,
  filteredRecentToolResults,
  activePredictionLabelIds,
  shouldResetLiveMode,
}: Props) {
  const history = useHistory()
  const dispatch = useDispatch()

  const [params] = useQueryParams()
  const { detail_item_id, detail_tool_result_id } = params

  const { allToolLabels } = useAllToolLabels()
  const connectionStatus = useConnectionStatus()

  const routines = useData(inspection?.id ? getterKeys.inspectionRoutines(inspection.id) : undefined)?.results

  const isFetchingMoreRef = useRef(false)

  const gridRef = useRef<FixedSizeGrid>(null)
  const outerRef = useRef<HTMLDivElement>(null)
  const hasReachedLiveResultsRef = useRef(true)
  const scrollPositionRef = useRef(0)
  const initialItemsFetchedRef = useRef(false)

  const containerDimensions = useContainerDimensions(outerContainerRef)
  const inspectionIsLive = !inspection?.ended_at

  const joinedPredictionIds = activePredictionLabelIds?.join()

  const queryParams = useMemo(
    () => ({
      inspection_id: inspection?.id,
      start: timeFilters?.start ? moment.unix(timeFilters.start).format() : undefined,
      end: timeFilters?.end ? moment.unix(timeFilters.end).format() : undefined,
      prediction_label_id__in: joinedPredictionIds,
      aoi_parent_id: aoi.parent?.id,
      use_primary_db: inspectionIsLive,
    }),
    [inspection?.id, timeFilters?.start, timeFilters?.end, joinedPredictionIds, aoi?.parent?.id, inspectionIsLive],
  )

  const refetchKey = useMemo(() => {
    const paramsKey = Object.keys(queryParams)
      .sort()
      .map(key => `${key}=${queryParams[key as keyof typeof queryParams]}`)
      .join()
    return `${paramsKey}${connectionStatus}`
  }, [connectionStatus, queryParams])

  const toolsToolResultsRes = useData(getterKeys.stationDetailToolsLatestToolResults(aoi.id))
  useEffect(() => {
    if (!aoi.id || activePredictionLabelIds === undefined) return

    let cancelled = false

    const fetchToolResults = async () => {
      const res = await service.getToolResults(queryParams)

      // If canceled is set to true before the service call ends, we can ignore it, as it means another effect already run.
      if (cancelled) return

      if (res?.type === 'success') {
        dispatch(Actions.getterSave({ key: getterKeys.stationDetailToolsLatestToolResults(aoi.id), data: res }))
        if (isLiveModeRef.current && (timeFilters || params.detail_item_id)) {
          disableLiveMode()
        }
      }
      initialItemsFetchedRef.current = true
    }

    fetchToolResults()

    return () => {
      cancelled = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchKey])

  const displayToolResults = useMemo(() => toolsToolResultsRes?.results, [toolsToolResultsRes])

  // We need to use this hook because we have to build the grid ourselves, since react-window needs rowHeight and columnWidth to render the grid
  const { rowCount, columnCount, columnWidth, rowHeight } = useGridDimensions(
    (containerDimensions?.width || 0) - TOOl_TAB_CONTAINER_LEFT_PADDING,
    displayToolResults?.length,
    { gridGap: GRID_GAP, minWidth: GRID_VIEW_CARD_MIN_WIDTH, elementRowHeight: GRID_VIEW_CARD_HEIGHT },
  )

  useEffect(() => {
    // If user is in live mode and new toolResults come in via websockets, then we add those to the start of the list

    if (!inspection?.id || !displayToolResults || !filteredRecentToolResults || !initialItemsFetchedRef.current) return

    if (isLiveModeRef.current && filteredRecentToolResults.length) {
      setShowNewUnitsButton(false)

      dispatch(
        Actions.getterUpdate({
          key: getterKeys.stationDetailToolsLatestToolResults(aoi.id),
          updater: prevRes =>
            getterAddOrUpdateResultsAndSort(prevRes, {
              results: filteredRecentToolResults,
              sliceEndIdx: MAX_RECENT_RESULTS,
              sort: sortByNewestFirst,
            }),
        }),
      )
    }
    // eslint-disable-next-line
  }, [filteredRecentToolResults])

  useEffect(
    () => {
      // liveModeWaitingForToolResult is true when user has scrolled down, but there have been no new websocket results
      // If new toolResults come in via websocket, display the "live results" button so the user can go back to live mode
      // Check filtered toolResults length, in case user has show pass toggled off and a new pass comes in
      if (!filteredRecentToolResults?.[0] || isHistoricBatch) return

      if (liveModeWaitingForToolResult.current) {
        setShowNewUnitsButton(true)
        liveModeWaitingForToolResult.current = false
      }
    },
    // eslint-disable-next-line
    [recentToolResults?.[0]?.id],
  )

  useEffect(() => {
    if (outerContainerRef.current) {
      const listElement = outerContainerRef.current

      const scrollHandler = () => {
        const scrollPosition = listElement.scrollTop
        const scrollingDown = scrollPosition > scrollPositionRef.current
        scrollPositionRef.current = scrollPosition

        // When we're scrolled down, set live mode to false, unless we are scrolling up due to click on "new items" button
        if (scrollPosition > rowHeight + CONTENT_BEFORE_LATEST_CLASSIFICATIONS_HEIGHT && !isAutoScrollingRef.current) {
          disableLiveMode()
        }

        if (
          !timeFilters &&
          scrollPosition < rowHeight + CONTENT_BEFORE_LATEST_CLASSIFICATIONS_HEIGHT &&
          hasReachedLiveResultsRef.current &&
          !isAutoScrollingRef.current &&
          !scrollingDown &&
          !isHistoricBatch
        ) {
          enableLiveMode()
        }
      }
      listElement.addEventListener('scroll', scrollHandler)
      return () => {
        listElement.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [disableLiveMode, enableLiveMode, isAutoScrollingRef, isHistoricBatch, outerContainerRef, rowHeight, timeFilters])

  // Disable live mode through this useEffect instead of the card onClick, as this also covers loading the page through a link or reloading
  useEffect(() => {
    if (isLiveModeRef.current && params.detail_item_id) {
      disableLiveMode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.detail_item_id])

  /** This function fetches the older results when a user scrolls down */
  const fetchMoreResults = useCallback(
    async (direction: 'next' | 'previous') => {
      if (!displayToolResults) return

      let toolResultToSeek: ToolResult | undefined

      if (direction === 'next') {
        toolResultToSeek = displayToolResults[displayToolResults.length - 1]
      } else {
        toolResultToSeek = displayToolResults[0]
      }

      if (!inspection?.id || !toolResultToSeek || isFetchingMoreRef.current || isAutoScrollingRef.current) return

      isFetchingMoreRef.current = true

      let toolResultsToAdd: ToolResult[] = []
      let foundInWsData: boolean = false

      // Fetch older toolResults from the websocket
      if (filteredRecentToolResults) {
        const itemInRecentToolResultsIdx = filteredRecentToolResults.findIndex(tool => tool.id === toolResultToSeek?.id)

        if (itemInRecentToolResultsIdx > -1) {
          // If we found the toolResult in the WS results and there's more data,
          // get 100 more toolResults from that list (may be less)

          if (direction === 'next' && itemInRecentToolResultsIdx < filteredRecentToolResults.length - 1) {
            toolResultsToAdd = filteredRecentToolResults.slice(
              itemInRecentToolResultsIdx + 1,
              itemInRecentToolResultsIdx + 1 + 100,
            )
            foundInWsData = true
          } else if (direction === 'previous' && itemInRecentToolResultsIdx > 0) {
            toolResultsToAdd = filteredRecentToolResults.slice(
              Math.max(0, itemInRecentToolResultsIdx - 100),
              itemInRecentToolResultsIdx,
            )
            foundInWsData = true
          }
        }
      }

      // If not found in the WS results,
      // fetch newer toolResults from DB
      if (!foundInWsData) {
        let reqParams: { inspection_id?: string; end?: string; start?: string; ordering?: string } = {
          inspection_id: inspection.id,
        }

        if (direction === 'next') {
          // Request older results, from newest to oldest
          reqParams = { ...queryParams, end: toolResultToSeek.created_at }
        } else {
          // Request newer results, starting from oldest to newest
          reqParams = { ...queryParams, start: toolResultToSeek.created_at, ordering: 'created_at' }
        }
        const res = await service.getToolResults(reqParams)

        if (res?.type === 'success') {
          toolResultsToAdd = res.data.results
        } else {
          // If something went wrong, set the loading ref to false so we can try again
          isFetchingMoreRef.current = false
        }
      }
      const numItemsFetched = toolResultsToAdd.length

      if (direction === 'previous' && toolResultsToAdd.length < 100) {
        // We have reached the top of the stored results, which means any new results are live,
        // Set this flag so that if the user scrolls up we then turn on live mode.
        hasReachedLiveResultsRef.current = true
      } else if (direction === 'next' && numItemsFetched + (displayToolResults.length || 0) > MAX_RECENT_RESULTS) {
        hasReachedLiveResultsRef.current = false
      }

      // Adjust user's scroll position so there is no shift of the cards the user is seeing
      const scrollPosition = scrollPositionRef.current

      if (numItemsFetched > 0) {
        if (direction === 'next') {
          // If items do not exceed MAX_RECENT_RESULTS, don't scroll, since cards will just be appended to list
          // If they do exceed it, then we remove numItemsFetched from the top of the list, so scroll up by that amount of items
          // so that user is seeing the same card after this update occurs

          if (
            (numItemsFetched !== 1 || toolResultsToAdd[0]?.id !== toolResultToSeek.id) && // If numItemsFetched is 1, we already have this item in our list
            numItemsFetched + (displayToolResults.length || 0) > MAX_RECENT_RESULTS
          ) {
            const itemsRemoved = numItemsFetched + displayToolResults.length - MAX_RECENT_RESULTS
            const rowsRemoved = itemsRemoved / columnCount

            const newScroll = CONTENT_BEFORE_LATEST_CLASSIFICATIONS_HEIGHT + scrollPosition - rowsRemoved * rowHeight

            scrollTo({ top: newScroll })
          }
        } else if (numItemsFetched !== 1 || toolResultsToAdd[0]?.id !== toolResultToSeek.id) {
          scrollTo({
            top: scrollPosition + Math.floor(numItemsFetched / columnCount) * rowHeight,
          })
        }

        // Update our current list with the new items, capping items to maxTimelineToolResults
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.stationDetailToolsLatestToolResults(aoi.id),
            updater: prevRes =>
              getterAddOrUpdateResultsAndSort(prevRes, {
                results: toolResultsToAdd,
                sort: sortByNewestFirst,
                ...(direction === 'next'
                  ? { sliceStartIdx: -MAX_RECENT_RESULTS }
                  : { sliceStartIdx: 0, sliceEndIdx: MAX_RECENT_RESULTS }),
              }),
          }),
        )
      }

      isFetchingMoreRef.current = false
    },
    [
      aoi.id,
      columnCount,
      dispatch,
      displayToolResults,
      filteredRecentToolResults,
      inspection?.id,
      isAutoScrollingRef,
      queryParams,
      rowHeight,
      scrollTo,
    ],
  )

  const throttledFetchMoreResults = useThrottleOrDebounce('throttle', fetchMoreResults, 500)

  const handleCardClick = useCallback(
    (itemId: string | undefined, toolResultId: string) => {
      if (itemId) {
        appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, { itemId, toolResultId })
      }
    },
    [history],
  )

  const handleItemsRendered = ({
    visibleRowStartIndex: visibleStartIndex,
    visibleRowStopIndex: visibleStopIndex,
  }: GridOnItemsRenderedProps) => {
    if (!displayToolResults) return

    // Handles virtualized list scrolling. Fetches more data
    if (isFetchingMoreRef.current) return
    if (visibleStopIndex + PAGINATION_THRESHOLD >= rowCount) {
      throttledFetchMoreResults('next')
    }

    if (visibleStartIndex - PAGINATION_THRESHOLD <= 0 && !hasReachedLiveResultsRef.current) {
      throttledFetchMoreResults('previous')
    }
  }

  const handleRefresh = useCallback(
    (toolResultUpdated: ToolResult) => {
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.stationDetailToolsLatestToolResults(aoi.id),
          updater: prevRes => {
            if (prevRes) {
              const newRes = prevRes.data.results.map(toolResult =>
                toolResultUpdated.id === toolResult.id
                  ? {
                      ...toolResult,
                      calculated_outcome: toolResultUpdated.calculated_outcome,
                      active_user_label_set: toolResultUpdated.active_user_label_set,
                    }
                  : toolResult,
              )

              return { ...prevRes, data: { ...prevRes.data, results: newRes } }
            }
          },
        }),
      )
    },
    [aoi.id, dispatch],
  )

  const showDetailModal =
    (detail_item_id || detail_tool_result_id) && !thresholdSliderIsOpen && displayToolResults && routines

  const handleDetailModalClose = () => {
    if (shouldResetLiveMode.current) {
      enableLiveMode()
    }
  }

  if (!inspection?.id || !aoi) return null

  if (!displayToolResults)
    return (
      <div className={`${Styles.toolCardsContainer} ${Styles.blankLabelContainer}`}>
        {Array(6)
          .fill(undefined)
          .map((_, i) => (
            <ToolsEmptyStateCard key={i} loader />
          ))}
      </div>
    )

  return (
    <>
      {displayToolResults.length === 0 && (
        <div className={Styles.toolsGridBlankState}>No predictions match your filters</div>
      )}
      {displayToolResults.length !== 0 && (
        <div className={Styles.gridContainer}>
          {displayToolResults && (
            <ReactWindowElementScroller
              type="grid"
              scrollerElementRef={outerContainerRef}
              gridRef={gridRef}
              outerRef={outerRef}
              childrenStyle={{ height: 'auto' }}
            >
              {({ style, onScroll }: GridElementScrollerChildrenProps) => (
                <FixedSizeGrid
                  ref={gridRef}
                  outerRef={outerRef}
                  width={containerDimensions?.width || 0}
                  height={containerDimensions?.height || 0}
                  rowCount={rowCount}
                  columnCount={columnCount}
                  rowHeight={rowHeight}
                  columnWidth={columnWidth}
                  className={Styles.virtualizedGridContainer}
                  itemData={{
                    displayToolResults,
                    aoi,
                    handleCardClick,
                    columnCount,
                    allToolLabels,
                  }}
                  style={style}
                  onScroll={onScroll}
                  onItemsRendered={handleItemsRendered}
                >
                  {CardRenderer}
                </FixedSizeGrid>
              )}
            </ReactWindowElementScroller>
          )}
        </div>
      )}

      {showDetailModal && (
        <MemoDetailModal
          toolResultsInitialRes={toolsToolResultsRes}
          onRefreshToolResult={handleRefresh}
          onClose={handleDetailModalClose}
        />
      )}
    </>
  )
}

const CardRenderer = React.memo(
  ({
    rowIndex,
    columnIndex,
    style,
    data,
  }: GridChildComponentProps<{
    displayToolResults: ToolResult[]
    aoi: AreaOfInterestExpanded
    handleCardClick: (itemId: string | undefined, toolResultId: string) => any
    columnCount: number
    allToolLabels: ToolLabel[] | undefined
  }>) => {
    const { displayToolResults, aoi, handleCardClick, columnCount, allToolLabels } = data
    const listIndex = rowIndex * columnCount + columnIndex
    const toolResult = displayToolResults[listIndex]
    const tool = aoi.tools[0]
    if (!toolResult || !tool) return null

    return (
      <MemoizedToolResultCard
        aoi={aoi}
        // TODO: if we ever move to more than one tool per AOI this needs to be refactored
        tool={tool}
        toolResult={toolResult}
        onCardClick={handleCardClick}
        style={style}
        data-test={'station-detail-tools-classifications-cards'}
        allToolLabels={allToolLabels}
      />
    )
  },
)

interface ToolResultCardProps {
  toolResult: ToolResult
  tool: Tool
  aoi: AreaOfInterestExpanded
  onCardClick: (itemId: string | undefined, toolResultId: string) => any
  style: React.CSSProperties
  'data-test': string
  allToolLabels: ToolLabel[] | undefined
}

const UnmemoizedToolResultCard = ({
  toolResult,
  tool,
  aoi,
  onCardClick,
  style,
  'data-test': dataTest,
  allToolLabels,
}: ToolResultCardProps) => {
  const { timeZone, timeWithSecondsFormat } = useDateTimePreferences()
  const untrained = isToolUntrained(tool)
  const [params] = useQueryParams()
  const picture = getImageFromPicture(toolResult.picture)

  // The overlay variable is used to display the tag and greyscale classifications when necessary
  let overlay: JSX.Element | undefined = undefined
  if (wasToolResultMuted(toolResult))
    overlay = (
      <Tag className={Styles.mutedTag} data-test="classification-muted">
        muted
      </Tag>
    )
  if (untrained && toolResult.calculated_outcome === 'error') overlay = <Tag className={Styles.mutedTag}>error</Tag>

  const calculatedLabelIds = useMemo(
    () => toolResult.active_user_label_set?.tool_labels || toolResult.prediction_labels,
    [toolResult.active_user_label_set?.tool_labels, toolResult.prediction_labels],
  )

  const foundLabels = useMemo(() => {
    const filteredLabels = allToolLabels ? allToolLabels.filter(lbl => calculatedLabelIds.includes(lbl.id)) : []
    const sortedLabelsByKind = filteredLabels.sort(sortByLabelKind)
    return sortBySeverity(sortedLabelsByKind)
  }, [allToolLabels, calculatedLabelIds])

  let statusToShow: Outcome = 'unknown'
  if (toolResult.calculated_outcome === 'fail') statusToShow = 'fail'
  if (toolResult.calculated_outcome === 'pass') statusToShow = 'pass'

  const renderLabel = () => {
    return (
      <LabelsList
        labels={foundLabels}
        className={Styles.calculatedLabelsContainer}
        toolParentId={tool.parent_id}
        toolSpecificationName={tool.specification_name}
      />
    )
  }

  return (
    <div
      className={Styles.toolCardsContainerChild}
      style={style}
      data-test={dataTest}
      data-test-attribute={statusToShow}
    >
      <LabelCard
        type="ghost4"
        outcome={<PrismOutcome icon={statusToShow} variant="dark" />}
        timeStamp={moment(toolResult.created_at).tz(timeZone).format(timeWithSecondsFormat)}
        active={toolResult.id === params.lastSelectedId}
        image={
          !!picture ? (
            <div className={Styles.imageCloseUpContainer}>
              <ImageCloseUp
                loaderType="skeleton"
                src={picture}
                thumbnailSrc={toolResult.picture.image_thumbnail}
                region={aoi}
                maskingRectangleFill="#111111"
                data-testid="station-detail-tools-classifications-cards-feed"
                useCache={false}
              />
            </div>
          ) : (
            <PrismElementaryCube addBackground />
          )
        }
        label={renderLabel()}
        overlay={overlay}
        imageClassName={overlay ? Styles.mutedTagContainer : undefined}
        onMouseDown={() => onCardClick(toolResult.item?.id, toolResult.id)}
      />
    </div>
  )
}

const MemoizedToolResultCard = React.memo(UnmemoizedToolResultCard)
