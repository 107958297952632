import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { backendErrorCodes, getterKeys, query, service, useQuery } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { IconButton } from 'components/IconButton/IconButton'
import ImgFallback from 'components/Img/ImgFallback'
import LabelInfo from 'components/LabelInfo/LabelInfo'
import { PrismArchiveIcon, PrismElementaryCube, PrismSearchIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal, ModalFooter, ModalHeader, PrismModal } from 'components/PrismModal/PrismModal'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import { PrismTabMenu } from 'components/PrismTab/PrismTab'
import { useAllToolLabels } from 'hooks'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { ToolLabel } from 'types'
import {
  getDisplaySeverity,
  getLabelName,
  getToolLabelImagesToShow,
  replaceAll,
  titleCase,
  uploadToolLabelUserImages,
} from 'utils'
import { DELETED_LABELS_GETTER_KEY } from 'utils/constants'

import { LabelFormFields } from './AddLabelModal'
import { updateReduxSingleToolLabel } from './LabelingScreen'
import Styles from './LabelingScreen.module.scss'
import ToolLabelDetail from './ToolLabelDetail'
import ToolLabelForm from './ToolLabelForm'

interface LabelGlossaryModalProps {
  onClose: () => any
  initialSelectedToolLabel?: ToolLabel
  toolParentId?: string
}

/**
 * Renders the label glossary modal
 *
 * @param toolSpecificationName - The current tool specification name
 * @param toolParentId - The current tool parent id
 * @param onClose - a function that closes the modal
 * @param initialSelectedToolLabel - the initial selected tool label
 */
export const LabelGlossaryModal = ({ toolParentId, onClose, initialSelectedToolLabel }: LabelGlossaryModalProps) => {
  const dispatch = useDispatch()
  const [selectedToolLabel, setSelectedToolLabel] = useState(initialSelectedToolLabel)
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const {
    control,
    getValues,
    trigger,
    watch,
    errors,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: getDefaultFormValues(selectedToolLabel),
    mode: 'onChange',
  })

  const selectedSeverity = watch('severity')

  const resetForm = useCallback(
    (toolLabel?: ToolLabel) => {
      reset(getDefaultFormValues(toolLabel))
    },
    [reset],
  )

  useEffect(() => {
    resetForm(selectedToolLabel)
  }, [resetForm, selectedToolLabel])

  const onSaveClick = async () => {
    const valid = await trigger()
    if (!valid || !selectedToolLabel) return

    const { description, rootCauses, correctiveActions, value, user_images } = getValues()
    if (!value) return

    const userImages = await uploadToolLabelUserImages(user_images)

    const editLabelRes = await service.patchToolLabel(selectedToolLabel.id, {
      description,
      root_cause: rootCauses,
      corrective_actions: correctiveActions,
      value,
      user_images: userImages,
    })

    if (editLabelRes.type === 'error' && editLabelRes.data.code === backendErrorCodes.labelAlreadyExists) {
      return error({ title: 'The label already exists', 'data-testid': 'label-glossary-modal-edit-label-error' })
    }

    if (editLabelRes.type !== 'success')
      return error({ title: 'Failed to update label', 'data-testid': 'label-glossary-modal-update-label-error' })

    success({ title: 'Label updated', 'data-testid': 'label-glossary-modal-edit-label-success' })

    updateReduxSingleToolLabel({ toolLabel: editLabelRes.data, labelsGetterKey: toolParentId, dispatch })

    setSelectedToolLabel({ ...selectedToolLabel, ...editLabelRes.data })
  }

  const handleArchiveLabel = async (archive: boolean = true) => {
    if (!selectedToolLabel) return
    const archiveLabelRes = await service.patchToolLabel(selectedToolLabel.id, { is_deleted: archive })

    if (archiveLabelRes.type !== 'success') return error({ title: 'Failed to archive label' })

    if (archive) {
      addLabelToGetter({ toolLabel: archiveLabelRes.data, labelsGetterKey: DELETED_LABELS_GETTER_KEY, dispatch })
    } else {
      removeLabelFromGetter({ toolLabel: archiveLabelRes.data, labelsGetterKey: DELETED_LABELS_GETTER_KEY, dispatch })
    }

    updateReduxSingleToolLabel({ toolLabel: archiveLabelRes.data, dispatch })

    if (toolParentId)
      await query(getterKeys.toolLabels(toolParentId), () => service.getToolLabels({ tool_parent_id: toolParentId }), {
        dispatch,
      })

    success({
      title: `Label ${archive ? 'archived' : 'unarchived'}`,
      'data-testid': 'label-glossary-modal-archive-success',
    })

    setSelectedToolLabel(tl => {
      if (!tl) return
      return {
        ...tl,
        is_deleted: archive,
      }
    })
  }

  return (
    <>
      {showArchiveModal && (
        <Modal
          id="glossary-archive-label"
          data-testid="label-glossary-modal-archive-confirm"
          header="Are you sure?"
          onClose={() => setShowArchiveModal(false)}
          okText="Archive"
          size="small"
          onOk={async () => {
            await handleArchiveLabel()
            setShowArchiveModal(false)
          }}
        >
          <p>Archive the label "{selectedToolLabel?.value}"?</p>
          <br />
          <p>This will hide it as an option for labeling. It won't remove it from any images.</p>
        </Modal>
      )}
      <PrismModal
        id="label-glossary"
        className={`${Styles.labelGlossaryModal} ${
          selectedToolLabel?.kind === 'custom' ? '' : Styles.labelGlossaryLists
        }`}
        onClose={onClose}
        size="largeSimpleForm"
        data-testid="label-glossary-modal"
      >
        <ModalHeader onClose={onClose} className={Styles.modalHeader}>
          <Breadcrumbs
            crumbs={[
              {
                label: 'Label Glossary',
                'data-testid': 'label-glossary-modal-breadcrumb',
                onClick: () => {
                  setSelectedToolLabel(undefined)
                },
              },
              ...(selectedToolLabel ? [{ label: titleCase(getLabelName(selectedToolLabel)) }] : []),
            ]}
            className={Styles.modalBreadcrumbs}
          />
          {selectedToolLabel?.kind === 'custom' && !selectedToolLabel.is_deleted && (
            <IconButton
              icon={<PrismArchiveIcon />}
              type="tertiary"
              size="xsmall"
              onClick={() => setShowArchiveModal(true)}
              data-testid="label-glossary-modal-archive-label"
            />
          )}
        </ModalHeader>

        <div className={`${Styles.labelGlossaryModalBody} ${Shared.verticalChildrenGap24}`}>
          {!selectedToolLabel && <LabelGlossaryList setSelectedToolLabel={setSelectedToolLabel} />}

          {selectedToolLabel?.kind === 'custom' && (
            <ToolLabelForm
              mode="edit"
              control={control}
              errors={errors}
              specificationName={undefined}
              selectedSeverity={selectedSeverity}
              handleArchive={handleArchiveLabel}
              toolLabel={selectedToolLabel}
            />
          )}

          {selectedToolLabel?.kind === 'default' && <ToolLabelDetail toolLabel={selectedToolLabel} />}
        </div>
        {selectedToolLabel?.kind === 'custom' && (
          <ModalFooter
            onCancel={onClose}
            onOk={onSaveClick}
            okText="Save"
            disableSave={!isDirty || !isValid}
            data-testid="label-glossary-modal-footer"
          />
        )}
      </PrismModal>
    </>
  )
}

/**
 * Renders an Tool Label item for the Label Glossary
 *
 * @param toolLabel - the tool label value
 * @param onClick - a function that handles the click event
 */
const LabelListItem = ({
  toolLabel,
  onClick,
  'data-test': dataTest,
}: {
  toolLabel: ToolLabel
  onClick: () => void
  'data-test'?: string
}) => {
  const image = getToolLabelImagesToShow(toolLabel)[0]
  return (
    <li className={Styles.labelClassificationListItem} onClick={onClick} data-test={dataTest}>
      <figure className={Styles.listItemImageContainer}>
        {!image && <PrismElementaryCube />}
        {image && <ImgFallback src={image} className={Styles.labelFormImage} loaderType="skeleton" />}
      </figure>
      <PrismOverflowTooltip className={Styles.listItemLabel} content={titleCase(getLabelName(toolLabel))} />
      <PrismResultButton
        severity={getDisplaySeverity(toolLabel)}
        className={`${Styles.severityContainer} ${Styles.resultContainerOnList}`}
        value={toolLabel.severity}
        type="noFill"
        size="small"
      />
    </li>
  )
}

const getDefaultFormValues = (toolLabel?: ToolLabel): LabelFormFields => {
  return {
    severity: toolLabel?.severity || '',
    description: toolLabel?.description || '',
    rootCauses: toolLabel?.root_cause || '',
    correctiveActions: toolLabel?.corrective_actions || '',
    value: toolLabel?.value || '',
    user_images: { current: toolLabel?.user_images || [], toUpload: [] },
  }
}

/**
 * Renders a list of Tool Labels for the Label Glossary
 *
 * @param setSelectedToolLabel - a function that sets the selected tool label
 */
const LabelGlossaryList = ({ setSelectedToolLabel }: { setSelectedToolLabel: (toolLabel: ToolLabel) => void }) => {
  const [selectedTab, setSelectedTab] = useState<'custom' | 'standard' | 'archived'>('custom')
  const [searchValue, setSearchValue] = useState('')

  const { defaultLabels, customLabels } = useAllToolLabels()

  const deletedLabels = useQuery(
    selectedTab === 'archived' ? getterKeys.toolLabels(DELETED_LABELS_GETTER_KEY) : null,
    () => service.getToolLabels({ is_deleted: true }),
  ).data?.data.results

  const labelsToShow = useMemo(() => {
    if (selectedTab === 'custom') return customLabels?.filter(tl => !tl.is_deleted)
    if (selectedTab === 'standard') return defaultLabels
    if (selectedTab === 'archived') return deletedLabels
  }, [customLabels, defaultLabels, deletedLabels, selectedTab])

  const filteredAndSortedLabels = useMemo(() => {
    return labelsToShow
      ?.filter(tl => {
        const currentLabelLowerCase = tl.value.toLowerCase()
        const currentLabelLowerCaseSpaces = replaceAll(currentLabelLowerCase, '_', ' ')
        const searchValueLowerCase = searchValue.toLowerCase().trim()
        if (currentLabelLowerCase.includes(searchValueLowerCase)) return true

        if (selectedTab === 'standard' && currentLabelLowerCaseSpaces.includes(searchValueLowerCase)) return true
        return false
      })
      .sort((a, b) => a.value.localeCompare(b.value))
  }, [labelsToShow, searchValue, selectedTab])

  return (
    <div className={Styles.labelByOrganization}>
      <LabelInfo>
        <PrismSearchInput
          placeholder="Search"
          value={searchValue}
          onInputChange={e => setSearchValue(e.target.value)}
          size="small"
          showIconPrefix
        />
      </LabelInfo>

      <div className={Styles.labelClassification}>
        <PrismTabMenu
          className={Styles.labelClassificationTabMenu}
          items={[
            { value: 'custom', label: 'custom', 'data-testid': 'label-glossary-modal-custom-tab' },
            { value: 'standard', label: 'standard', 'data-testid': 'label-glossary-modal-standard-tab' },
            { value: 'archived', label: 'archived', 'data-testid': 'label-glossary-modal-archived-tab' },
          ]}
          selectedItems={[selectedTab]}
          onSelect={setSelectedTab}
        />
      </div>
      {!filteredAndSortedLabels && <PrismLoader />}
      {filteredAndSortedLabels && (
        <ul className={Styles.labelClassificationList}>
          {filteredAndSortedLabels.map(toolLabel => (
            <LabelListItem
              data-test={`label-glossary-modal-${selectedTab}-list-item`}
              key={`${selectedTab}-${toolLabel.id}`}
              toolLabel={toolLabel}
              onClick={() => {
                setSelectedToolLabel(toolLabel)
              }}
            />
          ))}
          {filteredAndSortedLabels.length === 0 && (
            <GenericBlankStateMessage description="No results match your search" header={<PrismSearchIcon />} />
          )}
        </ul>
      )}
    </div>
  )
}

/**
 * Adds a newly created label to a given getter branch
 *
 * @param toolLabel - new label added
 * @param labelsGetterKey -  the getter key to update
 * @param dispatch - redux dispatch to perform the action
 */
export const addLabelToGetter = ({
  toolLabel,
  labelsGetterKey,
  dispatch,
}: {
  toolLabel: ToolLabel
  labelsGetterKey: string
  dispatch: Dispatch
}) => {
  dispatch(
    Actions.getterUpdate({
      key: getterKeys.toolLabels(labelsGetterKey),
      updater: res => {
        if (!res?.data.results) return res

        return { ...res, data: { ...res.data, results: [...res.data.results, toolLabel] } }
      },
    }),
  )
}

/**
 * Removes a label from a given getter branch
 *
 * @param toolLabel - Label to be removed
 * @param labelsGetterKey -  the getter key to update
 * @param dispatch - redux dispatch to perform the action
 */
const removeLabelFromGetter = ({
  toolLabel,
  labelsGetterKey,
  dispatch,
}: {
  toolLabel: ToolLabel
  labelsGetterKey: string
  dispatch: Dispatch
}) => {
  dispatch(
    Actions.getterUpdate({
      key: getterKeys.toolLabels(labelsGetterKey),
      updater: res => {
        if (!res?.data.results) return res

        const updatedLabels = [...res.data.results]

        const foundIdx = updatedLabels.findIndex(tl => tl.id === toolLabel.id)
        if (foundIdx < 0) return

        updatedLabels.splice(foundIdx, 1)

        return { ...res, data: { ...res.data, results: updatedLabels } }
      },
    }),
  )
}
