import React from 'react'

import { BreadcrumbLabel } from 'components/Breadcrumbs/Breadcrumbs'
import Layout from 'components/Layout/Layout'
import PrismSideNavLayout from 'components/PrismSideNavLayout/PrismSideNavLayout'
import paths from 'paths'

import DateTime from './DateTime'
import Password from './Password'
import Profile from './Profile'
import Styles from './Settings.module.scss'

/**
 * Renders settings screen that allows the user to edit his personal info, his
 * email (not yet implemented) and his password.
 */
const Settings = () => {
  return (
    <Layout title={<BreadcrumbLabel className={Styles.headerBreadcrumb}>My Account</BreadcrumbLabel>}>
      <PrismSideNavLayout
        getPath={paths.accountSettings}
        listItems={[
          {
            label: 'Profile',
            value: 'profile',
            component: <Profile />,
          },
          {
            label: 'Security',
            value: 'security',
            component: <Password />,
          },
          {
            label: 'Date & Time',
            value: 'datetime',
            component: <DateTime />,
          },
        ]}
      />
    </Layout>
  )
}

export default Settings
