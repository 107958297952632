import React, { useMemo } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Divider } from 'components/Divider/Divider'
import { PrismMenuList } from 'components/PrismMenuList/PrismMenuList'

import Styles from './PrismSideNavLayout.module.scss'

type Props<T> = {
  listItems: {
    label: string
    value: T
    component: React.ReactNode
    dataTestId?: string
  }[]
  leftContainerClassName?: String
  rightContainerClassName?: String
  className?: string
  getPath: ({ mode, params }: { mode: T; params?: {} }) => string
  'data-testid'?: string
  forceParams?: {
    [key: string]: string | undefined
  }
}

const PrismSideNavLayout = <T extends string>({
  listItems,
  getPath,
  leftContainerClassName = '',
  rightContainerClassName = '',
  className = '',
  'data-testid': dataTestid,
  forceParams,
}: Props<T>) => {
  const history = useHistory()
  const location = useLocation()

  let selectedItem = useMemo(() => {
    return listItems.find(item => {
      const path = getPath({ mode: item.value })
      return path === location.pathname
    })
  }, [getPath, listItems, location.pathname])

  if (!selectedItem) selectedItem = listItems[0]

  return (
    <main className={`${Styles.twoSideLayout} ${className}`}>
      {/* Left Panel */}
      <section className={`${Styles.leftSection} ${leftContainerClassName}`}>
        <PrismMenuList
          data-testid={dataTestid}
          list={listItems.map(item => ({
            ...item,
            isActive: selectedItem?.value === item.value,
            dataTestId: `${dataTestid}-${item.label.toLowerCase()}`,
          }))}
          onClick={value => history.push(getPath({ mode: value, params: forceParams }))}
        />
      </section>

      <Divider type="vertical" className={Styles.twoSideDivder} />

      <section className={`${Styles.rightSection} ${rightContainerClassName}`}>{selectedItem?.component}</section>
    </main>
  )
}

export default PrismSideNavLayout
