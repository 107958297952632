import React, { memo, ReactNode, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismCloseIcon } from 'components/prismIcons'
import { ModalContext, useModalsSync } from 'components/PrismModal/PrismModal'

import Styles from './FullScreen.module.scss'

interface Props {
  className?: string
  onClose: () => any
  id: string
}

/**
 * Renders a React component at the center of the viewport against a black
 * background, mainly for displaying images at their full size.
 * @param onClose - handler for closing the fullscreen modal
 * @param onCloseClick - Function called when clicking on the closing icon
 */
function FullScreen({ className = '', onClose, children, id }: React.PropsWithChildren<Props>) {
  const portalContainerRef = useRef<HTMLDivElement>(document.createElement('div'))
  useEffect(() => {
    document.body.appendChild(portalContainerRef.current)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document.body.removeChild(portalContainerRef.current)
    }
  }, [])

  useEffect(() => {
    const keyboardHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', keyboardHandler)

    return () => {
      document.removeEventListener('keydown', keyboardHandler)
    }
  }, [onClose])

  useModalsSync(id)

  if (!children) return null

  // Create a portal with react so that this component gets rendered at the root of the DOM.
  // This is important due to some issues regarding css stacking context.
  return ReactDOM.createPortal(
    <ModalContext.Provider value={{ id }}>
      <div className={`${Styles.fullScreen} ${className}`}>{children}</div>
    </ModalContext.Provider>,
    portalContainerRef.current,
  )
}

export default memo(FullScreen)

/**
 * Renders a header with a close icon in the Fullscreen component, plus any optional content passed in via props
 * @param onCloseClick - Function called when clicking on the closing icon
 * @param leftSide - Optional node to render alongside the close button on the left side of the header, if not passed, a default Close button appears
 * @param rightSide - Optional node to render on the right side of the header
 */
export function FullScreenHeader({
  onCloseClick,
  rightSide,
  leftSide,
}: {
  onCloseClick: () => any
  leftSide?: ReactNode
  rightSide?: ReactNode
}) {
  return (
    <div className={`${Styles.headerBar} ${rightSide ? Styles.leftAndRightHeader : ''}`}>
      <div className={`${Styles.leftContainer} ${Styles.headerSection}`}>{leftSide}</div>
      <div className={`${Styles.rightContainer} ${Styles.headerSection}`}>{rightSide}</div>
      <IconButton
        data-testid="full-screen-close"
        isOnTop
        onClick={onCloseClick}
        icon={<PrismCloseIcon />}
        size="small"
        type="secondary"
        className={Styles.closeContainer}
      />
    </div>
  )
}

export const FullScreenFooter = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => (
  <div className={`${Styles.fullscreenFooter} ${className}`}>{children}</div>
)
