import React, { useEffect, useMemo, useState } from 'react'

import { isNil } from 'lodash'
import { Control, Controller, useForm } from 'react-hook-form'

import { AddSignAnimation } from 'components/AddSignAnimation/AddSignAnimation'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import PrismBadge from 'components/PrismBadge/PrismBadge'
import { PrismSharedToolIcon } from 'components/prismIcons'
import { PrismInputNumber } from 'components/PrismInput/PrismInput'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { PrismSlider, PrismSliderRange } from 'components/PrismSlider/PrismSlider'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Token } from 'components/Token/Token'
import Shared from 'styles/Shared.module.scss'
import {
  AoiUpdate,
  RecipeRoutineExpanded,
  Threshold,
  ThresholdMetadata,
  ToolMetadata,
  ToolSpecificationName,
  UserFacingThreshold,
} from 'types'
import { getThresholdFromUserFacingValues, isThresholdFromGARTool, isUserFacingThresholdFromGARTool } from 'utils'

import { THRESHOLD_MAX, THRESHOLD_MIN, THRESHOLD_STEP, ThresholdByViewData } from './TrainingReport'
import Styles from './TrainingReport.module.scss'
import ViewThresholdSlider from './ViewThresholdSlider/ViewThresholdSlider'

/**
 * Renders the threshold sidebar to show on training reports. This sidebar allows editing the current threshold
 *
 * @param isSharedTool - Whether the tool is shared
 * @param onCancel - Handler for canceling the threshold update.
 * @param recommendedValue - Recommended threshold value set bby the ML model. This will display in the helper text
 * @param initialValue - Initial value for the slider.
 * @param thresholdButtonText - The text to show in the Save or apply button at the bottom of the sidebar
 * @param specificationName - The specification of the tool to edit. This changes the type of sldier we show
 * @param loading - Whether the tool is loading
 * @param onSave - Handler for when clicking on the save or apply button
 * @param onChange - Handler for when the slider or input is edited. This is used to update the training carousels.
 * @param toolVersion - used within the shared tooltip
 * @param thresholdByView - Threshold data by view
 * @param setThresholdByView - State updater for threshold by view
 * @param views - Views where the tool is shared
 * @params someViewThresholdOverriden - Whether some view threshold is overriden
 * @param forcedView - If provided, this view will be used instead of fetching all the views
 */
export const ThresholdSideBar = ({
  isSharedTool,
  onCancel,
  recommendedValue,
  initialValue,
  thresholdButtonText,
  specificationName,
  loading,
  onSave,
  onChange: onChangeExternal,
  toolVersion,
  thresholdByView,
  setThresholdByView,
  views,
  someViewThresholdOverriden,
  forcedView,
  toolId,
}: {
  recommendedValue?: string | number
  initialValue: Threshold | undefined
  loading?: boolean
  specificationName: ToolSpecificationName
  thresholdButtonText: string
  onSave: (val: Threshold, aoisUpdateData: AoiUpdate[], aoiThresholdMetadata: ToolMetadata['aoi_thresholds']) => any
  onChange: (val: UserFacingThreshold) => any
  isSharedTool?: boolean
  onCancel: () => any
  toolVersion?: number
  thresholdByView: ThresholdByViewData | undefined
  setThresholdByView: React.Dispatch<React.SetStateAction<ThresholdByViewData | undefined>>
  views: RecipeRoutineExpanded[] | undefined
  someViewThresholdOverriden: boolean
  forcedView: boolean
  toolId: string
}) => {
  const [showThresholdByView, setShowThresholdByView] = useState<boolean>(forcedView)
  const [slidersWrapperAnimation, setSlidersWrapperAnimation] = useState(false)

  // It's important to have local state and an external state that handle threshold since we only want to set the real
  // threshold once the user releases the slider (onAfterChange handler)
  const [globalThreshold, setGlobalThreshold] = useState<Threshold>()
  const [thresholdByViewChanged, setThresholdByViewChanged] = useState(false)

  const defaultValues = { threshold: initialValue }
  const {
    formState: { isValid, isDirty, errors },
    control,
    reset,
  } = useForm({ defaultValues, mode: 'onChange' })

  const initialValueKey =
    initialValue && isThresholdFromGARTool(initialValue)
      ? `${initialValue.lowerThreshold}${initialValue.upperThreshold}`
      : initialValue?.threshold
  // We want to set the threshold once we receive a value from the tool.
  useEffect(() => {
    setGlobalThreshold(initialValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValueKey])

  const showThresholdError = Object.keys(errors).length > 0

  const updateViewThreshold = (routineParentId: string, threshold: UserFacingThreshold) => {
    const updatedThreshold = getThresholdFromUserFacingValues(threshold, specificationName)
    setThresholdByViewChanged(true)
    setThresholdByView(prev => {
      const updated = { ...prev }

      if (updated[routineParentId]) {
        updated[routineParentId]!.threshold = updatedThreshold
        updated[routineParentId]!.overriden = true
      }

      return updated
    })
  }

  const resetViewThreshold = (routineParentId: string) => {
    if (isNil(globalThreshold)) return
    setThresholdByViewChanged(true)
    setThresholdByView(prev => {
      const updated = { ...prev }

      if (updated[routineParentId]) {
        updated[routineParentId]!.threshold = globalThreshold
        updated[routineParentId]!.overriden = false
      }

      return updated
    })
  }

  const getThresholdOverridesUpdateData = () => {
    const entriesToSave = Object.entries(thresholdByView || {})

    const aoisUpdates: AoiUpdate[] = []
    const aoiThresholdMetadata: ToolMetadata['aoi_thresholds'] = {}

    entriesToSave.forEach(([, thresholdData]) => {
      const updatedInferenceUserArgs: ThresholdMetadata = { ...thresholdData.tool.inference_user_args }
      if (isThresholdFromGARTool(thresholdData.threshold)) {
        updatedInferenceUserArgs.lower_threshold = thresholdData.threshold.lowerThreshold
        updatedInferenceUserArgs.upper_threshold = thresholdData.threshold.upperThreshold
      } else {
        updatedInferenceUserArgs.threshold = thresholdData.threshold.threshold
      }
      aoiThresholdMetadata[thresholdData.aoiParentId] = {
        user_override: updatedInferenceUserArgs,
      }
      if (thresholdData.tool.version === toolVersion) {
        thresholdData.aois.forEach(aoi => {
          aoisUpdates.push({
            id: aoi.id,
            inference_user_args: updatedInferenceUserArgs,
          })
        })
      }
    })

    return { aoisUpdates, aoiThresholdMetadata }
  }

  const handleSaveThreshold = async () => {
    if (globalThreshold === undefined) return

    if (!thresholdByView) return
    const { aoisUpdates, aoiThresholdMetadata } = getThresholdOverridesUpdateData()

    await onSave(globalThreshold, aoisUpdates, aoiThresholdMetadata)
    setThresholdByViewChanged(false)
    reset(defaultValues)
  }

  const allThresholdsByViewAreValid = useMemo(() => {
    if (!thresholdByView) return true
    const thresholdsData = Object.values(thresholdByView)

    return thresholdsData.every(data => isUserFacingThresholdValid(data.threshold))
  }, [thresholdByView])

  return (
    <div className={Styles.sidebarWrapper}>
      <div className={Styles.sidebarMain}>
        <div className={Styles.sidebarScrollableContainer}>
          {isSharedTool && (
            <PrismTooltip
              title={
                <Token label="Shared Tool">
                  This tool is used in multiple views. You can override its threshold on specific views.
                </Token>
              }
              mouseEnterDelay={0.01}
              placement="rightTop"
              overlayClassName={Styles.tooltipContainer}
            >
              <div className={Styles.sharedTool}>
                <PrismSharedToolIcon className={Styles.sharedIcon} />

                <span className={Styles.sidebarSharedCaption}>Shared tool used in multiple views</span>
              </div>
            </PrismTooltip>
          )}

          {!forcedView && (
            <GlobalThresholdSlider
              threshold={globalThreshold}
              setGlobalThreshold={setGlobalThreshold}
              specificationName={specificationName}
              control={control}
              onChangeExternal={onChangeExternal}
              isSharedTool={isSharedTool}
              setThresholdByView={setThresholdByView}
              showThresholdError={showThresholdError}
              recommendedValue={recommendedValue}
            />
          )}

          {isSharedTool && (
            <section className={Styles.thresholdByView}>
              {!forcedView && (
                <div
                  className={Styles.thresholdByViewButton}
                  onClick={() => {
                    setShowThresholdByView(!showThresholdByView)
                    setSlidersWrapperAnimation(!slidersWrapperAnimation)
                  }}
                >
                  <AddSignAnimation isActive={showThresholdByView} />

                  <span>Threshold by View</span>
                  {someViewThresholdOverriden && (
                    <PrismBadge type="warning" className={`${Styles.overrideBadge} ${true ? Styles.active : ''}`} />
                  )}
                </div>
              )}

              <div
                className={`${Styles.byRoutineBody} ${views?.length === 1 ? Styles.removeHeightLimit : ''}  ${
                  slidersWrapperAnimation ? Styles.expand : ''
                }`}
              >
                {views?.map(view => (
                  <ViewThresholdSlider
                    key={view.id}
                    toolSpecificationName={specificationName}
                    view={view}
                    containerIsRendered={showThresholdByView}
                    viewThreshold={thresholdByView?.[view.routine.parent.id]?.threshold}
                    defaultIsViewThresholdOverriden={thresholdByView?.[view.routine.parent.id]?.overriden}
                    updateViewThreshold={updateViewThreshold}
                    resetViewThreshold={resetViewThreshold}
                    forcedView={forcedView}
                    toolId={toolId}
                  />
                ))}
              </div>
            </section>
          )}
        </div>

        <div className={Styles.sidebarFooter}>
          <Button size="small" type="secondary" loading={loading} onClick={onCancel}>
            Cancel
          </Button>

          <Button
            disabled={(!isDirty && !thresholdByViewChanged) || !isValid || !allThresholdsByViewAreValid}
            size="small"
            loading={loading}
            onClick={handleSaveThreshold}
          >
            {thresholdButtonText}
          </Button>
        </div>
      </div>

      <Divider type="vertical" className={Styles.divider} />
    </div>
  )
}

const SharedTooltip = ({ iconClassName }: { iconClassName?: string }) => {
  return (
    <PrismTooltip
      title={<Token label="Shared Setting">Changing this tool’s threshold may affect other views.</Token>}
      mouseEnterDelay={0.01}
      placement="rightTop"
      overlayClassName={Styles.tooltipContainer}
    >
      <div className={`${Styles.sharedToolIcon} ${iconClassName ?? ''}`}>
        <PrismSharedToolIcon />
      </div>
    </PrismTooltip>
  )
}

export const ThresholdErrorMessage = () => <div className={Styles.showThresholdError}>Invalid threshold value</div>

export const isUserFacingThresholdValid = (threshold: UserFacingThreshold | undefined) => {
  if (!threshold) return true
  if (!isUserFacingThresholdFromGARTool(threshold)) return true

  if ((threshold.userFacingLowerThreshold || 0) > (threshold.userFacingUpperThreshold || 0)) return false
  return true
}

const GlobalThresholdSlider = ({
  threshold,
  setGlobalThreshold,
  specificationName,
  control,
  onChangeExternal,
  isSharedTool,
  setThresholdByView,
  showThresholdError,
  recommendedValue,
}: {
  threshold: Threshold | undefined
  setGlobalThreshold: (threshold: Threshold) => void
  specificationName: ToolSpecificationName
  control: Control<{ threshold?: Threshold }>
  onChangeExternal: (threshold: Threshold) => void
  isSharedTool?: boolean
  setThresholdByView: React.Dispatch<React.SetStateAction<ThresholdByViewData | undefined>>
  showThresholdError: boolean
  recommendedValue: string | number | undefined
}) => {
  const recommendedValueText =
    recommendedValue !== undefined
      ? ` Recommended ${specificationName === 'graded-anomaly' ? 'range' : 'value'} is ${recommendedValue}.`
      : undefined

  return (
    <Controller
      control={control}
      name="threshold"
      rules={{
        validate: isUserFacingThresholdValid,
      }}
      render={({ onChange: onChangeLocal }) => {
        const handleThresholdChange = (userFacingThreshold: UserFacingThreshold) => {
          const updatedThreshold = getThresholdFromUserFacingValues(userFacingThreshold, specificationName)
          onChangeLocal(updatedThreshold)
          onChangeExternal(updatedThreshold)
        }

        const updateThresholdsByView = (threshold: UserFacingThreshold) => {
          const updatedThreshold = getThresholdFromUserFacingValues(threshold, specificationName)

          setGlobalThreshold(updatedThreshold)
          setThresholdByView(prev => {
            const updated = { ...prev }
            Object.keys(updated).forEach(viewId => {
              // If tool is NOT shared, we want to update all the views entries (it should only be 1 view)
              // otherwise, we validate if view threshold is overriden
              if (isSharedTool && updated[viewId]?.overriden) return

              if (updated[viewId]) {
                updated[viewId]!.threshold = updatedThreshold
              }
            })

            return updated
          })
        }

        return (
          <div className={Styles.thresholdContainer}>
            <div className={`${Styles.toolThresholdModal} ${Shared.verticalChildrenGap8}`}>
              <div className={Styles.sliderAndActions}>
                {threshold && isThresholdFromGARTool(threshold) && (
                  <PrismSliderRange
                    label="Threshold"
                    sliderColor={specificationName === 'graded-anomaly' ? 'amber' : 'red'}
                    valueStart={threshold.userFacingLowerThreshold}
                    valueEnd={threshold.userFacingUpperThreshold}
                    min={THRESHOLD_MIN}
                    max={THRESHOLD_MAX}
                    step={THRESHOLD_STEP}
                    onAfterChange={(v: [number, number]) =>
                      handleThresholdChange({
                        userFacingLowerThreshold: getThresholdValue(v[0]),
                        userFacingUpperThreshold: getThresholdValue(v[1]),
                      })
                    }
                    onChange={(v: [number, number]) => {
                      updateThresholdsByView({
                        userFacingLowerThreshold: getThresholdValue(v[0]),
                        userFacingUpperThreshold: getThresholdValue(v[1]),
                      })
                    }}
                    showError={showThresholdError}
                    inputBoxLeft={
                      <PrismInputNumber
                        min={THRESHOLD_MIN}
                        max={THRESHOLD_MAX}
                        step={THRESHOLD_STEP}
                        value={threshold.userFacingLowerThreshold}
                        precision={2}
                        name="threshold"
                        onChange={(v: number) => {
                          const value = getThresholdValue(v)
                          updateThresholdsByView({ ...threshold, userFacingLowerThreshold: value })
                          handleThresholdChange({ ...threshold, userFacingLowerThreshold: value })
                        }}
                        size="small"
                      />
                    }
                    inputBoxRight={
                      <PrismInputNumber
                        min={THRESHOLD_MIN}
                        max={THRESHOLD_MAX}
                        step={THRESHOLD_STEP}
                        value={threshold.userFacingUpperThreshold}
                        precision={2}
                        name="threshold"
                        onChange={(v: number) => {
                          const value = getThresholdValue(v)
                          updateThresholdsByView({ ...threshold, userFacingUpperThreshold: value })
                          handleThresholdChange({ ...threshold, userFacingUpperThreshold: value })
                        }}
                        size="small"
                      />
                    }
                  />
                )}

                {threshold && !isThresholdFromGARTool(threshold) && (
                  <PrismSlider
                    className={Styles.thresholdSlider}
                    label="Threshold"
                    sliderColor="red"
                    value={threshold.userFacingThreshold}
                    min={THRESHOLD_MIN}
                    max={THRESHOLD_MAX}
                    step={THRESHOLD_STEP}
                    onAfterChange={v =>
                      handleThresholdChange({ ...threshold, userFacingThreshold: getThresholdValue(v) })
                    }
                    onChange={v => updateThresholdsByView({ ...threshold, userFacingThreshold: getThresholdValue(v) })}
                    inputBox={
                      <PrismInputNumber
                        min={THRESHOLD_MIN}
                        max={THRESHOLD_MAX}
                        step={THRESHOLD_STEP}
                        value={threshold.userFacingThreshold}
                        precision={2}
                        onChange={v => {
                          handleThresholdChange({ ...threshold, userFacingThreshold: getThresholdValue(v) })
                          updateThresholdsByView({ ...threshold, userFacingThreshold: getThresholdValue(v) })
                        }}
                        size="small"
                      />
                    }
                  />
                )}

                {isSharedTool && <SharedTooltip iconClassName={Styles.sharedToolIcon} />}
              </div>

              {showThresholdError && <ThresholdErrorMessage />}

              <div className={Styles.thresholdDescription}>
                {getPassCriteriaText(specificationName)}
                <span className={Styles.recommendedValueText}>{recommendedValueText}</span>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

const getPassCriteriaText = (specificationName: ToolSpecificationName) => {
  if (specificationName === 'graded-anomaly')
    return (
      <span>
        This tool will predict
        <PrismResultButton severity="good" value="good" size="small" className={Styles.thresholdResult} />
        if its score is above range,
        <PrismResultButton severity="minor" value="minor" size="small" className={Styles.thresholdResult} />
        if within range, and
        <PrismResultButton severity="critical" value="critical" size="small" className={Styles.thresholdResult} />
        if below.
      </span>
    )

  if (specificationName === 'match-classifier')
    return (
      <span>
        This tool will predict
        <PrismResultButton severity="pass" value="pass" size="small" className={Styles.thresholdResult} />
        if its score is above the threshold and the label is expected.
      </span>
    )

  return (
    <span>
      This tool will predict
      <PrismResultButton severity="good" value="good" size="small" className={Styles.thresholdResult} />
      if its score exceeds the threshold. Changes will only impact newly inspected items.
    </span>
  )
}

export const getThresholdValue = (threshold: number | undefined | null) => {
  return isNil(threshold) ? 0 : threshold
}
