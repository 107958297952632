import React, { useMemo } from 'react'

import { Table } from 'antd'
import { TableColumnsType } from 'antd'
import { useHistory } from 'react-router-dom'

import GridTableHeader from 'components/GridTableHeader/GridTableHeader'
import ImgFallback from 'components/Img/ImgFallback'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import paths from 'paths'
import { RecipeRoutineExpanded, Tool } from 'types'
import { getAoisAndToolsFromRoutine, getDisplayStationLocation, pluralize } from 'utils'

import Styles from './ToolInfoCard.module.scss'

// Used to have access to the current tool in the columns render method
interface RecipeRoutineWithTool extends RecipeRoutineExpanded {
  currentTool: Tool
}

type Props = {
  tool: Tool
  isTrainingTab: boolean
  routineParentId: string
  readOnly?: boolean
  recipeRoutines?: RecipeRoutineExpanded[]
}

/**
 * Renders a table with a list of the routines where the provided tool is used.
 *
 * @param tool - The current tool.
 * @param isTrainingTab - Whether we are showing this section from the trainig tab.
 * @param routineParentId - If `isTrainingTab` is true, we need to provide an id to filter the current routine parent.
 * @param readOnly - Indicates if the screen is in read only mode
 * @param RecipeRoutines - The selected recipe routine
 */
const ToolInfoCardViewsSection = ({ tool, isTrainingTab, routineParentId, readOnly, recipeRoutines }: Props) => {
  const history = useHistory()

  const filteredRecipeRoutines = useMemo(() => {
    if (isTrainingTab) {
      return recipeRoutines?.filter(recipeRoutine => recipeRoutine.routine.parent.id !== routineParentId)
    }
    return recipeRoutines
  }, [isTrainingTab, recipeRoutines, routineParentId])

  const routinesWithCurrentTool: RecipeRoutineWithTool[] | undefined = useMemo(() => {
    // We attach the current tool to the routine parents to have access to the tool in the column render method
    return filteredRecipeRoutines?.map(routineParent => {
      return { ...routineParent, currentTool: tool }
    })
  }, [filteredRecipeRoutines, tool])

  const handleRowClick = (recipeRoutine: RecipeRoutineWithTool) => {
    history.push(
      paths.settingsRecipe(recipeRoutine.recipe.parent.id, 'train', {
        routineParentId: recipeRoutine.routine.parent.id,
        toolParentId: recipeRoutine.currentTool.parent_id,
      }),
    )
  }

  return (
    <section className={Styles.routinesSection}>
      <h4 className={Styles.sectionTitle}>{isTrainingTab && 'Other'} Views</h4>
      <p className={Styles.sectionCaption}>
        This tool is used in {filteredRecipeRoutines?.length ?? 0} {isTrainingTab && 'other'}
        {pluralize({ wordCount: filteredRecipeRoutines?.length || 0, word: 'view' })}
      </p>

      <div className={Styles.sectionBody}>
        <div className={`${Styles.routinesTableWrapper} ${readOnly ? Styles.versionDrawerIsOpen : ''}`}>
          {!routinesWithCurrentTool && <RoutineLoadingTable />}

          {routinesWithCurrentTool && (
            <>
              <GridTableHeader
                columns={routineParentColumns}
                size={'small'}
                className={Styles.otherRoutinesTableHeader}
              />

              <Table
                rowKey="id"
                className={Styles.tableContainer}
                rowClassName={`${Styles.tableRow} ${Styles.otherRoutinesTable} ${
                  readOnly ? Styles.tableIsDisabled : ''
                }`}
                columns={routineParentColumns}
                dataSource={routinesWithCurrentTool}
                pagination={false}
                showHeader={false}
                onRow={routineParent => ({
                  onClick: () => (!readOnly ? handleRowClick(routineParent) : undefined),
                })}
              />
            </>
          )}
        </div>
      </div>
    </section>
  )
}

const RoutineLoadingTable = () => {
  return (
    <div className={Styles.routineLoadingTable}>
      <div className={Styles.loadingTableFirstColumn}>
        <PrismSkeleton className={Styles.squareLoader} />
        <PrismSkeleton />
      </div>
      <PrismSkeleton />
      <PrismSkeleton />
    </div>
  )
}

/**
 * Renders the routine title section of the row.
 * @param recipeRoutine - the current RecipeRoutine.
 */
const RoutineParentTitle = ({ recipeRoutine }: { recipeRoutine: RecipeRoutineExpanded }) => {
  const image = recipeRoutine.routine.fallback_images?.image_thumbnail || recipeRoutine.routine.fallback_images?.image

  return (
    <div className={Styles.toolColumn}>
      <figure className={Styles.toolColumnImageContainer}>
        {image && <ImgFallback src={image} loaderType="skeleton" className={Styles.toolColumnImage} />}
        {!image && <PrismElementaryCube />}
      </figure>
      <div className={Styles.toolNameAndView}>
        <PrismOverflowTooltip content={recipeRoutine.recipe.parent.name} />
        <PrismOverflowTooltip content={recipeRoutine.routine.parent.name} className={Styles.viewName} />
      </div>
    </div>
  )
}

/**
 * Renders the active model version for the given tool parent
 *
 * NOTE: We fetch the working routine for each routineParent, if we ever have a tool being shared in > 10 routines,
 * we'll need to work wit the backend team to find a better solution here
 * @param recipeRoutine - the current RecipeRoutine.
 */
const WorkingModelVersion = ({ recipeRoutine }: { recipeRoutine: RecipeRoutineWithTool }) => {
  const { currentTool } = recipeRoutine
  if (!recipeRoutine) return <PrismSkeleton />

  const { tools } = getAoisAndToolsFromRoutine(recipeRoutine.routine)
  const activeTool = tools.find(tool => tool.parent_id === currentTool.parent_id)

  return <PrismOverflowTooltip content={activeTool?.version ? `${activeTool?.version}` : 'No model'} />
}

/**
 * Renders the current station
 *
 * @param recipeRoutine - the current RecipeRoutine.
 */
const CurrentRecipeStation = ({ recipeRoutine }: { recipeRoutine: RecipeRoutineWithTool }) => {
  const recipeParentStation = recipeRoutine.recipe.parent.station
  return (
    <div className={Styles.stationNameContainer}>
      <PrismOverflowTooltip content={recipeParentStation?.name} className={Styles.stationTitle} />
      <PrismOverflowTooltip
        content={getDisplayStationLocation(recipeParentStation?.site_name || '', recipeParentStation?.belongs_to?.name)}
        className={Styles.stationSubtitle}
      />
    </div>
  )
}

const routineParentColumns: TableColumnsType<RecipeRoutineWithTool> = [
  {
    title: 'Recipe & Views',
    key: 'id',
    ellipsis: true,
    render: (_, recipeRoutine) => <RoutineParentTitle recipeRoutine={recipeRoutine} />,
  },
  {
    title: 'Station',
    key: 'station',
    render: (_, recipeRoutine) => <CurrentRecipeStation recipeRoutine={recipeRoutine} />,
  },
  {
    title: 'Product',
    key: 'name',
    ellipsis: true,
    render: (_, recipeRoutine) => <PrismOverflowTooltip content={recipeRoutine.recipe.parent.component_name} />,
  },
  {
    title: 'Active Model',
    key: 'model',
    ellipsis: true,
    render: (_, recipeRoutine) => <WorkingModelVersion recipeRoutine={recipeRoutine} />,
  },
]

export default ToolInfoCardViewsSection
