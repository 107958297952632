import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Barcode Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3678%3A31
 *
 * @param hasHover - Fill the icon with $light_100 when hovering the icon.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismBarcodeToolIcon = ({ isActive, hasHover, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${hasHover ? Styles.hoverInBlue : ''} ${isActive ? Styles.active : ''} ${
        className ?? ''
      }`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M19.2188 14H19.875V20H19.2188V14Z" fill="currentColor" />
      <path d="M15.9375 14H17.9062V20H15.9375V14Z" fill="currentColor" />
      <path d="M13.9688 14H14.625V20H13.9688V14Z" fill="currentColor" />
      <path d="M11.3438 14H12.6562V20H11.3438V14Z" fill="currentColor" />
      <path d="M8.71875 14H10.0312V20H8.71875V14Z" fill="currentColor" />
      <path d="M6.75 14H7.40625V20H6.75V14Z" fill="currentColor" />
      <path d="M4.125 14H5.4375V20H4.125V14Z" fill="currentColor" />
      <path d="M1.5 11.6667H22.5V12.3333H1.5V11.6667Z" fill="currentColor" />
      <path d="M19.2188 4H19.875V10H19.2188V4Z" fill="currentColor" />
      <path d="M15.9375 4H17.9062V10H15.9375V4Z" fill="currentColor" />
      <path d="M13.9688 4H14.625V10H13.9688V4Z" fill="currentColor" />
      <path d="M11.3438 4H12.6562V10H11.3438V4Z" fill="currentColor" />
      <path d="M8.71875 4H10.0312V10H8.71875V4Z" fill="currentColor" />
      <path d="M6.75 4H7.40625V10H6.75V4Z" fill="currentColor" />
      <path d="M4.125 4H5.4375V10H4.125V4Z" fill="currentColor" />
    </svg>
  )
}
