import React, { useEffect, useMemo, useRef, useState } from 'react'

import { RefSelectProps } from 'antd'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { query } from 'react-redux-query'

import { getterKeys, service, useQuery } from 'api'
import ImgFallback from 'components/Img/ImgFallback'
import LabelInfo from 'components/LabelInfo/LabelInfo'
import { PrismAddIcon, PrismEditIcon, PrismElementaryCube } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { SelectOption, ToolLabel, ToolLabelSeverity, ToolSpecificationName } from 'types'
import {
  getDisplaySeverity,
  getLabelName,
  getSeverityOptionsByTool,
  getToolLabelImagesToShow,
  replaceAll,
  sleep,
} from 'utils'
import { LABEL_VALUE_MAX_LENGTH } from 'utils/constants'

import { CREATE_NEW_LABEL_OPTION, LabelFormFields } from './AddLabelModal'
import Styles from './LabelingScreen.module.scss'

interface Props {
  modalLoaded: boolean
  labelSelectValue: string
  setLabelSelectValue: (value: string) => void
  defaultLabels?: ToolLabel[]
  renderSelectFooter: () => React.ReactNode
  newLabelValue: string
  setNewLabelValue: (value: string) => void
  currentToolLabels?: ToolLabel[]
  setValue: (name: keyof LabelFormFields, value: string) => void
  toolParentId: string
  toolSpecificationName: ToolSpecificationName
  onClose: () => void
  onShowGlossaryClick: () => void
  setSelectedLabel: (toolLabel?: ToolLabel) => void
  setSeverityRadioOption: (severity?: ToolLabelSeverity) => void
  selectScrollParentRef?: React.RefObject<HTMLDivElement>
}

/**
 * This component renders a PrismSelect that is used to search and select tool labels in the Add label modal.
 *
 * @param modalLoaded - a boolean that indicates if the modal has loaded
 * @param labelSelectValue - the value of PrismSelect
 * @param setLabelSelectValue - a function that sets the value of PrismSelect
 * @param defaultLabels - the default labels for the tool
 * @param renderSelectFooter - a function that renders the footer of the PrismSelect
 * @param newLabelValue - label value in case a new label is created
 * @param setNewLabelValue - a function that sets the value of the new label
 * @param currentToolLabels - all the labels related to the current tool
 * @param setValue - setValue funtion from useForm
 * @param toolParentId - current tool parent id
 * @param toolSpecificationName - current tool specification name
 * @param onClose - a function that closes the modal
 * @param onShowGlossaryClick - a function that shows the glossary
 * @param setSelectedLabel - a function that sets the selected label
 * @param setSeverityRadioOption - a function that sets the sevetity option when creating a new label
 * @param selectsScrollParentRef - Passes a ref to the wrapper or container that owns the scroll
 *
 */
const ToolLabelSearch = ({
  modalLoaded,
  labelSelectValue,
  setLabelSelectValue,
  defaultLabels,
  renderSelectFooter,
  newLabelValue,
  setNewLabelValue,
  currentToolLabels,
  setValue,
  toolParentId,
  toolSpecificationName,
  onClose,
  onShowGlossaryClick,
  setSelectedLabel,
  setSeverityRadioOption,
  selectScrollParentRef,
}: Props) => {
  const selectRef = useRef<RefSelectProps>(null)

  const [searchValue, setSearchValue] = useState('')

  const labelsKey = `${toolParentId}-search-labels`

  const {
    isSearchValueSameAsDefaultLabelValue,
    labelValueAlreadyExists,
    toolLabelSearchResults,
    refetchToolLabels,
    loadingLabels,
    filteredAndSortedLabels,
  } = useToolLabelSearchData({ labelsKey, currentToolLabels, defaultLabels, searchValue, toolSpecificationName })

  useEffect(() => {
    if (modalLoaded && !labelSelectValue) {
      const input = document.getElementById('glossary-search')
      input?.focus()
    }
  }, [labelSelectValue, modalLoaded])

  const showCreateLabelOption = !isSearchValueSameAsDefaultLabelValue && !labelValueAlreadyExists

  const renderAdditionalSeverityOptions = (): SelectOption[] => {
    if (!labelValueAlreadyExists || !toolLabelSearchResults) return []

    const additionalSeverities = getAvailableSeverityOptionsByToolAndValue(
      toolLabelSearchResults,
      searchValue,
      toolSpecificationName,
    )

    return additionalSeverities.map(severity => ({
      key: severity,
      value: `${CREATE_NEW_LABEL_OPTION}-${severity}`,
      title: searchValue || newLabelValue,
      dataTestId: `create-new-${severity}-label-option`,
      content: (
        <div className={Styles.searchValueItem}>
          <PrismAddIcon /> Create new label
          <PrismResultButton
            value={searchValue || newLabelValue}
            severity={severity}
            type="noFill"
            className={Styles.selectItemText}
          />
        </div>
      ),
    }))
  }

  const handleSearch = async (val: string) => {
    if (val.length > LABEL_VALUE_MAX_LENGTH) return
    refetchToolLabels(val)
    setSearchValue(val)
  }

  const showCreateNewLabelOption = (searchValue || newLabelValue) && showCreateLabelOption

  return (
    <LabelInfo title="Name" addDescriptionGap>
      <PrismSelect
        data-testid="tool-label-search-label-input"
        id="glossary-search"
        ref={selectRef}
        value={labelSelectValue || undefined}
        showSearch
        showArrow
        showAction={['focus', 'click']}
        stopPropagationOnDropdownClick
        onKeyUp={e => e.preventDefault()}
        optionLabelProp={labelSelectValue.includes(CREATE_NEW_LABEL_OPTION) ? 'title' : undefined}
        searchValue={searchValue}
        onSearch={handleSearch}
        className={Styles.glossarySearch}
        popupClassName={Styles.glossaryDropdown}
        thumbnailAndTextClassName={Styles.glossaryOptionSelected}
        filterOption={false}
        getPopupContainer={
          selectScrollParentRef?.current ? () => selectScrollParentRef.current as HTMLElement : undefined
        }
        placeholder="Search or create a new label"
        onSelect={async (val, option) => {
          setLabelSelectValue(val)

          const foundLabel = toolLabelSearchResults?.find(label => label.id === val)
          setSelectedLabel(foundLabel)

          if (val.includes(CREATE_NEW_LABEL_OPTION) && searchValue) setNewLabelValue(searchValue)
          if (!val.includes(CREATE_NEW_LABEL_OPTION)) setNewLabelValue('')

          setSeverityRadioOption(option.severity)
          if (option.severity) {
            await sleep(0)
            setValue('severity', option.severity)
          }
        }}
        dropdownRender={options => {
          if (!toolLabelSearchResults || loadingLabels) return <PrismLoader />

          return (
            <>
              {options}

              <div
                className={Styles.dropdownEditLabelsContainer}
                onClick={() => {
                  selectRef.current?.blur()
                  onClose()
                  onShowGlossaryClick()
                }}
              >
                <PrismEditIcon className={Styles.editLabelIcon} />{' '}
                <span className={Styles.editLabelText}>Edit labels</span>
              </div>
            </>
          )
        }}
        options={[
          {
            value: CREATE_NEW_LABEL_OPTION,
            title: searchValue || newLabelValue,
            content: (
              <div className={Styles.selectItemOption}>
                <PrismAddIcon className={Styles.selectItemIcon} />
                <span className={Styles.selectItemCaption} data-testid="tool-label-search-create-label">
                  Create new label "{searchValue || newLabelValue}"
                </span>
              </div>
            ),

            isHidden: !showCreateNewLabelOption,
          },
          ...(renderAdditionalSeverityOptions() || []),
          ...(filteredAndSortedLabels
            ? filteredAndSortedLabels.map(toolLabel => {
                const image = getToolLabelImagesToShow(toolLabel)[0]
                const labelName = getLabelName(toolLabel)
                return {
                  key: toolLabel.id,
                  value: toolLabel.id,
                  title: labelName,
                  dataTestId: `tool-label-search-${toolLabel.value.toLowerCase()}-option`,
                  content: (
                    <PrismResultButton
                      severity={getDisplaySeverity(toolLabel)}
                      value={labelName}
                      type="noFill"
                      className={Styles.selectItemText}
                      size="small"
                    />
                  ),
                  image: {
                    content: image ? (
                      <ImgFallback src={image} className={Styles.labelFormImage} loaderType="skeleton" />
                    ) : (
                      <PrismElementaryCube />
                    ),
                    hideBorderImage: true,
                  },
                }
              })
            : []),
        ]}
      />

      {renderSelectFooter()}
    </LabelInfo>
  )
}

export default ToolLabelSearch

/**
 * Hook in charge of providing the necessary data for the Search labels functionality.
 * NOTE: Move this hook into the `hooks.ts` file when it's used in more files
 *
 * @param labelsKey - getter key to use for fetching the search data
 * @param currentToolLabels - currently selected tool labels used on the select
 * @param defaultLabels - list of default labels to use
 * @param searchValue - search value from the select, which will be used to filter the labels
 * @param tool - current tool
 */
export const useToolLabelSearchData = ({
  labelsKey,
  currentToolLabels,
  defaultLabels,
  searchValue,
  toolSpecificationName,
}: {
  labelsKey: string
  currentToolLabels: ToolLabel[] | undefined
  defaultLabels: ToolLabel[] | undefined
  searchValue: string
  toolSpecificationName: ToolSpecificationName
}) => {
  const dispatch = useDispatch()
  const [loadingLabels, setLoadingToolLabels] = useState(false)

  const toolLabelSearchResults = useQuery(getterKeys.toolLabels(labelsKey), () =>
    service.getToolLabels({ kind__in: 'custom', severity__in: severityOptions.join() }),
  ).data?.data.results

  const severityOptions = getSeverityOptionsByTool(toolSpecificationName)

  const joinedSeverities = severityOptions.sort().join()

  const refetchToolLabels = useMemo(
    () =>
      debounce(async (searchValue?: string) => {
        if (searchValue !== undefined) setLoadingToolLabels(true)
        await query(
          getterKeys.toolLabels(labelsKey),
          () =>
            service.getToolLabels({
              kind__in: 'custom',
              severity__in: joinedSeverities,
              value: searchValue,
            }),
          { dispatch },
        )
        setLoadingToolLabels(false)
      }, 500),
    [dispatch, joinedSeverities, labelsKey],
  )

  const currentToolLabelIds = useMemo(() => {
    return currentToolLabels?.map(label => label.id) || []
  }, [currentToolLabels])

  const filteredAndSortedLabels = useMemo(() => {
    if (!toolLabelSearchResults) return
    return toolLabelSearchResults
      .filter(tl => {
        if (tl.is_deleted) return tl.value.toLowerCase() === searchValue.toLowerCase()
        return !currentToolLabelIds.includes(tl.id)
      })
      .sort((a, b) => a.value.localeCompare(b.value))
  }, [currentToolLabelIds, searchValue, toolLabelSearchResults])

  const isSearchValueSameAsDefaultLabelValue = useMemo(() => {
    return !!defaultLabels?.find(
      label =>
        replaceAll(label.value, '_', ' ').trim().toLowerCase() ===
        replaceAll(searchValue, '_', ' ').trim().toLowerCase(),
    )
  }, [defaultLabels, searchValue])

  const labelValueAlreadyExists = useMemo(() => {
    return !!toolLabelSearchResults?.find(label => label.value.toLowerCase() === searchValue.toLowerCase())
  }, [toolLabelSearchResults, searchValue])

  return {
    filteredAndSortedLabels,
    isSearchValueSameAsDefaultLabelValue,
    labelValueAlreadyExists,
    refetchToolLabels,
    loadingLabels,
    toolLabelSearchResults,
  }
}

/**
 * Obtains the severity options to show in the radio group according to a value and a tool
 *
 * @param customLabels - Custom labels to look up the value in
 * @param value - value to look up
 * @param tool - Current tool to add label to
 * @returns array of tool severities indicating which should be shown
 */
const getAvailableSeverityOptionsByToolAndValue = (
  customLabels: ToolLabel[],
  value: string,
  toolSpecificationName: ToolSpecificationName,
) => {
  const labelsWithSameValue = customLabels.filter(label => label.value.toLowerCase() === value.toLowerCase())
  const existingSeverities = labelsWithSameValue.map(label => label.severity)

  const severityOptions = getSeverityOptionsByTool(toolSpecificationName)

  return severityOptions.filter(severity => !existingSeverities.includes(severity))
}
