import React, { Key, ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import { Table } from 'antd'

import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import ImgFallback from 'components/Img/ImgFallback'
import { PrismElementaryCube, PrismSearchIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { LabelButtonSeverity, PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { useQueryParams } from 'hooks'
import { DetailModal } from 'pages/ItemDetail/DetailModal'
import {
  Component,
  ConnectionStatus,
  Threshold,
  Tool,
  ToolLabel,
  ToolLabelSeverity,
  TrainingMetrics,
  TrainingReportTab,
  TrainingResultFlat,
} from 'types'
import {
  calculatePercentage,
  extractLabelIdFromCalculatedLabels,
  getToolLabelImagesToShow,
  sortByValueAndSeverity,
} from 'utils'

import { MemoLabelContainer } from './LabelContainer'
import { LiveBatchCarousels } from './LiveBatchCarousels'
import { ThresholdByRoutineParentId } from './TrainingReport'
import Styles from './TrainingReport.module.scss'

type RowId = `${string}_${string}`

interface rowData {
  key: RowId
  label: {
    image?: string[]
    value: string
    severity: ToolLabelSeverity
  }
  images: number
  product: string
  accuracy: number
}

type Props = {
  trainingReportTab: TrainingReportTab
  tool?: Tool
  showCurrentBatchCarousels: boolean
  connectionStatus: ConnectionStatus
  thresholdForLiveCarousels?: Threshold
  inspectionId?: string | null
  aoiParentId?: string
  backendThresholdByRoutine?: ThresholdByRoutineParentId
  modalLoaded: boolean
  allToolLabels: ToolLabel[] | undefined
  labelsUsed: ToolLabel[] | undefined
  components: Component[] | undefined
  threshold: Threshold | undefined
  insightsEnabled?: boolean
  loadingThreshold: boolean
  containerRef: React.RefObject<HTMLDivElement>
  trainingMetricsByLabelAndComponent: TrainingMetrics['byLabelId'] | undefined
  trainingResults: TrainingResultFlat[] | undefined
}

export const TrainingReportSamples = ({
  trainingReportTab,
  tool,
  showCurrentBatchCarousels,
  insightsEnabled,
  connectionStatus,
  thresholdForLiveCarousels,
  inspectionId,
  aoiParentId,
  backendThresholdByRoutine,
  modalLoaded,
  allToolLabels,
  components,
  labelsUsed,
  threshold,
  loadingThreshold,
  containerRef,
  trainingMetricsByLabelAndComponent,
  trainingResults,
}: Props) => {
  const [expandedRowsIds, setExpandedRowsIds] = useState<RowId[]>([])
  const [carouselExpandedId, setCarouselExpandedId] = useState<RowId>()

  const [params] = useQueryParams()
  const { detail_tool_result_id } = params

  // If we are clicking on a shared link, we want to open the detail modal directly from here
  // We can guarantee its a shared link if we have `detail_tool_result` on our params and we havent render the carousels yet
  const isSharedLinkRef = useRef<boolean>(true)

  const handleOnExpandedRowsChange = (expandedRows: readonly Key[]) => {
    setExpandedRowsIds(expandedRows as RowId[])
    if (carouselExpandedId && !expandedRows.includes(carouselExpandedId)) setCarouselExpandedId(undefined)
  }

  const handleShowAll = useCallback(({ labelId, componentId }: { labelId: string; componentId: string }) => {
    const carouselKey: RowId = `${labelId}_${componentId}`
    setCarouselExpandedId(carouselKey)
  }, [])

  const sortedLabels = labelsUsed?.sort(sortByValueAndSeverity)

  const trainingResultsByLabelAndComponent = useMemo(() => {
    if (!allToolLabels) return
    return trainingResults?.reduce((trainingResultsByLabelAndComponent, trainingResult) => {
      const label = extractLabelIdFromCalculatedLabels(allToolLabels, trainingResult.calculated_labels)
      if (label) {
        const componentId = String(trainingResult.component_id)

        trainingResultsByLabelAndComponent[label] ??= {}
        trainingResultsByLabelAndComponent[label]![componentId] ??= []
        trainingResultsByLabelAndComponent[label]![componentId]!.push(trainingResult)
      }
      return trainingResultsByLabelAndComponent
    }, {} as { [labelId: string]: { [componentId: string]: TrainingResultFlat[] } })
  }, [allToolLabels, trainingResults])

  const { tableData, carousels } = useMemo(() => {
    if (!sortedLabels || !trainingResultsByLabelAndComponent || !trainingMetricsByLabelAndComponent)
      return { tableData: undefined, carousels: undefined }

    const { tableData, carousels } = sortedLabels.reduce(
      (tableDataAndCarousels, label) => {
        if (!(label.id in trainingResultsByLabelAndComponent)) return tableDataAndCarousels
        const componentsInTrainingResults = Object.keys(trainingResultsByLabelAndComponent[label.id] || {})
        const { tableData, carousels } = tableDataAndCarousels

        componentsInTrainingResults.forEach(componentId => {
          const metricsForLabelAndComponent = trainingMetricsByLabelAndComponent[label.id]?.[componentId]
          if (!metricsForLabelAndComponent) return

          const rowKey: RowId = `${label.id}_${componentId}`
          const imagesUsed = metricsForLabelAndComponent.total
          const correctPredictionsCount = metricsForLabelAndComponent.succesful

          const isCarouselExpanded = carouselExpandedId === rowKey
          const isRowExpanded = expandedRowsIds.includes(rowKey)
          carousels[rowKey] = (
            <MemoLabelContainer
              key={rowKey}
              carouselKey={`${rowKey}-${trainingReportTab}`}
              outerContainerRef={containerRef}
              onShowAll={handleShowAll}
              expanded={isCarouselExpanded}
              label={label}
              loading={loadingThreshold}
              threshold={threshold}
              tool={tool}
              toolLabels={allToolLabels}
              component_id={componentId}
              trainingResults={trainingResults}
              modalLoaded={modalLoaded}
              thresholdByRoutine={backendThresholdByRoutine}
              isSharedLinkRef={isSharedLinkRef}
              insightsEnabled={insightsEnabled}
              isRowExpanded={isRowExpanded}
            />
          )

          const componentName = components?.find(component => component.id === componentId)?.name
          tableData.push({
            key: rowKey,
            label: {
              image: getToolLabelImagesToShow(label),
              value: label.value,
              severity: label.severity,
            },
            product: componentName || '--',
            images: imagesUsed,
            accuracy: calculatePercentage(correctPredictionsCount, imagesUsed),
          })
        })

        return { tableData, carousels }
      },
      { tableData: [], carousels: {} } as { tableData: rowData[]; carousels: { [key: RowId]: ReactNode } },
    )
    // Sort by products by default
    tableData?.sort((a: rowData, b: rowData) => a.product.localeCompare(b.product))

    return { tableData, carousels }
  }, [
    sortedLabels,
    trainingResultsByLabelAndComponent,
    trainingMetricsByLabelAndComponent,
    carouselExpandedId,
    expandedRowsIds,
    trainingReportTab,
    containerRef,
    handleShowAll,
    loadingThreshold,
    threshold,
    tool,
    allToolLabels,
    trainingResults,
    modalLoaded,
    backendThresholdByRoutine,
    insightsEnabled,
    components,
  ])

  const isLoading = !tableData || !carousels

  return (
    <section
      className={`${Styles.carouselsWrapper} ${!tableData || tableData?.length === 0 ? Styles.containerHeight : ''}`}
    >
      {tool &&
        showCurrentBatchCarousels &&
        connectionStatus !== 'offline' &&
        thresholdForLiveCarousels !== undefined && (
          <LiveBatchCarousels
            inspectionId={inspectionId!}
            threshold={thresholdForLiveCarousels}
            modalLoaded={modalLoaded}
            tool={tool}
            aoiParentId={aoiParentId}
            thresholdByRoutine={backendThresholdByRoutine}
          />
        )}

      {!showCurrentBatchCarousels && connectionStatus !== 'offline' && (
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          expandable={{
            expandedRowRender: record => carousels?.[record.key],
            onExpandedRowsChange: handleOnExpandedRowsChange,
            expandRowByClick: true,
            expandIconColumnIndex: -1,
          }}
          className={Styles.tableContainer}
          components={{
            body: { row: 'div' },
          }}
          rowClassName={record => {
            let rowClassName = `${Styles.tableRow} `
            if (expandedRowsIds.includes(record.key)) rowClassName += Styles.rowIsActive
            return rowClassName
          }}
          loading={{
            spinning: isLoading,
            indicator: <PrismLoader />,
          }}
          locale={{
            emptyText: !isLoading ? (
              <GenericBlankStateMessage
                header={<PrismSearchIcon />}
                description="No images in this set"
                className={Styles.emptyStateWrapper}
              />
            ) : (
              <div></div>
            ),
          }}
        />
      )}
      {/* Render detail modal after training report modal has loaded */}
      {detail_tool_result_id && isSharedLinkRef.current && !showCurrentBatchCarousels && modalLoaded && (
        <DetailModal trainingResultsFlat={trainingResults} />
      )}
    </section>
  )
}

const columns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',

    render: (labelData: rowData['label']) => {
      return <ImageAndPrismResult image={labelData?.image?.[0]} value={labelData.value} severity={labelData.severity} />
    },
    sorter: (a: rowData, b: rowData) => a.label.value.localeCompare(b.label.value),
    width: '30%',
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    sorter: (a: rowData, b: rowData) => a.product.localeCompare(b.product),
  },
  {
    title: 'Images',
    dataIndex: 'images',
    key: 'images',
    sorter: (a: rowData, b: rowData) => a.images - b.images,
  },
  {
    title: 'Accuracy',
    dataIndex: 'accuracy',
    key: 'accuracy',
    sorter: (a: rowData, b: rowData) => a.accuracy - b.accuracy,
    render: (accuracyData: rowData['accuracy']) => `${accuracyData.toFixed(1)}%`,
  },
]

/**
 * Renders the first column containing and image with the prismResult (severity icon and label name)
 */
const ImageAndPrismResult = ({
  image,
  value,
  severity,
}: {
  image?: string
  value: string
  severity: LabelButtonSeverity
}) => {
  return (
    <div className={Styles.labelColumn}>
      <figure className={Styles.labelImage}>
        {image && <ImgFallback src={image} loaderType="skeleton" className={Styles.toolColumnImage} />}
        {!image && <PrismElementaryCube />}
      </figure>

      <PrismResultButton type="noFill" size="small" value={value} severity={severity} />
    </div>
  )
}
