import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import DetailItemList from 'components/DetailItemList/DetailItemList'
import ImgFallback, { getFromCache, prefetch } from 'components/Img/ImgFallback'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismSelect, PrismSelectPlaceholder } from 'components/PrismSelect/PrismSelect'
import { Token } from 'components/Token/Token'
import { useData } from 'hooks'
import paths from 'paths'
import { RecipeExpanded, StatusCommandRecipe } from 'types'
import { matchRole, titleCase } from 'utils'

import { AlertTool } from './AlertTool'
import { BATCH_TIMESTAMP_FORMAT, BatchErrorLevels } from './NewBatch'
import Styles from './RecipeSelection.module.scss'

const SELECT_ERROR_MSG: { [key in keyof typeof BatchErrorLevels]: string } = {
  SemiDeployedRecipe: 'This recipe is semi-deployed and will only run on some cameras.',
  TriggerMismatch: 'All views in a recipe must have the same trigger mode.',
  AllRobotsOffline: 'All cameras used in this recipe are offline.',
  SomeRobotsOffline: 'At least one camera is offline and will not run the recipe.',
}

interface RecipeSelectionProps {
  deployedRecipes: StatusCommandRecipe[]
  selectedRecipe?: RecipeExpanded
  onSelectRecipe: (recipeId: string) => void
  batchErrorType?: keyof typeof BatchErrorLevels
  showNewBatchAlert?: boolean
  className?: string
  batchName?: string
  setBatchName: (batchName: string) => void
  stationId: string
}

/**
 * Renders the Recipe Selection Section of the New Batch screen
 *
 * @param deployedRecipes - The current deployed recipes
 * @param selectedRecipe - The currently selected recipe
 * @param onSelectRecipe - Handler to be called when a recipe is selected
 * @param batchErrorType - Type of error in the batch screen
 * @param showNewBatchAlert - Whether we show or not the `No Recipes` alert
 * @param className - string that holds external optional styles
 * @param batchName - Name of the inspection
 * @param setBatchName - Handler to be called when the batchName changes
 */
const RecipeSelection = ({
  selectedRecipe,
  onSelectRecipe,
  deployedRecipes,
  batchErrorType,
  className = '',
  batchName,
  showNewBatchAlert = false,
  setBatchName,
  stationId,
}: RecipeSelectionProps) => {
  const history = useHistory()
  const timestamp = useMemo(() => moment(), [])
  const me = useData(getterKeys.me())

  return (
    <section
      className={`${Styles.recipeSelectionSection} ${className}`}
      data-testid={deployedRecipes.length > 0 ? 'new-batch-recipe-select-ready' : ''}
    >
      <h1 className={Styles.recipeSectionTitle}>Set Up Batch</h1>
      {showNewBatchAlert && (
        <AlertTool
          description="There are no recipes on this station."
          buttonTitle="Add Recipe"
          to={matchRole(me, 'manager') ? paths.stationDetail('recipes', stationId) : undefined}
          warning
        />
      )}
      {!showNewBatchAlert && (
        <>
          <Token label="Recipe" className={Styles.label}>
            <PrismSelect
              size="large"
              filterOption={(inputValue, option) => {
                return !!option?.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
              }}
              value={selectedRecipe?.id}
              onChange={onSelectRecipe}
              showSearch
              placeholder={<PrismSelectPlaceholder title="select recipe" hideImageBorder />}
              className={`${
                batchErrorType
                  ? BatchErrorLevels[batchErrorType] === 'error'
                    ? Styles.selectMismatchError
                    : Styles.selectMismatchWarning
                  : ''
              }`}
              data-testid="new-batch-recipe-select"
              options={deployedRecipes.map(recipeDefinition => ({
                key: recipeDefinition.id,
                value: recipeDefinition.id,
                dataTestId: `new-batch-recipe-select-option-${recipeDefinition.parent.name}`,
                dataTest: 'new-batch-recipe-select-option',
                content: getRecipeDisplayName(recipeDefinition),
                image: {
                  content: <RecipeOptionImage recipeDefinition={recipeDefinition} />,
                },
              }))}
            />

            {batchErrorType && (
              <div
                className={
                  BatchErrorLevels[batchErrorType] === 'warning' ? Styles.textMismatchWarning : Styles.textMismatchError
                }
              >
                {SELECT_ERROR_MSG[batchErrorType]}
              </div>
            )}
            {batchErrorType === 'SemiDeployedRecipe' && selectedRecipe && matchRole(me, 'manager') && (
              <Button
                size="small"
                type="secondary"
                className={Styles.recipeButton}
                onClick={() =>
                  history.push(paths.settingsRecipe(selectedRecipe?.parent_id, 'capture', { openDeployModal: true }))
                }
              >
                Re-deploy Recipe
              </Button>
            )}
            {batchErrorType === 'TriggerMismatch' && selectedRecipe && (
              <Button
                size="small"
                type="secondary"
                className={Styles.recipeButton}
                onClick={() => history.push(paths.settingsRecipe(selectedRecipe?.parent_id, 'capture'))}
              >
                Edit Recipe
              </Button>
            )}
          </Token>

          <Token label="Name (Optional)" className={Styles.label}>
            <PrismInput
              value={batchName}
              onChange={e => setBatchName(e.target.value)}
              placeholder={`Batch ${timestamp.format(BATCH_TIMESTAMP_FORMAT)}`}
              size="large"
              data-testid="new-batch-name-input"
              maxLength={100}
            />
          </Token>

          <Token label="Production Targets" className={Styles.label}>
            <DetailItemList
              list={[
                {
                  label: 'yield',
                  value: selectedRecipe?.user_settings?.production_targets
                    ? `${selectedRecipe.user_settings.production_targets?.target_yield}%`
                    : '--',
                },
                {
                  label: 'items / min',
                  value: selectedRecipe
                    ? selectedRecipe.user_settings?.production_targets?.units_per_minute || '--'
                    : '--',
                },
              ]}
            />
          </Token>
        </>
      )}
    </section>
  )
}

export default RecipeSelection

/**
 * The following hook takes care of using a cached image when given a recipe. This hook assumes that the recipe to be used comes
 * asset-management, so the recipe's image url will expire after one hour of deploy. This makes the urls from said recipes basically
 * useless, so we must fetch the recipes with a valid image from Django if we haven't stored them in the app's cache.
 *
 * This hook also has a timeout of 200ms, in case we're scrolling and the component using this hook mounts and unmounts quickly
 * we want to avoid fetching images.
 *
 * @param recipeDefinition - The recipe received from asset-management or vision-processing. Most likely contains an expired url
 * @returns an array of two elements: cachedImage and cachedThumbnailImage. Alternatively, these two entries in the array can be Undefined or Null.
 *  Returning undefined means the cached image or request to django is loading, returning null means it failed to load, or no image was obtained.
 */
const useCachedImageFromRecipeDefinition = (
  recipeDefinition?: StatusCommandRecipe,
): [string | null | undefined, string | null | undefined] => {
  const [cachedImage, setCachedImage] = useState<string | null>()
  const [cachedThumbnailImage, setCachedThumbnailImage] = useState<string | null>()

  useEffect(() => {
    if (!recipeDefinition) return

    let timeout: ReturnType<typeof setTimeout>

    const currentCachedImage = getFromCache(recipeDefinition.parent.fallback_images[0]?.image)
    const currentCachedThumbnailImage = getFromCache(recipeDefinition.parent.fallback_images[0]?.image_thumbnail)

    if (currentCachedImage) setCachedImage(currentCachedImage.src)
    if (currentCachedThumbnailImage) setCachedThumbnailImage(currentCachedThumbnailImage.src)

    const fetchRecipe = async () => {
      if (!recipeDefinition) return
      timeout = setTimeout(async () => {
        const recipeRes = await service.getRecipe(recipeDefinition.id)
        if (recipeRes.type === 'success') {
          const recipe = recipeRes.data
          if (recipe.parent.fallback_images[0]) prefetch(recipe.parent.fallback_images[0].image)
          if (recipe.parent.fallback_images[0]) prefetch(recipe.parent.fallback_images[0].image_thumbnail)

          setCachedImage(recipe.parent.fallback_images[0]?.image || null)
          setCachedThumbnailImage(recipe.parent.fallback_images[0]?.image_thumbnail || null)
        } else {
          if (!currentCachedImage) setCachedImage(null)
          if (!currentCachedThumbnailImage) setCachedThumbnailImage(null)
        }
      }, 200)
    }

    if (!currentCachedImage || !currentCachedThumbnailImage) {
      fetchRecipe()
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [recipeDefinition])

  return [cachedImage, cachedThumbnailImage]
}

const getRecipeDisplayName = (recipe: StatusCommandRecipe) => {
  const labelParts = [recipe.parent.component_name, recipe.parent.name]
    .filter((s): s is string => !!s)
    .map(part => titleCase(part))

  labelParts.push(`v${recipe.version}`)
  const label = labelParts.join(' - ')

  return label
}

const RecipeOptionImage = ({ recipeDefinition }: { recipeDefinition: StatusCommandRecipe }) => {
  const [cachedImage, cachedThumbnailImage] = useCachedImageFromRecipeDefinition(recipeDefinition)

  if (cachedThumbnailImage !== null || cachedImage !== null) {
    return <ImgFallback src={cachedThumbnailImage || cachedImage || undefined} loaderType="skeleton" />
  }
  if (cachedThumbnailImage === null && cachedImage === null) {
    return <PrismElementaryCube />
  }
}
