// This file contains all inline styles from a programatically created stylesheet.
// This is necessary for the pdf rendering

import { Font, StyleSheet } from '@react-pdf/renderer'

import EuclidMediumFont from '../../assets/fonts/euclid/EuclidCircularA-Medium.otf'
// Fonts (importing the font file as variables)
// for some reason the library can't read font weight of the type .ttf (truetype), we use as an alternative the .otf (openType)
import EuclidFont from '../../assets/fonts/euclid/EuclidCircularA-Regular.otf'
import EuclidSemiBoldFont from '../../assets/fonts/euclid/EuclidCircularA-Semibold.otf'
import IbmPlexFont from '../../assets/fonts/euclid/IBMPlexMono-Regular.ttf'
import OverpassMonoFont from '../../assets/fonts/euclid/OverpassMono-VariableFont_wght.ttf'

// table column's width

// Point (units) conversion value
const Pt_VALUE = 0.75
// Adding an extra because the conversion is right but visually looks like the measures are bigger.
const ADDITIONAL_MAGIC_NUMBER = 1.58

// converts the pixels value from figma to point units
export function pixelsIntoPoints(pixelValue: number) {
  return (pixelValue * Pt_VALUE) / ADDITIONAL_MAGIC_NUMBER
}

// Colors
const NEUTRAL_GRAY = '#EDEDF0'
const SMOKY_BLACK_60 = '#757575'
const SMOKY_BLACK_80 = '#4A4A4A'
const SMOKY_BLACK_94 = '#2A2A2A'
const BASE_BLACK = '#111'
const BASE_WHITE = '#fefefe'
const CARD_BORDER = '#dedee3'

/* Fonts register*/
// React-pdf don't support font weight
// Euclid regular
Font.register({
  family: 'euclid',
  fonts: [
    { src: EuclidFont, fontWeight: 400 },
    { src: EuclidMediumFont, fontWeight: 500 },
    { src: EuclidSemiBoldFont, fontWeight: 600 },
  ],
  format: 'truetype',
})

// IBM plex mono
Font.register({
  family: 'IBM',
  src: IbmPlexFont,
  format: 'truetype',
  fontWeight: 400,
})

// Overpass mono
Font.register({
  family: 'overpass-mono',
  src: OverpassMonoFont,
  format: 'truetype',
  fontWeight: 500,
})

// Create styles
const styles = StyleSheet.create({
  documentTitle: {
    textTransform: 'capitalize',
  },

  // Document page
  page: {
    paddingTop: pixelsIntoPoints(80),
    paddingBottom: pixelsIntoPoints(260),
    width: '100%',
  },
  pageInLandscape: {
    paddingTop: pixelsIntoPoints(80),
    paddingBottom: pixelsIntoPoints(180),
    width: '100%',
  },

  // Header
  headerWrapper: {
    flexDirection: 'row',
    marginRight: pixelsIntoPoints(80),
    marginLeft: pixelsIntoPoints(80),
  },

  headerLeftBox: {
    flexGrow: 1,
  },
  headerRightBox: {
    width: 'auto',
    height: pixelsIntoPoints(24),
    backgroundColor: NEUTRAL_GRAY,
    color: SMOKY_BLACK_80,
    fontSize: pixelsIntoPoints(12),
    fontFamily: 'IBM',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    padding: `0cm ${pixelsIntoPoints(8)}`,
    borderRadius: pixelsIntoPoints(1),
  },
  headerTitle: {
    fontFamily: 'euclid',
    fontSize: pixelsIntoPoints(32),
    color: BASE_BLACK,
  },
  headerDescriptionWrapper: {
    flexDirection: 'row',
    marginTop: pixelsIntoPoints(32),
    height: pixelsIntoPoints(24),
    fontFamily: 'IBM',
    fontSize: pixelsIntoPoints(12),
    textTransform: 'uppercase',
    color: BASE_BLACK,
  },

  headerIconAndDescriptionLeftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  headerDescriptionDate: {
    marginLeft: pixelsIntoPoints(8),
  },

  headerIconAndDescriptionRightContainer: {
    marginLeft: pixelsIntoPoints(32),
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerDescriptionLink: {
    marginLeft: pixelsIntoPoints(8),
  },
  headerLink: {
    color: BASE_BLACK,
    fontFamily: 'IBM',
    fontSize: pixelsIntoPoints(12),
  },
  footerLink: {
    color: SMOKY_BLACK_60,
    fontFamily: 'IBM',
    fontSize: pixelsIntoPoints(12),
    textDecoration: 'none',
  },

  // Body
  bodyWrapper: {
    marginTop: pixelsIntoPoints(64),
    marginLeft: pixelsIntoPoints(66),
    marginRight: pixelsIntoPoints(66),
  },
  tableBodyWrapper: {
    marginLeft: pixelsIntoPoints(80),
    marginRight: pixelsIntoPoints(80),
  },

  // Card and graphs container
  gridCardWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 'auto',
  },

  // Graph cards
  graphContainer: {
    flexGrow: 1,
    flexBasis: '50%',
    maxWidth: pixelsIntoPoints(552),
    height: pixelsIntoPoints(270),
    margin: pixelsIntoPoints(12),
    paddingTop: pixelsIntoPoints(28),
    paddingLeft: pixelsIntoPoints(32),
    paddingRight: pixelsIntoPoints(32),
    paddingBottom: pixelsIntoPoints(33),
    backgroundColor: BASE_WHITE,
    border: `${pixelsIntoPoints(1)} solid ${CARD_BORDER}`,
    borderRadius: pixelsIntoPoints(3),
  },
  landscapeGraphContainer: {
    flexBasis: '31.7%',
    height: pixelsIntoPoints(270),
    margin: pixelsIntoPoints(12),
    paddingTop: pixelsIntoPoints(28),
    paddingLeft: pixelsIntoPoints(32),
    paddingRight: pixelsIntoPoints(32),
    paddingBottom: pixelsIntoPoints(33),
    backgroundColor: BASE_WHITE,
    border: `${pixelsIntoPoints(1)} solid ${CARD_BORDER}`,
    borderRadius: pixelsIntoPoints(3),
  },
  graphHeader: {
    marginBottom: pixelsIntoPoints(32),
    fontFamily: 'euclid',
    fontWeight: 400,
    color: SMOKY_BLACK_94,
  },

  graphHeaderTitle: {
    fontSize: pixelsIntoPoints(10.67),
    textTransform: 'uppercase',
    letterSpacing: pixelsIntoPoints(0.75),
  },

  bigText: {
    fontSize: pixelsIntoPoints(32),
    color: BASE_BLACK,
    marginTop: pixelsIntoPoints(20),
    paddingBottom: pixelsIntoPoints(24),
  },

  smallText: {
    fontSize: pixelsIntoPoints(12),
    color: SMOKY_BLACK_60,
    marginTop: pixelsIntoPoints(12),
    letterSpacing: pixelsIntoPoints(0.5),
    paddingBottom: pixelsIntoPoints(24),
  },

  graphBody: {
    height: '100%',
    width: '100%',
  },

  graphBodyEmpty: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  graphBodyEmptyText: {
    fontFamily: 'euclid',
    fontSize: pixelsIntoPoints(12),
    fontWeight: 400,
    color: SMOKY_BLACK_60,
  },

  // Bar chart
  barChartWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: pixelsIntoPoints(20),
    color: SMOKY_BLACK_80,
    marginBottom: pixelsIntoPoints(8),
  },
  productTitle: {
    fontSize: pixelsIntoPoints(9),
    maxWidth: '100%',
    fontFamily: 'euclid',
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: pixelsIntoPoints(0.75),
  },
  productGraph: {
    borderStyle: 'dotted',
    borderColor: 'red',
    borderWidth: pixelsIntoPoints(1),
    flexGrow: 1,
    margin: `0 ${pixelsIntoPoints(8)}`,
    height: pixelsIntoPoints(10),
  },
  productValue: {
    fontFamily: 'overpass-mono',
    fontSize: pixelsIntoPoints(10.67),
    fontWeight: 500,
  },

  // Result Cards
  cardContainer: {
    backgroundColor: BASE_WHITE,
    flexGrow: 1,
    flexBasis: '16%',
    maxWidth: pixelsIntoPoints(210),
    height: pixelsIntoPoints(236),
    margin: pixelsIntoPoints(12),
    padding: pixelsIntoPoints(16),
    borderRadius: pixelsIntoPoints(3),
    border: `${pixelsIntoPoints(1)} solid ${CARD_BORDER}`,
    position: 'relative',
  },

  cardContainerLandscapeMode: {
    maxWidth: pixelsIntoPoints(195),
  },

  outcomeResultIcon: {
    position: 'absolute',
    top: pixelsIntoPoints(24),
    left: pixelsIntoPoints(24),
  },

  cardImageContainer: {
    height: '100%',
    border: `1px solid ${NEUTRAL_GRAY}`,
  },

  cardImage: {
    height: '100%',
    objectFit: 'cover',
  },

  cardElementaryCube: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardDescriptionContainer: {
    height: pixelsIntoPoints(40),
    marginTop: pixelsIntoPoints(16),
    fontFamily: 'euclid',
    color: SMOKY_BLACK_80,
  },

  cardDescriptionTitle: {
    fontSize: pixelsIntoPoints(9),
    textTransform: 'uppercase',
  },

  cardResultWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    transform: 'translateY(4)',
  },

  cardResultContainer: {
    marginRight: pixelsIntoPoints(4),
    flexDirection: 'row',
    alignItems: 'center',
    border: `1px solid ${BASE_WHITE}`,
  },

  cardOutcomeDiscard: {
    height: pixelsIntoPoints(20),
    width: pixelsIntoPoints(20),
  },

  cardResultEmpty: {
    fontSize: pixelsIntoPoints(12),
    fontWeight: 500,
    color: SMOKY_BLACK_94,
    marginLeft: pixelsIntoPoints(4),
  },

  cardResult: {
    marginLeft: pixelsIntoPoints(1),
    fontSize: pixelsIntoPoints(12),
    fontWeight: 500,
    color: SMOKY_BLACK_94,
    border: `1px solid ${BASE_WHITE}`,
  },

  cardResultIcon: {
    height: pixelsIntoPoints(21),
    width: pixelsIntoPoints(21),
    paddingLeft: pixelsIntoPoints(6),
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${BASE_WHITE}`,
    transform: `translateY(${pixelsIntoPoints(-2)})`,
  },

  cardResultExtraLabels: {
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: pixelsIntoPoints(12),
    color: SMOKY_BLACK_94,
    border: `1px solid ${BASE_WHITE}`,
  },

  // Table
  tableContainer: {
    width: 'auto',
    backgroundColor: BASE_WHITE,
    border: `${pixelsIntoPoints(1)} solid ${CARD_BORDER}`,
  },

  tableHeaderCell: {
    fontSize: pixelsIntoPoints(10.67),
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: pixelsIntoPoints(16),
    color: SMOKY_BLACK_94,
    fontFamily: 'euclid',
    fontWeight: 'medium',
    letterSpacing: pixelsIntoPoints(0.75),
    textTransform: 'uppercase',
  },

  tableHeaderRow: {
    margin: 'auto',
    flexDirection: 'row',
    borderBottom: '',
    height: pixelsIntoPoints(42),
    borderStyle: 'solid',
    borderWidth: pixelsIntoPoints(1),
    borderColor: NEUTRAL_GRAY,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    width: '100%',
    alignItems: 'center',
  },

  tableFirstColumn: {
    justifyContent: 'center',
    height: '16',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    paddingRight: pixelsIntoPoints(8),
  },

  tablePhotoInFirstColumn: {
    justifyContent: 'center',
    height: '16',
    paddingRight: pixelsIntoPoints(8),
    width: pixelsIntoPoints(100),
  },

  tableCenterColumn: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    height: '16',
    paddingRight: pixelsIntoPoints(2),
  },

  tableToolResultColumn: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    flexBasis: '4%',
    height: '16',
    paddingRight: pixelsIntoPoints(2),
  },

  tableItemsLastColumn: {
    alignItems: 'center',
    flexDirection: 'row',
    height: '16',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '5%',
    paddingRight: pixelsIntoPoints(8),
  },

  tableLastColumn: {
    alignItems: 'center',
    flexDirection: 'row',
    height: '16',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '10%',
    paddingRight: pixelsIntoPoints(8),
  },

  tableBodyRow: {
    height: pixelsIntoPoints(62),
    margin: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'euclid',
    fontSize: pixelsIntoPoints(14),
    color: SMOKY_BLACK_80,
    borderColor: NEUTRAL_GRAY,
    borderStyle: 'solid',
    borderWidth: pixelsIntoPoints(1),
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    width: '100%',
    textTransform: 'capitalize',
  },

  tableCell: {
    fontSize: pixelsIntoPoints(14),
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: pixelsIntoPoints(16),
  },

  pdfTableIcon: {
    position: 'relative',
    left: pixelsIntoPoints(6),
  },

  pdfTableIconText: {
    fontSize: pixelsIntoPoints(14),
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: pixelsIntoPoints(4),
  },

  pdfIconContainer: {
    position: 'relative',
    left: pixelsIntoPoints(16),
    marginRight: pixelsIntoPoints(8),
  },

  fixedText: {
    width: pixelsIntoPoints(70),
    textAlign: 'left',
  },

  photoInFirstColumn: {
    width: pixelsIntoPoints(91),
    height: pixelsIntoPoints(50),
    marginLeft: pixelsIntoPoints(8),
    justifyContent: 'flex-start',
    paddingLeft: pixelsIntoPoints(6),
  },

  photo: {
    width: pixelsIntoPoints(50),
    height: '100%',
    objectFit: 'contain',
  },

  graphImageContainer: {
    marginLeft: pixelsIntoPoints(8),
    width: pixelsIntoPoints(130),
  },

  graphImage: {
    objectFit: 'contain',
    width: '100%',
  },

  // Footer
  footerWrapper: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: pixelsIntoPoints(40),
    left: pixelsIntoPoints(80),
    right: pixelsIntoPoints(80),
    fontFamily: 'IBM',
    color: SMOKY_BLACK_60,
    fontSize: pixelsIntoPoints(12),
    textTransform: 'uppercase',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  footerIcon: {
    position: 'absolute',
    left: '48.5%',
    textAlign: 'center',
  },

  urlAndPageNumber: {
    width: 'auto',
    height: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  urlContainer: {
    marginRight: pixelsIntoPoints(194),
  },
})

export default styles
