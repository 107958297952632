import React, { useMemo } from 'react'

import { Controller, useForm } from 'react-hook-form'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import { PrismWarningIcon } from 'components/prismIcons'
import { PrismInput, PrismInputNumber } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal, modal } from 'components/PrismModal/PrismModal'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { useData } from 'hooks'
import { User, UserRole } from 'types'
import { getSessionLengthDisplayText, getSessionLengthUnitsAndTime, includes } from 'utils'
import { SECONDS_IN_DAY, SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from 'utils/constants'

import { renderRole } from './UsersTable'
import Styles from './UsersTable.module.scss'

type Props = {
  onCancel: () => any
  onRefresh: () => any
  user?: User
}
function UserConfigurationModal({ user, onRefresh, onCancel }: Props) {
  const me = useData(getterKeys.me())

  const defaultValues = getDefaultFormValues(user)
  const {
    formState: { isDirty, isValid, errors },
    control,
    trigger,
    getValues,
    watch,
  } = useForm({ defaultValues, mode: 'onChange' })

  const { sessionLengthTime, sessionLengthUnits } = watch()

  // If we're trying to edit a user that's an owner or a manager when logged in as anything but `owner`
  const noPermissionToEdit = useMemo(() => {
    return includes(['owner', 'manager'], user?.role) && me?.role !== 'owner'
  }, [user, me])

  const inactiveUser = user && !user.is_active

  const restoreSaveOrEditUser = async () => {
    if (inactiveUser) {
      editUserIsActive(true)
      return
    }

    const valid = await trigger()
    if (!valid) return 'invalid'

    const { first_name, last_name, email, role, sessionLengthTime, sessionLengthUnits } = getValues()

    let sessionLengthSeconds = sessionLengthTime
    if (sessionLengthUnits === 'day') sessionLengthSeconds *= SECONDS_IN_DAY
    else if (sessionLengthUnits === 'hour') sessionLengthSeconds *= SECONDS_IN_HOUR
    else if (sessionLengthUnits === 'minute') sessionLengthSeconds *= SECONDS_IN_MINUTE

    // editing
    if (user?.id) {
      const res = await service.patchUser(user?.id, {
        first_name,
        last_name,
        role,
        session_length_s: sessionLengthSeconds,
      })

      if (res.type !== 'success') return error({ title: 'Could not update user, make sure fields are correct' })

      success({ title: 'Edited' })
    }

    // creating
    else {
      const res = await service.createUser({
        first_name,
        last_name,
        email,
        role,
        session_length_s: sessionLengthSeconds,
      })

      if (res.type !== 'success') return error({ title: 'Could not create user, make sure fields are correct' })

      success({ title: `Activation email sent to ${first_name}`, 'data-testid': 'new-user-success' })
    }

    onRefresh()
  }

  const editUserIsActive = async (activate: boolean, close?: () => any) => {
    if (!user?.id) return

    await service.patchUser(user.id, { is_active: activate })
    const messageText = activate
      ? `${user.first_name} ${user.last_name} restored`
      : `${user.first_name} ${user.last_name} deleted`

    success({ title: messageText })

    onRefresh()

    close?.()
  }

  let extraButtons
  if (user?.id) {
    if (user.is_active)
      extraButtons = (
        <Button
          type="secondary"
          size="small"
          onClick={() => {
            modal.warning({
              id: 'delete-user-confirmation',
              header: (
                <div className={Styles.warningModalTitle}>
                  <PrismWarningIcon className={Styles.warningIconContainer} />
                  Are you sure ?
                </div>
              ),
              onOk: close => editUserIsActive(false, close),
              okText: 'Delete',
              content: 'This user will no longer be able to log in. Their inspections, however, will be preserved.',
            })
          }}
          disabled={noPermissionToEdit}
          data-testid="user-delete"
        >
          Delete
        </Button>
      )
  }

  let modalTitle = 'New User'
  if (user) {
    modalTitle = 'Edit User'
    if (!user.is_active) modalTitle = 'Restore User'
  }

  return (
    <>
      <Modal
        id="add-user"
        className={Styles.modalContainer}
        modalBodyClassName={Styles.modalBodyContainer}
        onClose={onCancel}
        size="largeNarrow"
        header={modalTitle}
        okText={inactiveUser ? 'Restore' : 'Save'}
        onOk={restoreSaveOrEditUser}
        extraButtons={extraButtons}
        disableSave={(!isDirty || !isValid || noPermissionToEdit) && !inactiveUser}
        data-testid="new-user-modal"
      >
        <Controller
          name="first_name"
          rules={{ required: 'First Name is required' }}
          control={control}
          render={props => (
            <PrismInput
              size="small"
              autoFocus
              {...props}
              label="First Name"
              disabled={noPermissionToEdit || inactiveUser}
              errors={errors}
              data-testid="new-user-first-name"
            />
          )}
        />

        <Controller
          name="last_name"
          rules={{ required: 'Last Name is required' }}
          control={control}
          render={props => (
            <PrismInput
              size="small"
              {...props}
              label="Last Name"
              disabled={noPermissionToEdit || inactiveUser}
              errors={errors}
              data-testid="new-user-last-name"
            />
          )}
        />

        <Controller
          name="email"
          rules={{ required: 'Email is required' }}
          control={control}
          render={props => (
            <PrismInput
              size="small"
              {...props}
              label="Email Address"
              disabled={noPermissionToEdit || inactiveUser}
              errors={errors}
              data-testid="new-user-email"
            />
          )}
        />

        <div>
          <div className={Styles.label}>Role</div>
          <Controller
            name="role"
            rules={{ required: true }}
            control={control}
            render={({ onChange, value }) => (
              <>
                {noPermissionToEdit ? (
                  <div className={Styles.disabledInput}>{renderRole(value)}</div>
                ) : (
                  <PrismSelect
                    className={Styles.roleInput}
                    popupClassName={Styles.roleDropdownItems}
                    value={value}
                    onChange={onChange}
                    disabled={noPermissionToEdit || inactiveUser}
                    data-testid="user-roles"
                    options={[
                      {
                        value: 'owner',
                        title: 'admin',
                        isHidden: !(me?.role === 'owner'),
                        dataTestId: 'user-role-owner',
                      },
                      {
                        value: 'manager',
                        title: 'editor',
                        isHidden: !(me?.role === 'owner'),
                        dataTestId: 'user-role-manager',
                      },
                      { value: 'inspector', title: 'operator', dataTestId: 'user-role-inspector' },
                      { value: 'member', title: 'viewer', dataTestId: 'user-role-member' },
                    ]}
                  />
                )}

                <div className={Styles.roleDescription}>{renderRoleDescription(value)}</div>
              </>
            )}
          />
        </div>

        <div>
          <div className={Styles.sessionLengthControls}>
            <Controller
              name="sessionLengthTime"
              rules={{
                required: true,
                validate: value => {
                  const units = getValues().sessionLengthUnits
                  if (value < 1 || (units === 'minute' && value < 15)) return 'Minimum length is 15 minutes'
                },
              }}
              control={control}
              render={props => (
                <>
                  <PrismInputNumber
                    {...props}
                    onChange={value => {
                      props.onChange(value || 0)
                      trigger('sessionLengthUnits')
                    }}
                    errors={errors}
                    label="Session length"
                    data-testid="session-length-input"
                    type="number"
                    disabled={noPermissionToEdit || inactiveUser}
                  />
                </>
              )}
            />
            <Controller
              name="sessionLengthUnits"
              control={control}
              rules={{
                required: true,
                validate: units => {
                  const time = getValues().sessionLengthTime
                  if (time < 1 || (units === 'minute' && time < 15)) return 'Minimum length is 15 minutes'
                },
              }}
              render={props => (
                <PrismSelect
                  {...props}
                  onChange={e => {
                    props.onChange(e)
                    trigger('sessionLengthTime')
                  }}
                  className={`${Styles.timeUnitSelect}`}
                  data-testid="session-length-unit"
                  size="large"
                  disabled={noPermissionToEdit || inactiveUser}
                  options={[
                    { value: 'day', title: 'days', dataTestId: 'session-length-days' },
                    { value: 'hour', title: 'hours', dataTestId: 'session-length-hours' },
                    { value: 'minute', title: 'minutes', dataTestId: 'session-length-minutes' },
                  ]}
                />
              )}
            />
          </div>
          <div className={Styles.roleDescription}>
            User will be logged out {getSessionLengthDisplayText(sessionLengthTime, sessionLengthUnits)} after they log
            in.
          </div>
        </div>
      </Modal>
    </>
  )
}

const getDefaultFormValues = (user?: User) => {
  const timeAndUnits = getSessionLengthUnitsAndTime(user?.session_length_s)

  return {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    role: user?.role || 'member',
    sessionLengthTime: timeAndUnits?.time,
    sessionLengthUnits: timeAndUnits?.unit,
  }
}

const renderRoleDescription = (role: UserRole | undefined) => {
  switch (role) {
    case 'member':
      return 'Can observe inspections, look at items, and monitor results'
    case 'inspector':
      return 'Can run inspections, label items, and monitor results.'
    case 'manager':
      return 'Can set up inspections, run them, label items, and monitor results. Can edit operators and viewers.'
    case 'owner':
      return 'Can set up inspections, run them, label items, and monitor results. Can edit admins, editors, operators, and viewers.'
    default:
      return ''
  }
}

export default UserConfigurationModal
