import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { Document, usePDF } from '@react-pdf/renderer'
import { Radio } from 'antd'
import { toJpeg } from 'html-to-image'
import { isNumber } from 'lodash'
import momentTz from 'moment-timezone'
import { Provider, ReactReduxContext } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button/Button'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { PrismGraphProgressBar } from 'components/PrismGraphProgressBar/PrismGraphProgressBar'
import { PrismAddIcon, PrismCopyIcon } from 'components/prismIcons'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { success } from 'components/PrismMessage/PrismMessage'
import { ModalBody, ModalFooter, ModalHeader, ModalProps, PrismModal } from 'components/PrismModal/PrismModal'
import { dismiss, info } from 'components/PrismNotification/PrismNotification'
import { PdfDashboard } from 'components/PrismPdf/PdfDashboard'
import { PdfGallery } from 'components/PrismPdf/PdfGallery'
import { PdfTable } from 'components/PrismPdf/PdfTable'
import PrismPdf from 'components/PrismPdf/PrismPdfBase'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { Tag } from 'components/Tag/Tag'
import { Token } from 'components/Token/Token'
import { useAllToolLabels, useAnalyticsQueryFilters, useDateTimePreferences } from 'hooks'
import CountGraph from 'pages/StationDetail/Components/CountGraph'
import { YieldGraph } from 'pages/StationDetail/Components/YieldGraph'
import Shared from 'styles/Shared.module.scss'
import {
  analyzeTabs,
  Item,
  ItemWithFallbackImages,
  PdfChartContent,
  PdfChartData,
  PdfContent,
  PdfData,
  PdfDataItem,
  PdfYieldAndCountRow,
  ToolResult,
  ToolResultEmptyOutcome,
  ToolResultOutcome,
} from 'types'
import {
  getDisplaySeverity,
  getDisplayThreshold,
  getImageFromItem,
  getLabelName,
  getToolResultLabels,
  isLabelDiscard,
  renderLargeNumber,
  sleep,
  titleCase,
  wasToolResultMuted,
} from 'utils'
import { ANALYZE_MINI_CHARTS_WIDTH, ANALYZE_ROW_WITH_CHART_HEIGHT } from 'utils/constants'

import Styles from './Analyze.module.scss'
import { TOOLTIP_POSITION } from './AnalyzeBase'

type ShareTab = 'link' | 'pdf' | 'table' | 'report'

type ShareModalProps = Pick<ModalProps, 'onClose'> & {
  pdfData: PdfData | undefined
}

const ShareModal = ({ pdfData, onClose }: ShareModalProps) => {
  const { store } = useContext(ReactReduxContext)

  const { allToolLabels } = useAllToolLabels()

  const [{ period, end, start }] = useAnalyticsQueryFilters({ tab: 'overview' })

  const [pdfContent, setPdfContent] = useState<PdfContent>()

  const cancelRenderRef = useRef(false)

  const { timeFormat, dateFormat, timeZone } = useDateTimePreferences()
  const nowString = momentTz().tz(timeZone).format(`${dateFormat}, ${timeFormat}`)

  const [shareTab, setShareTab] = useState<ShareTab>('link')

  const [pdfOrientation, setPdfOrientation] = useState<'portrait' | 'landscape'>('portrait')
  const [progressValue, setProgressValue] = useState(0)

  const portalContainerRef = useRef<HTMLDivElement>(document.createElement('div'))

  const location = useLocation()

  const tab = useMemo(() => {
    return analyzeTabs.find(tab => location.pathname.includes(tab))
  }, [location.pathname])

  const validateCancel = useCallback((element: HTMLElement | null) => {
    if (cancelRenderRef.current && element) {
      cancelRenderRef.current = false
      ReactDOM.unmountComponentAtNode(element)
      setProgressValue(0)
      return true
    }
  }, [])

  useEffect(() => {
    portalContainerRef.current.setAttribute('id', 'pdf-render-container')
    portalContainerRef.current.className = `${Styles.hiddenGraphRender} ${Styles.analyzeGridContainer}`
    document.body.appendChild(portalContainerRef.current)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document.body.removeChild(portalContainerRef.current)
    }
  }, [])

  const renderPdf = async () => {
    setProgressValue(1)
    // We want to wait for the `renderingPDF` state to be applied before we start rendering
    // content in the hidden DOM, othewise the screen freezes while it loads and we give
    // the user no feedback indicating the content is being generated.
    await sleep(100)
    const element = document.getElementById('pdf-render-container')
    if (pdfData?.type === 'charts') renderChartData(element, pdfData.chartContent)
    if (pdfData?.type === 'yieldAndCount') renderYieldCountTable(element, pdfData.title, pdfData.rows)
    if (pdfData?.type === 'items') renderItems(element, pdfData.viewMode, pdfData.content)
    if (pdfData?.type === 'toolResults') renderToolResults(element, pdfData.viewMode, pdfData.content)
  }

  const pdfDocument = useMemo(() => {
    if (!pdfContent) return <Document />
    const renderDate =
      pdfContent.startDate === 'allTime' || pdfContent.endDate === 'allTime'
        ? 'All Time'
        : `${pdfContent.startDate.format(dateFormat)} - ${pdfContent.endDate.format(dateFormat)}`

    return (
      <PrismPdf
        title={titleCase(tab || '')}
        date={renderDate}
        link={window.location.href}
        orientation={pdfOrientation}
        tableBodyWrapper={pdfContent.type === 'table'}
        nowString={nowString}
      >
        {pdfContent.type === 'dashboard' && <PdfDashboard orientation={pdfOrientation} charts={pdfContent.charts} />}

        {pdfContent.type === 'table' && (
          <PdfTable orientation={pdfOrientation} columns={pdfContent.columns} dataSource={pdfContent.dataSource} />
        )}

        {pdfContent.type === 'gallery' && <PdfGallery cards={pdfContent.cards} orientation={pdfOrientation} />}
      </PrismPdf>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfContent])

  const [pdfInstance, updatePdfInstance] = usePDF({ document: pdfDocument })

  const renderYieldCountTable = useCallback(
    (element: HTMLElement | null, title: 'product' | 'station' | 'batch', rows: PdfYieldAndCountRow[]) => {
      if (validateCancel(element)) return
      const pdfColumns = [
        {
          displayName: titleCase(title),
          key: title,
          className: 'tableFirstColumn' as const,
        },
        {
          displayName: 'Yield',
          key: 'yield',
          className: 'tableCenterColumn' as const,
        },
        {
          displayName: 'Items Inspected',
          key: 'items',
          className: 'tableCenterColumn' as const,
        },
        {
          displayName: 'Most Common Defects',
          key: 'defects',
          className: 'tableLastColumn' as const,
        },
      ]

      const startDate =
        start === null ? momentTz(Math.min(...rows.flatMap(row => row.series.map(s => s.ts)))).tz(timeZone) : start

      const endDate =
        end === null ? momentTz(Math.max(...rows.flatMap(row => row.series.map(s => s.ts)))).tz(timeZone) : end

      const progressStep = 97 / rows.length
      setProgressValue(2)
      const renderSubArray = (rows: PdfYieldAndCountRow[], data: PdfDataItem[], limit = 2) => {
        const rowsToUse = rows.slice(0, limit)
        const graphsForPdf = (
          <Provider store={store}>
            {rowsToUse.map((row, key) => (
              <Fragment key={key}>
                <div className={`${Styles.hiddenGraphRender} ${Styles.pdfTableGraph}`}>
                  <div id={`pdf-generation-yield-graph-product-${row.name}`}>
                    <YieldGraph
                      height={ANALYZE_ROW_WITH_CHART_HEIGHT}
                      width={ANALYZE_MINI_CHARTS_WIDTH}
                      yieldSeries={row.series}
                      period={period}
                      hideYAxis
                      hideTicks
                      mode="metrics"
                      fixedTooltipCoords={TOOLTIP_POSITION}
                      pdfFormat="table"
                    />
                  </div>
                </div>

                <div className={`${Styles.hiddenGraphRender} ${Styles.pdfTableGraph}`}>
                  <div id={`pdf-generation-items-graph-product-${row.name}`}>
                    <CountGraph
                      height={ANALYZE_ROW_WITH_CHART_HEIGHT}
                      width={ANALYZE_MINI_CHARTS_WIDTH}
                      mode="metrics"
                      graphSeries={row.series}
                      fixedTooltipCoords={TOOLTIP_POSITION}
                      period={period}
                      hideTicks
                      hideXAxis
                      hideYAxis
                      pdfFormat="table"
                    />
                  </div>
                </div>
              </Fragment>
            ))}
          </Provider>
        )

        ReactDOM.render(graphsForPdf, element, async () => {
          if (validateCancel(element)) return

          const dataSource = await Promise.all(
            rowsToUse.map(async row => {
              const yieldHtml = document.getElementById(`pdf-generation-yield-graph-product-${row.name}`)
              const yieldGraphBase64 =
                yieldHtml && !cancelRenderRef.current ? await toJpeg(yieldHtml, { fontEmbedCSS: '' }) : undefined

              const itemsHtml = document.getElementById(`pdf-generation-items-graph-product-${row.name}`)
              const itemsGraphBase64 =
                itemsHtml && !cancelRenderRef.current ? await toJpeg(itemsHtml, { fontEmbedCSS: '' }) : undefined

              if (!cancelRenderRef.current) setProgressValue(progressValue => progressValue + progressStep)
              return {
                [title]: { value: row.name },
                yield: { value: `${row.yield.toFixed(1)}%`, image: yieldGraphBase64 },
                items: { value: renderLargeNumber(row.count, 1000), image: itemsGraphBase64 },
                defects: {
                  // We only want to show the two most common defects, due to space constraints
                  value: row.defects?.slice(0, 2).map(defect => ({
                    severity: defect.toolLabel.severity,
                    text: `${getLabelName(defect.toolLabel)} (${defect.percentage.toFixed(1)}%)`,
                  })),
                },
              }
            }),
          )
          if (element) ReactDOM.unmountComponentAtNode(element)

          data = [...data, ...dataSource]

          if (validateCancel(element)) return

          if (!rows.length) {
            setPdfContent({
              startDate,
              endDate,
              type: 'table',
              columns: pdfColumns,
              dataSource: data,
            })
            setProgressValue(0)
          } else {
            renderSubArray(rows.slice(limit), data)
          }
        })
      }
      renderSubArray(rows, [])
    },
    [end, period, start, store, timeZone, validateCancel],
  )

  const renderItems = useCallback(
    (element: HTMLElement | null, viewMode: 'gallery' | 'list', items: ItemWithFallbackImages[]) => {
      if (validateCancel(element)) return

      const orderedItems = [...items].sort((itA, itB) => {
        if (itA.created_at > itB.created_at) return 1
        else return -1
      })

      const startDate =
        start === null ? (orderedItems.length ? momentTz(orderedItems[0]!.created_at).tz(timeZone) : 'allTime') : start

      const endDate =
        end === null
          ? orderedItems.length
            ? momentTz(orderedItems[orderedItems.length - 1]!.created_at).tz(timeZone)
            : 'allTime'
          : end

      const progressStep = 87 / items.length
      setProgressValue(2)
      ReactDOM.render(
        <Provider store={store}>
          <ItemPictureRenderer
            items={items}
            onLoad={async () => {
              if (validateCancel(element)) return
              setProgressValue(10)
              if (viewMode === 'gallery') {
                const cards = []
                // This rendering must be done synchronously in order for the canceling functionality to work properly
                for (const item of items) {
                  if (validateCancel(element)) return
                  const image = getImageFromItem(item)
                  let imageUrl: string | null = null
                  if (image) {
                    const imageHtml = document.getElementById(`items-pdf-picture-${item.id}`)
                    imageUrl =
                      imageHtml && !cancelRenderRef.current ? await toJpeg(imageHtml, { fontEmbedCSS: '' }) : null
                  }

                  if (!cancelRenderRef.current) setProgressValue(progressValue => progressValue + progressStep)

                  if (validateCancel(element)) return

                  cards.push({
                    imageUrl,
                    deleted: item.is_deleted,
                    outcome: ['pass', 'fail'].includes(item.calculated_outcome) ? item.calculated_outcome : 'unknown',
                    labels: allToolLabels ? allToolLabels.filter(lbl => item.calculated_labels?.includes(lbl.id)) : [],
                  })
                }

                if (validateCancel(element)) return

                setPdfContent({
                  startDate,
                  endDate,
                  type: 'gallery',
                  cards,
                })
                setProgressValue(0)
              } else {
                const dataSource = []
                // This rendering must be done synchronously in order for the canceling functionality to work properly
                for (const item of items) {
                  if (validateCancel(element)) return
                  const image = getImageFromItem(item)
                  let imageUrl: string | null = null
                  if (image) {
                    const imageHtml = document.getElementById(`items-pdf-picture-${item.id}`)
                    imageUrl =
                      imageHtml && !cancelRenderRef.current ? await toJpeg(imageHtml, { fontEmbedCSS: '' }) : null
                  }
                  if (validateCancel(element)) return

                  if (!cancelRenderRef.current) setProgressValue(progressValue => progressValue + progressStep)

                  let recipeNameAndVersion = ''
                  if (item.inspection.routines)
                    recipeNameAndVersion = `${item.inspection.routines[0]?.parent.recipe_parent_name} v${item.inspection.routines[0]?.parent.recipe_parent_version}`

                  dataSource.push({
                    image: { image: imageUrl },
                    outcome: {
                      value: ['pass', 'fail'].includes(item.calculated_outcome) ? item.calculated_outcome : 'unknown',
                    },
                    toolResults: {
                      // We only want to show the two most common defects, due to space constraints
                      value: allToolLabels
                        ?.filter(lbl => item.calculated_labels?.includes(lbl.id))
                        ?.slice(0, 2)
                        .map(label => ({
                          severity: label.severity,
                          text: getLabelName(label),
                        })),
                    },
                    date: { value: momentTz(item.created_at).tz(timeZone).format(`MMM D, ${timeFormat}`) },
                    itemId: { value: item.serial_number },
                    batch: { value: item.inspection.name },
                    product: { value: item.component.name },
                    recipe: {
                      value: recipeNameAndVersion,
                    },
                  })
                }

                if (validateCancel(element)) return

                setPdfContent({
                  startDate,
                  endDate,
                  type: 'table',
                  columns: itemPdfColumns,
                  dataSource,
                })
                setProgressValue(0)

                if (element) ReactDOM.unmountComponentAtNode(element)
              }
            }}
          />
        </Provider>,
        element,
      )
    },
    [allToolLabels, end, start, store, timeFormat, timeZone, validateCancel],
  )

  const renderToolResults = useCallback(
    (element: HTMLElement | null, viewMode: 'gallery' | 'list', toolResults: ToolResult[]) => {
      if (validateCancel(element)) return

      const orderedToolResults = [...toolResults].sort((itA, itB) => {
        if (itA.created_at > itB.created_at) return 1
        else return -1
      })

      const startDate =
        start === null
          ? orderedToolResults.length
            ? momentTz(orderedToolResults[0]!.created_at).tz(timeZone)
            : 'allTime'
          : start

      const endDate =
        end === null
          ? orderedToolResults.length
            ? momentTz(orderedToolResults[orderedToolResults.length - 1]!.created_at).tz(timeZone)
            : 'allTime'
          : end

      const progressStep = 90 / toolResults.length
      setProgressValue(2)
      ReactDOM.render(
        <Provider store={store}>
          <ToolResultPictureRenderer
            toolResults={toolResults}
            showMuted
            onLoad={async () => {
              if (validateCancel(element)) return
              setProgressValue(10)
              if (viewMode === 'gallery') {
                const cards = []
                // This rendering must be done synchronously in order for the canceling functionality to work properly
                for (const toolResult of toolResults) {
                  if (validateCancel(element)) return
                  let imageUrl: string | null = null
                  const image = toolResult.picture.image
                  if (image && toolResult.aoi) {
                    const imageHtml = document.getElementById(`toolResult-pdf-picture-${toolResult.id}`)
                    imageUrl =
                      imageHtml && !cancelRenderRef.current ? await toJpeg(imageHtml, { fontEmbedCSS: '' }) : null
                  }

                  if (validateCancel(element)) return

                  if (!cancelRenderRef.current) setProgressValue(progressValue => progressValue + progressStep)
                  const outcome: ToolResultOutcome | 'discard' = ['pass', 'fail'].includes(
                    toolResult.calculated_outcome,
                  )
                    ? toolResult.calculated_outcome
                    : 'unknown'

                  const toolResultLabels = getToolResultLabels(toolResult, allToolLabels)
                  cards.push({
                    imageUrl,
                    outcome,
                    deleted: !!toolResultLabels?.some(label => isLabelDiscard(label)),
                    labels: toolResultLabels || [],
                  })
                }

                if (validateCancel(element)) return
                setPdfContent({
                  startDate,
                  endDate,
                  type: 'gallery',
                  cards,
                })
                setProgressValue(0)
              } else {
                const dataSource = []
                // This rendering must be done synchronously in order for the canceling functionality to work properly
                for (const toolResult of toolResults) {
                  if (validateCancel(element)) return
                  let imageUrl: string | null = null
                  const image = toolResult.picture.image
                  if (image && toolResult.aoi) {
                    const imageHtml = document.getElementById(`toolResult-pdf-picture-${toolResult.id}`)
                    imageUrl =
                      imageHtml && !cancelRenderRef.current ? await toJpeg(imageHtml, { fontEmbedCSS: '' }) : null
                  }
                  if (validateCancel(element)) return

                  if (!cancelRenderRef.current) setProgressValue(progressValue => progressValue + progressStep)
                  const toolResultLabels = allToolLabels?.filter(label =>
                    toolResult.active_user_label_set?.tool_labels?.includes(label.id),
                  )

                  let outcome: ToolResultOutcome | 'discard' = 'unknown'
                  if (toolResultLabels?.some(label => isLabelDiscard(label))) outcome = 'discard'
                  else
                    outcome = ['pass', 'fail'].includes(toolResult.calculated_outcome)
                      ? toolResult.calculated_outcome
                      : 'unknown'

                  dataSource.push({
                    image: { image: imageUrl },
                    name: { value: toolResult.tool?.parent_name || '-' },
                    date: { value: momentTz(toolResult.created_at).tz(timeZone).format(`MMM D, ${timeFormat}`) },
                    toolResults: {
                      // We only want to show the two most common defects, due to space constraints
                      value:
                        getToolResultLabels(toolResult, allToolLabels)
                          ?.slice(0, 2)
                          .map(label => ({
                            severity: label.severity,
                            text: getLabelName(label),
                          })) || '--',
                    },
                    outcome: {
                      value: outcome,
                    },
                    score: {
                      value: isNumber(toolResult.prediction_score)
                        ? getDisplayThreshold(
                            toolResult.prediction_score,
                            toolResult.tool?.specification_name || 'deep-svdd',
                          )
                        : '--',
                    },
                    recipe: { value: toolResult.recipe_parent_name || '-' },
                  })
                }

                if (validateCancel(element)) return

                setPdfContent({
                  startDate,
                  endDate,
                  type: 'table',
                  columns: toolResultPdfColumns,
                  dataSource,
                })

                if (element) ReactDOM.unmountComponentAtNode(element)

                setProgressValue(0)
              }
            }}
          />
        </Provider>,
        element,
      )
    },
    [allToolLabels, end, start, store, timeFormat, timeZone, validateCancel],
  )

  const renderChartData = useCallback(
    (element: HTMLElement | null, chartContent: PdfChartContent) => {
      if (validateCancel(element)) return

      const showNoDefectiveProducts = chartContent.productsWithFailYield?.data?.length === 0
      const showNoDefectiveStations = chartContent.stationsWithFailYield?.data?.length === 0
      const showNoDefects = chartContent.mostCommonDefects?.data?.length === 0

      const startDate =
        start === null ? momentTz(Math.min(...chartContent.yield.data.map(mtrc => mtrc.ts))).tz(timeZone) : start
      const endDate =
        end === null ? momentTz(Math.max(...chartContent.yield.data.map(mtrc => mtrc.ts))).tz(timeZone) : end

      // We programatically render the graphs on the hidden container only when the preview button is clicked.
      // This saves memory and render performance since we're not rendering unnecessary components.
      ReactDOM.render(
        // ReactDOM.render creates its own context and, by default, doesn't inherit the current
        // redux store, props and else. Since we use redux to fetch user data
        <Provider store={store}>
          <div id="pdf-generation-yield-graph" className={Styles.hiddenDiv}>
            <YieldGraph
              chartWidth="100%"
              chartHeight={112}
              containerClassName={Styles.pdfGraphContainer}
              yieldSeries={chartContent.yield.data}
              start={start}
              end={end}
              period={period}
              syncId="overview"
              mode="metrics"
              pdfFormat="overview"
            />
          </div>

          <div
            id="pdf-generation-item-count"
            className={`${Styles.hiddenDiv} ${
              chartContent.count.value === 0 ? `${Styles.forceHideTooltip} ${Styles.forceZeroPosition}` : ''
            }`}
          >
            <CountGraph
              chartWidth="100%"
              chartHeight={112}
              graphSeries={chartContent.count.data}
              mode="metrics"
              start={start}
              end={end}
              period={period}
              syncId="overview"
              pdfFormat="overview"
            />
          </div>

          {!showNoDefects && (
            <div id="pdf-generation-common-defects" className={Styles.hiddenDiv}>
              <div className={Styles.pdfGraphProgressContainer}>
                {chartContent.mostCommonDefects?.data?.map(defect => (
                  <PrismGraphProgressBar
                    key={defect.toolLabel.id}
                    type="fail"
                    graphName={
                      <PrismResultButton
                        severity={getDisplaySeverity(defect.toolLabel)}
                        value={getLabelName(defect.toolLabel)}
                        type="pureWhite"
                        className={Styles.defectItemTitle}
                        iconClassName={Styles.pdfSeverityIcon}
                        isPdf
                      />
                    }
                    graphPercentage={defect.percentage.toFixed(1) || 0}
                    graphCount={defect.defectCount}
                    isPdf
                  />
                ))}
              </div>
            </div>
          )}

          {!showNoDefectiveProducts && (
            <div id="pdf-generation-defective-products" className={Styles.hiddenDiv}>
              <div className={Styles.pdfGraphProgressContainer}>
                {chartContent.productsWithFailYield?.data?.map(product => (
                  <PrismGraphProgressBar
                    key={product.id}
                    type="fail"
                    graphName={product.name}
                    graphPercentage={product.failYield?.toFixed(1) || 0}
                    graphCount={product.failCount || 0}
                    isPdf
                  />
                ))}

                {chartContent.productsWithFailYield?.data?.length === 0 && (
                  <div className={Styles.graphContainerEmptyState}>No products match your filters</div>
                )}
              </div>
            </div>
          )}

          {!showNoDefectiveStations && (
            <div id="pdf-generation-defective-stations" className={Styles.hiddenDiv}>
              <div className={Styles.pdfGraphProgressContainer}>
                {chartContent.stationsWithFailYield?.data?.map(station => (
                  <PrismGraphProgressBar
                    key={station.id}
                    type="fail"
                    graphName={station.name}
                    graphPercentage={station.failYield?.toFixed(1) || 0}
                    graphCount={station.failCount || 0}
                    isPdf
                  />
                ))}

                {chartContent.stationsWithFailYield?.data?.length === 0 && (
                  <div className={Styles.graphContainerEmptyState}>No stations match your filters</div>
                )}
              </div>
            </div>
          )}
        </Provider>,
        element,
        async () => {
          if (validateCancel(element)) return

          const yieldGraphHtml = document.getElementById('pdf-generation-yield-graph')
          const yieldGraphBase64 = yieldGraphHtml ? await toJpeg(yieldGraphHtml, { fontEmbedCSS: '' }) : undefined
          if (validateCancel(element)) return
          setProgressValue(20)

          const itemCountHtml = document.getElementById('pdf-generation-item-count')
          const itemCounhBase64 = itemCountHtml ? await toJpeg(itemCountHtml, { fontEmbedCSS: '' }) : undefined
          if (validateCancel(element)) return
          setProgressValue(40)

          let commonDefectsBase64: string | undefined
          if (!showNoDefects) {
            const commonDefectsHtml = document.getElementById('pdf-generation-common-defects')
            commonDefectsBase64 = commonDefectsHtml ? await toJpeg(commonDefectsHtml, { fontEmbedCSS: '' }) : undefined
          }
          if (validateCancel(element)) return
          setProgressValue(60)

          let defectiveProductBase64: string | undefined
          if (!showNoDefectiveProducts) {
            const defectiveProductsHtml = document.getElementById('pdf-generation-defective-products')
            defectiveProductBase64 = defectiveProductsHtml
              ? await toJpeg(defectiveProductsHtml, { fontEmbedCSS: '' })
              : undefined
          }
          if (validateCancel(element)) return
          setProgressValue(80)

          let defectiveStationsBase64: string | undefined
          if (!showNoDefectiveStations) {
            const defectiveStationsHtml = document.getElementById('pdf-generation-defective-stations')
            defectiveStationsBase64 = defectiveStationsHtml
              ? await toJpeg(defectiveStationsHtml, { fontEmbedCSS: '' })
              : undefined
          }
          if (validateCancel(element)) return
          setProgressValue(99)

          if (validateCancel(element)) return

          const chartsToRender: PdfChartData[] = [
            {
              title: 'Yield',
              subtitle: `${chartContent.yield.value.toFixed(1)}%`,
              subtitleSize: 'big',
              imageUrl: yieldGraphBase64,
            },
            {
              title: 'Items Inspected',
              subtitle: renderLargeNumber(chartContent.count.value, 1000),
              subtitleSize: 'big',
              imageUrl: itemCounhBase64,
            },
          ]

          if (chartContent.productInspected)
            chartsToRender.push({
              title: 'Product Inspected',
              subtitle: chartContent.productInspected.value,
              subtitleSize: 'big',
            })

          if (chartContent.mostCommonDefects)
            chartsToRender.push({
              title: 'Most Common Defects',
              subtitle: '% of items with defect',
              subtitleSize: 'small',
              imageUrl: commonDefectsBase64,
              fallbackText: 'No defects match your filters',
            })

          if (chartContent.productsWithFailYield)
            chartsToRender.push({
              title: 'Most Defective Products',
              subtitle: '% of items with defect per product',
              subtitleSize: 'small',
              imageUrl: defectiveProductBase64,
              fallbackText: 'No products match your filters',
            })

          if (chartContent.stationsWithFailYield)
            chartsToRender.push({
              title: 'Most Defective Stations',
              subtitle: '% of items with defect per station',
              subtitleSize: 'small',
              imageUrl: defectiveStationsBase64,
              fallbackText: 'No stations match your filters',
            })

          setPdfContent({
            startDate,
            endDate,
            type: 'dashboard',
            charts: chartsToRender,
          })

          setProgressValue(0)

          // After generating the pdf images necessary, we unmount the component in order to remove unused components.

          if (element) ReactDOM.unmountComponentAtNode(element)
        },
      )
    },
    [end, period, start, store, timeZone, validateCancel],
  )

  useEffect(() => {
    updatePdfInstance(pdfDocument)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfDocument])

  // This effect creates the notification with a button to download the pdf
  useEffect(() => {
    if (!pdfInstance.loading && pdfInstance.blob && pdfContent && !cancelRenderRef.current) {
      const url = URL.createObjectURL(pdfInstance.blob)
      info({
        id: url,
        title: 'Your PDF is ready',
        handleClose: () => URL.revokeObjectURL(url),
        description: 'You can go ahead and download it.',
        children: (
          <a href={url} download={'Analyze.pdf'}>
            <Button
              onClick={() => {
                dismiss(url)
                // can't revoke url immediately, since that causes download to fail
                setTimeout(() => URL.revokeObjectURL(url), 1000 * 60)
              }}
              type="secondary"
              size="small"
            >
              Download
            </Button>
          </a>
        ),
        duration: 0,
      })
      setProgressValue(0)
    } else if (cancelRenderRef.current) {
      cancelRenderRef.current = false
      setProgressValue(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfInstance.loading, pdfInstance.blob])

  const handleCancelRender = useCallback(() => {
    cancelRenderRef.current = true
    setProgressValue(0)
  }, [])

  return (
    <PrismModal
      id="share-modal"
      className={Styles.pdfModalWrapper}
      onClose={() => {
        if (progressValue || pdfInstance.loading) {
          handleCancelRender()
        }
        onClose?.()
      }}
    >
      <ModalHeader
        className={Styles.pdfModalHeaderWrapper}
        onClose={() => {
          if (progressValue || pdfInstance.loading) {
            handleCancelRender()
          }
          onClose?.()
        }}
        modalNav={{
          selectedItems: [shareTab],
          onSelect: val => setShareTab(val as ShareTab),
          items: [{ value: 'link' }, { value: 'pdf' }],
        }}
      >
        Share
      </ModalHeader>

      <ModalBody className={Styles.pdfModalBody}>
        {shareTab === 'link' && (
          <Token label={<p className={Styles.pdfModalBodyTitle}>link address</p>}>
            <div>
              <PrismInput value={window.location.href} readOnly />
              <p className={Styles.linkDescription}>This link is for your currently filtered view.</p>
            </div>
          </Token>
        )}

        {shareTab === 'pdf' && (
          <div className={Shared.verticalChildrenGap8}>
            <p className={Styles.pdfModalBodyTitle}>Orientation</p>
            <Radio.Group
              value={pdfOrientation}
              onChange={e => {
                setPdfOrientation(e.target.value)
              }}
              disabled={!!progressValue}
            >
              <Radio value="portrait" className={Styles.radioOption}>
                Portrait
              </Radio>
              <Radio value="landscape" className={Styles.radioOption}>
                Landscape
              </Radio>
            </Radio.Group>
          </div>
        )}
      </ModalBody>

      <ModalFooter className={Styles.pdfModalFooter}>
        {shareTab === 'link' && (
          <Button
            onClick={async () => {
              await navigator.clipboard.writeText(window.location.href)
              success({ title: 'Link copied!' })
            }}
            size="small"
            badge={<PrismCopyIcon />}
          >
            Copy
          </Button>
        )}

        {shareTab === 'pdf' && !(progressValue || pdfInstance.loading) && (
          <div className={`${Styles.modalPdfFooter} ${Shared.verticalChildrenGap24} `}>
            <Button loading={!pdfData} size="small" onClick={() => renderPdf()} badge={<PrismAddIcon />}>
              Create
            </Button>

            <p className={Styles.pdfModalFooterCaption}>
              Your PDF will include up to 100 results. Include specific results by sorting and filtering.
            </p>
          </div>
        )}

        {shareTab === 'pdf' && (progressValue || pdfInstance.loading) && (
          <div className={`${Styles.modalPdfFooter} ${Shared.verticalChildrenGap24} `}>
            <ProgressBar progress={Math.round(progressValue)} height="small" className={Styles.pdfProgressBar} />
            <Button
              size="small"
              onClick={() => handleCancelRender()}
              type="secondary"
              className={Styles.pdfCancelButton}
            >
              Cancel
            </Button>
          </div>
        )}
      </ModalFooter>
    </PrismModal>
  )
}

export default ShareModal

const itemPdfColumns = [
  {
    displayName: 'Image',
    key: 'image',
    className: 'tablePhotoInFirstColumn' as const,
  },
  {
    displayName: 'Outcome',
    key: 'outcome',
    className: 'tableCenterColumn' as const,
  },
  {
    displayName: 'Tool Results',
    key: 'toolResults',
    className: 'tableToolResultColumn' as const,
  },
  {
    displayName: 'Date',
    key: 'date',
    className: 'tableCenterColumn' as const,
  },
  {
    displayName: 'Item ID',
    key: 'itemId',
    className: 'tableCenterColumn' as const,
  },
  {
    displayName: 'Batch',
    key: 'batch',
    className: 'tableCenterColumn' as const,
  },
  {
    displayName: 'Product',
    key: 'product',
    className: 'tableCenterColumn' as const,
  },
  {
    displayName: 'Recipe',
    key: 'recipe',
    className: 'tableItemsLastColumn' as const,
  },
]

const ItemPictureRenderer = ({ items, onLoad }: { items: Item[]; onLoad: () => void }) => {
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const images = items.map(it => {
    const image = getImageFromItem(it)
    if (!image) return null

    return (
      <div key={it.id} id={`items-pdf-picture-${it.id}`} className={Styles.pdfImageContainer}>
        <img
          alt="noimage"
          src={image}
          onLoad={() => setImagesLoaded(imgLoaded => imgLoaded + 1)}
          crossOrigin="anonymous"
        />
      </div>
    )
  })

  useEffect(() => {
    if (imagesLoaded === items.filter(it => getImageFromItem(it)).length) {
      onLoad()
      return
    }
  }, [imagesLoaded, items, onLoad])

  return <div className={Styles.hiddenGraphRender}>{images}</div>
}

export const ToolResultPictureRenderer = ({
  toolResults,
  onLoad,
  className,
  showMuted = false,
}: {
  toolResults: ToolResultEmptyOutcome[]
  onLoad: () => void
  className?: string
  showMuted?: boolean
}) => {
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const images = useMemo(
    () =>
      toolResults.map(toolResult => {
        const image = toolResult.picture.image
        if (!image || !toolResult.aoi) return null
        const imageElement = (
          <ImageCloseUp
            loaderType="skeleton"
            src={toolResult.picture.image}
            region={toolResult.aoi}
            onError={() => setImagesLoaded(imgLoaded => imgLoaded + 1)}
            onCanvasDrawn={() => setImagesLoaded(imgLoaded => imgLoaded + 1)}
          />
        )

        return (
          <div
            key={toolResult.id}
            id={`toolResult-pdf-picture-${toolResult.id}`}
            className={`${Styles.galleryImageContainer} ${Styles.pdfGalleryImageContainer} ${
              showMuted ? Styles.pdfGalleryMuted : ''
            } ${className ?? ''}`}
          >
            {wasToolResultMuted(toolResult) && showMuted && <Tag className={Styles.mutedTag}>muted</Tag>}
            {imageElement}
          </div>
        )
      }),
    [toolResults, className, showMuted],
  )

  useEffect(() => {
    if (imagesLoaded === toolResults.filter(toolResult => toolResult.picture.image && toolResult.aoi).length) {
      onLoad()
      return
    }
  }, [imagesLoaded, toolResults, onLoad])

  return <div className={Styles.hiddenGraphRender}>{images}</div>
}

const toolResultPdfColumns = [
  {
    displayName: 'Image',
    key: 'image',
    className: 'tablePhotoInFirstColumn' as const,
  },
  {
    displayName: 'Tool Name',
    key: 'name',
    className: 'tableCenterColumn' as const,
  },
  {
    displayName: 'Outcome',
    key: 'outcome',
    className: 'tableCenterColumn' as const,
  },
  {
    displayName: 'Tool Results',
    key: 'toolResults',
    className: 'tableToolResultColumn' as const,
  },
  {
    displayName: 'Score',
    key: 'score',
    className: 'tableCenterColumn' as const,
  },
  {
    displayName: 'Date',
    key: 'date',
    className: 'tableCenterColumn' as const,
  },
  {
    displayName: 'Recipe',
    key: 'recipe',
    className: 'tableLastColumn' as const,
  },
]
