/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react'

import { isNil } from 'lodash'
import throttle from 'lodash/throttle'
import { Image, Layer, Stage } from 'react-konva'

import ImgFallback from 'components/Img/ImgFallback'
import { PrismLoader, PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { useImageElement } from 'hooks'
import { Box } from 'types'
import { contain } from 'utils'

import AoiOutline, { AoiOutlineConfiguration, MaskingRectangle } from './AoiOutline'
import Styles from './ImageWithBoxes.module.scss'

const BoundingBoxOverlayImage = ({
  boundingBox,
  scaling,
}: {
  boundingBox: AoiOutlineConfiguration
  scaling: number
}) => {
  const [img] = useImageElement(boundingBox.overlaySrc)

  const x = boundingBox.x * scaling
  const y = boundingBox.y * scaling
  const width = boundingBox.width * scaling
  const height = boundingBox.height * scaling

  return <Image image={img} width={width} height={height} x={x} y={y} />
}

export interface Props extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'onResize'> {
  fallbackSrc?: string
  onlyRenderFallbackIfLoaded?: boolean
  boundingBoxes?: AoiOutlineConfiguration[]
  imageClassName?: string
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => any
  onClickBox?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, bb: AoiOutlineConfiguration) => any
  children?: React.ReactNode
  parentDivEventHandlers?: { [event: string]: React.MouseEventHandler<HTMLDivElement> }
  naturalHeight?: number
  naturalWidth?: number
  innerDivStyle?: React.CSSProperties
  handleLoad?: (src: string) => any
  onResize?: (width: number, height: number) => any
  zIndex?: number
  showLoader?: boolean
  showOverlay?: boolean
  'data-test'?: string
  predictionScore?: React.ReactNode
  loaderType?: 'bars' | 'skeleton'
}

export interface State extends Box {
  image: HTMLImageElement | null
}

/**
 * Renders an image with a set of boxes overlayed on top.
 *
 * @param boundingBoxes - Array of BoundingBox components defining the
 *     coordinates where to draw the boxes on the image
 * @param imageClassName - Css class for the img element
 * @param onClick - Function to be called when clicking on the image
 * @param onClickBox - Function to be called when clicking on a box in an image,
 *     overrides onClick
 * @param children - Optional react children rendered as sibling of image
 * @param parentDivEventHandlers - Event handlers
 * @param naturalHeight - naturalHeight of image
 * @param naturalWidth - naturalWidth of image
 * @param innerDivStyle - Spread into inner div
 * @param rest - extends all img element params
 * @param zIndex - z-index of the canvas rendering the aois atop the image
 */
class ImageWithBoxes extends React.PureComponent<Props, State> {
  containerRect = React.createRef<HTMLDivElement>()

  resizeObserver: ResizeObserver | null = null
  state: State = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    image: null,
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver(() => {
      if (!this.state.image) return
      this.draw()
    })

    if (this.containerRect.current) {
      this.resizeObserver.observe(this.containerRect.current)
    }
  }

  componentWillUnmount() {
    if (this.containerRect.current) {
      this.resizeObserver?.unobserve(this.containerRect.current)
    }
  }

  draw = throttle(
    () => {
      const image = this.state.image
      if (!image) return

      const rect = this.containerRect.current?.getBoundingClientRect()
      if (!rect) return

      const renderableRect = contain(image.naturalWidth, image.naturalHeight, rect.width, rect.height)
      if (!renderableRect) return

      this.setState({ ...renderableRect })
      if (this.props.onResize) this.props.onResize(renderableRect.width, renderableRect.height)
    },
    200,
    // This will make it so that the image with boxes is drawn immediately
    { leading: true },
  )

  handleImgLoad = (e: React.BaseSyntheticEvent<Event, HTMLImageElement>) => {
    this.setState({ image: e.currentTarget }, this.draw)
    if (this.props.handleLoad && this.props.src) this.props.handleLoad(this.props.src)
  }

  render() {
    const {
      boundingBoxes,
      className = '',
      onClick,
      onClickBox,
      imageClassName,
      children = null,
      parentDivEventHandlers,
      naturalHeight,
      naturalWidth,
      innerDivStyle,
      zIndex,
      onResize,
      showLoader,
      showOverlay,
      'data-test': dataTest,
      predictionScore,
      loaderType = 'bars',
      ...rest
    } = this.props

    const { x, y, width, height, image } = this.state

    const scaling = image ? Math.min(width / image!.naturalWidth!, height / image!.naturalHeight!) : 1
    const imageHeight = image?.naturalHeight || 0
    const imageWidth = image?.naturalWidth || 0

    // We find the AOIs that need a mask to be applied and if there are any, we build a mask and then render the AOI
    // twice, first just as a cutout, and then second, just the outline
    const boundingBoxesWithShadow = boundingBoxes && boundingBoxes.filter(bb => bb.applyMask)
    const shouldApplyMask = !!boundingBoxesWithShadow && boundingBoxesWithShadow.length > 0

    const maskAndCutouts = !shouldApplyMask ? null : (
      <Layer>
        <MaskingRectangle imageWidth={imageWidth} imageHeight={imageHeight} scaling={scaling} />
        {boundingBoxesWithShadow &&
          boundingBoxesWithShadow.map((bb, idx) => (
            <AoiOutline
              key={'' + (bb.id || idx) + ':cutout'}
              scaling={scaling}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              {...bb}
              cutoutOnly
            />
          ))}
      </Layer>
    )

    let displayLoader = <PrismLoader />
    if (loaderType === 'skeleton') displayLoader = <PrismSkeleton size="extraLarge" />

    return (
      <div className={`${className} ${Styles.imageWithBoxesWrapper}`} onClick={onClick} ref={this.containerRect}>
        {isNil(predictionScore) ? null : predictionScore}

        {!width && !height && displayLoader}

        <div
          style={{
            top: y,
            left: x,
            visibility: !width && !height ? 'hidden' : 'visible',
            width: width || '100%',
            height: height || '100%',

            ...innerDivStyle,
          }}
          className={Styles.imageWithBoxesContainer}
          {...parentDivEventHandlers}
        >
          {showOverlay ? <div className={Styles.overlay} /> : ''}

          <ImgFallback
            {...rest}
            data-test={dataTest}
            onLoad={this.handleImgLoad}
            className={`${imageClassName} ${Styles.imageWrapper}`}
            draggable={false}
            onDragStart={e => e.preventDefault()} // Disable default drag on firefox
            showLoader={showLoader}
          />

          {image && boundingBoxes && (
            <Stage className={Styles.stage} width={width} height={height} zIndex={zIndex}>
              {maskAndCutouts}
              {boundingBoxes.map((bb, idx) => (
                <Layer key={bb.id || idx}>
                  {/* If no insight image, we need to pass in undefined, not an empty string. Konva will try to render the empty string and crash */}
                  {bb.overlaySrc ? <BoundingBoxOverlayImage boundingBox={bb} scaling={scaling} /> : undefined}
                  <AoiOutline
                    onClick={onClickBox ? e => onClickBox(e, bb) : onClick}
                    scaling={scaling}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    {...bb}
                    applyMask={!!bb.overlaySrc}
                    innerOnly={!!bb.overlaySrc}
                  />
                </Layer>
              ))}
            </Stage>
          )}

          {image && children}
        </div>
      </div>
    )
  }
}

export default ImageWithBoxes
