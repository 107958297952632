/**
 * Notes on reducers... All branches of our state tree are typed. All reducers
 * must update their branch of the state tree in an immutable way, such that
 * Redux-connected components subscribed to updates in this branch are actually
 * re-rendered. All reducers take 2 arguments:
 *
 * @param state - Given branch of state
 * @param action - Actions dispatched to Redux to update state tree
 *
 * @returns state - Updated branch of state tree
 */
import { Action as QueryAction, reducer as queryReducer } from 'react-redux-query'
import { Action, combineReducers } from 'redux'

import { RootState } from 'types'

import auth from './auth'
import connectionStatus from './connectionStatus'
import edge from './edge'
import events from './events'
import inspector from './inspector'
import itemUpload from './itemUpload'
import localStorage from './localStorage'
import locationHistory from './locationHistory'
import modals from './modals'
import robotDiscoveriesById from './robotDiscoveries'
import triggerMode from './triggerMode'
import video from './video'

const reducers: {
  [Key in keyof RootState]: (
    state: RootState[Key] | undefined,
    action: Key extends 'getter' ? QueryAction : Action,
  ) => RootState[Key]
} = {
  getter: queryReducer,
  auth,
  events,
  inspector,
  video,
  robotDiscoveriesById,
  itemUpload,
  locationHistory,
  edge,
  triggerMode,
  connectionStatus,
  localStorage,
  modals,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
