/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react'

import { PrismLoader, PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { log } from 'utils'

interface CacheEntry {
  src: string
  naturalWidth?: number
  naturalHeight?: number
  state: 'loading' | 'retry' | 'fail' | 'success'
}

const srcCache: { [key: string]: CacheEntry } = {}

const getUrl = (src: string) => {
  try {
    return new URL(src)
  } catch (e) {
    return undefined
  }
}

/**
 * Gets entry from cache. `src` URL without query string is used as key.
 *
 * @param src - Full src URL, including query string
 *
 * @returns Cache entry with previously requested src URL and loading state
 */
export function getFromCache(src?: string): CacheEntry | undefined {
  if (!src) return
  const url = getUrl(src)
  if (!url) return

  const key = url.hostname + url.pathname

  return srcCache[key]
}

/**
 * Sets entry in cache by extracting key from `entry.src`. Only sets entry if
 * there is currently no entry at key.
 *
 * @param entry - Cache entry
 */
export function setCacheEntry(entry: CacheEntry) {
  const url = getUrl(entry.src)
  if (!url) return
  const key = url.hostname + url.pathname
  if (!srcCache[key]) srcCache[key] = entry
}

/**
 * Sets or overwrites entry in cache by extracting key from `entry.src`.
 *
 * @param entry - Cache entry
 */
export function updateCacheEntry(entry: CacheEntry) {
  const url = getUrl(entry.src)
  if (!url) return
  const key = url.hostname + url.pathname
  srcCache[key] = entry
}

/**
 * Instantiates `Image` with `src` so that image is fetched in background,
 * without rendering anything to the DOM. Returns image element.
 *
 * @param src - Source URL
 */
export function prefetch(src: string): HTMLImageElement {
  // Don't prefetch image if we already fetched it successfully
  const entry = getFromCache(src)
  const image = new Image()

  // Make sure we set crossOrigin attr on the image; see similar comment in
  // ImgFallback for why
  image.crossOrigin = 'anonymous'
  if (entry && entry.state === 'success') {
    image.src = entry.src
    return image
  }

  setCacheEntry({ src, state: 'loading' })
  image.src = src
  image.onload = () => {
    updateCacheEntry({ src, state: 'success', naturalWidth: image.naturalWidth, naturalHeight: image.naturalHeight })
  }

  return image
}

export interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallbackSrc?: string
  retries?: number
  retryDelayMs?: number
  useCache?: boolean
  onlyRenderFallbackIfLoaded?: boolean
  loaderType?: 'bars' | 'skeleton' | 'none'
  showLoader?: boolean
  'data-testid'?: string
  'data-test'?: string
}

/**
 * Renders an image that retries up to `retries` times if there's an error
 * loading it. Also caches images in memory by the src without query string.
 *
 * @param fallbackSrc - Url of image to fall back on if the src fails to load
 * @param retries - Number of times to retry fetching image in case of failure
 * @param retryDelayMs - Time in ms to wait between retries
 * @param useCache - Whether to cache the image in memory
 * @param onlyRenderFallbackIfLoaded - Don't try to render fallback if it hasn't
 *     been downloaded
 * @param loaderType - Renders the type of animation, it could be bars or skeleton
 */
class ImgFallback extends React.PureComponent<Props> {
  static defaultProps: Props = { useCache: true, onlyRenderFallbackIfLoaded: false, loaderType: 'bars' }

  counter: number = 0
  mounted: boolean = false

  componentDidMount() {
    this.mounted = true
    this.loadImage(this.props.src)
  }

  componentDidUpdate(prevProps: Props) {
    if (this.setAndGetSrc(this.props.src) !== this.setAndGetSrc(prevProps.src)) {
      this.counter = 0
      this.loadImage(this.props.src)
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  setAndGetSrc = (src: string | undefined): string | undefined => {
    if (!this.props.useCache || !src) return src
    setCacheEntry({ src, state: 'loading' })
    return getFromCache(src)?.src
  }

  checkAndUpdateCacheEntry = (entry: CacheEntry) => {
    if (!this.props.useCache) return

    updateCacheEntry(entry)
  }

  loadImage = (uncachedSrc: string | undefined) => {
    const { retries = 4, retryDelayMs = 2000 } = this.props

    if (!uncachedSrc) return
    const src = this.setAndGetSrc(uncachedSrc)
    if (!src || !this.mounted) return

    const image = new Image()
    image.crossOrigin = 'anonymous'
    image.src = src

    // Make sure we set crossOrigin attr on the image; this sets some CORS headers
    // on request to fetch image; if request doesn't have these CORS headers,
    // server won't respond with CORS headers in response, and browser won't let
    // you do certain things with image, like render it to a canvas without
    // "tainting" the canvas
    //
    // Note that if we set crossOrigin attr on an image that isn't served with
    // CORS response headers, BROWSER WILL REFUSE TO EVEN RENDER THE IMAGE; this
    // means all images rendered by this component must be served with CORS
    // headers; our s3 buckets, and our nginx "media" server for local deploys,
    // both serve all files with CORS headers

    // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/crossorigin
    image.onerror = () => {
      log('src/components/Img/ImgFallback.tsx', 'imageOnError', { counter: this.counter, retries, uncachedSrc, src })
      if (this.counter >= retries) {
        this.checkAndUpdateCacheEntry({ src, state: 'fail' })
        if (this.mounted) this.forceUpdate()
        return
      } else {
        // don't retry url in cache if load fails, and instead try new url
        this.checkAndUpdateCacheEntry({ src: uncachedSrc, state: 'retry' })
        if (this.mounted) this.forceUpdate()
      }

      this.counter += 1

      setTimeout(() => {
        this.loadImage(uncachedSrc)
      }, retryDelayMs)
    }

    image.onload = () => {
      this.checkAndUpdateCacheEntry({
        src,
        state: 'success',
        naturalWidth: image.naturalWidth,
        naturalHeight: image.naturalHeight,
      })
      if (this.mounted) this.forceUpdate()
    }
  }

  render() {
    const {
      src: uncachedSrc,
      alt,
      fallbackSrc: uncachedFallbackSrc,
      onlyRenderFallbackIfLoaded,
      onLoad,
      useCache,
      loaderType,
      showLoader,
      'data-testid': dataTestId,
      'data-test': dataTest,
      ...rest
    } = this.props

    const src = this.setAndGetSrc(uncachedSrc)
    const fallbackSrc = this.setAndGetSrc(uncachedFallbackSrc)

    let loader: JSX.Element | null = null
    if (loaderType === 'bars') loader = <PrismLoader />
    if (loaderType === 'skeleton') loader = <PrismSkeleton size="extraLarge" />

    if (showLoader) return loader

    const entry = useCache ? getFromCache(uncachedSrc) : { src: uncachedSrc, state: 'success' }

    if (!entry) return null

    // Only render fallback image if it's truthy and different from src image
    let canRenderFallback = fallbackSrc && src !== fallbackSrc
    if (onlyRenderFallbackIfLoaded) {
      const fallbackEntry = getFromCache(uncachedFallbackSrc)
      if (fallbackEntry?.state !== 'success') canRenderFallback = false
    }

    const { state } = entry

    if (state === 'loading') {
      if (!canRenderFallback) return loader

      return (
        <>
          <span style={{ position: 'relative' }}>
            {loader}
            <img crossOrigin="anonymous" src={fallbackSrc} alt={alt} {...rest} />
          </span>
        </>
      )
    }

    if (state === 'retry') {
      if (canRenderFallback) return <img crossOrigin="anonymous" src={fallbackSrc} alt={alt} {...rest} />
      return loader
    }
    if (state === 'fail') {
      if (fallbackSrc)
        return (
          <img
            data-testid={`${dataTestId}-fail-state`}
            data-test={dataTest}
            crossOrigin="anonymous"
            src={fallbackSrc}
            alt={alt}
            {...rest}
          />
        )
      return (
        <img
          data-testid={`${dataTestId}-fail-state`}
          data-test={dataTest}
          crossOrigin="anonymous"
          src={src}
          alt={alt}
          {...rest}
        />
      )
    }
    if (state === 'success') {
      return (
        <img
          data-testid={`${dataTestId}-success-state`}
          data-test={dataTest}
          crossOrigin="anonymous"
          src={src}
          alt={alt}
          onLoad={onLoad}
          {...rest}
        />
      )
    }
    return loader
  }
}

export default ImgFallback
