import React from 'react'
import ReactDOM from 'react-dom'

import { toJpeg } from 'html-to-image'
import { isNil } from 'lodash'
import { AnyAction, Dispatch } from 'redux'

import { service } from 'api'
import { ToolResultPictureRenderer } from 'pages/Analyze/ShareModal'
import { updateReduxSingleToolLabel } from 'pages/RoutineOverview/LabelingScreen/LabelingScreen'
import { ToolLabel, ToolResultEmptyOutcome } from 'types'
import { dataURLtoFile, uploadImagesAndGenerateThumbnails } from 'utils'

import Styles from './LabelImageGeneration.module.scss'

/**
 * This function is used to upload fallback images for the provided tool labels.
 * First it renders a the tool results pictures, then generates jpegs and their respective thumbnails, and
 * finally those images are uploaded to s3 and the tool label urls are updated.
 *
 * @param toolLabel - Current Tool Label
 * @param toolResults - Tool Results to generate the fallback images from
 * @param toolParentId - Current tool parent id
 * @param dispatch - Redux dispatch
 *
 */
const renderAndUploadFallbackImagesFromToolResults = ({
  toolLabel,
  toolResults,
  toolParentId,
  dispatch,
}: {
  toolLabel: ToolLabel
  toolResults: ToolResultEmptyOutcome[]
  toolParentId: string
  dispatch: Dispatch<AnyAction>
}) => {
  const container = document.createElement('div')
  container.className = Styles.hiddenGraphRender || ''
  document.body.appendChild(container)
  ReactDOM.render(
    <ToolResultPictureRenderer
      toolResults={toolResults}
      className={Styles.toolLabelImageGeneration}
      onLoad={async () => {
        const allFiles = await Promise.all(
          toolResults.map(async tr => {
            const renderedCropElement = document.getElementById(`toolResult-pdf-picture-${tr.id}`)
            if (renderedCropElement) {
              try {
                const jpegUrlData = await toJpeg(renderedCropElement, { fontEmbedCSS: '' })
                const file = dataURLtoFile(jpegUrlData, `${toolLabel.id}-${tr.id}.jpg`)
                return file
              } catch (e) {
                return null
              }
            }
            return null
          }),
        )

        ReactDOM.unmountComponentAtNode(container)
        document.body.removeChild(container)

        const filesToSave = allFiles.filter((file): file is File => !isNil(file))

        if (!filesToSave.length) return

        const newFallbackImages = await uploadImagesAndGenerateThumbnails(filesToSave)

        if (!newFallbackImages) return

        const updatedFallbackImages = [...toolLabel.fallback_images, ...newFallbackImages]
        const patchLabelRes = await service.patchToolLabel(toolLabel.id, {
          fallback_images: updatedFallbackImages,
        })

        if (patchLabelRes.type !== 'success') return

        updateReduxSingleToolLabel({ toolLabel: patchLabelRes.data, labelsGetterKey: toolParentId, dispatch })
      }}
    />,
    container,
  )
}

export default renderAndUploadFallbackImagesFromToolResults
