import React, { SyntheticEvent, useRef, useState } from 'react'

import { ButtonProps } from 'antd'
import { useHistory } from 'react-router-dom'

import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'

import Styles from './Button.module.scss'

const sizeClasses = {
  xsmall: Styles.xsmall,
  small: Styles.small,
  medium: Styles.medium,
  large: Styles.large,
  xlarge: Styles.extraLarge,
}

const typeClasses = {
  primary: Styles.primary,
  secondary: Styles.secondary,
  tertiary: Styles.tertiary,
  ghost: Styles.ghost,
  danger: Styles.danger,
  link: Styles.link,
  default: Styles.default,
}

export interface PrismButtonProps extends Omit<ButtonProps, 'type' | 'size'> {
  // TODO: improve this type to not use any
  onClick?: React.MouseEventHandler | void | any
  children: React.ReactNode
  type?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link' | 'ghost' | 'default'
  isOnTop?: boolean
  htmlType?: 'button' | 'submit' | 'reset'
  className?: string
  loading?: boolean
  disabled?: boolean
  hideLoader?: boolean
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'
  wide?: boolean
  to?: string
  badge?: React.ReactNode
  invertBadgePosition?: boolean
  badgeClassName?: string
  'data-testid'?: string
  'data-test'?: string
  'data-test-attribute'?: string
  childrenWrapperClassName?: string
  childrenClassName?: string
}

export const useClick = ({ to, onClick, loading }: Pick<PrismButtonProps, 'to' | 'onClick' | 'loading'>) => {
  const history = useHistory()
  const [innerLoading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const loadingRef = useRef(false)

  // Helper function that allows us to capture promises and auto-set loading state
  const handleClick = async (e: SyntheticEvent) => {
    // Link behavior with `to` takes precedence over `onClick`
    if (to) {
      history.push(to)
      return
    }

    if (loadingRef.current || !onClick || loading) return

    // add loading state to button if onClick returns a promise
    let value = onClick(e)
    if (typeof (value as NonNullable<Promise<any>>)?.then === 'function') {
      loadingRef.current = true
      setLoading(true)
      value = await value
    }

    loadingRef.current = false
    setLoading(false)
    setDone(true)
  }

  return { handleClick, innerLoading, done }
}

/**
 * Renders custom button component that wraps ant-d button
 *
 * @param onClick - {onClick}
 * @param children - default React prop with the text and/or icon to be rendered
 *     by the button
 * @param type - changes the looks of the button | 'default' type takes out most of the button styles to allow the customization
 * @param htmlType - html type of button, use submit when using button inside a
 *     form
 * @param isOnTop - adds a special hover background for buttons that are in front of other elements
 * @param className - string class that will be passed down to the button
 *     element
 * @param loading - shows a loading indicator and prevents the button from being
 *     clicked
 * @param disabled - it prevents button from being clicked
 * @param size - increase/decrease size of padding and text inside the button | when type is 'default' size won't be applied.
 * @param wide - apply when button needs to expand horizontally
 * @param to - a path inside the app that the button will redirect to
 * @param style - inline styles applied to root component
 * @param badge - renders a component to the left side (default position) of the text
 * @param invertBadgePosition - the badge position changes to the right of text
 */
export function Button({
  onClick,
  children,
  isOnTop,
  className,
  htmlType = 'button',
  size = 'medium',
  type = 'primary',
  wide,
  loading,
  disabled,
  to,
  hideLoader,
  badge,
  invertBadgePosition,
  'data-testid': dataTestId,
  'data-test': dataTest,
  'data-test-attribute': dataTestAttribute,
  badgeClassName,
  childrenWrapperClassName = '',
  childrenClassName = '',
}: PrismButtonProps) {
  const { handleClick, innerLoading, done } = useClick({ to, onClick, loading })

  const isLoading = innerLoading || loading

  const sizeClass = sizeClasses[size]
  const typeClass = typeClasses[type]

  const buttonClassName = `${type === 'default' ? '' : sizeClass} ${typeClass} ${Styles.button} ${
    type === 'default' ? Styles.default : Styles.base
  } ${badge ? Styles.badgeInButton : ''} ${invertBadgePosition ? Styles.badgePaddingRight : Styles.badgePaddingLeft} ${
    className || ''
  } ${wide ? Styles.wide : ''} ${isLoading ? Styles.isLoading : ''} ${isOnTop ? Styles.isOnTop : ''}`

  return (
    <button
      className={buttonClassName}
      onClick={handleClick}
      type={htmlType}
      disabled={disabled || isLoading}
      data-testid={dataTestId}
      data-test={dataTest}
      data-test-attribute={
        isLoading
          ? `${dataTestId}-loading`
          : done
          ? `${dataTestId}-done`
          : disabled
          ? `${dataTestId}-disabled`
          : `${dataTestId}-active`
      }
    >
      <div
        className={`${Styles.childrenWrapper} ${childrenWrapperClassName} ${
          invertBadgePosition ? Styles.invertBadgePosition : ''
        }`}
      >
        {badge && (
          <div
            className={`${Styles.buttonBadge} ${badgeClassName ?? ''}  ${
              !invertBadgePosition ? Styles.badgeLeftPosition : Styles.badgeRightPosition
            }`}
            data-testid={`${dataTestId}-badge`}
            data-test-attribute={dataTestAttribute}
          >
            {badge}
          </div>
        )}

        <div className={childrenClassName}>{children}</div>
      </div>

      {isLoading && !hideLoader && <PrismLoader className={Styles.loadingIcon} />}
    </button>
  )
}
