import React, { useEffect, useMemo, useRef, useState } from 'react'

import { isEqual } from 'lodash'
import { isMoment } from 'moment'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import PrismBadge from 'components/PrismBadge/PrismBadge'
import { PrismAddIcon, PrismResetIcon } from 'components/prismIcons'
import { PrismPopover } from 'components/PrismPopover/PrismPopover'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import RangePicker from 'components/RangePicker'
import { SearchableSelect } from 'components/SearchableSelect/SearchableSelect'
import SearchInput from 'components/SearchInput/SearchInput'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { Token } from 'components/Token/Token'
import { useAnalyticsQueryFilters, useQueryEntityFromSites } from 'hooks'
import {
  getSiteLineAndStationIdsFromCascaderValue,
  getSitesAndLineCascaderValue,
  SitesAndLinesCascader,
} from 'pages/StationDetail/Components/EntityModals/SitesAndLinesCascader'
import { AnalyzeTab, DateValue, Outcome, TimeSeriesDatePeriod, ToolSpecificationName } from 'types'
import { SiteAndLineCascaderLocation, titleCase } from 'utils'
import { TOOL_NAMES } from 'utils/constants'

import Styles from './AnalyzeFilters.module.scss'
import { LabelSelect } from './LabelSelect'

const displayNameByPeriod = { '30m': '30m', hour: 'Hour', day: 'Day', week: 'Week', month: 'Month' }
const Periods: TimeSeriesDatePeriod[] = ['30m', 'hour', 'day', 'week', 'month']

export type Filters = {
  component_id?: string
  inspection_id?: string
  recipe_id?: string
  user_id?: string
  station_id?: string
  prediction_label_id?: string[]
  user_label_id?: string[]
  tool_specification?: ToolSpecificationName
  tool_parent_id?: string
  start: DateValue
  end: DateValue
  period?: TimeSeriesDatePeriod
  search?: string
  serial_number?: string
  outcome?: Outcome
  selected_item_id?: string[]
  insightsActive?: boolean
  site_id?: string
  subsite_id?: string
  subsite_type_id?: string
  station_subtype_id?: string
}

export type ExtraOptions = {
  periodsAvailable: TimeSeriesDatePeriod[]
  onUpdateFilters: FiltersUpdater
  onResetFilters: () => void
  filtersAreDefault: boolean
  expanded?: string
  viewMode?: 'gallery' | 'list'
  filterKey: string
}

export type FiltersUpdater = (
  changes: {
    [key in keyof (Filters & ExtraOptions)]?: DateValue | string | number | string[] | number[] | boolean | undefined
  },
  routerType?: 'push' | 'replace',
) => void

type Props = {
  tab: AnalyzeTab
  insightsDisabled?: boolean
  'data-testid'?: string
}

export type FilterOption =
  | 'search'
  | 'date'
  | 'interval'
  | 'product'
  | 'outcome'
  | 'batch'
  | 'recipe'
  | 'inspector'
  | 'prediction'
  | 'tool'
  | 'label'
  | 'location'

interface FilterMenuProps {
  filterList: FilterOption[]
  activeFilterCount: number
  disabled?: boolean
  periodsAvailable?: TimeSeriesDatePeriod[]
  onUpdateFilters: FiltersUpdater
  filtersValue: Filters
  calculatingFiltersList: boolean
}

/**
 * Renders a bar with all the relevant filters to use in the Analyze screen.
 *
 * BACKEND LIMITATIONS:
 * 1. Filtering by outcome(s) and prediction label(s) simultaneously is not allowed. In other words,
 *  calculated_outcome/__in and user_outcome/__in are mutually exclusive with prediction_label_id/__in.
 *  This would require adding calculated_outcome and user_outcome to ToolResultLabel and indexing on these fields.
 *  Prediction labels are tightly correlated with outcomes, so the cost isn’t worth it.
 * 2. Predicted Labels and User Labels are limited to at most 12 different combinations, to comply with this
 *  limitation, the frontend will only allow 3 options in both of these selects, to a maximum of 3x3=9 combinations
 * 3. User Outcome and Calculated Outcome have the same limitation of 12 combinations. In case we wish to
 *  implement multiselects for these filters, we should keep this consideration in mind.
 * 4. These limitations apply for filtering items as well as tool results.
 *
 * @param tab - Currently selected tab. Which filters are shown depends on the selected tab.
 * @param insightsDisabled - Whether the insights button should be enabled or not.
 * @param onFiltersChange - Callback handler for when any filter changes.
 * @param data-testid - Test ID
 */
const AnalyzeFilters = ({ tab, insightsDisabled, 'data-testid': dataTestId }: Props) => {
  const analyzeFiltersRef = useRef<HTMLDivElement>(null)
  const filtersListRef = useRef<HTMLDivElement>(null)
  const renderFullListRef = useRef(false)
  const windowWidthRef = useRef<number>(window.innerWidth)
  const [filtersToShow, setFiltersToShow] = useState<FilterOption[]>()
  const [filterLoadedForceRerender, setFilterLoadedForceRerender] = useState<Set<string>>(new Set())

  const [filters, options] = useAnalyticsQueryFilters({ tab })
  // Used only to update the input render value, filters qs params update is debounced
  const [searchInputValue, setSearchInputValue] = useState(tab === 'items' ? filters.serial_number : filters.search)
  const { periodsAvailable, onUpdateFilters, filtersAreDefault, onResetFilters } = options

  // Always default to an available period
  useEffect(() => {
    if (filters.period && !periodsAvailable?.includes(filters.period))
      onUpdateFilters({ period: periodsAvailable?.[0] })
  }, [filters.period, onUpdateFilters, periodsAvailable])

  const tabFilters = getFilters(tab, filters, options.expanded)
  const filterNames = tabFilters.map(({ name }) => name)

  const [filtersInPopover, activeFilterCountInPopover] = useMemo(
    () => {
      let activeFilterCountInPopover = 0
      const filtersInPopover = tabFilters?.filter(filter => {
        const filterIsInPopover = !filtersToShow?.includes(filter.name)
        if (filterIsInPopover && filter.active) activeFilterCountInPopover++
        return filterIsInPopover
      })
      return [filtersInPopover.map(({ name }) => name), activeFilterCountInPopover]
    },
    // eslint-disable-next-line
    [filtersToShow],
  )

  // Since select size changes when an option is selected, we want to recalculate space when a select's value changes or on initial load while fetching the values to render on each select
  const filtersChangedKey = Object.entries(filters)
    .filter(([key]) => {
      if (tab === 'items' || tab === 'tools') return true
      // We want to ignore these filters when they are located on the side gallery panel
      return !['user_label_id', 'prediction_label_id'].includes(key)
    })
    .map(([, val]) => (isMoment(val) ? val.toISOString() : val?.toString()))
    .sort()
    .join()

  const filtersLoadedKey = [...filterLoadedForceRerender].sort().join()

  // Forces component to rerender with all filters to then let the next useEffect calculate which fit
  useEffect(() => {
    const filterListCleanUp = analyzeFiltersRef.current

    const renderAllFilters = (event?: UIEvent) => {
      if (renderFullListRef.current) return
      // If function was called through the resize event listener, ignore vertical resizes
      if (event) {
        const oldCurrentWidth = windowWidthRef.current
        const newCurrentWidth = window.innerWidth
        // update width for next iteration
        windowWidthRef.current = newCurrentWidth
        if (oldCurrentWidth === newCurrentWidth) return
      }

      renderFullListRef.current = true
      setFiltersToShow([...filterNames])
    }

    renderAllFilters()
    const observer = new ResizeObserver(() => {
      setTimeout(() => renderAllFilters())
    })

    if (filterListCleanUp) observer.observe(filterListCleanUp)
    window.addEventListener('resize', renderAllFilters)

    return () => {
      if (filterListCleanUp) observer.unobserve(filterListCleanUp)
      window.removeEventListener('resize', renderAllFilters)
    }
  }, [filtersChangedKey, filtersLoadedKey]) //eslint-disable-line

  // Calculates which filters fit in the space available, then rerenders component
  useEffect(() => {
    if (
      !analyzeFiltersRef.current ||
      !renderFullListRef.current ||
      !filtersListRef.current ||
      !filtersToShow ||
      !filtersToShow.length
    )
      return

    const analyzeFilterWrapper = analyzeFiltersRef.current.getBoundingClientRect()
    const domFiltersList = filtersListRef.current.querySelectorAll<HTMLDivElement>(':scope>div')
    // compares the filter position with the filters container
    const visibleFilters = filterNames.filter((_, i) => {
      const filterRect = domFiltersList[i]?.getBoundingClientRect()
      if (filterRect) return filterRect.bottom <= analyzeFilterWrapper.bottom
      return false
    })

    renderFullListRef.current = false
    setFiltersToShow(visibleFilters)
  }, [filtersToShow]) // eslint-disable-line

  const showSearchFilter = !!filterNames.find(filterName => filterName === 'search')

  let searchIsDisabledIfFilter = 'label' as FilterOption
  if (!!filters.prediction_label_id) searchIsDisabledIfFilter = 'prediction'
  if (!!filters.outcome) searchIsDisabledIfFilter = 'outcome'

  const searchIsDisabled =
    tab === 'items' && (!!filters.user_label_id || !!filters.prediction_label_id || !!filters.outcome)

  return (
    <div
      className={`${Styles.filtersWrapper} ${tab === 'items' || tab === 'tools' ? Styles.tooltabWrapper : ''}`}
      data-testid={dataTestId}
      ref={analyzeFiltersRef}
    >
      {/* If the relationship between parent and children on this container ever changes, the smart space logic above will break */}
      <div className={Styles.filtersContainer} ref={filtersListRef}>
        {showSearchFilter && (
          <FilterDisabledTooltip
            filterToHover="search"
            filterThatForceDisabled={searchIsDisabledIfFilter}
            condition={searchIsDisabled}
          >
            <SearchInput
              data-testid="analyze-filters-search"
              size="height24"
              name="search"
              placeholder="Search"
              value={searchInputValue}
              onChange={e => {
                const key = tab === 'items' ? 'serial_number' : 'search'
                // Immediately update the rendered value
                setSearchInputValue(e.target.value)
                // This filters qs params update is debounced
                onUpdateFilters({ [key]: e.target.value })
              }}
              wrapperClassName={Styles.searchField}
              key="search"
              disabled={searchIsDisabled}
            />
          </FilterDisabledTooltip>
        )}

        {filtersToShow?.map(filterOpt => (
          <AnalyzeFilter
            filter={filterOpt}
            onUpdateFilters={onUpdateFilters}
            periodsAvailable={periodsAvailable}
            isInsidePopoverList={false}
            filtersValue={filters}
            key={filterOpt}
            onInitialLoad={key =>
              setFilterLoadedForceRerender(set => {
                const newSet = new Set(set)
                newSet.add(key)
                return newSet
              })
            }
          />
        ))}
      </div>

      <div className={`${Styles.buttonsContainer} ${tab === 'tools' ? Styles.toolsTabButtonWrapperGrow : ''}`}>
        <div className={Styles.buttonsLeftPanel}>
          <MemoFiltersMenu
            calculatingFiltersList={renderFullListRef.current}
            activeFilterCount={activeFilterCountInPopover}
            disabled={!filtersInPopover?.length}
            filterList={filtersInPopover}
            onUpdateFilters={onUpdateFilters}
            periodsAvailable={periodsAvailable}
            filtersValue={filters}
          />

          <Button
            data-testid="analyze-filters-reset"
            className={`${Styles.resetButton} ${
              filtersAreDefault ? Styles.resetButtonIsHidden : Styles.resetButtonIsVisbile
            }`}
            badge={<PrismResetIcon />}
            size="xsmall"
            type="secondary"
            onClick={() => {
              onResetFilters()
              setSearchInputValue('')
            }}
          >
            Reset
          </Button>
        </div>
        {tab === 'tools' && options.viewMode !== 'list' && (
          <ToggleButton
            className={Styles.insightsButton}
            size="xsmall"
            title="insights"
            disabled={insightsDisabled}
            onClick={() => {
              onUpdateFilters({ insightsActive: !filters.insightsActive })
            }}
            active={filters.insightsActive}
            hotkey="I"
          />
        )}
      </div>
    </div>
  )
}

export default AnalyzeFilters

/**
 * Renders the filters button, opens popover with the filters that don't fit and displays the number of active filters.
 *
 * @param children - Holds the hidden filters
 * @param activeFilterCount - Holds the number of active filters
 * @param disabled - Prevents the button from being clicked
 * @param onUpdateFilters - callback handler
 * @param periodsAvailable - options for the period filter
 * @param filtersValue - An object with the filters value
 * @param calculatingFiltersList - If we are calculating if the filters fit outside of the popover
 */
const FiltersMenu = ({
  filterList,
  activeFilterCount,
  disabled,
  onUpdateFilters,
  periodsAvailable,
  calculatingFiltersList,
  filtersValue,
}: FilterMenuProps) => {
  const [showFiltersPopover, setShowFiltersPopover] = useState(false)

  // Forces to close the popover when the last filter resets to its original width.
  useEffect(() => {
    if (showFiltersPopover && !calculatingFiltersList && filterList.length === 0) setShowFiltersPopover(false)
  }, [filterList, calculatingFiltersList, showFiltersPopover])

  return (
    <Button
      data-testid="analyze-filters-button"
      className={showFiltersPopover ? Styles.filtersButtonActive : ''}
      size="xsmall"
      type="secondary"
      badge={
        activeFilterCount === 0 ? (
          <PrismAddIcon />
        ) : (
          <PrismBadge
            count={activeFilterCount}
            shape="box"
            className={Styles.counterContainer}
            type="smokey"
            size="medium"
          />
        )
      }
      invertBadgePosition={activeFilterCount !== 0}
      onClick={() => {
        setShowFiltersPopover(!showFiltersPopover)
      }}
      disabled={disabled}
    >
      Filters
      {showFiltersPopover && (
        <PrismPopover onClose={() => setShowFiltersPopover(false)} bodyClassName={Styles.popoverBody}>
          {filterList.map(filterOpt => (
            <AnalyzeFilter
              filter={filterOpt}
              onUpdateFilters={onUpdateFilters}
              periodsAvailable={periodsAvailable}
              isInsidePopoverList
              filtersValue={filtersValue}
              key={filterOpt}
            />
          ))}
        </PrismPopover>
      )}
    </Button>
  )
}

const MemoFiltersMenu = React.memo(FiltersMenu, (oldProps, newProps) => {
  // We don't re-render if we are calculating whether the filters should be in the popover or not
  if (newProps.calculatingFiltersList) return true
  // Otherwise, we compare the props
  return isEqual(oldProps, newProps)
})

/**
 * Renders different looks for the filters.
 * Filter outside the popover displays a placeholder.
 * When it goes into the popover the placeholder is replaced by a label.
 *
 * @param label - Holds the filter name, set as a placeholder or a label
 * @param children - reserve to hold a PrismSelect,RangePicker or SearchableSelect
 * @param isInsidePopoverList - a boolean to change the styles
 * @param className - string that adds external styles to the wrapper
 * @param labelClassName - string that adds external styles to the Token label
 */
const AnalyzeFilterContainer = ({
  label,
  children,
  isInsidePopoverList,
  className = '',
  labelClassName = '',
}: {
  label: string
  children: JSX.Element
  isInsidePopoverList: boolean
  className?: string
  labelClassName?: string
}) => {
  return (
    <ConditionalWrapper
      condition={isInsidePopoverList}
      wrapper={content => (
        <Token
          label={label}
          className={`${Styles.selectWrapper} ${className}`}
          labelClassName={`${Styles.selectLabel} ${labelClassName}`}
        >
          {content}
        </Token>
      )}
    >
      {children}
    </ConditionalWrapper>
  )
}

/**
 * Renders a filter tooltip, with the caption:
 * "Cannot filter by filter1 and filter2 at the same time".
 *
 * @param condition - Condition in which the tooltip is going to be displayed
 * @param children - The filter element
 * @param filterToHover -  Filter to hover.
 * @param filterThatForceDisabled - Filter that forced the disabled state.
 * @param className - class name of the filter container
 */
export const FilterDisabledTooltip = ({
  condition,
  children,
  filterToHover,
  filterThatForceDisabled,
  className = '',
}: {
  condition: boolean
  children: JSX.Element
  filterToHover: FilterOption
  filterThatForceDisabled: FilterOption
  className?: string
}) => {
  const tooltipCaption = (
    <>
      Cannot filter by {<span className={Styles.capitalizeOptions}>{filterToHover}</span>} and
      {''} {<span className={Styles.capitalizeOptions}>{filterThatForceDisabled}</span>} at the same time
    </>
  )

  return (
    <PrismTooltip condition={condition} title={tooltipCaption} anchorClassName={className}>
      {children}
    </PrismTooltip>
  )
}

/**
 * Renders the analyze filters
 *
 *
 * @param filter - filter name
 * @param onUpdateFilters - Callback handler
 * @param periodsAvailable - the interval options
 * @param isInsidePopoverList - boolean to change the styles
 * @param filtersValue - The filters object value
 * @param onInitialLoad - Force parent to recalculate space when select fetches it's data and can now render its values. We're assuming that we'll be able to fetch allToolLabels before all of our selects complete their own fetches
 */
const AnalyzeFilter = ({
  filter,
  onUpdateFilters,
  periodsAvailable,
  isInsidePopoverList,
  filtersValue,
  onInitialLoad,
}: {
  filter: FilterOption
  onUpdateFilters: FiltersUpdater
  periodsAvailable?: TimeSeriesDatePeriod[]
  isInsidePopoverList: boolean
  filtersValue: Filters
  onInitialLoad?: (key: string) => void
}) => {
  if (!filter) return null
  const {
    component_id,
    inspection_id,
    recipe_id,
    station_id,
    prediction_label_id,
    user_label_id,
    user_id,
    tool_specification,
    start,
    end,
    period,
    outcome,
    site_id,
    subsite_id,
    station_subtype_id,
  } = filtersValue

  return (
    <>
      {filter === 'date' && (
        <AnalyzeFilterContainer
          key={filter}
          label="Date"
          className={Styles.datePickerInPopover}
          data-testid="analyze-filters-date-picker"
          isInsidePopoverList={isInsidePopoverList}
        >
          <RangePicker
            data-testid="analyze-filters-date-picker"
            className={Styles.datePickerContainer}
            size={isInsidePopoverList ? undefined : 'small'}
            value={[start, end]}
            onChange={newValues =>
              onUpdateFilters({
                start: newValues?.[0]?.startOf('day').format() || null,
                end: newValues?.[1]?.endOf('day').format() || null,
              })
            }
          />
        </AnalyzeFilterContainer>
      )}

      {filter === 'interval' && (
        <AnalyzeFilterContainer label="Interval" isInsidePopoverList={isInsidePopoverList} key={filter}>
          <PrismSelect
            data-testid="analyze-filters-interval"
            value={period ? `Interval: ${titleCase(period)}` : undefined}
            onChange={val => onUpdateFilters({ period: val })}
            placeholder="Interval: Day"
            onKeyUp={e => e.preventDefault()}
            showSearch
            className={isInsidePopoverList ? Styles.selectWrapper : Styles.intervalSelectContainer}
            stopPropagationOnDropdownClick
            size={isInsidePopoverList ? undefined : 'small'}
            options={Periods.map(per => ({
              key: per,
              value: per,
              disabled: !periodsAvailable?.includes(per),
              dataTestId: `analyze-filters-interval-${per}`,
              title: displayNameByPeriod[per],
            }))}
            wrapperClassName={Styles.analyzeSelectContainer}
          />
        </AnalyzeFilterContainer>
      )}

      {filter === 'product' && (
        <AnalyzeFilterContainer label="Product" key="product" isInsidePopoverList={isInsidePopoverList}>
          <SearchableSelect
            disableDropdownAnimation
            data-testid="analyze-filters-product"
            fetcher={searchValue => service.getComponents({ name: searchValue })}
            missingOptionFetcher={id => service.getComponent(id)}
            formatter={product => product.name}
            getterKey={getterKeys.metricsFilteredComponents()}
            key="component"
            placeholder={isInsidePopoverList ? '' : 'Product'}
            size={isInsidePopoverList ? undefined : 'small'}
            clearable
            value={component_id || undefined}
            onChange={val => onUpdateFilters({ component_id: val })}
            wrapperClassName={Styles.analyzeSelectContainer}
            popupClassName={Styles.dropdownWrapper}
            onInitialLoad={() => onInitialLoad?.('component')}
            queryOptions={{ noRefetch: true, noRefetchMs: 30 * 1000 }}
            onKeyUp={e => e.preventDefault()}
            stopPropagationOnDropdownClick
            getSelectOptionDataTestId={product => `analyze-filters-product-${product.name}`}
          />
        </AnalyzeFilterContainer>
      )}

      {filter === 'location' && (
        <AnalyzeFilterContainer label="Location" key="location" isInsidePopoverList={isInsidePopoverList}>
          <LocationFilter
            siteId={site_id}
            stationSubtypeId={station_subtype_id}
            lineId={subsite_id}
            stationId={station_id}
            onUpdateFilters={onUpdateFilters}
            isInsidePopoverList={isInsidePopoverList}
          />
        </AnalyzeFilterContainer>
      )}

      {filter === 'outcome' && (
        <AnalyzeOutcomeFilter
          value={outcome || undefined}
          filterThatForceDisabled={!!filtersValue.serial_number ? 'search' : 'prediction'}
          renderTooltipAndDisableSelect={!!prediction_label_id?.length || !!filtersValue.serial_number}
          isInsidePopoverList={isInsidePopoverList}
          onUpdateFilters={onUpdateFilters}
        />
      )}

      {filter === 'batch' && (
        <AnalyzeFilterContainer label="Batch" key="batch" isInsidePopoverList={isInsidePopoverList}>
          <SearchableSelect
            disableDropdownAnimation
            data-testid="analyze-filters-batch"
            data-test="analyze-filters-batch-name"
            clearable
            // TODO: we should use flat inspections for this
            fetcher={searchValue => service.getInspections({ name: searchValue })}
            missingOptionFetcher={id => service.getInspection(id)}
            formatter={inspection => inspection.name}
            getterKey={getterKeys.metricsFilteredInspectionsData()}
            onKeyUp={e => e.preventDefault()}
            value={inspection_id || undefined}
            onChange={val => onUpdateFilters({ inspection_id: val })}
            showArrow
            stopPropagationOnDropdownClick
            size={isInsidePopoverList ? undefined : 'small'}
            placeholder={isInsidePopoverList ? '' : 'Batch'}
            wrapperClassName={`${Styles.selectWrapper} ${Styles.analyzeSelectContainer}`}
            popupClassName={Styles.dropdownWrapper}
            onInitialLoad={() => onInitialLoad?.('batch')}
            queryOptions={{ noRefetch: true, noRefetchMs: 30 * 1000 }}
            getSelectOptionDataTestId={inspection => `analyze-filters-batch-${inspection.name}`}
          />
        </AnalyzeFilterContainer>
      )}

      {filter === 'recipe' && (
        <AnalyzeFilterContainer label="Recipe" key="recipe" isInsidePopoverList={isInsidePopoverList}>
          <SearchableSelect
            disableDropdownAnimation
            data-testid="analyze-filters-recipe"
            data-test="analyze-filters-recipe-name"
            fetcher={searchValue => service.getRecipes({ parent_name: searchValue, parent_is_deleted: false })}
            formatter={recipe => `${recipe.parent.name} v${recipe.version}`}
            missingOptionFetcher={id => service.getRecipe(id)}
            getterKey={getterKeys.metricsFilteredRecipes()}
            clearable
            onKeyUp={e => e.preventDefault()}
            value={recipe_id || undefined}
            onChange={val => onUpdateFilters({ recipe_id: val })}
            showArrow
            stopPropagationOnDropdownClick
            size={isInsidePopoverList ? undefined : 'small'}
            placeholder={isInsidePopoverList ? '' : 'Recipe'}
            wrapperClassName={`${Styles.selectWrapper} ${Styles.analyzeSelectContainer}`}
            popupClassName={Styles.dropdownWrapper}
            onInitialLoad={() => onInitialLoad?.('recipe')}
            queryOptions={{ noRefetch: true, noRefetchMs: 30 * 1000 }}
            getSelectOptionDataTestId={recipe => `analyze-filters-recipe-${recipe.parent.name}`}
          />
        </AnalyzeFilterContainer>
      )}

      {filter === 'inspector' && (
        <AnalyzeFilterContainer isInsidePopoverList={isInsidePopoverList} label="Inspector" key="inspector">
          <SearchableSelect
            disableDropdownAnimation
            data-testid="analyze-filters-inspector"
            fetcher={searchValue =>
              service.getUsers({ name_or_email: searchValue, role__in: 'inspector,manager,owner' })
            }
            missingOptionFetcher={id => service.getUser(id)}
            formatter={user => `${user.first_name} ${user.last_name}`}
            getterKey={getterKeys.users()}
            clearable
            onKeyUp={e => e.preventDefault()}
            value={user_id || undefined}
            onChange={val => onUpdateFilters({ user_id: val })}
            showArrow
            stopPropagationOnDropdownClick
            size={isInsidePopoverList ? undefined : 'small'}
            placeholder={isInsidePopoverList ? '' : 'Inspector'}
            wrapperClassName={`${Styles.selectWrapper} ${Styles.analyzeSelectContainer}`}
            popupClassName={Styles.dropdownWrapper}
            onInitialLoad={() => onInitialLoad?.('inspector')}
            queryOptions={{ noRefetch: true, noRefetchMs: 30 * 1000 }}
          />
        </AnalyzeFilterContainer>
      )}

      {filter === 'label' && (
        <FilterDisabledTooltip
          filterToHover={filter}
          filterThatForceDisabled="search"
          condition={!!filtersValue.serial_number}
        >
          <AnalyzeFilterContainer label="Label" key="label" isInsidePopoverList={isInsidePopoverList}>
            <LabelSelectWrapper
              labelType="user"
              filtersValue={filtersValue}
              onUpdateFilters={onUpdateFilters}
              disabled={!!filtersValue.serial_number}
              hasMultiSelectClassName={user_label_id === undefined}
              isInsidePopoverList={isInsidePopoverList}
              popoverPlaceholder="Label"
            />
          </AnalyzeFilterContainer>
        </FilterDisabledTooltip>
      )}

      {filter === 'prediction' && (
        <FilterDisabledTooltip
          filterToHover={filter}
          filterThatForceDisabled={!!outcome?.length ? 'outcome' : 'search'}
          condition={!!outcome?.length || !!filtersValue.serial_number}
        >
          <AnalyzeFilterContainer key="prediction" label="prediction" isInsidePopoverList={isInsidePopoverList}>
            <LabelSelectWrapper
              labelType="prediction"
              filtersValue={filtersValue}
              onUpdateFilters={onUpdateFilters}
              disabled={!!outcome?.length || !!filtersValue.serial_number}
              hasMultiSelectClassName={prediction_label_id === undefined}
              isInsidePopoverList={isInsidePopoverList}
              popoverPlaceholder="Prediction"
            />
          </AnalyzeFilterContainer>
        </FilterDisabledTooltip>
      )}

      {filter === 'tool' && (
        <AnalyzeFilterContainer label="Tool" key="tool" isInsidePopoverList={isInsidePopoverList}>
          <PrismSelect
            disableDropdownAnimation
            data-testid="analyze-filters-tools"
            onKeyUp={e => e.preventDefault()}
            clearable
            showArrow
            value={tool_specification}
            onChange={val => {
              onUpdateFilters({ tool_specification: val })
            }}
            stopPropagationOnDropdownClick
            showSearch
            optionFilterProp="content"
            size={isInsidePopoverList ? undefined : 'small'}
            placeholder={isInsidePopoverList ? '' : 'Tool'}
            wrapperClassName={`${Styles.selectWrapper} ${Styles.analyzeSelectContainer}`}
            popupClassName={Styles.dropdownWrapper}
            showSelectedOptionCheck
            options={[
              { value: '', content: <>&mdash;</> },
              ...Object.entries(TOOL_NAMES).map(([toolValue, toolName]) => {
                return {
                  value: toolValue,
                  content: toolName,
                  className: `${Styles.capitalizeOptions} ${Styles.dropdownItemInPopover}`,
                  key: toolValue,
                  dataTestId: `analyze-filters-tools-${toolName.split(' ').join('-').toLowerCase()}`,
                }
              }),
            ]}
          />
        </AnalyzeFilterContainer>
      )}
    </>
  )
}

const getFilters = (tab: AnalyzeTab, filters: Filters, expanded?: string) => {
  const filtersList: {
    name: FilterOption
    active: boolean
  }[] = [
    ...(tab === 'overview' || tab === 'tools' || !!expanded
      ? []
      : [{ name: 'search' as FilterOption, active: tab === 'items' ? !!filters.serial_number : !!filters.search }]),
    { name: 'date', active: !!filters.start || !!filters.end },
    ...(tab === 'items' || tab === 'tools' ? [] : [{ name: 'interval' as FilterOption, active: !!filters.period }]),
    { name: 'product', active: !!filters.component_id },
    { name: 'location', active: !!filters.site_id },
    { name: 'outcome', active: !!filters.outcome },
    ...(tab !== 'tools' ? [] : [{ name: 'tool' as FilterOption, active: !!filters.tool_specification }]),
    ...(tab !== 'tools' && tab !== 'items'
      ? []
      : [
          { name: 'prediction' as FilterOption, active: !!filters.prediction_label_id },
          { name: 'label' as FilterOption, active: !!filters.user_label_id },
        ]),
    { name: 'batch', active: !!filters.inspection_id },
    { name: 'recipe', active: !!filters.recipe_id },
    { name: 'inspector', active: !!filters.user_id },
  ]

  return filtersList
}

const LabelSelectWrapper = ({
  labelType,
  disabled,
  filtersValue,
  onUpdateFilters,
  hasMultiSelectClassName,
  isInsidePopoverList,
  popoverPlaceholder,
}: {
  labelType: 'user' | 'prediction'
  disabled: boolean
  filtersValue: Filters
  onUpdateFilters: FiltersUpdater
  hasMultiSelectClassName?: boolean
  isInsidePopoverList?: boolean
  popoverPlaceholder: 'Label' | 'Prediction'
}) => {
  return (
    <LabelSelect
      labelType={labelType}
      filters={filtersValue}
      onUpdateFilters={onUpdateFilters}
      disabled={disabled}
      wrapperClassName={`${Styles.multiSelectWrapper} ${hasMultiSelectClassName ? Styles.multiSelectPlaceholder : ''}`}
      popupClassName={Styles.dropdownWrapper}
      resultButtonClassName={Styles.prismResultInSelector}
      size={isInsidePopoverList ? undefined : 'small'}
      placeholder={isInsidePopoverList ? '' : popoverPlaceholder}
    />
  )
}

const LocationFilter = ({
  siteId,
  stationSubtypeId,
  lineId,
  stationId,
  onUpdateFilters,
  isInsidePopoverList,
}: {
  siteId: string | undefined
  stationSubtypeId: string | undefined
  lineId: string | undefined
  stationId: string | undefined
  onUpdateFilters: FiltersUpdater
  isInsidePopoverList?: boolean
}) => {
  // Use noRefetch to use existing data from cascader
  const lines = useQueryEntityFromSites({ entity: 'line', siteId, noRefetch: true })

  return (
    <SitesAndLinesCascader
      onKeyUp={e => e.preventDefault()}
      value={getSitesAndLineCascaderValue({
        siteId,
        stationSubtypeId,
        lineId,
        stationId,
      })}
      showStations
      changeOnSelect
      onChange={(value: (string | number)[]) => {
        const newValues = getSiteLineAndStationIdsFromCascaderValue(value as SiteAndLineCascaderLocation)

        // Avoid setting new filters if only the `Independent stations` line option is selected
        if (newValues.lineId === null && !newValues.stationId) return

        const foundLine = lines?.find(line => line.id === newValues.lineId)

        const subsiteTypeId = foundLine?.type_id

        onUpdateFilters({
          site_id: newValues.siteId,
          station_subtype_id: newValues.stationSubtypeId,
          subsite_id: newValues.lineId,
          station_id: newValues.stationId,
          subsite_type_id: subsiteTypeId,
        })
      }}
      className={`${Styles.analyzeSelectContainer} ${Styles.locationSelect}`}
      selectedOptionClassName={Styles.cascaderSelectedOptionContainer}
      size={isInsidePopoverList ? 'large' : 'small'}
      placeholder={isInsidePopoverList ? undefined : 'location'}
      data-testid="analyze-filters-location"
    />
  )
}

const AnalyzeOutcomeFilter = ({
  value,
  filterThatForceDisabled,
  renderTooltipAndDisableSelect,
  isInsidePopoverList,
  onUpdateFilters,
}: {
  value?: any
  filterThatForceDisabled: FilterOption
  renderTooltipAndDisableSelect: boolean
  isInsidePopoverList: boolean
  onUpdateFilters: FiltersUpdater
}) => {
  const [searchOutcome, setSearchOutcome] = useState('')
  return (
    <FilterDisabledTooltip
      filterToHover={'outcome'}
      filterThatForceDisabled={filterThatForceDisabled}
      condition={renderTooltipAndDisableSelect}
    >
      <AnalyzeFilterContainer label="Outcome" key="outcome" isInsidePopoverList={isInsidePopoverList}>
        <PrismSelect
          disableDropdownAnimation
          data-testid="analyze-filters-result"
          key="outcome"
          showArrow
          placeholder={isInsidePopoverList ? '' : 'Outcome'}
          clearable
          size={isInsidePopoverList ? undefined : 'small'}
          disabled={renderTooltipAndDisableSelect}
          filterOption={(inputValue, option) =>
            option?.value?.toString().toLowerCase().includes(inputValue.toLowerCase()) || false
          }
          value={value}
          onChange={val => {
            onUpdateFilters({ outcome: val })
            setSearchOutcome('')
          }}
          wrapperClassName={Styles.analyzeSelectContainer}
          popupClassName={Styles.dropdownWrapper}
          stopPropagationOnDropdownClick
          showSelectedOptionCheck
          options={[
            { value: '', content: <>&mdash;</> },
            ...['pass', 'fail', 'unknown'].map(outcome => ({
              value: outcome,
              dataTestId: `analyze-filters-result-${outcome.toLocaleLowerCase()}`,
              key: outcome,
              className: `${Styles.capitalizeOptions} ${isInsidePopoverList ? Styles.dropdownItemInPopover : ''}`,
            })),
          ]}
          onSearch={value => setSearchOutcome(value)}
          searchValue={searchOutcome}
          showSearch
        />
      </AnalyzeFilterContainer>
    </FilterDisabledTooltip>
  )
}
