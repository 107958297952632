import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { throttle } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { VariableSizeList } from 'react-window'

import { getterKeys, service } from 'api'
import { Divider } from 'components/Divider/Divider'
import { IconButton } from 'components/IconButton/IconButton'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import { prefetch } from 'components/Img/ImgFallback'
import LabelsList from 'components/LabelList/LabelsList'
import { PrismCloseIcon, PrismNavArrowIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { Token } from 'components/Token/Token'
import ZoomableImage from 'components/ZoomableImage/ZoomableImage'
import { useAllToolLabels, useQueryParams } from 'hooks'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { LabelingMetrics, LabelingScreenMode, ToolParent, ToolResult, ToolSpecificationName } from 'types'
import {
  getAoisAndToolsFromRoutine,
  getDisplayThreshold,
  getterAddOrUpdateResultsAndSort,
  isAnyQsFilterActive,
  sortByLabelKind,
  sortBySeverity,
} from 'utils'

import FocusCarousel from './FocusCarousel'
import { EmptyLabelingState } from './LabelingEmptyStates'
import { RoutinesMap } from './LabelingScreen'
import Styles from './LabelingScreen.module.scss'

const ARROW_HOTKEY_NAVIGATION_DELAY = 350

interface FocusLabelScreenProps {
  uniqueToolResults?: ToolResult[]
  listEndRef: React.MutableRefObject<boolean>
  toolParentId: string
  toolSpecificationName: ToolSpecificationName
  currentToolResult?: ToolResult
  metrics: LabelingMetrics | undefined
  parent: ToolParent
  galleryGroupingActive: boolean
  toolResultsBranch?: string
  mode: LabelingScreenMode
  setCurrentToolResult: (newState: ToolResult | undefined) => void
  handleSetToolResult: (newState: ToolResult | undefined) => void
  showInsights: boolean
  setShowInsights: (show: boolean) => void
  fetchNextPage: () => Promise<ToolResult | undefined>
  showEntireImage: boolean
  setShowEntireImage: (show: boolean) => void
  routinesMap: RoutinesMap
}

/**
 * Renders the Focus Labeling mode, here the user can focus and label one toolResult at the time.
 * It also renders a carousel with the next and previous toolResults on the left side.
 *
 * @param uniqueToolResults - the current set of toolResults
 * @param listEndRef - a ref to the end of the list, used to detect when the list is scrolled to the end
 * @param toolParentId - the current tool parent id
 * @param toolSpecificationName - the current tool specification name
 * @param currentToolResult - the current selected toolResult
 * @param metrics - Labeling metrics
 * @param parent - The current Tool Parent
 * @param galleryGroupingActive - wheter gallery or smart groups are active
 * @param toolResultsBrach - the current branch to store tool results
 * @param mode - labeling screen mode
 * @param setCurrentToolResults - state handler for the current tool results
 * @param handleSetToolResult - wrapper around current tool result state handler, sets the current tool result and has additional functionality
 * @param showInsights - whether to show insights
 * @param setShowInsights - state handler for the show insights value
 * @param fetchNextPage - Handler for fetching next page of results
 * @param showEntireImage - Whether to show the entire tool result image (in context)
 * @param setShowEntireImage - State handler for showEntireImage
 * @param routinesMap - Object containing current tool results Routines, keys are routineIds, and values are Routines
 */
const FocusLabelScreen = ({
  uniqueToolResults,
  listEndRef,
  toolParentId,
  toolSpecificationName,
  currentToolResult,
  metrics,
  parent,
  galleryGroupingActive,
  toolResultsBranch,
  mode,
  setCurrentToolResult,
  handleSetToolResult,
  showInsights,
  setShowInsights,
  fetchNextPage,
  showEntireImage,
  setShowEntireImage,
  routinesMap,
}: FocusLabelScreenProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [params] = useQueryParams()
  const { allToolLabels } = useAllToolLabels()

  const galleryBodyRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<VariableSizeList>(null)

  const [showReferenceImage, setShowReferenceImage] = useState(false)

  useEffect(() => {
    if (showInsights && showReferenceImage) {
      setShowReferenceImage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInsights])

  useEffect(() => {
    if (currentToolResult && showInsights && !currentToolResult.insight_image) {
      setShowInsights(false)
    }
  }, [currentToolResult, setShowInsights, showInsights])

  useEffect(() => {
    if (!currentToolResult || !uniqueToolResults) return
    const toolResultIndex = uniqueToolResults?.findIndex(toolResult => toolResult.id === currentToolResult.id)
    if (toolResultIndex >= 0) {
      listRef.current?.scrollToItem(toolResultIndex, 'start')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToolResult])

  const handleArrowUp = useMemo(
    () =>
      throttle((currentToolResultId?: string) => {
        if (!uniqueToolResults || !currentToolResultId) return
        const currentToolResultIndex = uniqueToolResults.findIndex(toolResult => toolResult.id === currentToolResultId)

        if (currentToolResultIndex > 0) {
          const newToolResult = uniqueToolResults?.[currentToolResultIndex - 1]

          handleSetToolResult(newToolResult)
        }
      }, ARROW_HOTKEY_NAVIGATION_DELAY),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uniqueToolResults],
  )

  const handleArrowDown = useMemo(
    () =>
      throttle((currentToolResultId?: string) => {
        if (!uniqueToolResults || !currentToolResultId) return
        const currentToolResultIndex = uniqueToolResults.findIndex(toolResult => toolResult.id === currentToolResultId)

        if (currentToolResultIndex < uniqueToolResults.length - 1) {
          const newToolResult = uniqueToolResults?.[currentToolResultIndex + 1]

          handleSetToolResult(newToolResult)
        }
      }, ARROW_HOTKEY_NAVIGATION_DELAY),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uniqueToolResults],
  )

  const toggleReferenceImage = useCallback(() => {
    if (!currentToolResult) return
    setShowInsights(false)
    setShowReferenceImage(!showReferenceImage)
  }, [currentToolResult, setShowInsights, showReferenceImage])

  const toggleEntireImage = useCallback(() => {
    if (!currentToolResult) return
    setShowInsights(false)
    setShowEntireImage(!showEntireImage)
  }, [currentToolResult, setShowInsights, showEntireImage, setShowEntireImage])

  // This effect is tasked with finding the current selected toolResult either
  // from the query string, or selecting the first element in the list
  useEffect(() => {
    if (!uniqueToolResults || !parent || galleryGroupingActive || !toolResultsBranch) return
    const { tool_result_id } = params

    if (uniqueToolResults && !tool_result_id && !listEndRef.current) {
      if (mode === 'gallery') return
      setCurrentToolResult(uniqueToolResults[0])
      return
    }

    const currentToolResult = uniqueToolResults.find(toolResult => toolResult.id === tool_result_id)

    if (currentToolResult) {
      // If the toolResult is found on the list of toolResults currently in redux,
      // set that toolResult as active
      setCurrentToolResult(currentToolResult)
      return
    }

    if (listEndRef.current && !currentToolResult) {
      setCurrentToolResult(undefined)
      return
    }

    // Running this function means that the toolResult we want to show isn't
    const fn = async () => {
      const removeInvalidId = () => {
        // Remove the id from the query string, it's not valid
        history.replace(
          paths.labelingScreen(toolParentId, mode, {
            ...params,
            tool_result_id: undefined,
          }),
        )
      }

      if (!tool_result_id) return removeInvalidId()
      // We fetch the selected toolResult by ID, and stick it into the getter brach
      const res = await service.getToolResult(tool_result_id)
      if (res.type !== 'success') return removeInvalidId()

      dispatch(
        Actions.getterUpdate({
          key: getterKeys.toolParentToolResults(toolResultsBranch),
          updater: prevRes => getterAddOrUpdateResultsAndSort(prevRes, { results: [res.data] }),
        }),
      )
    }

    fn()
  }, [
    params,
    uniqueToolResults,
    dispatch,
    parent,
    history,
    toolParentId,
    mode,
    galleryGroupingActive,
    toolResultsBranch,
    listEndRef,
    setCurrentToolResult,
  ])

  let currentToolResultImage = currentToolResult?.picture.image

  const currentToolResultRoutine = useMemo(() => {
    if (!currentToolResult?.routine_id) return
    const foundRoutine = routinesMap[currentToolResult.routine_id]
    if (!foundRoutine || foundRoutine === 'loading') return
    return foundRoutine
  }, [currentToolResult, routinesMap])

  const aois = useMemo(() => {
    if (!currentToolResultRoutine) return []
    const { aois } = getAoisAndToolsFromRoutine(currentToolResultRoutine)
    return aois
  }, [currentToolResultRoutine])

  if (showReferenceImage) currentToolResultImage = currentToolResultRoutine?.image

  useEffect(() => {
    if (!currentToolResult || !uniqueToolResults) return
    const toolResultIdx = uniqueToolResults.findIndex(toolResult => toolResult.id === currentToolResult.id)

    if (toolResultIdx === -1) return

    for (let i = toolResultIdx + 1; i <= toolResultIdx + 3; i++) {
      const toolResultToPrefetch = uniqueToolResults[i]
      if (toolResultToPrefetch?.picture.image) prefetch(toolResultToPrefetch.picture.image)
    }
    // eslint-disable-next-line
  }, [currentToolResult?.id])

  const renderImage = () => {
    if (!uniqueToolResults) return <PrismLoader />

    if (!currentToolResult) {
      const filtering = isAnyQsFilterActive(params)
      const userHasLabeled = Object.values(metrics || {}).reduce((aggr, val) => aggr + val, 0) > 0
      return <EmptyLabelingState filtering={filtering} userHasLabeled={userHasLabeled} />
    }

    let toolResultPredictionScoreElement: JSX.Element | undefined = undefined
    if (params.showPredictionScore && currentToolResult.tool?.specification_name) {
      toolResultPredictionScoreElement = (
        <div className={Styles.cardPredictionScore}>
          {getDisplayThreshold(currentToolResult.prediction_score, currentToolResult.tool.specification_name)}
        </div>
      )
    }

    if (showEntireImage) {
      return (
        <ImageWithBoxes
          src={currentToolResultImage}
          boundingBoxes={aois.map(aoi => ({
            ...aoi,
            left: aoi.x,
            top: aoi.y,
            stroke: aoi.parent?.id === currentToolResult.aoi?.parent_id ? '#319cff' : 'rgba(255, 255, 255, 0.45)',
          }))}
          predictionScore={toolResultPredictionScoreElement}
        />
      )
    }

    const currentAoi = currentToolResult?.aoi
    if (!currentAoi) return null
    return (
      <ZoomableImage
        key={currentToolResult.id}
        containerClassName={Styles.bigImageContainer}
        imageBox={currentAoi}
        src={currentToolResultImage}
        overlaySrc={showInsights ? currentToolResult.insight_image : undefined}
        predictionScoreOverlay={toolResultPredictionScoreElement}
      />
    )
  }

  const predictedLabelsList =
    currentToolResult?.prediction_labels && allToolLabels
      ? currentToolResult.prediction_labels.map(
          predictedLabelId => allToolLabels.find(label => label.id === predictedLabelId)!,
        )
      : []

  const predictedLabelsSortedByKind = sortBySeverity(predictedLabelsList.sort(sortByLabelKind))

  const currentAoi = aois.find(aoi => aoi.parent?.id === currentToolResult?.aoi?.parent_id)
  const currentAoiName = currentAoi?.parent?.name || '--'
  const toolResultsCount = uniqueToolResults?.length || 0

  const getItemTitle = () => {
    if (!currentToolResult) return '--'

    if (showReferenceImage) return 'Reference'

    if (!currentToolResult.item) return 'Training Data'

    return currentToolResult.item.serial_number || '--'
  }

  return (
    <div className={Styles.focusLabelContainer}>
      <section className={Styles.labelingContainer}>
        <div
          ref={galleryBodyRef}
          className={Styles.labelCardList}
          id="labeling-queue"
          data-testid={`labeling-screen-image-list-${uniqueToolResults ? 'ready' : 'not-ready'}`}
        >
          {!toolResultsCount && (
            <div className={`${Styles.emptyStateList} ${Shared.verticalChildrenGap32}`}>
              {[0, 1, 2, 3, 4].map(val => {
                return <div key={val} className={`${Styles.toolResultCard} ${Styles.toolResultCardBackground}`} />
              })}
            </div>
          )}

          {uniqueToolResults && toolResultsCount !== 0 && (
            <FocusCarousel
              galleryBodyRef={galleryBodyRef}
              listRef={listRef}
              uniqueToolResults={uniqueToolResults}
              fetchNextPage={fetchNextPage}
              selectedToolResult={currentToolResult}
              onSetToolResult={handleSetToolResult}
            />
          )}
        </div>
      </section>

      <Divider type="vertical" className={`${Styles.labelingDivider} ${Styles.specialCaseNoMargin}`} />

      <section className={Styles.videoSection}>
        <header className={Styles.videoSectionHeader}>
          <Token horizontal label="item:" className={Styles.videoToken} data-testid="labeling-screen-unit-id">
            <PrismOverflowTooltip content={getItemTitle()} className={Styles.tokenValue} />
          </Token>
          <Token horizontal label="area:" className={Styles.videoToken} data-testid="labeling-screen-focus-aoi">
            <PrismOverflowTooltip content={currentAoiName} className={Styles.tokenValue} />
          </Token>
          <Token
            horizontal
            label="original prediction:"
            className={`${Styles.videoToken} ${Styles.originalPredictionContainer}`}
            valueClassName={Styles.predictionLabelsContainer}
            data-testid="labeling-screen-original-prediction"
          >
            {predictedLabelsSortedByKind && (
              <LabelsList
                labels={predictedLabelsSortedByKind}
                toolParentId={toolParentId}
                toolSpecificationName={toolSpecificationName}
                showPopoverOnClick
                className={Styles.labelsList}
              />
            )}
          </Token>

          <IconButton
            icon={<PrismCloseIcon />}
            type="ghost"
            size="small"
            className={Styles.closeFocusMode}
            onClick={() =>
              history.push(paths.labelingScreen(toolParentId, 'gallery', params), { redirectedFromOtherMode: true })
            }
            data-testid="labeling-focus-header-close"
          />
        </header>
        <body data-testid="focus-mode-image-container" className={Styles.imageContainer}>
          <div className={Styles.imageBody}>{renderImage()}</div>
        </body>
        <footer className={Styles.labelingActions}>
          <ToggleButton
            className={Styles.toggleButton}
            title="prev."
            hotkey={<PrismNavArrowIcon direction="up" className={Styles.arrowIcon} />}
            hiddenHotkeys={['ArrowUp']}
            onClick={() => handleArrowUp(currentToolResult?.id)}
            disabled={!currentToolResult}
          />
          <ToggleButton
            className={Styles.toggleButton}
            title="next"
            hotkey={<PrismNavArrowIcon direction="down" className={Styles.arrowIcon} />}
            hiddenHotkeys={['ArrowDown']}
            onClick={() => handleArrowDown(currentToolResult?.id)}
            disabled={!currentToolResult}
          />
          <ToggleButton
            className={Styles.toggleButton}
            title="in context"
            hotkey="C"
            onClick={toggleEntireImage}
            disabled={!currentToolResult}
            active={showEntireImage}
          />
          <ToggleButton
            className={Styles.toggleButton}
            title="reference"
            hotkey="R"
            onClick={toggleReferenceImage}
            disabled={!currentToolResult || !currentToolResult.item}
            active={showReferenceImage}
          />
        </footer>
      </section>
    </div>
  )
}

export default FocusLabelScreen
