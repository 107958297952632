import React from 'react'

import { VERSION, VERSION_RELEASE_NOTES_URL } from 'env'
import { useIsColocated } from 'hooks'

import Styles from './AppVersion.module.scss'

/**
 * Renders app version (company wide version, not frontend cut version).
 * If the user is not colocated, app version is clickable and navigates to release notes.
 *
 * @param className - It is the parent's responsibility to position this component correctly
 */

function AppVersion({ className }: { className?: string }) {
  const { isColocated } = useIsColocated()

  const canShowLink = isColocated === false
  const navigateToReleaseDocs = () => {
    if (!canShowLink) return
    window.open(VERSION_RELEASE_NOTES_URL, '_blank')?.focus()
  }

  return (
    <span
      className={`${className || ''} ${Styles.appVersion} ${canShowLink ? Styles.appVersionHover : ''}`}
      onClick={navigateToReleaseDocs}
      data-testid="app-version-link"
      data-test-attribute={!isColocated ? 'app-version-link-ready' : ''}
    >
      {VERSION}
    </span>
  )
}

export default AppVersion
