import React, { useMemo, useRef, useState } from 'react'

import { InputRef } from 'antd'
import { useDispatch } from 'react-redux'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import { PrismAddIcon, PrismEditIcon, PrismSearchIcon } from 'components/prismIcons'
import PrismLabelButton from 'components/PrismLabelButton/PrismLabelButton'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, info } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import { useData, useHotkeyPress } from 'hooks'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { ToolLabel, ToolResult, ToolSpecificationName } from 'types'
import {
  getDisplaySeverity,
  getLabelName,
  getToolLabelHotkey,
  handleLabelingKeydown,
  handleToolResultsLabelType,
  matchRole,
  replaceAll,
} from 'utils'
import { TRAINABLE_TOOL_SPECIFICATION_NAMES } from 'utils/constants'

import AddToolLabelModal from './AddLabelModal'
import { LabelGlossaryModal } from './LabelGlossaryModal'
import { ToolResultsMapDispatch } from './LabelingGallery/LabelingGallery'
import Styles from './LabelingScreen.module.scss'
import SearchLabel from './SearchLabel'

interface Props {
  toolParentId: string
  toolSpecificationName: ToolSpecificationName
  toolLabels?: ToolLabel[]
  updateToolResultsLabels: handleToolResultsLabelType
  selectedLabelIds: string[] | undefined
  currentToolResults: ToolResult[]
  setSelectedToolResults: ToolResultsMapDispatch
}

/**
 * Renders the labeling menu of the labeling screen, which contains all the labels the user can label a toolResult with.
 * This can be user generated labels for a match tool or the standard labels for any other tool.
 * This component also handles the keyboard shortcuts to label.
 *
 * @param toolParentId - Current tool parent id
 * @param toolSpecificationName - Current tool specification name
 * @param toolLabels - Tool labels for the current tool
 * @param updateToolResultsLabels - Function to update the selected tool results labels
 * @param selectedLabelId - The label id the user just selected, or the one currently tagged to the toolResult.
 * @param currentToolResults - The currentl selected tool results, either from gallery or focus mode
 * @param setSelectedToolResults - Handler to set selected tool results, used to reset the state
 */

function LabelingOptions({
  toolParentId,
  toolSpecificationName,
  toolLabels,
  updateToolResultsLabels,
  selectedLabelIds,
  currentToolResults,
  setSelectedToolResults,
}: Props) {
  const dispatch = useDispatch()
  const me = useData(getterKeys.me())
  const searchInputRef = useRef<InputRef>(null)

  const [labelSearch, setLabelSearch] = useState('')

  const [searchingLabel, setSearchingLabel] = useState(false)
  const [isEditModeActive, setIsEditModeActive] = useState(false)
  const [creatingLabel, setCreatingLabel] = useState(false)
  const [showLabelGlossary, setShowLabelGlossary] = useState(false)
  const [showAddLabelModal, setShowAddLabelModal] = useState(false)

  // used by the modals
  const [selectedToolLabel, setSelectedToolLabel] = useState<ToolLabel | undefined>()

  const showSearchInput = toolLabels?.length || 0 > 7

  const filteredLabels = useMemo(
    () =>
      toolLabels?.filter(label => {
        const currentLabelLowerCase = label.value.toLowerCase()
        const currentLabelLowerCaseSpaces = replaceAll(currentLabelLowerCase, '_', ' ')
        const searchValueLowerCase = labelSearch.toLowerCase().trim()
        if (
          currentLabelLowerCase.includes(searchValueLowerCase) ||
          currentLabelLowerCaseSpaces.includes(searchValueLowerCase)
        )
          return true
        return false
      }),
    [labelSearch, toolLabels],
  )

  const handleKeyDown = (e: KeyboardEvent) => {
    if (creatingLabel || isEditModeActive) return
    // Ensure user can always "delete" a result

    handleLabelingKeydown({
      key: e.key,
      handleToolResultsLabel: updateToolResultsLabels,
      toolLabels: filteredLabels,
    })

    if (searchingLabel) return

    if (e.key === '/' && showSearchInput) {
      e.preventDefault()
      setCreatingLabel(false)
      setSearchingLabel(!searchingLabel)
      searchInputRef.current?.focus()
    }
  }

  useHotkeyPress(handleKeyDown)

  const handleRemoveLabelFromTool = async (toolLabel: ToolLabel) => {
    if (!toolLabels) return
    const newToolLabelsIds = toolLabels.filter(tl => tl.kind === 'custom' && tl.id !== toolLabel.id).map(tl => tl.id)
    const patchToolRes = await service.patchToolParent(toolParentId, { labels: newToolLabelsIds })

    if (patchToolRes.type !== 'success') return error({ title: 'There was a problem removing the label' })

    dispatch(
      Actions.getterUpdate({
        key: getterKeys.toolLabels(toolParentId),
        updater: res => {
          if (!res?.data.results) return res

          const updatedLabels = [...res.data.results.filter(tl => tl.id !== toolLabel.id)]

          return { ...res, data: { ...res.data, results: updatedLabels } }
        },
      }),
    )

    info({ title: 'Label removed', 'data-testid': 'labeling-options-label-removed-success' })
  }

  const renderLabels = () => {
    if (!filteredLabels) {
      return <PrismLoader />
    }

    const activeLabelIds = selectedLabelIds || getSelectedToolLabels(currentToolResults)

    return (
      <>
        {filteredLabels.map((toolLabel, labelIdx) => {
          const isActive = activeLabelIds.includes(toolLabel.id)

          return (
            <PrismLabelButton
              key={toolLabel.id}
              value={getLabelName(toolLabel)}
              severity={getDisplaySeverity(toolLabel)}
              active={isActive}
              hotkey={getToolLabelHotkey({ labelIdx })}
              onClick={() => updateToolResultsLabels({ toolLabel })}
              data-testid={`labeling-options-${toolLabel.value.toLowerCase()}-${toolLabel.severity}`}
              data-test="labeling-options"
              data-test-attribute={isActive ? 'labeling-options-active' : ''}
              popoverClassName={Styles.labelingScreenInfoPopover}
              editMode={isEditModeActive}
              onEditClick={e => {
                e.stopPropagation()
                setSelectedToolLabel(toolLabel)
                setShowLabelGlossary(!showLabelGlossary)
              }}
              onDeleteClick={e => {
                e.stopPropagation()
                modal.confirm({
                  id: 'remove-label-confirmation',
                  'data-testid': 'labeling-options-remove-label-confirm',
                  header: 'Are you sure?',
                  content: (
                    <>
                      <p>Remove the label "{getLabelName(toolLabel)}"?</p>
                      <br />
                      <p>This will hide it as an option for this tool. It won't remove it from any images</p>
                    </>
                  ),
                  okText: 'Remove',
                  onOk: async close => {
                    await handleRemoveLabelFromTool(toolLabel)
                    close()
                  },
                })
              }}
              toolLabel={toolLabel}
              toolParentId={toolParentId}
              toolSpecificationName={toolSpecificationName}
            />
          )
        })}

        {showLabelGlossary && (
          <LabelGlossaryModal
            onClose={() => {
              setShowLabelGlossary(false)
              setSelectedToolLabel(undefined)
            }}
            toolParentId={toolParentId}
            initialSelectedToolLabel={selectedToolLabel}
          />
        )}
        {showAddLabelModal && (
          <AddToolLabelModal
            onClose={() => setShowAddLabelModal(false)}
            currentToolLabels={toolLabels}
            toolSpecificationName={toolSpecificationName}
            onShowGlossaryClick={() => setShowLabelGlossary(true)}
            toolParentId={toolParentId}
          />
        )}
      </>
    )
  }

  return (
    <section className={Styles.labelOptionsWrapper}>
      {showSearchInput && (
        <SearchLabel
          ref={searchInputRef}
          wrapperClassName={Styles.labelSearchWrapper}
          className={Styles.labelSearch}
          search={labelSearch}
          setSearch={setLabelSearch}
          onClose={() => {
            setLabelSearch('')
            setSearchingLabel(false)
            searchInputRef.current?.blur()
          }}
          onFocus={() => {
            setSearchingLabel(true)
          }}
          onBlur={() => {
            setSearchingLabel(false)
          }}
          prefix={<PrismSearchIcon className={Styles.searchIcon} />}
          suffix={<div className={Styles.searchHotkey}>/</div>}
        />
      )}
      <div className={`${Styles.labelOptionsContainer} ${Shared.verticalChildrenGap16}`}>{renderLabels()}</div>

      {TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(toolSpecificationName) && (
        <>
          <Divider />
          <div className={Styles.labelOptionsActions}>
            {!isEditModeActive && matchRole(me, 'manager') && (
              <>
                <Button
                  data-testid="labeling-options-edit-label"
                  type="ghost"
                  size="small"
                  badge={<PrismEditIcon />}
                  onClick={() => {
                    setSelectedToolResults({})
                    setIsEditModeActive(!isEditModeActive)
                  }}
                >
                  edit
                </Button>

                <Button
                  data-testid="labeling-options-add-label"
                  type="ghost"
                  size="small"
                  badge={<PrismAddIcon />}
                  onClick={() => {
                    setSearchingLabel(false)
                    setShowAddLabelModal(!showAddLabelModal)
                  }}
                >
                  add
                </Button>
              </>
            )}
            {isEditModeActive && matchRole(me, 'manager') && (
              <Button
                type="secondary"
                size="small"
                onClick={() => setIsEditModeActive(!isEditModeActive)}
                data-testid="labeling-options-exit-edit-mode"
              >
                exit edit mode
              </Button>
            )}
          </div>
        </>
      )}
    </section>
  )
}

export default LabelingOptions

export const getOldLabelOrOutcomeId = (toolResult: ToolResult) => {
  if (toolResult.active_user_label_set?.tool_labels?.length) {
    return toolResult.active_user_label_set.tool_labels?.[0] || ''
  }
  return toolResult.active_user_label_set?.outcome || ''
}

/**
 * Returns an array representing the active ToolLabels among the selected ToolResults.
 * A ToolLabel id is included in the retured value only if it is present in all the
 * selected ToolResults
 */
export const getSelectedToolLabels = (selectedToolResults: ToolResult[]) => {
  if (!selectedToolResults.length) return []
  const allLabelIds = selectedToolResults.flatMap(toolResult => toolResult.active_user_label_set?.tool_labels || [])

  return allLabelIds.filter(labelId => {
    const labelInAllSelectedToolResults = selectedToolResults.every(toolResult =>
      toolResult.active_user_label_set?.tool_labels.includes(labelId),
    )

    return labelInAllSelectedToolResults
  })
}
