import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { maxBy } from 'lodash'
import { batch, shallowEqual, useDispatch } from 'react-redux'

import { AtomSendCommandResponseData, getterKeys, query, SendToApiResponse, service, useQuery, wsPaths } from 'api'
import { Divider } from 'components/Divider/Divider'
import MultiVideoListener from 'components/MultiVideoListener/MultiVideoListener'
import { areTriggersMismatched } from 'components/NewBatch/NewBatch'
import { error, info, warning } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import RobotRoutineSlotsFetcher from 'components/RobotRoutineSlotsFetcher'
import RobotsStatusListener from 'components/RobotsStatusListener'
import { handleMessages as handleToolsetsMessages } from 'components/RobotToolsetsListener'
import StreamListener from 'components/StreamListener'
import { CLOUD_FASTAPI_WS_URL } from 'env'
import { useRobotDiscovery, useStatusByRobotId, useTypedSelector } from 'hooks'
import * as Actions from 'rdx/actions'
import {
  CameraStatus,
  Capabilities,
  RecipeExpanded,
  RecipeParentExpanded,
  RecipeRoutine,
  Robot,
  RoutineSlot,
  StreamDescriptor,
  Toolset,
  ToolsetDownloadStreamMessage,
  Toolsets,
  VpStatus,
  VpStatusStreamMessage,
} from 'types'
import {
  fetchRobotToolsets,
  getAoisAndToolsFromRoutine,
  getData,
  getLatestActiveToolset,
  getRobotVpStatusMessage,
  sortByNewestFirst,
} from 'utils'

import Styles from './DeployModal.module.scss'
import DeployModalStationStatus from './DeployModalStationStatus'
import RecipeVersionCard from './RecipeVersionCard'

export type RobotWithToolsets = Robot & {
  stationName: string
  stationId: string
  status: CameraStatus | undefined
  vpStatus: VpStatus | undefined
  inspectionId: string | undefined
  inspectionName: string | undefined
  toolsets?: Toolsets
  latestToolset?: Toolset
}

const RECIPE_DEPLOY_ERROR_MESSAGE = 'Deploy not successful, try again'

interface Props {
  onClose: () => any
  recipeParent: RecipeParentExpanded
  recipe: RecipeExpanded
}

/**
 * Renders the deploy modal
 *
 * @param recipeParent - Current recipe parent
 * @param onClose - On close modal handler
 */
const DeployModal = ({ onClose, recipeParent, recipe }: Props) => {
  const dispatch = useDispatch()
  const [deployingRobotIds, setDeployingRobotIds] = useState<Set<string>>(new Set())
  const [deployedRobotIds, setDeployedRobotIds] = useState<Set<string>>(new Set())
  const robotsCountForFullDeployment = useRef<number>()
  const [modalLoaded, setModalLoaded] = useState(false)
  const [deployProgressByRobotId, setDeployProgressByRobotId] = useState<{ [robotId: string]: number }>({})
  const [activeDeploymentRecipeId, setActiveDeploymentRecipeId] = useState<string>()
  const [cancelingDeployment, setCancelingDeployment] = useState(false)
  const [startingDeploymentRecipeId, setStartingDeploymentRecipeId] = useState<string>()
  const [loadingToolsets, setLoadingToolsets] = useState(true)
  const [canceledDeployRobotIds, setCanceledDeployRobotIds] = useState<string[]>([])
  const [failedDeploymentRobotIds, setFailedDeploymentRobotIds] = useState<string[]>([])

  const loadedToolsetsRef = useRef(false)

  const station = useQuery(getterKeys.station(recipeParent.station_id), () =>
    service.getStation(recipeParent.station_id),
  ).data?.data

  const robotIds = station?.robots.map(robot => robot.id) || []

  const statusByRobotId = useStatusByRobotId(robotIds)

  const recipesIds = useMemo(
    () => recipeParent.recipes && new Set(recipeParent.recipes.map(recipe => recipe.id)),
    [recipeParent],
  )

  const routineIds = useMemo(
    () => recipe.recipe_routines.map(recipeRoutine => recipeRoutine.routine.id),
    [recipe.recipe_routines],
  )

  const viewsWithLinkedRobot = useMemo(() => {
    return getViewsWithLinkedRobot(recipe)
  }, [recipe])

  // RobotToolsetsFetcher rendered below ensures we eventually have toolsets for all online robots
  const toolsetsByRobotId = useTypedSelector(state => {
    const toReturn: { [robotId: string]: Toolsets | undefined } = {}

    if (!station) return toReturn

    station.robots.forEach(robot => {
      const toolsets = getData(state.getter, getterKeys.robotToolsets(robot.id))
      if (toolsets) toReturn[robot.id] = toolsets
    })
    return toReturn
  }, shallowEqual)

  const vpStatusMessageByRobotId = useTypedSelector(state => {
    const toReturn: { [robotId: string]: VpStatusStreamMessage | undefined } = {}

    station?.robots.forEach(robot => {
      toReturn[robot.id] = getRobotVpStatusMessage(robot.id, state)
    })

    return toReturn
  }, shallowEqual)

  // Combine the results from Django and from asset-management into one list of robots with toolsets
  const robotsWithToolsets = useMemo(() => {
    const toReturn: RobotWithToolsets[] = []

    station?.robots.forEach(robot => {
      const toolsets = toolsetsByRobotId[robot.id]

      let latestActiveToolset: Toolset | undefined = undefined
      if (recipesIds && toolsets) {
        latestActiveToolset = getLatestActiveToolset(
          Object.values(toolsets).filter(toolset => recipesIds.has(toolset.recipe_status.name)),
        )
      }

      toReturn.push({
        ...robot,
        stationName: station.name,
        stationId: station.id,
        toolsets,
        latestToolset: latestActiveToolset,
        vpStatus: vpStatusMessageByRobotId[robot.id]?.payload.status,
        inspectionId: vpStatusMessageByRobotId[robot.id]?.payload.data.inspection_id,
        inspectionName: vpStatusMessageByRobotId[robot.id]?.payload.data.inspection_definition?.name,
        status: statusByRobotId?.[robot.id],
      })
    })

    return toReturn
  }, [recipesIds, station, statusByRobotId, toolsetsByRobotId, vpStatusMessageByRobotId])

  // We use this filter so that the discoveries are refetched once the robot is reconnected
  const discoveries = useRobotDiscovery(robotsWithToolsets.filter(r => r.status !== 'disconnected').map(r => r.id))

  const latestStationDeployedRecipeId = useMemo(() => {
    const toolsets = robotsWithToolsets
      .filter(robot => robot.status !== 'disconnected' && robot.latestToolset?.recipe_status.state === 'LOADED')
      .map(robot => robot.latestToolset)
      .filter(toolset => !!toolset)
    return maxBy(toolsets, toolset => toolset?.recipe_status.metadata.deployed_at)?.recipe_status.name
  }, [robotsWithToolsets])

  const inspectionRunning = robotsWithToolsets.some(robot => robot.status === 'running')
  const robotsStillLoading = robotsWithToolsets.some(robot => robot.status === 'loading')

  const refetchToolsets = useCallback(async () => {
    setLoadingToolsets(true)

    await Promise.all(robotsWithToolsets.map(robot => fetchRobotToolsets(robot.id, dispatch)))

    setLoadingToolsets(false)
  }, [dispatch, robotsWithToolsets])

  // This effect is in charge of fetching the initial toolsets for each robot
  useEffect(() => {
    if (!station || loadedToolsetsRef.current) return

    const fetchInitialToolsets = async () => {
      loadedToolsetsRef.current = true
      await refetchToolsets()
    }

    fetchInitialToolsets()
  }, [refetchToolsets, station])

  const routineSlotsByRobotId = useRoutineSlotsByRobotId(viewsWithLinkedRobot.map(view => view.robot_id))

  useEffect(() => {}, [routineSlotsByRobotId])

  // This effect is in charge of figuring out if a deployment is in progress and its state for each robot
  useEffect(() => {
    if (activeDeploymentRecipeId || cancelingDeployment || startingDeploymentRecipeId || loadingToolsets) return

    const firstRobotDeploying = robotsWithToolsets
      .filter(robot => robot.status !== 'disconnected')
      .find(robot => {
        const state = robot.latestToolset?.recipe_status.state
        if (!state) return false
        return ['DOWNLOADING', 'QUEUED'].includes(state)
      })

    if (!firstRobotDeploying) return

    setActiveDeploymentRecipeId(firstRobotDeploying.latestToolset?.recipe?.id)

    const newDeployingRobotIds = new Set<string>()
    recipe.recipe_routines.forEach(recipeRoutine => {
      if (recipeRoutine.robot_id) newDeployingRobotIds.add(recipeRoutine.robot_id)
    })

    setDeployingRobotIds(newDeployingRobotIds)
  }, [
    activeDeploymentRecipeId,
    cancelingDeployment,
    loadingToolsets,
    recipe.recipe_routines,
    robotsWithToolsets,
    startingDeploymentRecipeId,
  ])

  const resetDeployingState = () => {
    setActiveDeploymentRecipeId(undefined)
    setDeployingRobotIds(new Set())
    setDeployedRobotIds(new Set())
    setDeployProgressByRobotId({})
    robotsCountForFullDeployment.current = undefined
    setCanceledDeployRobotIds([])
    setStartingDeploymentRecipeId(undefined)
    setFailedDeploymentRobotIds([])
  }

  const removeStartingDeployRobotId = (robotId: string) => {
    setDeployingRobotIds(prev => {
      const updated = new Set(prev)
      updated.delete(robotId)
      return updated
    })
  }

  // This funcion returns a slot_update if needed, we check if there is some toolSlot that needs to be updated,
  // based on existing routine slots and the recipe to be deployed
  const getRobotRoutineSlotUpdate = (view: RecipeRoutine, robotRoutineSlots: RoutineSlot[], recipeId: string) => {
    const tools = getAoisAndToolsFromRoutine(view.routine).tools

    const foundRoutineSlot = robotRoutineSlots.find(
      routineSlot => routineSlot.routine_parent_id === view.routine.parent.id,
    )

    // If current view.routine is not set in any routineSlot for the view robot, we don't
    // need to update anything
    if (!foundRoutineSlot) return {}

    const updatedToolSlots = foundRoutineSlot.tool_slots.map(toolSlot => {
      if (!toolSlot.tool_parent_id) return toolSlot

      // If we have a tool parent id set in the tool slot, we want to find the tool version used in this routine
      const foundToolInNewVersion = tools.find(tool => tool.parent_id === toolSlot.tool_parent_id)

      return {
        ...toolSlot,
        // This would clear the tool slot if the tool is no longer in the current recipe version
        tool_id: foundToolInNewVersion?.id || null,
        tool_parent_id: foundToolInNewVersion?.parent_id || null,
      }
    })

    return {
      slot_update: {
        index: foundRoutineSlot.index,
        routine_slot: {
          ...foundRoutineSlot,
          routine_id: view.routine.id,
          routine_parent_id: view.routine.parent.id,
          recipe_id: recipeId,
          tool_slots: updatedToolSlots,
        },
      },
    }
  }

  const deployRecipe = async (recipeId: string) => {
    setStartingDeploymentRecipeId(recipeId)
    // We fetch the whole Recipe data
    const recipeRes = await service.getRecipe(recipeId)

    if (recipeRes.type !== 'success') {
      resetDeployingState()
      return warning({ title: "Couldn't read routine" })
    }
    const fetchedRecipe = recipeRes.data
    const fethedViewsWithLinkedRobot = getViewsWithLinkedRobot(fetchedRecipe)

    robotsCountForFullDeployment.current = fethedViewsWithLinkedRobot.length

    setDeployingRobotIds(prev => {
      const updated = new Set(prev)
      fethedViewsWithLinkedRobot.forEach(recipeRoutine => {
        if (recipeRoutine.robot_id) updated.add(recipeRoutine.robot_id)
      })
      return updated
    })

    if (!fethedViewsWithLinkedRobot.length) {
      return warning({ title: 'Link a View to a Camera to deploy' })
    }

    const fetchedRoutines = fethedViewsWithLinkedRobot.map(record => record.routine)

    if (areTriggersMismatched(fethedViewsWithLinkedRobot.filter(recipeRoutine => recipeRoutine.routine.settings))) {
      resetDeployingState()
      return error({ title: 'All views in a recipe must have the same trigger mode' })
    }

    const someRoutineWithTools = fetchedRoutines.some(routine => {
      const { tools } = getAoisAndToolsFromRoutine(routine)
      return tools.length > 0
    })

    if (!someRoutineWithTools) {
      resetDeployingState()
      return warning({ title: 'In order to deploy, this recipe needs at least one tool' })
    }

    const capabilitiesByRobotId: { [robotId: string]: Capabilities | null | undefined } = {}
    Object.entries(discoveries || {}).forEach(([robotId, discovery]) => {
      capabilitiesByRobotId[robotId] = discovery?.basler?.capabilities
    })

    const viewsToDeploy = fethedViewsWithLinkedRobot.filter(recipeRoutine => {
      if (!recipeRoutine.routine.settings) {
        removeStartingDeployRobotId(recipeRoutine.robot_id)
        return false
      }

      if (!capabilitiesByRobotId[recipeRoutine.robot_id]) {
        removeStartingDeployRobotId(recipeRoutine.robot_id)
        return false
      }

      const { tools } = getAoisAndToolsFromRoutine(recipeRoutine.routine)
      if (!tools.length) {
        removeStartingDeployRobotId(recipeRoutine.robot_id)
        return false
      }

      const robot = robotsWithToolsets.find(robot => robot.id === recipeRoutine.robot_id)

      if (!robot) return false

      if (robot.status === 'disconnected') {
        removeStartingDeployRobotId(recipeRoutine.robot_id)
        return false
      }

      return true
    })

    // If after refetching, the resolution still mismatches, we throw an error
    if (!camerasResolutionIsCompatibleWithRoutinesSettings(capabilitiesByRobotId, viewsToDeploy)) {
      resetDeployingState()
      return warning({ title: 'A camera has a different resolution than the recipe' })
    }

    // Set the recipe as active if not currently active. This will block further changes
    if (!fetchedRecipe.is_protected) {
      const activeRes = await service.updateRecipe(fetchedRecipe.id, { deployed: true })
      if (activeRes.type !== 'success') {
        resetDeployingState()
        return warning({ title: RECIPE_DEPLOY_ERROR_MESSAGE })
      }

      await query(getterKeys.recipe(fetchedRecipe.id), () => service.getRecipe(fetchedRecipe.id), { dispatch })
    }

    // Resume downloads that were paused. Right now we don't let user pause downloads, but we need to call this anyway because if user has cancelled downloads, load_tool_set doesn't work until we call download_resume
    await Promise.all(
      fethedViewsWithLinkedRobot.map(recipeRoutine => {
        return service.atomSendCommand(
          'asset-management',
          'download_resume',
          recipeRoutine.robot_id,
          {
            command_args: {},
          },
          { retry: { retries: 4, delay: 2000 } },
        )
      }),
    )

    const deployRecipeToRobot = async (view: RecipeRoutine) => {
      const robotId = view.robot_id
      const robotRoutineSlots = routineSlotsByRobotId[robotId]

      if (!robotRoutineSlots) {
        removeStartingDeployRobotId(robotId)
        return
      }

      // This response doesn't have the same shape as the status response, so we need to mutate the data on recipe_routines
      const res = await service.atomSendCommand<Omit<Toolset, 'recipe'> & { recipe: RecipeExpanded }>(
        'asset-management',
        'download_recipe',
        robotId,
        {
          command_args: {
            recipe: fetchedRecipe,
            ...getRobotRoutineSlotUpdate(view, robotRoutineSlots, fetchedRecipe.id),
          },
        },
      )
      if (res.type !== 'success' || res.data.success === false) {
        removeStartingDeployRobotId(robotId)
        return
      }

      const toolset = res.data.result.data

      if (!toolset) {
        removeStartingDeployRobotId(robotId)
        return
      }

      const mutatedRecipeRoutines = toolset.recipe.recipe_routines.map(recipeRoutine => ({
        robot_id: recipeRoutine.robot_id,
        routine_id: recipeRoutine.routine.id,
      }))
      const mutatedToolset: Toolset = {
        ...toolset,
        recipe: { ...toolset.recipe, recipe_routines: mutatedRecipeRoutines },
      }

      dispatch(
        Actions.getterUpdate({
          key: getterKeys.robotToolsets(robotId),
          updater: prevRes => {
            if (!prevRes) return { ...res, data: { [recipeId]: mutatedToolset } }
            return { ...prevRes, data: { ...prevRes.data, [recipeId]: mutatedToolset } }
          },
        }),
      )

      return res
    }

    const deployResponses = await Promise.allSettled(
      viewsToDeploy.map(recipeRoutine => deployRecipeToRobot(recipeRoutine)),
    )

    if (deployResponses.every(entry => entry.status !== 'fulfilled' || !entry.value)) {
      resetDeployingState()
      return error({ title: RECIPE_DEPLOY_ERROR_MESSAGE })
    }

    setActiveDeploymentRecipeId(recipeId)
    setStartingDeploymentRecipeId(undefined)
  }

  const cancelRobotDeployment = async (robotId: string) => {
    if (!activeDeploymentRecipeId) return

    if (!deployedRobotIds.has(robotId)) {
      const response = await service.atomSendCommand('asset-management', 'download_cancel', robotId, {
        command_args: {},
      })
      if (response.type !== 'success' || response.data.success === false) {
        error({ title: 'An error occurred removing the routine' })
        return
      }
    }

    // We must also remove the tool_set from the robot so that it doesn't show up as "MISSING"
    const removeRespose = await service.atomSendCommand('asset-management', 'remove_recipes', robotId, {
      command_args: { ids: [activeDeploymentRecipeId] },
    })
    if (removeRespose.type !== 'success' || removeRespose.data.success === false) {
      error({ title: 'An error occurred removing the routine' })
      return
    }

    setCanceledDeployRobotIds(prev => [...prev, robotId])
  }

  const refetchingToolsetsRef = useRef(false)

  // This effect is in charge of reseting deployment state when station deploy cancel is finished
  useEffect(() => {
    if (refetchingToolsetsRef.current) return
    const handler = async () => {
      if (cancelingDeployment && deployingRobotIds.size === canceledDeployRobotIds.length) {
        setCancelingDeployment(false)
        showCancelMessage()
        resetDeployingState()

        refetchToolsets()
      }
    }

    handler()
  }, [canceledDeployRobotIds.length, cancelingDeployment, deployingRobotIds.size, refetchToolsets])

  const cancelStationDeployment = async () => {
    setCancelingDeployment(true)
    setCanceledDeployRobotIds(Array.from(deployedRobotIds))
    await Promise.all([...deployingRobotIds].map(robotId => cancelRobotDeployment(robotId)))
  }

  // This function figures out which recipes will be removed from which robot
  const removeRecipeFromStation = async () => {
    let someCameraOffline = false
    const recipeIds = recipeParent.recipes.map(recipe => recipe.id)

    const removeRecipesPromises: Promise<SendToApiResponse<AtomSendCommandResponseData<{}>>>[] = []

    robotsWithToolsets.forEach(robot => {
      const deployedRecipeIds = Object.keys(robot.toolsets || {})

      // We only remove recipes that are already deployed to avoid a command error
      const recipesToRemove = deployedRecipeIds.filter(recipeId => recipeIds.includes(recipeId))

      if (!recipesToRemove.length) return

      if (robot.status === 'disconnected') {
        someCameraOffline = true
        return
      }

      removeRecipesPromises.push(
        service.atomSendCommand('asset-management', 'remove_recipes', robot.id, {
          command_args: { ids: recipesToRemove },
        }),
      )
    })

    const responses = await Promise.allSettled(removeRecipesPromises)

    if (responses.some(res => res.status === 'rejected' || res.value.type !== 'success' || !res.value.data.success)) {
      error({ title: 'Remove failed, try again' })
    }
    if (someCameraOffline) {
      warning({ title: 'The recipe could not be removed from offline cameras' })
    }
    await refetchToolsets()
  }

  const robotIdsKey = robotIds.sort().join()
  const streams: StreamDescriptor[] = useMemo(() => {
    return robotIds.map(id => {
      return { element: 'asset-management', stream: 'tool-sets:download', robot_id: id, past_ms: 8 * 1000 }
    })
  }, [robotIdsKey]) // eslint-disable-line

  const handleStreamMessages = useCallback(
    (messages: ToolsetDownloadStreamMessage[]) => {
      batch(() => {
        for (const msg of messages) {
          if (!msg.meta) continue

          handleToolsetsMessages([msg as ToolsetDownloadStreamMessage], msg.meta.robot_id, dispatch)

          if (routineIds.includes(msg.payload.tool_set_id)) {
            if (['failed', 'error'].includes(msg.payload.status)) {
              setFailedDeploymentRobotIds(prev => [...prev, msg.meta!.robot_id])
            }
          }
        }
      })
    },
    [dispatch, routineIds],
  )

  return (
    <>
      <MultiVideoListener element="transcoder-basler-image-thumbnail" stream="compressed" robotIds={robotIds} />
      <StreamListener
        mode="message"
        connect={{ url: `${CLOUD_FASTAPI_WS_URL}${wsPaths.multiStream()}` }}
        onMessages={handleStreamMessages}
        streams={streams}
      />
      <RobotsStatusListener robotIds={robotIds} />

      {viewsWithLinkedRobot.map(view => (
        <RobotRoutineSlotsFetcher key={view.id} robotId={view.robot_id} />
      ))}

      <Modal
        id="deploy-recipe"
        size="wide"
        header="Deploy Recipe"
        headerClassName={Styles.deployModalHeader}
        onClose={onClose}
        modalBodyClassName={Styles.deployBodyContainer}
        showFooter={false}
        onModalLoad={() => setModalLoaded(true)}
        data-testid="deploy-recipe-modal"
      >
        <section className={`${Styles.descriptionSection} ${inspectionRunning ? Styles.fixedHeight : ''}`}>
          <DeployModalStationStatus
            stationName={station?.name}
            robotsWithToolsets={robotsWithToolsets}
            activeDeploymentRecipeId={activeDeploymentRecipeId}
            deployingRobotIds={deployingRobotIds}
            isModalLoaded={modalLoaded}
            setDeployedRobotIds={setDeployedRobotIds}
            deployedRobotIds={deployedRobotIds}
            setDeployProgressByRobotId={setDeployProgressByRobotId}
            deployProgressByRobotId={deployProgressByRobotId}
            resetDeployingState={resetDeployingState}
            refetchToolsets={refetchToolsets}
            robotsCountForFullDeployment={robotsCountForFullDeployment.current}
            startingDeploymentRecipeId={startingDeploymentRecipeId}
            failedDeploymentRobotIds={failedDeploymentRobotIds}
            recipe={recipe}
          />
        </section>
        <Divider type="vertical" className={Styles.deployModalDivider} />
        <section className={Styles.versionSection}>
          {[...recipeParent.recipes].sort(sortByNewestFirst).map((recipe, idx) => (
            <RecipeVersionCard
              key={recipe.id}
              recipeId={recipe.id}
              showPrimaryDeployButton={idx === 0}
              onDeployRecipe={deployRecipe}
              loadingToolsets={loadingToolsets}
              robotsWithToolsets={robotsWithToolsets}
              removeRecipeFromStation={removeRecipeFromStation}
              activeDeploymentRecipeId={activeDeploymentRecipeId}
              cancelStationDeployment={cancelStationDeployment}
              stationStatus={{ runningInspection: inspectionRunning, robotsLoading: robotsStillLoading }}
              cancelingDeployment={cancelingDeployment}
              startingDeploymentRecipeId={startingDeploymentRecipeId}
              latestStationDeployedRecipeId={latestStationDeployedRecipeId}
            />
          ))}
        </section>
      </Modal>
    </>
  )
}

export default DeployModal

const camerasResolutionIsCompatibleWithRoutinesSettings = (
  capabilitiesByRobotId: { [robotId: string]: Capabilities | null | undefined },
  recipeRoutines: RecipeRoutine[],
) => {
  // If routine hasn't been setup, any camera is compatible with it
  if (recipeRoutines?.every(recipeRoutine => !recipeRoutine.routine.settings?.sensor_aoi)) return true

  return recipeRoutines?.every(recipeRoutine => {
    const settings = recipeRoutine.routine.settings
    if (!settings) return false
    const capabilities = capabilitiesByRobotId[recipeRoutine.robot_id]
    if (!capabilities) return false
    return (
      capabilities.cam_max_width_pixels >= settings.sensor_aoi.x + settings.sensor_aoi.width &&
      capabilities.cam_max_height_pixels >= settings.sensor_aoi.y + settings.sensor_aoi.height
    )
  })
}

export const showCancelMessage = () => {
  info({ id: 'DEPLOY_CANCEL_MESSAGE', title: 'Deploy Canceled' })
}

const getViewsWithLinkedRobot = (recipe: RecipeExpanded) => recipe.recipe_routines.filter(view => view.robot_id)

const useRoutineSlotsByRobotId = (robotIds: string[]) => {
  const routineSlotsByRobotId = useTypedSelector(state => {
    const toReturn: Record<string, RoutineSlot[]> = {}

    robotIds.forEach(robotId => {
      const toolSlots = getData(state.getter, getterKeys.robotRoutineSlots(robotId))
      if (toolSlots) {
        toReturn[robotId] = toolSlots
      }
    })

    return toReturn
  }, shallowEqual)

  return routineSlotsByRobotId
}
