import React, { useMemo, useState } from 'react'

import { Checkbox } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { toJpeg } from 'html-to-image'
import { debounce, uniqBy } from 'lodash'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { query } from 'react-redux-query'

import { getterKeys, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import { PrismCopyIcon } from 'components/prismIcons'
import { PrismElementaryIsotypeIcon } from 'components/prismIcons/PrismElementaryIsotypeIcon'
import { PrismEmailIcon } from 'components/prismIcons/PrismEmailIcon'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { ModalBody, ModalFooter, ModalHeader, PrismModal } from 'components/PrismModal/PrismModal'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { Token } from 'components/Token/Token'
import { useAllToolLabels, useData, useDateTimePreferences, useTypedSelector } from 'hooks'
import { localStorageUpdate } from 'rdx/actions'
import { Inspection, ItemExpanded, Recipe, ToolLabel, ToolResultEmptyOutcome, ToolResultOutcome, User } from 'types'
import {
  dataURLtoFile,
  getAllUserRoles,
  getDisplayThreshold,
  getPredictionText,
  getToolResultLabels,
  getUsername,
  sortByLabelKind,
  uploadSingleFile,
} from 'utils'
import { TRAINABLE_TOOL_SPECIFICATION_NAMES } from 'utils/constants'

import Styles from './ToolDetailModal.module.scss'

type ShareTab = 'email' | 'link'

type Props = {
  item: ItemExpanded
  inspection: Inspection | undefined
  recipe: Recipe | undefined
  toolResult: ToolResultEmptyOutcome | undefined
  renderedPictureId: string | undefined
  setShowShareModal: React.Dispatch<React.SetStateAction<boolean>>
  modalOverlayClassName?: string
}

const ELEMENTARY_SUPPORT_EMAIL = 'support@elementaryrobotics.com'
const OTHER_ADDRESS_VALUE = 'otherAddress'

// https://www.abstractapi.com/guides/email-address-pattern-validation RFC 5322 official standard regular expression to validate email addresses
const EMAIL_REGEX = // eslint-disable-next-line
  /^[a-zA-Z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?=.*[a-zA-Z-].*)(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/

export const ShareDetailModal = ({
  renderedPictureId,
  inspection,
  item,
  recipe,
  toolResult,
  setShowShareModal,
  modalOverlayClassName = '',
}: Props) => {
  const { lastEmailAdress, includeImage } = useTypedSelector(state => state.localStorage.shareDetailModalSettings)
  const dispatch = useDispatch()

  const { allToolLabels } = useAllToolLabels()

  const usersGetterKey = getterKeys.usersList({ hideElemUsers: true })
  const org = useData(getterKeys.organization())
  const userListParams = useMemo(
    () => ({
      role__in: getAllUserRoles().join(),
      is_active: true,
      email_domain__not_in: org?.email_domain_skip_eula_allowlist.join(','),
    }),
    [org?.email_domain_skip_eula_allowlist],
  )
  const users = useQuery(usersGetterKey, () => service.getUsers(userListParams)).data?.data.results
  const defaultValues = getDefaultValues({ lastEmailAdress, includeImage, users })
  const {
    formState: { isValid, errors },
    control,
    watch,
    getValues,
  } = useForm({ defaultValues, mode: 'onChange' })
  const [shareTab, setShareTab] = useState<ShareTab>('email')
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const { timeZone } = useDateTimePreferences()

  const recipient = watch('recipient')
  const email = watch('email')

  function validateEmail(emailInput: string) {
    if (recipient !== OTHER_ADDRESS_VALUE) return true
    // Matches a string if it matches `{anything}@{letters}.{letters}`
    return !!emailInput.match(EMAIL_REGEX) || 'Invalid email'
  }

  async function handleShareEmail() {
    // We use `watch` instead of `getValues` return values for the email input since the latter doesn't subscribe
    // to input changes, and we ran into issues when changing the recipient select
    const { recipient, note, includeImageInEmail } = getValues()
    const user = users?.find(user => user.id === recipient)

    let emailToShareTo: string | undefined
    if (recipient === ELEMENTARY_SUPPORT_EMAIL) emailToShareTo = ELEMENTARY_SUPPORT_EMAIL
    else if (recipient !== OTHER_ADDRESS_VALUE) emailToShareTo = user?.email
    else emailToShareTo = email

    if (emailToShareTo) {
      const img_url = includeImageInEmail && renderedPictureId ? await uploadImage(renderedPictureId) : undefined
      saveSettingsInLocalStorage(emailToShareTo, includeImageInEmail)
      const res = await service.shareToolResultOrItem({
        ...getPayload(timeZone, item, toolResult, inspection, recipe, allToolLabels),
        email: emailToShareTo,
        note,
        detail_url: window.location.href,
        img_url,
      })

      if (res.type !== 'success') {
        return error({ title: "Couldn't send the email. Please try again" })
      }
      const userName =
        emailToShareTo === ELEMENTARY_SUPPORT_EMAIL ? 'Elementary Support' : user ? getUsername(user) : emailToShareTo
      success({ title: `Item ${item.serial_number} shared with ${userName}` })

      setShowShareModal(false)
    }
  }

  async function handleCopyLink() {
    await navigator.clipboard.writeText(window.location.href)
    success({ title: 'Link copied!' })
  }

  const refetchUsers = useMemo(
    () =>
      debounce(async (emailSearch: string) => {
        setIsLoadingUsers(true)
        await query(usersGetterKey, () => service.getUsers({ ...userListParams, email: emailSearch }), { dispatch })
        setIsLoadingUsers(false)
      }, 500),
    [dispatch, userListParams, usersGetterKey],
  )

  function saveSettingsInLocalStorage(userEmail: string, includeImage: boolean) {
    dispatch(
      localStorageUpdate({
        key: 'shareDetailModalSettings',
        data: {
          lastEmailAdress: userEmail,
          includeImage,
        },
      }),
    )
  }
  const handleClose = () => {
    setShowShareModal(false)
  }

  function getUsersOptions(users?: User[]) {
    const listDefaultOptions = [
      {
        value: ELEMENTARY_SUPPORT_EMAIL,
        content: 'Elementary Support',
        image: { content: <PrismElementaryIsotypeIcon className={Styles.elementaryCube} /> },
      },
      { value: OTHER_ADDRESS_VALUE, content: OTHER_ADDRESS_VALUE, image: { content: <PrismEmailIcon /> } },
    ]
    if (!users) return listDefaultOptions
    const usersList = users.map(user => ({
      value: user.id,
      key: user.id,
      content: getUsername(user),
    }))
    return [...listDefaultOptions, ...usersList]
  }

  return (
    <PrismModal
      id="share-detail-modal"
      size="largeSimpleForm"
      className={Styles.shareModal}
      overlayClassName={modalOverlayClassName}
      onClose={handleClose}
    >
      <ModalHeader
        onClose={handleClose}
        modalNav={{
          selectedItems: [shareTab],
          onSelect: val => setShareTab(val as ShareTab),
          items: [{ value: 'email' }, { value: 'link' }],
        }}
        className={Styles.titleCase}
      >
        Share this result
      </ModalHeader>
      <ModalBody
        className={`${Styles.shareModalBody} ${shareTab === 'email' ? Styles.modalBodyEmail : Styles.modalBodyLink}`}
      >
        {shareTab === 'email' && (
          <>
            <Token label="recipient (*)" labelClassName={Styles.titleColor}>
              <Controller
                name="recipient"
                rules={{ required: true }}
                control={control}
                render={props => (
                  <PrismSelect
                    {...props}
                    size="middle"
                    showSearch
                    showArrow
                    clearable
                    stopPropagationOnDropdownClick
                    dropdownRender={isLoadingUsers ? () => <PrismLoader /> : undefined}
                    onSearch={refetchUsers}
                    filterOption={false}
                    popupClassName={Styles.selectImageGap}
                    className={Styles.selectImageGap}
                    autoFocus
                    options={getUsersOptions(users)}
                  />
                )}
              />
            </Token>

            <Token
              label="email (*)"
              labelClassName={Styles.titleColor}
              className={`${Styles.emailWrapper} ${
                recipient === OTHER_ADDRESS_VALUE ? Styles.showEmail : Styles.hideEmail
              }`}
            >
              <Controller
                name="email"
                control={control}
                rules={{ validate: validateEmail }}
                render={props => <PrismInput {...props} errors={errors} size="small" />}
              />
            </Token>

            <Token label="note" labelClassName={Styles.titleColor}>
              <Controller name="note" control={control} render={props => <TextArea {...props} />} />
            </Token>

            <Controller
              name="includeImageInEmail"
              control={control}
              render={({ onChange, value }) => (
                <Checkbox className={Styles.checkboxWrapper} checked={value} onChange={e => onChange(e.target.checked)}>
                  Include image in email
                </Checkbox>
              )}
            />
          </>
        )}

        {shareTab === 'link' && (
          <Token label="link address" labelClassName={Styles.titleColor}>
            <PrismInput size="small" value={window.location.href} readOnly />
          </Token>
        )}
      </ModalBody>

      <ModalFooter className={Styles.shareModalFooter}>
        <Button
          badge={shareTab === 'email' ? <PrismEmailIcon /> : <PrismCopyIcon />}
          size="small"
          disabled={shareTab === 'email' && (!isValid || (recipient === OTHER_ADDRESS_VALUE && !email))}
          onClick={shareTab === 'email' ? handleShareEmail : handleCopyLink}
        >
          {shareTab === 'email' ? 'Send' : 'Copy'}
        </Button>
      </ModalFooter>
    </PrismModal>
  )
}

async function uploadImage(renderedPictureId: string) {
  const renderedCropElement = document.getElementById(renderedPictureId)
  if (renderedCropElement) {
    const jpegUrlData = await toJpeg(renderedCropElement, { fontEmbedCSS: '' })
    const file = dataURLtoFile(jpegUrlData, `${renderedPictureId}.jpg`)

    const presignedUrlsRes = await service.getBatchPresignedUrls('picture_image', 'jpg', 1)
    if (presignedUrlsRes.type !== 'success') return
    const presignedUrl = presignedUrlsRes.data.results[0]?.url

    const imgUrl = presignedUrl && (await uploadSingleFile(presignedUrl, file))
    return imgUrl
  }
}

function getOutcomeIcon(outcome: ToolResultOutcome | 'empty') {
  if (outcome === 'pass')
    return {
      color: '#5cd6c6;',
      icon: '&#x2713;',
      label: outcome,
    }
  else if (outcome === 'fail')
    return {
      color: '#ed523d;',
      icon: '&#x29B8;',
      font_size: '18px;',
      label: outcome,
    }
  else
    return {
      color: '#c9c9c9;',
      icon: '&#x3f;',
      label: outcome,
    }
}

function getToolLabelIcons(toolResults: ToolResultEmptyOutcome[], allToolLabels: ToolLabel[] | undefined) {
  const toolResultToolLabels = toolResults.flatMap(toolResult => getToolResultLabels(toolResult, allToolLabels) || [])
  const uniqToolLabels = uniqBy(toolResultToolLabels, toolLabel => toolLabel.id).sort(sortByLabelKind)

  const toolLabelIcons: { color: string; icon: string; value: string }[] = []

  uniqToolLabels.forEach(toolLabel => {
    let color: string
    let icon: string

    if (toolLabel.severity === 'good') {
      color = '#5cd6c6;'
      icon = '&#x2191;'
    } else if (toolLabel.severity === 'neutral') {
      color = '#c9c9c9;'
      icon = '&#x003D;'
    } else if (toolLabel.severity === 'minor') {
      color = '#FFCE41;'
      icon = '&#x21E3;'
    } else {
      color = '#ED523D;'
      icon = '&#x21CA;'
    }
    toolLabelIcons.push({
      color,
      icon,
      value: toolLabel.value,
    })
  })
  return toolLabelIcons
}

function getPayload(
  timezone: string,
  item: ItemExpanded,
  toolResult: ToolResultEmptyOutcome | undefined,
  inspection: Inspection | undefined,
  recipe: Recipe | undefined,
  allToolLabels: ToolLabel[] | undefined,
) {
  const localTime = moment(item.created_at).tz(timezone)

  const baseParams = {
    date: localTime.format('MMM D, YYYY HH:mm:ss z'),
    item_serial_number: item.serial_number,
    site_name: inspection?.site?.name || '--',
    line_name: inspection?.subsites?.[0]?.name || '--',
    inspection_name: inspection?.name || '--',
    station_name: inspection?.station_name || '--',
    product_name: inspection?.component.name || '--',
    recipe_name: recipe?.parent.name || '--',
  }

  if (toolResult && toolResult.tool) {
    // We are sharing a ToolResult
    const sharedToolPayload = {
      tool_name: toolResult.tool.parent_name,
      outcome_icon: getOutcomeIcon(toolResult.calculated_outcome),
    }
    if (TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(toolResult.tool.specification_name))
      return {
        ...baseParams,
        ...sharedToolPayload,
        prediction_score: toolResult.prediction_score
          ? getDisplayThreshold(toolResult.prediction_score, toolResult.tool.specification_name)
          : undefined,
        tool_labels_icons: getToolLabelIcons([toolResult], allToolLabels),
      }
    return {
      ...baseParams,
      ...sharedToolPayload,
      prediction_text: `due to prediction: ${getPredictionText(toolResult) || '--'}`,
    }
  }
  // We are sharing an Item
  return {
    ...baseParams,
    outcome_icon: getOutcomeIcon(item.calculated_outcome),
    tool_labels_icons: getToolLabelIcons(item.pictures.flatMap(picture => picture.tool_results) || [], allToolLabels),
  }
}

function getDefaultValues({
  lastEmailAdress,
  includeImage,
  users,
}: {
  lastEmailAdress?: string
  includeImage?: boolean
  users?: User[]
}) {
  let recipient: string | undefined = undefined
  let email: string | undefined = undefined
  const user = users?.find(user => user.email === lastEmailAdress)
  if (lastEmailAdress && lastEmailAdress !== ELEMENTARY_SUPPORT_EMAIL && !user) {
    recipient = OTHER_ADDRESS_VALUE
    email = lastEmailAdress
  }
  if (lastEmailAdress === ELEMENTARY_SUPPORT_EMAIL || lastEmailAdress === undefined)
    recipient = ELEMENTARY_SUPPORT_EMAIL
  if (user) recipient = user.id

  return {
    recipient,
    email,
    note: undefined,
    includeImageInEmail: includeImage !== undefined ? includeImage : true,
  }
}
