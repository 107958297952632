/* eslint-disable react-hooks/rules-of-hooks  */
/* eslint-disable no-inline-styles/no-inline-styles  */
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Popover, Radio } from 'antd'
import { debounce, isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { Rnd } from 'react-rnd'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service, useQuery, wsPaths } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import CameraSettings from 'components/CameraSettings/CameraSettings'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import FullScreen, { FullScreenHeader } from 'components/FullScreen/FullScreen'
import { IconButton } from 'components/IconButton/IconButton'
import { AoiOutlineConfiguration } from 'components/ImageWithBoxes/AoiOutline'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import {
  PrismAddIcon,
  PrismCameraViewIcon,
  PrismCloseIcon,
  PrismCropIcon,
  PrismDiscardIcon,
  PrismElementaryCube,
  PrismExpandIcon,
  PrismGearIcon,
  PrismOverflowIcon,
  PrismOverlayIcon,
  PrismPassIcon,
  PrismStopIcon,
  PrismUploadIcon,
  PrismVideoLoadError,
  PrismWarningIcon,
  PrismZoomInIcon,
} from 'components/prismIcons'
import { dismiss, error, info, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import PrismTooltipWithPopover from 'components/PrismTooltipWithPopover/PrismTooltipWithPopover'
import { RobotDisplayName } from 'components/RobotDisplayName/RobotDisplayName'
import RobotsStatusListener from 'components/RobotsStatusListener'
import { Status } from 'components/Status/Status'
import UploadModal from 'components/UploadModal/UploadModal'
import VideoWithLastFrameTime from 'components/Video/VideoWithLastFrameTime'
import ZoomableImage from 'components/ZoomableImage/ZoomableImage'
import { IS_LOCAL_DEPLOY } from 'env'
import {
  useDisableVirtualKeyboard,
  useGetCurrentInspectionId,
  useRobotDiscovery,
  useRobotStatus,
  useStationStatus,
} from 'hooks'
import Shared from 'styles/Shared.module.scss'
import {
  Box,
  Capabilities,
  RecipeExpanded,
  Routine,
  RoutineSettings,
  RoutineWithAois,
  SettingsRules,
  Station,
} from 'types'
import {
  calculatePercentage,
  captureFrame,
  constrainBoxInBounds,
  contain,
  getAoisAndToolsFromRoutine,
  getDefaultCameraSettings,
  getRad,
  getRobotDisplayName,
  isRecipeOrRoutineResponseProtected,
  protectedOnChange,
  refreshRoutineAndRecipe,
  resizeImageFile,
  rotatePoint,
  sleep,
  stopRobotBatch,
} from 'utils'
import { promptDeepCopy } from 'utils/promptDeepCopy/promptDeepCopy'

import Styles from './Capture.module.scss'
import { scaleBox } from './Configure/Tools/AOIEditing/Utils'
import DeleteViewModal from './DeleteViewModal'
import LinkViewModal from './LinkViewModal'
import RenameRobotModal from './RenameRobotModal'

const MAXBANDWIDTH = 740 * 1000000 // Empirical max bandwidth to GigE, bits per second (spec is 1000 * 1000000)
// Bits per pixel; it's 32, RGBA, on disk, but for purposes of bandwidth it's only 8
const BITDEPTH = 8

const RIGHT_ANGLES = [90, 270]

const videoStreamUrls = {
  standard: wsPaths.videoBaslerStandard,
  high: wsPaths.videoBaslerHigh,
  highest: wsPaths.videoBaslerHighest,
}

export const defaultItemCorrelation = { type: 'composite', window_ms: 500 } as const
export const defaultRecipeRoutineItemCorrelationSettings = { offset_ms: 0 } as const

const triggerSettings: (keyof RoutineSettings)[] = [
  'camera_trigger_mode',
  'interval_ms',
  'trigger_input',
  'trigger_delay_ms',
  'trigger_debounce_ms',
  'trigger_edge',
]

interface Props {
  routine: RoutineWithAois
  routineParentId: string
  recipe: RecipeExpanded
  robotId: string | undefined
  confirmRemoveReferencePhotoIsOpen: boolean
  versionDrawerOpen: boolean
  station: Station | undefined
}

export type SizeHelperHighlightOptions = 'min' | 'max' | 'search'

/**
 * Renders capture component in which users can set the camera settings, take reference photos
 * and test triggers
 *
 * @param routine - The currently selected routine
 * @param onUpdateRoutine - Callback for when a routine is updated
 * @param onSaveReferencePhoto - Callback for when a reference photo is taken and saved
 * @param confirmRemoveReferencePhotoIsOpen - whether the confirmation modal is currently open
 * @param versionDrawerOpen - Whether the version drawer is open
 *
 * Business Rules:
 * - The item correlation window_ms should default to half of the interval_ms set on this component
 */
function Capture({
  routine,
  routineParentId,
  recipe,
  robotId,
  confirmRemoveReferencePhotoIsOpen,
  versionDrawerOpen,
  station,
}: Props) {
  const history = useHistory()
  const dispatch = useDispatch()

  /** REFS AND STATE **/
  const videoContainer = useRef<HTMLDivElement>(null)
  const referenceImageContainer = useRef<ImageWithBoxes>(null)
  const capturedFrameUrlRef = useRef<string>()
  const [capturedFrame, setCapturedFrame] = useState<Blob>()
  const [routineSettingsState, setRoutineSettings] = useState<RoutineSettings>()
  const [isLiveFeedDisconnected, setIsLiveFeedDisconnected] = useState<boolean>(false)
  const [cropMode, setCropMode] = useState<boolean>(false)
  const [renameCameraModalOpen, setRenameCameraModalOpen] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [zoom, setZoom] = useState<0.5 | 0.33 | 0.25>()
  const [compareReference, setCompareReference] = useState<'overlay' | 'side-by-side'>()
  const [isPhotoBeingUploaded, setIsPhotoBeingUploaded] = useState(false)
  const [showLinkViewModal, setShowLinkViewModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [isFeedQualityPopoverVisible, setIsFeedQualityPopoverVisible] = useState<boolean>(false)
  const [feedQualityValue, setFeedQualityValue] = useState<'standard' | 'high' | 'highest'>('standard')
  const [configureWarningModal, setConfigureWarningModal] = useState<{
    onOk: () => Promise<boolean>
    onCancel?: () => void
  }>()
  const [referenceWarningModal, setReferenceWarningModal] = useState<{ onOk: () => Promise<boolean> }>()

  /** END REFS AND STATE **/

  const robot = useQuery(getterKeys.robot(robotId || ''), () => service.getRobot(robotId || '')).data?.data
  const robotStatus = useRobotStatus(robotId)
  const stationStatus = useStationStatus(station)

  const isRobotLoadingRunningOrOffline = ['loading', 'running', 'disconnected'].includes(robotStatus)
  const isStationLoadingOrRunning = ['loading', 'running'].includes(stationStatus)

  const robotDiscoveries = useRobotDiscovery(station?.robots.map(robot => robot.id))
  const robotCapabilities = robot && robotDiscoveries?.[robot.id]?.basler?.capabilities

  useEffect(() => {
    if (!referenceImageContainer.current) return

    // This timeout waits for the version management animation to end and then recalculates the image size using the draw function
    window.setTimeout(() => referenceImageContainer.current?.draw(), 300)
  }, [versionDrawerOpen])

  const cameraSettings = useMemo(
    () => robotCapabilities && getDefaultCameraSettings(robotCapabilities),
    [robotCapabilities],
  )

  const routineSettings = routineSettingsState || routine.settings || cameraSettings?.routine

  const refreshRoutineAndRecipeRedux = useCallback(async () => {
    await refreshRoutineAndRecipe({
      routineId: routine.id,
      recipeId: recipe.id,
      recipeParentId: recipe.parent_id,
      dispatch,
    })
    // by setting this to undefined the form makes use of the fetched routine data
    setRoutineSettings(undefined)
  }, [routine.id, recipe.id, recipe.parent_id, dispatch])

  const currentInspectionId = useGetCurrentInspectionId(robot ? [robot.id] : undefined)
  // Apply the routine settings to the camera once camera is defined, as long it's not running an inspection

  useEffect(() => {
    // if currentInspectionId is null, we haven't fetched robot's events yet so we can't be sure it isn't running an inspection
    // unless all of these conditions are met, it's not safe to apply settings to a camera
    if (!routineSettings || !robot || currentInspectionId || currentInspectionId === null || !robotCapabilities) return
    applySettings(routineSettings)
  }, [robot?.id, currentInspectionId, robotCapabilities?.cam_model]) // eslint-disable-line

  // If routine settings have not yet been set for routine, set them based on selected robot
  useEffect(() => {
    async function setDefaultRoutineSettings() {
      if (cameraSettings && !routine.settings) {
        const res = await service.patchRoutine(routine.id, {
          settings: cameraSettings.routine,
        })
        if (res.type === 'success') refreshRoutineAndRecipeRedux()
      }
    }
    setDefaultRoutineSettings()
  }, [routine, robot, refreshRoutineAndRecipeRedux, cameraSettings])

  // This effect handles addition and removal of key listeners and info message whenever zoom is changed
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setZoom(undefined)
      }
    }
    if (zoom) {
      window.addEventListener('keydown', handleKeyDown)
      info({
        id: 'zoom-message-info',
        title: 'Hover to zoom, ESC to exit',
        duration: 0,
      })
    }
    return () => {
      if (zoom) {
        window.removeEventListener('keydown', handleKeyDown)
        dismiss('zoom-message-info')
      }
    }
  }, [zoom])

  const routineHasImage = () => {
    // If it does, changing settings that affect camera feed means user has to retake image / reconfigure AOIs
    return !!routine?.image
  }

  const routineHasAois = () => {
    // If it does, changing settings that affect camera feed means user has to retake image / reconfigure AOIs
    return !!routine?.aois.length
  }

  const routineHasTools = getAoisAndToolsFromRoutine(routine).tools.length > 0

  const savedSettings = routine.settings || cameraSettings?.routine
  const applySettingsDisabled = routineSettingsState ? isEqual(routineSettingsState, savedSettings) : true

  const fullSizeSensorAoi = cameraSettings?.routine.sensor_aoi

  const isImgRotated90Deg = RIGHT_ANGLES.includes(routineSettings?.image_rotation_degrees || 0)

  // Dimensions of the image displayed in the cointainer
  const imgDimensions = fullSizeSensorAoi && {
    ...fullSizeSensorAoi,
    width: isImgRotated90Deg ? fullSizeSensorAoi.height : fullSizeSensorAoi.width,
    height: isImgRotated90Deg ? fullSizeSensorAoi.width : fullSizeSensorAoi.height,
  }

  // Multiply bits per pixel * number of pixels, divide by max # of bits per second
  const minFullSizeIntervalSeconds =
    fullSizeSensorAoi && (BITDEPTH * fullSizeSensorAoi.width * fullSizeSensorAoi.height) / MAXBANDWIDTH
  const minFullSizeIntervalMs = minFullSizeIntervalSeconds && minFullSizeIntervalSeconds * 1000

  useDisableVirtualKeyboard()

  const showError = () => {
    setRoutineSettings(undefined)
    error({
      title: "Couldn't apply the settings, please try again",
      id: 'apply_settings_fail',
      'data-testid': 'capture-apply-settings-error',
    })
    refreshRoutineAndRecipeRedux()
    return false
  }

  const applySettings = useMemo(
    () =>
      debounce(
        async (routineSettings: RoutineSettings) => {
          if (!robotCapabilities || !robot) return true
          const updatedSettings = { ...routineSettings }

          const res = await service.atomSendCommand('hal', 'settings', robot.id, {
            command_args: {
              settings: updatedSettings,
              robot: {
                cam_model: robotCapabilities.cam_model,
                cam_max_height_pixels: robotCapabilities.cam_max_height_pixels,
                cam_max_width_pixels: robotCapabilities.cam_max_width_pixels,
                cam_max_fps: robotCapabilities.cam_max_fps,
              },
            },
          })
          if (res.type !== 'success' || !res.data.success) {
            await query(getterKeys.robotDiscovery(robot.id), () => service.atomGetRobotDiscovery([robot.id]), {
              dispatch,
            })
            return showError()
          }
          return true
        },
        500,
        // We use leading here to be able to await the return of this debounced function, if we don't use leading
        // we are actually getting the value from the previous call to debounce, and not awaiting anything.
        // https://github.com/lodash/lodash/issues/4400#issuecomment-904931112
        { leading: true },
      ),
    [robot, robotCapabilities], // eslint-disable-line
  )

  /**
   * Calls HAL to apply settings. If this succeeds, saves settings to DB and
   * refreshes routine.
   */
  const applyAndSaveSettings = async (
    routineSettings: RoutineSettings,
    options?: {
      routineFields?: Partial<Routine>
    },
  ) => {
    const { routineFields } = options || {}

    routineSettings.sensor_aoi = constrainBoxInBounds(
      { ...routineSettings.sensor_aoi },
      routineSettings.camera_properties
        ? {
            maxWidth: routineSettings.camera_properties.cam_max_width_pixels,
            maxHeight: routineSettings.camera_properties.cam_max_height_pixels,
          }
        : undefined,
    )

    const applySettingsSuccess = await applySettings(routineSettings)

    if (!applySettingsSuccess) return false

    // If the item correlation is set to composite, we need to update the window, otherwise we keep it the same
    let item_correlation = recipe.user_settings?.item_correlation

    if (item_correlation?.type.includes('composite') && routineSettings.camera_trigger_mode === 'manual') {
      item_correlation = defaultItemCorrelation
      // Update routines cameras offset linked to recipe
      recipe.recipe_routines.forEach(async recipeRoutine => {
        if (
          recipeRoutine.user_settings?.item_correlation?.offset_ms ===
          defaultRecipeRoutineItemCorrelationSettings.offset_ms
        )
          return

        const res = await service.patchProtectedRecipeRoutine(recipeRoutine.id, {
          user_settings: {
            ...recipeRoutine?.user_settings,
            item_correlation: defaultRecipeRoutineItemCorrelationSettings,
          },
        })
        if (res.type !== 'success') return showError()
      })
    } else if (item_correlation?.type === 'composite_multiple_picture_single_camera') {
      item_correlation.self_window_ms = routineSettings.interval_ms / 2
    } else if (item_correlation?.type === 'composite_multiple_picture_multiple_camera') {
      item_correlation.self_window_ms = routineSettings.interval_ms / 2
      item_correlation.window_ms = routineSettings.interval_ms / 2
    }

    // Composite item mode is disabled for continuous trigger, so we must force item correlation to be distinct
    if (routineSettings.camera_trigger_mode === 'automatic') item_correlation = { type: 'distinct' }

    // Save recipe and routine settings to the db
    const updateRecipeRes = await service.patchRecipe(recipe.id, {
      user_settings: { ...recipe.user_settings, item_correlation },
    })

    if (
      isRecipeOrRoutineResponseProtected(updateRecipeRes, {
        routineParentId: routine.parent.id,
        history,
        recipe: recipe,
      })
    )
      return true

    if (updateRecipeRes.type !== 'success') return showError()

    const updateRoutineRes = await service.patchRoutine(routine.id, {
      ...routineFields,
      settings: routineSettings,
    })
    if (
      isRecipeOrRoutineResponseProtected(updateRoutineRes, {
        routineParentId: routine.parent.id,
        history,
        recipe: recipe,
      })
    )
      return true

    if (updateRoutineRes.type !== 'success') return showError()

    await refreshRoutineAndRecipeRedux()
    return true
  }

  // Called by the settings control when their values change
  const setSettings = (newSettings: { routine?: Partial<RoutineSettings> }) => {
    if (!routineSettings) return

    const newRoutineSettings = { ...routineSettings, ...(newSettings.routine || {}) }

    // remove undefined keys
    removeUndefinedKeysFromObject(newRoutineSettings)

    setRoutineSettings(newRoutineSettings)
  }

  const settingsRules = robotCapabilities && routineSettings && getCameraRules(robotCapabilities, routineSettings)
  const sensorAoi = routineSettings && routineSettings.sensor_aoi

  const containerRect = videoContainer.current?.getBoundingClientRect()

  const scaledDown =
    containerRect &&
    imgDimensions &&
    fullSizeSensorAoi &&
    contain(imgDimensions.width, imgDimensions.height, containerRect.width, containerRect.height)

  // Crop variables
  const {
    x: cropX,
    y: cropY,
    width: cropWidth,
    height: cropHeight,
  } = getCropBox(sensorAoi, fullSizeSensorAoi, scaledDown, imgDimensions, routineSettings?.image_rotation_degrees || 0)

  const currentBandWidth =
    sensorAoi &&
    fullSizeSensorAoi &&
    routineSettings &&
    (BITDEPTH * sensorAoi.width * sensorAoi.height * 1000) / routineSettings.interval_ms
  const currentBandWidthPercent = currentBandWidth && calculatePercentage(currentBandWidth, MAXBANDWIDTH)

  const bandWidthBars =
    currentBandWidthPercent &&
    [1, 20, 40, 60, 80, 95].map(v => {
      return (
        <div
          key={v}
          className={(() => {
            if (currentBandWidthPercent > 100) return `${Styles.progressBar} ${Styles.progressBar_red}`
            if (currentBandWidthPercent > 95) return `${Styles.progressBar} ${Styles.progressBar_orange}`
            if (v < currentBandWidthPercent) return `${Styles.progressBar} ${Styles.progressBar_green}`
            return Styles.progressBar
          })()}
        />
      )
    })

  /** CONDITIONS FOR DISABLING BUTTONS **/
  const baseCameraHeaderOptsDisabled = !robot || isRobotLoadingRunningOrOffline || versionDrawerOpen

  const feedQualityControlDisabled = baseCameraHeaderOptsDisabled || isStationLoadingOrRunning

  const cameraHeaderOptionsButtonDisabled = baseCameraHeaderOptsDisabled || isLiveFeedDisconnected

  const cameraSettingsDisabled =
    cameraHeaderOptionsButtonDisabled || !!currentInspectionId || !!capturedFrame || cropMode

  const cropButtonDisabled =
    cameraHeaderOptionsButtonDisabled ||
    !fullSizeSensorAoi ||
    !minFullSizeIntervalMs ||
    !applySettingsDisabled ||
    !!currentInspectionId ||
    isPhotoBeingUploaded ||
    routineHasTools

  const livePhotoButtonDisabled = cameraHeaderOptionsButtonDisabled || !applySettingsDisabled || !!currentInspectionId

  /** EVENT HANDLERS **/

  const handleApplySettingsClick = async () => {
    if (!routineSettings || !minFullSizeIntervalMs || !fullSizeSensorAoi) return true
    // Wait for a bit to ensure hardware updates previously applied by HAL have successfully propagated before trying to apply settings again
    await sleep(500)

    const updatedSettings = { ...routineSettings }

    if (updatedSettings.rotate_180) {
      updatedSettings.image_rotation_degrees = 180
      updatedSettings.rotate_180 = undefined
    }

    if ((currentBandWidth && currentBandWidth > MAXBANDWIDTH) || cropMode) {
      if (!cropMode) info({ title: 'You need to crop the feed to support this interval' })
      const applyCropSettingsSuccess = await applySettings({
        ...updatedSettings,
        sensor_aoi: fullSizeSensorAoi,
        interval_ms: Math.ceil(minFullSizeIntervalMs / updatedSettings.interval_ms) * updatedSettings.interval_ms,
      })

      if (applyCropSettingsSuccess) setCropMode(true)
      return applyCropSettingsSuccess
    }

    if (routineHasImage() && routine.settings && reconfigureRoutineRequired(updatedSettings, routine.settings)) {
      // Check if routine is protected before allowing user to remove reference image
      const routineRes = await service.getRoutine(routine.id)
      if (routineRes.type !== 'success') return
      if (routineRes.data.is_protected) {
        return promptDeepCopy({
          routineParentId: routine.parent.id,
          history,
          recipe: recipe,
        })
      }
      return setConfigureWarningModal({ onOk: () => applyAndSaveSettings(updatedSettings) })
    }
    return applyAndSaveSettings(updatedSettings)
  }

  const handleRobotOptionMenuClick = (value: 'rename') => {
    if (value === 'rename') setRenameCameraModalOpen(true)
  }

  const handleRenameRobot = async () => {
    if (!robot) return
    await query(getterKeys.robot(robot.id), () => service.getRobot(robot.id), {
      dispatch,
    })
  }

  const handleClickTakeOrSavePhoto = protectedOnChange<React.SyntheticEvent<Element, Event>>(
    async () => {
      if (!robot) return
      // We haven't read frame from camera yet, so get it, then return early
      if (!capturedFrame) {
        info({
          title: 'Uploading high definition photo to cloud for optimal analysis...',
          id: 'uploading-picture',
          duration: 0,
        })
        setIsPhotoBeingUploaded(true)
        // Wait for a bit to ensure hardware updates applied by HAL (such as crop size) have successfully propagated to transcoder video feed before grabbing frame from this feed
        await sleep(500)
        const frame = await captureFrame(robot.id)
        dismiss('uploading-picture')
        setIsPhotoBeingUploaded(false)
        if (!frame) {
          return error({ title: "Couldn't capture image, please try again", 'data-testid': 'capture-image-error' })
        }

        capturedFrameUrlRef.current = URL.createObjectURL(frame)
        setCapturedFrame(frame)
        return
      }

      if (routineHasImage() && routineHasAois()) return setReferenceWarningModal({ onOk: save })

      return save()
    },
    { routine, history, recipe },
  )

  const handleImageUpload = async (files: File[]) => {
    const file = files[0]
    setUploadModalOpen(false)
    info({
      id: 'uploading-message',
      title: 'Uploading reference image',
      duration: 0,
    })
    await save(file)
    dismiss('uploading-message')
  }

  // We have frame; upload it to cloud
  const save = useCallback(
    async (file?: File) => {
      if (!capturedFrame && !file) return false

      // Attempt to get presigned URL, upload file direct to cloud storage, and report URL to API
      let resSuccess = false
      const urlRes = await service.getBatchPresignedUrls('routine_image', 'jpg', 1)
      if (urlRes.type === 'success') {
        const url = urlRes.data.results[0]?.url

        let uploadFile = file

        if (!uploadFile && capturedFrame) uploadFile = new File([capturedFrame], 'file')

        if (uploadFile && url) {
          const uploadRes = await service.uploadFile(uploadFile, url, { headers: { 'content-type': '' } })

          if (uploadRes.type === 'success') {
            const apiRes = await service.patchRoutine(routine.id, { image: url })
            resSuccess = apiRes.type === 'success'
          }

          const thumbnailUrlRes = await service.getBatchPresignedUrls('routine_image_thumbnail', 'jpg', 1)

          if (thumbnailUrlRes.type === 'success') {
            const resizedFile = await resizeImageFile(uploadFile, 160, 120, 'jpg')
            const thumbnailUrl = thumbnailUrlRes.data.results[0]?.url
            if (resizedFile && thumbnailUrl) {
              const thumbnailRes = await service.uploadFile(resizedFile, thumbnailUrl, {
                headers: { 'content-type': '' },
              })
              if (thumbnailRes.type === 'success') {
                await service.patchRoutine(routine.id, { image_thumbnail: thumbnailUrl })
              }
            }
          }
        }
      }

      if (IS_LOCAL_DEPLOY) {
        let image = file
        if (!image && capturedFrame) {
          image = new File([capturedFrame], 'position.jpg')
        }

        if (image) {
          const uploadRes = await service.sendFileToDiskLocalDeploy(image, 'routine', 'jpg')
          if (uploadRes.type === 'success') {
            const apiRes = await service.patchRoutine(routine.id, { image: uploadRes.data.url })
            resSuccess = apiRes.type === 'success'
          }
        }
      }

      if (!resSuccess) {
        error({ title: 'Failed to capture image, please try again' })
        return false
      } else {
        success({
          title: 'Reference image uploaded successfully',
          'data-testid': `capture-reference-image-success-${robotId}`,
        })
      }

      setCapturedFrame(undefined)
      if (capturedFrameUrlRef.current) URL.revokeObjectURL(capturedFrameUrlRef.current)
      refreshRoutineAndRecipeRedux()

      return true
    },
    [capturedFrame, refreshRoutineAndRecipeRedux, robotId, routine.id],
  )

  const handleOverlayClick = useCallback(() => {
    switch (compareReference) {
      case 'overlay':
        setCompareReference('side-by-side')
        break
      case 'side-by-side':
        setCompareReference(undefined)
        break
      default:
        setCompareReference('overlay')
        break
    }
  }, [compareReference])

  const renderCameraTitle = () => {
    if (capturedFrame) return 'Test Photo'
    if (cropMode) return 'Crop Feed'
    if (!robot) return 'No Camera'

    return getRobotDisplayName(robot)
  }

  const getPrimaryButtonBadge = () => {
    return capturedFrame ? <PrismPassIcon /> : <PrismAddIcon />
  }

  const getPrimaryButtonText = () => {
    if (capturedFrame) {
      if (screenWidth > 1359) return 'save as reference image'
      return 'save reference'
    }
    return 'take photo'
  }

  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

  /** RENDER **/

  let cropTitle = 'Crop'
  if (routineHasTools) cropTitle = 'Delete your tools in order to crop feed'
  return (
    <>
      {robot && <RobotsStatusListener robotIds={[robot.id]} />}
      <div className={Styles.layoutOverflow} data-testid={routine.image ? 'capture-finish-image-saved' : undefined}>
        <section className={`${Styles.captureGridContainer} ${versionDrawerOpen ? Styles.versionDrawerIsOpen : ''}`}>
          {/* Settings panel (Left) */}

          <section className={Styles.leftPanel}>
            <CameraSettings
              robotCapabilities={robotCapabilities}
              routineParentId={routineParentId}
              recipe={recipe}
              routineSettings={routineSettings}
              routine={routine}
              setRoutineSettings={settings => {
                setSettings({ routine: settings })
              }}
              resetRoutineSettings={() => setRoutineSettings(undefined)}
              rules={settingsRules}
              controlsDisabled={cameraSettingsDisabled}
              onApplySettings={handleApplySettingsClick}
              formIsDirty={!applySettingsDisabled || !!capturedFrame}
              applyButtonDisabled={cameraSettingsDisabled || applySettingsDisabled}
              versionDrawerOpen={versionDrawerOpen}
            />
          </section>

          {/* Center Panel Start */}

          <div className={`${Styles.videoContainer} ${versionDrawerOpen ? Styles.versionDrawerIsOpen : ''}`}>
            {/* Header start */}
            <div className={Styles.cameraScreenHeader}>
              <div className={Styles.cameraTitleLayout}>
                <RobotDisplayName robotName={renderCameraTitle()} className={Styles.robotTitle} />
              </div>

              {robot && isRobotLoadingRunningOrOffline && <Status status={robotStatus} />}
              {!isRobotLoadingRunningOrOffline && robot && (
                <div className={Styles.camButtonsContainer}>
                  {!cropMode && (
                    <>
                      <OptionMenu
                        data-testid="rename-camera-option"
                        options={[{ value: 'rename', title: 'Rename camera', disabled: versionDrawerOpen }]}
                        openWithClick
                        closeOnClick
                        onMenuItemClick={handleRobotOptionMenuClick}
                        menuItemClassName={Styles.viewMenuItem}
                      >
                        <IconButton
                          icon={<PrismOverflowIcon />}
                          type="tertiary"
                          className={Styles.iconButton}
                          disabled={versionDrawerOpen}
                        />
                      </OptionMenu>
                      <PrismTooltipWithPopover
                        hoverTitle={
                          stationStatus === 'running' ? 'Inspection is running on the station' : 'Feed Quality'
                        }
                        popoverVisible={isFeedQualityPopoverVisible}
                        handlePopoverVisibleChange={setIsFeedQualityPopoverVisible}
                        popoverContent={
                          <div className={Styles.popoverContainer}>
                            <div className={Styles.popoverUpperContainer}>
                              <h3 className={Styles.popoverTitle}>Feed Quality</h3>

                              <Radio.Group
                                value={feedQualityValue}
                                className={Styles.popoverRadioGroup}
                                onChange={e => {
                                  setFeedQualityValue(e.target.value)
                                  setIsFeedQualityPopoverVisible(false)
                                }}
                              >
                                <Radio
                                  value="standard"
                                  className={Styles.popoverRadio}
                                  children={<span>Standard</span>}
                                />
                                <Radio value="high" className={Styles.popoverRadio} children={<span>High</span>} />
                                <Radio
                                  value="highest"
                                  className={Styles.popoverRadio}
                                  children={<span>Highest</span>}
                                />
                              </Radio.Group>
                            </div>

                            <div className={Styles.popoverCaption}>
                              This is for setup purposes. Inspections always run at the highest quality.
                            </div>
                          </div>
                        }
                        popoverClassName={Styles.popoverWrapper}
                      >
                        <IconButton
                          icon={<PrismGearIcon />}
                          className={`${Styles.iconButton}`}
                          type="tertiary"
                          disabled={feedQualityControlDisabled}
                        />
                      </PrismTooltipWithPopover>
                      {!capturedFrame && (
                        <>
                          <PrismTooltip title="Compare to reference" placement="bottom">
                            <IconButton
                              className={`${Styles.iconButton} ${compareReference ? Styles.activeButton : ''}`}
                              icon={<PrismOverlayIcon />}
                              type="tertiary"
                              disabled={cameraHeaderOptionsButtonDisabled || !routine?.image}
                              onClick={handleOverlayClick}
                            />
                          </PrismTooltip>
                          {/* If we're not in crop mode, show a button to enter crop mode, which sets sensor_aoi to full size so user can see entire feed regardless of what current crop value is */}
                          {/* Crop Buttons start */}
                          <PrismTooltip title={cropTitle} placement="bottom" trigger={'hover'}>
                            <>
                              <span data-testid={routineHasTools ? 'capture-crop-tooltip' : ''} />

                              <IconButton
                                data-testid="capture-crop-button"
                                className={`${Styles.iconButton} ${cropMode ? Styles.activeButton : ''}`}
                                icon={<PrismCropIcon />}
                                type="tertiary"
                                disabled={cropButtonDisabled}
                                onClick={protectedOnChange(
                                  async () => {
                                    if (zoom) setZoom(undefined)
                                    setCompareReference(undefined)
                                    if (!fullSizeSensorAoi || !minFullSizeIntervalMs || !routineSettings) return
                                    // When reverting to full size feed, find minimum exposure_ms that gets us below bandwidth limit, and ceil this to nearest integer multiple of current exposure_ms so that user still sees one out of every N items in frames that come through
                                    const applyCropSettingsSuccess = await applySettings({
                                      ...routineSettings,
                                      sensor_aoi: fullSizeSensorAoi,
                                      interval_ms:
                                        Math.ceil(minFullSizeIntervalMs / routineSettings.interval_ms) *
                                        routineSettings.interval_ms,
                                    })
                                    if (applyCropSettingsSuccess) setCropMode(true)
                                  },
                                  { routine, history, recipe },
                                )}
                              />
                            </>
                          </PrismTooltip>
                          {/* Crop Buttons finish */}
                        </>
                      )}

                      <ZoomButtons zoom={zoom} setZoom={setZoom} disabled={cameraHeaderOptionsButtonDisabled} />
                    </>
                  )}

                  {/* Bandwidth bars and percentage for when on crop mode */}
                  {cropMode && (
                    <div className={Styles.bandwidthContainer}>
                      {currentBandWidthPercent && (
                        <span className={Styles.progressText}>{`${currentBandWidthPercent.toFixed(
                          2,
                        )}% of bandwidth used`}</span>
                      )}
                      {bandWidthBars && <span className={Styles.progressBarContainer}>{bandWidthBars}</span>}
                    </div>
                  )}

                  {!cropMode && (
                    <PrismTooltip title="Full Screen" placement="bottom">
                      <IconButton
                        data-testid="capture-full-screen"
                        className={`${Styles.iconButton} ${cropMode ? Styles.activeButton : ''}`}
                        icon={<PrismExpandIcon />}
                        type="tertiary"
                        disabled={cameraHeaderOptionsButtonDisabled}
                        onClick={() => {
                          if (zoom) setZoom(undefined)
                          setCompareReference(undefined)
                          setFullscreen(true)
                        }}
                      />
                    </PrismTooltip>
                  )}
                </div>
              )}
            </div>
            {/* Header finish */}

            {/* Main content start */}
            {(robotStatus !== 'disconnected' || !robotId) && !versionDrawerOpen && (
              <div className={Styles.cameraBody} ref={videoContainer}>
                {!robotId && (
                  <GenericBlankStateMessage
                    header={<PrismWarningIcon className={Styles.warningIcon} isActive />}
                    description="Link this view to a camera in order to enable it."
                    title="View is unlinked"
                  />
                )}

                {capturedFrame && (
                  <ConditionalWrapper
                    condition={fullscreen}
                    wrapper={children => (
                      <FullScreen
                        id="captured-frame-fullscreen"
                        onClose={() => setFullscreen(false)}
                        className={Styles.expandedView}
                      >
                        <FullScreenHeader onCloseClick={() => setFullscreen(false)} />
                        <div className={Styles.expandedContainer}>{children}</div>
                      </FullScreen>
                    )}
                  >
                    <ZoomableImage
                      data-testid="capture-reference-image"
                      containerClassName={`${Shared.containedImage} ${Styles.frameScreenColor}`}
                      src={capturedFrameUrlRef.current}
                      enableZoom={!!zoom}
                      scalingFactor={zoom}
                      alt=""
                    />
                  </ConditionalWrapper>
                )}

                {robot && routineSettings && !capturedFrame && (
                  <CaptureLiveFeed
                    data-test-attribute={routine.settings ? 'capture-routine-camera-ready' : ''}
                    key={robotId}
                    robotId={robot.id}
                    relativeUrl={
                      stationStatus === 'running'
                        ? wsPaths.videoBaslerMedium(robot.id) // Edge only publishes to medium quality stream when running an inspection
                        : videoStreamUrls[feedQualityValue](robot.id)
                    }
                    routineSettings={routineSettings}
                    setIsLiveFeedDisconnected={setIsLiveFeedDisconnected}
                    cropMode={cropMode}
                    fullscreenVideo={fullscreen}
                    setFullscreenVideo={setFullscreen}
                    zoom={zoom}
                    hardwareTrigger={routine?.settings?.camera_trigger_mode === 'hardware'}
                    referenceImage={routine?.image}
                    splitFeed={compareReference === 'side-by-side'}
                    showOverlay={compareReference === 'overlay'}
                  />
                )}

                {/* Crop start */}
                {cropMode &&
                  fullSizeSensorAoi &&
                  scaledDown &&
                  cropX !== undefined &&
                  cropY !== undefined &&
                  cropWidth !== undefined &&
                  cropHeight !== undefined &&
                  sensorAoi && (
                    <div
                      style={{
                        position: 'absolute',
                        top: scaledDown.y,
                        left: scaledDown.x,
                        width: scaledDown.width,
                        height: scaledDown.height,
                      }}
                    >
                      <div
                        className={Styles.cover}
                        style={{
                          // Clip polygon creates an opaque mask with a window that creates a better interface for cropping
                          // For info about setup checkout: https://stackoverflow.com/questions/58283119/create-a-transparent-window-in-a-div-background-with-css-and-javascript
                          clipPath: `polygon(
                      0% 0%,
                      0% 100%,
                      ${cropX}px 100%,
                      ${cropX}px ${cropY}px,
                      ${cropX + cropWidth * scaledDown.width}px ${cropY}px,
                      ${cropX + cropWidth * scaledDown.width}px ${cropY + cropHeight * scaledDown.height}px,
                      ${cropX}px ${cropY + cropHeight * scaledDown.height}px,
                      ${cropX}px 100%,
                      100% 100%,
                      100% 0%)
                    `,
                        }}
                      />
                      <Rnd
                        className={Styles.cropAreaGuides}
                        default={{
                          x: cropX,
                          y: cropY,
                          width: `${cropWidth * 100}%`,
                          height: `${cropHeight * 100}%`,
                        }}
                        resizeHandleClasses={{
                          bottom: `${Styles.rndBorder} ${Styles.bottom}`,
                          top: `${Styles.rndBorder} ${Styles.top}`,
                          right: `${Styles.rndBorder} ${Styles.right}`,
                          left: `${Styles.rndBorder} ${Styles.left}`,
                          bottomRight: `${Styles.rndCorner} ${Styles.bottomRight}`,
                          topRight: `${Styles.rndCorner} ${Styles.topRight}`,
                          bottomLeft: `${Styles.rndCorner} ${Styles.bottomLeft}`,
                          topLeft: `${Styles.rndCorner} ${Styles.topLeft}`,
                        }}
                        bounds="parent"
                        onResize={(_, __, refToElement, ___, position) => {
                          if (!routineSettings || !imgDimensions) return
                          const newWidthPct = parseFloat(refToElement.style.width.slice(undefined, -1))
                          const newHeightPct = parseFloat(refToElement.style.height.slice(undefined, -1))

                          const sensor_aoi = resizeToPixelValues(
                            { width: newWidthPct, height: newHeightPct, x: position.x, y: position.y },
                            fullSizeSensorAoi,
                            imgDimensions,
                            scaledDown,
                            routineSettings.image_rotation_degrees,
                          )
                          // Set state with cropped feed as you crop it, but don't set settings until user confirms
                          setRoutineSettings({ ...routineSettings, sensor_aoi })
                        }}
                        onDrag={(_, dragData) => {
                          if (!routineSettings || !imgDimensions) return
                          const sensor_aoi = resizeToPixelValues(
                            { ...sensorAoi, x: dragData.x, y: dragData.y },
                            fullSizeSensorAoi,
                            imgDimensions,
                            scaledDown,
                            routineSettings.image_rotation_degrees,
                            true,
                          )

                          setRoutineSettings({ ...routineSettings, sensor_aoi })
                        }}
                      />
                    </div>
                  )}
                {/* Crop finish */}
              </div>
            )}
            {robotId && robotStatus === 'disconnected' && !versionDrawerOpen && (
              <GenericBlankStateMessage
                header={<PrismElementaryCube />}
                title="No camera detected"
                description="This may be due to connectivity issues or a lack of power on the device."
                className={Styles.cameraBody}
              />
            )}
            {versionDrawerOpen && (
              <div className={`${Styles.cameraBody} ${Styles.versionHistoryWrapper}`}>
                <PrismElementaryCube />
              </div>
            )}

            {/* Main content finish */}
            <LiveFeedButtons
              robotId={robotId}
              onClickStopRobotBatch={
                !!currentInspectionId
                  ? () => stopRobotBatch({ inspectionId: currentInspectionId, dispatch })
                  : undefined
              }
              capturedFrame={capturedFrame}
              handleClickTakeOrSavePhoto={handleClickTakeOrSavePhoto}
              cropMode={cropMode}
              onClickDeleteView={() => setShowDeleteModal(true)}
              onClickLinkView={() => setShowLinkViewModal(true)}
              onClickDiscardFrame={() => {
                setCapturedFrame(undefined)
                if (capturedFrameUrlRef.current) URL.revokeObjectURL(capturedFrameUrlRef.current)
              }}
              cropButtonDisabled={versionDrawerOpen || (!!currentBandWidthPercent && currentBandWidthPercent > 100)}
              onClickApplyCrop={async () => {
                if (!routineSettings) return

                if (!routineHasImage()) {
                  const success = await applyAndSaveSettings(routineSettings)
                  if (success) setCropMode(false)
                  return success
                }

                return setConfigureWarningModal({
                  onOk: async () => {
                    const applySettingsSuccess = await applyAndSaveSettings(routineSettings)
                    if (applySettingsSuccess) setCropMode(false)
                    return applySettingsSuccess
                  },
                  onCancel: () => {
                    if (!routine.settings) return
                    setCropMode(false)
                    setRoutineSettings(undefined)
                    applySettings(routine.settings)
                  },
                })
              }}
              onCancelCrop={() => {
                if (!routine.settings) return
                setCropMode(false)
                setRoutineSettings(undefined)
                applySettings(routine.settings)
              }}
              takePhotoDisabled={livePhotoButtonDisabled}
              takePhotoPopoverVisible={!applySettingsDisabled && !confirmRemoveReferencePhotoIsOpen}
              takeOrSavePhotoBadge={getPrimaryButtonBadge()}
              takeOrSavePhotoText={getPrimaryButtonText()}
            />
          </div>
          {/* Center Panel Finish */}

          {/* Instructions and reference panel (right) start */}
          <div className={Styles.rightPanel}>
            <div className={Styles.layoutContainerHeight}>
              <div className={Styles.referenceTitleContainer}>
                <div className={Styles.referenceTitle}>Reference Image</div>
                <IconButton
                  disabled={versionDrawerOpen}
                  className={Styles.iconButton}
                  icon={<PrismUploadIcon />}
                  type="tertiary"
                  onClick={protectedOnChange(() => setUploadModalOpen(true), { routine, history, recipe })}
                />
              </div>
              <div className={Styles.referenceBodyContainer}>
                <div className={Styles.rightPanelUpper}>
                  <>
                    {routineHasImage() && (
                      <div
                        className={Styles.referenceContainer}
                        style={{ display: 'block' }}
                        data-testid="capture-reference-image-container"
                      >
                        <ImageWithBoxes
                          ref={referenceImageContainer}
                          imageClassName={Styles.imageWithBoxes}
                          src={routine?.image}
                          boundingBoxes={routine?.aois.map<AoiOutlineConfiguration>(aoi => ({
                            ...aoi,
                            x: aoi.x,
                            y: aoi.y,
                            width: aoi.width,
                            height: aoi.height,
                            outcomeOrStatus: 'noResult',
                          }))}
                          style={{ display: 'block' }}
                        />
                      </div>
                    )}
                  </>

                  {!routineHasImage() && (
                    <GenericBlankStateMessage
                      header={<PrismElementaryCube className={Styles.noReferenceImageContainer} />}
                      description={
                        <>
                          Tap <b>Take Photo</b> button to capture your reference
                        </>
                      }
                    />
                  )}
                </div>

                <div className={Styles.rightPanelLower}>
                  <>
                    <div className={Styles.instructionsTitle}>Instructions</div>
                    <div className={Styles.instructionsPanel}>
                      <ol className={Styles.instructionsList}>
                        <li>Select device</li>
                        <li>Move camera until part is within frame</li>
                        <li>Select trigger and crop feed if bandwidth limit is reached</li>
                        <li>Adjust camera and lighting settings until part is clearly visible and in focus</li>
                        <li>Take photo to validate settings</li>
                        <li>If photo is acceptable, save as reference</li>
                        <li>Configure inspection with appropriate tools</li>
                        <li>Deploy and run your recipe</li>
                      </ol>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Instructions and reference panel (right) finish */}
        {renameCameraModalOpen && (
          <RenameRobotModal
            onClose={() => {
              setRenameCameraModalOpen(false)
            }}
            robot={robot}
            visible
            onRenameRobot={handleRenameRobot}
          />
        )}

        {uploadModalOpen && (
          <UploadModal
            title="Upload Reference Image"
            onClose={() => setUploadModalOpen(false)}
            onUpload={handleImageUpload}
          />
        )}
        {showLinkViewModal && (
          <LinkViewModal
            onClose={() => setShowLinkViewModal(false)}
            onOk={() => setShowLinkViewModal(false)}
            recipe={recipe}
            routine={routine}
          />
        )}
        {showDeleteModal && (
          <DeleteViewModal onClose={() => setShowDeleteModal(false)} routine={routine} recipe={recipe} mode="capture" />
        )}
        {!!configureWarningModal && (
          <Modal
            id="update-camera-settings"
            data-testid="utils-update-camera-settings"
            size="small"
            header="Update Camera Settings"
            onClose={() => {
              configureWarningModal.onCancel?.()
              setConfigureWarningModal(undefined)
            }}
            onOk={async () => {
              const res = await service.patchRoutine(routine.id, { image: '' })
              if (res.type === 'success') {
                refreshRoutineAndRecipe({ routineId: routine.id, recipeId: recipe.id, dispatch })
                await configureWarningModal.onOk()
                setConfigureWarningModal(undefined)
              }
            }}
            okText="Update"
          >
            {'You will need to retake your reference image and may need to reconfigure your tools.'}
          </Modal>
        )}
        {!!referenceWarningModal && (
          <Modal
            id="update-reference-photo"
            data-testid="routine-overview-update-reference-photo"
            size="small"
            header="Update Reference Photo?"
            onClose={() => {
              setReferenceWarningModal(undefined)
            }}
            onOk={async () => {
              await referenceWarningModal.onOk()
              setReferenceWarningModal(undefined)
            }}
            okText="Update"
          >
            {'You may need to adjust or retrain your tools to be compatible with the new photo.'}
          </Modal>
        )}
      </div>
    </>
  )
}

export default Capture

/**
 * Renders Video component for capture screen. Handles every frame on state and sends it down to any components
 * that require refresh on every frame. Currently, the only component that requires refresh is the zoom functionality.
 *
 * @param robotId - Currently selected robot ID
 * @param routineSettings - Current routine settings. Needed to format the video feed
 * @param setButtonsDisabled - Handler for disabling buttons if the video feed crashes
 * @param cropMode - Whether crop mode is currently active
 * @param fullscreenVideo - Whether fullscreen mode is activated
 * @param setFullscreenVideo - Handler for initiating fullscreen video mode.
 * @param hardwareTrigger - Whether camera is in hardware trigger mode.
 */
function CaptureLiveFeed({
  robotId,
  relativeUrl,
  routineSettings,
  setIsLiveFeedDisconnected,
  cropMode,
  fullscreenVideo,
  setFullscreenVideo,
  zoom,
  hardwareTrigger,
  referenceImage,
  splitFeed,
  showOverlay,
  'data-test-attribute': dataTestAttribute,
  'data-testid': dataTestId,
}: {
  robotId: string
  relativeUrl: string
  routineSettings: RoutineSettings
  cropMode: boolean
  setIsLiveFeedDisconnected: (liveFeedDisconnected: boolean) => any
  fullscreenVideo: boolean
  setFullscreenVideo: (show: boolean) => any
  zoom?: 0.5 | 0.33 | 0.25
  hardwareTrigger: boolean
  splitFeed?: boolean
  referenceImage?: string
  showOverlay?: boolean
  'data-test-attribute'?: string
  'data-testid'?: string
}) {
  const [splitFeedZoom, setSplitFeedZoom] = useState<Box>()
  const feedDimensionsRef = useRef<{ width: number; height: number }>()
  const referenceDimensionsRef = useRef<{ width: number; height: number }>()

  const handleVideoImgLoad: React.ReactEventHandler<HTMLImageElement> = e => {
    const img = e.currentTarget

    feedDimensionsRef.current = { width: img.naturalWidth, height: img.naturalHeight }
  }

  const handleReferenceImgLoad: React.ReactEventHandler<HTMLImageElement> = e => {
    const img = e.currentTarget

    referenceDimensionsRef.current = { width: img.naturalWidth, height: img.naturalHeight }
  }

  const splitFeedZoomScaling =
    (referenceDimensionsRef.current &&
      feedDimensionsRef.current &&
      referenceDimensionsRef.current.width / feedDimensionsRef.current.width) ||
    1

  const videoElement = (
    <VideoWithLastFrameTime
      data-testid="capture-live-feed"
      data-test-attribute={dataTestAttribute}
      robotId={robotId}
      relativeUrl={relativeUrl}
      videoClassName={`${Styles.frameVideoContainer} ${splitFeed ? Styles.splitFeed : Styles.wholeFeed} `}
      videoStyle={{ display: 'block' }}
      waitingForFrameTimeoutMs={(routineSettings.interval_ms || 0) * 2}
      onChangeState={state => {
        if (state === 'notConnected' && !hardwareTrigger) {
          // If we're using hardware trigger, camera only publishes frame on trigger, which might not happen frequently, so we can't disable buttons if we don't get frames
          setIsLiveFeedDisconnected(true)
        }
        if (state === 'connected') setIsLiveFeedDisconnected(false)
      }}
      showTimestamp={!cropMode}
      intervalMs={routineSettings.interval_ms}
      zoom={zoom}
      overlaySrc={showOverlay ? referenceImage : undefined}
      overlayOpacity={0.5}
      onRegionChange={splitFeed ? setSplitFeedZoom : undefined}
      forceRegion={splitFeed ? splitFeedZoom : undefined}
      onLoad={handleVideoImgLoad}
      onOverlayLoad={handleReferenceImgLoad}
      overlayScaleToOriginal
      fallbackImage={<PrismVideoLoadError className={Styles.videoLoadError} />}
    />
  )

  return (
    <div className={Styles.liveFeedContainer} data-testid={dataTestId}>
      <ConditionalWrapper
        condition={fullscreenVideo}
        wrapper={children => (
          <FullScreen id="fullscreen-video" onClose={() => setFullscreenVideo(false)} className={Styles.expandedView}>
            <FullScreenHeader onCloseClick={() => setFullscreenVideo(false)} />
            <div className={Styles.expandedContainer}>{children}</div>
          </FullScreen>
        )}
      >
        {videoElement}
      </ConditionalWrapper>

      {splitFeed && (
        <ZoomableImage
          containerClassName={Styles.splitFeed}
          src={referenceImage}
          scalingFactor={zoom}
          enableZoom={!!zoom}
          onRegionChange={box =>
            box ? setSplitFeedZoom(scaleBox(box, 1 / splitFeedZoomScaling)) : setSplitFeedZoom(undefined)
          }
          forceRegion={splitFeedZoom ? scaleBox(splitFeedZoom, splitFeedZoomScaling) : undefined}
        />
      )}

      {splitFeed && (
        <div className={Styles.splitFeedGrid}>
          <div className={Styles.splitFeed}>
            <span className={Styles.splitFeedLabel}>Live</span>
          </div>
          <div className={Styles.splitFeed}>
            <span className={Styles.splitFeedLabel}>Reference</span>
          </div>
          {Array(5)
            .fill(undefined)
            .map((_, i) => (
              <div
                key={i}
                style={{
                  top: (16.666 * i).toString() + '%',
                }}
                className={Styles.splitFeedHorizontalRuler}
              ></div>
            ))}
          {Array(5)
            .fill(undefined)
            .map((_, i) => (
              <div
                key={i}
                className={`${Styles.splitFeedGridVerticalRuler} ${i === 2 ? Styles.splitFeedGridDivider : ''}`}
                style={{
                  left: (i * 16.66).toString() + '%',
                }}
              ></div>
            ))}
        </div>
      )}
    </div>
  )
}

function ZoomButtons({
  zoom,
  setZoom,
  disabled,
}: {
  zoom: 0.5 | 0.33 | 0.25 | undefined
  setZoom: (newState: 0.5 | 0.33 | 0.25 | undefined) => any
  disabled: boolean
}) {
  return (
    <>
      {zoom && (
        <PrismSelect
          value={zoom}
          size="small"
          onChange={val => setZoom(val)}
          className={Styles.zoomDropdownContainer}
          options={[
            { value: 0.5, title: '200%' },
            { value: 0.33, title: '300%' },
            { value: 0.25, title: '400%' },
          ]}
        />
      )}
      <PrismTooltip title="Zoom In" placement="bottom">
        <IconButton
          className={`${Styles.iconButton} ${zoom ? Styles.activeButton : ''}`}
          icon={<PrismZoomInIcon />}
          type="tertiary"
          disabled={disabled}
          onClick={() => {
            if (zoom) return setZoom(undefined)
            setZoom(0.5)
          }}
        />
      </PrismTooltip>
    </>
  )
}

/**
 * Rules depend on routine settings and camera model. There will be differnt
 * rules for different camera models, and for different routine settings (e.g.
 * depending on camera_trigger_mode).
 */
const getCameraRules = (capabilities: Capabilities, routineSettings: RoutineSettings): SettingsRules => {
  return {
    exposure_ms: {
      max:
        routineSettings.camera_trigger_mode !== 'manual'
          ? { value: routineSettings.interval_ms, message: 'Your exposure cannot exceed your interval' }
          : undefined,
      min: {
        value: 0.001,
      },
    },
    interval_ms: {
      min: {
        value: capabilities.cam_max_fps > 0 ? 1000 / capabilities.cam_max_fps : 1,
        message: 'Interval value is below minimum',
      },
    },
    trigger_delay_ms: {
      min: { value: 1 },
    },
  }
}

/**
 * The draggable and resizable box displayed over the image requires values
 * relative to the viewport and HTML element, this function takes care of
 * resizing said box using the calculated ratio to match the exact position
 * in the sensor dimensions.
 *
 * @param x - x coordinate of the current box drawn, relative to the screen
 * @param y - y coordinate of the current box drawn, relative to the screen
 * @param width - width size of the current box drawn, relative to the screen
 * @param height - height size of the current box drawn, relative to the screen
 * @param sensor - Real pixel value size of the camera sensor of the current robot
 * @param imgDim - Dimensions on the image displayed on the container
 * @param scaledWidth - Scaled width size of the container,relative to the sensor
 * @param scaledHeight - Scaled height size of the container,relative to the sensor
 * @param degrees - The angle of the rotation of the box, in degrees
 * @param onlyPosition - Whether the function should calculate only the position
 *     (x, y) values or all 4 values (x, y, width, height)
 *
 * @returns A constrained box of the drawing, relative to the sensor and never
 *  exceeding its values
 */
function resizeToPixelValues(
  { x, y, width, height }: Box,
  sensor: Box,
  imgDim: Box,
  { width: scaledWidth, height: scaledHeight }: Box,
  degrees: number,
  onlyPosition?: boolean,
): Box {
  if (RIGHT_ANGLES.includes(degrees) && onlyPosition) {
    ;[height, width] = [width, height]
  }
  let newWidth = onlyPosition ? width : (imgDim.width / 100) * width
  let newHeight = onlyPosition ? height : (imgDim.height / 100) * height

  let newX = (x * imgDim.width) / scaledWidth
  let newY = (y * imgDim.height) / scaledHeight

  if (degrees !== 0) {
    // We have a `Box` with the coordinates of the rotated image, so we need to get the coordinates of the non-rotated
    // image (hence the `-degrees`)
    ;({
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight,
    } = rotate(imgDim, sensor, { x: newX, y: newY, width: newWidth, height: newHeight }, -degrees))
  }
  const constrainedBox = constrainBox(
    { x: snap(newX), y: snap(newY), width: snap(newWidth), height: snap(newHeight) },
    imgDim,
  )
  return constrainedBox
}

/**
 * If you change a setting that affects the image you get from the camera, you
 * DEFINITELY need to update your reference image (Routine.image), otherwise your
 * reference image (on top of which you draw AOIs) won't match the images taken
 * by the camera when you run an inspection.
 *
 * Because AOIs are drawn on top of the reference image, you likely need to
 * update the AOIs as well. Currently, we force the user to retake the reference
 * and reconfigure all AOIs and tools if they change a setting that
 * affects the images captured by the camera.
 *
 * Examples of these settings are focus, brightness, exposure, etc.
 */
function reconfigureRoutineRequired(settings: RoutineSettings, newSettings: RoutineSettings) {
  for (const setting of Object.keys(settings)) {
    const settingName = setting as keyof RoutineSettings

    // Trigger settings don't affect reference
    if (triggerSettings.includes(settingName)) continue

    // If any other setting has changed show the modal
    if (!isEqual(settings[settingName], newSettings[settingName])) return true
  }
  return false
}

/**
 * Calculates the size of the cropBox when the user toggles the "Crop Feed" button.
 */
function getCropBox(
  sensorAoi: Box | undefined,
  fullSizeSensorAoi: Box | undefined,
  scaledDown: Box | undefined,
  imgDimensions: Box | undefined,
  degrees: number,
) {
  if (!sensorAoi || !fullSizeSensorAoi || !scaledDown || !imgDimensions)
    return { x: undefined, y: undefined, width: undefined, height: undefined }
  const { x, y, width, height } = rotate(fullSizeSensorAoi, imgDimensions, sensorAoi, degrees)
  const newX = (x * scaledDown.width) / imgDimensions.width
  const newY = (y * scaledDown.height) / imgDimensions.height
  const newWidth = width / imgDimensions.width
  const newHeight = height / imgDimensions.height

  return { x: newX, y: newY, width: newWidth, height: newHeight }
}

/**
 * Calculates box coordinates, when rotated by a given angle based on the center of an image
 */
function rotate(
  { width: originalImageWidth, height: originalImageHeight }: Box,
  { width: rotatedImageWidth, height: rotatedImageHeight }: Box,
  boxToRotate: Box, // Do NOT to overwrite anything here, since this would change the routine settings directly
  angle: number,
) {
  // https://stackoverflow.com/questions/2259476/rotating-a-point-about-another-point-2d
  const rad = getRad(angle)
  const centerX = originalImageWidth / 2
  const centerY = originalImageHeight / 2

  const boundingBox = [
    // topleft
    [boxToRotate.x, boxToRotate.y],
    // btmleft
    [boxToRotate.x, boxToRotate.y + boxToRotate.height],
    // btmright
    [boxToRotate.x + boxToRotate.width, boxToRotate.y + boxToRotate.height],
    // topright
    [boxToRotate.x + boxToRotate.width, boxToRotate.y],
  ]
  const newBoundingBox = boundingBox.map(([x, y]) => {
    if (x === undefined || y === undefined) return [0, 0]
    const adjustedX = x - centerX
    const adjustedY = y - centerY

    let { x: newX, y: newY } = rotatePoint({ x: adjustedX, y: adjustedY }, rad)
    // We add the center of the rotated image, not te original image
    newX += rotatedImageWidth / 2
    newY += rotatedImageHeight / 2
    return [newX, newY]
  })

  const flatX = newBoundingBox.map(row => row[0] || 0)
  const flatY = newBoundingBox.map(row => row[1] || 0)

  const minX = Math.min(...flatX)
  const maxX = Math.max(...flatX)
  const minY = Math.min(...flatY)
  const maxY = Math.max(...flatY)

  // We need to round both the width and the height, since sometimes the difference
  // between `min` and `max` results in not exact values , which leads to buggy
  // behaviour when `snap` truncates to the nearest px
  return { x: minX, y: minY, width: Math.round(maxX - minX), height: Math.round(maxY - minY) }
}

function snap(px: number, toNearestPx = 2) {
  return Math.trunc(px / toNearestPx) * toNearestPx
}

function constrainBox(box: Box, limits: Box) {
  return {
    x: Math.max(box.x, limits.x),
    y: Math.max(box.y, limits.y),
    width: Math.min(box.width, limits.width),
    height: Math.min(box.height, limits.height),
  }
}

// Removes keys that would point to undefined values, such that the comparison with `isEqual` in this component works between changing triggers
function removeUndefinedKeysFromObject(object: any) {
  Object.keys(object).forEach(key => object[key] === undefined && delete object[key])
}

interface LiveFeedButtonsProps {
  robotId: string | undefined
  onClickStopRobotBatch?: () => any
  capturedFrame: Blob | undefined
  cropMode: boolean
  onClickDiscardFrame: () => any
  onCancelCrop: () => any
  onClickApplyCrop: () => any
  cropButtonDisabled: boolean
  onClickDeleteView: () => any
  onClickLinkView: () => any
  takePhotoDisabled: boolean
  takePhotoPopoverVisible: boolean
  handleClickTakeOrSavePhoto: (args: SyntheticEvent<Element, Event>) => Promise<void>
  takeOrSavePhotoBadge?: JSX.Element
  takeOrSavePhotoText: string
}

/**
 * Displays the button below the live feed which can render different buttons for all different states the camera/routine
 * can be in
 */
function LiveFeedButtons({
  robotId,
  onClickStopRobotBatch,
  capturedFrame,
  cropMode,
  onClickDiscardFrame,
  onCancelCrop,
  onClickApplyCrop,
  cropButtonDisabled,
  onClickDeleteView,
  onClickLinkView,
  takePhotoDisabled,
  takePhotoPopoverVisible,
  handleClickTakeOrSavePhoto,
  takeOrSavePhotoBadge,
  takeOrSavePhotoText,
}: LiveFeedButtonsProps) {
  // Since this component is used in multiple states we declare it outside the scope of the render function
  const takeOrSavePhotoButton = (
    <Popover
      content={<div className={Styles.tooltipDescription}>Apply settings to crop feed or take photo</div>}
      open={takePhotoPopoverVisible}
    >
      <Button
        wide
        disabled={takePhotoDisabled}
        size="medium"
        onClick={handleClickTakeOrSavePhoto}
        data-testid={capturedFrame ? 'save-referece-button' : 'take-photo-button'}
        badge={takeOrSavePhotoBadge}
      >
        {takeOrSavePhotoText}
      </Button>
    </Popover>
  )

  const renderButtons = () => {
    // Camera is unlinked buttons
    if (!robotId)
      return (
        <>
          <Button type="secondary" size="medium" wide onClick={onClickDeleteView} badge={<PrismDiscardIcon />}>
            delete view
          </Button>
          <Button size="medium" wide onClick={onClickLinkView} badge={<PrismCameraViewIcon />}>
            link to camera
          </Button>
        </>
      )
    // Running batch button
    if (!!onClickStopRobotBatch)
      return (
        <Button
          wide
          type="secondary"
          onClick={onClickStopRobotBatch}
          badge={<PrismStopIcon className={Styles.iconColor} />}
          data-testid="station-detail-stop-batch-button"
        >
          Stop Batch
        </Button>
      )
    // Discard or save frame buttons
    if (capturedFrame)
      return (
        <>
          <Button
            data-testid="capture-cancel-button"
            type="secondary"
            size="medium"
            wide
            badge={<PrismCloseIcon />}
            onClick={onClickDiscardFrame}
          >
            Discard
          </Button>
          {takeOrSavePhotoButton}
        </>
      )

    // Crop mode buttons.
    if (cropMode)
      return (
        <>
          <Button type="secondary" size="medium" wide badge={<PrismCloseIcon />} onClick={onCancelCrop}>
            Cancel
          </Button>

          <Button size="medium" wide badge={<PrismPassIcon />} disabled={cropButtonDisabled} onClick={onClickApplyCrop}>
            Apply Crop
          </Button>
        </>
      )

    return takeOrSavePhotoButton
  }

  return (
    <section className={Styles.takePhotoContainer}>
      <div className={Styles.buttonsContainer}>{renderButtons()}</div>
    </section>
  )
}
