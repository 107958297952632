import React, { useEffect, useMemo, useState } from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, service, wsPaths } from 'api'
import { Button } from 'components/Button/Button'
import { Carousel, CarouselItem } from 'components/Carousel/Carousel'
import { Divider } from 'components/Divider/Divider'
import { IconButton } from 'components/IconButton/IconButton'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import {
  PrismArrowIcon,
  PrismElementaryCube,
  PrismFailIcon,
  PrismHelpIcon,
  PrismNavArrowIcon,
  PrismPassIcon,
  PrismPlayIcon,
  PrismStopIcon,
  PrismTakePhotoIcon,
} from 'components/prismIcons'
import { ICON_BY_RESULT } from 'components/prismIcons'
import { dismiss, loading, success, warning } from 'components/PrismMessage/PrismMessage'
import { Modal, modal } from 'components/PrismModal/PrismModal'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Status } from 'components/Status/Status'
import StreamListener from 'components/StreamListener'
import Timer, { getTimeDifference } from 'components/Timer/Timer'
import { Token } from 'components/Token/Token'
import Video from 'components/Video/Video'
import {
  useConnectionStatus,
  useData,
  useDateTimePreferences,
  useInspectionAndRecipeDefinition,
  useIsColocated,
  useSortedRobotsWithStatus,
  useStationStatus,
  useToolsetsByRobotId,
  useTypedSelector,
} from 'hooks'
import DownloadModal from 'pages/StaticInspector/DownloadModal'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import {
  Inspection,
  ItemsByRobotId,
  RecipeExpanded,
  Robot,
  Routine,
  RoutineWithAois,
  Station,
  StreamMessage,
} from 'types'
import { getRobotDisplayName, matchRole } from 'utils'

import Styles from '../StationDetail.module.scss'
import { ImageWithAoiBoxes } from './ImageWithAoiBoxes'
import StackLight from './StackLight/StackLight'

interface StationDetailSideBarProps {
  inspection: Inspection | undefined
  recipe?: RecipeExpanded
  selectedRobotId: string | null
  robots: Robot[]
  routines: RoutineWithAois[] | undefined
  isOverview: boolean
  inspectionStopping: boolean
  inspectionLoading: boolean
  newBatchButtonEnabled: boolean
  stopBatchButtonEnabled: boolean
  robotIsReady: boolean
  isHistoricBatch: boolean
  station: Station | undefined
  inspectionIdByRobotId: { [robotId: string]: string | undefined }
  currentItemByRobotId: ItemsByRobotId | undefined
  historicRoutinesByRobotId?: { [robotId: string]: Routine }
  handleClickManualTrigger: () => any
  isLoadingCurrentItem: boolean
  isManageMode: boolean
}

/**
 * Renders the right sidebar of the Station Details screen.
 */
function StationDetailSideBar({
  inspection,
  recipe,
  selectedRobotId,
  robots,
  routines,
  isOverview,
  newBatchButtonEnabled,
  historicRoutinesByRobotId,
  inspectionStopping,
  inspectionLoading,
  stopBatchButtonEnabled,
  robotIsReady,
  station,
  isHistoricBatch,
  inspectionIdByRobotId,
  currentItemByRobotId,
  handleClickManualTrigger,
  isLoadingCurrentItem,
  isManageMode,
}: StationDetailSideBarProps) {
  const { inspectionDefinition, recipeDefinition } = useInspectionAndRecipeDefinition(robots.map(rbt => rbt.id))
  const dispatch = useDispatch()
  const history = useHistory()
  const me = useData(getterKeys.me())
  const { timeZone } = useDateTimePreferences()

  const [showOfflineModal, setShowOfflineModal] = useState(false)
  const [showRoutinesPopover, setShowRoutinesPopover] = useState(false)
  const [itemsSyncing, setItemsSyncing] = useState(false)

  const robotIds = robots.map(r => r.id)
  // These are fetched and kept up to date by RobotToolsetsFetcher and RobotToolsetsListener in DownloadModal
  const downloadingOrQueuedToolsetsByRobot = useToolsetsByRobotId(
    robotIds,
    toolset => toolset.recipe_status.state === 'DOWNLOADING' || toolset.recipe_status.state === 'QUEUED',
  )

  const connectionStatus = useConnectionStatus()
  const { isColocated } = useIsColocated()
  const stationStatus = useStationStatus(station)
  const sortedRobots = useSortedRobotsWithStatus(robots)

  const cameraRoutineId = inspection?.inspection_routines.find(
    inspectionRoutine => inspectionRoutine.robot_id === selectedRobotId,
  )?.routine_id

  const cameraRoutine = routines?.find(routine => routine.id === cameraRoutineId)
  const firstRoutine = routines?.[0]

  const image = cameraRoutine?.image

  const aois = useMemo(() => cameraRoutine?.aois, [cameraRoutine])

  const manualTrigger = cameraRoutine?.settings?.camera_trigger_mode === 'manual'

  const manualTriggerDisabled = useTypedSelector(state => state.inspector.isManualTriggerDisabled)
  const setManualTriggerDisabled = (manualTriggerDisabled: boolean) => {
    dispatch(Actions.inspectorUpdate({ isManualTriggerDisabled: manualTriggerDisabled }))
  }

  const isInspectingManualPhoto = useTypedSelector(state => state.inspector.isInspectingManualPhoto)
  const setIsInspectingManualPhoto = (isInspecting: boolean) => {
    dispatch(Actions.inspectorUpdate({ isInspectingManualPhoto: isInspecting }))
  }

  const setSelectedRobotId = (robotId: string) => dispatch(Actions.inspectorUpdate({ selectedRobotId: robotId }))

  const handleClickDoneInspecting = () => {
    setIsInspectingManualPhoto(false)
    setManualTriggerDisabled(false)
  }

  const handleClickStopBatch = () => {
    // End inspections for all robots / inspections
    modal.confirm({
      id: 'sidebar-stop-batch-confirmation',
      content: 'Are you sure you want to stop this batch?',
      'data-testid': 'stop-batch-modal',
      danger: true,
      okText: 'Yes, Stop',
      onOk: async close => {
        const stopBatchId = 'stop-batch'

        const stopInspectionPromises = robots.map(async robot => {
          // Ensure task is unloaded before ending inspection
          const res = await service.atomSendCommand('vision-processing', 'stop_inspection', robot.id, {
            command_args: {},
          })

          // Don't await this, just try to stop this inspection, and retry if there's a connection error; this is a rare case of retrying a request with side effects, and is a workaround for not being able to atomically unload and end an inspection
          const inspectionId = inspectionIdByRobotId[robot.id]
          if (res.type === 'success' && inspectionId) {
            service.stopInspection(inspectionId, {
              retry: { retries: 4, delay: 2000 },
            })
          }

          return { res, robotId: robot.id }
        })
        close()

        // Ensure all tasks are unloaded (at which stop button is disabled)
        const stopInspectionResponses = await Promise.all(stopInspectionPromises)
        const anyRobotFailedToStop = stopInspectionResponses.some(
          ({ res, robotId }) => inspectionIdByRobotId[robotId] && (res.type !== 'success' || !res.data.success),
        )
        if (anyRobotFailedToStop) {
          warning({
            id: stopBatchId,
            title: "Couldn't stop the inspection",
          })
        } else {
          success({
            id: stopBatchId,
            title: 'Batch stopped',
            'data-testid': 'new-batch-stopped-success',
          })
        }
      },
    })
  }

  const handleSelectNextRobot = () => {
    const idx = robots.findIndex(rbt => rbt.id === selectedRobotId)
    if (idx === robots.length - 1) return
    const nextRobot = robots[idx + 1]
    if (nextRobot) setSelectedRobotId(nextRobot.id)
  }

  const handleSelectPrevRobot = () => {
    const idx = robots.findIndex(rbt => rbt.id === selectedRobotId)
    if (idx === 0) return
    const prevRobot = robots[idx - 1]
    if (prevRobot) setSelectedRobotId(prevRobot.id)
  }

  const columns: ColumnsType<Robot> = useMemo(() => {
    return [
      {
        title: 'Camera',
        dataIndex: 'camera',
        key: 'name',
        ellipsis: true,
        render: (_, robot) => <PrismOverflowTooltip content={getRobotDisplayName(robot)} />,
      },
      {
        title: 'View',
        dataIndex: 'view',
        key: 'routine',
        ellipsis: true,
        render: (_, robot) => {
          const historicInspectionRoutine = inspection?.inspection_routines.find(
            historicInspectionRoutine => historicInspectionRoutine.robot_id === robot.id,
          )

          // When looking at a live batch, we use the routine received form VP, since it's the most accurate
          // representation of what's actually running. If looking at a historical batch, VP won't have the
          // required data, so we must use data from Django.
          const currentRoutine = isHistoricBatch
            ? routines?.find(routine => routine.id === historicInspectionRoutine?.routine_id)
            : recipeDefinition?.recipe_routines.find(recipeRoutine => recipeRoutine.robot_id === robot.id)?.routine

          return (
            <>
              {currentRoutine?.parent.name ? (
                <PrismOverflowTooltip content={currentRoutine?.parent.name} className={Styles.routineName} />
              ) : (
                '--'
              )}

              {currentRoutine && matchRole(me, 'manager') && (
                <IconButton
                  type="tertiary"
                  size="xsmall"
                  icon={<PrismNavArrowIcon />}
                  disabled={connectionStatus !== 'online'}
                  onClick={() => {
                    if (connectionStatus !== 'online') return
                    if (!currentRoutine) return

                    return (
                      recipe?.parent_id &&
                      history.push(
                        paths.settingsRecipe(recipe.parent_id, 'configure', {
                          routineParentId: currentRoutine.parent.id,
                        }),
                      )
                    )
                  }}
                  className={Styles.routineArrowIcon}
                />
              )}
            </>
          )
        },
      },
    ]
  }, [
    inspection?.inspection_routines,
    isHistoricBatch,
    routines,
    recipeDefinition?.recipe_routines,
    me,
    connectionStatus,
    recipe?.parent_id,
    history,
  ])

  const renderRoutinesAndRobots = () => {
    return (
      <Table
        rowKey="id"
        className={Styles.tableContainer}
        rowClassName={Styles.tableRow}
        pagination={false}
        columns={columns}
        dataSource={station?.robots}
      />
    )
  }

  const permissionToOperateBatch = matchRole(me, 'inspector')

  const isStationLoading = stationStatus === 'loading'

  const renderDurations = () => {
    if (inspectionDefinition?.started_at && !isHistoricBatch) {
      return (
        <span className={Styles.timer}>
          <Timer specialFormat="hh:mm:ss" startTime={inspectionDefinition.started_at} />
        </span>
      )
    }

    if (isHistoricBatch && inspection?.started_at && inspection.ended_at) {
      return (
        <span className={Styles.timer}>
          {getTimeDifference({
            startTime: moment(inspection?.started_at).valueOf(),
            endTime: moment(inspection?.ended_at).valueOf(),
            specialFormat: 'hh:mm:ss',
            timeZone,
          })}
        </span>
      )
    }

    return '--'
  }

  const renderInspectionRoutines = () => {
    const inspectionRoutineCount = isHistoricBatch
      ? inspection?.routines?.length
      : recipeDefinition?.recipe_routines.length

    const currentFirstRoutine = isHistoricBatch ? firstRoutine : recipeDefinition?.recipe_routines[0]?.routine
    return (
      <>
        {!inspectionRoutineCount && '--'}

        {inspectionRoutineCount === 1 && (
          <>
            {recipe?.parent.name ? (
              <PrismOverflowTooltip content={recipe.parent.name} className={Styles.recipeValue} />
            ) : (
              '--'
            )}

            {recipe?.version && <div className={Styles.routineVersion}> v{recipe.version}</div>}

            {matchRole(me, 'manager') && currentFirstRoutine && (
              <IconButton
                icon={<PrismNavArrowIcon />}
                type="tertiary"
                size="xsmall"
                disabled={connectionStatus !== 'online' || isManageMode}
                onClick={() =>
                  connectionStatus === 'online' &&
                  recipe &&
                  history.push(paths.settingsRecipe(recipe.parent.id, 'configure'))
                }
                data-testid="station-detail-sidebar-routine-arrow"
                className={`${Styles.routineArrowIcon} ${isManageMode ? Styles.managingMode : ''}`}
              />
            )}
          </>
        )}

        {inspectionRoutineCount !== undefined && inspectionRoutineCount > 1 && recipe && (
          <PrismTooltip
            placement="bottomRight"
            trigger="click"
            title={renderRoutinesAndRobots()}
            overlayClassName={Styles.tooltipContainer}
            className={Styles.routineNameTooltipAnchor}
            onOpenChange={() => setShowRoutinesPopover(!showRoutinesPopover)}
          >
            <div className={Styles.routineWrapper}>
              <Button
                disabled={isManageMode}
                type="default"
                badge={
                  <PrismArrowIcon
                    direction={showRoutinesPopover ? 'up' : 'down'}
                    className={`${Styles.routineArrowIcon} ${isManageMode ? Styles.managingMode : ''}`}
                    data-testid="station-detail-sidebar-routine-arrow"
                  />
                }
                invertBadgePosition
                className={Styles.routineNameButton}
                childrenClassName={Styles.routineNameButtonText}
              >
                <div className={Styles.routineNameContainer}>
                  <PrismOverflowTooltip className={Styles.routineName} content={recipe.parent.name} />
                  <span className={Styles.routineVersion}>v{recipe.version}</span>
                </div>
              </Button>
            </div>
          </PrismTooltip>
        )}
      </>
    )
  }

  const renderProductOrBatchName = (title: 'product' | 'batch') => {
    if (isHistoricBatch && inspection) {
      if (title === 'product') return inspection.component.name
      return inspection.name
    }
    if (!isHistoricBatch) {
      if (title === 'product' && recipeDefinition) return recipeDefinition.parent.component_name
      if (title === 'batch' && inspectionDefinition) return inspectionDefinition.name
    }
    return '--'
  }

  const runBatchButtonIsLoading =
    (!robotIsReady || inspectionLoading) && !isHistoricBatch && stationStatus !== 'disconnected'
  const runBatchButtonIsDisabled =
    !newBatchButtonEnabled || inspectionStopping || isHistoricBatch || stationStatus === 'disconnected'

  return (
    <div className={`${Styles.controlMenu} ${isManageMode ? Styles.managingMode : ''}`}>
      {connectionStatus === 'online' &&
        robots?.map(robot => {
          return <ItemQueueListener key={robot.id} robotId={robot.id} onSyncingStateChange={setItemsSyncing} />
        })}

      <DownloadModal downloadingOrQueuedToolsetsByRobot={downloadingOrQueuedToolsetsByRobot} robotIds={robotIds} />

      <StackLight
        isManageMode={isManageMode}
        isStationLoading={isStationLoading}
        isHistoricBatch={isHistoricBatch}
        stationStatus={stationStatus}
        connectionStatus={connectionStatus}
        itemsSyncing={itemsSyncing}
      />

      <div className={Styles.rightPanel}>
        <div className={Styles.panelHeader}>
          <div className={Styles.currentBatch}>batch</div>
          <PrismOverflowTooltip
            content={renderProductOrBatchName('batch')}
            className={Styles.productId}
            data-testid="station-detail-sidebar-batch-name"
            allowBreakLines
          />
        </div>

        <div className={Styles.panelDetails}>
          <Token label="product" className={Styles.singleToken}>
            <PrismOverflowTooltip content={renderProductOrBatchName('product')} />
          </Token>

          <Token label="duration" valueClassName={Styles.limitValueSize}>
            {renderDurations()}
          </Token>

          <Token label="recipe" className={`${Styles.singleToken} ${Styles.routineToken}`}>
            <div className={Styles.routineTokenValues}>{renderInspectionRoutines()}</div>
          </Token>

          {isColocated && (
            <Token label="Internet" className={`${Styles.statusToken} ${Styles.singleToken}`}>
              <div className={Styles.connectionContainer}>
                <Status status={connectionStatus !== 'online' ? 'disconnected' : 'running'} showLabel>
                  <span className={Styles.statusLabel}>
                    {connectionStatus === 'online' && 'Online'}

                    {connectionStatus === 'offline' && 'Offline'}

                    {connectionStatus === 'recovering' && 'Reconnecting'}
                  </span>
                </Status>

                {connectionStatus === 'offline' && (
                  <IconButton
                    onClick={() => setShowOfflineModal(true)}
                    icon={<PrismHelpIcon hasBackground className={Styles.helpIcon} />}
                    type="tertiary"
                    size="xsmall"
                  />
                )}
              </div>
            </Token>
          )}
        </div>

        <div
          className={`${Styles.panelCameraControls} ${
            permissionToOperateBatch &&
            inspection &&
            manualTrigger &&
            !isHistoricBatch &&
            Styles.cameraControlsWithButton
          }`}
        >
          {!isOverview && (
            <>
              {sortedRobots && sortedRobots.length > 1 && (
                <div className={Styles.panelCarousel}>
                  {/* Don't unmount this, instead change to display none, so that we don't have to reconnect to WS */}
                  <Carousel
                    onClickNext={handleSelectNextRobot}
                    onClickPrev={handleSelectPrevRobot}
                    className={isInspectingManualPhoto ? Styles.hideCarousel : ''}
                  >
                    {sortedRobots.map(robot => {
                      if (isHistoricBatch && historicRoutinesByRobotId) {
                        return (
                          <RoutineImageCarouselItem
                            key={robot.id}
                            robotId={robot.id}
                            routine={historicRoutinesByRobotId[robot.id]}
                            selectedRobotId={selectedRobotId}
                            setSelectedRobotId={setSelectedRobotId}
                          />
                        )
                      }

                      return (
                        <CarouselItem
                          key={robot.id}
                          onClick={() => setSelectedRobotId(robot.id)}
                          active={robot.id === selectedRobotId}
                        >
                          <Video
                            data-testid={`sd-sidebar-camera-${robot.serial_number}`}
                            key={robot.id}
                            robotId={robot.id}
                            relativeUrl={wsPaths.videoBaslerThumbnail(robot.id)}
                            waitingForFrameTimeoutMs={(cameraRoutine?.settings?.interval_ms || 0) * 2}
                            showSpinner={false}
                            fallbackImage={<PrismElementaryCube addBackground />}
                          />
                        </CarouselItem>
                      )
                    })}
                  </Carousel>

                  {isInspectingManualPhoto && (
                    <Carousel>
                      {sortedRobots.map(robot => {
                        if (!inspectionIdByRobotId[robot.id]) return null
                        // We need to still use this image in case we don't have a robotID stored in the picture entry
                        const fallbackFirstImagePicture = currentItemByRobotId?.[robot.id]?.pictures[0]

                        const currentPicture =
                          currentItemByRobotId?.[robot.id]?.pictures.find(picture => picture.robot_id === robot.id) ||
                          fallbackFirstImagePicture

                        return (
                          <CarouselItem
                            key={robot.id}
                            onClick={() => setSelectedRobotId(robot.id)}
                            active={robot.id === selectedRobotId}
                          >
                            {currentPicture?.image && (
                              <ImageWithBoxes src={currentPicture.image}>
                                <div className={Styles.overlayIcon}>
                                  {ICON_BY_RESULT(currentPicture?.calculated_outcome || 'unknown')}
                                </div>
                              </ImageWithBoxes>
                            )}
                            {!currentPicture?.image && <PrismElementaryCube />}
                          </CarouselItem>
                        )
                      })}
                    </Carousel>
                  )}
                </div>
              )}

              {permissionToOperateBatch && inspection && manualTrigger && !isHistoricBatch && (
                <div className={Styles.manualButton}>
                  {!isInspectingManualPhoto && manualTrigger && (
                    <Button
                      size="medium"
                      type="secondary"
                      onClick={handleClickManualTrigger}
                      disabled={connectionStatus !== 'online' || manualTriggerDisabled}
                      wide
                      loading={inspectionLoading}
                      data-testid="sd-sidebar-take-photo"
                      badge={<PrismTakePhotoIcon className={Styles.takePhotoIcon} />}
                    >
                      Take Photo
                    </Button>
                  )}
                  {isInspectingManualPhoto && manualTrigger && (
                    <Button
                      size="medium"
                      onClick={handleClickDoneInspecting}
                      wide
                      type="secondary"
                      data-testid="sd-sidebar-done"
                    >
                      Done
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
          <div className={Styles.panelCamera}>
            <div className={Styles.boxContainer}>
              {isOverview && (
                <>
                  <div className={Styles.topLines}>
                    <div className={`${Styles.box} ${Styles.leftTop}`}></div>
                    <div className={`${Styles.box} ${Styles.rightTop}`}></div>
                  </div>
                  <div className={Styles.bottomLines}>
                    <div className={`${Styles.box} ${Styles.leftBottom}`}></div>
                    <div className={`${Styles.box} ${Styles.rightBottom}`}></div>
                  </div>
                </>
              )}

              {!isOverview && (
                <>
                  {!isInspectingManualPhoto && selectedRobotId && !isHistoricBatch && (
                    <Video
                      data-testid={`sd-sidebar-image-${selectedRobotId}`}
                      data-test="sd-sidebar-image"
                      data-test-attribute={image ? 'sd-sidebar-camera-ready' : ''}
                      key={selectedRobotId}
                      robotId={selectedRobotId}
                      relativeUrl={wsPaths.videoBaslerMedium(selectedRobotId)}
                      waitingForFrameTimeoutMs={(cameraRoutine?.settings?.interval_ms || 0) * 2}
                    />
                  )}

                  {isHistoricBatch && image && <ImageWithBoxes src={image} />}
                  {isHistoricBatch && !image && <PrismElementaryCube />}

                  {isInspectingManualPhoto && selectedRobotId && (
                    <ImageWithAoiBoxes
                      robotId={selectedRobotId}
                      aois={aois}
                      inspectionId={inspection?.id}
                      isLoading={isLoadingCurrentItem}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`${Styles.panelFooter} ${isManageMode ? Styles.hideButton : ''}`} data-testid="sd-sidebar-footer">
        {permissionToOperateBatch && stopBatchButtonEnabled && !isHistoricBatch && (
          <Button
            wide
            key="batchActionButton"
            type="secondary"
            onClick={handleClickStopBatch}
            loading={inspectionStopping}
            disabled={inspectionLoading}
            badge={<PrismStopIcon className={Styles.iconColor} />}
            data-testid="station-detail-stop-batch-button"
          >
            Stop Batch
          </Button>
        )}

        {(newBatchButtonEnabled || isHistoricBatch) && permissionToOperateBatch && (
          <Button
            wide
            key="batchActionButton"
            disabled={runBatchButtonIsDisabled}
            to={paths.newBatch(station?.id)}
            loading={runBatchButtonIsLoading}
            badge={<PrismPlayIcon className={Styles.iconColor} />}
            data-testid="station-detail-start-batch-button"
          >
            Run Batch
          </Button>
        )}
      </div>

      {showOfflineModal && <ConnectionOfflineModal onShow={setShowOfflineModal} />}
    </div>
  )
}

const MemoizedStationDetailSidebar = React.memo(StationDetailSideBar)
MemoizedStationDetailSidebar.displayName = 'StationDetailSidebar'

export default MemoizedStationDetailSidebar

const ConnectionOfflineModal = ({ onShow }: { onShow: (show: boolean) => void }) => {
  return (
    <Modal
      id="offline-inspections"
      header="Offline Inspections"
      showCancel={false}
      onClose={() => onShow(false)}
      className={Styles.offlineModalWrapper}
      headerClassName={Styles.offlineModalHeader}
      modalBodyClassName={`${Styles.offlineModalBody} ${Shared.verticalChildrenGap16}`}
    >
      <h3 className={Styles.modalBodyTitle}>System Status</h3>
      <table className={Shared.verticalChildrenGap16}>
        <tr className={Styles.modalBodyRow}>
          <td className={Styles.modalBodyText}>Inspection Tools</td>
          <td>
            <PrismPassIcon variant="iconColor" />
          </td>
        </tr>
        <tr className={Styles.modalBodyRow}>
          <td className={Styles.modalBodyText}>PLC Integrations</td>
          <td>
            <PrismPassIcon variant="iconColor" />
          </td>
        </tr>
        <tr className={Styles.modalBodyRow}>
          <td className={Styles.modalBodyText}>Batch Control & Settings</td>
          <td>
            <PrismPassIcon variant="iconColor" />
          </td>
        </tr>
        <tr className={Styles.modalBodyRow}>
          <td className={Styles.modalBodyText}>Analytics & Image Review</td>
          <td>
            <PrismFailIcon variant="iconColor" />
          </td>
        </tr>
      </table>

      <Divider className={Styles.offlineModalDivider} />

      <h3 className={Styles.modalBodyTitle}>Data Backup</h3>
      <p className={Styles.modalBodyText}>
        Your analytics data is safe and ready for upload once your internet connection returns. If you remain offline
        for an extended period, images may be discarded.{' '}
      </p>
    </Modal>
  )
}

const ITEM_QUEUE_LIMIT = 200
const SYNC_MESSAGE_ID = 'items-queue-sync'

// TODO: once camera consolidation goes in, we should use a multi stream here
const ItemQueueListener = ({
  robotId,
  onSyncingStateChange,
}: {
  robotId: string
  onSyncingStateChange: (syncing: boolean) => void
}) => {
  // When this component unmounts and we're no longer listening to these messages, make sure to disappear notification.
  useEffect(() => {
    return () => {
      onSyncingStateChange(false)
      dismiss(SYNC_MESSAGE_ID)
    }
  }, []) // eslint-disable-line

  const handleMessages = (messages: StreamMessage[]) => {
    const lastMessage = messages[messages.length - 1]

    if (!lastMessage) return

    const remaining = lastMessage.payload.remaining

    if (remaining >= ITEM_QUEUE_LIMIT) {
      onSyncingStateChange(true)
      loading({ id: SYNC_MESSAGE_ID, title: 'Uploading Data. Metrics may be delayed', duration: 0 })
    } else {
      onSyncingStateChange(false)
      dismiss(SYNC_MESSAGE_ID)
    }
  }
  return (
    <StreamListener
      mode="message"
      connect={{ robotId, relativeUrl: wsPaths.visionProcessingItemUploadQueue(robotId) }}
      onMessages={handleMessages}
      params={{ last_n: 1 }}
    />
  )
}

export const RoutineImageCarouselItem = ({
  routine,
  robotId,
  selectedRobotId,
  setSelectedRobotId,
}: {
  routine?: Routine
  robotId: string
  selectedRobotId: string | null
  setSelectedRobotId: (robotId: string) => void
}) => {
  const imgUrl = routine?.fallback_images?.image_thumbnail
  return (
    <CarouselItem onClick={() => setSelectedRobotId(robotId)} active={robotId === selectedRobotId}>
      {imgUrl && <ImageWithBoxes src={imgUrl} />}
      {!imgUrl && <PrismElementaryCube />}
    </CarouselItem>
  )
}
