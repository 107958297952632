import React, { useEffect, useState } from 'react'

import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Popover, Radio, Slider, Tag as AntTag } from 'antd'
import moment from 'moment-timezone'
import { useToasterStore } from 'react-hot-toast'

import { GraphBlankState } from 'components/BlankStates/GraphBlankState'
import ToolBlankState from 'components/BlankStates/ToolBlankState'
import { BreadcrumbLabel, BreadcrumbMenu, Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import EntityButton from 'components/EntityButton/EntityButton'
import { IconButton } from 'components/IconButton/IconButton'
import { LabelCard } from 'components/LabelCard/LabelCard'
import { LiveFeed } from 'components/LiveFeed/LiveFeed'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import { Alert, AlertBlankState } from 'components/PrismAlert/PrismAlert'
import { PrismCascader } from 'components/PrismCascader/PrismCascader'
import PrismCheckbox from 'components/PrismCheckbox/PrismCheckbox'
import {
  PrismAddIcon,
  PrismAnalyzeIcon,
  PrismAnomalyToolIcon,
  PrismArrowIcon,
  PrismBarcodeToolIcon,
  PrismCameraViewIcon,
  PrismCloudConnectedIcon,
  PrismCreateIcon,
  PrismCriticalIcon,
  PrismDetailIcon,
  PrismDiscardIcon,
  PrismElementaryCube,
  PrismEllipseIcon,
  PrismErrorIcon,
  PrismExpandIcon,
  PrismEyeHideIcon,
  PrismEyeShowIcon,
  PrismFailIcon,
  PrismGoodIcon,
  PrismHelpIcon,
  PrismInfoIcon,
  PrismInspectIcon,
  PrismLineIcon,
  PrismMenuIcon,
  PrismMinorIcon,
  PrismNavArrowIcon,
  PrismNeutralIcon,
  PrismNotifyIcon,
  PrismPassIcon,
  PrismPolygonIcon,
  PrismProductIcon,
  PrismRandomToolIcon,
  PrismRecipeIcon,
  PrismRectangleIcon,
  PrismSettingsIcon,
  PrismSiteIcon,
  PrismSuccessIcon,
  PrismUnknownIcon,
  PrismUploadIcon,
  PrismWarningIcon,
  PrismZoomInIcon,
} from 'components/prismIcons'
import { PrismStationIcon } from 'components/prismIcons/PrismStationIcon'
import { PrismInput, PrismInputNumber } from 'components/PrismInput/PrismInput'
import PrismLabelButton from 'components/PrismLabelButton/PrismLabelButton'
import { PrismLoader, PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { PrismMenuList } from 'components/PrismMenuList/PrismMenuList'
import { error, info, Message, success, warning } from 'components/PrismMessage/PrismMessage'
import { Modal, ModalBody, ModalFooter, ModalHeader, PrismModal } from 'components/PrismModal/PrismModal'
import {
  error as errorNotification,
  info as infoNotification,
  Notification,
  open as openNotification,
  success as successNotification,
  warning as warningNotification,
} from 'components/PrismNotification/PrismNotification'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import PrismPdf from 'components/PrismPdf/PrismPdfBase'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import SelectNotFound from 'components/PrismSelect/SelectNoResultOption'
import { PrismSlider, PrismSliderRange } from 'components/PrismSlider/PrismSlider'
import { PrismToggleGroup } from 'components/PrismToggleGroup/PrismToggleGroup'
import PrismTooltipWithPopover from 'components/PrismTooltipWithPopover/PrismTooltipWithPopover'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import SearchInput from 'components/SearchInput/SearchInput'
import { Status } from 'components/Status/Status'
import { BetaTag, OfflineTag, Tag } from 'components/Tag/Tag'
import { Timeline, TimelineItem } from 'components/Timeline/Timeline'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { Token } from 'components/Token/Token'
import {
  EmptyStateCollectImages,
  EmptyStateDeployAndCollectImages,
  EmptyStateLabelImagesCollectedBeforeToolCreation,
  EmptyStateNoImagesMatch,
  EmptyStateNoImagesToLabel,
} from 'pages/RoutineOverview/LabelingScreen/LabelingEmptyStates'

import Styles from './UiPlayground.module.scss'

const Wrapper = ({
  children,
  className = '',
  style,
  title,
}: {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  title?: string
}) => {
  return (
    <div className={`${Styles.prismWrapper} ${className}`} style={style}>
      {title ? <h1>{title}</h1> : ''}
      {children}
    </div>
  )
}

// PDF Table

const TypographyLabel = ({ children }: { children?: React.ReactNode }) => (
  <span className={Styles.typographyLabel}>{children}</span>
)
const TypographyHeading = ({ children }: { children?: React.ReactNode }) => (
  <span className={Styles.typographyHeading}>{children}</span>
)

const RightSidebar = ({
  sidebarTitleCaption,
  sidebarTitle,
}: {
  sidebarTitleCaption: string | React.ReactNode
  sidebarTitle: string | React.ReactNode
}) => {
  return (
    <aside className={Styles.rightSidebar}>
      <TypographyLabel>{sidebarTitleCaption}</TypographyLabel>
      <TypographyHeading>{sidebarTitle}</TypographyHeading>
      <Divider />
      <Token label="Start date">12/23/20</Token>
      <Token label="Duration">00:00:00</Token>
      <Token label="Product">Some product name</Token>
      <Token label="Recipe">Will need to add links to this</Token>
      <Token label="Start date">
        <LiveFeed status="fail">Camera: Production Station A12</LiveFeed>
      </Token>
      <Divider />
      IMAGE
    </aside>
  )
}

const AppContent = ({ children }: { children?: React.ReactNode }) => {
  return <div className={Styles.appContent}>{children}</div>
}

const AppHeader = () => {
  return (
    <header className={Styles.appHeader}>
      <div>
        <Breadcrumbs
          crumbs={[
            { label: 'Master page', path: '/root/' },
            { label: 'Sub page 1', path: '/root/page' },
          ]}
        />
      </div>
      <div>
        <Button type="secondary">Hi</Button>
        <Button>Hi</Button>
      </div>
    </header>
  )
}

const AppLayout = () => {
  return (
    <div className={Styles.appLayout}>
      <AppContent>
        <AppHeader />
      </AppContent>
      <RightSidebar sidebarTitleCaption="current batch" sidebarTitle="Product RUN hi hello #123123" />
    </div>
  )
}

export const StationLayout = () => {
  return <AppLayout></AppLayout>
}

const RegularModal = ({ visible, toggle }: { visible: boolean; toggle: (show: boolean) => void }) => (
  <PrismModal id="playground-regular-modal" onClose={() => toggle(!visible)}>
    <ModalHeader onClose={() => toggle(!visible)}>Regular title</ModalHeader>
    <ModalBody>Content</ModalBody>
    <ModalFooter onOk={() => undefined} onCancel={() => undefined}>
      <Button size="small">Tertiary button</Button>
    </ModalFooter>
  </PrismModal>
)

const DefaultFooterModal = ({
  visible,
  toggle,
  children,
}: {
  visible: boolean
  toggle: (show: boolean) => void
  children: React.ReactNode
}) => (
  <PrismModal id="playground-default-footer-modal" onClose={() => toggle(!visible)}>
    <ModalHeader>Regular title</ModalHeader>
    {children}
    <ModalFooter
      onOk={() => undefined}
      okText="Positive action text"
      onCancel={() => toggle(!visible)}
      cancelText="Cencel action text"
    />
  </PrismModal>
)

const detectToastChangeToastId = 'detect-change'

const UiPlayground = () => {
  const [showModal, setShowModal] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [showDefaultFooterModal, setShowDefaultFooterModal] = useState(false)
  const [showDefaultModal, setShowDefaultModal] = useState(false)
  const [showSmallModal, setShowSmallModal] = useState(false)
  const [showMediumModal, setShowMediumModal] = useState(false)
  const [showLargeModal, setShowLargeModal] = useState(false)
  const [showExtraLargeModal, setShowExtraLargeModal] = useState(false)
  const [showLargeNarrowModal, setShowLargeNarrowModal] = useState(false)
  const [showLargeSimpleFormModal, setShowLargeSimpleFormModal] = useState(false)
  const [showWideModal, setShowWideModal] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const [iconButtonIsLoading, setIconButtonIsLoading] = useState(false)
  const [sliderMarks, setSliderMarks] = useState(0)
  const [showTooltipPopover, setShowTooltipPopover] = useState(false)

  const { toasts } = useToasterStore()

  // example on how to detect if a toast is no longer active
  useEffect(() => {
    const myToast = toasts.find(toast => toast.id === detectToastChangeToastId)
    if (myToast?.id && !myToast?.visible) {
      alert('toast is hidden now')
    }
  }, [toasts])

  const multiTagOptions = [
    { value: 'gold' },
    { value: 'lime' },
    { value: 'green' },
    { value: 'cyan' },
    { value: 'long word text for a tag' },
  ]

  return (
    <div className={Styles.wrapper}>
      <Wrapper title="Modal">
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
        <div className={Styles.displayFlex}>
          <Button type="secondary" onClick={() => setShowModal(!showModal)}>
            Toggle modal
          </Button>
          <Button type="secondary" onClick={() => setShowAlertModal(!showAlertModal)}>
            Alert modal
          </Button>
          <Button type="secondary" onClick={() => setShowDefaultFooterModal(!showDefaultFooterModal)}>
            DefaultFooter modal
          </Button>
          <Button type="secondary" onClick={() => setShowDefaultModal(!showDefaultModal)}>
            Standard modal
          </Button>
        </div>
        {showModal && <RegularModal visible={showModal} toggle={() => setShowModal(!showModal)} />}

        {showDefaultFooterModal && (
          <DefaultFooterModal
            visible={showDefaultFooterModal}
            toggle={() => setShowDefaultFooterModal(!showDefaultFooterModal)}
          >
            Something...
          </DefaultFooterModal>
        )}

        {showDefaultModal && (
          <Modal
            id="playground-default-modal"
            header="Some header"
            onClose={() => setShowDefaultModal(false)}
            onOk={() => setShowDefaultModal(false)}
          >
            Standard modal with two buttons
          </Modal>
        )}
      </Wrapper>

      <Wrapper title="Modal Size">
        <div className={Styles.displayFlex}>
          <Button size="small" type="secondary" onClick={() => setShowSmallModal(!showSmallModal)}>
            small
          </Button>
          <Button size="small" type="secondary" onClick={() => setShowMediumModal(!showMediumModal)}>
            Medium
          </Button>
          <Button size="small" type="secondary" onClick={() => setShowLargeModal(!showLargeModal)}>
            large
          </Button>
          <Button size="small" type="secondary" onClick={() => setShowLargeNarrowModal(!showLargeNarrowModal)}>
            large Narrow
          </Button>
          <Button size="small" type="secondary" onClick={() => setShowLargeSimpleFormModal(!showLargeSimpleFormModal)}>
            large simple form
          </Button>
          <Button size="small" type="secondary" onClick={() => setShowExtraLargeModal(!showExtraLargeModal)}>
            extra large
          </Button>
          <Button size="small" type="secondary" onClick={() => setShowWideModal(!showWideModal)}>
            Wide
          </Button>
        </div>
        {showSmallModal && (
          <Modal
            id="playground-small-modal"
            size="small"
            header="small Modal"
            onClose={() => setShowSmallModal(!showSmallModal)}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit dolores explicabo dolor quisquam magnam,
          </Modal>
        )}
        {showMediumModal && (
          <Modal
            id="playground-medium-modal"
            size="medium"
            header="Medium Modal"
            onClose={() => setShowMediumModal(!showMediumModal)}
          >
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero eligendi eum repellat sint ipsum,
            perspiciatis omnis amet ex. In magnam deleniti ipsam velit debitis perferendis ipsum sapiente aliquid
            reprehenderit et. Esse error at porro. Labore laudantium fuga alias fugiat fugit incidunt, laborum aut
            voluptatum similique itaque quia ipsa illo vero sunt excepturi, sequi temporibus ducimus. Ratione nam
            numquam harum veritatis? Sit eveniet ratione cumque rem eius laudantium, labore unde molestiae excepturi
            perferendis quas minima eligendi est animi officia iste velit expedita autem architecto? Atque saepe,
            reiciendis minus hic accusamus optio. Modi laboriosam distinctio repudiandae doloribus culpa porro
            consectetur vero possimus reprehenderit sapiente aperiam maxime vel, alias fugiat ipsum cupiditate officiis
            consequatur aliquam aliquid, nihil totam eaque! Adipisci eaque nulla tempore. Velit corporis explicabo ullam
            excepturi aliquid repudiandae recusandae cupiditate eum iusto illum dolore modi reiciendis qui omnis animi
            deserunt, aut officiis quia ab fugit beatae tenetur! Quae odio est illum! Provident, architecto vitae nemo
            eaque numquam voluptates maiores? Nulla, atque voluptatum! Deserunt nulla nesciunt ipsa porro! Odio ducimus
            laborum dolores? Sunt accusantium quisquam fugit reprehenderit temporibus error, enim sed voluptate.
            Obcaecati fugit pariatur itaque debitis est facere, voluptates mollitia sequi animi dolorem quo! Aut aliquam
            commodi necessitatibus reiciendis. A quis neque maiores sint, quidem totam non alias. Voluptatum, magni
            consequuntur. Deserunt nulla, earum deleniti corporis ab nemo corrupti nobis debitis, accusantium libero
            laboriosam esse officiis pariatur ducimus? Maxime quam esse, illo alias itaque facilis repudiandae ipsa
            dicta. Doloremque, rerum possimus. Corrupti voluptates asperiores voluptatem quasi voluptate perferendis
            delectus dolorem nobis quibusdam ab placeat suscipit quidem ipsum harum dicta, non ex porro est nostrum
            eligendi atque nemo nulla ipsa deserunt. Provident? Aspernatur vitae praesentium laborum adipisci quas id?
            Consectetur odio esse quae quaerat. Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni!
            Vitae tempore nam perspiciatis eligendi porro. Blanditiis, eos vero!
          </Modal>
        )}
        {showLargeModal && (
          <Modal
            id="playground-large-modal"
            size="large"
            header="Large Modal"
            onClose={() => setShowLargeModal(!showLargeModal)}
          >
            Doloremque, rerum possimus. Corrupti voluptates asperiores voluptatem quasi voluptate perferendis delectus
            dolorem nobis quibusdam ab placeat suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi
            atque nemo nulla ipsa deserunt. Provident? Aspernatur vitae praesentium laborum adipisci quas id?
            Consectetur odio esse quae quaerat. Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni!
            Vitae tempore nam perspiciatis eligendi porro. Blanditiis, eos vero!
          </Modal>
        )}

        {showLargeNarrowModal && (
          <Modal
            id="playground-large-narrow-modal"
            size="largeNarrow"
            header="Large Modal"
            onClose={() => setShowLargeNarrowModal(!showLargeNarrowModal)}
          >
            Doloremque, rerum possimus. Corrupti voluptates asperiores voluptatem quasi voluptate perferendis delectus
            dolorem nobis quibusdam ab placeat suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi
            atque nemo nulla ipsa deserunt. Provident? Aspernatur vitae praesentium laborum adipisci quas id?
            Consectetur odio esse quae quaerat. Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni!
            Vitae tempore nam perspiciatis eligendi porro. Blanditiis, eos vero!
          </Modal>
        )}
        {showLargeSimpleFormModal && (
          <Modal
            id="playground-large-simple-form-modal"
            size="largeSimpleForm"
            header="Large Modal"
            onClose={() => setShowLargeSimpleFormModal(!showLargeSimpleFormModal)}
          >
            Doloremque, rerum possimus. Corrupti voluptates asperiores voluptatem quasi voluptate perferendis delectus
            dolorem nobis quibusdam ab placeat suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi
            atque nemo nulla ipsa deserunt. Provident? Aspernatur vitae praesentium laborum adipisci quas id?
            Consectetur odio esse quae quaerat. Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni!
            Vitae tempore nam perspiciatis eligendi porro. Blanditiis, eos vero! Doloremque, rerum possimus. Corrupti
            voluptates asperiores voluptatem quasi voluptate perferendis delectus dolorem nobis quibusdam ab placeat
            suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi atque nemo nulla ipsa deserunt.
            Provident? Aspernatur vitae praesentium laborum adipisci quas id? Consectetur odio esse quae quaerat.
            Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni! Vitae tempore nam perspiciatis
            eligendi porro. Blanditiis, eos vero! Doloremque, rerum possimus. Corrupti voluptates asperiores voluptatem
            quasi voluptate perferendis delectus dolorem nobis quibusdam ab placeat suscipit quidem ipsum harum dicta,
            non ex porro est nostrum eligendi atque nemo nulla ipsa deserunt. Provident? Aspernatur vitae praesentium
            laborum adipisci quas id? Consectetur odio esse quae quaerat. Possimus earum totam dolorum eius. Repudiandae
            dolor doloribus magni! Vitae tempore nam perspiciatis eligendi porro. Blanditiis, eos vero! Doloremque,
            rerum possimus. Corrupti voluptates asperiores voluptatem quasi voluptate perferendis delectus dolorem nobis
            quibusdam ab placeat suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi atque nemo nulla
            ipsa deserunt. Provident? Aspernatur vitae praesentium laborum adipisci quas id? Consectetur odio esse quae
            quaerat. Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni! Vitae tempore nam
            perspiciatis eligendi porro. Blanditiis, eos vero!
          </Modal>
        )}
        {showExtraLargeModal && (
          <Modal
            id="playground-extra-large-modal"
            size="extraLarge"
            header="Large Modal"
            onClose={() => setShowExtraLargeModal(!showExtraLargeModal)}
          >
            Doloremque, rerum possimus. Corrupti voluptates asperiores voluptatem quasi voluptate perferendis delectus
            dolorem nobis quibusdam ab placeat suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi
            atque nemo nulla ipsa deserunt. Provident? Aspernatur vitae praesentium laborum adipisci quas id?
            Consectetur odio esse quae quaerat. Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni!
            Vitae tempore nam perspiciatis eligendi porro. Blanditiis, eos vero! Doloremque, rerum possimus. Corrupti
            voluptates asperiores voluptatem quasi voluptate perferendis delectus dolorem nobis quibusdam ab placeat
            suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi atque nemo nulla ipsa deserunt.
            Provident? Aspernatur vitae praesentium laborum adipisci quas id? Consectetur odio esse quae quaerat.
            Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni! Vitae tempore nam perspiciatis
            eligendi porro. Blanditiis, eos vero! Doloremque, rerum possimus. Corrupti voluptates asperiores voluptatem
            quasi voluptate perferendis delectus dolorem nobis quibusdam ab placeat suscipit quidem ipsum harum dicta,
            non ex porro est nostrum eligendi atque nemo nulla ipsa deserunt. Provident? Aspernatur vitae praesentium
            laborum adipisci quas id? Consectetur odio esse quae quaerat. Possimus earum totam dolorum eius. Repudiandae
            dolor doloribus magni! Vitae tempore nam perspiciatis eligendi porro. Blanditiis, eos vero! Doloremque,
            rerum possimus. Corrupti voluptates asperiores voluptatem quasi voluptate perferendis delectus dolorem nobis
            quibusdam ab placeat suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi atque nemo nulla
            ipsa deserunt. Provident? Aspernatur vitae praesentium laborum adipisci quas id? Consectetur odio esse quae
            quaerat. Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni! Vitae tempore nam
            perspiciatis eligendi porro. Blanditiis, eos vero!
          </Modal>
        )}
        {showWideModal && (
          <Modal
            id="playground-wide-modal"
            size="wide"
            header="Wide Modal"
            onClose={() => setShowWideModal(!showWideModal)}
          >
            Doloremque, rerum possimus. Corrupti voluptates asperiores voluptatem quasi voluptate perferendis delectus
            dolorem nobis quibusdam ab placeat suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi
            atque nemo nulla ipsa deserunt. Provident? Aspernatur vitae praesentium laborum adipisci quas id?
            Consectetur odio esse quae quaerat. Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni!
            Vitae tempore nam perspiciatis eligendi porro. Blanditiis, eos vero! Doloremque, rerum possimus. Corrupti
            voluptates asperiores voluptatem quasi voluptate perferendis delectus dolorem nobis quibusdam ab placeat
            suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi atque nemo nulla ipsa deserunt.
            Provident? Aspernatur vitae praesentium laborum adipisci quas id? Consectetur odio esse quae quaerat.
            Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni! Vitae tempore nam perspiciatis
            eligendi porro. Blanditiis, eos vero! Doloremque, rerum possimus. Corrupti voluptates asperiores voluptatem
            quasi voluptate perferendis delectus dolorem nobis quibusdam ab placeat suscipit quidem ipsum harum dicta,
            non ex porro est nostrum eligendi atque nemo nulla ipsa deserunt. Provident? Aspernatur vitae praesentium
            laborum adipisci quas id? Consectetur odio esse quae quaerat. Possimus earum totam dolorum eius. Repudiandae
            dolor doloribus magni! Vitae tempore nam perspiciatis eligendi porro. Blanditiis, eos vero! Doloremque,
            rerum possimus. Corrupti voluptates asperiores voluptatem quasi voluptate perferendis delectus dolorem nobis
            quibusdam ab placeat suscipit quidem ipsum harum dicta, non ex porro est nostrum eligendi atque nemo nulla
            ipsa deserunt. Provident? Aspernatur vitae praesentium laborum adipisci quas id? Consectetur odio esse quae
            quaerat. Possimus earum totam dolorum eius. Repudiandae dolor doloribus magni! Vitae tempore nam
            perspiciatis eligendi porro. Blanditiis, eos vero!
          </Modal>
        )}
      </Wrapper>

      <Wrapper title="Breadcrumbs">
        <BreadcrumbLabel>Single breadcrumb label</BreadcrumbLabel>

        <Breadcrumbs
          crumbs={[
            { label: 'Master page', path: '/root/' },
            { label: 'Sub page 1', path: '/root/page' },
            { label: 'Sub page B', path: '/root/page/sub' },
            { label: 'Take me there Take me there', path: '/root/page/sub/asdfasdfads' },
          ]}
        />
      </Wrapper>
      <Wrapper title="Breadcrumbs with Option Menu">
        <Breadcrumbs
          crumbs={[
            { label: 'Master page', path: '/root/' },
            { label: 'Sub page 1', path: '/root/page' },
            { label: 'Sub page B', path: '/root/page/sub' },
            { label: 'Take me there Take me there Take me there', path: '/root/page/sub/asdfasdfads' },
            {
              menu: (
                <OptionMenu
                  options={[
                    { value: 'version_history', title: 'See Version History' },
                    { value: 'duplicate', title: 'Duplicate Routine' },
                    { value: 'edit', title: 'Edit Routine', tooltipProps: { title: 'this is an option menu tooltip' } },
                  ]}
                  onMenuItemClick={() => {}}
                  openWithClick
                >
                  <BreadcrumbLabel>
                    <BreadcrumbMenu title="nombre v123 aflskdjhf klasdf lkasdjhf lasdkjh flakdhjs" />
                  </BreadcrumbLabel>
                </OptionMenu>
              ),
            },
          ]}
        />
      </Wrapper>

      <Wrapper title="Tag">
        <Tag shape="rectangle" type="success">
          Camera: Production Station A12
        </Tag>
        <Tag type="danger">Camera: Production Station A12</Tag>
        <Tag>Camera: Production Station A12</Tag>
      </Wrapper>

      <Wrapper title="Tag: rectangle shape" className={Styles.marginTop}>
        <div>
          <Tag shape="rectangle" type="success">
            Camera: Production Station A12
          </Tag>
          <Tag shape="rectangle" type="danger">
            Camera: Production Station A12
          </Tag>
          <Tag shape="rectangle">Camera: Production Station A12</Tag>
        </div>
        <div>
          <Tag shape="rectangle">label</Tag>
          <Tag shape="rectangle" type="success" addBadge>
            label
          </Tag>

          <Tag shape="rectangle" type="danger" addBadge>
            label
          </Tag>

          <Tag shape="rectangle" type="unknown" addBadge>
            label
          </Tag>
          <Tag shape="rectangle" type="info" addBadge={<PrismUploadIcon />}>
            label
          </Tag>
          <BetaTag />
          <OfflineTag />
        </div>
      </Wrapper>

      <Wrapper title="live feed">
        <LiveFeed status="default" />
        <LiveFeed status="pass" />
        <LiveFeed />
        <LiveFeed status="fail" showLabel>
          Camera: Production Station A12
        </LiveFeed>
      </Wrapper>

      <Wrapper title="progress bar">
        <ProgressBar height="small" progress={80} />
        <ProgressBar height="medium" progress={80} />
        <ProgressBar height="large" progress={80} />
        <ProgressBar height="extraLarge" progress={80} />
        <ProgressBar height="extraLarge" type="white" progress={30} />
        <ProgressBar height="extraLarge" type="pass" progress={30} />
        <ProgressBar height="extraLarge" type="fail" progress={40} />
        <ProgressBar height="extraLarge" type="unknown" progress={50} />
      </Wrapper>

      <Wrapper title="token">
        <Token
          onClick={() => {
            alert('test')
          }}
        >
          12/23/20
        </Token>
        <Token
          onClick={() => {
            alert('test')
          }}
        >
          {(37 * 10) / 100}%
        </Token>
        <Token
          onClick={() => {
            alert('test')
          }}
        >
          <Status status="running" />
          <Status status="stopped" />
          <Status status="connected" />
          <Status status="disconnected" />
          <Status status="loading" />
        </Token>
        <Token
          onClick={() => {
            alert('test')
          }}
        >
          <Status status="connected" />
        </Token>
      </Wrapper>

      <Wrapper>
        <Token horizontal label="Failure">
          {(37 * 10) / 100}%
        </Token>

        <Token horizontal label="Failure">
          {(37 * 10) / 100}%
        </Token>

        <Token horizontal label="Fail Rate">
          {(37 * 10) / 100}%
        </Token>

        <Token horizontal label="Fail Rate">
          {(37 * 10) / 100}%
        </Token>
      </Wrapper>

      <Wrapper>
        <Token inverted label="Failure">
          {(37 * 10) / 100}%
        </Token>
        <Token inverted label="Failure" align="center">
          {(37 * 10) / 100}%
        </Token>
        <Token inverted label="Failure" align="right">
          {(37 * 10) / 100}%
        </Token>
      </Wrapper>

      <Wrapper title="Token with Live feed">
        <Token label="Start date">
          <LiveFeed status="fail">Camera: Production Station A12</LiveFeed>
        </Token>
      </Wrapper>

      <Wrapper title="radio button">
        <PrismToggleGroup
          options={[
            { label: 'Brand', type: 'danger', value: 'brand' },
            { label: 'Product', type: 'success', value: 'label' },
          ]}
        />
      </Wrapper>
      <Wrapper>
        <PrismToggleGroup
          options={[
            { value: 'brand', label: 'Brand', type: 'danger' },
            { value: 'product', label: 'Product', type: 'success' },
          ]}
        />
      </Wrapper>

      <Wrapper>
        <PrismToggleGroup
          className={Styles.PrismToggleGroup}
          options={[
            { value: 'fail', label: 'Fail', type: 'danger' },
            { value: 'pass', label: 'Pass', type: 'success' },
            { value: 'unknown', label: 'unknown', type: 'unknown' },
          ]}
        />
      </Wrapper>
      <Wrapper>
        <PrismToggleGroup
          options={[
            { value: 'fail', label: 'Fail', type: 'danger' },
            { value: 'pass', label: 'Pass', type: 'success' },
            { value: 'other', label: 'other' },
          ]}
        />
      </Wrapper>

      <Wrapper title="label card">
        <LabelCard image={<PrismElementaryCube addBackground />} label={<Token label="Label">None</Token>} />
      </Wrapper>
      <Wrapper title="label card with hover">
        <LabelCard
          image={<PrismElementaryCube addBackground />}
          className={Styles.thresholdCard}
          label={<Token label="Label">None</Token>}
        />
      </Wrapper>

      <Wrapper>
        <Message type="success">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum consequatur non rem labore voluptas eius,
          impedit aspernatur reiciendis ad, omnis illo rerum veritatis corporis voluptatem quos? Placeat, voluptatibus
          voluptas. Dignissimos? Ut eum, ullam quisquam saepe consectetur ipsa id facere rerum molestiae, asperiores
          accusamus dignissimos iure dicta aliquam animi, nostrum alias vel? Accusamus ut, modi exercitationem qui
          magnam debitis. Perferendis, quas. Odio, perspiciatis asperiores error ut voluptatem dolore illo nobis impedit
          eos possimus magnam tempore exercitationem facilis beatae et est cumque adipisci nam ipsa. Minus quia ullam
          dolorum nulla doloribus velit!
        </Message>
        <Message type="warning">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum consequatur non rem labore voluptas eius,
          impedit aspernatur reiciendis ad
        </Message>

        <Message type="info">Hello</Message>
        <Message type="error">Hello</Message>
        <Message
          type="error"
          onButtonClick={() => {
            alert('go to recipe')
          }}
          buttonText="click"
        >
          Something went wrong, click the button for more info
        </Message>
      </Wrapper>
      <Wrapper>
        <Message type="loading">Loading Message</Message>
      </Wrapper>
      <Wrapper className={Styles.notificationsContainer}>
        <Notification
          title="Title"
          description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum consequatur non rem labore voluptas eius, impedit aspernatur reiciendis ad, omnis illo rerum veritatis corporis voluptatem quos? Placeat, voluptatibus voluptas. Dignissimos?
      Ut eum, ullam quisquam saepe consectetur ipsa id facere rerum molestiae, asperiores accusamus dignissimos iure dicta aliquam animi, nostrum alias vel? Accusamus ut, modi exercitationem qui magnam debitis. Perferendis, quas.
      Odio, perspiciatis asperiores error ut voluptatem dolore illo nobis impedit eos possimus magnam tempore exercitationem facilis beatae et est cumque adipisci nam ipsa. Minus quia ullam dolorum nulla doloribus velit!"
        />

        <Notification
          title="Title"
          type="error"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non urna malesuada nascetur a, volutpat donec non semper molestie."
        />

        <Notification
          title="Title"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non urna malesuada nascetur a, volutpat donec non semper molestie."
        >
          <ProgressBar progress={80} height="medium" />
        </Notification>
        <Notification
          title="Title"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non urna malesuada nascetur a, volutpat donec non semper molestie."
        >
          <Button type="secondary" size="small">
            Button
          </Button>
        </Notification>
        <Notification
          title="Title"
          type="error"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non urna malesuada nascetur a, volutpat donec non semper molestie."
        >
          <Button type="secondary" size="small">
            Button
          </Button>
        </Notification>
        <h2>Messages</h2>
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
        <div className={Styles.displayFlex}>
          <Button size="small" onClick={() => success({ title: 'Photo taken correctly', duration: 0 })}>
            Success
          </Button>
          <Button
            size="small"
            onClick={() =>
              error({
                title: 'Failed to connect to camera',
              })
            }
          >
            Fail
          </Button>

          <Button size="small" onClick={() => warning({ title: 'Confliting settings on cameras' })}>
            Warning
          </Button>
          <Button
            size="small"
            onClick={() => {
              info({ title: "We're having some trouble with X thing" })
            }}
          >
            Info
          </Button>
        </div>
        <h2>NotificationS</h2>
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
        <div className={Styles.displayFlex}>
          <Button
            size="small"
            onClick={() =>
              successNotification({
                title: 'Photo taken correctly',
                position: 'top-left',
                description:
                  'Lorem ipsum dolor sit amet, pellentesque wisi tortor duis, amet adipiscing bibendum elit aliquam leo.',
                duration: 0,
              })
            }
          >
            Success
          </Button>
          <Button
            size="small"
            onClick={() => {
              openNotification({
                title: 'Initial desc',
                description: 'Initial desc',
                duration: 0,
                id: 'update-notification',
              })

              window.setTimeout(() => {
                errorNotification({ title: 'Failed to connect to camera', id: 'update-notification' })
              }, 500)
            }}
          >
            Change
          </Button>
          <Button
            size="small"
            onClick={() =>
              errorNotification({
                title: 'Failed to connect to camera',
                position: 'bottom-left',
                description:
                  'Lorem ipsum dolor sit amet, pellentesque wisi tortor duis, amet adipiscing bibendum elit aliquam leo.',
                children: <ProgressBar progress={80} height="medium" />,
              })
            }
          >
            Fail
          </Button>
          <Button
            size="small"
            onClick={() =>
              warningNotification({
                title: 'Confliting settings on cameras',
                position: 'top-right',
                description:
                  'Lorem ipsum dolor sit amet, pellentesque wisi tortor duis, amet adipiscing bibendum elit aliquam leo.',
              })
            }
          >
            Warning
          </Button>
          <Button
            size="small"
            onClick={() =>
              infoNotification({
                id: detectToastChangeToastId,
                title: "We're having some trouble with X thing",
                description:
                  'Lorem ipsum dolor sit amet, pellentesque wisi tortor duis, amet adipiscing bibendum elit aliquam leo.',
              })
            }
          >
            Info
          </Button>
        </div>
      </Wrapper>
      <Wrapper title="buttons">
        <div className={Styles.buttonLoader}>
          <Button size="small" type="primary">
            Primary
          </Button>
          <Button size="small" type="secondary">
            Secondary
          </Button>
          <Button size="small" type="tertiary">
            Tertiary
          </Button>
          <Button size="small" type="ghost">
            Ghost
          </Button>
          <Button size="small" type="danger">
            Danger
          </Button>
          <Button disabled size="small" type="primary">
            Primary
          </Button>
          <Button disabled size="small" type="secondary">
            Secondary
          </Button>
          <Button disabled size="small" type="tertiary">
            Tertiary
          </Button>
          <Button disabled size="small" type="danger">
            Danger
          </Button>
          <Button disabled type="default">
            Default
          </Button>
          <Button type="default">Default</Button>
        </div>

        <div className={Styles.buttonLoader}>
          <IconButton size="small" type="primary" icon={<PrismHelpIcon />} />

          <IconButton size="small" type="secondary" icon={<PrismHelpIcon />} />
          <IconButton size="small" type="secondary" disabled icon={<PrismHelpIcon />} />

          <IconButton size="small" type="tertiary" icon={<PrismHelpIcon />} />

          <IconButton size="small" type="ghost" icon={<PrismHelpIcon />} />
        </div>
      </Wrapper>
      <Wrapper title="buttons">
        <div className={Styles.buttonLoader}>
          <Button size="small">button</Button>
          <Button size="small" badge={<PrismAddIcon />}>
            button
          </Button>
          <Button size="small" badge={<PrismAddIcon />} invertBadgePosition>
            button
          </Button>
        </div>
      </Wrapper>
      <Wrapper>
        <Alert
          onClick={() => {}}
          status="available"
          description="Yield fell below target of 95.5% today from 5:01pm to 5:02pm"
        />
        <Alert status="running" disabled description="Yield fell below target of 95.5% today from 5:01pm to 5:02pm" />
        <Alert status="paused" description="Yield fell below target of 95.5% today from 5:01pm to 5:02pm" />
        <Alert status="offline" description="Yield fell below target of 95.5% today from 5:01pm to 5:02pm" />
      </Wrapper>

      <Wrapper>
        <ToolBlankState />
      </Wrapper>

      <Wrapper>
        <GraphBlankState />
      </Wrapper>

      <Wrapper>
        <AlertBlankState />
      </Wrapper>

      <Wrapper>
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
        <div style={{ display: 'flex', marginBottom: '16px' }}>
          <IconButton size="small" icon={<PrismAddIcon />} />
          <IconButton size="small" type="secondary" icon={<PrismAddIcon />} />
          <IconButton size="small" type="tertiary" icon={<PrismAddIcon />} />
          <IconButton size="small" type="ghost" icon={<PrismAddIcon />} />
        </div>
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
        <div style={{ display: 'flex', marginBottom: '16px' }}>
          <IconButton size="medium" icon={<PrismAddIcon />} />
          <IconButton size="medium" type="secondary" icon={<PrismAddIcon />} />
          <IconButton size="medium" type="tertiary" icon={<PrismAddIcon />} />
          <IconButton size="medium" type="ghost" icon={<PrismAddIcon />} />
        </div>
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
        <div className={Styles.displayFlex}>
          <IconButton size="large" icon={<PrismAddIcon />} />
          <IconButton size="large" type="secondary" icon={<PrismAddIcon />} />
          <IconButton size="large" type="tertiary" icon={<PrismAddIcon />} />
          <IconButton size="large" type="ghost" icon={<PrismAddIcon />} />
        </div>
      </Wrapper>

      <Wrapper title="icon buttons with loader">
        <Button onClick={() => setIconButtonIsLoading(!iconButtonIsLoading)} size="small" type="secondary">
          loader {iconButtonIsLoading ? 'off' : 'on'}
        </Button>
        <div className={`${Styles.displayFlex} ${Styles.alignment}`}>
          <IconButton loading={iconButtonIsLoading} icon={<PrismArrowIcon />} size="large" />
          <IconButton loading={iconButtonIsLoading} icon={<PrismAddIcon />} size="medium" />
          <IconButton loading={iconButtonIsLoading} icon={<PrismZoomInIcon />} size="xsmall" />
        </div>
        <div className={`${Styles.displayFlex} ${Styles.alignment}`}>
          <IconButton loading={iconButtonIsLoading} type="ghost" icon={<PrismArrowIcon />} size="large" />
          <IconButton loading={iconButtonIsLoading} type="secondary" icon={<PrismAddIcon />} size="medium" />
          <IconButton loading={iconButtonIsLoading} type="ghost" icon={<PrismZoomInIcon />} size="xsmall" />
        </div>
      </Wrapper>

      <Wrapper title="Timeline">
        <Timeline>
          <TimelineItem
            className={Styles.timelineItem}
            description={<div>Set as Fail because Pass Criteria was not met</div>}
            date={moment()}
          />
        </Timeline>
      </Wrapper>
      <Wrapper title="Prism Loader">
        <PrismLoader />
        <PrismLoader withBackground />
      </Wrapper>

      <Wrapper title="Skeleton Loader">
        <div className={Styles.skeletonContainer}>
          <PrismSkeleton size="small" />
          <PrismSkeleton size="medium" />
          <PrismSkeleton size="default" />
          <PrismSkeleton size="large" />
          <PrismSkeleton size="extraLarge" className={Styles.skeletonWithBorder} />
          <PrismSkeleton size="extraLarge" className={Styles.skeletonWithBorder} lighterShade />

          <PrismSkeleton maxWidth={100} />

          <PrismSkeleton maxWidth={80} />
          <PrismSkeleton maxWidth={40} shape="circle" />
          <PrismSkeleton maxWidth={80} shape="circle" />
        </div>
      </Wrapper>
      <Wrapper>
        <div className={Styles.displayFlex}>
          <ToolBlankState isLoading />
          <Button loading> Loading</Button>
        </div>
      </Wrapper>
      <Wrapper>
        <LoadingOutlined spin />
      </Wrapper>

      <Wrapper title="Select large, height: 48px">
        <PrismSelect
          disabled
          placeholder="Dropdown Text"
          options={[{ value: '1', title: 'LongTextToBreakAnElement' }]}
        />

        <PrismSelect options={[1, 2, 3, 4, 5].map(n => ({ value: `${n}`, title: `Option ${n}` }))} />
      </Wrapper>

      <Wrapper title="Select Middle, height: 40px">
        <PrismSelect size="middle" placeholder="Select medium size" options={[{ value: '1', title: 'option 1' }]} />
      </Wrapper>

      <Wrapper title="Select small height: 24px">
        <PrismSelect size="small" disabled placeholder="Dropdown Text" options={[{ value: '1', title: 'option 1' }]} />

        <PrismSelect size="small" placeholder="Dropdown Text" options={[{ value: '1', title: 'option 1' }]} />

        <PrismSelect size="small" options={[1, 2, 3, 4, 5].map(n => ({ value: `${n}`, title: `Option ${n}` }))} />
      </Wrapper>
      <Wrapper className={Styles.popover}>
        <Popover placement="top" content={<span>Apply settings to crop feed or take photo</span>} open={showPopover}>
          <Button onClick={() => setShowPopover(!showPopover)}>Popover</Button>
        </Popover>
        <PrismTooltipWithPopover
          hoverTitle="This is a conditional tooltip, when the condition is met the tooltip is shown, and if the condition is false nothing will be shown."
          popoverContent={<>This is the popover content</>}
          handlePopoverVisibleChange={() => setShowTooltipPopover(!showTooltipPopover)}
          popoverVisible={showTooltipPopover}
        >
          <>Tooltip with popover (Click me!)</>
        </PrismTooltipWithPopover>
      </Wrapper>

      <Wrapper title="Prism Slider">
        <PrismSliderRange
          label="Range"
          min={0}
          max={20}
          step={1}
          onChange={() => {}}
          onAfterChange={() => {}}
          valueStart={0}
          valueEnd={20}
        />
        <PrismSliderRange
          label="Range"
          min={0}
          max={20}
          step={1}
          onChange={() => {}}
          onAfterChange={() => {}}
          valueStart={0}
          valueEnd={20}
          inputBoxLeft={<PrismInput value={0} />}
          inputBoxRight={<PrismInput value={20} />}
        />
        <PrismSlider min={0} max={20} value={3} step={1} onChange={() => {}} onAfterChange={() => {}} />
        <PrismSlider
          label="Simple Slider"
          min={0}
          max={20}
          step={1}
          value={3}
          onChange={() => {}}
          onAfterChange={() => {}}
          actions={<div>hi</div>}
          disabled
        />
        <PrismSlider min={0} max={20} step={1} value={3} onChange={() => {}} />
        <PrismSlider
          label="Simple Slider"
          min={0}
          max={20}
          step={1}
          value={3}
          onChange={() => {}}
          onAfterChange={() => {}}
          inputBox={<PrismInput />}
        />
        <PrismSlider
          label="Simple Slider"
          min={0}
          max={20}
          step={1}
          value={3}
          onChange={() => {}}
          onAfterChange={() => {}}
          inputBox={<PrismInput />}
          inverted
          actions={
            <div>
              <PrismHelpIcon />
            </div>
          }
        />
        <Slider
          value={sliderMarks}
          min={0}
          max={270}
          marks={{ 0: '', 90: '', 180: '', 270: '' }}
          step={null}
          onChange={e => {
            setSliderMarks(e)
          }}
        />
      </Wrapper>
      <Wrapper>
        <PrismDiscardIcon />
        <PrismDiscardIcon variant="high" />
        <PrismDiscardIcon variant="high" />
        <PrismDiscardIcon variant="low" />
      </Wrapper>

      <Wrapper className={Styles.pdfContainer}>
        <div>
          <PDFDownloadLink
            document={
              <PrismPdf
                nowString={moment().format()}
                title="overview"
                date="Jan-10-2022 10:00AM – Jan-18-22 10:00AM"
                link="view live"
              ></PrismPdf>
            }
            fileName="somename.pdf"
          >
            {({ loading }) => <Button>{loading ? 'Loading document...' : 'Download now!'}</Button>}
          </PDFDownloadLink>
        </div>
      </Wrapper>

      <Wrapper title="Prism Menu List">
        <PrismMenuList
          className={Styles.prismMenuList}
          list={[
            { label: 'test 1', value: 'test1', isActive: false },
            { label: 'test 2', value: 'test2', isActive: true },
            { label: 'test 3', value: 'test3', isActive: false },
          ]}
          onClick={() => {}}
        />
      </Wrapper>

      <Wrapper title="Toggle Group">
        <PrismToggleGroup onChange={() => {}} options={[{ label: 'Brand', type: 'default', value: 'brand' }]} />
        <PrismToggleGroup
          onChange={() => {}}
          value="ellipse"
          options={[
            { label: 'Brand', type: 'danger', value: 'brand' },
            { label: 'Brand', type: 'default', value: 'brand' },
            { icon: <PrismEllipseIcon />, value: 'ellipse' },
            { icon: <PrismRectangleIcon />, type: 'success', value: 'rectangle' },
            { icon: <PrismPolygonIcon />, type: 'unknown', value: 'polygon' },
          ]}
        />
      </Wrapper>
      <Wrapper>
        <SelectNotFound />
      </Wrapper>

      <Wrapper>
        <EmptyStateNoImagesMatch />
        <EmptyStateNoImagesToLabel />
        <EmptyStateDeployAndCollectImages />
        <EmptyStateCollectImages />
        <EmptyStateLabelImagesCollectedBeforeToolCreation onClick={() => {}} />
      </Wrapper>

      <Wrapper title="Prism Result button">
        <div className={Styles.prismSeverityWrapper}>
          <div className={Styles.prismSeverityContainer}>
            <h4>type: default</h4>
            <PrismResultButton severity="good" value="label" />
            <PrismResultButton severity="good" value="label" onClick={() => {}} />
            <PrismResultButton severity="good" value="label" onClick={() => {}} disabled />
            <h4>type: fill</h4>
            <PrismResultButton severity="good" value="label" onClick={() => {}} type="fill" />
            <PrismResultButton severity="good" value="label" type="fill" />
            <PrismResultButton severity="good" value="label" onClick={() => {}} type="fill" disabled />
            <h4>type: noFill</h4>
            <PrismResultButton severity="good" value="label" onClick={() => {}} type="noFill" />
            <PrismResultButton severity="good" value="label" onClick={() => {}} type="noFill" disabled />
            <h4>type: ghost</h4>
            <PrismResultButton severity="good" value="label" onClick={() => {}} type="ghost" />
            <PrismResultButton severity="good" value="label" onClick={() => {}} type="ghost" disabled />
          </div>
          <div className={Styles.prismSeverityContainer}>
            <PrismResultButton severity="critical" value="label" />
            <PrismResultButton severity="critical" value="label" onClick={() => {}} />
            <PrismResultButton severity="critical" value="label" onClick={() => {}} disabled />

            <PrismResultButton severity="critical" value="label" onClick={() => {}} type="fill" />
            <PrismResultButton severity="critical" value="label" type="fill" />
            <PrismResultButton severity="critical" value="label" onClick={() => {}} type="fill" disabled />

            <PrismResultButton severity="critical" value="label" onClick={() => {}} type="noFill" />
            <PrismResultButton severity="critical" value="label" onClick={() => {}} type="noFill" disabled />

            <PrismResultButton severity="critical" value="label" onClick={() => {}} type="ghost" />
            <PrismResultButton severity="critical" value="label" onClick={() => {}} type="ghost" disabled />
          </div>
          <div className={Styles.prismSeverityContainer}>
            <PrismResultButton severity="minor" value="label" />
            <PrismResultButton severity="minor" value="label" onClick={() => {}} />
            <PrismResultButton severity="minor" value="label" onClick={() => {}} disabled />
            <PrismResultButton severity="minor" value="label" onClick={() => {}} type="fill" />
            <PrismResultButton severity="minor" value="label" onClick={() => {}} type="fill" disabled />
            <PrismResultButton severity="minor" value="label" onClick={() => {}} type="noFill" />
            <PrismResultButton severity="minor" value="label" onClick={() => {}} type="noFill" disabled />
            <PrismResultButton severity="minor" value="label" onClick={() => {}} type="ghost" />
            <PrismResultButton severity="minor" value="label" onClick={() => {}} type="ghost" disabled />
          </div>
          <div className={Styles.prismSeverityContainer}>
            <PrismResultButton severity="neutral" value="label" />
            <PrismResultButton severity="neutral" value="label" onClick={() => {}} />
            <PrismResultButton severity="neutral" value="label" onClick={() => {}} disabled />
            <PrismResultButton severity="neutral" value="label" onClick={() => {}} type="fill" />
            <PrismResultButton severity="neutral" value="label" onClick={() => {}} type="fill" disabled />
            <PrismResultButton severity="neutral" value="label" onClick={() => {}} type="noFill" />
            <PrismResultButton severity="neutral" value="label" onClick={() => {}} type="noFill" disabled />
            <PrismResultButton severity="neutral" value="label" onClick={() => {}} type="ghost" />
            <PrismResultButton severity="neutral" value="label" onClick={() => {}} type="ghost" disabled />
          </div>
          <div className={Styles.prismSeverityContainer}>
            <PrismResultButton severity="pass" value="label" />
            <PrismResultButton severity="pass" value="label" onClick={() => {}} />
            <PrismResultButton severity="pass" value="label" onClick={() => {}} disabled />
            <PrismResultButton severity="pass" value="label" onClick={() => {}} type="fill" />
            <PrismResultButton severity="pass" value="label" onClick={() => {}} type="fill" disabled />
            <PrismResultButton severity="pass" value="label" onClick={() => {}} type="noFill" />
            <PrismResultButton severity="pass" value="label" onClick={() => {}} type="noFill" disabled />
            <PrismResultButton severity="pass" value="label" onClick={() => {}} type="ghost" />
            <PrismResultButton severity="pass" value="label" onClick={() => {}} type="ghost" disabled />
          </div>
          <div className={Styles.prismSeverityContainer}>
            <PrismResultButton severity="fail" value="label" />
            <PrismResultButton severity="fail" value="label" onClick={() => {}} />
            <PrismResultButton severity="fail" value="label" onClick={() => {}} disabled />
            <PrismResultButton severity="fail" value="label" onClick={() => {}} type="fill" />
            <PrismResultButton severity="fail" value="label" onClick={() => {}} type="fill" disabled />
            <PrismResultButton severity="fail" value="label" onClick={() => {}} type="noFill" />
            <PrismResultButton severity="fail" value="label" onClick={() => {}} type="noFill" disabled />
            <PrismResultButton severity="fail" value="label" onClick={() => {}} type="ghost" />
            <PrismResultButton severity="fail" value="label" onClick={() => {}} type="ghost" disabled />
          </div>
          <div className={Styles.prismSeverityContainer}>
            <PrismResultButton severity="unknown" value="label" />
            <PrismResultButton severity="unknown" value="label" onClick={() => {}} disabled />
            <PrismResultButton severity="unknown" value="label" onClick={() => {}} type="fill" />
            <PrismResultButton severity="unknown" value="label" onClick={() => {}} type="noFill" />
            <PrismResultButton severity="discard" type="ghost" />
          </div>
        </div>
      </Wrapper>
      <Wrapper title="Prism Outcome">
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
        <div style={{ display: 'flex' }}>
          {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <PrismOutcome icon="fail" />
            <PrismOutcome icon="fail" />
            <PrismOutcome icon="fail" variant="dark" />
            <PrismOutcome icon="fail" variant="low" outcome="fail" />
            <PrismOutcome icon="fail" variant="high" outcome="fail" />
          </div>
          {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <PrismOutcome icon="pass" />
            <PrismOutcome icon="pass" />
            <PrismOutcome icon="pass" variant="dark" />
            <PrismOutcome icon="pass" variant="low" outcome="pass" />
            <PrismOutcome icon="pass" variant="high" outcome="pass" />
          </div>
          {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <PrismOutcome icon="unknown" />
            <PrismOutcome icon="unknown" />
            <PrismOutcome icon="unknown" variant="dark" />
            <PrismOutcome icon="unknown" variant="low" />
            <PrismOutcome icon="unknown" variant="high" />
          </div>
          {/* eslint-disable-next-line no-inline-styles/no-inline-styles  */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <PrismOutcome icon="test_set" />
            <PrismOutcome icon="test_set" />
            <PrismOutcome icon="test_set" variant="dark" />
            <PrismOutcome icon="test_set" variant="low" />
            <PrismOutcome icon="test_set" variant="high" />
          </div>
        </div>
      </Wrapper>
      <Wrapper>
        <PrismLabelButton
          value="Anomaly"
          severity="minor"
          hotkey={2}
          onEditClick={e => {
            e.stopPropagation()
          }}
        />
        <PrismLabelButton value="Pass" severity="pass" hotkey={2} editMode />
        <PrismLabelButton value="Pass" severity="pass" hotkey={2} disabled />
        <PrismLabelButton value="Pass" severity="pass" hotkey={2} active />
        <PrismLabelButton
          value="good"
          severity="good"
          hotkey={2}
          active
          onEditClick={e => {
            e.stopPropagation()
          }}
        />
      </Wrapper>

      <Wrapper title="Toggle Button">
        <div>
          <ToggleButton title="Area" hotkey="A" />
          <ToggleButton title="Area" hotkey="A" active />
          <ToggleButton title="Area" hotkey="A" disabled tooltip="hello" />
        </div>
      </Wrapper>

      <Wrapper>
        <PrismSlider min={0} max={0} label="treshold" inputBox inverted />
      </Wrapper>
      <Wrapper>
        <PrismSelect
          mode="multiple"
          size="large"
          className={Styles.multiTagContainer}
          tagRender={({ closable, onClose, value }) => {
            return (
              <AntTag closable={closable} onClose={onClose} className={Styles.labelTag}>
                <PrismResultButton value={value} severity="neutral" />
              </AntTag>
            )
          }}
          options={multiTagOptions}
        />
      </Wrapper>
      <Wrapper title="Icons">
        <Wrapper title="Icons that can be active and hover">
          <PrismAnomalyToolIcon />
          <PrismAnomalyToolIcon isActive />
        </Wrapper>
        <Wrapper
          title="Default icons: icon color don't change and add background if needed, most of these will be used inside
            iconButton, or a button."
        >
          <PrismNavArrowIcon direction="right" />
          <PrismNavArrowIcon direction="right" hasBackground />

          <PrismMenuIcon />
          <PrismMenuIcon hasBackground />

          <PrismHelpIcon />
          <PrismErrorIcon />
          <PrismExpandIcon />
          <PrismDiscardIcon />
          <PrismInfoIcon />
          <PrismEyeShowIcon />
          <PrismEyeHideIcon />
        </Wrapper>

        <Wrapper title="Outcome icons: icons with special backgrounds">
          <PrismDiscardIcon />
          <PrismDiscardIcon variant="low" />
          <PrismDiscardIcon variant="high" />
          <PrismDiscardIcon variant="dark" />

          <PrismPassIcon />
          <PrismPassIcon variant="iconColor" />
          <PrismPassIcon variant="low" />
          <PrismPassIcon variant="high" />
          <PrismPassIcon variant="dark" />

          <PrismFailIcon />
          <PrismFailIcon variant="iconColor" />
          <PrismFailIcon variant="low" />
          <PrismFailIcon variant="high" />
          <PrismFailIcon variant="dark" />

          <PrismUnknownIcon />
          <PrismUnknownIcon variant="iconColor" />
          <PrismUnknownIcon variant="low" />
          <PrismUnknownIcon variant="high" />
          <PrismUnknownIcon variant="dark" />
        </Wrapper>

        <Wrapper title="Severity icons">
          <PrismNeutralIcon />
          <PrismNeutralIcon variant="iconColor" />
          <PrismNeutralIcon variant="iconAndBackground" />

          <PrismCriticalIcon />
          <PrismCriticalIcon variant="iconColor" />
          <PrismCriticalIcon variant="iconAndBackground" />

          <PrismMinorIcon />
          <PrismMinorIcon variant="iconColor" />
          <PrismMinorIcon variant="iconAndBackground" />

          <PrismGoodIcon />
          <PrismGoodIcon variant="iconColor" />
          <PrismGoodIcon variant="iconAndBackground" />
        </Wrapper>

        <Wrapper title="Special cases: icons with more than 2 colors, can be hovered and activated">
          <PrismCreateIcon />
          <PrismCreateIcon isActive />

          <PrismInspectIcon />
          <PrismInspectIcon isActive />

          <PrismAnalyzeIcon />
          <PrismAnalyzeIcon isActive />

          <PrismNotifyIcon />
          <PrismNotifyIcon isActive />

          <PrismSettingsIcon />
          <PrismSettingsIcon isActive />

          <PrismCloudConnectedIcon />
          <PrismCloudConnectedIcon isActive />

          <PrismRandomToolIcon />
          <PrismRandomToolIcon isActive />

          <PrismBarcodeToolIcon />
          <PrismBarcodeToolIcon isActive />

          <PrismInfoIcon />
          <PrismErrorIcon />
          <PrismWarningIcon />
          <PrismSuccessIcon />
          <PrismInfoIcon isActive />
          <PrismErrorIcon isActive />
          <PrismWarningIcon isActive />
          <PrismSuccessIcon isActive />
          <PrismDetailIcon />
          <PrismGoodIcon />
        </Wrapper>

        <Wrapper title="entity icons">
          <PrismStationIcon />
          <PrismCameraViewIcon />
          <PrismProductIcon />
          <PrismRecipeIcon />
          <PrismSiteIcon />
          <PrismLineIcon />
        </Wrapper>
      </Wrapper>
      <Wrapper title="input status error">
        <PrismInput status="error" statusMessage={'this is an error'} />
        <PrismInputNumber status="warning" statusMessage="warning text" />
      </Wrapper>
      <Wrapper>
        <Radio />
        <Radio disabled />
        <Radio checked />
        <Radio checked disabled />
        <Radio disabled checked>
          <span>Object</span>
        </Radio>
        <Radio disabled>
          <span>Object</span>
        </Radio>
      </Wrapper>
      <Wrapper>
        <PrismCheckbox />
        <PrismCheckbox disabled />
        <PrismCheckbox checked />
        <PrismCheckbox checked disabled />

        <PrismCheckbox label="Label" />
        <PrismCheckbox disabled label="Label" />
        <PrismCheckbox checked label="Label" />
        <PrismCheckbox checked disabled label="Label" />
      </Wrapper>
      <Wrapper>
        <SearchInput />

        <PrismSearchInput onInputChange={() => {}} size="small" />
        <PrismSearchInput onInputChange={() => {}} addAnimation />
      </Wrapper>
      <Wrapper>
        <PrismCascader
          placeholder="Site Name / Line Name"
          titles={['sites', 'lines', 'stations']}
          options={[
            {
              value: 'site1',
              label: 'site 1',
              children: [
                {
                  value: 'line1',
                  label: 'line 1',
                  children: [
                    {
                      value: 'station1',
                      label: 'station 1',
                    },
                  ],
                },
              ],
            },
            {
              value: 'site2',
              label: 'site 2',
              children: [
                {
                  value: 'line2',
                  label: 'line 2',
                  children: [
                    {
                      value: 'station2',
                      label: 'station 2',
                      children: [
                        {
                          value: 'additional',
                          label: 'additional',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              value: 'site3',
              label: 'site 3',
              children: [
                {
                  value: 'line3',
                  label: 'line 3',
                  children: [
                    {
                      value: 'station3',
                      label: 'station 3',
                    },
                  ],
                },
              ],
            },
          ]}
        />
      </Wrapper>
      <Wrapper>
        <EntityButton menuPosition="topRight" />
        <div className={Styles.flexWrap}>
          {Array(6)
            .fill(undefined)
            .map((_, idx) => (
              <PrismSelect
                options={[
                  { value: 'add', title: 'add' },
                  { value: 'addMore', title: 'add more' },
                  { value: 'addMoreTitles', title: 'add more titles' },
                  { value: 'erase', title: 'erase' },
                  { value: 'eraseMore', title: 'erase more' },
                  { value: 'eraseMoreTitles', title: 'erase more titles' },
                  { value: 'loremIpsum', title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.' },
                  { value: 'loremEraseMore', title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.' },
                  {
                    value: 'loremEraseMoreTitles',
                    title:
                      'Lorem ipsum dolor sit amet consectetur adipisicing elit. titlesLorem ipsum dolor sit amet consectetur adipisicing elit. titles',
                  },
                ]}
                wrapperClassName={Styles.wrappedSelect}
                className={Styles.select}
                key={idx}
                placeholder="pick an option"
              />
            ))}
          <PrismSelect
            options={[
              { value: 'add', title: 'add' },
              { value: 'addMore', title: 'add more' },
              { value: 'addMoreTitles', title: 'add more titles' },
              { value: 'erase', title: 'erase' },
              { value: 'eraseMore', title: 'erase more' },
              { value: 'eraseMoreTitles', title: 'erase more titles' },
              { value: 'loremIpsum', title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.' },
              { value: 'loremEraseMore', title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.' },
              {
                value: 'loremEraseMoreTitles',
                title:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit. titlesLorem ipsum dolor sit amet consectetur adipisicing elit. titles',
              },
            ]}
            wrapperClassName={Styles.wrappedSelect}
            className={Styles.select}
            mode="multiple"
            placeholder="pick an option"
          />
        </div>
      </Wrapper>
    </div>
  )
}

export default UiPlayground
