import React from 'react'

import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { error, success } from 'components/PrismMessage/PrismMessage'
import paths from 'paths'

import { FormValues, ResetPasswordForm } from './Establish'

export interface Props {
  token: string
}

/**
 * Renders screen for establish or setting a new password by including a "magic"
 * set password token in the request, typically parsed from the URL / qs.
 *
 * @param token - Reset password token
 * @param userId - Id of user whose password is to be reset
 */
function Activate({ token }: Props) {
  const history = useHistory()

  const handleSubmit = async (values: FormValues) => {
    const res = await service.activateAccount(token, values.password)

    if (res.type === 'success') {
      success({ title: 'Account has been activated' })
      history.replace(paths.login())
    } else if (res.type === 'error' && res.data?.code === 'password_too_common') {
      error({ title: 'Your password is too common' })
    } else {
      error({ title: "Couldn't activate account" })
    }
  }

  if (!token) {
    history.replace('/404')
    return null
  }

  return <ResetPasswordForm onSubmit={handleSubmit} />
}

export default Activate
