import React from 'react'

import Styles from './PrismElementaryCube.module.scss'

/**
 * Renders a blank state cube.
 *
 * The container fits 100% to width and height of the parent container, set a background color, and center the image.
 * The image gets 60% as the default size.
 *
 * @param shade - Switch the cube's color.
 * @param addBackground - adds a background color (default: $smokeyBlack_94).
 */
export const PrismElementaryCube = ({
  shade = 'gray',
  addBackground,
  className,
}: {
  shade?: 'gray' | 'blue'
  addBackground?: boolean
  className?: string
}) => {
  return (
    <div className={`${Styles.imageContainer} ${addBackground ? Styles.addBackground : ''} ${className ?? ''}`}>
      <svg
        className={`${Styles.image} ${shade === 'blue' ? Styles.blueCube : ''}`}
        viewBox="0 0 239 273"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_873:7)">
          <rect
            width="132.213"
            height="132.213"
            transform="matrix(0.866025 -0.5 2.20305e-08 1 119.5 136.211)"
            fill={shade === 'gray' ? 'currentColor' : '#4861FF'}
            fillOpacity={shade === 'gray' ? '0.1' : '0.3'}
          />
          <rect
            width="132.213"
            height="132.213"
            transform="matrix(0.866025 0.5 -2.20305e-08 1 5 70.104)"
            fill={shade === 'gray' ? 'currentColor' : '#314DFF'}
            fillOpacity={shade === 'gray' ? '0.04' : '0.3'}
          />
          <rect
            width="132.213"
            height="132.213"
            transform="matrix(0.866025 0.5 -0.866025 0.5 119.5 4)"
            fill={shade === 'gray' ? 'currentColor' : '#6378FF'}
            fillOpacity={shade === 'gray' ? '0.16' : '0.3'}
          />
        </g>
      </svg>
    </div>
  )
}
