import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { intersection } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { AddSignAnimation } from 'components/AddSignAnimation/AddSignAnimation'
import { Button } from 'components/Button/Button'
import GridTableHeader from 'components/GridTableHeader/GridTableHeader'
import { PrismCheckmark } from 'components/PrismCheckmark/PrismCheckmark'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import SkeletonWrapper from 'components/SkeletonWrapper/SkeletonWrapper'
import { useAllToolLabels, useQueryParams, useTrainingMetrics } from 'hooks'
import {
  AreaOfInterestConfiguration,
  RecipeExpanded,
  RoutineWithAois,
  Tool,
  ToolFlatWithCurrentExperiment,
  ToolLabel,
} from 'types'
import {
  appendDataToQueryString,
  calculatePercentage,
  getExperimentState,
  getLabelsFromPredictionLabels,
  getTimeAgoFromDate,
  getToolAoisFromRoutine,
  isRecipeOrRoutineResponseProtected,
  prepareBatchCreateUpdateDeleteBody,
  titleCase,
} from 'utils'

import { TrainingReport } from '../TrainingReport/TrainingReport'
import Styles from './ToolInfoCard.module.scss'

/**
 * Renders the table section where all the models i.e. tool versions that where trained are displayed
 * and their metrics.
 *
 * @param models - List of models to display in the table.
 * @param readOnly - Whether we're in readOnly mode.
 * @param isTrainingTab - Whether we're in the training tab.
 * @param routine - The current routine.
 * @param currentTool - The current selected tool in the left side list of Training Tab.
 */
export const ModelSection = ({
  models,
  readOnly,
  isTrainingTab,
  routine,
  recipe,
  currentTool,
}: {
  models?: ToolFlatWithCurrentExperiment[]
  readOnly: boolean
  isTrainingTab: boolean
  routine: RoutineWithAois
  recipe: RecipeExpanded
  currentTool: Tool
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [params] = useQueryParams<'reportModelId'>()

  const [showMore, setShowMore] = useState<boolean>(false)

  const currentAois = getToolAoisFromRoutine(routine, currentTool.id)

  const { allToolLabels } = useAllToolLabels()

  const ref = useRef<HTMLDivElement>(null)

  const handleClose = useCallback(() => {
    appendDataToQueryString(history, { reportModelId: undefined })
  }, [history])

  useEffect(() => {
    setShowMore(false)
  }, [currentTool.parent_id])

  useEffect(() => {
    if (!ref.current) return
    const container = ref.current

    if (showMore) {
      // To optimize system and browser resources, it is recommended to use requestAnimationFrame, which requests the browser to execute the code during the next repaint cycle. This allows the system to optimize resources and frame-rate to reduce unnecessary reflow/repaint calls.
      requestAnimationFrame(() => (container.style.height += container?.scrollHeight + 1 + 'px'))
    }

    if (!showMore && models) {
      // set the container's max height when it's collapsed.
      // setProperty is passing the CSS Custom property to the selector .tableWrapper in CSS.
      if (models.length >= 3) container.style.setProperty('--rowCount', '3')
      else if (models.length === 2) container.style.setProperty('--rowCount', '2')
      else container.style.setProperty('--rowCount', '1')

      // Stop forcing the maxHeight controlled by JS and let it run through CSS
      requestAnimationFrame(() => (container.style.height = ''))
    }
  }, [models, showMore])

  const setActiveModel = async (
    model: ToolFlatWithCurrentExperiment,
    aois: AreaOfInterestConfiguration[],
    allToolLabels: ToolLabel[] | undefined,
    newRecipeCreated?: boolean,
  ) => {
    if (aois.length === 0) return
    const modelToActivate = { ...model }
    // We need to add the current model expected labels to the new model we want to activate
    if (model.specification_name === 'match-classifier') {
      const labelsAbleToPredict = getLabelsFromPredictionLabels(
        allToolLabels || [],
        model.metadata.prediction_labels || [],
      )
      modelToActivate.inference_user_args.expected_classes = intersection(
        labelsAbleToPredict.map(label => label.id),
        currentTool.inference_user_args.expected_classes,
      )
    }

    const body = prepareBatchCreateUpdateDeleteBody(aois, modelToActivate)

    const activateModelRes = await service.batchCreateUpdateDeleteAois(body)

    if (
      isRecipeOrRoutineResponseProtected(activateModelRes, {
        routineParentId: routine.parent.id,
        history,
        recipe: recipe,
        onCreate: async (_, createdRoutine) => {
          if (!createdRoutine) return
          const newAois = getToolAoisFromRoutine(createdRoutine, currentTool.id)
          await setActiveModel(model, newAois, allToolLabels, true)
        },
      })
    ) {
      return
    }

    if (activateModelRes.type !== 'success') error({ title: 'Something went wrong while activating the model' })

    if (!newRecipeCreated) {
      await query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), { dispatch })
    }

    success({ title: `Model ${model.version ? model.version : ''} activated` })
  }

  const handleSaveThresholdSuccess = useCallback(async () => {
    await Promise.all([
      query(getterKeys.toolParent(currentTool.parent_id), () => service.getToolParent(currentTool.parent_id), {
        dispatch,
      }),
      query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), { dispatch }),
    ])
  }, [currentTool.parent_id, dispatch, recipe.id])

  const columns = getColumns({
    readOnly,
    isTrainingTab,
    activeModel: currentTool,
    setActiveModel,
    aois: currentAois,
    allToolLabels,
  })

  return (
    <section className={Styles.modelsSection}>
      <h4 className={Styles.sectionTitle}>Models</h4>
      <div className={Styles.sectionBody}>
        <div className={Styles.tableWrapper} ref={ref}>
          {models && !models.length && (
            <div className={Styles.emptyModelList}>Label and train to create your first model</div>
          )}
          {!models && <LoadingTable columns={5} className={Styles.loadingTableRow} />}

          {models && (
            <>
              <GridTableHeader columns={columns} size="small" className={Styles.gridTableHeader} />
              <Table
                rowKey="id"
                className={Styles.tableContainer}
                rowClassName={`${Styles.tableRow} ${readOnly ? Styles.tableIsDisabled : ''}`}
                columns={columns}
                dataSource={models}
                pagination={false}
                showHeader={false}
                onRow={model => {
                  return {
                    onClick: () => {
                      model.currentExperiment?.state === 'successful' &&
                        !readOnly &&
                        appendDataToQueryString(history, { reportModelId: model.id })
                    },
                  }
                }}
              />
            </>
          )}
        </div>

        {models && models.length > 3 && (
          <div className={`${Styles.buttonRowWrapper} ${!showMore ? Styles.collapse : ''}`}>
            <Button
              badge={<AddSignAnimation isActive={showMore} className={Styles.addSign} />}
              type="tertiary"
              size="small"
              className={Styles.showMoreButton}
              onClick={() => {
                setShowMore(!showMore)
              }}
              wide
            >
              show {showMore ? 'less' : 'more'}
            </Button>
          </div>
        )}
      </div>

      {params.reportModelId && (
        <TrainingReport
          key={params.reportModelId}
          onClose={handleClose}
          toolId={params.reportModelId}
          onSaveThresholdSuccess={handleSaveThresholdSuccess}
          toolWithOverridesContext={params.reportModelId === currentTool.id ? currentTool : undefined}
        />
      )}
    </section>
  )
}

export const LoadingTable = ({ columns, className }: { columns: number; className?: string }) => {
  return (
    <div className={className ?? ''}>
      {Array(columns)
        .fill(undefined)
        .map((_, idx) => (
          <div key={idx}>
            <PrismSkeleton />
          </div>
        ))}
    </div>
  )
}

const getColumns = ({
  readOnly,
  isTrainingTab,
  setActiveModel,
  activeModel,
  aois,
  allToolLabels,
}: {
  readOnly: boolean
  isTrainingTab: boolean
  setActiveModel: (
    model: ToolFlatWithCurrentExperiment,
    aois: AreaOfInterestConfiguration[],
    allToolLabels: ToolLabel[] | undefined,
  ) => Promise<void>
  activeModel?: Tool
  aois: AreaOfInterestConfiguration[]
  allToolLabels: ToolLabel[] | undefined
}) => {
  const columns: ColumnsType<ToolFlatWithCurrentExperiment> = [
    {
      title: 'Model',
      key: 'model',
      render: (_, model) => (
        <SkeletonWrapper loading={getExperimentState(model.currentExperiment?.state) === 'in_progress'}>
          <>{model.version ? `${model.version}` : '--'}</>
        </SkeletonWrapper>
      ),
      ellipsis: true,
    },
    {
      title: 'Trained',
      key: 'lastTrained',
      render: (_, model) => {
        const state = getExperimentState(model.currentExperiment?.state)
        if (state === 'successful' && model.currentExperiment)
          return titleCase(getTimeAgoFromDate(model.currentExperiment?.updated_at).text, true)
        if (state === 'in_progress') return 'Training ...'
        return 'Training Error'
      },
      ellipsis: true,
    },
    {
      title: 'Accuracy',
      dataIndex: 'accuracy',
      key: 'accuracy',
      render: (_, model) => (
        <MetricRender
          tool={model}
          mode="accuracy"
          loading={getExperimentState(model.currentExperiment?.state) === 'in_progress'}
        />
      ),
      ellipsis: true,
    },
    {
      title: 'Images',
      dataIndex: 'trained_images',
      key: 'images',
      render: (_, model) => (
        <MetricRender
          tool={model}
          mode="trained_images"
          loading={getExperimentState(model.currentExperiment?.state) === 'in_progress'}
        />
      ),
      ellipsis: true,
    },
  ]

  if (isTrainingTab) {
    columns.push({
      dataIndex: 'checkmark',
      key: 'checkmark',
      render: (_, model) => {
        if (model.currentExperiment?.state !== 'successful') return null
        const isActiveModel = model.id === activeModel?.id
        return (
          <PrismCheckmark
            checked={isActiveModel}
            disabled={readOnly}
            onChecked={(_, event) => {
              event.preventDefault()
              event.stopPropagation()
              if (isActiveModel) return

              setActiveModel(model, aois, allToolLabels)
            }}
            tooltipDescription={isActiveModel ? 'Active model' : 'Activate model'}
            placement="bottomRight"
          />
        )
      },
    })
  }

  return columns
}

const MetricRender = ({
  tool,
  mode,
  loading,
}: {
  tool: ToolFlatWithCurrentExperiment
  mode: 'accuracy' | 'trained_images'
  loading: boolean
}) => {
  const toolTrainingMetrics = tool.currentExperiment?.state === 'successful' && tool.metadata.training_metrics
  const trainingMetrics = useTrainingMetrics(
    Object.keys(toolTrainingMetrics || {}).length
      ? undefined
      : tool.currentExperiment?.state !== 'successful'
      ? undefined
      : tool,
  )

  const { succesful, total } = trainingMetrics || toolTrainingMetrics || {}

  let value: React.ReactNode = '--'
  if (mode === 'accuracy') value = `${calculatePercentage(succesful!, total).toFixed(1)}%`
  if (mode === 'trained_images' && total) value = total

  return (
    <SkeletonWrapper loading={loading}>
      <>{value}</>
    </SkeletonWrapper>
  )
}
