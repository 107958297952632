import Konva from 'konva'

import { AreaOfInterestConfiguration, MaxBounds } from 'types'

export interface EditableShapeProps {
  aoi: AreaOfInterestConfiguration // We should always make sure to pass {...aoi} to calls to onUpdateAoiWithShap
  isInactive?: boolean
  disableTransform?: boolean
  onUpdateAoiWithShape: (shape: AreaOfInterestConfiguration) => any
  onSelect: (shape: AreaOfInterestConfiguration) => any
  scaling: number
  bounds: MaxBounds
  deselectAois: (stage: Konva.Stage) => void
}

export const ANCHOR_SIZE = 8
export const HANDLE_STROKE_WIDTH = 1
export const HANDLE_FILL = 'rgba(254, 254, 254, 1)'
export const HANDLE_STROKE = 'rgba(21, 99, 226, 1)'
export const BORDER_STROKE_WIDTH = 1
export const ACTIVE_STROKE = 'rgba(21, 99, 226, 1)'
export const SHADOW_STROKE_WIDTH = 3
export const SHADOW_HOVER_STROKE_WIDTH = 5
export const SHADOW_STROKE = 'rgba(0,0,0,0.07)'
export const SHADOW_HOVER_STROKE = 'rgba(0, 0, 0, .15)'
export const INACTIVE_STROKE = 'rgba(201, 201, 201, 1)'
export const ACTIVE_STROKE_WIDTH = 1
export const INACTIVE_STROKE_WIDTH = 1
export const CORNER_RADIUS = 0

export const VERTEX_FILL = 'rgba(254, 254, 254, 1)'
export const VERTEX_HOVER_FILL = 'rgba(254, 254, 254, 1)'
export const VERTEX_HOVER_STROKE = 'rgba(254, 254, 254, 1)'
export const BOUNDARY_STROKE_WIDTH = 3
export const BOUNDARY_STROKE_HIT_WIDTH = 10
export const VERTEX_SELECTED_STROKE = 'rgba(254, 254, 254, 1)'
export const VERTEX_SELECTED_FILL = 'rgba(21, 99, 226, 1)'
export const BOUNDARY_SELECTED_STROKE = 'rgba(49, 77, 255, 1)'
export const BOUNDARY_UNSELECTED_STROKE = 'rgba(254, 254, 254, 1)'

export const VERTEX_STROKE_WIDTH = 1
export const VERTEX_DEFAULT_RADIUS = 4
export const VERTEX_SELECTED_RADIUS = 5
export const VERTEX_MOUSEOVER_RADIUS = 7
export const VERTEX_STROKE = 'rgba(49, 77, 255, 1)'
