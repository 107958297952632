import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { debounce, groupBy, isNil } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'

import { getterKeys } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Divider } from 'components/Divider/Divider'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import { PrismArchiveIcon, PrismGearIcon, PrismSearchIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import { useData, useQueryEntityFromSites, useQueryParams } from 'hooks'
import AddOrEditSiteModal from 'pages/StationDetail/Components/EntityModals/AddOrEditSiteModal'
import { Site, StationForSite, SubSiteExpanded, WithSiteId } from 'types'
import { appendDataToQueryString, getSiteName, matchRole, sortByName } from 'utils'
import { INDEPENDENT_STATION_LINE_NAME } from 'utils/constants'

import Styles from '../Inspect.module.scss'
import { SectionHeader } from '../InspectSites'
import ArchiveEntityModal from './ArchiveSlsModal'
import { ArchivedSitesLinesAndStations } from './ArchiveView'
import { LineAccordion } from './LinesList'

interface SiteDetailsProps {
  selectedSite: Site | undefined
  onStationSelect: (stationId: string | undefined) => void
  showArchive: boolean
}

/**
 * Renders the Site Details
 *
 * @param selectedSite - The selected site item
 * @param sitesList - Takes the data from the list to display the data from the selected site
 * @param props.SelectedStation - Modifies the layout to show the stationPreview section
 *
 */

type FilteredLine = WithSiteId<SubSiteExpanded & { stations: WithSiteId<StationForSite>[] | undefined }>

const SiteDetails = ({ selectedSite, showArchive, onStationSelect }: SiteDetailsProps) => {
  const { state: locationState } = useLocation<{ lineIdToExpand?: string }>()
  const history = useHistory()
  const lineIdToExpand = locationState?.lineIdToExpand
  const [params] = useQueryParams<'stationId'>()
  const me = useData(getterKeys.me())

  const [showArchiveModal, setShowArchiveModal] = useState<boolean>()
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>()
  const [searchText, setSearchText] = useState<string>()
  const [filteredLines, setFilteredLines] = useState<FilteredLine[]>()
  const [independentStations, setIndependentStations] = useState<WithSiteId<StationForSite>[]>()
  const [linesOpen, setLineOpen] = useState<(string | null)[] | null>(null)
  const [searchInputOpened, setSearchInputOpened] = useState(false)

  const lines = useQueryEntityFromSites({
    entity: 'line',
    siteId: selectedSite?.id,
    filterFn: line => !line.is_deleted,
  })?.sort(sortByName)
  const stations = useQueryEntityFromSites({
    entity: 'station',
    siteId: selectedSite?.id,
    filterFn: station => !station.is_deleted,
  })
  const stationSubtype = useQueryEntityFromSites({
    entity: 'subSiteType',
    siteId: selectedSite?.id,
    filterFn: subSiteType => subSiteType.normalized_name === 'station',
  })?.[0]

  // Remove search text when switching to archvie view
  useEffect(() => {
    setSearchText('')
  }, [showArchive])

  const stationsByLineId = useMemo(() => groupBy(stations, station => station.belongs_to_id), [stations])
  const stationsWithNoLine = stationsByLineId['null']

  const setLinesDefaultValues = useCallback(() => {
    const filteredLines = lines?.map(line => ({ ...line, stations: stationsByLineId[line.id] }))
    setFilteredLines(filteredLines)
    setIndependentStations(stationsWithNoLine)

    if (lineIdToExpand !== undefined) {
      // null represents independent station
      const lineToOpen = lineIdToExpand === null ? null : lines?.find(line => line.id === lineIdToExpand)?.id
      // We don't want to persist this state after expanding the line, so we need to remove it
      setLineOpen(lineToOpen !== undefined ? [lineToOpen] : [])
      // setTimeout used to let animation and scroll logic run before removing it from the location state
      setTimeout(() => {
        appendDataToQueryString(history, {}, { lineIdToExpand: undefined })
      }, 0)
      return
    }

    // Open first line
    if (!linesOpen?.length && searchInputOpened === false) {
      const stationWithLine = params.stationId && stations?.find(station => station.id === params.stationId)
      if (stationWithLine) return setLineOpen([stationWithLine.belongs_to_id])

      if (lines?.[0]) return setLineOpen([lines[0].id])
      setLineOpen([null])
    }
  }, [
    history,
    lineIdToExpand,
    lines,
    linesOpen?.length,
    params.stationId,
    searchInputOpened,
    stations,
    stationsByLineId,
    stationsWithNoLine,
  ])

  function handleLinesOpenState(lineId: string | null, isOpen: boolean) {
    if (!linesOpen) return
    if (isOpen) return setLineOpen([...linesOpen, lineId])
    return setLineOpen(linesOpen.filter(openLineId => openLineId !== lineId))
  }

  const getFilteredLines = useMemo(
    () =>
      debounce((searchValue: string | undefined) => {
        if (!searchValue) {
          // Restore default lines state when user is no longer searching
          return setLinesDefaultValues()
        }

        const lowerCasedSearch = searchValue.toLowerCase()
        const matchedLines: FilteredLine[] = []
        const openLines: (string | null)[] = []

        lines?.forEach(line => {
          const lineName = line.name.toLowerCase()
          const stationsInLine = stationsByLineId[line.id]
          const matchedStations = stationsInLine?.filter(station =>
            station.name.toLowerCase().includes(lowerCasedSearch),
          )
          const lineMatches = lineName.includes(lowerCasedSearch)

          if (!matchedStations?.length && !lineMatches) return

          if (lineMatches) return matchedLines.push({ ...line, stations: stationsInLine })

          if (matchedStations?.length) {
            openLines.push(line.id)
            return matchedLines.push({ ...line, stations: matchedStations })
          }
        })

        // Line for independent stations
        const matchedIndependentStations = stationsWithNoLine?.filter(station =>
          station.name.toLowerCase().includes(lowerCasedSearch),
        )
        const independentStationLineMatches = INDEPENDENT_STATION_LINE_NAME.toLowerCase().includes(lowerCasedSearch)
        const independentStationLineOpen = !!matchedIndependentStations?.length

        if (independentStationLineOpen) openLines.push(null)

        setLineOpen(prevOpenLines => (prevOpenLines ? [...prevOpenLines, ...openLines] : [...openLines]))
        setFilteredLines(matchedLines)
        setIndependentStations(
          independentStationLineMatches
            ? stationsWithNoLine || []
            : matchedIndependentStations?.length
            ? matchedIndependentStations
            : undefined,
        )
      }, 300),
    [lines, setLinesDefaultValues, stationsByLineId, stationsWithNoLine],
  )

  function handleOptionMenuClick(option: 'settings' | 'archive') {
    if (option === 'settings') setShowSettingsModal(true)
    if (option === 'archive') setShowArchiveModal(true)
  }
  // This useEffect triggers each time we change the `Sites` redux branch, so we need to be careful when we update the branch to not cause any weird behaviour
  useEffect(() => {
    getFilteredLines(searchText)
    // eslint-disable-next-line
  }, [searchText, stations, lines, lineIdToExpand])

  // In this state the user can't do anything so show a special message for them.
  const userIsBlocked = !matchRole(me, 'manager') && stations && stations.length === 0

  return (
    <section className={Styles.siteDetailsSection}>
      <SectionHeader
        title={showArchive ? 'Archive' : selectedSite?.name || ''}
        subtitle={
          !showArchive && (
            <PrismOverflowTooltip
              tooltipPlacement="bottom"
              content={selectedSite ? getSiteName(selectedSite) : '--'}
              textClassName={Styles.siteDetailsHeaderLocation}
            />
          )
        }
        icons={
          <>
            {!showArchive && matchRole(me, 'manager') && (
              <OptionMenu
                buttonDisabled={!selectedSite}
                options={[
                  {
                    value: 'settings',
                    badge: <PrismGearIcon />,
                  },
                  { value: 'archive', title: 'Archive', badge: <PrismArchiveIcon /> },
                ]}
                onMenuItemClick={handleOptionMenuClick}
                openWithClick
                iconButtonType="tertiary"
                position="bottomLeft"
                className={`${Styles.menu}`}
                menuContainerClassName={Styles.menuContainer}
                iconButtonClassName={Styles.overflowMenuIcon}
                menuWidth={216}
              />
            )}

            <PrismSearchInput
              disabled={!showArchive && !selectedSite}
              buttonDisabled={!showArchive && !selectedSite}
              inputDataTestId="site-search-input"
              buttonDataTestId="site-search-button"
              value={searchText}
              onSearchButtonClick={showInput => {
                setSearchInputOpened(showInput)
                setSearchText('')
              }}
              onInputChange={e => {
                setSearchText(e.target.value)
              }}
              addAnimation
              className={Styles.siteDetailsSearchField}
            />
          </>
        }
      />

      <Divider />

      {userIsBlocked && (
        <div className={Styles.emptyStation}>No Assigned Stations. Please contact your manager for more details.</div>
      )}

      {!userIsBlocked && (
        <ul className={Styles.linesList}>
          {!showArchive && selectedSite && (
            <>
              {!filteredLines && <PrismLoader />}
              {filteredLines &&
                filteredLines.map(line => (
                  <li key={line.id}>
                    <LineAccordion
                      dataTestId={`${selectedSite.name}-${line.name}`}
                      stations={line.stations}
                      label={line.name}
                      isSearching={!!searchText}
                      line={line}
                      location={[`${selectedSite.id}_${stationSubtype?.id}`, line.id]}
                      onStationSelect={onStationSelect}
                      isOpen={!!linesOpen?.find(lineId => lineId === line.id)}
                      setIsOpen={handleLinesOpenState}
                    />
                  </li>
                ))}
              {(searchText && !!independentStations && filteredLines) || (!searchText && filteredLines) ? (
                <LineAccordion
                  dataTestId={`${selectedSite.name}-null`}
                  label={INDEPENDENT_STATION_LINE_NAME}
                  isSearching={!!searchText}
                  stations={independentStations}
                  location={[`${selectedSite.id}_${stationSubtype?.id}`, null]}
                  onStationSelect={onStationSelect}
                  className={Styles.lineOtherStations}
                  isOpen={!!linesOpen && linesOpen.findIndex(lineId => lineId === null) !== -1}
                  setIsOpen={handleLinesOpenState}
                />
              ) : (
                <> </>
              )}
            </>
          )}
          {showArchive && <ArchivedSitesLinesAndStations searchText={searchText} />}

          {searchText && !filteredLines?.length && !showArchive && isNil(independentStations) && (
            <GenericBlankStateMessage
              dataTestId="empty-results-state"
              description="No results match your search"
              header={<PrismSearchIcon />}
              className={Styles.noMatchWrapper}
            />
          )}
        </ul>
      )}

      {showSettingsModal && selectedSite && (
        <AddOrEditSiteModal isEditMode onClose={() => setShowSettingsModal(false)} entity={selectedSite} />
      )}

      {showArchiveModal && selectedSite && (
        <ArchiveEntityModal entityType="site" entityId={selectedSite.id} onClose={() => setShowArchiveModal(false)} />
      )}
    </section>
  )
}

export default SiteDetails
