import React from 'react'

import { Controller, useForm } from 'react-hook-form'

import { getterKeys, service } from 'api'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { SearchableSelect } from 'components/SearchableSelect/SearchableSelect'
import { Token } from 'components/Token/Token'
import Shared from 'styles/Shared.module.scss'

import Styles from './RecipesList.module.scss'

export type RenameRecipeModalProps = {
  recipeParentId: string
  componentId: string
  onClose: () => void
  onSuccess: () => void
}

const ChangeRecipeProductModal = ({ recipeParentId, componentId, onClose, onSuccess }: RenameRecipeModalProps) => {
  const defaultValues = { componentId }
  const {
    formState: { isDirty, isValid },
    control,
    trigger,
    getValues,
  } = useForm({ defaultValues, mode: 'onChange' })

  const handleSave = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const valid = await trigger()
    if (!valid) return

    const { componentId } = getValues()

    const res = await service.updateRecipeParent(recipeParentId, {
      component_id: componentId,
    })

    if (res.type !== 'success') {
      return error({ title: 'There was an error changing the product, please try again' })
    }

    onSuccess()
    success({ title: 'Product changed', 'data-testid': 'change-product-recipe-notification' })
    onClose()
  }

  return (
    <Modal
      id="change-product"
      header="Change Product"
      headerClassName={Styles.recipeModalHeader}
      onClose={onClose}
      onOk={handleSave}
      disableSave={!isDirty || !isValid}
      okText="Save"
      data-testid="change-product-recipe-modal"
      size="largeSimpleForm"
      modalFooterClassName={Styles.recipeModalFooter}
      modalBodyClassName={Shared.verticalChildrenGap8}
    >
      <form onSubmit={handleSave}>
        <Token label="product" className={Styles.selectWrapper}>
          <Controller
            name="componentId"
            rules={{ required: 'Required' }}
            control={control}
            render={({ value, onChange }) => (
              <SearchableSelect
                hideDashEmptyOption
                value={value}
                onSelect={id => onChange(id)}
                size="large"
                placeholder="Select product"
                getterKey={getterKeys.components('select-options')}
                fetcher={searchValue => service.getComponents({ name: searchValue, is_deleted: false })}
                missingOptionFetcher={id => service.getComponent(id)}
                formatter={component => component.name}
                data-test="change-product-recipe-option"
                data-testid="change-product-recipe-select"
              />
            )}
          />
        </Token>
      </form>

      <div className={Styles.caption}>
        Changing a recipe's product will only affect future inspections. Historical results are preserved.
      </div>
    </Modal>
  )
}

export default ChangeRecipeProductModal
