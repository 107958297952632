import 'antd/dist/antd.less'
import 'styles/index.scss'

import React, { useMemo } from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { includes } from 'lodash'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { ConfigContext as QueryConfigContext } from 'react-redux-query'
import { BrowserRouter, match, Redirect, Route, Switch } from 'react-router-dom'

import { getterKeys } from 'api/dataKeys'
import ItemUploader from 'components/ItemUploader/ItemUploader'
import NewBatch from 'components/NewBatch/NewBatch'
import OnMountApp from 'components/OnMountApp'
import { PlcControl } from 'components/PlcControl/PlcControl'
import { AUTH0_AUDIENCE, AUTH0_CLIENTID, AUTH0_DOMAIN, ENABLE_PLC_CONTROL, ENABLE_UI_PLAYGROUND, NODE_ENV } from 'env'
import { useData, useQueryParams } from 'hooks'
import Administration from 'pages/Administration/Administration'
import AnalyzeBase from 'pages/Analyze/AnalyzeBase'
import CameraStatus from 'pages/CameraStatus/CameraStatus'
import Activate from 'pages/Establish/Activate'
import Establish from 'pages/Establish/Establish'
import Login from 'pages/Login/Login'
import NotFound from 'pages/NotFound/NotFound'
import Notify from 'pages/Notify/Notify'
import PrivateRoute from 'pages/PrivateRoute'
import ToolScreen from 'pages/RoutineOverview/Configure/ToolScreen'
import LabelingScreen from 'pages/RoutineOverview/LabelingScreen/LabelingScreen'
import RecipeOverview from 'pages/RoutineOverview/RecipeOverview'
import SendResetPasswordEmail from 'pages/SendResetPasswordEmail/SendResetPasswordEmail'
import Settings from 'pages/Settings/Settings'
import StationDetailRoot from 'pages/StaticInspector/StationDetailRoot'
import ColocatedStationRedirect from 'pages/StationList/ColocatedStationRedirect'
import UiPlayground from 'pages/UiPlayground/UiPlayground'
import paths from 'paths'
import typedStore, { TypedStore } from 'rdx/store'
import {
  AccountSettingsMode,
  accountSettingsModes,
  AdministrationSettingsMode,
  administrationSettingsModes,
  AnalyzeTab,
  analyzeTabs,
  InspectTabs,
  inspectTabs,
  LabelingScreenMode,
  NotifyMode,
  notifyModes,
  RecipeOverviewMode,
  recipeOverviewModes,
  StationDetailMode,
  stationDetailModes,
  stationDetailPrivateModes,
} from 'types'
import { matchRole } from 'utils'

if (NODE_ENV === 'production') {
  // https://docs.sentry.io/platforms/javascript/react/
  Sentry.init({
    dsn: 'https://f25bcc97355041b4bf4eb18b0ac6ae59@o272992.ingest.sentry.io/1486906',
    // ResizeObserver error can be safely ignored to reduce log spam: https://stackoverflow.com/a/66260608
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    environment: NODE_ENV,
    // Reduce payload size
    maxBreadcrumbs: 30,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'console' && breadcrumb.level !== 'error') return null
      return breadcrumb
    },
  })
}

const AdministrationWrapper = ({ match }: { match: match<{ mode: AdministrationSettingsMode }> }) => {
  const { mode } = match.params

  if (!administrationSettingsModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.administrationSettings({ mode: 'users' }) }} />
  }
  return <Administration />
}

const InspectWrapper = ({ match }: { match: match<{ mode: InspectTabs }> }) => {
  const { mode } = match.params

  if (!inspectTabs.includes(mode)) {
    return <Redirect to={{ pathname: paths.inspect({ mode: 'site' }) }} />
  }

  return <ColocatedStationRedirect inspectTab={mode} />
}

const NewBatchWrapper = ({ match }: { match?: match<{ stationId: string }> }) => {
  return <NewBatch stationId={match?.params.stationId} />
}

const PlcControlWrapper = ({ match }: { match?: match<{ stationId: string }> }) => {
  return <PlcControl stationId={match?.params.stationId} />
}

const EstablishWrapper = ({ match }: { match: match<{ userId: string; token: string }> }) => {
  return <Establish userId={match.params.userId} token={match.params.token} />
}

const ActivateWrapper = ({ match }: { match: match<{ token: string }> }) => {
  return <Activate token={match.params.token} />
}

const AnalyzeWrapper = ({ match }: { match: match<{ mode: AnalyzeTab }> }) => {
  const { mode } = match.params

  if (!analyzeTabs.includes(mode)) {
    return <Redirect to={{ pathname: paths.analyze({ mode: 'overview' }) }} />
  }
  return <AnalyzeBase />
}

const NotifyWrapper = ({ match }: { match: match<{ mode: NotifyMode }> }) => {
  const { mode } = match.params

  if (!notifyModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.settingsNotify({ mode: 'subscriptions' }) }} />
  }
  return <Notify />
}

const AccountSettingsWrapper = ({ match }: { match: match<{ mode: AccountSettingsMode }> }) => {
  const { mode } = match.params

  if (!accountSettingsModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.accountSettings({ mode: 'profile' }) }} />
  }
  return <Settings />
}

const RecipeOverviewWrapper = ({ match }: { match: match<{ recipeParentId: string; mode: RecipeOverviewMode }> }) => {
  const { recipeParentId, mode } = match.params

  if (!recipeOverviewModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.settingsRecipe(recipeParentId, 'configure') }} />
  }
  return <RecipeOverview recipeParentId={recipeParentId} mode={mode} />
}

const StationDetailWrapper = ({ match }: { match: match<{ stationId: string; mode: StationDetailMode }> }) => {
  const [params] = useQueryParams()
  const me = useData(getterKeys.me())

  const { mode, stationId } = match.params
  const historicInspectionId = useMemo(() => params.historicInspectionId, [params])

  // Allow devs to check out inspection screens without running any robots (not allowed in production)
  if (!stationId && NODE_ENV === 'production') return <Redirect to={{ pathname: paths.inspect({ mode: 'site' }) }} />

  if (!stationDetailModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.stationDetail('overview', stationId) }} />
  }

  if (includes(stationDetailPrivateModes, mode) && !matchRole(me, 'manager')) {
    return <Redirect to={{ pathname: paths.stationDetail('overview', stationId) }} />
  }

  return <StationDetailRoot stationId={stationId} mode={mode} historicInspectionId={historicInspectionId} />
}
const LabelingScreenWrapper = ({ match }: { match: match<{ toolParentId: string; mode: LabelingScreenMode }> }) => {
  const { toolParentId, mode } = match.params
  if (['gallery', 'focus'].includes(mode)) {
    return <LabelingScreen toolParentId={toolParentId} mode={mode} />
  }
  return <Redirect to={{ pathname: paths.labelingScreen(toolParentId, 'gallery') }} />
}

const ToolScreenWrapper = ({ match }: { match: match<{ recipeId: string; toolId: string }> }) => {
  const { recipeId, toolId } = match.params
  return <ToolScreen recipeId={recipeId} toolId={toolId} />
}

const RedirectToStations = () => {
  return <Redirect to={{ pathname: paths.inspect({ mode: 'site' }) }} />
}

/**
 * Renders all routes in app. Separate from `App` to facilitate testing.
 */
export function Routes() {
  return (
    <>
      <OnMountApp />

      {/* Ensure clicking outside of virtual keyboard dismisses it */}
      <div
        onClick={() => {
          const keyboard = document.getElementById('virtualKeyboardChromeExtensionOverlayScrollExtend')
          if (keyboard?.style) keyboard.style.display = 'none'
        }}
      >
        <Switch>
          <Route exact path={paths.login()} component={Login} />
          <Route exact path={paths.resetPassword(':userId', ':token')} component={EstablishWrapper} />
          <Route exact path={paths.activateUser(':token')} component={ActivateWrapper} />
          <Route exact path={paths.resetPasswordEmail()} component={SendResetPasswordEmail} />
          {ENABLE_UI_PLAYGROUND && <Route exact path={paths.uiPlayground()} component={UiPlayground} />}

          <PrivateRoute exact path="/" component={RedirectToStations} />

          <PrivateRoute exact path={paths.inspect({ mode: ':mode' })} component={InspectWrapper} />

          {ENABLE_PLC_CONTROL && (
            <PrivateRoute exact path={paths.plcControl(':stationId?')} component={PlcControlWrapper} />
          )}

          <PrivateRoute exact path={paths.newBatch(':stationId?')} component={NewBatchWrapper} />

          <PrivateRoute
            exact
            path={paths.analyze({ mode: ':mode' })}
            component={AnalyzeWrapper}
            requiredRole="manager"
          />

          <PrivateRoute
            exact
            path={paths.administrationSettings({ mode: ':mode' })}
            component={AdministrationWrapper}
            requiredRole="manager"
          />

          <PrivateRoute exact path={paths.accountSettings({ mode: ':mode' })} component={AccountSettingsWrapper} />

          <PrivateRoute
            exact
            path={paths.settingsNotify({ mode: ':mode' })}
            component={NotifyWrapper}
            requiredRole="manager"
          />

          <PrivateRoute
            exact
            path={paths.settingsRecipe(':recipeParentId', ':mode')}
            component={RecipeOverviewWrapper}
            requiredRole="manager"
          />

          <PrivateRoute exact path={paths.stationDetail(':mode', ':stationId')} component={StationDetailWrapper} />

          <PrivateRoute exact path={paths.settingsTool(':recipeId', ':toolId')} component={ToolScreenWrapper} />

          <PrivateRoute exact path={paths.labelingScreen(':toolParentId', ':mode')} component={LabelingScreenWrapper} />

          <PrivateRoute exact path={paths.cameraStatus(':robotId')} component={CameraStatus} />

          {/* If there are no matches with routes above, render 404 page */}
          <Route component={NotFound} />
        </Switch>
      </div>
    </>
  )
}

/**
 * Renders component at root of render tree. Mounts our Router, all routes, and
 * connects everything to Redux.
 *
 * @param store - Redux store instance for app
 */
const App = ({ store }: { store?: TypedStore }) => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENTID}
      audience={AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
    >
      <Provider store={store || typedStore}>
        <ItemUploader />
        <Toaster />

        <QueryConfigContext.Provider value={{ branchName: 'getter', catchError: false }}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </QueryConfigContext.Provider>
      </Provider>
    </Auth0Provider>
  )
}

export default App
