import React, { useEffect, useState } from 'react'

import { isNil } from 'lodash'

import { IconButton } from 'components/IconButton/IconButton'
import OverflowingTooltip from 'components/OverflowingTooltip/OverflowingTooltip'
import { PrismResetIcon } from 'components/prismIcons'
import { PrismInputNumber } from 'components/PrismInput/PrismInput'
import { PrismSlider, PrismSliderRange } from 'components/PrismSlider/PrismSlider'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { RecipeRoutineExpanded, Threshold, ToolSpecificationName, UserFacingThreshold } from 'types'
import { getThresholdFromUserFacingValues, isThresholdFromGARTool } from 'utils'

import { getThresholdValue, isUserFacingThresholdValid, ThresholdErrorMessage } from '../ThresholdSideBar'
import { THRESHOLD_MAX, THRESHOLD_MIN, THRESHOLD_STEP } from '../TrainingReport'
import Styles from './ViewThresholdSlider.module.scss'

interface Props {
  view: RecipeRoutineExpanded
  containerIsRendered: boolean
  toolSpecificationName: ToolSpecificationName
  viewThreshold: Threshold | undefined
  defaultIsViewThresholdOverriden?: boolean
  updateViewThreshold: (routineParentId: string, threshold: UserFacingThreshold) => void
  resetViewThreshold: (routineParentId: string) => void
  forcedView: boolean
  toolId: string
}

/**
 * Renders a Recipe slider, with an input, title and subtitle.
 *
 * @param title - title in the slider. the order should be recipe - view
 * @param subtitle - subtitle in the slider. the order should be product - station
 * @param overrideSharedThreshold - boolean that shows or hides the reset button.
 * @param isModalLoaded - This value helps with the OverflowingTooltip calculations.
 * @param className - optional and external string for the styles.
 * @param onChange - Callback function that is fired when the user changes the slider's value
 *     by the button
 * @param onAfterChange - Fires when onmouseup is fired
 * @param onClick - click handler for when clicking the reset button
 * @param step - The granularity the slider can step through values
 *
 * @param view - Current view
 * @param containerIsRendered - Whether the container is already rendered (eg. a modal)
 * @param viewThreshold - Threshold for this view
 * @param isViewThresholdOverriden - Whether the threshold for this view is overriden
 * @param updateViewThreshold - Handler to update a single view threshold
 * @param resetViewThreshold - Handler to reset a threshold if it is overriden
 * @param forcedView - If provided, this view will be used instead of fetching all the views
 */
const ViewThresholdSlider = ({
  view,
  containerIsRendered,
  toolSpecificationName,
  viewThreshold,
  defaultIsViewThresholdOverriden,
  updateViewThreshold,
  resetViewThreshold,
  forcedView,
  toolId,
}: Props) => {
  const recipeParent = view.recipe.parent

  const [localThreshold, setLocalThreshold] = useState(viewThreshold)
  const [isViewThresholdOverriden, setIsViewThresholdOverriden] = useState(defaultIsViewThresholdOverriden)

  // Sync with the outside value in case the view changed
  useEffect(() => {
    setIsViewThresholdOverriden(defaultIsViewThresholdOverriden)
  }, [defaultIsViewThresholdOverriden])

  // Update the local threshold only if view is not overriden
  useEffect(() => {
    if (localThreshold !== undefined && isViewThresholdOverriden) return
    setLocalThreshold(viewThreshold)
  }, [isViewThresholdOverriden, localThreshold, viewThreshold])

  // Reset the local threshold as undefined whenever the tool id changes
  useEffect(() => {
    setLocalThreshold(undefined)
  }, [toolId])

  const handleChangeThreshold = (value: UserFacingThreshold) => {
    const updatedThreshold = getThresholdFromUserFacingValues(value, toolSpecificationName)
    setLocalThreshold(updatedThreshold)
    setIsViewThresholdOverriden(true)
  }

  const showThresholdError = isNil(localThreshold) ? false : !isUserFacingThresholdValid(localThreshold)

  const station = recipeParent.station!

  return (
    <>
      <div className={Styles.sliderItemContainer}>
        {localThreshold && isThresholdFromGARTool(localThreshold) && (
          <PrismSliderRange
            label={
              <>
                <OverflowingTooltip
                  label={{ parent: recipeParent.name, child: view.routine.parent.name }}
                  containerIsRendered={containerIsRendered}
                />

                <SliderSubtitle
                  label={{
                    parent: recipeParent.component_name,
                    child: station.belongs_to
                      ? `${station.name}, ${station.belongs_to.name}, ${station.site_name} `
                      : `${station.name}, ${station.site_name}`,
                  }}
                />
              </>
            }
            sliderColor="amber"
            valueStart={localThreshold.userFacingLowerThreshold}
            valueEnd={localThreshold.userFacingUpperThreshold}
            min={THRESHOLD_MIN}
            max={THRESHOLD_MAX}
            step={THRESHOLD_STEP}
            onChange={(v: [number, number]) => {
              handleChangeThreshold({
                userFacingLowerThreshold: getThresholdValue(v[0]),
                userFacingUpperThreshold: getThresholdValue(v[1]),
              })
            }}
            onAfterChange={(v: [number, number]) => {
              updateViewThreshold(view.routine.parent.id, {
                userFacingLowerThreshold: getThresholdValue(v[0]),
                userFacingUpperThreshold: getThresholdValue(v[1]),
              })
            }}
            inputBoxLeft={
              <PrismInputNumber
                min={THRESHOLD_MIN}
                max={THRESHOLD_MAX}
                step={THRESHOLD_STEP}
                value={localThreshold.userFacingLowerThreshold}
                precision={2}
                name="threshold"
                onChange={(v: number) => {
                  const value = getThresholdValue(v)
                  handleChangeThreshold({ ...localThreshold, userFacingLowerThreshold: value })
                  updateViewThreshold(view.routine.parent.id, { ...localThreshold, userFacingLowerThreshold: value })
                }}
                size="small"
              />
            }
            inputBoxRight={
              <PrismInputNumber
                min={THRESHOLD_MIN}
                max={THRESHOLD_MAX}
                step={THRESHOLD_STEP}
                value={localThreshold.userFacingUpperThreshold}
                precision={2}
                name="threshold"
                onChange={(v: number) => {
                  const value = getThresholdValue(v)
                  handleChangeThreshold({ ...localThreshold, userFacingUpperThreshold: value })
                  updateViewThreshold(view.routine.parent.id, { ...localThreshold, userFacingUpperThreshold: value })
                }}
                size="small"
              />
            }
          />
        )}
        {localThreshold && !isThresholdFromGARTool(localThreshold) && (
          <PrismSlider
            label={
              <>
                <OverflowingTooltip
                  label={{ parent: recipeParent.name, child: view.routine.parent.name }}
                  containerIsRendered={containerIsRendered}
                />

                <SliderSubtitle
                  label={{
                    parent: recipeParent.component_name,
                    child: station.belongs_to
                      ? `${station.name}, ${station.belongs_to.name}, ${station.site_name} `
                      : `${station.name}, ${station.site_name}`,
                  }}
                />
              </>
            }
            sliderColor="red"
            className={Styles.sliderItem}
            onChange={(value: number) => {
              handleChangeThreshold({ userFacingThreshold: getThresholdValue(value) })
            }}
            onAfterChange={(value: number) => {
              updateViewThreshold(view.routine.parent.id, { userFacingThreshold: getThresholdValue(value) })
            }}
            value={localThreshold.userFacingThreshold}
            inputBox={
              <PrismInputNumber
                min={THRESHOLD_MIN}
                max={THRESHOLD_MAX}
                step={THRESHOLD_STEP}
                value={localThreshold.userFacingThreshold}
                precision={2}
                onChange={(v: number) => {
                  const value = getThresholdValue(v)
                  handleChangeThreshold({ userFacingThreshold: value })
                  updateViewThreshold(view.routine.parent.id, { userFacingThreshold: value })
                }}
                size="small"
              />
            }
          />
        )}

        {defaultIsViewThresholdOverriden && !forcedView && (
          <PrismTooltip
            title="Reset to shared threshold"
            mouseEnterDelay={0.01}
            placement="right"
            anchorClassName={Styles.resetBtn}
          >
            <IconButton
              type="tertiary"
              icon={<PrismResetIcon />}
              size="xsmall"
              onClick={() => {
                resetViewThreshold(view.routine.parent.id)
                setIsViewThresholdOverriden(false)
              }}
            />
          </PrismTooltip>
        )}
        {showThresholdError && <ThresholdErrorMessage />}
      </div>
    </>
  )
}

export default ViewThresholdSlider

const SliderSubtitle = ({ label }: { label: { parent: string; child: string } }) => {
  return (
    <div className={Styles.sliderSubtitle}>
      {label.parent} <span className={Styles.sliderSubtitleLowercase}>on</span>
      <span className={Styles.subtitleSls}>{label.child}</span>
    </div>
  )
}
