/**
 * Constructs a ULID generator closure that emits universally unique, monotonic
 * values.
 *
 * Returns function that can be called to generate ULID array of 16 8-bit
 * integers, in [0, 255].
 *
 * Adapted from https://github.com/skeeto/ulid-js/blob/master/ulid.js
 */
export function ULID() {
  let last = -1
  // Pre-allocate work buffers / views
  const ulid = new Uint8Array(16)
  const time = new DataView(ulid.buffer, 0, 6)
  const rand = new Uint8Array(ulid.buffer, 6, 10)

  return function () {
    const now = Date.now()
    if (now === last) {
      // 80-bit overflow is so incredibly unlikely that it's not considered as a possiblity here
      for (let i = 9; i >= 0; i--) if (rand[i]++ < 255) break
    } else {
      last = now
      time.setUint16(0, (now / 4294967296.0) | 0)
      time.setUint32(2, now | 0)
      window.crypto.getRandomValues(rand)
    }
    return ulid
  }
}

const byteToHex: string[] = []

for (let i = 0; i < 256; ++i) {
  byteToHex.push((i + 0x100).toString(16).slice(1))
}

export function stringifyUuidArray(arr: Uint8Array) {
  // Adapted from https://github.com/uuidjs/uuid/blob/main/src/stringify.js
  return (
    ((((((((((((((((((((((((((((((byteToHex[arr[0] as number] as string) + byteToHex[arr[1] as number]) as string) +
      byteToHex[arr[2] as number]) as string) + byteToHex[arr[3] as number]) as string) +
      '-' +
      byteToHex[arr[4] as number]) as string) + byteToHex[arr[5] as number]) as string) +
      '-' +
      byteToHex[arr[6] as number]) as string) + byteToHex[arr[7] as number]) as string) +
      '-' +
      byteToHex[arr[8] as number]) as string) + byteToHex[arr[9] as number]) as string) +
      '-' +
      byteToHex[arr[10] as number]) as string) + byteToHex[arr[11] as number]) as string) +
      byteToHex[arr[12] as number]) as string) + byteToHex[arr[13] as number]) as string) +
      byteToHex[arr[14] as number]) as string) + byteToHex[arr[15] as number]) as string
  ).toLowerCase()
}

/**
 * Get a UUID string using the ULID spec.
 */
export function ulidUuid() {
  const gen = ULID()
  return stringifyUuidArray(gen())
}
