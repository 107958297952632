import React, { useEffect, useState } from 'react'

import { Moment } from 'moment'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { getterKeys, query, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { RobotDisplayName } from 'components/RobotDisplayName/RobotDisplayName'
import { Status } from 'components/Status/Status'
import { useData, useRobotStatus } from 'hooks'
import { PlcTagLayout, PlcTags, RobotTagLayout } from 'types'
import { getRobotDisplayName, getTimeAgoFromDate, matchRole } from 'utils'

import AddTagModal, { AddTagModalProps } from './AddTagModal'
import BlankBox from './BlankBox'
import Styles from './PlcControl.module.scss'
import PlcOptionBox from './PlcOptionBox'

const EXTRA_BOXES = 3

type Props = { robotId: string }

/**
 * Renders the main configuration for the PLC Layout of a single robot. This component renders a
 * grid with all the items, as well as the camera name with its status and the Edit and Refresh buttons.
 *
 * @param robotId - The selected robot ID. If only 1 robot in the current station, that robot's ID
 */
function PlcConfiguration({ robotId }: Props) {
  const dispatch = useDispatch()

  const me = useData(getterKeys.me())

  const [showSettings, setShowSettings] = useState<boolean>(false)
  const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<Partial<AddTagModalProps>>()
  const [lastLoad, setLastLoad] = useState<Moment>(moment())

  const status = useRobotStatus(robotId)

  const robot = useQuery(getterKeys.robot(robotId), () => service.getRobot(robotId)).data?.data

  const robotTags = robot?.integration_plc_tags_layout?.tags

  // PLC layout information directly from the plc. We request the data for every tag AND enable_tag
  const plcTags = useQuery(
    robotTags ? getterKeys.plcTags(robotId) : undefined,
    () =>
      service.atomSendCommandExtractData<PlcTags>('plc', 'read_tags', robotId, {
        command_args: { tags: robotTags?.flatMap(item => [item.tag, item.enable_tag]) },
      }),
    {
      intervalMs: 10000,
      updater: (oldData, newData) => {
        // We must update the keys from the previous response, we don't want to lose data
        const mergedData = { ...oldData?.data, ...newData?.data }
        return { ...newData, data: mergedData }
      },
    },
  ).data?.data

  useEffect(() => {
    setLastLoad(moment())
  }, [plcTags])

  // Convert plc layout information to displayable data, according to the Django layout defined and the plc tag info received
  const plcTagsLayout = mapPlcTags(robotTags, plcTags)

  const handleRefreshPlc = async () => {
    setLoadingRefresh(true)

    await query(
      getterKeys.plcTags(robotId),
      async () => {
        const res = await service.atomSendCommandExtractData<PlcTags>('plc', 'read_tags', robotId, {
          command_args: { tags: robotTags?.flatMap(item => [item.tag, item.enable_tag]) },
        })

        return res
      },
      { dispatch },
    )

    setLoadingRefresh(false)
  }

  const handleOpenModal = (props: Partial<AddTagModalProps>) => {
    setOpenModal(props)
  }

  const handleUpdateSettings = async (data: RobotTagLayout) => {
    if (!robot) return false

    const tmpTags = robotTags || []
    const currentTagIdx = tmpTags?.findIndex(tag => tag.index === data.index)

    if (currentTagIdx !== -1) {
      tmpTags[currentTagIdx] = data
    } else {
      tmpTags.push(data)
    }

    const res = await service.patchRobot(robotId, {
      body: {
        integration_plc_tags_layout: {
          ...robot.integration_plc_tags_layout,
          tags: tmpTags,
        },
      },
    })

    if (res.type === 'success') {
      const getRobotPromise = query(getterKeys.robot(robotId), () => service.getRobot(robotId), { dispatch })

      const getTagsPromise = query(
        getterKeys.plcTags(robotId),
        async () => {
          const res = await service.atomSendCommandExtractData<PlcTags>('plc', 'read_tags', robotId, {
            command_args: { tags: [data.tag, data.enable_tag] },
          })

          return res
        },
        {
          dispatch,
          updater: (oldData, newData) => {
            // We must update the keys from the previous response, we don't want to lose data
            const mergedData = { ...oldData?.data, ...newData?.data }
            return { ...newData, data: mergedData }
          },
        },
      )

      const responses = await Promise.all([getRobotPromise, getTagsPromise])
      if (responses.every(response => response?.type === 'success')) {
        success({ title: 'Saved settings' })
        setOpenModal(undefined)
      } else {
        error({ title: "Couldn't save changes" })
      }
    }
  }

  const handleDelete = async (index: number) => {
    if (!robot || !robotTags?.length) return false

    const tmpTags = robotTags

    const currentTagIdx = tmpTags?.findIndex(tag => tag.index === index)
    if (currentTagIdx !== -1) {
      tmpTags.splice(currentTagIdx, 1)

      const res = await service.patchRobot(robotId, {
        body: {
          integration_plc_tags_layout: {
            ...robot.integration_plc_tags_layout,
            tags: tmpTags,
          },
        },
      })

      if (res.type === 'success') {
        await query(getterKeys.robot(robotId), () => service.getRobot(robotId), { dispatch })
        success({ title: 'Saved settings' })
        setOpenModal(undefined)
      } else error({ title: "Couldn't save changes" })
    }
  }

  const renderBoxes = () => {
    const mappedBoxes = []

    if (!plcTagsLayout || !plcTagsLayout.length) {
      // In case we don't have any layout defined yet, we show three empty boxes
      for (let i = 0; i < EXTRA_BOXES; i++) {
        mappedBoxes.push(<BlankBox key={i} onClick={() => handleOpenModal({ index: i })} showSettings={showSettings} />)
      }

      return mappedBoxes
    }

    plcTagsLayout.sort((tagA, tagB) => tagA.index - tagB.index)

    // Find the largest index and add the number of extra boxes to add at the end
    const maxIdx = (plcTagsLayout[plcTagsLayout.length - 1]?.index || 0) + EXTRA_BOXES

    // The last found plcTag index
    let j = 0

    for (let i = 1; i <= maxIdx; i++) {
      if (plcTagsLayout[j]?.index === i) {
        const tag = plcTagsLayout[j]
        if (!tag) continue
        // If we're rendering a grid box item that has an index equal to the latest tag, we render the box with options, otherwise we render a blank space
        mappedBoxes.push(
          <PlcOptionBox
            robotId={robotId}
            key={robotId}
            onClick={() => handleOpenModal({ index: i, data: tag, disabled: !matchRole(me, tag.min_user_role) })}
            tag={tag}
            showSettings={showSettings}
          />,
        )
        j++
      } else {
        mappedBoxes.push(
          <BlankBox key={i} onClick={() => handleOpenModal({ index: i })} showSettings={showSettings} invisible />,
        )
      }
    }

    return mappedBoxes
  }

  if (!robot) return <PrismLoader fullScreen />

  return (
    <div className={Styles.plcConfiguration}>
      {/* PLC Header */}
      <div className={Styles.plcHeader}>
        {/* Header Left side */}
        <div className={Styles.plcHeaderLeft}>
          <div className={Styles.plcTitleContainer}>
            <RobotDisplayName className={Styles.plcTitle} robotName={getRobotDisplayName(robot)} />
            <Status status={status} showLabel={false} className={Styles.statusPosition} />
          </div>
          <p className={Styles.plcUpdate}>Updated {getTimeAgoFromDate(lastLoad).text}</p>
        </div>

        {/* Header right side */}
        <div className={Styles.plcHeaderRight}>
          <Button loading={loadingRefresh} onClick={handleRefreshPlc} type="tertiary" size="small">
            Refresh
          </Button>
          {matchRole(me, 'manager') && (
            <Button
              onClick={() => setShowSettings(!showSettings)}
              type={showSettings ? 'primary' : 'secondary'}
              size="small"
            >
              {showSettings ? 'View' : 'Edit'}
            </Button>
          )}
        </div>
      </div>

      <Divider className={Styles.headerDivider} />

      <div className={Styles.plcBody}>
        {/* PLC Grid Container */}
        <div className={Styles.plcGridOptions}>{renderBoxes()}</div>
      </div>

      {openModal && (
        <AddTagModal
          robotId={robotId}
          onDelete={handleDelete}
          onUpdateSettings={handleUpdateSettings}
          onClose={() => setOpenModal(undefined)}
          {...openModal}
        />
      )}
    </div>
  )
}

/**
 * Returns an array of elements in the format with which they'll be displayed on screen, so the
 * data from the PLC and from django must be merged according to the tag's name.
 *
 * @param robotTagLayout The layout data as it's received from Django
 * @param plcTags The value and status data as it's received from the PLC directly
 */
const mapPlcTags = (robotTagLayout?: RobotTagLayout[], plcTags?: PlcTags): PlcTagLayout[] | undefined => {
  if (!robotTagLayout) return

  if (!plcTags) return robotTagLayout.map(item => ({ ...item, disabled: true } as PlcTagLayout))
  return robotTagLayout.map(
    item =>
      ({
        ...item,
        disabled: item.enable_tag && !plcTags[item.enable_tag]?.tag_value,
        value: plcTags[item.tag]?.tag_value,
        dataType: plcTags[item.tag]?.tag_type,
      } as PlcTagLayout),
  )
}

export default PlcConfiguration
