import React from 'react'

import moment from 'moment-timezone'
import {
  AllowEscapeViewBox,
  Bar,
  BarChart,
  CartesianGrid,
  Coordinate,
  RechartsFunction,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { colors, renderBar, YieldTooltip, YieldTooltipProps } from 'components/CustomRecharts'
import { useDateTimePreferences } from 'hooks'
import { DateValue, TimeSeriesDatePeriod } from 'types'
import { CombinedOutcomeData, formatGraphTooltipDate, xAxisFormatter } from 'utils'

const LINE_STROKE_WIDTH = 1

const Y_AXIS_WIDTH = 38
const PDF_Y_AXIS_WIDTH = 40

export type GraphSeries = CombinedOutcomeData & { failYield: number; passYield: number; unknownYield: number }

interface GraphProps {
  yieldSeries: GraphSeries[]
  onChartClick?: RechartsFunction
  chartHeight?: number | string
  chartWidth?: number | string
  containerClassName?: string
  graphRef?: React.RefObject<CartesianGrid>
  yieldThreshold?: number
  period?: TimeSeriesDatePeriod
  width?: number
  height?: number
  hideXAxis?: boolean
  hideYAxis?: boolean
  hideTicks?: boolean
  showPasses?: boolean
  showUknowns?: boolean
  fixedTooltipCoords?: Coordinate
  start?: DateValue
  end?: DateValue
  getBarClassName?: (...e: any[]) => string
  syncId?: string
  mode: 'inspection' | 'metrics'
  pdfFormat?: 'overview' | 'table'
  allowEscapeViewBox?: AllowEscapeViewBox
}

/** Renders a bar chart that displays the inspection's pass/fail/unknown yield for a specified time span
 *
 * @param graphSeries - The series to use as chart's data. Data charted is failYield, passYield and unknownYield (x axis) and ts (y axis)
 * @param syncId - If other charts are rendered at the same time, passing the same syncId to the charts syncs their tooltip
 * @param onChartClick - Function called when user clicks on the chart
 * @param chartHeight - Height for the responsive graph container
 * @param graphRef - A react ref for the graph's grid
 * @param yieldThreshold - The routine's yield threshold. A reference line will be drawn for this point
 * @param showPasses - Whether to show passes or only fail/unknown
 * @param getBarClassName - A function that returns className to be applied to the bars
 */
export function YieldGraph({
  yieldSeries,
  onChartClick,
  chartHeight,
  chartWidth = '100%',
  graphRef,
  height,
  period,
  width,
  hideXAxis,
  hideYAxis,
  hideTicks,
  yieldThreshold,
  containerClassName,
  showPasses = true,
  showUknowns = true,
  mode,
  start,
  fixedTooltipCoords,
  end,
  getBarClassName,
  pdfFormat,
  syncId,
  allowEscapeViewBox,
}: GraphProps) {
  let tick: boolean | { fill: string } = !hideTicks
  if (pdfFormat) tick = { fill: colors.pdfTicksStroke }

  const { timeZone, timeFormat } = useDateTimePreferences()
  return (
    <ConditionalWrapper
      condition={!!chartHeight}
      wrapper={content => (
        <ResponsiveContainer className={containerClassName} width={chartWidth} height={chartHeight}>
          {content}
        </ResponsiveContainer>
      )}
    >
      <BarChart
        data={yieldSeries}
        width={width}
        height={height}
        syncId={syncId}
        barCategoryGap={0}
        onClick={onChartClick}
        style={{ cursor: onChartClick ? 'pointer' : 'default', backgroundColor: pdfFormat ? '#fff' : undefined }}
      >
        {mode === 'inspection' && (
          <CartesianGrid stroke={colors.darkGrid} strokeDasharray="4" vertical={false} ref={graphRef} />
        )}

        {mode === 'metrics' && pdfFormat === 'overview' && (
          <CartesianGrid stroke={colors.pdfCartesianGridStroke} strokeWidth={0.5} />
        )}

        <XAxis
          dataKey="ts"
          stroke={pdfFormat ? colors.pdfAxisStroke : colors.axisStroke}
          axisLine={mode === 'inspection' ? false : undefined}
          height={hideTicks ? 1 : undefined} // this is 30 by default, but when in the small viewport, we want to give it only enough space to show the line
          hide={hideXAxis}
          tickLine={false}
          tick={tick}
          tickFormatter={ts => {
            if (mode === 'inspection')
              return moment(ts * 1000)
                .tz(timeZone)
                .format(timeFormat)
            return xAxisFormatter(ts, {
              startTime: start?.toDate().getTime(),
              endTime: end?.toDate().getTime(),
              timeFormat,
              timeZone,
            })
          }}
          interval="preserveStartEnd"
        />

        <YAxis
          stroke={pdfFormat ? colors.pdfAxisStroke : colors.axisStroke}
          axisLine={false}
          width={pdfFormat ? PDF_Y_AXIS_WIDTH : Y_AXIS_WIDTH}
          tick={tick}
          hide={hideYAxis}
          tickLine={mode === 'metrics' ? false : undefined}
          domain={[0, 100]}
          tickFormatter={val => `${Math.round(val)}%`}
        />

        <Tooltip
          position={fixedTooltipCoords}
          allowEscapeViewBox={allowEscapeViewBox}
          content={(props: YieldTooltipProps) => (
            <YieldTooltip
              showUnknown={showUknowns}
              showPass={showPasses}
              {...props}
              formatter={data =>
                formatGraphTooltipDate({
                  ts: data.ts,
                  timeFormat,
                  timeZone,
                  period,
                })
              }
            />
          )}
          cursor={{ stroke: '#fff', strokeWidth: 0.5, fill: 'transparent' }}
          filterNull={false}
          wrapperStyle={{ zIndex: 2 }}
        />

        {showPasses && (
          <Bar
            dataKey="passYield"
            fill={pdfFormat ? colors.pdfGreenFill : colors.greenBackground}
            stroke={pdfFormat ? colors.pdfGreenStroke : colors.greenBorder}
            strokeWidth={LINE_STROKE_WIDTH}
            isAnimationActive={mode === 'metrics' ? false : undefined}
            name="Yield"
            stackId={'a'}
            barSize={mode === 'metrics' ? 18 : 24}
            shape={props =>
              renderBar({
                ...props,
                className: getBarClassName ? getBarClassName(props) : '',
              })
            }
          />
        )}

        <Bar
          dataKey="failYield"
          fill={pdfFormat ? colors.pdfRedFill : colors.redBackground}
          stroke={pdfFormat ? colors.pdfRedStroke : colors.redBorder}
          name="Yield"
          strokeWidth={LINE_STROKE_WIDTH}
          stackId={'a'}
          barSize={mode === 'metrics' ? 18 : 24}
          isAnimationActive={mode === 'metrics' ? false : undefined}
          shape={props =>
            renderBar({
              ...props,
              className: getBarClassName ? getBarClassName(props) : '',
            })
          }
        />

        {showUknowns && (
          <Bar
            dataKey="unknownYield"
            fill={pdfFormat ? colors.pdfWhiteFill : colors.whiteFill}
            stroke={pdfFormat ? colors.pdfWhiteStroke : colors.whiteStroke}
            strokeWidth={LINE_STROKE_WIDTH}
            name="Yield"
            stackId={'a'}
            barSize={mode === 'metrics' ? 18 : 24}
            isAnimationActive={mode === 'metrics' ? false : undefined}
            shape={props =>
              renderBar({
                ...props,
                className: getBarClassName ? getBarClassName(props) : '',
              })
            }
          />
        )}

        {yieldThreshold && <ReferenceLine y={yieldThreshold} stroke="#F8F8F9" />}
      </BarChart>
    </ConditionalWrapper>
  )
}
