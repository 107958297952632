import React, { useRef } from 'react'

import { isNil } from 'lodash'

import { Divider } from 'components/Divider/Divider'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { PrismMetrics } from 'components/PrismMetrics/PrismMetrics'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { useAllToolLabels, useQueryParams, useTextIsOverflowing, useTrainingLabelsCounts } from 'hooks'
import Shared from 'styles/Shared.module.scss'
import { LabelingProgressObject, QsFilters, ToolFlatWithCurrentExperiment, ToolLabel } from 'types'
import {
  commaSeparatedNumbers,
  findSingleToolLabelFromPartialData,
  getDisplaySeverity,
  getLabelName,
  getTimeAgoFromDate,
} from 'utils'
import { TRAINING_STATES } from 'utils/constants'
import { DISCARD_LABEL, TEST_SET_LABEL, UNCERTAIN_LABEL } from 'utils/labels'

import { getLabeledSinceTrain } from '../Train/Train'
import GenerateEmptyToolResultsButton from './GenerateEmptyToolResultsButton'
import { getUpdatedUserLabelFilters } from './LabelingHeader'
import Styles from './LabelingScreen.module.scss'
interface Props {
  tool: ToolFlatWithCurrentExperiment
  customToolLabels: ToolLabel[] | undefined
  metricsHaveLoaded?: boolean
  countsByLabelId?: { [outcomeOrLabelId: string]: number }
  testSetCountsByLabelId?: { [outcomeOrLabelId: string]: number }
  setForceRefetch: React.Dispatch<React.SetStateAction<number>>
  toolParentId: string
  updateFilters: (filters: QsFilters) => void
  labelingProgress: LabelingProgressObject
  totalUnlabeled: number | undefined
  trainingInProgress: boolean
}

/**
 * Renders the left hand side part of the labeling screen, which contains the metrics for the labeled tool results
 * linked to the tool.
 *
 * @param tool - The tool we are labeling for
 * @param toolLabels - All current tool labels
 * @param metricsHaveLoaded - Whether metrics have loaded
 * @param metrics - Total metrics for current tool
 * @param setForceRefetch - state setter function to trigger a refetch when qs params wouldn't change.
 * @param toolParentId - The current tool parent id
 * @param updateFilters - Function called whenever the selected filters are changed
 */
function LabelingMetrics({
  tool,
  customToolLabels,
  metricsHaveLoaded,
  countsByLabelId,
  testSetCountsByLabelId,
  setForceRefetch,
  toolParentId,
  updateFilters,
  labelingProgress,
  totalUnlabeled,
  trainingInProgress,
}: Props) {
  const [params] = useQueryParams()
  const { defaultLabels } = useAllToolLabels()

  const discardLabel = findSingleToolLabelFromPartialData(defaultLabels, DISCARD_LABEL)
  const uncertainLabel = findSingleToolLabelFromPartialData(defaultLabels, UNCERTAIN_LABEL)
  const testSetLabel = findSingleToolLabelFromPartialData(defaultLabels, TEST_SET_LABEL)

  const { labelsUsedInDataset, labelsAvailableForDataset } = useTrainingLabelsCounts(tool)

  const { goalsForThisLevelById, labeledCount } = labelingProgress

  const fetchToolResults = (labelNameOrId: string) => {
    updateFilters(getUpdatedUserLabelFilters(params, labelNameOrId))
  }

  const testSetCount = testSetCountsByLabelId ? Object.values(testSetCountsByLabelId).reduce((a, b) => (a += b), 0) : 0

  // Counts come back as string value
  const readyToRenderTotalUnlabeled = !isNil(totalUnlabeled) && metricsHaveLoaded
  const readyToRenderNewlyAdded = !isNil(labeledCount) && metricsHaveLoaded

  const renderMetrics = () => {
    return customToolLabels?.map(toolLabel => {
      const count = countsByLabelId?.[toolLabel.id] || 0

      return (
        <ToolLabelCountWithProgress
          toolLabel={toolLabel}
          labelingProgress={metricsHaveLoaded ? (count * 100) / (goalsForThisLevelById[toolLabel.id] || 1) : 0}
          count={count}
          onClick={() => fetchToolResults(toolLabel.id)}
          active={params.user_label_id__in === toolLabel.id}
          key={toolLabel.id}
        />
      )
    })
  }

  const isUnlabeledTokenActive = params.user_label_set_isnull === 'true'

  const trainedStatus = getTrainedStatus(tool, trainingInProgress)

  return (
    <section className={`${Styles.metricCountsContainer} ${Shared.verticalChildrenGap16}`}>
      <GenerateEmptyToolResultsButton
        toolId={tool.id}
        fetchToolResults={() => setForceRefetch(forceRefetch => forceRefetch + 1)}
        toolParentId={toolParentId}
      />
      <PrismMetrics
        label="unlabeled"
        value={metricsHaveLoaded ? renderNumbersOrSpinner(readyToRenderTotalUnlabeled, totalUnlabeled!) : '--'}
        onClick={() => {
          fetchToolResults('unlabeled')
          setForceRefetch(forceRefetch => forceRefetch + 1)
        }}
        data-testid={`labeling-unlabeled-token-${
          readyToRenderTotalUnlabeled && metricsHaveLoaded ? 'ready' : 'not-ready'
        }`}
        active={params.user_label_set_isnull === 'true'}
        data-test-attribute={
          isUnlabeledTokenActive ? 'labeling-unlabeled-token-active' : 'labeling-unlabeled-token-not-active'
        }
      />
      <Divider />

      {!!customToolLabels?.length && (
        <>
          <ul className={`${Shared.verticalChildrenGap8} ${Styles.metricsList}`}>{renderMetrics()}</ul>
          <Divider />
        </>
      )}
      <section className={Shared.verticalChildrenGap8}>
        <PrismMetrics
          label="Test Set"
          data-testid={`labeling-metrics-${testSetLabel?.value}-${testSetLabel?.severity}-progress`}
          data-test-attribute={testSetCount.toString()}
          value={testSetCount}
          onClick={() => testSetLabel && fetchToolResults(testSetLabel.id)}
          active={params.user_label_id__in === testSetLabel?.id}
          icon={
            <PrismResultButton
              severity="test_set"
              size="xSmall"
              type="noFill"
              iconClassName={Styles.resultIconColor}
              className={Styles.iconContainer}
              borderType="borderless"
            />
          }
        />

        <PrismMetrics
          label="uncertain"
          value={
            metricsHaveLoaded && uncertainLabel && countsByLabelId![uncertainLabel.id]
              ? countsByLabelId![uncertainLabel.id]
              : 0
          }
          onClick={() => uncertainLabel && fetchToolResults(uncertainLabel.id)}
          active={params.user_label_id__in === uncertainLabel?.id}
          icon={
            <PrismResultButton
              severity="unknown"
              size="xSmall"
              type="noFill"
              iconClassName={Styles.resultIconColor}
              className={Styles.iconContainer}
              borderType="borderless"
            />
          }
        />

        <PrismMetrics
          data-testid="labeling-metrics-discard"
          label="discard"
          value={
            metricsHaveLoaded && discardLabel && countsByLabelId![discardLabel.id]
              ? countsByLabelId![discardLabel.id]
              : 0
          }
          onClick={() => discardLabel && fetchToolResults(discardLabel.id)}
          active={params.user_label_id__in === discardLabel?.id}
          icon={
            <PrismResultButton
              severity="discard"
              size="xSmall"
              type="noFill"
              iconClassName={Styles.resultIconColor}
              className={Styles.iconContainer}
              borderType="borderless"
            />
          }
        />
      </section>

      <Divider />

      <section className={Shared.verticalChildrenGap8}>
        <PrismMetrics
          label="Trained"
          data-testid="labeling-metrics-train-status"
          value={trainedStatus.label}
          className={`${Styles.metricsWithoutStates} ${
            trainedStatus.status === 'failed' ? Styles.failedStatusColor : ''
          } ${trainedStatus.status === 'in_progress' ? Styles.inProgressStatusColor : ''}`}
        />

        <PrismMetrics label="Images used" value={labelsUsedInDataset || '--'} className={Styles.metricsWithoutStates} />

        <PrismMetrics
          label="Newly Labeled"
          value={readyToRenderNewlyAdded ? getLabeledSinceTrain(labeledCount, labelsAvailableForDataset) : '--'}
          className={Styles.metricsWithoutStates}
        />
      </section>
    </section>
  )
}

export default LabelingMetrics

const renderNumbersOrSpinner = (readyToRenderTotalUnlabeled: boolean | undefined, totalUnlabeled: number) => {
  if (readyToRenderTotalUnlabeled && totalUnlabeled >= 0) return commaSeparatedNumbers(totalUnlabeled)
  if (readyToRenderTotalUnlabeled && totalUnlabeled < 0) return '--'
  return <PrismSkeleton size="medium" />
}

// Renders the current trained status of a tool, returns in order of message priority
function getTrainedStatus(
  tool: ToolFlatWithCurrentExperiment,
  trainingInProgress: boolean,
): {
  label: string
  status: 'failed' | 'in_progress' | 'successful' | 'never' | 'canceled'
} {
  const state = tool.currentExperiment?.state
  const isTraining = trainingInProgress || (state && TRAINING_STATES.includes(state))
  const failed = state === 'failed'
  const isCanceled = state === 'canceled'
  if (failed) return { label: 'Error', status: 'failed' }
  if (isTraining) return { label: 'Training', status: 'in_progress' }

  if (isCanceled)
    return {
      label: 'Canceled',
      status: 'canceled',
    }

  if (tool.currentExperiment?.state_updated_at) {
    return {
      label: getTimeAgoFromDate(tool.currentExperiment.state_updated_at).text,

      status: 'successful',
    }
  }

  return { label: 'Never', status: 'never' }
}

const ToolLabelCountWithProgress = ({
  toolLabel,
  labelingProgress,
  active,
  onClick,
  count,
}: {
  toolLabel: ToolLabel
  labelingProgress: number
  active?: boolean
  onClick?: () => void
  count: number | string
}) => {
  const textContainerRef = useRef<null | HTMLHeadingElement>(null)
  const textIsOverflowing = useTextIsOverflowing(textContainerRef)

  const labelName = getLabelName(toolLabel)

  return (
    <PrismTooltip
      title={labelName}
      mouseEnterDelay={0.3}
      placement="right"
      condition={textIsOverflowing}
      overlayClassName={Styles.metricsTooltip}
    >
      <li>
        <PrismMetrics
          label={labelName}
          hasProgressBar
          value={labelingProgress}
          icon={
            <PrismResultButton
              severity={getDisplaySeverity(toolLabel)}
              size="xSmall"
              type="noColor"
              className={Styles.iconContainer}
            />
          }
          active={active}
          data-testid={`labeling-metrics-${toolLabel.value}-${toolLabel.severity}-progress`}
          data-test-attribute={count.toString()}
          onClick={onClick}
          count={count}
          textRef={textContainerRef}
        />
      </li>
    </PrismTooltip>
  )
}
