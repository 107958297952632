import React, { useEffect, useMemo } from 'react'

import { uniq } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import { Alert } from 'components/PrismAlert/PrismAlert'
import PrismCheckbox from 'components/PrismCheckbox/PrismCheckbox'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismWarningIcon } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { useAllToolLabels, useData, useKeepEventSubsInSync, useQueryEventTypesSubsAndTemplates } from 'hooks'
import { getEventTypeListName } from 'pages/Administration/QualityEvents'
import paths from 'paths'
import Shared from 'styles/Shared.module.scss'
import { CreateUpdateDeleteSubsBody, DefaultEventType, EventSub, EventType, ToolLabel, User } from 'types'
import { getDirtyValues, refreshEventSubsBranches } from 'utils'
import { DEFAULT_COMPUTE_EVENTS, DEFAULT_INSEPCTION_EVENTS, DEFAULT_TOOL_TRAINING_EVENTS } from 'utils/constants'

import { EventNotificationAccordion, EventNotificationGroup } from './EventNotification'
import Styles from './Notify.module.scss'

/**
 * Renders form to allow user to change their notification settings.
 *
 * Assumptions:
 * - For E&N v1 every `EventSub` with a specific `EventSub.type_id` will share the same `via_email`, `via_sms` & `via_app` fields across all `EventSub.targets`. The backend has this flexibility, but the frontend is responsible for keeping the data in synced
 */
export function Notifications() {
  const user = useData(getterKeys.me())
  const history = useHistory()
  const dispatch = useDispatch()
  const { allToolLabels } = useAllToolLabels()

  const { userEventSubs, eventSubTypeTemplates, eventSubTargetTemplates, defaultEvents, qualityEvents } =
    useQueryEventTypesSubsAndTemplates()

  useKeepEventSubsInSync({ userEventSubs, eventSubTypeTemplates, eventSubTargetTemplates })

  // Only show qualityEvents that have the same targets as our users subscriptions
  const filteredQualityEvents = useMemo(() => {
    const subscribedTargetIds = uniq(
      eventSubTargetTemplates?.flatMap(({ targets }) => {
        return Object.values(targets)
      }),
    )

    if (!subscribedTargetIds.length) return []

    return qualityEvents?.filter(eventType => {
      return eventType.event_scopes.find(eventScope =>
        eventScope.targets.find(
          target => subscribedTargetIds.includes(target.id) || target.id === user?.organization_id,
        ),
      )
    })
  }, [eventSubTargetTemplates, qualityEvents, user?.organization_id])

  const toolTrainingStoppedEventsIds = useMemo(() => {
    return defaultEvents
      ?.filter(defaultEvent => TOOL_TRAINING_STOPPED_STATES.includes(defaultEvent.name))
      .map(defaultEvent => defaultEvent.id)
  }, [defaultEvents])

  const groupedEventTypes = useMemo(() => {
    return getInitValues({
      user,
      userEventSubs,
      eventSubTypeTemplates,
      qualityEvents: filteredQualityEvents,
      defaultEvents,
      toolTrainingStoppedEventsIds,
      allToolLabels,
    })
  }, [
    allToolLabels,
    defaultEvents,
    eventSubTypeTemplates,
    filteredQualityEvents,
    toolTrainingStoppedEventsIds,
    user,
    userEventSubs,
  ])

  const {
    reset,
    formState: { isValid, dirtyFields },
    control,
    getValues,
  } = useForm({ defaultValues: groupedEventTypes, mode: 'onChange', shouldUnregister: false })

  // We use our own `isDirty` implementation because our `defaultValues` have extra information, so when `react-hook-form` checks if
  // the user has changed a field, it just compares the field and not the extra information, resulting in always having a dirty form
  // TODO: verifty this is still the case when we update react-hook-form
  const isDirty = !!Object.keys(dirtyFields).length

  useEffect(() => {
    if (user)
      reset(
        getInitValues({
          user,
          userEventSubs,
          eventSubTypeTemplates,
          qualityEvents: filteredQualityEvents,
          defaultEvents,
          toolTrainingStoppedEventsIds,
          allToolLabels,
        }),
      )
  }, [
    allToolLabels,
    defaultEvents,
    eventSubTypeTemplates,
    groupedEventTypes,
    filteredQualityEvents,
    reset,
    toolTrainingStoppedEventsIds,
    user,
    userEventSubs,
  ])

  async function handleSubmit() {
    if (!user) return
    const values: NotificationsFormValues = getValues()

    const dirtyValues = getDirtyValues(dirtyFields, values)
    const CRUDBody: CreateUpdateDeleteSubsBody = {
      update: [],
      create: [],
      delete: [],
    }

    Object.entries(dirtyValues).forEach(([accordionType, valuesMap]) => {
      const typedAccordionType = accordionType as keyof NotificationsFormValues
      if (typedAccordionType === 'reports') return
      Object.entries(valuesMap).forEach(([idx, values]) => {
        const notifRowValues = groupedEventTypes[typedAccordionType] as NotificationRow[]
        const notifRowValuesOverrided = {
          ...notifRowValues[+idx],
          // Dirty values override initial values
          ...(values as { email?: boolean; sms?: boolean }),
        } as NotificationRow

        // We are removing the subscription since the user doesn't want email/sms notifications
        // This would also delete the EventSubTypeTemplate
        if (
          !notifRowValuesOverrided.email &&
          !notifRowValuesOverrided.sms &&
          notifRowValuesOverrided.eventSubs?.length
        ) {
          CRUDBody.delete?.push(...notifRowValuesOverrided.eventSubs.map(eventSub => ({ id: eventSub.id })))
        }

        // If we have an active subscription, update it
        if (
          (notifRowValuesOverrided.email || notifRowValuesOverrided.sms) &&
          notifRowValuesOverrided.eventSubs?.length
        ) {
          CRUDBody.update?.push(
            ...notifRowValuesOverrided.eventSubs.map(eventSub => ({
              ...eventSub,
              via_email: notifRowValuesOverrided.email,
              via_sms: notifRowValuesOverrided.sms,
            })),
          )
        }

        // If we don't have a subscription already for that type, create a new subscription for each target already subscribed to
        if (!notifRowValuesOverrided.eventSubs?.length) {
          const sharedFields = {
            type_id: notifRowValuesOverrided.eventTypeId,
            via_email: notifRowValuesOverrided.email || false,
            via_sms: notifRowValuesOverrided.sms || false,
            via_app: true,
          }

          const targetsAlreadySubTo = eventSubTargetTemplates?.flatMap(eventSub => eventSub.targets)
          // Create an eventSubTypeTemplate
          CRUDBody.create?.push({
            ...sharedFields,
            targets: {},
          })
          // Create a sub for each target
          targetsAlreadySubTo?.forEach(target => {
            CRUDBody.create?.push({
              ...sharedFields,
              targets: target,
            })
          })
          // Special case when dealing with events tool_training stopped events
          if (toolTrainingStoppedEventsIds?.includes(notifRowValuesOverrided.eventTypeId)) {
            CRUDBody.create?.forEach(eventSub => {
              toolTrainingStoppedEventsIds.forEach(eventTypeId => {
                if (eventTypeId === notifRowValuesOverrided.eventTypeId) return

                CRUDBody.create?.push({
                  ...eventSub,
                  type_id: eventTypeId,
                })
              })
            })
          }
        }
      })
    })

    const eventSubPatch = await service.createUpdateDeleteCurrentUserSubs(CRUDBody)

    const userRes = await service.patchUser(user?.id, {
      daily_inspections_report: dirtyValues.reports?.daily,
      weekly_inspections_report: dirtyValues.reports?.weekly,
    })

    if (userRes.type !== 'success' || eventSubPatch.type !== 'success') {
      return error({ title: 'An error occurred, please try again' })
    }

    await refreshEventSubsBranches({ dispatch })
    await query(getterKeys.me(), () => service.me(), { dispatch })
    success({ title: 'Preferences saved', 'data-testid': 'success-notif' })
  }

  const someSmsActiveSub = useMemo(() => {
    return eventSubTypeTemplates?.some(sub => !!sub.via_sms)
  }, [eventSubTypeTemplates])

  return (
    <>
      <LeavePagePrompt when={isDirty} />
      <PrismContainer
        title="Notifications"
        className={Shared.rightSectionContainer}
        bodyClassName={Styles.bodyContainer}
        headerTitleAction={
          <Button
            data-testid="save-notification-btn"
            htmlType="submit"
            disabled={!isDirty || !isValid}
            onClick={handleSubmit}
            size="small"
          >
            Save
          </Button>
        }
        invertTitleAndActions
        actions={
          <p className={Styles.headerDescription}>
            Get notified of quality and operational events for stations and products you subscribe to.
          </p>
        }
        headerActionsClassName={Styles.headerWrapper}
      >
        {!user?.phone_number && someSmsActiveSub && (
          <Alert
            icon={<PrismWarningIcon isActive />}
            description="Set up your SMS number in Contact Information to receive messages"
            onClick={() => history.push(paths.settingsNotify({ mode: 'contactInformation' }))}
          />
        )}
        <EventNotificationGroup
          title="Quality Events"
          description="Track groups of defects when specific conditions are met."
        >
          <EventNotificationAccordion
            title="Quality Events"
            eventList={groupedEventTypes.qualityEvents}
            accordionType="qualityEvents"
            control={control}
          />
        </EventNotificationGroup>

        <EventNotificationGroup
          title="Operational Events"
          description="Stay informed of system updates and status changes."
        >
          <EventNotificationAccordion
            title="Inspections"
            control={control}
            accordionType="inspections"
            eventList={groupedEventTypes.inspections}
          />

          <EventNotificationAccordion
            title="Tool Training"
            control={control}
            accordionType="tool_training"
            eventList={groupedEventTypes.tool_training}
          />

          <EventNotificationAccordion
            control={control}
            title="Computes"
            accordionType="computes"
            eventList={groupedEventTypes.computes}
          />
        </EventNotificationGroup>

        <EventNotificationGroup title="Reports" description="Receive daily and weekly summary reports of inspections.">
          <li className={`${Styles.eventRow} ${Styles.eventItem}`}>
            <p className={Styles.title}>Daily report</p>
            <Controller
              name={'reports.daily'}
              control={control}
              render={({ onChange, value }) => (
                <PrismCheckbox
                  className={Styles.eventCheckbox}
                  onChange={e => onChange(e.target.checked)}
                  checked={value}
                  label="Email"
                />
              )}
            />
          </li>
          <li className={`${Styles.eventRow} ${Styles.eventItem}`}>
            <p className={Styles.title}>Weekly report</p>
            <Controller
              name={'reports.weekly'}
              control={control}
              render={({ onChange, value }) => (
                <PrismCheckbox
                  className={Styles.eventCheckbox}
                  onChange={e => onChange(e.target.checked)}
                  checked={value}
                  label="Email"
                />
              )}
            />
          </li>
        </EventNotificationGroup>
      </PrismContainer>
    </>
  )
}

export type NotificationRow = {
  eventName: string
  name: string | React.JSX.Element
  eventTypeId: string
  eventSubs: EventSub[] | undefined
  sms: boolean
  email: boolean
}

export type NotificationsFormValues = {
  qualityEvents: NotificationRow[]
  inspections: NotificationRow[]
  tool_training: NotificationRow[]
  computes: NotificationRow[]
  reports: {
    daily: boolean
    weekly: boolean
  }
}

function getInitValues({
  user,
  userEventSubs,
  eventSubTypeTemplates,
  qualityEvents,
  defaultEvents,
  toolTrainingStoppedEventsIds,
  allToolLabels,
}: {
  user: User | undefined
  userEventSubs: EventSub[] | undefined
  eventSubTypeTemplates: EventSub[] | undefined
  qualityEvents: EventType[] | undefined
  defaultEvents: EventType[] | undefined
  toolTrainingStoppedEventsIds: string[] | undefined
  allToolLabels: ToolLabel[] | undefined
}) {
  const initValues: NotificationsFormValues = {
    qualityEvents: [],
    inspections: [],
    tool_training: [],
    computes: [],
    reports: {
      daily: user?.daily_inspections_report || false,
      weekly: user?.weekly_inspections_report || false,
    },
  }

  qualityEvents?.forEach(qualityEvent => {
    const subsSubscribedToEvent = []
    // We can have multiple subs per type
    const subs = userEventSubs?.filter(eventSub => eventSub.type_id === qualityEvent.id)
    if (subs) subsSubscribedToEvent.push(...subs)
    // Grab the EventSubTypeTemplate
    const eventSubTypeTemplate = eventSubTypeTemplates?.find(sub => sub.type_id === qualityEvent.id)
    if (eventSubTypeTemplate) subsSubscribedToEvent.push(eventSubTypeTemplate)

    initValues.qualityEvents.push({
      eventName: qualityEvent.name,
      name: getEventTypeListName(qualityEvent, allToolLabels),
      // @ TODO eventsv2 - We are assuming that all eventSubs have the same notifications between them, this is not
      // enforced in the backend, so the frontend is responsible for keeping this restriction.
      // We default to false instead of undefined to make use of UseForm.dirtyFields
      sms: eventSubTypeTemplate?.via_sms || false,
      email: eventSubTypeTemplate?.via_email || false,
      eventTypeId: qualityEvent.id,
      eventSubs: subsSubscribedToEvent,
    })
  })

  defaultEvents?.forEach(defaultEvent => {
    const eventName = defaultEvent.name as DefaultEventType
    // We need to special case train_success, since the backend has multiple events for train_stopped
    // So we need to filter the extra event types to avoid rendering them
    if (eventName === 'train_fail' || eventName === 'train_cancel') return
    const subsSubscribedToEvent = []
    const nonTemplateSubs = userEventSubs?.filter(eventSub => {
      // Add all subscriptions belonging to the other train_stopped events to train_success
      if (eventName === 'train_success') {
        return toolTrainingStoppedEventsIds?.includes(eventSub.type_id || '')
      }
      return eventSub.type_id === defaultEvent.id
    })
    if (nonTemplateSubs) subsSubscribedToEvent.push(...nonTemplateSubs)

    // Grab the typeTemplateSubs
    const templateSubs = eventSubTypeTemplates?.filter(eventSub => {
      // Add all subscriptions belonging to the other train_stopped events to train_success
      if (eventName === 'train_success') {
        return toolTrainingStoppedEventsIds?.includes(eventSub.type_id || '')
      }
      return eventSub.type_id === defaultEvent.id
    })
    if (templateSubs) subsSubscribedToEvent.push(...templateSubs)

    const rowData = {
      eventName,
      name: DEFAULT_EVENT_NAMES[eventName],
      sms: templateSubs?.[0]?.via_sms || false,
      email: templateSubs?.[0]?.via_email || false,
      eventTypeId: defaultEvent.id,
      eventSubs: subsSubscribedToEvent,
      disabled: !userEventSubs?.length,
    }
    if (DEFAULT_INSEPCTION_EVENTS.includes(eventName)) initValues.inspections.push(rowData)
    if (DEFAULT_TOOL_TRAINING_EVENTS.includes(eventName)) initValues.tool_training.push(rowData)
    if (DEFAULT_COMPUTE_EVENTS.includes(eventName)) initValues.computes.push(rowData)
  })

  return initValues
}

const TOOL_TRAINING_STOPPED_STATES = ['train_fail', 'train_cancel', 'train_success']

const DEFAULT_EVENT_NAMES: {
  [key in DefaultEventType]: key extends 'train_fail' | 'train_cancel' ? '' : string
} = {
  inspection_stop: 'Inspection has stopped',
  inspection_start: 'Inspection has started',
  tool_update_user_args: 'Pass criteria has been changed',
  tool_mute: 'Tool has been muted',
  tool_unmute: 'Tool has been unmuted',
  train_start: 'Training has started',
  train_success: 'Training has stopped',
  train_fail: '',
  train_cancel: '',
  recipe_deploy: 'Recipe has been deployed',
  recipe_remove: 'Recipe has been removed',
  compute_offline: 'Compute has gone offline',
  compute_online: 'Compute has gone online',
}
