import {
  query as reduxQuery,
  QueryOptions,
  QueryStateOptions,
  StateKey,
  useQuery as useReduxQuery,
} from 'react-redux-query'
import { AnyAction, Dispatch } from 'redux'
import request, { ErrorResponse, ExceptionResponse, Method, Options, SuccessResponse } from 'request-dot-js'

import { GetterData, getterKeys } from 'api'
import { BACKEND_URL, CLOUD_FASTAPI_URL, IS_QA, NODE_ENV } from 'env'
import { SmartGroup } from 'pages/RoutineOverview/LabelingScreen/LabelingGallery/SmartGroups'
import typedStore, { TypedStore } from 'rdx/store'
import {
  AreaOfInterest,
  AreaOfInterestConfiguration,
  AtomCommand,
  AtomElement,
  Auth,
  BatchItem,
  Component,
  CreateUpdateDeleteEventScopesBody,
  CreateUpdateDeleteSubsBody,
  Dataset,
  DbObject,
  DeepCopyRoutineBody,
  Eula,
  Event,
  EventKind,
  EventScope,
  EventsCount,
  EventSub,
  EventType,
  EventTypeBody,
  Experiment,
  FlatInspection,
  GroupByNotificationsCount,
  InspectedComponent,
  Inspection,
  InspectionCountResult,
  InspectionCreateResponseData,
  Item,
  ItemExpanded,
  ItemWithFallbackImages,
  ListResponseData,
  NotificationCounts,
  Organization,
  PatchProtectedToolBody,
  Picture,
  QsFilters,
  ReadTimeSeriesBody,
  Recipe,
  RecipeExpanded,
  RecipeParent,
  RecipeParentExpanded,
  RecipeRoutine,
  RecipeRoutineExpanded,
  Robot,
  RobotDiscoveriesById,
  Routine,
  RoutineLinkedToRobotResponse,
  RoutineParent,
  RoutineWithAois,
  Site,
  Station,
  SubSite,
  SubSiteExpanded,
  SubSiteType,
  SuccessResponseOnlyData,
  TimeSeriesMeta,
  TimeSeriesResult,
  Tool,
  ToolLabel,
  ToolLabelBody,
  ToolParent,
  ToolParentsCount,
  ToolParentWithAoi,
  ToolResult,
  ToolResultCount,
  ToolResultEmptyOutcome,
  ToolResultsBatchLabel,
  ToolSpecification,
  TrainingResult,
  TrainingResultFlat,
  User,
  UserLabelSet,
} from 'types'
import { fastApiUrl, log, logoutSessionExpired } from 'utils'
import { EVENT_SUBS_RESULTS_PAGE_SIZE } from 'utils/constants'

export type SendToApiResponse<T, ET = {}> =
  | (SuccessResponse<T> & { queryData: SuccessResponse<T> })
  | (ErrorResponse<ET> & { queryData: null })
  | (ExceptionResponse & { queryData: null })

export type SendToApiResponseOnlyData<T, ET = {}> =
  | (SuccessResponseOnlyData<T> & { queryData: SuccessResponseOnlyData<T> })
  | (ErrorResponse<ET> & { queryData: null })
  | (ExceptionResponse & { queryData: null })

/**
 * Sends request to Django or FastAPI and adds in auth headers. For GET
 * requests, retries request several times if there are connection errors. On
 * 401 response, logs user out.
 *
 * @param url - URL to which we're sending request
 * @param options - Options object passed into request-dot-js
 *
 * @returns request-dot-js response object
 */
export const sendToApi = async <T = any, ET = {}>(
  url: string,
  options: Options & { store?: TypedStore; baseUrl?: string } = {},
) => {
  const { baseUrl = BACKEND_URL, headers = {}, store = typedStore, retry: retryOptions, ...rest } = options

  // Client code can specify retry options; we default to retrying only for get requests
  let retry = retryOptions
  if (!retry && (!options.method || options.method.toUpperCase() === 'GET')) retry = { retries: 4, delay: 2000 }

  const rootState = store.getState()
  const { auth } = rootState

  let fullUrl = url
  // If relative URL was passed, we need to prepend base URL
  if (!url.startsWith('http://') && !url.startsWith('https://')) fullUrl = `${baseUrl}${url}`

  const res = await request<T, ET>(fullUrl, {
    ...rest,
    headers: {
      ...(NODE_ENV === 'development' && auth.auth ? { Authorization: `Token ${auth.auth.token}` } : {}),
      ...headers,
    },
    retry,
    // Have fetch send cookies even if web app origin (scheme, domain, port) doesn't match server origin; required for
    // local deploys, because Django, FastAPI, and web app all server on different ports. The only case where we don't
    // want to send cookies, is if we are developing locally against a stack, since this produces a CORS error when sending
    // requests to FastApi.
    credentials: NODE_ENV !== 'development' ? 'include' : undefined,
  })
  if (res.type !== 'success') log('src/api/service.ts', 'sendToApiResponse', res)

  if (auth.auth && res.type === 'error' && res.status === 401) logoutSessionExpired(store)

  // Don't persist error or exception responses to getter branch; see react-redux-query docs; DON'T CHANGE THIS CODE
  return { ...res, queryData: res.type === 'success' ? res : null } as SendToApiResponse<T, ET>
}

export type SitesData = ListResponseData<Site>
export type SubSitesData = ListResponseData<SubSiteExpanded>
export type SubSiteTypesData = ListResponseData<SubSiteType>
export type InspectionsData = ListResponseData<Inspection>
export type FlatInspectionData = ListResponseData<FlatInspection>
export type ItemsData = ListResponseData<ItemWithFallbackImages> & { last_inspection_id?: string }
export type ItemsExpandedData = ListResponseData<ItemExpanded>
export type StationsData = ListResponseData<Station>
export type RobotsData = ListResponseData<Robot>
export type PicturesData = ListResponseData<Picture>
export type ToolResultsData = ListResponseData<ToolResult> & { last_inspection_id?: string }
export type ToolResultsEmptyOutcomeData = ListResponseData<ToolResultEmptyOutcome>
export type ToolSpecificationData = ListResponseData<ToolSpecification>
export type ComponentsData = ListResponseData<Component>
export type RoutinesData = ListResponseData<Routine>
export type RoutineParentsData = ListResponseData<RoutineParent>
export type RecipesData = ListResponseData<Recipe>
export type RecipeParentsData = ListResponseData<RecipeParent>
export type RecipeRoutineData = ListResponseData<RecipeRoutineExpanded>
export type UsersData = ListResponseData<User>
export type ToolLabelsData = ListResponseData<ToolLabel>
export type InspectedComponentsData = ListResponseData<InspectedComponent>
export type ToolParentsData = ListResponseData<ToolParent>
export type ToolParentsWithAoiData = ListResponseData<ToolParentWithAoi>
export type EventTypesData = ListResponseData<EventType>
export type EventScopesData = ListResponseData<EventScope>
export type EventsData = ListResponseData<Event>
export type EventSubsData = ListResponseData<EventSub>
export type NotificationsCountData = ListResponseData<NotificationCounts>
export interface RtsResultsData {
  results: TimeSeriesResult[]
  meta: TimeSeriesMeta
}

export type SmartGroupsData = { groups: [SmartGroup]; complete: boolean }

export type ToolResultsBatchLabelResponse = { user_label_sets: UserLabelSet[] }

type AtomSerialization = 'none' | 'msgpack' | 'arrow'

type AtomSendCommandBody = {
  command_args?: any
  serialization?: AtomSerialization
  deserialization?: AtomSerialization
  created_by_id?: string
}
type AtomSendCommandOptions = { retry?: Options['retry']; timeout?: Options['timeout']; store?: TypedStore }
export type AtomSendCommandResponseData<T> = {
  success: boolean
  result: { err_code?: number; err_str: string; data?: T }
}

/**
 * Object with all methods for sending requests to API.
 */
export const service = {
  // ATOM-SERVER ENDPOINTS

  async atomSendCommand<T>(
    element: AtomElement,
    command: AtomCommand,
    robotId: string,
    body: AtomSendCommandBody = {},
    options: AtomSendCommandOptions = {},
  ) {
    const { store = typedStore, retry, timeout } = options
    const rootState = store.getState()

    return sendToApi<AtomSendCommandResponseData<T>>(`/atom/send_command/${element}/${command}/${robotId}`, {
      baseUrl: fastApiUrl(rootState.edge, robotId),
      method: 'POST',
      body,
      retry,
      timeout,
    })
  },

  // We should always use this function if we plan on throwing the response into Redux
  async atomSendCommandExtractData<T>(
    element: AtomElement,
    command: AtomCommand,
    robotId: string,
    body: AtomSendCommandBody = {},
    options: AtomSendCommandOptions = {},
  ) {
    const { store = typedStore, retry } = options
    const rootState = store.getState()

    const res = await sendToApi<AtomSendCommandResponseData<T>>(`/atom/send_command/${element}/${command}/${robotId}`, {
      baseUrl: fastApiUrl(rootState.edge, robotId),
      method: 'POST',
      body,
      retry,
    })

    // We're extracting data and ensuring that unsuccessful command calls are treated as `error` responses
    if (res.type !== 'success') return res
    if (!res.data.success || !res.data.result.data) return { ...res, type: 'error' as const, queryData: null }

    const { queryData, ...rest } = res
    return { ...rest, data: res.data.result.data, queryData: { ...rest, data: res.data.result.data } }
  },

  // Only use this endpoint if you want to get raw bytes back instead of JSON from a command
  async atomSendCommandRaw(
    element: AtomElement,
    command: string,
    robotId: string,
    body: AtomSendCommandBody = {},
    options: AtomSendCommandOptions = {},
  ) {
    const { store = typedStore, retry } = options
    const rootState = store.getState()

    return sendToApi<Body>(`/atom/send_command/${element}/${command}/${robotId}`, {
      baseUrl: fastApiUrl(rootState.edge, robotId),
      method: 'POST',
      body: { ...body, raw_result: true },
      jsonOut: false,
      retry,
    })
  },

  async atomReadEntry<T>(
    element: AtomElement,
    stream: string,
    robotId: string,
    body: Partial<{
      timeout_s: number
      deserialization: AtomSerialization
      data_key: string
    }>,
    store: TypedStore = typedStore,
  ) {
    const rootState = store.getState()

    const res = await sendToApi<T>(`/atom/read_entry/${element}/${stream}/${robotId}`, {
      baseUrl: fastApiUrl(rootState.edge, robotId),
      method: 'POST',
      body,
      jsonOut: !body.data_key,
    })
    return res
  },

  async atomMget<T>(
    keys: string[],
    options?: { hash_key?: string; command?: 'mget' | 'hmget'; redis?: 'default' | 'rts' },
  ) {
    return sendToApi<T>('/atom/mget', {
      baseUrl: CLOUD_FASTAPI_URL,
      method: 'POST',
      body: { keys, ...options },
    })
  },

  /**
   * Fetches a list of robot discovery values by robot id
   *
   * @param robotIds List of robot its to get discovery for
   * @param discoveryType whether we're fetching elements or status
   * @returns the response from the robot discovery request
   */

  async atomGetRobotDiscovery(robotIds: string[], discoveryType: 'elements' | 'status' = 'elements') {
    return sendToApi<RobotDiscoveriesById>('/atom/get_robots_discovery', {
      baseUrl: CLOUD_FASTAPI_URL,
      method: 'POST',
      retry: { retries: 4, delay: 2000 },
      body: { robot_ids: robotIds, discovery_type: discoveryType },
    })
  },

  // DJANGO ENDPOINTS; make sure to include a trailing slash at the end of all Django endpoint paths

  // Account

  login: async (email: string, password: string) => {
    const res = await sendToApi<Auth, { code?: BackendErrorCodes; error?: string }>('/api/v1/account/auth/', {
      method: 'POST',
      body: { email, password },
    })
    if (res.type !== 'success') return res

    // Don't persist token to localStorage unless development environment
    if (NODE_ENV !== 'development') res.data.token = undefined

    return res
  },

  logout: async (options?: { retry?: Options['retry'] }) => {
    return sendToApi('/api/v1/account/auth/delete/', { method: 'POST', ...options })
  },

  // auth0 returns a token that we need to exchange for a django token in order to be able to interact with API
  exchangeAuth0Token: async (body: { id_token: string; access_token: string }) => {
    const res = await sendToApi<Auth>('/api/v1/account/auth/exchange_jwts_for_token/', {
      method: 'POST',
      body,
    })
    if (res.type !== 'success') return res

    // Don't persist token to localStorage unless development environment
    if (NODE_ENV !== 'development') res.data.token = undefined

    return res
  },

  me: async () => {
    return sendToApi<User>('/api/v1/user/', { method: 'GET' })
  },

  organization: async () => {
    return sendToApi<Organization>('/api/v1/organization/', { method: 'GET' })
  },

  getSite: async (id: string) => {
    return sendToApi<Site>(`/api/v1/sites/${id}`, { method: 'GET' })
  },

  getSites: async (params?: Record<string, unknown>) => {
    return sendToApi<SitesData>('/api/v1/sites/', { params, method: 'GET' })
  },

  createSite: async (body: Partial<Site>) => {
    return sendToApi<Site>('/api/v1/sites/', { method: 'POST', body })
  },

  updateSite: async (siteId: string, body: Partial<Site>) => {
    return sendToApi<Site>(`/api/v1/sites/${siteId}/`, { method: 'PATCH', body })
  },

  // Fow v1, we only have 'lines' as subsites
  getSubSites: async (params?: Record<string, unknown>) => {
    return sendToApi<SubSitesData>('/api/v1/subsites/', { params, method: 'GET' })
  },

  getSubSite: async (subSiteId: string) => {
    return sendToApi<SubSiteExpanded>(`/api/v1/subsites/${subSiteId}`)
  },

  getSubSiteTypes: async (params?: { site_id?: string }) => {
    return sendToApi<SubSiteTypesData>('/api/v1/subsite_types/', { params, method: 'GET' })
  },

  createSubSite: async (body: Partial<SubSite>) => {
    return sendToApi<SubSite>('/api/v1/subsites/', { method: 'POST', body })
  },

  updateSubSite: async (siteId: string, body: Partial<SubSite>) => {
    return sendToApi<SubSite>(`/api/v1/subsites/${siteId}`, { method: 'PATCH', body })
  },

  orderStations: async (subsiteId: string, body: { station_id: string; ordering: number | null }[]) => {
    return sendToApi(`/api/v1/subsites/${subsiteId}/station_ordering/`, {
      method: 'POST',
      body: { stations: body },
    })
  },

  meUpdate: async (body: {}) => {
    return sendToApi<User>('/api/v1/user/', { method: 'PATCH', body })
  },

  activateAccount: async (token: string, password: string) => {
    return sendToApi<any, { code?: BackendErrorCodes; error?: string }>('/api/v1/account/activate/', {
      method: 'POST',
      body: { activation_key: token, new_password: password },
    })
  },

  setPassword: async (old_password: string, password: string) => {
    return sendToApi<any, { code?: BackendErrorCodes; error?: string }>('/api/v1/password/change/', {
      method: 'POST',
      body: { old_password, new_password: password },
    })
  },

  setPasswordWithToken: async (userId: string, token: string, password: string) => {
    return sendToApi<any, { code?: BackendErrorCodes; error?: string }>('/api/v1/password/reset/confirm/', {
      method: 'POST',
      body: { uidb64: userId, token, new_password: password },
    })
  },

  sendResetPasswordEmail: async (email: string) => {
    return sendToApi('/api/v1/password/reset/', { method: 'POST', body: { email } })
  },

  // Other

  getNextPage<T>(nextPageUrl: string, options?: Options) {
    const nextPagePath = nextPageUrl.slice(nextPageUrl.indexOf('/api'))
    return sendToApi<T>(nextPagePath, options)
  },

  getUser(id: string) {
    return sendToApi<User>(`/api/v1/users/${id}/`)
  },

  getUsers: async (params: { [param: string]: any } = {}) => {
    return sendToApi<UsersData>('/api/v1/users/', {
      params,
    })
  },

  patchUser: async (id: string, body: {}) => {
    return sendToApi<User>(`/api/v1/users/${id}/`, {
      method: 'PATCH',
      body,
    })
  },

  createUser: async (body: {}) => {
    return sendToApi<User>('/api/v1/users/', {
      method: 'POST',
      body,
    })
  },

  shareToolResultOrItem: async (body: {}) => {
    return sendToApi('/api/v1/email/share_detail_result', { method: 'POST', body })
  },

  sendPhoneVerificationToken: async (body: {}) => {
    return sendToApi('/api/v1/account/verifynumber/', { method: 'POST', body })
  },

  resendPhoneVerificationToken: async () => {
    return sendToApi('/api/v1/account/verifynumber/resend/', { method: 'POST' })
  },

  getUsersNoAuth: async (email: string = '') => {
    return sendToApi<ListResponseData<User>>('/api/v1/users_no_auth/', { method: 'GET', params: { email } })
  },

  getStations: async (params?: {}) => {
    return getAllPages(sendToApi<StationsData>('/api/v1/stations/', { method: 'GET', params }))
  },

  getInspectedComponents: async () => {
    return sendToApi<InspectedComponentsData>('/api/v1/count/recently_inspected_components/')
  },

  getStation: async (id: string) => {
    return sendToApi<Station>(`/api/v1/stations/${id}/`)
  },

  updateStation: async (id: string, body: Partial<Station>) => {
    return sendToApi<Station>(`/api/v1/stations/${id}/`, { body, method: 'PATCH' })
  },

  createStation: async (options?: { body: Options['body']; retry?: Options['retry'] }) => {
    return sendToApi<Station>('/api/v1/stations/', { ...options, method: 'POST' })
  },

  getRobots: async (params?: {}) => {
    return sendToApi<RobotsData>('/api/v1/robots/', { method: 'GET', params })
  },

  getRobot: async (id: string) => {
    return sendToApi<Robot>(`/api/v1/robots/${id}/`)
  },

  patchRobot: async (id: string, options?: { body: Options['body']; retry?: Options['retry'] }) => {
    return sendToApi<Robot>(`/api/v1/robots/${id}/`, { ...options, method: 'PATCH' })
  },

  getComponent: async (id: string) => sendToApi<Component>(`/api/v1/components/${id}/`),

  updateComponent: async (id: string, body: Partial<Component>) =>
    sendToApi<Component>(`/api/v1/components/${id}/`, { method: 'PATCH', body }),

  getComponents: async (params?: {}) => {
    return getAllPages(sendToApi<ComponentsData>('/api/v1/components/', { params }))
  },

  createComponent: async (body: {}) => {
    return sendToApi<Component>('/api/v1/components/', { method: 'POST', body: { ...body } })
  },

  getItem: async (id: string) => {
    return sendToApi<ItemExpanded>(`/api/v1/items/${id}/`)
  },

  getInspection: async (id: string) => {
    return sendToApi<Inspection>(`/api/v1/inspections/${id}/`)
  },

  getInspections: async (params?: {}) => {
    return sendToApi<InspectionsData>('/api/v1/inspections/', { params })
  },

  getFlatInspections: async (params?: {}) => {
    return sendToApi<FlatInspectionData>('/api/v1/inspections/flat/', { params })
  },

  patchInspection: async (id: string, options?: { body: Options['body']; retry?: Options['retry'] }) => {
    return sendToApi(`/api/v1/inspections/${id}/`, { ...options, method: 'PATCH' })
  },

  stopInspection: async (id: string, options?: { retry?: Options['retry'] }) => {
    return sendToApi(`/api/v1/inspections/${id}/stop/`, { ...options, method: 'POST' })
  },

  getItems: async <T = ItemsData>(
    params: { robot_id?: string; component_id?: string; inspection_id?: string; use_primary_db?: boolean } & {
      [key: string]: any
    } = {},
    options?: { method?: Method; body?: {} },
  ) => {
    return sendToApi<T>('/api/v1/items/', { params, ...options })
  },

  getItemsExpanded: async (
    params: { robot_id?: string; component_id?: string; inspection_id?: string; use_primary_db?: boolean } & {
      [key: string]: any
    } = {},
  ) => {
    return sendToApi<ItemsExpandedData>('/api/v1/items/expanded/', { params })
  },

  batchItemCreate: async (items: BatchItem[], options?: { retry?: Options['retry'] }) => {
    return sendToApi<{ items: BatchItem[] }>('/api/v1/sc/items_batch_create/', {
      body: { items },
      method: 'POST',
      headers: {},
      ...options,
    })
  },

  async getInspectionRoutines(id: string, params?: {}) {
    return sendToApi<ListResponseData<RoutineWithAois>>(`/api/v1/inspections/${id}/routines/`, { params })
  },

  createAoi: async (body: {}) => {
    return sendToApi<AreaOfInterest>('/api/v1/aois/', { method: 'POST', body: { ...body } })
  },

  patchAoi: async (id: string, options: { body: Options['body']; retry?: Options['retry'] }) => {
    return sendToApi<AreaOfInterest>(`/api/v1/aois/${id}/`, { ...options, method: 'PATCH' })
  },

  patchExperiment: async (experimentId: string, body: Partial<Experiment>) => {
    return sendToApi<Experiment>(`/api/v1/cvml/experiments/${experimentId}/`, { method: 'PATCH', body })
  },

  patchAoiParent: async (parentId: string, body: {}) => {
    return sendToApi<AreaOfInterest>(`/api/v1/aoi_parents/${parentId}/`, { body, method: 'PATCH' })
  },

  deleteAoi: async (id: string) => {
    return sendToApi<AreaOfInterest, { code?: BackendErrorCodes; error?: string }>(`/api/v1/aois/${id}/`, {
      method: 'DELETE',
    })
  },

  batchCreateUpdateDeleteAois: async (body: {}) => {
    return sendToApi<{ aois: AreaOfInterestConfiguration[] }, { code?: BackendErrorCodes; error?: string }>(
      '/api/v1/aois/batch_create_update_delete/',
      {
        method: 'POST',
        body,
      },
    )
  },

  getPicture: async (id: string) => sendToApi<Picture>(`/api/v1/pictures/${id}/`),

  deletePicture: async (id: string) => sendToApi<Picture>(`/api/v1/pictures/${id}/`, { method: 'DELETE' }),

  getRoutine: async (id: string, params?: {}) => {
    return sendToApi<RoutineWithAois>(`/api/v1/routines/${id}/`, { params })
  },

  getRoutines: async (params: { [param: string]: any } = {}) => {
    return sendToApi<RoutinesData>('/api/v1/routines/', { params })
  },

  getRoutineParent: async (id: string) => {
    return sendToApi<RoutineParent & { routines: Routine[] }>(`/api/v1/routine_parents/${id}/`)
  },
  getRoutineParents: async (params: { [param: string]: any } = {}) => {
    return sendToApi<RoutineParentsData>('/api/v1/routine_parents/', { params })
  },

  createRecipe: async (body: {}) => {
    return sendToApi<RecipeExpanded>('/api/v1/recipes/', { method: 'POST', body })
  },

  updateRecipe: async (id: string, body: {}) => {
    return sendToApi<Recipe>(`/api/v1/recipes/${id}/`, { method: 'PATCH', body })
  },

  getRecipe: async (id: string) => {
    const res = await sendToApi<RecipeExpanded>(`/api/v1/recipes/${id}/`)
    if (res.type === 'success') {
      // We want to filter RecipeRoutines records with a deleted RoutineParent as a safecheck, this
      // will cover the edge case where a RoutineParent from a view was set as deleted, but
      // it was not unlinked from the recipe. We only want to do this on recipes that are not deleted, since
      // a deleted Recipe has all of their routines set as deleted, so we would end up with no RecipeRoutine records.
      if (!res.data.parent.is_deleted) {
        res.data.recipe_routines = res.data.recipe_routines.filter(
          recipeRoutine => !recipeRoutine.routine.parent.is_deleted,
        )
      }
    }
    return res
  },

  getRecipes: async (params: { [param: string]: any } = {}) => {
    return sendToApi<RecipesData>('/api/v1/recipes/', { params })
  },

  updateRecipeParent: async (id: string, body: {}) => {
    return sendToApi<RecipeParentExpanded>(`/api/v1/recipe_parents/${id}/`, { method: 'PATCH', body })
  },

  getRecipeParent: async (id: string) => {
    return sendToApi<RecipeParentExpanded>(`/api/v1/recipe_parents/${id}/`)
  },

  duplicateRecipeParent: async (id: string, body: {}) => {
    return sendToApi<RecipeExpanded>(`/api/v1/recipe_parent/${id}/deep_copy/`, { method: 'POST', body })
  },

  getRecipeParents: async (params: { [param: string]: any } = {}) => {
    return sendToApi<RecipeParentsData>('/api/v1/recipe_parents/', { params })
  },

  getRecipeRoutine: async (id: string) => {
    return sendToApi<RecipeRoutineExpanded>(`/api/v1/recipe_routines/${id}/`)
  },

  getRecipeRoutines: async (params: { [param: string]: any } = {}) => {
    return sendToApi<RecipeRoutineData>('/api/v1/recipe_routines/', {
      params: { ...params, is_routine_deleted: false },
    })
  },

  deleteRoutineFromRecipe: async (routineId: string, recipeId: string) => {
    return sendToApi(`/api/v1/routines/${routineId}/remove_from_recipe/`, {
      method: 'POST',
      body: { recipe_id: recipeId },
    })
  },

  patchRecipe: async (recipeId: string, body: Partial<RecipeExpanded>) => {
    return sendToApi<RecipeExpanded>(`/api/v1/recipes/${recipeId}/`, {
      method: 'PATCH',
      body,
    })
  },

  patchProtectedRecipe: async (recipeId: string, body?: Partial<RecipeExpanded>) => {
    return sendToApi<Routine>(`/api/v1/recipes/${recipeId}/update_protected/`, {
      method: 'PATCH',
      body,
    })
  },

  patchProtectedRecipeRoutine: async (recipeRoutineId: string, body?: Partial<RecipeRoutine>) => {
    return sendToApi<Routine>(`/api/v1/recipe_routines/${recipeRoutineId}/update_protected/`, {
      method: 'PATCH',
      body,
    })
  },

  linkRoutineToRobot: async (routineId: string, recipeId: string, robotId: string) => {
    return sendToApi<RoutineLinkedToRobotResponse>(`/api/v1/routines/${routineId}/link_robot/`, {
      method: 'POST',
      body: { recipe_id: recipeId, robot_id: robotId },
    })
  },

  unlinkRoutineToRobot: async (routineId: string, recipeId: string, robotId: string) => {
    return sendToApi<RoutineLinkedToRobotResponse>(`/api/v1/routines/${routineId}/unlink_robot/`, {
      method: 'POST',
      body: { recipe_id: recipeId, robot_id: robotId },
    })
  },

  createRoutineParent: async (body: {}) => {
    return sendToApi<RoutineParent>('/api/v1/routine_parents/', { method: 'POST', body })
  },

  patchRoutineParent: async (id: string, body: {}) => {
    return sendToApi<RoutineParent>(`/api/v1/routine_parents/${id}/`, { method: 'PATCH', body })
  },

  deleteRoutineParent: async (id: string) => {
    return sendToApi<RoutineParent>(`/api/v1/routine_parents/${id}/`, { method: 'DELETE' })
  },

  createRoutine: async (body: {}) => {
    return sendToApi<Routine>('/api/v1/routines/', {
      method: 'POST',
      body,
    })
  },

  patchRoutine: async (routineId: string, body: { [key: string]: any }, options?: Options) => {
    return sendToApi<Routine>(`/api/v1/routines/${routineId}/`, {
      method: 'PATCH',
      body,
      options,
    })
  },

  deepCopyTool: async (toolId: string, body: {}) => {
    return sendToApi<Tool>(`/api/v1/tools/${toolId}/deep_copy/`, { method: 'POST', body })
  },

  deepCopyRoutine: async (routineId: string, body?: DeepCopyRoutineBody) => {
    return sendToApi<RoutineWithAois>(`/api/v1/routines/${routineId}/deep_copy/`, { method: 'POST', body })
  },

  trainRoutine: async (body?: {}) => {
    return sendToApi<{ routine_id: string }>('/api/v1/cvml/train/', { method: 'POST', body })
  },

  // In this case a fake inspection is one that's not created by any camera, but rather depends on
  // Manual image uploads. In this specific case, where the robots are set to an empty array,
  // we will receive an inspection token which will be used to create items directly.
  createFakeInspection: async (routineId: string, recipeId: string, name: string) => {
    return sendToApi<InspectionCreateResponseData>('/api/v1/inspections/', {
      method: 'POST',
      body: {
        recipe_id: recipeId,
        recipe_routines: [{ routine_id: routineId }],
        name,
      },
    })
  },

  getToolResults<T extends boolean = false>(
    params: { [param: string]: any } = {},
    options?: { include_empty?: T; method?: Method; body?: {} },
  ) {
    // We don't want to show empty tool results anywhere, UNTIL they've been labeled (calculated_outcome !== empty),
    // only in the labeling screen
    // Tool results with empty outcomes are tool results that have been created from old pictures,
    // and associated with new tools, so that users don't have to run inspections only to gather data.

    if (!options?.include_empty) {
      const calculatedOutcomeIn = params['calculated_outcome__in']
      if (!calculatedOutcomeIn) {
        params['calculated_outcome__in'] = ['pass', 'fail', 'unknown', 'error', 'needs-data'].join()
      }
    }
    return sendToApi<T extends false ? ToolResultsData : ToolResultsEmptyOutcomeData>('/api/v1/tool_results/', {
      params,
      method: options?.method,
      body: options?.body,
    })
  },

  getToolResult(id: string) {
    return sendToApi<ToolResult>(`/api/v1/tool_results/${id}/`, {
      method: 'GET',
    })
  },

  countLabeledToolResults(toolParentId: string, params?: { is_test_set?: boolean }) {
    return sendToApi<ToolResultCount>('/api/v1/count/tool_results/', {
      method: 'GET',
      params: {
        group_by: 'active_user_label_set__tool_labels__id,tool_parent_id,component_id',
        tool_parent_id: toolParentId,
        ...params,
      },
    })
  },

  countInspections(params: { station_id?: string } = {}) {
    return sendToApi<{ results: InspectionCountResult[] }>('/api/v1/count/inspections/', {
      method: 'GET',
      params: {
        group_by: 'recipe_id',
        ...params,
      },
    })
  },

  patchItem(id: string, body: Partial<Item>) {
    return sendToApi(`/api/v1/items/${id}/`, {
      method: 'PATCH',
      body,
    })
  },

  getToolSpecifications() {
    return sendToApi<ToolSpecificationData>('/api/v1/tool_specifications/', { params: { is_custom: false } })
  },

  getTool(id: string) {
    return sendToApi<Tool>(`/api/v1/tools/${id}/`)
  },

  countToolParents() {
    return sendToApi<ToolParentsCount>('/api/v1/count/tool_parents/')
  },

  getToolParents(params?: {}) {
    return sendToApi<ToolParentsWithAoiData>('/api/v1/tool_parents/', { params })
  },

  getToolParent(id: string) {
    return sendToApi<ToolParent>(`/api/v1/tool_parents/${id}/`)
  },

  patchToolParent: async (id: string, body: {}) => {
    return sendToApi<Tool>(`/api/v1/tool_parents/${id}/`, { method: 'PATCH', body })
  },

  patchTool(id: string, body: {}) {
    return sendToApi<Tool>(`/api/v1/tools/${id}/`, { method: 'PATCH', body })
  },

  patchProtectedTool(id: string, body: PatchProtectedToolBody) {
    return sendToApi<Tool>(`/api/v1/tools/${id}/update_protected/`, { method: 'PATCH', body })
  },

  createTool(body: {}) {
    return sendToApi<DbObject>('/api/v1/tools/', { method: 'POST', body })
  },

  getToolLabels(params?: {
    tool_parent_id?: string
    tool_parent_id__in?: string
    tool_specification__in?: string
    kind__in?: string
    value?: string
    severity__in?: string
    is_deleted?: boolean
    id__in?: string
    page_size?: number
  }) {
    return getAllPages(
      sendToApi<ListResponseData<ToolLabel>>('/api/v1/tool_labels/', {
        method: 'GET',
        params,
      }),
      // QA is our only "customer" that will have more than 100 labels for the next 5 years most likely.
      // Let's buy ourselves more time with QA with a depth of 5 here, such that by the time they actually
      // go over this limit, we can prioritize the proper pagination work around tool labels. Our customers
      // will NEVER hit this before QA does.
      5,
    )
  },

  getToolLabel(toolLabelId: string) {
    return sendToApi<ToolLabel>(`/api/v1/tool_labels/${toolLabelId}/`)
  },

  createToolLabel(body: ToolLabelBody, options?: Options) {
    return sendToApi<ToolLabel, { code?: BackendErrorCodes }>('/api/v1/tool_labels/', {
      method: 'POST',
      body,
      options,
    })
  },

  patchToolLabel(toolLabelId: string, body: Omit<Partial<ToolLabel>, 'severity'>, options?: Options) {
    return sendToApi<ToolLabel, { code?: BackendErrorCodes }>(`/api/v1/tool_labels/${toolLabelId}`, {
      method: 'PATCH',
      body,
      options,
    })
  },

  deleteUserLabelSet(labelId: string) {
    return sendToApi<UserLabelSet>(`/api/v1/user_label_sets/${labelId}/`, {
      method: 'DELETE',
    })
  },

  toolResultBatchLabel(body: ToolResultsBatchLabel) {
    return sendToApi<ToolResultsBatchLabelResponse>('/api/v1/tool_results/batch_label/', {
      method: 'POST',
      body,
    })
  },

  generateSmartGroups(id: string, params?: QsFilters) {
    return sendToApi<{ groups_id: string }, { code?: BackendErrorCodes }>(
      `/api/v1/cvml/tool_parents/${id}/generate_smart_groups/`,
      {
        method: 'POST',
        params,
      },
    )
  },

  getToolResultList(toolResultIds: string[], options?: Options) {
    return sendToApi<ToolResultsData>('/api/v1/tool_results/filters_in_request_body/', {
      method: 'POST',
      body: { ids: toolResultIds },
      ...options,
    })
  },

  getEvent(eventId: string) {
    return sendToApi<Event>(`/api/v1/events/${eventId}`)
  },

  getEvents(params: { inspection_id?: string; type_id?: string | null; page_size?: number } = {}) {
    return sendToApi<EventsData>('/api/v1/events/', { params })
  },

  countEvents(params = {}) {
    return sendToApi<EventsCount>('/api/v1/count/events/', { params })
  },

  getEventTypes(
    params: { kind?: EventKind; name?: string; is_deleted?: boolean; page?: number; page_size?: number } = {},
  ) {
    return getAllPages(sendToApi<EventTypesData>('/api/v1/event_types/', { params }))
  },

  createEventType(body: EventTypeBody) {
    return sendToApi<EventType, { code?: BackendErrorCodes }>('/api/v1/event_types/', { method: 'POST', body })
  },

  patchEventType(eventTypeId: string, body: Partial<EventType>) {
    return sendToApi<EventType, { code?: BackendErrorCodes }>(`/api/v1/event_types/${eventTypeId}/`, {
      method: 'PATCH',
      body,
    })
  },
  deleteEventType(eventTypeId: string) {
    return sendToApi<EventType>(`/api/v1/event_types/${eventTypeId}/`, { method: 'DELETE' })
  },

  getEventScopes(
    params: {
      page?: number
      page_size?: number
      target_id?: string
      target_table?: string
      type_id?: string
      type_id__in?: string
    } = {},
  ) {
    return sendToApi<EventScopesData>('/api/v1/event_scopes/', { params })
  },

  createUpdateDeleteEventScopes(body: CreateUpdateDeleteEventScopesBody) {
    return sendToApi<EventScope>('/api/v1/event_scopes/', { method: 'POST', body })
  },

  getEventSubs(
    params: {
      target_id?: string
      target_table?: string
      type_id?: string
      user_id?: string
      has_type?: boolean
    } = {},
  ) {
    return sendToApi<EventSubsData>('/api/v1/event_subs/', { params })
  },

  getCurrentEventSubs(
    params: {
      target_id?: string
      target_table?: string
      has_targets?: boolean
      type_id?: string
      user_id?: string
      has_type?: boolean
    } = {},
  ) {
    return sendToApi<EventSubsData>('/api/v1/event_subs/user/', {
      params: { ...params, page_size: EVENT_SUBS_RESULTS_PAGE_SIZE },
    })
  },

  countNotifications(params: { group_by: GroupByNotificationsCount; start?: string }) {
    return sendToApi<NotificationCounts>('/api/v1/count/notifications/', { params })
  },

  createUpdateDeleteSubs(body: CreateUpdateDeleteSubsBody) {
    return sendToApi<CreateUpdateDeleteSubsBody>('/api/v1/event_subs/', { method: 'POST', body })
  },
  getCurrentUserEventSubs(params: { target_id?: string; target_table?: string }) {
    return sendToApi<EventSubsData>('/api/v1/event_subs/user/', { params })
  },
  createUpdateDeleteCurrentUserSubs(body: CreateUpdateDeleteSubsBody) {
    return sendToApi<CreateUpdateDeleteSubsBody>('/api/v1/event_subs/user/', { method: 'POST', body })
  },
  getSmartGroups(toolId: string, groupsId: string) {
    return sendToApi<SmartGroupsData>(`/api/v1/cvml/tool_parents/${toolId}/smart_groups/${groupsId}/`)
  },

  getDataset(datasetId: string) {
    return sendToApi<Dataset>(`/api/v1/cvml/datasets/${datasetId}/`)
  },

  // File uploads

  getBatchPresignedUrls(
    file_type: 'routine_image' | 'picture_image' | 'routine_image_thumbnail',
    file_extension: 'png' | 'jpg' = 'jpg',
    count: number,
  ) {
    return sendToApi<{ results: { url: string }[] }>('/api/v1/files/batch_generate_presigned_urls/', {
      method: 'POST',
      body: { file_type, file_extension, count },
    })
  },

  uploadFile(file: File, presignedUrl: string, options?: { retry?: Options['retry']; headers?: Options['headers'] }) {
    return request(presignedUrl, { ...options, method: 'PUT', body: file })
  },

  /**
   * Writes an image to disk and returns the path to the image. This is only used for local deploys.
   */
  sendFileToDiskLocalDeploy(
    file: File,
    fileType: 'picture' | 'picture_thumb' | 'insight' | 'embedding' | 'routine' | 'routine_thumb',
    ext = 'jpg',
  ) {
    const data = new FormData()
    data.append('image', file)
    data.append('upload_type', fileType)
    data.append('ext', ext)

    return sendToApi<{ url: string }>('/api/v1/sc/upload_image/', {
      method: 'POST',
      body: data,
    })
  },

  createEmptyToolResults<T extends boolean | undefined = undefined>(
    tool_id: string,
    body: {
      count: number
      dry_run?: T
      inspection_ids?: string[]
      routine_parent_id?: string
    },
  ) {
    return sendToApi<T extends true ? { can_be_created: number } : { created: number }>(
      `/api/v1/tools/${tool_id}/create_empty_tool_results/`,
      {
        method: 'POST',
        body,
      },
    )
  },

  getToolTrainingResults(toolId: string, page_size: number = 100000) {
    return sendToApi<ListResponseData<TrainingResultFlat>>(
      `/api/v1/tools/${toolId}/trainingresults/?page_size=${page_size}`,
      {
        method: 'GET',
      },
    )
  },

  getToolTrainingResultsByIds(ids: string[]) {
    return sendToApi<ListResponseData<TrainingResult>>('/api/v1/trainingresults/filters_in_request_body/', {
      method: 'POST',
      body: { ids },
    })
  },

  async readTimeSeries(body: ReadTimeSeriesBody) {
    return sendToApi<RtsResultsData>('/api/v1/time_series/mrange/', {
      method: 'POST',
      body,
    })
  },

  /** Sends a POST request that updates the user `accepted_eula` and `accepted_eula_version` fields
   * with the current datetime and the active eula version, determined by the backend.
   */
  acceptEula() {
    return sendToApi('/api/v1/user/accept_eula/', { method: 'POST' })
  },

  getEulaJson() {
    return sendToApi<Eula>(IS_QA ? '/eula_qa.json' : '/eula.json', { baseUrl: 'https://eula.elementaryml.com' })
  },
}

/**
 * This is a function that can be used to wrap a service call to ensure we get
 * all of the relevant data immediately. This can be used inside a service
 * method wrapping the sendToApi call, to affect all calls to that endpoint. Or
 * this can be used inside a `query` or `useQuery` by just wrapping the promise
 * passed into that.
 *
 * @param fetcher - The promise the service call returns.
 */
export async function getAllPages<T>(fetcher: Promise<SendToApiResponse<ListResponseData<T>>>, maxDepth = 5) {
  const results: T[] = []
  let res = await fetcher

  while (res.type === 'success') {
    results.push(...res.data.results)
    if (--maxDepth <= 0 || !res.data.next) break
    res = await service.getNextPage<ListResponseData<T>>(res.data.next)
  }

  const data = { ...res.data, results }
  return { ...res, data, queryData: { ...res.queryData, data } } as SendToApiResponse<ListResponseData<T>>
}

/**
 * Wrapper around RRQ's query function. Same signature, but key must be
 * constructed with function from getterKeys, and return type is inferred and
 * validated based on which key function is used.
 */
export const query = <
  T extends keyof typeof getterKeys,
  U extends ReturnType<(typeof getterKeys)[T]>,
  R extends GetterData<U>,
>(
  key: U,
  fetcher: () => Promise<SendToApiResponseOnlyData<R>>,
  options: QueryOptions<SuccessResponseOnlyData<R> | null> & {
    dispatch: Dispatch<AnyAction>
  },
) => {
  return reduxQuery(key, fetcher, options)
}

/**
 * Wrapper around RRQ's useQuery hook. Same signature, but key must be
 * constructed with function from getterKeys, and return type is inferred and
 * validated based on which key function is used.
 *
 * Our service methods return responses with all properties present on
 * SuccessResponse (status, statusText, etc), but we don't guarantee that
 * responses retrieved from the getter branch contain this metadata.
 * @param key - Key in query branch at which to store data; if null/undefined,
 *   fetcher not called
 * @param fetcher - Function that returns response with optional queryData
 *   property; if null/undefined, fetcher not called
 * @param options - Options object
 * @param options.intervalMs - Interval between end of fetcher call and next
 *   fetcher call
 * @param options.intervalRedefineFetcher - If true, fetcher is redefined each
 *   time it's called on interval, by forcing component to rerender (false by
 *   default)
 * @param options.noRefetch - If true, don't refetch if there's already data at
 *   key
 * @param options.noRefetchMs - If noRefetch is true, noRefetch behavior active
 *   for this many ms (forever by default)
 * @param options.refetchKey - Pass in new value to force refetch without
 *   changing key
 * @param options.updater - If passed, this function takes data currently at
 *   key, plus data in response, and returns updated data to be saved at key
 * @param options.dedupe - If true, don't call fetcher if another request was
 *   recently sent for key
 * @param options.dedupeMs - If dedupe is true, dedupe behavior active for this
 *   many ms (2000 by default)
 * @param options.catchError - If true, any error thrown by fetcher is caught
 *   and assigned to queryState.error property (true by default)
 * @param options.stateKeys - Additional keys in query state to include in
 *   return value (only data and dataMs included by default)
 * @param options.compare - Equality function compares previous query state with
 *   next query state; if it returns false, component rerenders, else it
 *   doesn't; uses shallowEqual by default
 *
 * @returns Query state at key, with subset of properties specified by stateKeys
 */
export const useQuery = <
  T extends keyof typeof getterKeys,
  U extends ReturnType<(typeof getterKeys)[T]>,
  D extends GetterData<U>,
  K extends StateKey[] = [],
>(
  key: U | null | undefined,
  fetcher: (() => Promise<SendToApiResponseOnlyData<D>>) | null | undefined,
  options: QueryOptions<SuccessResponseOnlyData<D> | null> &
    QueryStateOptions<K, SuccessResponseOnlyData<D>> & {
      intervalMs?: number
      intervalRedefineFetcher?: boolean
      noRefetch?: boolean
      noRefetchMs?: number
      refetchKey?: any
    } = {},
) => {
  return useReduxQuery(key, fetcher, options)
}

export const backendErrorCodes = {
  // protection error codes
  recipeIsProtected: 'protected_recipe_update_error',
  // password error codes
  userLockoutTooManyAttempts: 'too_many_attempts',
  passwordTooCommon: 'password_too_common',
  // smart group error codes
  smartGroupsNotEnoughResults: 'not_enough_results',
  // labels error codes
  labelAlreadyExists: 'tool_label_value_exists',
  // aoi delete error codes
  aoiHasToolResults: 'aoi_has_toolresult',
  eventTypeRulesAlreadyExists: 'event_type_rules_exist',
} as const

type BackendErrorCodes = (typeof backendErrorCodes)[keyof typeof backendErrorCodes]
