import React, { useEffect } from 'react'

import { message } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import PasswordInput from 'components/PasswordInput/PasswordInput'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismInputNumber } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { useData } from 'hooks'
import Shared from 'styles/Shared.module.scss'
import { User } from 'types'
import { getSessionLengthDisplayText, getSessionLengthUnitsAndTime, matchRole } from 'utils'
import { SECONDS_IN_DAY, SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from 'utils/constants'

import Styles from './Settings.module.scss'

const errorTypes = {
  noMatch: 'no-match',
  wrongFormat: 'wrong-format',
  required: 'required',
}

// Checks that the input is at least 8  characters long. This is an external function in case we wish to add some more validations in the future, e.g. at least one uppercase or number or character
const validatePasswordType = (password: string, confirmPassword?: string, checkConfirmationPassword?: boolean) => {
  if (!password) return

  if (checkConfirmationPassword && confirmPassword) {
    if (password !== confirmPassword) return 'Make sure New Password matches Confirm New Password'
    if (confirmPassword.length < 8) return 'Your password must be at least 8 characters long'

    return
  }

  if (password.length < 8) return 'Your password must be at least 8 characters long'
}

const ChangePasswordForm = () => {
  const me = useData(getterKeys.me())
  const dispatch = useDispatch()
  const defaultValues = getDefaultValues(me)
  const {
    formState: { isDirty, isValid, errors, dirtyFields },
    control,
    reset,
    trigger,
    getValues,
    watch,
  } = useForm({ defaultValues, mode: 'onChange' })

  const { sessionLengthTime, sessionLengthUnits } = watch()

  useEffect(() => {
    if (!me) return

    reset(getDefaultValues(me))
  }, [me, reset])

  const handleSubmit = async () => {
    const valid = await trigger()
    if (!valid) return

    if (dirtyFields.sessionLengthTime || dirtyFields.sessionLengthUnits) {
      const { sessionLengthTime, sessionLengthUnits } = getValues()

      let sessionLengthSeconds = sessionLengthTime
      if (sessionLengthUnits === 'day') sessionLengthSeconds *= SECONDS_IN_DAY
      else if (sessionLengthUnits === 'hour') sessionLengthSeconds *= SECONDS_IN_HOUR
      else if (sessionLengthUnits === 'minute') sessionLengthSeconds *= SECONDS_IN_MINUTE

      const res = await query(getterKeys.me(), () => service.meUpdate({ session_length_s: sessionLengthSeconds }), {
        dispatch,
      })
      if (res?.type === 'success') {
        success({ title: 'Information updated', 'data-testid': 'profile-update-success' })
      } else {
        error({ title: 'There was a problem updating your personal information' })
      }
    }

    if (dirtyFields.newPassword) {
      const { currentPassword, newPassword } = getValues()

      const res = await service.setPassword(currentPassword, newPassword)

      if (res.type === 'success') message.success('Saved')
      else if (res.type === 'error' && res.data?.code === 'password_too_common') {
        message.error('Your password is too common')
      } else
        modal.error({
          id: 'change-password-error',
          header: "Couldn't change password",
          okText: 'Dismiss',
          content: (
            <div>
              <br />- Make sure you typed your current password correctly
              <br />- Your new password must not be too generic, e.g. "password"
            </div>
          ),
        })
      reset(getDefaultValues(me))
    }
  }

  /** This functions checks the password and triggers validation for confirmPassword.
   */
  const validatePasswords = () => {
    const { newPassword, confirmNewPassword } = getValues()
    if (newPassword) {
      if (confirmNewPassword) trigger('confirmNewPassword')

      return validatePasswordType(newPassword)
    }
  }

  /** This functions checks the password and triggers validation for newPassword.
   */
  const validateConfirmationPassword = () => {
    const { newPassword, confirmNewPassword } = getValues()
    if (confirmNewPassword) {
      if (newPassword) trigger('currentPassword')

      return validatePasswordType(newPassword, confirmNewPassword, true)
    }
  }

  return (
    <>
      <LeavePagePrompt when={isDirty} />

      <PrismContainer
        headerElementsAlign="vertical"
        title="Security"
        className={Shared.rightSectionContainer}
        bodyClassName={Styles.bodyContainer}
        actions={
          <Button
            htmlType="submit"
            disabled={!isDirty || !isValid}
            onClick={handleSubmit}
            size="small"
            data-testid="password-save-button"
          >
            Save
          </Button>
        }
      >
        <div className={`${Styles.formWrapper} ${Shared.verticalChildrenGap32}`}>
          <div className={`${Styles.formContainer} ${Shared.verticalChildrenGap24}`}>
            <h2 className={Styles.formSectionTitle}>Login Expiration</h2>

            <div className={Shared.verticalChildrenGap8}>
              <div className={Styles.sessionLengthControls}>
                <Controller
                  name="sessionLengthTime"
                  rules={{
                    required: true,
                    validate: value => {
                      const units = getValues().sessionLengthUnits
                      if (value < 1 || (units === 'minute' && value < 15)) return 'Minimum length is 15 minutes'
                    },
                  }}
                  control={control}
                  render={props => (
                    <>
                      <PrismInputNumber
                        disabled={!matchRole(me, 'manager')}
                        {...props}
                        onChange={value => {
                          props.onChange(value || 0)
                          trigger('sessionLengthUnits')
                        }}
                        wrapperClassName={Styles.sessionLengthTimeInput}
                        errors={errors}
                        label="Session length"
                        data-testid="session-length-input"
                        type="number"
                      />
                    </>
                  )}
                />
                <Controller
                  name="sessionLengthUnits"
                  control={control}
                  rules={{
                    required: true,
                    validate: units => {
                      const time = getValues().sessionLengthTime
                      if (time < 1 || (units === 'minute' && time < 15)) return 'Minimum length is 15 minutes'
                    },
                  }}
                  render={props => (
                    <PrismSelect
                      disabled={!matchRole(me, 'manager')}
                      {...props}
                      onChange={e => {
                        props.onChange(e)
                        trigger('sessionLengthTime')
                      }}
                      wrapperClassName={
                        errors.sessionLengthTime || errors.sessionLengthUnits ? Styles.formErrorCaption : ''
                      }
                      data-testid="session-length-unit"
                      size="large"
                      options={[
                        {
                          value: 'day',
                          title: 'days',
                          dataTestId: 'session-length-days',
                        },
                        {
                          value: 'hour',
                          title: 'hours',
                          dataTestId: 'session-length-hours',
                        },
                        {
                          value: 'minute',
                          title: 'minutes',
                          dataTestId: 'session-length-minutes',
                        },
                      ]}
                    />
                  )}
                />
              </div>
              <p className={Styles.sessionLengthCaption}>
                You will be logged out {getSessionLengthDisplayText(sessionLengthTime, sessionLengthUnits)} after you
                log in.
              </p>
            </div>
          </div>
          <Divider />
          <div className={`${Styles.formContainer} ${Shared.verticalChildrenGap24}`}>
            <div className={Shared.verticalChildrenGap8}>
              <h2 className={Styles.formSectionTitle}>Password Change</h2>
              <p className={Styles.formSectionSubTitle}>You will be logged out after changing your password.</p>
            </div>
            <Controller
              name="currentPassword"
              rules={{ required: !!getValues().newPassword, validate: validatePasswordType }}
              control={control}
              render={props => (
                <PasswordInput
                  {...props}
                  wrapperClassName={Styles.inputWrapper}
                  errors={errors}
                  label="Current Password"
                  data-testid="password-current"
                />
              )}
            />

            <Controller
              name="newPassword"
              rules={{ validate: () => validatePasswords() }}
              control={control}
              render={props => (
                <PasswordInput
                  {...props}
                  wrapperClassName={Styles.inputWrapper}
                  errors={errors}
                  label="New Password"
                  data-testid="password-new"
                />
              )}
            />

            <Controller
              name="confirmNewPassword"
              rules={{ required: !!getValues().newPassword, validate: () => validateConfirmationPassword() }}
              control={control}
              render={props => (
                <PasswordInput
                  {...props}
                  wrapperClassName={Styles.inputWrapper}
                  errors={errors}
                  label="Confirm new password"
                  data-testid="password-confirm"
                />
              )}
            />
            {errors.confirmNewPassword?.message === errorTypes.noMatch && (
              <div className={Styles.inlineErrorMessage}>Make sure New Password matches Confirm New Password</div>
            )}
          </div>
        </div>
      </PrismContainer>
    </>
  )
}

export default ChangePasswordForm

const getDefaultValues = (user: User | undefined) => {
  const timeAndUnits = getSessionLengthUnitsAndTime(user?.session_length_s || 0)
  return {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    sessionLengthTime: timeAndUnits?.time,
    sessionLengthUnits: timeAndUnits?.unit,
  }
}
