import React from 'react'

import { Ellipse, Line, Rect } from 'react-konva'

import { BoxWithShape, Outcome } from 'types'
import { toRadians } from 'utils'

export const MaskingRectangle = ({
  imageWidth,
  imageHeight,
  scaling,
  maskingRectangleFill,
}: {
  imageWidth: number
  imageHeight: number
  scaling: number
  maskingRectangleFill?: string
}) => {
  return (
    <Rect
      x={0}
      y={0}
      width={imageWidth * scaling}
      height={imageHeight * scaling}
      fill={maskingRectangleFill || 'rgba(1,1,1 ,0.45)'}
      listening={false}
      name={'shadow'}
    />
  )
}

export interface AoiOutlineConfiguration extends BoxWithShape {
  id?: string
  applyMask?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onClick?: (e: any) => any
  strokeWidth?: number
  stroke?: string
  fill?: string
  outcomeOrStatus?: AoiOutcomeOrStatus
  overlaySrc?: string
}

export interface AoiOutlineProps extends AoiOutlineConfiguration {
  scaling: number
  imageWidth: number
  imageHeight: number
  maskingRectangleFill?: string
  cutoutOnly?: boolean
  innerOnly?: boolean
}
// TODO: Comment this component
const AoiOutline = (props: AoiOutlineProps) => {
  const { x, y, width, height, imageWidth, imageHeight, applyMask, scaling, shape, cutoutOnly, innerOnly } = props
  let renderedStroke = null
  let ghostFill = null
  if (shape?.type === 'polygon' && !!shape.data) {
    const flattenedPoints: number[] = []
    shape.data!.forEach(pt => {
      flattenedPoints.push((pt[0] * width + x) * scaling, (pt[1] * height + y) * scaling)
    })
    renderedStroke = <Line points={flattenedPoints} closed {...visibleProps(props)} />
    ghostFill = <Line points={flattenedPoints} closed {...ghostProps({ innerOnly })} />
  } else if (shape?.type === 'ellipse' && !!shape.data) {
    const originX = (x + width / 2) * scaling
    const originY = (y + height / 2) * scaling
    const radiusX = shape.data.radiusX * width * scaling
    const radiusY = shape.data.radiusY * height * scaling
    const rotationDegrees = shape.data.rotationDegrees
    renderedStroke = (
      <Ellipse
        x={originX}
        y={originY}
        radiusX={radiusX}
        radiusY={radiusY}
        rotation={rotationDegrees}
        {...visibleProps(props)}
      />
    )
    ghostFill = (
      <Ellipse
        x={originX}
        y={originY}
        radiusX={radiusX}
        radiusY={radiusY}
        rotation={rotationDegrees}
        {...ghostProps({ innerOnly })}
      />
    )
  } else if (shape?.type === 'rectangle' && !!shape.data) {
    const rectWidth = shape.data.width * width * scaling
    const rectHeight = shape.data.height * height * scaling
    const rotationDegrees = shape.data.rotationDegrees % 180 // Constrain angle to usable range

    const scaledX = x * scaling
    const scaledY = y * scaling

    let originX = 0
    let originY = 0
    if (0 <= rotationDegrees && rotationDegrees <= 90) {
      originX = scaledX + Math.sin(toRadians(180 - rotationDegrees)) * rectHeight
      originY = scaledY
    } else if (90 < rotationDegrees && rotationDegrees <= 180) {
      originX = (x + width) * scaling
      originY = scaledY + Math.sin(toRadians(rotationDegrees - 90)) * rectHeight
    } else if (-90 <= rotationDegrees && rotationDegrees < 0) {
      originX = scaledX
      originY = scaledY + Math.sin(toRadians(-rotationDegrees)) * rectWidth
    } else if (-180 <= rotationDegrees && rotationDegrees < -90) {
      originX = scaledX + Math.sin(toRadians(-rotationDegrees - 90)) * rectWidth
      originY = (y + height) * scaling
    }

    renderedStroke = (
      <Rect
        x={originX}
        y={originY}
        width={rectWidth}
        height={rectHeight}
        rotation={rotationDegrees}
        {...visibleProps(props)}
      />
    )
    ghostFill = (
      <Rect
        x={originX}
        y={originY}
        width={rectWidth}
        height={rectHeight}
        rotation={rotationDegrees}
        {...ghostProps({ innerOnly })}
      />
    )
  } else {
    renderedStroke = (
      <Rect
        x={x * scaling}
        y={y * scaling}
        width={width * scaling}
        height={height * scaling}
        {...visibleProps(props)}
      />
    )
    ghostFill = (
      <Rect
        x={x * scaling}
        y={y * scaling}
        width={width * scaling}
        height={height * scaling}
        {...ghostProps({ innerOnly })}
      />
    )
  }
  return (
    <>
      {applyMask && (
        <>
          {!cutoutOnly && !innerOnly && (
            <MaskingRectangle
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              scaling={scaling}
              maskingRectangleFill={props.maskingRectangleFill}
            />
          )}
          {ghostFill}
        </>
      )}
      {!cutoutOnly && renderedStroke}
    </>
  )
}

export type AoiOutcomeOrStatus = Outcome | 'selected' | 'noResult'

const strokeAndFillByOutcome: {
  [K in AoiOutcomeOrStatus]: { stroke?: string; fill?: string }
} = {
  unknown: { stroke: '#c9c9c9' },
  pass: { stroke: '#5CD6C6' },
  fail: { stroke: '#ED523D' },
  selected: { stroke: '#1563E2' },
  noResult: { stroke: '#c9c9c9' },
}

const visibleProps = (props: AoiOutlineProps) => {
  const { fill: outcomeFill, stroke: outcomeStroke } =
    (props.outcomeOrStatus && strokeAndFillByOutcome[props.outcomeOrStatus]) || {}

  return {
    stroke: props.stroke || outcomeStroke || 'rgba(254, 254, 254, 1)',
    fill: props.fill || outcomeFill,
    strokeWidth: props.strokeWidth || 1,
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick,
  } as const
}

const ghostProps = ({ innerOnly }: { innerOnly: boolean | undefined }) =>
  ({
    fill: 'rgba(0, 0, 0, 1)',
    globalCompositeOperation: innerOnly ? 'destination-in' : 'destination-out',
    listening: false,
  } as const)

export default AoiOutline
