import React, { CSSProperties, useEffect, useMemo, useRef } from 'react'

import { uniq } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FixedSizeGrid, GridChildComponentProps, ListChildComponentProps, VariableSizeList } from 'react-window'
import {
  GridElementScrollerChildrenProps,
  ListElementScrollerChildrenProps,
  ReactWindowElementScroller,
} from 'react-window-element-scroller'

import { getterKeys } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Divider } from 'components/Divider/Divider'
import { IconButton } from 'components/IconButton/IconButton'
import { LabelCard } from 'components/LabelCard/LabelCard'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismArrowIcon, PrismCloseIcon, PrismSearchIcon } from 'components/prismIcons'
import { PrismLoader, PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import {
  useAllToolLabels,
  useContainerDimensions,
  useData,
  useGridDimensions,
  useItemsForAnalyzeWithProxyResults,
  useQueryParams,
} from 'hooks'
import { DetailModal } from 'pages/ItemDetail/DetailModal'
import { getterUpdate } from 'rdx/actions'
import { AnalyzeTab, ItemExpanded, ItemWithFallbackImages, ToolLabel } from 'types'
import {
  appendItemOrToolResultIdPictureIdOrLastSelectedToQs,
  getItemCalculatedLabels,
  sortByLabelKind,
  sortBySeverity,
} from 'utils'
import { ANALYZE_HORIZONTAL_PADDING_BREAKPOINT } from 'utils/constants'

import {
  ANALYZE_HORIZONTAL_PADDING,
  ANALYZE_SMALL_HORIZONTAL_PADDING,
  ANALYZE_VERTICAL_PADDING,
  AnalyzeWithGalleryProps,
  CardRendererProps,
} from './AnalyzeBase'
import { FilterDisabledTooltip, Filters } from './AnalyzeFilters'
import Styles from './AnalyzeGallery.module.scss'
import { ItemCard } from './AnalyzeItems'
import { LabelSelect } from './LabelSelect'

type AnalyzeGalleryProps = Pick<AnalyzeWithGalleryProps, 'isGalleryExpanded' | 'setIsGalleryExpanded'> & {
  tab: Exclude<AnalyzeTab, 'tools'>
  parentContainerRef: React.RefObject<HTMLDivElement>
  titleRef: React.RefObject<HTMLDivElement>
  filters: Filters
  transitionInProgress?: boolean
  setTransitionInProgress: React.Dispatch<React.SetStateAction<boolean>>
}

interface ListCardRendererProps<T> {
  elements: T[] | undefined
  onClick: (item: T) => void
  allToolLabels: ToolLabel[] | undefined
}

const VERTICAL_PADDINGS = 3 // Parent container has padding at the top and container has padding at top and bottom
const PARENT_CONTAINER_CHILDREN = 2
const LIST_CARD_HEIGHT = 80
const LIST_VERTICAL_HEIGHT = 16

export const AnalyzeGallery = ({
  tab,
  isGalleryExpanded,
  setIsGalleryExpanded,
  parentContainerRef,
  titleRef,
  filters,
  transitionInProgress,
  setTransitionInProgress,
}: AnalyzeGalleryProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { allToolLabels } = useAllToolLabels()
  const [params] = useQueryParams()
  const { detail_item_id } = params

  const { items, handleEndReached, reloadingItems, loadingMore, filterOptions } = useItemsForAnalyzeWithProxyResults({
    tab,
  })
  const itemsData = useData(getterKeys.analyticsItems())
  const galleryBodyRef = useRef<HTMLDivElement>(null)
  const outerRef = useRef<HTMLDivElement>(null)
  const listDimensions = useContainerDimensions(galleryBodyRef)
  // Calculate correct horizontal padding according to window size
  const { width: windowWidth } = useContainerDimensions()
  let horizontalPaddingToUse = ANALYZE_HORIZONTAL_PADDING
  if (windowWidth && windowWidth <= ANALYZE_HORIZONTAL_PADDING_BREAKPOINT)
    horizontalPaddingToUse = ANALYZE_SMALL_HORIZONTAL_PADDING

  const isFetchingMoreRef = useRef(false)
  const listRef = useRef<VariableSizeList>(null)
  const gridRef = useRef<FixedSizeGrid>(null)
  const visibleItemIndex = useRef<number>(0)
  const { rowCount, columnCount, columnWidth, rowHeight } = useGridDimensions(
    // We are dividng the parent container by two, since once the gallery is expanded it has half the width of the parentcontainer
    (parentContainerRef.current?.clientWidth || 0) / PARENT_CONTAINER_CHILDREN - horizontalPaddingToUse, // container right padding
    items?.length,
    { gridGap: 16, minWidth: 140, elementRowHeight: 193 },
  )

  const {
    component_id,
    inspection_id,
    recipe_id,
    station_id,
    prediction_label_id,
    user_label_id,
    user_id,
    tool_specification,
    start,
    end,
    period,
    outcome,
    site_id,
    subsite_id,
  } = filters

  const handleOnClick = (item: ItemWithFallbackImages) => {
    appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, { itemId: item.id })
  }

  useEffect(() => {
    if (transitionInProgress) return
    if (isGalleryExpanded)
      gridRef.current?.scrollToItem({
        rowIndex: visibleItemIndex.current / columnCount,
        columnIndex: 0,
        align: 'start',
      })
    else listRef.current?.scrollToItem(visibleItemIndex.current, 'start')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionInProgress])

  // Resets the gallery body scroll to top whenever a filter is changed
  useEffect(() => {
    const galleryBody = galleryBodyRef.current
    if (!galleryBody) return
    galleryBody.scrollTo({ top: 0, behavior: 'smooth' })
  }, [
    component_id,
    inspection_id,
    recipe_id,
    station_id,
    prediction_label_id,
    user_label_id,
    user_id,
    tool_specification,
    start,
    end,
    period,
    outcome,
    site_id,
    subsite_id,
  ])

  // We are doing inline styles to match the size of the container and the values passed to the virtualized grid/list
  const sideGalleryStyle = useMemo(() => {
    const galleryStyle: CSSProperties = {
      paddingLeft: horizontalPaddingToUse,
    }
    if (isGalleryExpanded) {
      galleryStyle.paddingTop = ANALYZE_VERTICAL_PADDING
    } else {
      galleryStyle.paddingRight = horizontalPaddingToUse
    }
    return galleryStyle
  }, [isGalleryExpanded, horizontalPaddingToUse])

  const galleryGrid = (
    <ReactWindowElementScroller
      type="grid"
      scrollerElementRef={galleryBodyRef}
      gridRef={gridRef}
      outerRef={outerRef}
      childrenStyle={{ height: 'fit-content' }}
    >
      {({ style, onScroll }: GridElementScrollerChildrenProps) => (
        <FixedSizeGrid
          ref={gridRef}
          rowCount={rowCount}
          columnCount={columnCount}
          columnWidth={columnWidth}
          rowHeight={rowHeight}
          height={
            (parentContainerRef.current?.clientHeight || 0) -
            ((titleRef.current?.clientHeight || 0) + ANALYZE_VERTICAL_PADDING * VERTICAL_PADDINGS)
          }
          width={(parentContainerRef.current?.clientWidth || 0) / PARENT_CONTAINER_CHILDREN}
          itemData={{
            elements: items,
            columnCount,
            onClick: handleOnClick,
            showInsights: false,
            allToolLabels,
          }}
          style={style}
          onScroll={onScroll}
          onItemsRendered={async ({ visibleRowStartIndex, visibleRowStopIndex }) => {
            if (isFetchingMoreRef.current) return
            const visibleIndex = visibleRowStartIndex * columnCount
            if (visibleIndex > 0) visibleItemIndex.current = visibleIndex

            if (visibleRowStopIndex + 5 >= rowCount) {
              isFetchingMoreRef.current = true
              await handleEndReached?.()
              isFetchingMoreRef.current = false
            }
          }}
        >
          {CardRenderer}
        </FixedSizeGrid>
      )}
    </ReactWindowElementScroller>
  )

  const listGrid = (
    <ReactWindowElementScroller
      type="list"
      scrollerElementRef={galleryBodyRef}
      listRef={listRef}
      outerRef={outerRef}
      childrenStyle={{ height: 'fit-content' }}
    >
      {({ style, onScroll }: ListElementScrollerChildrenProps) => (
        <VariableSizeList
          ref={listRef}
          width={listDimensions?.width || 0 - horizontalPaddingToUse}
          height={listDimensions?.height || 0 - ANALYZE_VERTICAL_PADDING * 2}
          itemCount={items?.length || 0}
          itemSize={() => LIST_CARD_HEIGHT + LIST_VERTICAL_HEIGHT}
          style={style}
          onScroll={onScroll}
          onItemsRendered={async ({ visibleStopIndex, visibleStartIndex }) => {
            if (isFetchingMoreRef.current) return
            if (visibleStartIndex > 0) visibleItemIndex.current = visibleStartIndex
            if (visibleStopIndex + 5 >= rowCount) {
              isFetchingMoreRef.current = true
              await handleEndReached?.()
              isFetchingMoreRef.current = false
            }
          }}
          itemData={{
            elements: items,
            onClick: handleOnClick,
            allToolLabels,
          }}
        >
          {CardRenderer}
        </VariableSizeList>
      )}
    </ReactWindowElementScroller>
  )

  const handleRefresh = (newItem: ItemExpanded) => {
    const calculatedLabels = getItemCalculatedLabels(newItem)
    dispatch(
      getterUpdate({
        key: getterKeys.analyticsItems(),
        updater: prevRes => {
          if (prevRes) {
            const newRes = prevRes.data.results.map(item =>
              item.id === newItem.id
                ? {
                    ...item,
                    serial_number: newItem.serial_number,
                    calculated_outcome: newItem.calculated_outcome,
                    calculated_labels: calculatedLabels,
                  }
                : item,
            )
            return { ...prevRes, data: { ...prevRes.data, results: newRes } }
          }
        },
      }),
    )
  }

  const isDisplayingBlankGallery = items?.length === 0
  const isEmptyGalleryLoading = !items || transitionInProgress || reloadingItems

  const renderGalleryView = () => {
    if (isDisplayingBlankGallery)
      return (
        <GenericBlankStateMessage
          header={isGalleryExpanded ? <PrismSearchIcon /> : null}
          description={`No items ${isGalleryExpanded ? 'match your filters' : ''}`}
          className={Styles.emptyGalleryState}
        />
      )
    if (isEmptyGalleryLoading) return <EmptySideGallery isLoading />
    const toRender = []
    if (items?.length) {
      if (isGalleryExpanded) toRender.push(galleryGrid)
      else toRender.push(listGrid)
    }
    if (loadingMore)
      toRender.push(
        <div className={Styles.bottomSpinner}>
          <PrismLoader />
        </div>,
      )
    return toRender
  }

  return (
    <>
      <AnalyzeGalleryDivider
        isGalleryExpanded={isGalleryExpanded}
        setIsGalleryExpanded={(bool: boolean) => {
          setIsGalleryExpanded(bool)
          setTransitionInProgress(true)
        }}
      />
      <section
        onTransitionEnd={event => {
          // There are multiple transitions when opening/closing the side gallery, so we need to wait until the longest animation finishes to set the state
          if (event.propertyName !== 'width') return
          setTransitionInProgress(false)
        }}
        className={`${Styles.collapsedLayout} ${isGalleryExpanded ? Styles.expandedLayout : ''}`}
      >
        <div style={sideGalleryStyle} className={isGalleryExpanded ? Styles.galleryExpanded : Styles.galleryCollapsed}>
          <PrismContainer
            title={'Gallery'}
            bodyRef={galleryBodyRef}
            headerTitleAction={
              <IconButton
                icon={<PrismCloseIcon />}
                size="small"
                type="tertiary"
                onClick={() => {
                  setTransitionInProgress(true)
                  setIsGalleryExpanded(false)
                }}
                className={Styles.closeGalleryButton}
              />
            }
            headerActionsClassName={Styles.galleryTitle}
            bodyClassName={`${Styles.galleryGridBody} ${isDisplayingBlankGallery ? Styles.blankGalleryGrid : ''}`}
            actions={
              <div className={Styles.filtersContainer}>
                <FilterDisabledTooltip
                  filterToHover="prediction"
                  filterThatForceDisabled={!!filters?.outcome?.length ? 'outcome' : 'search'}
                  condition={!!filters?.outcome?.length}
                  className={Styles.multiSelectWrapper}
                >
                  <LabelSelect
                    filters={filters}
                    labelType="prediction"
                    onUpdateFilters={filterOptions.onUpdateFilters}
                    size="small"
                    disabled={!!filters?.outcome?.length}
                    resultButtonClassName={Styles.prismResultInSelector}
                    popupClassName={Styles.galleryFiltersDropdown}
                    selectClassName={Styles.labelSelect}
                  />
                </FilterDisabledTooltip>
                <FilterDisabledTooltip
                  filterToHover="label"
                  filterThatForceDisabled="search"
                  condition={!!filters?.serial_number}
                  className={Styles.multiSelectWrapper}
                >
                  <LabelSelect
                    filters={filters}
                    labelType="user"
                    onUpdateFilters={filterOptions.onUpdateFilters}
                    size="small"
                    resultButtonClassName={Styles.prismResultInSelector}
                    popupClassName={Styles.galleryFiltersDropdown}
                    selectClassName={Styles.labelSelect}
                  />
                </FilterDisabledTooltip>
              </div>
            }
          >
            <div
              className={`
            ${Styles.galleryGrid}
            ${isGalleryExpanded ? Styles.expandedGalleryGrid : Styles.collapsedGalleryList}`}
              data-testid="analyze-side-gallery"
              data-test-attribute={items?.length}
            >
              {renderGalleryView()}
            </div>
            {detail_item_id && items && <DetailModal onRefresh={handleRefresh} itemsInitialRes={itemsData} />}
          </PrismContainer>
        </div>
      </section>
    </>
  )
}

const CardRenderer = React.memo(
  <T extends ItemWithFallbackImages>({
    style,
    ...props
  }: GridChildComponentProps<CardRendererProps<T>> | ListChildComponentProps<ListCardRendererProps<T>>) => {
    const { elements: items, onClick, allToolLabels } = props.data
    let listIndex: number
    if ('rowIndex' in props) listIndex = props.rowIndex * props.data.columnCount + props.columnIndex
    else listIndex = props.index
    const item = items?.[listIndex]

    const itemLabels = useMemo(() => {
      if (!item || !allToolLabels) return []
      const uniqueLabelIds = uniq(item.calculated_labels)
      const filteredLabels = allToolLabels.filter(lbl => uniqueLabelIds.includes(lbl.id))
      const sortedLabelsByKind = filteredLabels.sort(sortByLabelKind)
      return sortBySeverity(sortedLabelsByKind)
    }, [item, allToolLabels])

    if (!item) return null
    return (
      <div style={{ ...style }} className={Styles.galleryCardGap}>
        <ItemCard
          itemLabels={itemLabels}
          onClick={() => {
            onClick(item)
          }}
          item={item}
          key={item.id}
          className={`${Styles.analyzeGalleryCard} ${Styles.card}`}
          outcomeClassName={Styles.cardOutcome}
          imageClassName={Styles.cardImage}
          figureClassName={Styles.figureContainer}
        />
      </div>
    )
  },
)

const EmptySideGallery = ({ isLoading = false }: { isLoading?: boolean }) => {
  return (
    <>
      {Array(50)
        .fill(undefined)
        .map((_, i) => (
          <div className={Styles.blankCardWrapper} key={i}>
            <LabelCard
              key={i}
              className={`${Styles.card} ${Styles.blankState} ${isLoading ? Styles.cardIsLoading : ''}`}
              figureClassName={Styles.figureContainer}
              imageClassName={Styles.cardImage}
              type="ghost4"
              label={<span className={Styles.blankCardLabel}>--</span>}
              hasCubeWithBackground={false}
              isLoading={isLoading}
              image={isLoading ? <PrismSkeleton size="extraLarge" /> : undefined}
              borderType="borderless"
            />
          </div>
        ))}
    </>
  )
}

/**
 * Renders a divider with a button
 *
 * @param isGalleryExpanded - boolean to pass the values expanded or collapsed
 * @param setIsGalleryExpanded - pointer for the function to change the boolean value
 
 */
const AnalyzeGalleryDivider = ({
  isGalleryExpanded,
  setIsGalleryExpanded,
  hideExpandButton,
}: {
  isGalleryExpanded: boolean
  setIsGalleryExpanded: (bool: boolean) => void
  hideExpandButton?: boolean
}) => {
  return (
    <span className={Styles.expandDivider}>
      <div
        className={`${Styles.expandOverlay} ${hideExpandButton ? Styles.showOverlay : Styles.hideOverlay} ${
          isGalleryExpanded ? Styles.shrinkOverlay : Styles.growOverlay
        }`}
      />
      <IconButton
        icon={<PrismArrowIcon />}
        size="xsmall"
        type="secondary"
        className={`${Styles.expandButton} ${isGalleryExpanded ? Styles.rotateIcon : ''} ${
          hideExpandButton ? Styles.hideExpandButton : ''
        }`}
        onClick={() => {
          setIsGalleryExpanded(!isGalleryExpanded)
        }}
      />

      <Divider type="vertical" className={Styles.verticalDivider} />
    </span>
  )
}
