import React, { useCallback, useEffect, useMemo } from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { uniq } from 'lodash'
import momentTz from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GridChildComponentProps } from 'react-window'

import { getterKeys } from 'api'
import { BackTop, useBackTopWithClassName } from 'components/BackTop/BackTop'
import ImgFallback from 'components/Img/ImgFallback'
import { LabelCard } from 'components/LabelCard/LabelCard'
import LabelsList from 'components/LabelList/LabelsList'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { PrismToggleGroup } from 'components/PrismToggleGroup/PrismToggleGroup'
import {
  useAllToolLabels,
  useData,
  useDateTimePreferences,
  useItemsForAnalyzeWithProxyResults,
  useQueryParams,
} from 'hooks'
import { DetailModal } from 'pages/ItemDetail/DetailModal'
import * as Actions from 'rdx/actions'
import { Item, ItemExpanded, ItemWithFallbackImages, PdfData, ToolLabel, ToolResultOutcome } from 'types'
import {
  appendItemOrToolResultIdPictureIdOrLastSelectedToQs,
  getImageFromItem,
  getItemCalculatedLabels,
  getTimezoneAbbr,
  sortByLabelKind,
  sortBySeverity,
} from 'utils'
import { ANALYZE_ITEMS_OR_TOOLS_TABLE_ROW_HEIGHT, PDF_CARD_LIMIT, TABLE_HEADER_HEIGHT } from 'utils/constants'

import Styles from './Analyze.module.scss'
import { CardRendererProps, renderToolResultsColumn, useVirtualizedGallery, useVirtualizedTable } from './AnalyzeBase'
import { AnalyzeEmptyState } from './AnalyzeEmptyState'
import AnalyzeFilters from './AnalyzeFilters'

const BREAKPOINTS_BY_ITEMS_COLUMNS_KEY = {
  routine: { 1360: 0 },
  component__name: { 1360: 0 },
  inspection__name: { 1360: 0 },
}

export const itemsPdfContainerId = 'items-pdf-pictures-container'

export const AnalyzeItems = ({ setPdfData }: { setPdfData: (data: PdfData | undefined) => void }) => {
  const { allToolLabels } = useAllToolLabels()

  const dispatch = useDispatch()
  const history = useHistory()
  const [params] = useQueryParams<'detail_item_id' | 'detail_tool_result_id' | 'lastSelectedId' | 'event_id'>()

  const { detail_item_id, detail_tool_result_id } = params

  const { items, handleEndReached, reloadingItems, loadingMore, filterOptions } = useItemsForAnalyzeWithProxyResults({
    tab: 'items',
  })
  const { viewMode = 'gallery' } = filterOptions

  const { timeFormat, timeZone } = useDateTimePreferences()

  const itemsData = useData(getterKeys.analyticsItems())

  const columns = useMemo(() => {
    return getColumns({ timeFormat, timeZone, allToolLabels })
  }, [timeFormat, timeZone, allToolLabels])

  const handleRefresh = (newItem: ItemExpanded) => {
    const calculatedLabels = getItemCalculatedLabels(newItem)
    dispatch(
      Actions.getterUpdate({
        key: getterKeys.analyticsItems(),
        updater: prevRes => {
          if (prevRes) {
            const newRes = prevRes.data.results.map(item =>
              item.id === newItem.id
                ? {
                    ...item,
                    serial_number: newItem.serial_number,
                    calculated_outcome: newItem.calculated_outcome,
                    calculated_labels: calculatedLabels,
                  }
                : item,
            )
            return { ...prevRes, data: { ...prevRes.data, results: newRes } }
          }
        },
      }),
    )
  }

  const handleTableRowClick = (item: Item) => {
    appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, { itemId: item.id })
  }

  const isRowActive = useCallback(
    (item: Item) => {
      return params.lastSelectedId === item.id
    },
    [params.lastSelectedId],
  )

  const { columnsWithWidths, renderVirtualTable, tableContainerRef, tableHeight } = useVirtualizedTable(columns, {
    onClick: handleTableRowClick,
    rowHeight: ANALYZE_ITEMS_OR_TOOLS_TABLE_ROW_HEIGHT,
    handleEndReached,
    breakPointsByColumnKey: BREAKPOINTS_BY_ITEMS_COLUMNS_KEY,
    isRowActive,
  })

  const { galleryBodyRef, galleryGrid } = useVirtualizedGallery(
    items,
    allToolLabels,
    handleEndReached,
    item => appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, { itemId: item.id }),
    false,
    CardRenderer,
  )

  useBackTopWithClassName('ant-table-body')

  // This effect sets the chart or table data to be used on the PDF
  useEffect(() => {
    if (!items) {
      setPdfData(undefined)
      return
    }

    const cappedItems = items.slice(0, PDF_CARD_LIMIT)

    setPdfData({
      type: 'items',
      viewMode,
      content: cappedItems,
    })
  }, [items, setPdfData, viewMode])

  useEffect(() => {
    return () => {
      // Reset PDF data when component unmounts
      setPdfData(undefined)
    }
  }, [setPdfData])

  const renderGalleryView = () => {
    let toRender = null
    if (!items) return <PrismLoader className={Styles.galleryInitialLoadingWrapper} />

    if (items?.length) toRender = [galleryGrid]
    if (items?.length === 0) toRender = [<AnalyzeEmptyState key="items" tab="items" isGalleryView />]

    if (items && reloadingItems)
      toRender?.push(
        <PrismLoader dataTestId="analyze-items-gallery-reload-loader" className={Styles.reloadingWrapper} />,
      )
    if (loadingMore)
      toRender?.push(
        <div className={Styles.bottomSpinner}>
          <PrismLoader />
        </div>,
      )
    return toRender
  }

  return (
    <PrismContainer
      bodyRef={galleryBodyRef}
      title="Items"
      className={Styles.analyzeItemsToolsMainBody}
      headerActionsClassName={Styles.headerTitle}
      bodyClassName={Styles.rightSectionBodyWrapper}
      actions={
        <div className={Styles.headerActionsContainer}>
          <AnalyzeFilters tab="items" data-testid="analyze-items-filters" />

          <PrismToggleGroup
            value={viewMode}
            onChange={value => filterOptions.onUpdateFilters({ viewMode: value }, 'push')}
            options={[
              {
                label: 'Gallery',
                type: 'default',
                value: 'gallery',
                dataTestId: 'analyze-items-gallery-button',
              },
              {
                label: 'List',
                type: 'default',
                value: 'list',
                dataTestId: 'analyze-items-list-button',
              },
            ]}
            size="small"
            className={Styles.radioButtonsWrapper}
          />
        </div>
      }
      data-testid="analyze-items-container"
    >
      {viewMode === 'gallery' && (
        <div
          className={Styles.galleryGridContainer}
          data-testid="analyze-items-gallery"
          data-test-attribute={items?.length}
        >
          {renderGalleryView()}
        </div>
      )}

      {viewMode === 'list' && (
        <div className={Styles.tableWrapper} ref={tableContainerRef}>
          <BackTop scrollContainerClassName="ant-table-body" />
          {tableHeight && (
            <Table
              data-testid="analyze-items-table"
              id="monitor-table"
              rowKey="id"
              className={Styles.tableContainer}
              rowClassName={Styles.tableRow}
              locale={{ emptyText: <AnalyzeEmptyState tab="items" /> }}
              loading={{
                spinning: !items || reloadingItems || !!params.event_id,
                wrapperClassName: Styles.tableSpinnerWrapper,
                indicator: <PrismLoader className={Styles.loaderPosition} />,
              }}
              pagination={false}
              scroll={{ y: tableHeight - TABLE_HEADER_HEIGHT }}
              columns={columnsWithWidths}
              components={items?.length ? { body: renderVirtualTable } : undefined}
              dataSource={items}
              onRow={item => ({
                onClick: () => {
                  appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, { itemId: item.id })
                },
              })}
            />
          )}

          {loadingMore && (
            <div className={Styles.bottomSpinner}>
              <PrismLoader />
            </div>
          )}
        </div>
      )}

      {(detail_item_id || detail_tool_result_id) && items && (
        <DetailModal onRefresh={handleRefresh} itemsInitialRes={itemsData} />
      )}
      <div id={itemsPdfContainerId}></div>
    </PrismContainer>
  )
}

type CardProps = {
  item: Item
  onClick: () => void
  itemLabels: ToolLabel[]
  className?: string
  outcomeClassName?: string
  imageClassName?: string
  figureClassName?: string
}

export const ItemCard = ({
  item,
  onClick,
  itemLabels,
  className = '',
  outcomeClassName = '',
  imageClassName = '',
  figureClassName = '',
}: CardProps) => {
  const [params] = useQueryParams()
  const image = useMemo(() => {
    const image = getImageFromItem(item, { preferThumbnail: true })

    const imageElement = image ? (
      <ImgFallback src={image} height="100%" loaderType="skeleton" />
    ) : (
      <PrismElementaryCube className={Styles.labelCardNoImageContainer} />
    )

    return <div className={Styles.galleryImageContainer}>{imageElement}</div>
  }, [item])

  return (
    <LabelCard
      data-test="analyze-items-label-card"
      active={params.lastSelectedId === item.id}
      onClick={onClick}
      label={<LabelsList labels={itemLabels} className={Styles.calculatedLabelsContainer} />}
      image={image}
      type="ghost4"
      outcome={<PrismOutcome icon={item.calculated_outcome} variant="dark" />}
      className={`${Styles.galleryCard} ${className}`}
      outcomeClassName={outcomeClassName}
      imageClassName={imageClassName}
      figureClassName={figureClassName}
      borderType="borderless"
    />
  )
}

const getColumns = ({
  timeFormat,
  timeZone,
  allToolLabels,
}: {
  timeFormat: string
  timeZone: string
  allToolLabels: ToolLabel[] | undefined
}): ColumnsType<Item> => {
  return [
    {
      title: 'Image',
      dataIndex: 'image_thumbnail',
      key: 'has_picture',
      width: 92,
      render: (thmb, item) => {
        const image = getImageFromItem(item, { preferThumbnail: true })

        let imageElement = image ? (
          <ImgFallback src={image} loaderType="skeleton" />
        ) : (
          <PrismElementaryCube className={Styles.labelCardNoImageContainer} />
        )

        if (!image) {
          // Adds opacity when there is no photo
          imageElement = <div className={Styles.noImageOverlay}>{imageElement}</div>
        }

        return <div className={Styles.imageContainer}>{imageElement}</div>
      },
    },
    {
      title: 'Outcome',
      dataIndex: 'calculated_outcome',
      key: 'calculated_outcome',
      width: 120,
      render: (outcome: ToolResultOutcome) => {
        const outcomeToShow = outcome === 'pass' || outcome === 'fail' ? outcome : 'unknown'
        return (
          <PrismResultButton
            severity={outcomeToShow}
            value={outcomeToShow}
            type="noFill"
            size="small"
            className={Styles.prismResultInTable}
          />
        )
      },
    },
    {
      title: 'Tool Results',
      dataIndex: 'calculated_labels',
      ellipsis: true,
      render: (labels: string[]) => {
        const uniqueLabelIds = uniq(labels)
        const filteredLabels = allToolLabels ? allToolLabels.filter(lbl => uniqueLabelIds.includes(lbl.id)) : []
        const sortedLabelsByKind = filteredLabels.sort(sortByLabelKind)
        const sortedByKindThenSeverity = sortBySeverity(sortedLabelsByKind)
        return renderToolResultsColumn(sortedByKindThenSeverity)
      },
    },
    {
      title: (
        <>
          Date <span>({getTimezoneAbbr(timeZone)})</span>
        </>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 150,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: date => momentTz(date).tz(timeZone).format(`MMM D, ${timeFormat}`),
    },

    {
      title: <div className={Styles.tableHeaderItemId}>Item ID</div>,
      dataIndex: 'serial_number',
      key: 'serial_number',
      ellipsis: true,
    },
    {
      title: 'Batch',
      dataIndex: 'inspection__name',
      key: 'inspection__name',
      ellipsis: true,
      render: (name, item) => item.inspection.name,
    },

    {
      title: 'Product',
      dataIndex: 'component__name',
      key: 'component__name',
      ellipsis: true,
      render: (name, item) => item.component.name,
    },

    {
      title: 'Recipe',
      dataIndex: 'recipe',
      key: 'recipe',
      ellipsis: true,
      render: (name, item) => {
        if (item.inspection.routines?.length) {
          const recipeParent = item.inspection.routines[0]?.parent
          const recipeNameAndVersion = `${recipeParent?.recipe_parent_name}  v${recipeParent?.recipe_parent_version}`
          return recipeNameAndVersion
        }
        return 'Unknown'
      },
    },
  ]
}

const CardRenderer = React.memo(
  <T extends ItemWithFallbackImages>({
    columnIndex,
    rowIndex,
    style,
    data,
  }: GridChildComponentProps<CardRendererProps<T>>) => {
    const { columnCount, elements: items, onClick, allToolLabels } = data

    const listIndex = rowIndex * columnCount + columnIndex
    const item = items?.[listIndex]

    const itemLabels = useMemo(() => {
      if (!item || !allToolLabels) return []
      const uniqueLabelIds = uniq(item.calculated_labels)
      const filteredLabels = allToolLabels.filter(lbl => uniqueLabelIds.includes(lbl.id))
      const sortedLabelsByKind = filteredLabels.sort(sortByLabelKind)
      return sortBySeverity(sortedLabelsByKind)
    }, [item, allToolLabels])
    if (!item) return null
    return (
      <div style={{ ...style }} className={Styles.galleryCardGap}>
        <ItemCard itemLabels={itemLabels} onClick={() => onClick(item)} item={item} key={item.id} />
      </div>
    )
  },
)
