/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

const PDFLogo = () => (
  <Svg style={{ width: 16, height: 16 }} viewBox="0 0 28 28">
    <Path d="m13.722 23.37 8.944-2.396V17.78l-8.944 2.396v3.195Z" fill="#1D1D1D" />
    <Path d="m4.824 20.962 8.898 2.409v-3.195L4.78 17.779l.045 3.183Z" fill="#F8F8F9" />
    <Path d="m22.666 17.779-8.944-2.397L4.78 17.78l8.943 2.396 8.944-2.396Z" fill="#3D80ED" />
    <Path d="m13.722 17.78 8.944-2.397V6.597l-8.944 2.396v8.787Z" fill="#1D1D1D" />
    <Path d="m4.824 15.371 8.898 2.409V8.993L4.78 6.597l.045 8.774Z" fill="#F8F8F9" />
    <Path d="M22.666 6.597 13.722 4.2 4.78 6.597l8.943 2.396 8.944-2.396Z" fill="#3B3A3A" />
  </Svg>
)
export default PDFLogo
