import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { message } from 'antd'
import { memoize, uniqBy } from 'lodash'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps, ListOnItemsRenderedProps } from 'react-window'

import { getterKeys, query, service, useQuery } from 'api'
import ImgFallback from 'components/Img/ImgFallback'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import SerialList from 'components/SerialList/SerialList'
import {
  useAllToolLabels,
  useConnectionStatus,
  useData,
  useDateTimePreferences,
  useInspectionDurationRef,
  usePreventUserScroll,
  usePrevious,
  useQueryParams,
  useScrollTo,
  useThrottleOrDebounce,
} from 'hooks'
import { DetailModal } from 'pages/ItemDetail/DetailModal'
import * as Actions from 'rdx/actions'
import { Inspection, Item, ItemExpanded, RoutineWithAois, ToolLabel } from 'types'
import {
  appendItemOrToolResultIdPictureIdOrLastSelectedToQs,
  calculateMetricsCompactionParams,
  findSingleToolLabelFromPartialData,
  getDisplaySeverity,
  getImageFromItem,
  getLabelName,
  getterAddOrUpdateResultsAndSort,
  getToolResultLabels,
  isLabelDiscard,
  sortByLabelKind,
  sortByNewestFirst,
  sortBySeverity,
} from 'utils'
import { UNTRAINED_LABEL } from 'utils/labels'

import Styles from './StationDetailTimeline.module.scss'

const LOADING_MESSAGE_KEY = 'LOADING_MESSAGE_TIMELINE'
const PAGINATION_THRESHOLD = 15
const MAX_TIMELINE_CARDS = 300
const TIMELINE_CARD_HEIGHT = 110
const MAX_DEFETCS_TO_SHOW = 2

interface Props {
  graphPositionObserver: IntersectionObserver
  height: number
  width: number
  routines: RoutineWithAois[] | undefined
  inspection: Inspection
  isHistoricBatch: boolean
  graphClickedTime: number | undefined
  setGraphClickedTime: React.Dispatch<React.SetStateAction<number | undefined>>
  setShowNewUnitsButton: React.Dispatch<React.SetStateAction<boolean>>
  setIsLiveMode: React.Dispatch<React.SetStateAction<boolean>>
  isLiveMode: boolean
  liveModeWaitingForToolResult: React.MutableRefObject<boolean>
}

/**
 *
 * @param graphPositionObserver - Intersection observer instance that handles graph higlight
 * @param height - Height of the list component
 * @param width - Width of the list component
 * @param inspection - Inspection to fetch data for
 * @param isHistoricBatch - Specifies whether we are inspecting a current or historic inspection
 * @param graphClickedTime - Time at which graph was clicked
 * @param setGraphClickedTime - Set time at which graph was clicked
 * @param setShowNewUnitsButton - Set whether to show live results button at top of timeline
 * @param isLiveMode - Whether timeline is in live mode
 * @param liveModeWaitingForToolResult - Whether timeline should go into live mode when a new item is received through Websocket
 *
 *
 *
 * Logic rules:
 * When user loads the page, data from the websocket is shown, if available. At the same time, a request is made to
 * fetch 100 Items from the database, and combined with the web socket data in `timelineItems`.
 *
 * User is initially in live mode, which means there's no newer items to fetch from the database.
 * In this state, Items that come in through websocket are automatically added to the front of the list.
 * As soon as the user scrolls down, we stop adding these websocket Items to the front of the list.
 *
 * The "live results" button appears whenever the user has scrolled down
 * and new Items have been added to the websocket list.
 *
 * Scrolling down:
 * If user scrolls to the bottom of the list, then we get the next 100 older Items, either from WS if available,
 * or from the database, and add them to the end of the list. At this moment, we are no longer in live mode.
 * If we fetched from DB and we got less than 100 Items, then we are at the bottom of the list (no more results).
 *
 * Scrolling up:
 * If user scrolls to the top of the list, then we get the next 100 newer Items, either from WS if available,
 * or from the database, and add them to the start of the list. At this moment, we are no longer at the "bottom of the list".
 * If we fetched from DB and we got less than 100 Items, then we are at the top of the list (no more results)
 * and if scroll position is at the top of the container, we also start bringing in new Items from the websocket.
 *
 * If user clicks on graph:
 * The graph click will send a request to the server to fetch Items for the clicked on graph time, with
 * which we will replace the whole `timelineItems` list and follow the same logic as above.
 **/
export function UnmemoizedTimelineList({
  graphPositionObserver,
  height,
  width,
  inspection,
  isHistoricBatch,
  setGraphClickedTime,
  graphClickedTime,
  setShowNewUnitsButton,
  setIsLiveMode,
  isLiveMode,
  liveModeWaitingForToolResult,
}: Props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const connectionStatus = useConnectionStatus()
  const { inspectionDurationMsRef } = useInspectionDurationRef(inspection)
  const isFetchingMoreRef = useRef(false)
  const listContainerRef = useRef<HTMLDivElement>(null)
  const hasReachedLiveResultsRef = useRef(true)
  const [scrollTo, isAutoScrollingRef] = useScrollTo(listContainerRef)
  usePreventUserScroll(isAutoScrollingRef)
  const scrollPositionRef = useRef(0)
  const previousIsLiveMode = usePrevious(isLiveMode)
  const initialItemsFetchedRef = useRef(false)
  const { agg_s } = calculateMetricsCompactionParams(inspectionDurationMsRef.current, {
    isHistoricBatch,
  })

  const [params] = useQueryParams<'detail_item_id' | 'detail_tool_result_id' | 'defaultTimelineTimeS'>()
  const { detail_item_id, detail_tool_result_id, defaultTimelineTimeS } = params

  // We need to set first and last element to know from where we want to fetch when scrolling
  const firstAndLastElements = useRef<{
    firstElement: Item | ItemExpanded | undefined
    lastElement: Item | ItemExpanded | undefined
  }>({
    firstElement: undefined,
    lastElement: undefined,
  })

  const inspectionId = inspection?.id
  const inspectionIdRef = useRef(inspectionId)
  inspectionIdRef.current = inspectionId

  useEffect(() => {
    scrollTo({ top: 0 })
  }, [inspectionId, scrollTo])
  const inspectionIsLive = !inspection.ended_at

  const handleCardClick = useCallback(
    (itemId: string) => {
      appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, { itemId })
    },
    [history],
  )

  const { allToolLabels } = useAllToolLabels()

  // These are the realtime toolResults coming in from websocket
  const liveModeItems = useData(inspectionId ? getterKeys.inspectionRecentItems(inspectionId) : undefined)

  // Combine existing toolResults (from websocket) with data fetched when page loads, in case there's not
  // enough data already to fill the screen
  const { data: timelineItemsRes } = useQuery(
    inspectionId ? getterKeys.stationDetailTimelineItems(inspectionId) : undefined,
    async () => {
      // We use the end filter parameter here becase we want to get items older than
      // what we currently have in the live toolResults list
      // OR
      // older than the defaultTimeMs if the user has clicked on graph and navigated away and back
      const res = await service.getItemsExpanded({
        inspection_id: inspectionId,
        end: defaultTimelineTimeS ? moment.unix(+defaultTimelineTimeS).format() : undefined,
        use_primary_db: inspectionIsLive,
      })

      if (res.type === 'success') {
        firstAndLastElements.current = {
          firstElement: res.data.results[0],
          lastElement: res.data.results[res.data.results.length - 1],
        }
        if (defaultTimelineTimeS) {
          setIsLiveMode(false)
          liveModeWaitingForToolResult.current = true
          hasReachedLiveResultsRef.current = false
        }
      }
      initialItemsFetchedRef.current = true
      return res
    },
    { refetchKey: connectionStatus },
  )

  const timelineItems = useMemo(() => {
    return timelineItemsRes?.data.results
  }, [timelineItemsRes?.data.results])

  useEffect(() => {
    // If user is in live mode and new toolResults come in via websockets, then we add those to the start of the list
    if (!inspectionId || !liveModeItems || !timelineItems || !initialItemsFetchedRef.current) return

    if (isLiveMode) {
      setShowNewUnitsButton(false)
      let isLiveModeAdjacent = false
      // If liveModeAdjacent specifies whether we are "at the edge" of live mode.
      // If liveModeAdjacent is false, overwrite previous data with recent toolResults, this has to be done in case:
      // * User clicks on graph and loads older resuts, then clicks on "live results" button.
      // * User scrolls down, sits there for a while (until recent toolResults cap is reached), and clicks "live results" button.
      // If we don't do this user would get a mixture of recent toolResults and outdated toolResults, not a continuous list.
      if (previousIsLiveMode) {
        isLiveModeAdjacent = true
      } else {
        const lastLiveModeToolResult = liveModeItems.results[liveModeItems.results.length - 1]
        if (lastLiveModeToolResult) {
          isLiveModeAdjacent = timelineItems.some(tool => tool.id === lastLiveModeToolResult.id)
        }
      }

      if (!isLiveModeAdjacent) {
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.stationDetailTimelineItems(inspectionId),
            updater: prevRes =>
              getterAddOrUpdateResultsAndSort(
                { ...prevRes, data: { ...prevRes?.data, results: [] } },
                {
                  results: liveModeItems.results,
                  sliceEndIdx: MAX_TIMELINE_CARDS,
                  sort: sortByNewestFirst,
                },
              ),
          }),
        )
      } else {
        // Was and continues to be live mode, or last live mode toolResult was found in the timeline

        dispatch(
          Actions.getterUpdate({
            key: getterKeys.stationDetailTimelineItems(inspectionId),
            updater: prevRes =>
              getterAddOrUpdateResultsAndSort(prevRes, {
                results: liveModeItems.results,
                sliceEndIdx: MAX_TIMELINE_CARDS,
                sort: sortByNewestFirst,
              }),
          }),
        )
      }
    }
    // eslint-disable-next-line
  }, [dispatch, inspectionId, isLiveMode, MAX_TIMELINE_CARDS, previousIsLiveMode, liveModeItems, setShowNewUnitsButton])

  useEffect(() => {
    // liveModeWaitingForToolResult is true when user has scrolled down, but there have been no new websocket results
    // If new toolResults come in via websocket, display the "new items" button so the user can go back to live mode
    if (!liveModeItems || isHistoricBatch) return

    if (liveModeWaitingForToolResult.current) {
      setShowNewUnitsButton(true)
      liveModeWaitingForToolResult.current = false
    }
    // eslint-disable-next-line
  }, [liveModeItems?.results[0]?.id])

  useEffect(() => {
    // If live mode is toggled to true, scroll the user back up to top
    if (isLiveMode && initialItemsFetchedRef.current) {
      scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isLiveMode, scrollTo])

  const fetchMoreResults = useCallback(
    async (direction: 'next' | 'previous'): Promise<Item | ItemExpanded | undefined> => {
      if (!timelineItems) return

      let elementToSeek: Item | ItemExpanded | undefined

      if (direction === 'next') {
        elementToSeek = firstAndLastElements.current.lastElement
      } else {
        elementToSeek = firstAndLastElements.current.firstElement
      }

      if (
        !inspectionId ||
        !elementToSeek ||
        isAutoScrollingRef.current ||
        isFetchingMoreRef.current ||
        !initialItemsFetchedRef.current
      )
        return

      isFetchingMoreRef.current = true

      let itemsToAdd: (Item | ItemExpanded)[] = []
      let foundInWsData = false
      let next: string | null | undefined = null

      // Try fetching toolResults from the websocket
      if (liveModeItems) {
        // Find the toolResult we're seeking in WS
        const toolResultInWsIdx = liveModeItems.results.findIndex(tool => tool.id === elementToSeek?.id)

        // If we found the toolResult in the WS results and there's more data,
        // get 100 more toolResults from that list (may be less)
        if (toolResultInWsIdx > -1) {
          if (direction === 'next' && toolResultInWsIdx < liveModeItems.results.length - 1) {
            itemsToAdd = liveModeItems.results.slice(toolResultInWsIdx + 1, toolResultInWsIdx + 1 + 100)
            foundInWsData = true
          } else if (direction === 'previous' && toolResultInWsIdx > 0) {
            itemsToAdd = liveModeItems.results.slice(Math.max(0, toolResultInWsIdx - 100), toolResultInWsIdx)
            foundInWsData = true
          }
        }
      }

      // If not found in the WS results,
      // fetch newer toolResults from DB
      if (!foundInWsData) {
        let reqParams: { inspection_id: string; end?: string; start?: string; ordering?: string } = {
          inspection_id: inspectionId,
        }

        if (direction === 'next') {
          // Request older results, from newest to oldest
          reqParams = { ...reqParams, end: elementToSeek.created_at }
        } else {
          // Request newer results, starting from oldest to newest
          reqParams = { ...reqParams, start: elementToSeek.created_at, ordering: 'created_at' }
        }

        const res = await service.getItemsExpanded({ ...reqParams, use_primary_db: inspectionIsLive })

        if (res?.type === 'success') {
          itemsToAdd = res.data.results
          next = res.data.next
        } else {
          // If something went wrong, set the loading ref to false so we can try again
          isFetchingMoreRef.current = false
        }
      }

      if (direction === 'previous' && itemsToAdd.length < 100) {
        // We have reached the top of the stored results, which means any new results are live,
        // Set this flag so that if the user scrolls up we then turn on live mode.
        hasReachedLiveResultsRef.current = true
      } else {
        hasReachedLiveResultsRef.current = false
      }

      // Adjust user's scroll position so there is no shift of the cards the user is seeing
      const scrollPosition = scrollPositionRef.current
      const numItemsFetched = itemsToAdd.length
      // Compare to inspectionIdRef in case user has switched inspection while request was en route
      // If user chose a historical batch or came back to a live one, don't scroll, as this could
      // place the user in a weird position
      if (numItemsFetched > 0 && inspectionId === inspectionIdRef.current) {
        if (direction === 'next') {
          // If items do not exceed MAX_TIMELINE_CARDS, don't scroll, since cards will just be appended to list
          // If they do exceed it, then we remove numItemsFetched from the top of the list, so scroll up by that amount of items
          // so that user is seeing the same card after this update occurs

          if (numItemsFetched + (timelineItems.length || 0) > MAX_TIMELINE_CARDS) {
            const itemsRemoved = numItemsFetched + timelineItems.length - MAX_TIMELINE_CARDS
            const newScroll = scrollPosition - itemsRemoved * TIMELINE_CARD_HEIGHT

            if (
              (numItemsFetched !== 1 || itemsToAdd[0]?.id !== elementToSeek.id) && // If numItemsFetched is 1, we already have this item in our list
              numItemsFetched + (timelineItems.length || 0) > MAX_TIMELINE_CARDS
            ) {
              scrollTo({ top: newScroll })
            }
          }
        } else {
          if (numItemsFetched !== 1 || itemsToAdd[0]?.id !== elementToSeek.id) {
            if (timelineItems.length < 100) {
              // HACK:
              // If there is less than 100 items, the container isn't high enough to scroll, so first add
              // the new items to the end of the list so the container's height has increased.
              // Using a timeout here just adds the scrolling event to the queue so scroll happens after
              // the new elements are added. Don't do this always because it causes a flicker, just when necessary.
              setTimeout(() => {
                scrollTo({ top: scrollPosition + numItemsFetched * TIMELINE_CARD_HEIGHT })
              }, 0)
            } else {
              scrollTo({ top: scrollPosition + numItemsFetched * TIMELINE_CARD_HEIGHT })
            }
          }
        }

        // Update our current list with the new items, capping items to maxTimelineToolResults
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.stationDetailTimelineItems(inspectionId),
            updater: prevRes => {
              if (!prevRes) return { data: { results: itemsToAdd, next } }

              const items = getterAddOrUpdateResultsAndSort(prevRes, {
                results: itemsToAdd,
                sort: sortByNewestFirst,

                ...(direction === 'next'
                  ? { sliceStartIdx: -MAX_TIMELINE_CARDS }
                  : { sliceStartIdx: 0, sliceEndIdx: MAX_TIMELINE_CARDS }),
              })

              firstAndLastElements.current = {
                firstElement: items.data.results[0],
                lastElement: items.data.results[items.data.results.length - 1],
              }

              return { ...items, data: { ...items.data, next } }
            },
          }),
        )
      }

      isFetchingMoreRef.current = false
    },
    [timelineItems, inspectionId, isAutoScrollingRef, liveModeItems, dispatch, scrollTo, inspectionIsLive],
  )

  const throttledFetchMoreResults = useThrottleOrDebounce('throttle', fetchMoreResults, 500)

  const scrollToOrFetchByTime = useCallback(
    async (endTime: number) => {
      if (!timelineItems) return

      const itemIndex = timelineItems.findIndex(item => {
        const itemTime = moment(item.created_at).valueOf()

        // Find the first item that belongs to clicked timespan
        return itemTime >= endTime - agg_s && itemTime <= endTime
      })

      // If we have a group for the clicked chart time, then scroll to the group.
      // Otherwise, make that time the extension group's base time so we can send
      // requests that get the data the user wants to see, then scroll to that data
      if (itemIndex > -1) {
        setIsLiveMode(false)
        liveModeWaitingForToolResult.current = true

        scrollTo({
          top: TIMELINE_CARD_HEIGHT * itemIndex + 1,
          behavior: 'smooth',
        })
      } else if (itemIndex === -1) {
        if (isFetchingMoreRef.current) return
        isFetchingMoreRef.current = true
        setIsLiveMode(false)
        liveModeWaitingForToolResult.current = true

        message.loading({ content: 'Loading items', key: LOADING_MESSAGE_KEY, duration: 0 })
        const res = await query(
          getterKeys.stationDetailTimelineItems(inspectionId),
          () => {
            return service.getItemsExpanded({
              inspection_id: inspectionId,
              end: moment.unix(endTime).format(),
              use_primary_db: inspectionIsLive,
            })
          },
          { dispatch },
        )
        if (res?.type === 'success') {
          scrollTo({
            top: 0,
          })
        } else {
          message.error('Could not get items')
        }
        message.destroy(LOADING_MESSAGE_KEY)

        isFetchingMoreRef.current = false
        if (res?.type === 'success') {
          firstAndLastElements.current = {
            firstElement: res.data.results[0],
            lastElement: res.data.results[res.data.results.length - 1],
          }
          throttledFetchMoreResults('previous')
        }
      }
    },
    [
      dispatch,
      inspectionId,
      inspectionIsLive,
      liveModeWaitingForToolResult,
      scrollTo,
      setIsLiveMode,
      throttledFetchMoreResults,
      agg_s,
      timelineItems,
    ],
  )

  useEffect(() => {
    if (listContainerRef.current) {
      const listElement = listContainerRef.current

      const scrollHandler = () => {
        const scrollPosition = listElement.scrollTop
        scrollPositionRef.current = scrollPosition

        // When we're scrolled down, set live mode to false, unless we are scrolling up due to click on "live results" button
        if (scrollPosition > TIMELINE_CARD_HEIGHT && !isAutoScrollingRef.current) {
          setIsLiveMode(false)

          liveModeWaitingForToolResult.current = true
        }

        if (scrollPosition < TIMELINE_CARD_HEIGHT && hasReachedLiveResultsRef.current && !isAutoScrollingRef.current) {
          liveModeWaitingForToolResult.current = false

          setIsLiveMode(true)
          hasReachedLiveResultsRef.current = false
        }
      }
      listElement.addEventListener('scroll', scrollHandler)
      return () => {
        listElement.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [isAutoScrollingRef, liveModeWaitingForToolResult, setIsLiveMode])

  const handleItemsRendered = ({ visibleStartIndex, visibleStopIndex }: ListOnItemsRenderedProps) => {
    if (!timelineItems) return

    // Handles virtualized list scrolling. Fetches more data
    if (isFetchingMoreRef.current) return
    if (visibleStartIndex - PAGINATION_THRESHOLD <= 0 && !isLiveMode) {
      throttledFetchMoreResults('previous')
    }

    if (visibleStopIndex + PAGINATION_THRESHOLD >= timelineItems.length) {
      throttledFetchMoreResults('next')
    }
  }

  useEffect(() => {
    // This effect handles the scrolling to an item when graph is clicked
    if (!graphClickedTime) return

    setGraphClickedTime(undefined)
    scrollToOrFetchByTime(graphClickedTime)
  }, [scrollToOrFetchByTime, graphClickedTime, setGraphClickedTime])

  const extraSlots = (listContainerRef.current?.clientHeight || 0) / (TIMELINE_CARD_HEIGHT || 1)

  return (
    <>
      <FixedSizeList
        itemCount={timelineItems?.length ? timelineItems.length + Math.ceil(extraSlots) : 0} // Extra slots is so we can scroll to the last card
        itemSize={TIMELINE_CARD_HEIGHT} // The size of a card
        width={width}
        height={height}
        itemData={memoizeItemData({
          // This data is passed to the RowRenderer function
          timelineItems,
          handleCardClick,
          graphPositionObserver,
          toolLabels: allToolLabels,
        })}
        onItemsRendered={handleItemsRendered} // This function is executed whenever scrolling of the list causes new items to be rendered. We do pagination here
        outerRef={listContainerRef}
        className={Styles.listContainer}
      >
        {RowRenderer}
      </FixedSizeList>

      {(detail_item_id || detail_tool_result_id) && timelineItemsRes && (
        <DetailModal itemsInitialRes={timelineItemsRes.data} />
      )}
    </>
  )
}

interface RenderFuncProps {
  handleCardClick: (itemId: string, timestamp: string) => any
  graphPositionObserver: IntersectionObserver
  timelineItems: (Item | ItemExpanded)[] | undefined
  toolLabels?: ToolLabel[]
}

const RowRenderer = memo(({ index, style, data }: ListChildComponentProps<RenderFuncProps>) => {
  if (!data.timelineItems) return null
  const timelineItem = data.timelineItems[index]
  if (!timelineItem) return null
  return (
    <TimelineItemGroupCard
      data-testid="timeline-group-card"
      item={timelineItem}
      key={timelineItem.id}
      onItemClick={data.handleCardClick}
      positionObserver={data.graphPositionObserver}
      style={style}
      toolLabels={data.toolLabels}
    />
  )
})

/**
 * Renders a card for a group of items. If group has more than one item, card can be expanded to show group's invidivual item cards.
 * If group has only one item, then this renders the individual item card.
 *
 * @param group - Group of display items
 * @param cardRef - A ref to measure this card
 * @param onItemClick - Function to execute when an individual item of the group is clicked
 * @param positionObserver - Intersection Observer instance to keep track of which card is at the top of the container
 */
function UnmemoizedTimelineItemGroupCard({
  item,
  onItemClick,
  positionObserver,
  style,
  'data-testid': dataTestId,
  toolLabels,
}: TimelineItemGroupCardProps) {
  const groupContainerRef = useRef<HTMLDivElement>(null)
  const [imageLoaded, setImageLoaded] = useState(false)

  const { timeZone } = useDateTimePreferences()
  const [params] = useQueryParams()

  const isItemActive = params.lastSelectedId === item.id

  // This effect takes the intersection observer and adds this card to it's observed items
  useEffect(() => {
    const groupContainer = groupContainerRef.current

    if (groupContainer && positionObserver) {
      positionObserver.observe(groupContainer)

      return () => positionObserver.unobserve(groupContainer)
    }
  }, [positionObserver])

  // If the current item has a pictures key, then we get the image from that array instead of fallback images.
  const image = useMemo(() => {
    return getImageFromItem(item, { forceThumbnail: true })
  }, [item])

  const result = item.calculated_outcome
  return (
    <div
      className={Styles.timelineCardAndTime}
      style={style}
      data-test={`${dataTestId}-item`}
      data-testid={`${dataTestId}-${item.id}`}
    >
      <div>
        <div className={Styles.timelineTimes}>
          <div className={Styles.timelineBackgroundLineSingleTime} />
          <div className={Styles.timelineTimeCard}>
            <span data-testid={`${dataTestId}-timestamp`}>{moment(item.created_at).tz(timeZone).format('LTS')}</span>
          </div>
        </div>
      </div>
      <div ref={groupContainerRef} data-time={item.created_at} className={Styles.timelineItemContainer}>
        <div
          className={`${Styles.timelineCard} ${isItemActive ? Styles.timelineRowActive : ''}`}
          onMouseDown={() => onItemClick(item.id, item.created_at)}
        >
          {!imageLoaded && (
            <div className={Styles.timelineCardImg}>
              <PrismElementaryCube className={Styles.emptyCube} />
            </div>
          )}

          <div className={imageLoaded ? Styles.timelineCardImg : Styles.imageNotLoaded}>
            <ImgFallback
              src={image}
              className={Styles.imageLoaded}
              onLoad={() => setImageLoaded(true)}
              useCache={false}
            />
          </div>

          <div className={Styles.timelineCardText}>{renderResult(item, toolLabels)}</div>

          <PrismOutcome
            icon={result}
            outcome={result}
            variant="low"
            data-testid={`${dataTestId}-outcome`}
            data-test-attribute={`outcome-${result}`}
          />
        </div>
      </div>
    </div>
  )
}

export interface TimelineItemGroupCardProps {
  item: Item | ItemExpanded
  onItemClick: (itemId: string, timestamp: string) => any
  positionObserver: IntersectionObserver
  style: React.CSSProperties
  'data-testid'?: string
  toolLabels: ToolLabel[] | undefined
}

export const TimelineList = memo(UnmemoizedTimelineList)
export const TimelineItemGroupCard = memo(UnmemoizedTimelineItemGroupCard)

const memoizeItemData = memoize(
  ({ timelineItems, handleCardClick, graphPositionObserver, toolLabels }: RenderFuncProps): RenderFuncProps => ({
    timelineItems,
    handleCardClick,
    graphPositionObserver,
    toolLabels,
  }),
)

const renderResult = (item: ItemExpanded | Item, allToolLabels: ToolLabel[] | undefined) => {
  const outcome = item.calculated_outcome
  const defaultPrefix = `1 item ${outcome !== 'unknown' ? outcome + 'ed' : outcome}`

  // If not item expanded, we can't get to labels. Also pass doesn't render labels so just return
  if (!('pictures' in item) || item.calculated_outcome === 'pass') return defaultPrefix

  const toolResults = item.pictures.flatMap(pic => pic.tool_results)

  const toolResultLabels: ToolLabel[] = toolResults
    .flatMap(toolResult => getToolResultLabels(toolResult, allToolLabels))
    .filter((toolLabel): toolLabel is ToolLabel => !!toolLabel)

  const failedToolResulsLabels = getItemExpandedFailedLabels(item, allToolLabels)

  const prefix = defaultPrefix + `${failedToolResulsLabels.length === 0 ? '' : ' due to '}`

  // If item outcome is unknown and we find the untrained label on the results, render only that label
  if (item.calculated_outcome === 'unknown') {
    if (!allToolLabels) return defaultPrefix
    const untrainedLabel = findSingleToolLabelFromPartialData(allToolLabels, UNTRAINED_LABEL)
    if (untrainedLabel && toolResultLabels.find(lbl => lbl.id === untrainedLabel.id))
      return (
        <>
          {prefix}
          <PrismResultButton
            data-testid="timeline-list-result"
            severity={getDisplaySeverity(untrainedLabel)}
            value={untrainedLabel.value}
            size="small"
            type="noFill"
            className={Styles.timelinePrismResult}
          />
        </>
      )
    return defaultPrefix
  }

  // In case we have untrainable tools or tools without a label, we return the default prefix
  if (!toolResultLabels.length) return defaultPrefix

  // Finally in the failed case, show only critical and minor labels, sorted by critical first
  // 2 labels - label and label
  // 3+ labels label, label and n other defects

  return (
    <>
      {prefix} <TimeLineToolResultLabels toolLabels={failedToolResulsLabels} />
    </>
  )
}

const TimeLineToolResultLabels = ({ toolLabels }: { toolLabels: ToolLabel[] }) => {
  const listElements = useMemo(() => {
    // we render 2 labels max
    const elements = toolLabels
      .slice(0, MAX_DEFETCS_TO_SHOW)
      .map(label => (
        <PrismResultButton
          data-testid={`timeline-list-result-labels-${getLabelName(label).toLowerCase().split(' ').join('-')}`}
          key={label.id}
          severity={getDisplaySeverity(label)}
          value={getLabelName(label)}
          size="small"
          type="noFill"
          className={Styles.timelinePrismResult}
        />
      ))

    if (toolLabels.length > MAX_DEFETCS_TO_SHOW) {
      const additionalDefects = toolLabels.length - MAX_DEFETCS_TO_SHOW
      elements.push(<span>{additionalDefects} other defects</span>)
    }
    return elements
  }, [toolLabels])

  return <SerialList>{listElements}</SerialList>
}

const getItemExpandedFailedLabels = (item: ItemExpanded, allToolLabels?: ToolLabel[]) => {
  const toolResults = item.pictures.flatMap(pic => pic.tool_results)

  const failedToolResultLabels: ToolLabel[] = toolResults
    .filter(toolResult => toolResult.calculated_outcome === 'fail')
    .flatMap(toolResult => getToolResultLabels(toolResult, allToolLabels))
    .filter((toolLabel): toolLabel is ToolLabel => !!toolLabel && !isLabelDiscard(toolLabel))

  const uniqueFailedToolResultLabels = uniqBy(failedToolResultLabels, label => label.id)
  const sortedLabelsByKind = uniqueFailedToolResultLabels.sort(sortByLabelKind)
  return sortBySeverity(sortedLabelsByKind)
}
