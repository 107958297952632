import React, { useCallback, useMemo, useRef, useState } from 'react'

import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { query, useQuery } from 'react-redux-query'

import { GetterData, getterKeys, SendToApiResponse, service } from 'api'
import { PrismLoader, PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { PrismSelect, PrismSelectProps } from 'components/PrismSelect/PrismSelect'
import * as Actions from 'rdx/actions'
import { SelectOption, SuccessResponseOnlyData } from 'types'
import { getterAddPage, ListItem, ListOrNever } from 'utils'

import Styles from './SearchableSelect.module.scss'

type SearchableSelectProps<T, U, V> = Omit<
  PrismSelectProps,
  'onSearch' | 'searchValue' | 'showSearch' | 'filterOption' | 'onSelect' | 'mode' | 'onChange'
> & {
  fetcher: (searchValue: string) => Promise<SendToApiResponse<V>>
  formatter: (result: ListItem<V>) => React.ReactNode
  getterKey: T
  preventFetch?: boolean
  queryOptions?: { noRefetch?: boolean; noRefetchMs?: number; refetchKey?: any }
  onInitialLoad?: (results: ListItem<V>[]) => void
  missingOptionFetcher?: (id: string) => Promise<SendToApiResponse<U>>
  sorter?: (a: ListItem<V>, b: ListItem<V>) => number
  isOptionDisabled?: (option: ListItem<V>) => boolean
  'data-test'?: string
  className?: string
  wrapperClassName?: string
  getSelectOptionDataTestId?: (result: ListItem<V>) => string
  onSelect?: (id: string, result?: ListItem<V>) => void
  onChange?: (val: any) => void
} & (
    | { mode?: undefined; hideDashEmptyOption?: boolean }
    | {
        mode: 'multiple'
        hideDashEmptyOption?: undefined
        clearable?: undefined
      }
  )

type WithIdOrNever<T> = T extends { id: string } ? T : never

/**
 * Wrapper around PrismSelect that handles fetching new results on search
 *
 * @param onSelect - onSelect handler
 * @param fetcher - function used to fetch new results
 * @param formatter - function applied to each rendered option. If the formatter doesn't return a string, it's the users responsibility to show a hover title on that formatter
 * @param placeholder - PrismSelect placeholder
 * @param getterKey - getterKey to store new fetched results
 * @param missingOptionFetcher - function used to fetch an element of the list using its id
 * @param sorter - function used to sort the Select Options
 * @param isOptionDisabled - Optional function used to set an Option as disabled
 */
export const SearchableSelect = <
  K extends keyof typeof getterKeys,
  T extends ReturnType<(typeof getterKeys)[K]>,
  U extends WithIdOrNever<ListItem<GetterData<T>>>,
  V extends ListOrNever<GetterData<T>, U>,
>({
  fetcher,
  formatter,
  getterKey,
  preventFetch,
  queryOptions,
  onSelect,
  onInitialLoad,
  missingOptionFetcher,
  sorter,
  isOptionDisabled,
  'data-test': dataTest,
  hideDashEmptyOption,
  className = '',
  multiClassName = '',
  wrapperClassName = '',
  mode,
  getSelectOptionDataTestId,
  onChange,
  ...rest
}: SearchableSelectProps<T, U, V>) => {
  const dispatch = useDispatch()

  const isFetchingMoreRef = useRef(false)
  const [searchValue, setSearchValue] = useState('')
  const [loadingResults, setLoadingResults] = useState(false)
  const [persistedSelections, setPersistedSelections] = useState<U[]>()

  const apiResponse = useQuery(
    preventFetch ? undefined : getterKey,
    async () => {
      setLoadingResults(true)
      const res = await fetchResults(searchValue)
      setLoadingResults(false)
      if (res.type === 'success') {
        onInitialLoad?.(res.data.results)
      }
      return res
    },
    queryOptions,
  )

  const resultsData = apiResponse.data?.data.results

  const results = useMemo(() => {
    if (resultsData && sorter) {
      return [...resultsData].sort(sorter)
    }

    return resultsData
  }, [resultsData]) // eslint-disable-line

  const fetchOptionsById = useCallback(
    async (value: string | string[]): Promise<U[] | null> => {
      if (!missingOptionFetcher) return null

      const ids = Array.isArray(value) ? value : [value]

      const allResponses = await Promise.allSettled(ids.map(id => missingOptionFetcher(id)))

      const fetchedOptions: U[] = []

      allResponses.forEach(response => {
        if (response.status !== 'fulfilled' || response.value.type !== 'success') return

        fetchedOptions.push(response.value.data)
      })

      return fetchedOptions.length ? fetchedOptions : null
    },
    [missingOptionFetcher],
  )

  const getSelectedOptions = useCallback(async () => {
    if (persistedSelections) return persistedSelections
    if (!rest.value) return null

    return await fetchOptionsById(rest.value)
  }, [persistedSelections, fetchOptionsById, rest.value])

  const fetchResults = useCallback(
    async (search: string) => {
      const res = await fetcher(search)

      if (res.type !== 'success') return res

      const selectedOptions = await getSelectedOptions()

      const missingResults: U[] = []
      const newPersistedSelections: U[] = []

      // Add the selected option to the current list if it doesn't already exist
      selectedOptions?.forEach(selectedOption => {
        if (!res.data.results.find(r => r.id === selectedOption.id)) {
          missingResults.push(selectedOption)
        }
        newPersistedSelections.push(selectedOption)
      })

      if (missingResults.length) {
        res.data.results = [...missingResults, ...res.data.results]
      }
      setPersistedSelections(newPersistedSelections)

      return res
    },
    [fetcher, getSelectedOptions],
  )

  const refetchResults = useMemo(
    () =>
      debounce(async (search: string) => {
        if (preventFetch) return
        setLoadingResults(true)
        await query(getterKey, () => fetchResults(search), {
          dispatch,
        })
        setLoadingResults(false)
      }, 500),
    [dispatch, fetchResults, getterKey, preventFetch],
  )

  const showSkeleton = useMemo(() => {
    if (!rest.value) return false

    if (Array.isArray(rest.value)) {
      return rest.value.some(result => !results?.find(res => res.id === result))
    }

    return !results?.find(res => res.id === rest.value)
  }, [rest.value, results])

  const multipleModeSkeletonTagRender = () => {
    return <PrismSkeleton />
  }

  const value = useMemo(() => {
    if (showSkeleton) {
      // We need to set this as the select value to render a PrismSkeleton
      return 'loading'
    }
    return rest.value
  }, [rest.value, showSkeleton])

  async function fetchNextPage() {
    if (preventFetch || !apiResponse.data?.data.next || isFetchingMoreRef.current) return
    isFetchingMoreRef.current = true
    const res = await service.getNextPage(apiResponse.data.data.next)
    if (res.type === 'success') {
      dispatch(
        Actions.getterUpdate({
          key: getterKey,
          updater: prevRes => getterAddPage(prevRes, (res as SuccessResponseOnlyData).data),
        }),
      )
    }
    isFetchingMoreRef.current = false
  }

  return (
    <PrismSelect
      showSearch
      mode={mode}
      filterOption={false}
      searchValue={searchValue}
      dropdownRender={loadingResults ? () => <PrismLoader /> : undefined}
      onSearch={val => {
        setSearchValue(val)
        refetchResults(val)
      }}
      onSelect={selectedValue => {
        const result = results?.find(r => r.id === selectedValue)
        onSelect?.(selectedValue, result)
      }}
      onChange={selectedValue => {
        if (mode === 'multiple') {
          // Type check, in multiple mode, this should be an array
          const selectedIds = (Array.isArray(selectedValue) ? selectedValue : [selectedValue]) as string[]
          const selectedResults = results?.filter(result => selectedIds.includes(result.id))
          setPersistedSelections(selectedResults)
        }

        if (mode !== 'multiple') {
          const result = results?.find(r => r.id === selectedValue)
          if (result) {
            setPersistedSelections([result])
          }
        }

        onChange?.(selectedValue)
      }}
      onPopupScroll={e => {
        const target = e.target as HTMLInputElement
        const offsetValue = 0.9 // Tested in different resolutions, it feels good but there could be a better thought value
        if (target.scrollTop + target.offsetHeight >= target.scrollHeight * offsetValue) fetchNextPage()
      }}
      {...rest}
      clearable={mode === 'multiple' ? true : rest?.clearable}
      value={value}
      showSelectedOptionCheck
      // For multiple Selects we need to show the Skeleton as a custom tag
      tagRender={showSkeleton && mode === 'multiple' ? multipleModeSkeletonTagRender : undefined}
      multiClassName={`${multiClassName} ${showSkeleton ? Styles.multipleModeSkeletonLoader : ''}`}
      className={className}
      wrapperClassName={wrapperClassName}
      options={[
        { value: '', content: <>&mdash;</>, isHidden: hideDashEmptyOption || mode === 'multiple' },
        {
          // For single Selects we need to inject this loading option to show the skeleton
          value: 'loading',
          isHidden: !(showSkeleton && mode !== 'multiple'),
          content: <PrismSkeleton />,
        },
        ...(results
          ? results?.map<SelectOption>(result => {
              const content = formatter(result)
              const disabled = !!isOptionDisabled?.(result)
              const selectOption: SelectOption = {
                key: result.id,
                value: result.id,
                disabled: disabled,
                dataTest: dataTest,
                dataTestId: getSelectOptionDataTestId?.(result),
                content: content,
              }
              // If the formatter doesn't return a string, it's the users responsibility to show a hover title on that formatter
              return selectOption
            })
          : []),
      ]}
    />
  )
}
