import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { isNumber } from 'lodash'
import momentTz from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GridChildComponentProps } from 'react-window'

import { getterKeys, service } from 'api'
import { BackTop, useBackTopWithClassName } from 'components/BackTop/BackTop'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { LabelCard } from 'components/LabelCard/LabelCard'
import LabelsList from 'components/LabelList/LabelsList'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { PrismToggleGroup } from 'components/PrismToggleGroup/PrismToggleGroup'
import { Tag } from 'components/Tag/Tag'
import {
  useAllToolLabels,
  useAnalyticsQueryFilters,
  useData,
  useDateTimePreferences,
  useInspectionsProxyResults,
  useQueryParams,
  useStateAndRef,
} from 'hooks'
import { DetailModal } from 'pages/ItemDetail/DetailModal'
import * as Actions from 'rdx/actions'
import { PdfData, ToolLabel, ToolResult, ToolResultOutcome } from 'types'
import { getDisplayThreshold, getFiltersToProxyInspections, sortByLabelKind, wasToolResultMuted } from 'utils'
import {
  appendItemOrToolResultIdPictureIdOrLastSelectedToQs,
  convertAllFiltersToBackendQueryParams,
  evaluateOutcomes,
  getTimezoneAbbr,
  getToolResultLabels,
  handleInspectionsProxyResultsEndReached,
  sortBySeverity,
} from 'utils'
import { ANALYZE_ITEMS_OR_TOOLS_TABLE_ROW_HEIGHT, PDF_CARD_LIMIT, TABLE_HEADER_HEIGHT } from 'utils/constants'

import Styles from './Analyze.module.scss'
import { CardRendererProps, renderToolResultsColumn, useVirtualizedGallery, useVirtualizedTable } from './AnalyzeBase'
import { AnalyzeEmptyState } from './AnalyzeEmptyState'
import AnalyzeFilters from './AnalyzeFilters'

const BREAKPOINTS_BY_TOOLS_COLUMN_KEY = {
  name: { 1680: 180, 1440: 150, 1280: 130 },
  calculated_outcome: { 1680: 132, 1280: 100 },
  score: { 1680: 100, 1280: 80 },
  routine: { 1280: 0 },
}

const PROXY_KEY = 'analyzeTools'

export const AnalyzeTools = ({ setPdfData }: { setPdfData: (data: PdfData | undefined) => void }) => {
  const [filters, { viewMode = 'gallery', onUpdateFilters }] = useAnalyticsQueryFilters({ tab: 'tools' })
  const { allToolLabels } = useAllToolLabels()

  const [reloadingToolResults, setReloadingToolResults] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const [params] = useQueryParams<'detail_item_id' | 'detail_tool_result_id' | 'lastSelectedId' | 'event_id'>()
  const { detail_item_id, detail_tool_result_id } = params

  const paramFilters = convertAllFiltersToBackendQueryParams({ ...filters, tool_name: filters.search })

  const backendFilters = useMemo(() => {
    return {
      ...paramFilters,
      tool_name: paramFilters.search,
      search: undefined,
      // `inspection_isnull` is used to prevent tool results generated aritificially by the ML team from showing up in the analytics page
      inspection_isnull: false,
    }
  }, [paramFilters])

  useInspectionsProxyResults({
    getterKey: getterKeys.toolResults(),
    holdOff: 'event_id' in paramFilters,
    fetcher: scanInspectionIds =>
      service.getToolResults(
        backendFilters,
        scanInspectionIds ? { body: { scan_inspection_ids: scanInspectionIds }, method: 'POST' } : {},
      ),
    setReloadingResults: setReloadingToolResults,
    inspectionFilters: paramFilters,
    proxyGetterKey: PROXY_KEY,
  })

  const inspectionsForProxyFiltersData = useData(getterKeys.inspectionsForFiltersProxy(PROXY_KEY))
  const inspectionsForProxyFilters = useMemo(
    () => inspectionsForProxyFiltersData?.results,
    [inspectionsForProxyFiltersData?.results],
  )

  const toolResultsData = useData(getterKeys.toolResults())
  const toolResults = useMemo(() => {
    // if we have filters that need to be proxied by inspections and we have exactly 0 inspections, it means we don't have results
    const { filtersCount: proxiesCount } = getFiltersToProxyInspections(paramFilters)
    if (proxiesCount > 0 && inspectionsForProxyFilters?.length === 0) return []
    return toolResultsData?.results || []
  }, [inspectionsForProxyFilters?.length, paramFilters, toolResultsData?.results])
  const nextToolResultsPage = toolResultsData?.next || ''

  // if we are fetching with inspections as proxy, this is the last scanned inspection id
  const lastScannedInspectionId = toolResultsData?.last_inspection_id
  const nextInspectionsPage = inspectionsForProxyFiltersData?.next

  const { timeFormat, timeZone } = useDateTimePreferences()
  const [isLoadingMore, setIsLoadingMore, isLoadingMoreRef] = useStateAndRef(false)

  // Used to refresh the redux list when an element from the Detail Modal gets updated
  const handleRefresh = (toolResultUpdated: ToolResult) => {
    dispatch(
      Actions.getterUpdate({
        key: getterKeys.toolResults(),
        updater: prevRes => {
          if (prevRes) {
            const newRes = prevRes.data.results.map(toolResult =>
              toolResultUpdated.id === toolResult.id
                ? {
                    ...toolResult,
                    calculated_outcome: toolResultUpdated.calculated_outcome,
                    active_user_label_set: toolResultUpdated.active_user_label_set,
                  }
                : toolResult,
            )

            return { ...prevRes, data: { ...prevRes.data, results: newRes } }
          }
        },
      }),
    )
  }

  const columns = useMemo(() => {
    return getColumns({ timeFormat, timeZone, allToolLabels })
  }, [timeFormat, timeZone, allToolLabels])

  const handleEndReached = useCallback(async () => {
    if (!toolResults || reloadingToolResults) return
    await handleInspectionsProxyResultsEndReached({
      paramFilters,
      getterKey: getterKeys.toolResults(),
      isLoadingMoreRef,
      inspectionsForProxy: inspectionsForProxyFilters,
      setIsLoadingMore,
      dispatch,
      nextResultsPage: nextToolResultsPage,
      lastScannedInspectionId,
      proxyGetterKey: PROXY_KEY,
      nextInspectionsPage,
      resultsFetcher: scanIds =>
        service.getToolResults(backendFilters, { body: { scan_inspection_ids: scanIds }, method: 'POST' }),
    })
  }, [
    toolResults,
    reloadingToolResults,
    isLoadingMoreRef,
    paramFilters,
    inspectionsForProxyFilters,
    setIsLoadingMore,
    dispatch,
    nextToolResultsPage,
    lastScannedInspectionId,
    nextInspectionsPage,
    backendFilters,
  ])

  useBackTopWithClassName('ant-table-body')

  // This effect sets the chart or table data to be used on the PDF
  useEffect(() => {
    if (!toolResults) {
      setPdfData(undefined)
      return
    }

    const cappedToolResults = toolResults.slice(0, PDF_CARD_LIMIT)

    setPdfData({
      type: 'toolResults',
      viewMode,
      content: cappedToolResults,
    })
  }, [toolResults, setPdfData, viewMode])

  useEffect(() => {
    return () => {
      // Reset PDF data when component unmounts
      setPdfData(undefined)
    }
  }, [setPdfData])
  const handleTableRowClick = (toolResult: ToolResult) => {
    appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
      itemId: toolResult.item?.id,
      toolResultId: toolResult.id,
    })
  }

  const isRowActive = useCallback(
    (toolResult: ToolResult) => {
      return params.lastSelectedId === toolResult.id
    },
    [params.lastSelectedId],
  )

  const { columnsWithWidths, renderVirtualTable, tableContainerRef, tableHeight } = useVirtualizedTable(columns, {
    onClick: handleTableRowClick,
    rowHeight: ANALYZE_ITEMS_OR_TOOLS_TABLE_ROW_HEIGHT,
    handleEndReached,
    breakPointsByColumnKey: BREAKPOINTS_BY_TOOLS_COLUMN_KEY,
    isRowActive,
  })

  const { galleryBodyRef, galleryGrid } = useVirtualizedGallery(
    toolResults,
    allToolLabels,
    handleEndReached,
    toolResult =>
      appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
        itemId: toolResult.item?.id,
        toolResultId: toolResult.id,
      }),
    !!filters.insightsActive,
    CardRenderer,
  )

  const renderGalleryView = () => {
    let toRender: React.JSX.Element[] | null = null
    if (!toolResults) return <PrismLoader className={Styles.galleryInitialLoadingWrapper} />

    if (toolResults?.length) toRender = [galleryGrid]
    if (toolResults?.length === 0) toRender = [<AnalyzeEmptyState key="tools" tab="tools" isGalleryView />]

    if (toolResults && reloadingToolResults)
      toRender?.push(
        <PrismLoader
          key="reload"
          dataTestId="analyze-tools-gallery-reload-loader"
          className={Styles.reloadingWrapper}
        />,
      )
    if (isLoadingMore)
      toRender?.push(
        <div key="spinner" className={Styles.bottomSpinner}>
          <PrismLoader />
        </div>,
      )
    return toRender
  }

  return (
    <PrismContainer
      title="Tools"
      bodyRef={galleryBodyRef}
      className={Styles.analyzeItemsToolsMainBody}
      headerActionsClassName={Styles.headerTitle}
      bodyClassName={Styles.rightSectionBodyWrapper}
      actions={
        <div className={Styles.headerActionsContainer}>
          <AnalyzeFilters
            insightsDisabled={!!(detail_item_id || detail_tool_result_id)}
            tab="tools"
            data-testid="analyze-tools-filters"
          />

          <PrismToggleGroup
            value={viewMode}
            onChange={value => onUpdateFilters({ viewMode: value }, 'push')}
            options={[
              {
                label: 'Gallery',
                type: 'default',
                value: 'gallery',
                dataAllowedHotkeys: 'i',
              },
              {
                label: 'List',
                type: 'default',
                value: 'list',
                dataAllowedHotkeys: 'i',
              },
            ]}
            className={Styles.radioButtonsWrapper}
          />
        </div>
      }
      data-testid="analyze-tools-container"
    >
      {viewMode === 'gallery' && (
        <div
          className={Styles.galleryGridContainer}
          data-testid="analyze-tools-gallery"
          data-test-attribute={toolResults?.length}
        >
          {renderGalleryView()}
        </div>
      )}
      {viewMode === 'list' && (
        <div className={Styles.tableWrapper} ref={tableContainerRef}>
          <BackTop scrollContainerClassName="ant-table-body" />
          {tableHeight && (
            <Table
              data-testid="analyze-tools-table"
              id="monitor-table"
              rowKey="id"
              className={Styles.tableContainer}
              rowClassName={Styles.tableRow}
              locale={{ emptyText: <AnalyzeEmptyState tab="tools" /> }}
              loading={{
                spinning: !toolResults || reloadingToolResults || !!params.event_id,
                wrapperClassName: Styles.tableSpinnerWrapper,
                indicator: <PrismLoader className={Styles.loaderPosition} />,
              }}
              pagination={false}
              scroll={{ y: tableHeight - TABLE_HEADER_HEIGHT }}
              columns={columnsWithWidths}
              components={toolResults?.length ? { body: renderVirtualTable } : undefined}
              dataSource={toolResults}
              onRow={toolResult => ({
                onClick: () => {
                  appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
                    itemId: toolResult?.item?.id,
                    toolResultId: toolResult.id,
                  })
                },
              })}
            />
          )}
          {isLoadingMore && (
            <div className={Styles.bottomSpinner}>
              <PrismLoader />
            </div>
          )}
        </div>
      )}

      {(detail_item_id || detail_tool_result_id) && toolResults && (
        <DetailModal onRefreshToolResult={handleRefresh} toolResultsInitialRes={toolResultsData} />
      )}
      <div id="items-pdf-pictures-container"></div>
    </PrismContainer>
  )
}

type CardProps = {
  toolResult: ToolResult
  onClick: () => void
  showInsights: boolean
  toolResultLabels: ToolLabel[]
}

const ToolCard = ({ toolResult, onClick, showInsights, toolResultLabels }: CardProps) => {
  // The overlay variable is used to display the muted tag and a greyscale filter when necessary
  const [params] = useQueryParams()
  let overlay: JSX.Element | undefined = undefined
  if (wasToolResultMuted(toolResult)) overlay = <Tag className={Styles.mutedTag}>muted</Tag>

  const image = useMemo(() => {
    const image = toolResult.picture.image

    const imageElement =
      image && toolResult.aoi ? (
        <ImageCloseUp
          loaderType="skeleton"
          src={toolResult.picture.image}
          thumbnailSrc={toolResult.picture.image_thumbnail}
          region={toolResult.aoi}
          overlaySrc={showInsights ? toolResult.insight_image : undefined}
        />
      ) : (
        <PrismElementaryCube className={Styles.labelCardNoImageContainer} />
      )

    return <div className={Styles.galleryImageContainer}>{imageElement}</div>
  }, [
    toolResult.aoi,
    toolResult.insight_image,
    toolResult.picture.image,
    toolResult.picture.image_thumbnail,
    showInsights,
  ])

  return (
    <LabelCard
      data-test="analyze-tools-label-card"
      data-test-attribute={'tool-label-'}
      active={params.lastSelectedId === toolResult.id}
      onClick={onClick}
      overlay={overlay}
      imageClassName={overlay ? Styles.mutedCardOverlay : undefined}
      label={<LabelsList labels={toolResultLabels} className={Styles.calculatedLabelsContainer} />}
      image={image}
      type="ghost4"
      outcome={<PrismOutcome icon={evaluateOutcomes([toolResult.calculated_outcome])} variant="dark" />}
      className={Styles.galleryCard}
    />
  )
}

const getColumns = ({
  timeFormat,
  timeZone,
  allToolLabels,
}: {
  timeFormat: string
  timeZone: string
  allToolLabels: ToolLabel[] | undefined
}): ColumnsType<ToolResult> => {
  return [
    {
      title: 'Image',
      dataIndex: 'image_thumbnail',
      key: 'has_picture',
      width: 92,
      render: (thmb, toolResult) => {
        const image = toolResult.picture.image

        const imageElement =
          image && toolResult.aoi ? (
            <ImageCloseUp
              loaderType="skeleton"
              src={toolResult.picture.image}
              thumbnailSrc={toolResult.picture.image_thumbnail}
              region={toolResult.aoi}
            />
          ) : (
            <PrismElementaryCube className={Styles.labelCardNoImageContainer} />
          )

        return <div className={Styles.imageContainer}>{imageElement}</div>
      },
    },
    {
      title: <div className={Styles.tableHeaderItemId}>Tool Name</div>,
      dataIndex: 'name',
      key: 'name',
      render: (name, toolResult) => toolResult.tool?.parent_name,
      ellipsis: true,
    },

    {
      title: 'Outcome',
      dataIndex: 'calculated_outcome',
      key: 'calculated_outcome',
      render: (outcome: ToolResultOutcome) => (
        <PrismResultButton
          severity={evaluateOutcomes([outcome])}
          value={evaluateOutcomes([outcome])}
          type="noFill"
          size="small"
          className={Styles.prismResultInTable}
        />
      ),
    },
    {
      title: 'Tool Results',
      dataIndex: 'toolResults',
      ellipsis: true,
      render: (_, toolResult) => {
        const sortedLabelsByKind = (getToolResultLabels(toolResult, allToolLabels) || []).sort(sortByLabelKind)
        const sortedByKindThenSeverity = sortBySeverity(sortedLabelsByKind)
        return renderToolResultsColumn(sortedByKindThenSeverity)
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      ellipsis: true,
      render: (name, toolResult) =>
        isNumber(toolResult.prediction_score) && toolResult.tool?.specification_name
          ? getDisplayThreshold(toolResult.prediction_score, toolResult.tool?.specification_name)
          : '--',
    },
    {
      title: (
        <>
          Date <span>({getTimezoneAbbr(timeZone)})</span>
        </>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: date => momentTz(date).tz(timeZone).format(`MMM D, ${timeFormat}`),
    },
    {
      title: 'Recipe',
      dataIndex: 'recipe',
      key: 'recipe',
      ellipsis: true,
      render: (name, toolResult) => {
        return toolResult.recipe_parent_name || '--'
      },
    },
  ]
}

const CardRenderer = React.memo(
  <T extends ToolResult>({ columnIndex, rowIndex, style, data }: GridChildComponentProps<CardRendererProps<T>>) => {
    const { columnCount, elements, onClick, showInsights, allToolLabels } = data
    const listIndex = rowIndex * columnCount + columnIndex
    const toolResult = elements?.[listIndex]
    const toolResultLabels =
      useMemo(() => {
        if (!toolResult) return []
        const sortedLabelsByKind = (getToolResultLabels(toolResult, allToolLabels) || []).sort(sortByLabelKind)
        return sortBySeverity(sortedLabelsByKind)
      }, [toolResult, allToolLabels]) || []

    if (!toolResult) return null

    return (
      <div style={{ ...style }} className={Styles.galleryCardGap}>
        <ToolCard
          onClick={() => onClick(toolResult)}
          toolResult={toolResult}
          toolResultLabels={toolResultLabels}
          key={toolResult.id}
          showInsights={showInsights}
        />{' '}
      </div>
    )
  },
)
