import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { Button } from 'components/Button/Button'
import PasswordInput from 'components/PasswordInput/PasswordInput'
import { PrismErrorIcon } from 'components/prismIcons'
import { PrismElementaryIsotypeIcon } from 'components/prismIcons/PrismElementaryIsotypeIcon'
import { error, success } from 'components/PrismMessage/PrismMessage'
import paths from 'paths'

import Styles from './Establish.module.scss'

export interface FormValues {
  password: string
  confirmPassword: string
}

/**
 * Validates form field values for errors.
 */
function validate(values: FormValues) {
  const errors: Partial<FormValues> = {}

  if (!values.password) {
    errors.password = 'Required'
  } else if (values.password.length < 8) {
    errors.password = 'Must be 8 characters or more'
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required'
  } else if (values.confirmPassword.length < 8) {
    errors.confirmPassword = 'Must be 8 characters or more'
  } else if (!errors.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords don't match"
  }

  return errors
}

const renderField = (inputComponent: React.ReactNode, error?: string) => (
  <div className={Styles.field}>
    {inputComponent}

    {error && error !== 'Required' && (
      <div className={Styles.error}>
        <PrismErrorIcon isActive />
        {error}
      </div>
    )}
  </div>
)

export interface ResetPasswordFormProps {
  onSubmit: (values: FormValues) => Promise<any>
  submitting?: boolean
}

const ResetPasswordForm = ({ onSubmit }: ResetPasswordFormProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleSubmit = async () => {
    if (submitting) return
    setSubmitting(true)
    await onSubmit({ password, confirmPassword })
    setSubmitting(false)
  }

  const errors = validate({ password, confirmPassword })

  return (
    <div className={Styles.container}>
      <div className={Styles.formContainer}>
        <div className={Styles.elementaryLogoContainer}>
          <PrismElementaryIsotypeIcon />
        </div>
        <form
          className={Styles.form}
          onSubmit={e => {
            e.preventDefault()
            handleSubmit()
          }}
          autoComplete="off"
        >
          {renderField(
            <PasswordInput
              className={Styles.input}
              onChange={e => setPassword(e.target.value)}
              value={password}
              name="password"
              placeholder="Enter your password"
            />,
            errors.password,
          )}

          {renderField(
            <PasswordInput
              className={Styles.input}
              onChange={e => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              name="confirmPassword"
              placeholder="Confirm password"
            />,
            errors.confirmPassword,
          )}
          <Button
            size="small"
            disabled={Object.keys(errors).length > 0 || submitting}
            htmlType="submit"
            className={Styles.buttonContainer}
          >
            Establish Password
          </Button>
        </form>
      </div>
    </div>
  )
}

export { ResetPasswordForm }

export interface Props {
  token: string
  userId: string
}

/**
 * Renders screen to establish or set a new password by including a "magic"
 * set password token in the request, typically parsed from the URL / qs.
 *
 * @param token - Reset password token
 * @param userId - Id of user whose password is to be reset
 */
function Establish({ token, userId }: Props) {
  const history = useHistory()

  const handleSubmit = async (values: FormValues) => {
    const res = await service.setPasswordWithToken(userId, token, values.password)

    if (res.type === 'success') {
      success({ title: 'Password has been reset' })
      history.replace(paths.login())
    } else if (res.type === 'error' && res.data?.code === 'password_too_common') {
      error({ title: 'Your password is too common' })
    } else {
      error({ title: "Couldn't change password" })
    }
  }

  if (!(token && userId)) {
    history.replace('/404')
    return null
  }

  return <ResetPasswordForm onSubmit={handleSubmit} />
}

export default Establish
