import React, { useMemo, useState } from 'react'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Control, Controller, useWatch } from 'react-hook-form'

import PrismAccordion from 'components/PrismAccordion/PrismAccordion'
import PrismCheckbox from 'components/PrismCheckbox/PrismCheckbox'
import { PrismInfoIcon } from 'components/prismIcons'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { pluralize } from 'utils'

import { NotificationRow, NotificationsFormValues } from './Notifications'
import Styles from './Notify.module.scss'

const DEFAULT_VALUES = { subscribedEvents: 0, subscribedEmails: 0, subscribedSms: 0, total: 0 }

/**
 * Renders an event checkbox
 *
 */
const EventCheckbox = ({
  label,
  onChange,
  checked,
  isHidden = false,
  disabled,
  partialSelection,
}: {
  label: string
  onChange: (e: CheckboxChangeEvent) => void
  checked: boolean
  isHidden?: boolean
  disabled?: boolean
  partialSelection?: boolean
}) => {
  return (
    <div onClick={e => e.stopPropagation()} className={`${Styles.eventCheckbox} ${isHidden ? Styles.hide : ''}`}>
      <PrismCheckbox
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        label={label}
        partialSelection={partialSelection}
      />
    </div>
  )
}

/**
 * Renders an EventItem Row
 *
 */
const EventItem = ({
  title,
  control,
  formName,
  disabled,
  dataTestId,
}: {
  title: string | React.JSX.Element
  formName: string
  control: Control<NotificationsFormValues>
  disabled?: boolean
  dataTestId?: string
}) => {
  return (
    <li className={`${Styles.eventRow} ${Styles.eventItem}`}>
      <p className={Styles.title}>{title}</p>
      <Controller
        name={`${formName}.email`}
        control={control}
        render={({ onChange, value }) => (
          <PrismCheckbox
            data-testid={`${dataTestId}-email`}
            label="Email"
            checked={value}
            onChange={e => onChange(e.target.checked)}
            disabled={disabled}
          />
        )}
      />

      <Controller
        name={`${formName}.sms`}
        control={control}
        render={({ onChange, value }) => (
          <PrismCheckbox
            data-testid={`${dataTestId}-sms`}
            label="SMS"
            checked={value}
            onChange={e => onChange(e.target.checked)}
            disabled={disabled}
          />
        )}
      />
    </li>
  )
}

/**
 * Renders an EmptyEventItem Row
 *
 * Displayed when the event list is empty
 *
 */
const EmptyEventItem = () => {
  return (
    <li className={Styles.emptyEventItem}>
      <div className={Styles.emptyEventIconContainer}>
        <PrismInfoIcon />
      </div>
      <p className={Styles.emptyEventDescription}>
        Your organization has not set up any
        <PrismTooltip
          title="Administrators and Managers can create quality events in the Administration section of Prism"
          placement="bottom"
          overlayClassName={Styles.emptyEventTooltip}
        >
          <span className={Styles.emptyEventTooltipAnchor}>Quality Events</span>
        </PrismTooltip>
        for the stations and products you are subscribed to.
      </p>
    </li>
  )
}

/**
 * Renders an Even Notification Group
 *
 * Main Wrapper of an accordion list
 * @param children - insert here EventNotificationAccordions
 * @param title - Wrapper title
 * @param description - Wrapper description
 *
 */
export const EventNotificationGroup = ({
  children,
  title,
  description,
}: {
  children: React.ReactNode
  title: string
  description: string
}) => {
  return (
    <section className={Styles.eventNotificationGroup}>
      <div className={Styles.eventNotificationName}>
        <h2 className={Styles.title}>{title}</h2>
        <p className={Styles.description}>{description}</p>
      </div>
      {children}
    </section>
  )
}

/**
 * Renders an Event Notification Accordion
 *
 * @param title - Event's title
 * @param eventList - List of (string) events
 *
 */
export const EventNotificationAccordion = <T extends Exclude<keyof NotificationsFormValues, 'reports'>>({
  title,
  eventList,
  control,
  accordionType,
  disabled,
}: {
  title: string
  control: Control<NotificationsFormValues>
  accordionType: T
  eventList: NotificationRow[]
  disabled?: boolean
}) => {
  const [accordionIsOpen, setAccordionIsOpen] = useState(false)

  const allFormValues = useWatch<NotificationsFormValues>({ control })
  const eventListIsEmpty = !eventList || eventList.length === 0

  const { subscribedEvents, subscribedEmails, subscribedSms, total } = useMemo(() => {
    if (!allFormValues) return DEFAULT_VALUES

    const accordionValues = allFormValues as NotificationsFormValues
    if (!accordionValues[accordionType]) return DEFAULT_VALUES

    const checkBoxInAccordion = accordionValues[accordionType] as NotificationRow[] | undefined
    if (!checkBoxInAccordion) return DEFAULT_VALUES

    return checkBoxInAccordion.reduce(
      (counts, rowValue) => {
        if (rowValue.email || rowValue.sms) counts.subscribedEvents += 1
        if (rowValue.email) counts.subscribedEmails += 1
        if (rowValue.sms) counts.subscribedSms += 1
        counts.total += 1
        return counts
      },
      { ...DEFAULT_VALUES },
    )
  }, [accordionType, allFormValues])

  const description =
    subscribedEvents > 0
      ? `Subscribed to ${subscribedEvents} ${pluralize({ word: 'event', wordCount: subscribedEvents })}`
      : 'No notifications'
  const emailPartialSelection = subscribedEmails > 0 && subscribedEmails < total
  const smsPartialSelection = subscribedSms > 0 && subscribedSms < total

  function allCheckboxChange(type: 'email' | 'sms', event: CheckboxChangeEvent) {
    const checked = event.target.checked

    eventList?.forEach((_, idx) => {
      control.setValue(`${accordionType as string}.${idx}.${type}`, checked, {
        shouldDirty: true,
      })
    })
  }

  return (
    <PrismAccordion
      dataTestId={`${accordionType}-accordion`}
      header={
        <>
          <div className={Styles.accordionTitleContainer}>
            <h3 className={Styles.title}>{title}</h3>
            <p
              data-testid={`${accordionType}-accordion-description`}
              data-test-attribute={subscribedEvents}
              className={Styles.description}
            >
              {description}
            </p>
          </div>

          <EventCheckbox
            label="Email"
            isHidden={!accordionIsOpen || !eventList.length}
            checked={!eventListIsEmpty && subscribedEmails > 0}
            onChange={event => allCheckboxChange('email', event)}
            disabled={disabled || eventListIsEmpty}
            partialSelection={emailPartialSelection}
          />

          <EventCheckbox
            label="SMS"
            isHidden={!accordionIsOpen || !eventList.length}
            checked={!eventListIsEmpty && subscribedSms > 0}
            onChange={event => allCheckboxChange('sms', event)}
            disabled={disabled || eventListIsEmpty}
            partialSelection={smsPartialSelection}
          />
        </>
      }
      body={
        <>
          {eventListIsEmpty && <EmptyEventItem />}

          {eventList?.map((eventItem, idx) => {
            return (
              <EventItem
                dataTestId={eventItem.eventName}
                key={eventItem.eventName}
                formName={`${accordionType as string}.${idx}`}
                control={control}
                title={eventItem.name}
                disabled={disabled}
              />
            )
          })}
        </>
      }
      isOpen={accordionIsOpen}
      setIsOpen={setAccordionIsOpen}
      className={Styles.eventNotificationAccordion}
      headerClassName={`${Styles.accordionHeader} ${Styles.eventRow}`}
      iconContainerClassName={Styles.accordionIconContainer}
      iconClassName={Styles.accordionIcon}
    />
  )
}
