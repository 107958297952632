import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { difference, groupBy, uniq, uniqBy } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Dispatch } from 'redux'

import { getterKeys, SendToApiResponse, service, ToolResultsData, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismCloseIcon, PrismNavArrowIcon, PrismSharedToolIcon } from 'components/prismIcons'
import { PrismLayout } from 'components/PrismLayout/PrismLayout'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { loading } from 'components/PrismMessage/PrismMessage'
import { dismiss, success } from 'components/PrismNotification/PrismNotification'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Token } from 'components/Token/Token'
import {
  useData,
  useDefaultToolLabels,
  useHotkeyPress,
  useInspectionsProxyResults,
  useQueryParams,
  useToolLabels,
  useToolParentLabelCounts,
  useTypedSelector,
} from 'hooks'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import {
  LabelingScreenMode,
  NonTrainableToolLabel,
  QsFilters,
  RoutineWithAois,
  SuccessResponseOnlyData,
  ToolLabel,
  ToolResult,
  ToolSpecificationName,
} from 'types'
import {
  areFiltersActive,
  convertAllFiltersToBackendQueryParams,
  defaultEmptyLabelingProgressReturn,
  evaluateOutcomes,
  getFiltersToProxyInspections,
  getMilestoneProgress,
  getToolParentMostRecentValidTool,
  getToolResultsBranch,
  handleInspectionsProxyResultsEndReached,
  isLabelTestSet,
  isNonTrainingLabel,
  searchLocationHistory,
  sleep,
  sortByNewestFirst,
  sortByValueAndSeverity,
  updateToolResultBatchLabels,
} from 'utils'
import { ALL_TOOL_LABELS_GETTER_KEY, TRAINABLE_TOOL_SPECIFICATION_NAMES, TRAINING_STATES } from 'utils/constants'

import FocusLabelScreen from './FocusLabelScreen'
import LabelingGallery, {
  areGroupsActive,
  areSmartGroupsActive,
  ToolResultsMap,
} from './LabelingGallery/LabelingGallery'
import { getLabelingGroups } from './LabelingGallery/LabelingGroups'
import {
  fetchSmartGroupToolResults,
  getSmartGroupId,
  useFilteredSmartGroupToolResults,
  useSmartGroups,
} from './LabelingGallery/SmartGroups'
import LabelingHeader from './LabelingHeader'
import LabelingMetrics from './LabelingMetrics'
import LabelingOptions, { getSelectedToolLabels } from './LabelingOptions'
import Styles from './LabelingScreen.module.scss'

export type RoutinesMap = {
  [routineId: string]: 'loading' | RoutineWithAois
}

export const FILTERING_IMAGES_MESSAGE_ID = 'filtering-images'
const ALL_LABELING_GOALS_MET_NOTIFICATION_KEY = 'all-labeling-goals-met'

const PROXY_KEY = 'labeling-screen'
/**
 * Renders the screen that allows users to label ToolResults for a specific
 * ToolParent. For the users this means they are here to prepare training
 * data for an AOI.
 *
 * This screen has two modes: focus and gallery
 * The focus mode renders just one toolResult at a time, with a carousel at the left side, so that
 * the user can easily focus on one toolResult.
 *
 * The gallery mode renders a grid view, so that the users can easily browse and label a bunch
 * of toolResults at once.
 *
 * @param toolParentId - The current tool parent id
 * @param mode - labeling screen mode, one of "focus" or "gallery"
 */
function LabelingScreen({ toolParentId, mode }: { toolParentId: string; mode: LabelingScreenMode }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation<{ redirectedFromOtherMode?: boolean; selectedToolResults?: ToolResultsMap }>()
  const locationHistory = useTypedSelector(state => state.locationHistory)
  const defaultLabels = useDefaultToolLabels()

  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>()
  const [showInsights, setShowInsights] = useState(false)
  const [showEntireImage, setShowEntireImage] = useState(false)
  const [routinesMap, setRoutinesMap] = useState<RoutinesMap>({})
  // Gallery state
  const [selectedToolResults, setSelectedToolResults] = useState<ToolResultsMap>({})
  const [lastSelectedToolResultId, setLastSelectedToolResultId] = useState<string | null>(null)
  const [generatingSmartGroups, setGeneratingSmartGroups] = useState(false)
  const [disableSmartGroupsButton, setDisableSmartGroupsButton] = useState(false)
  // State to trigger fetching useEffect in order to refetch tool results on an user action when the qs won't change
  const [forceRefetch, setForceRefetch] = useState(0)
  // Focus state
  const [currentToolResult, setCurrentToolResult] = useState<ToolResult>()

  const loadingToolResultsRef = useRef(false)
  const nullPredictionsFetchedRef = useRef(false)
  const updatingToolResultRef = useRef<boolean>(false)
  const listEndRef = useRef<boolean>(false)

  // OnComponentWillUnmount useEffect. Use this to clean up anything you don't want to persist when leaving the screen.
  useEffect(() => {
    return () => {
      dismiss(ALL_LABELING_GOALS_MET_NOTIFICATION_KEY)
    }
  }, [])

  const { metricsHaveLoaded, countsByLabelId, testSetCountsByLabelId, totalUnlabeled } = useToolParentLabelCounts({
    toolParentId,
  })

  const toolParent = useQuery(getterKeys.toolParent(toolParentId), () => service.getToolParent(toolParentId)).data?.data

  const latestTool = useMemo(() => {
    if (!toolParent) return
    return getToolParentMostRecentValidTool(toolParent)
  }, [toolParent])

  const trainingInProgress = useMemo(() => {
    if (!toolParent || !latestTool) return false

    const sortedExperiments = [...toolParent.experiments].sort(sortByNewestFirst)
    const lastExperiment = sortedExperiments[0]

    if (!lastExperiment) return false

    return TRAINING_STATES.includes(lastExperiment.state)
  }, [latestTool, toolParent])

  const toolSpecificationName: ToolSpecificationName | undefined = latestTool?.specification_name

  const currentToolLabels = useToolLabels(
    toolSpecificationName ? [{ parent_id: toolParentId, specification_name: toolSpecificationName }] : undefined,
  )

  const toolLabels = useMemo(() => {
    if (!currentToolLabels) return
    return currentToolLabels.sort(sortByValueAndSeverity)
  }, [currentToolLabels])

  const customToolLabels = useMemo(() => {
    return toolLabels?.filter(toolLabel => !isNonTrainingLabel(toolLabel))
  }, [toolLabels])

  const labelingProgress =
    latestTool && countsByLabelId
      ? getMilestoneProgress(toolSpecificationName, countsByLabelId, toolLabels)
      : defaultEmptyLabelingProgressReturn

  // Keep state when the qs changes, such as when we expand a carousel
  useEffect(() => {
    if (location.state?.selectedToolResults) setSelectedToolResults(location.state.selectedToolResults)
  }, [location, setSelectedToolResults])

  const selectedToolResultsCount = Object.keys(selectedToolResults).length

  const toggleInsights = useCallback(() => {
    setShowEntireImage(false)
    setShowInsights(showInsights => !showInsights)
  }, [])

  const [params] = useQueryParams()
  const {
    group_by,
    group_id,
    tool_result_id,
    groups_id,
    smart_group_idx,
    recipeParentId,
    routineParentId,
    ...restParams
  } = params

  const memoizedRestParams = useMemo(() => {
    return restParams
  }, [JSON.stringify(restParams)]) //eslint-disable-line

  const navigateBack = useCallback(() => {
    const firstNonLabelingScreenPath = searchLocationHistory(locationHistory.history, historyEntry => {
      return !historyEntry.pathname.includes('/setup/label')
    })

    if (firstNonLabelingScreenPath) {
      const { pathname, search } = firstNonLabelingScreenPath
      history.push(pathname + search)
    } else {
      // TODO: once we have the Tools Page, we should redirect there
      history.push(paths.inspect({ mode: 'site' }))
    }
  }, [history, locationHistory.history])

  const navigateToTrainTab = useCallback(async () => {
    if (recipeParentId && routineParentId) {
      dismiss(ALL_LABELING_GOALS_MET_NOTIFICATION_KEY)
      history.push(
        paths.settingsRecipe(recipeParentId, 'train', { toolParentId, routineParentId, showTrainingModal: true }),
      )

      return
    }

    const firstRecipeParentRes = await service.getRecipeParents({
      tool_parent_id: toolParentId,
      page_size: 1,
      is_deleted: false,
    })

    if (firstRecipeParentRes.type !== 'success') return

    const firstRecipeParentId = firstRecipeParentRes.data.results[0]?.id

    if (!firstRecipeParentId) return

    dismiss(ALL_LABELING_GOALS_MET_NOTIFICATION_KEY)
    history.push(paths.settingsRecipe(firstRecipeParentId, 'train', { toolParentId, showTrainingModal: true }))
  }, [history, recipeParentId, routineParentId, toolParentId])

  const handleGoBack = useCallback(() => {
    if (selectedToolResultsCount > 0) {
      return setSelectedToolResults({})
    }

    navigateBack()
  }, [navigateBack, selectedToolResultsCount])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        mode === 'focus'
          ? history.push(paths.labelingScreen(toolParentId, 'gallery', params), { redirectedFromOtherMode: true })
          : handleGoBack()
      }
    },
    [handleGoBack, history, mode, params, toolParentId],
  )

  useHotkeyPress(handleKeyDown)

  const galleryGroupingActive = (areGroupsActive(params) || areSmartGroupsActive(params)) && mode === 'gallery'

  const toolResultsBranchKey = useMemo(() => {
    if (!toolParent || galleryGroupingActive) return

    if (group_by === 'smart-group' && smart_group_idx) {
      const groupIndex = +smart_group_idx
      if (Number.isNaN(groupIndex) || groupIndex < 0) return

      return getToolResultsBranch({
        params,
        toolParentId: toolParent.id,
        groupId: getSmartGroupId(groupIndex, groups_id, params),
        smartGroupActive: true,
      })
    }
    if (group_by && group_id) {
      return getToolResultsBranch({ params, toolParentId: toolParent.id, groupId: group_id })
    }
    return getToolResultsBranch({ params, toolParentId: toolParent.id })
  }, [group_by, group_id, smart_group_idx, galleryGroupingActive, params, toolParent, groups_id])

  const groupQueryData = useMemo(() => {
    if (!toolSpecificationName || !currentToolLabels || !group_by) return
    const groups = getLabelingGroups(group_by, currentToolLabels)
    const group = groups.find(g => g.id === group_id)
    if (!group) return
    return group.queryData
  }, [toolSpecificationName, currentToolLabels, group_by, group_id])

  const smartGroups = useSmartGroups({
    parentId: toolParentId,
    groupsId: groups_id,
    preventFetch: galleryGroupingActive,
  })

  const currentSmartGroup = useMemo(() => {
    if (!smartGroups || !smart_group_idx) return
    const groupIndex = Number.parseInt(smart_group_idx, 10)
    if (Number.isNaN(groupIndex) || groupIndex < 0) return
    return smartGroups[groupIndex]
  }, [smartGroups, smart_group_idx])

  const memoizedSmartGroupToolResults = useMemo(() => {
    if (!currentSmartGroup) return []
    return currentSmartGroup.results
  }, [currentSmartGroup])

  const filteredSmartGroupToolResults = useFilteredSmartGroupToolResults(
    memoizedSmartGroupToolResults,
    toolSpecificationName,
    galleryGroupingActive,
  )

  const toolResultsFilters = useMemo(() => {
    const backendFilters = convertAllFiltersToBackendQueryParams({
      ...memoizedRestParams,
      // we add the tool_specification here just to know the correct prediction score filters we need for each tool, we will remove it later
      tool_specification: toolSpecificationName,
      predictionScore: [memoizedRestParams.prediction_score_min, memoizedRestParams.prediction_score_max],
    })

    return {
      ...backendFilters,
      // we don't need to send the tool_specification as we are already sending the tool parent id
      tool_specification: undefined,
      // These two params should always persist
      tool_parent_id: toolParent?.id,
      has_image: 'true',
      ...groupQueryData,
    }
  }, [groupQueryData, memoizedRestParams, toolParent, toolSpecificationName])

  const { filtersCount } = getFiltersToProxyInspections(memoizedRestParams)
  const inspectionsProxyNeeded = !!filtersCount

  const inspectionsForProxyFiltersData = useData(getterKeys.inspectionsForFiltersProxy(PROXY_KEY))
  const inspectionsForProxyFilters = useMemo(() => {
    if (!inspectionsProxyNeeded) return
    return inspectionsForProxyFiltersData?.results
  }, [inspectionsForProxyFiltersData?.results, inspectionsProxyNeeded])

  const toolResultsData = useData(getterKeys.toolParentToolResults(toolResultsBranchKey || ''))
  const toolResults = useMemo(() => {
    // if we have filters that need to be proxied by inspections and we have exactly 0 inspections, it means we don't have results
    if (inspectionsProxyNeeded && inspectionsForProxyFilters?.length === 0) return []
    return toolResultsData?.results
  }, [inspectionsForProxyFilters?.length, inspectionsProxyNeeded, toolResultsData?.results])

  // This effect is in charge of fetching all the routines needed by the current loaded Tool Results to render the correspoinding aois and reference images
  useEffect(() => {
    const fetchRoutines = async () => {
      if (!toolResults) return
      const allRoutineIds = toolResults.map(tr => tr.routine_id).filter((routineId): routineId is string => !!routineId)
      if (!allRoutineIds.length) return
      const uniqueRoutineIds = uniq(allRoutineIds)

      const currentRoutineIds = Object.keys(routinesMap)

      const missingRoutineIds = difference(uniqueRoutineIds, currentRoutineIds)

      if (!missingRoutineIds.length) return

      // we set the routine id with a value of 'loading' in the routines map so that if a new effect runs before
      // this effect ends, we don't try to fetch the routines again
      setRoutinesMap(current => {
        const updated = { ...current }
        missingRoutineIds.forEach(id => {
          updated[id] = 'loading'
        })
        return updated
      })

      const routinesRequests = missingRoutineIds.map(routineId => service.getRoutine(routineId))

      const allResponses = await Promise.allSettled(routinesRequests)

      setRoutinesMap(current => {
        const updated = { ...current }

        allResponses.forEach((settledRes, idx) => {
          if (settledRes.status !== 'fulfilled' || settledRes.value.type !== 'success') {
            // If routine fetch failed, delete that routine id key from routines to avoid keeping it as 'loading' forever
            const failedRoutineId = missingRoutineIds[idx]
            if (!failedRoutineId) return
            delete updated[failedRoutineId]
          } else {
            const res = settledRes.value
            updated[res.data.id] = res.data
          }
        })

        return updated
      })
    }

    fetchRoutines()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolResults])

  const nextToolResultsPage = toolResultsData?.next
  // if we are fetching with inspections as proxy, this is the last scanned inspection id
  const lastScannedInspectionId = toolResultsData?.last_inspection_id
  const nextInspectionsPage = inspectionsForProxyFiltersData?.next

  const holdOff = useMemo(() => {
    if (!toolResultsBranchKey || !toolParent || galleryGroupingActive) return true
    if (group_by && group_id && !groupQueryData) return true

    // if only the mode changed and we already have toolResults, we do not need to fetch again.
    if (location.state?.redirectedFromOtherMode && toolResults) return true

    return false
  }, [
    toolResultsBranchKey,
    toolParent,
    galleryGroupingActive,
    group_by,
    group_id,
    groupQueryData,
    location.state?.redirectedFromOtherMode,
    toolResults,
  ])

  const smartGroupsActive = Boolean(areSmartGroupsActive({ group_by }) && smart_group_idx)

  // This use effect is in charge of fetching smart groups
  useEffect(() => {
    if (!smartGroupsActive || holdOff || !toolSpecificationName) return

    const fetchToolResultsForSmartGroups = async () => {
      await fetchSmartGroupToolResults({
        toolSpecificationName,
        filteredGroupToolResults: filteredSmartGroupToolResults,
        toolResultsBranch: toolResultsBranchKey,
        dispatch,
        params: memoizedRestParams,
      })
    }

    fetchToolResultsForSmartGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolResultsBranchKey, dispatch, smartGroupsActive, holdOff])

  const { predictionScoreSortingActive, predictionScoreRangeFiltersActive } = areFiltersActive(params)

  useInspectionsProxyResults({
    getterKey: getterKeys.toolParentToolResults(toolResultsBranchKey!),
    fetcher: async (scanInspectionIds, additionalParams) => {
      const res = await service.getToolResults(
        {
          ...toolResultsFilters,
          ...additionalParams,
        },
        // include_empty is passed so that we fetch the "imported" tool results
        {
          include_empty: true,
          ...(scanInspectionIds ? { body: { scan_inspection_ids: scanInspectionIds }, method: 'POST' } : {}),
        },
      )

      return res as SendToApiResponse<ToolResultsData>
    },
    holdOff: holdOff || smartGroupsActive,
    setReloadingResults: reloading => {
      if (reloading) {
        loadingToolResultsRef.current = true
        loading({
          title: 'Filtering images',
          id: FILTERING_IMAGES_MESSAGE_ID,
          'data-testid': 'labeling-screen-filtering-images',
        })
      } else {
        loadingToolResultsRef.current = false
        dismiss(FILTERING_IMAGES_MESSAGE_ID)
      }
    },
    inspectionFilters: memoizedRestParams,
    proxyGetterKey: PROXY_KEY,
    refetchKey: forceRefetch,
    nullPredictionsFetchedRef,
    predictionScoreSortingActive,
    predictionScoreRangeFiltersActive,
  })

  // It may be possible for us to have more than one element with the same ID in the array
  // of toolResults, so we must remove duplicates
  const uniqueToolResults = useMemo(() => {
    if (!toolResults) return

    // It's necessary to create a copy of toolResults, for the uniqBy function to work properly
    // otherwise the array collapses into a single element.
    const copyOfToolResults = [...toolResults]
    return uniqBy(copyOfToolResults, toolResult => toolResult.id)
  }, [toolResults])

  const fetchNextToolResultsPage = useCallback(async () => {
    if (!toolParent || !toolResultsBranchKey || loadingToolResultsRef.current) return

    await handleInspectionsProxyResultsEndReached({
      paramFilters: memoizedRestParams,
      nullPredictionsFetchedRef,
      getterKey: getterKeys.toolParentToolResults(toolResultsBranchKey),
      inspectionsForProxy: inspectionsForProxyFilters,
      dispatch,
      nextResultsPage: nextToolResultsPage,
      lastScannedInspectionId,
      proxyGetterKey: PROXY_KEY,
      nextInspectionsPage,
      resultsFetcher: async (scanIds, additionalParams) => {
        const res = await service.getToolResults(
          { ...toolResultsFilters, ...additionalParams },
          {
            // include_empty is passed so that we fetch the "imported" tool results
            include_empty: true,
            ...(scanIds ? { body: { scan_inspection_ids: scanIds }, method: 'POST' } : {}),
          },
        )
        return res as SendToApiResponse<ToolResultsData>
      },
      setIsLoadingMore: loading => {
        loadingToolResultsRef.current = loading
      },
      predictionScoreSortingActive,
      predictionScoreRangeFiltersActive,
    })
  }, [
    toolParent,
    toolResultsBranchKey,
    memoizedRestParams,
    inspectionsForProxyFilters,
    dispatch,
    nextToolResultsPage,
    lastScannedInspectionId,
    nextInspectionsPage,
    predictionScoreSortingActive,
    predictionScoreRangeFiltersActive,
    toolResultsFilters,
  ])

  const fetchNewRoundOfToolResults = useCallback(async () => {
    if (areSmartGroupsActive({ group_by }) && smart_group_idx && toolSpecificationName) {
      return fetchSmartGroupToolResults({
        toolSpecificationName,
        fetchedToolResults: toolResults,
        filteredGroupToolResults: filteredSmartGroupToolResults,
        toolResultsBranch: toolResultsBranchKey,
        dispatch,
        params: memoizedRestParams,
      })
    } else {
      await fetchNextToolResultsPage()
    }
  }, [
    group_by,
    smart_group_idx,
    toolSpecificationName,
    toolResults,
    filteredSmartGroupToolResults,
    toolResultsBranchKey,
    dispatch,
    memoizedRestParams,
    fetchNextToolResultsPage,
  ])

  const onGalleryLabelingSucess = () => {
    setSelectedToolResults({})
    setLastSelectedToolResultId(null)

    // artificial wait for better UX. When the request is super fast it looks ugly.
    setSelectedLabelIds(undefined)
  }

  const handleSetToolResult = useCallback(
    (toolResult?: ToolResult) => {
      if (!uniqueToolResults?.[0]) return
      listEndRef.current = !toolResult
      const { tool_result_id, ...restParams } = params

      history.replace(
        paths.labelingScreen(toolParentId, 'focus', {
          ...restParams,
          tool_result_id: toolResult?.id,
        }),
        location.state,
      )
    },
    [history, location.state, params, toolParentId, uniqueToolResults],
  )

  const onFocusLabelingSuccess = useCallback(async () => {
    if (!toolResults) return
    const editedIdx = toolResults.findIndex(toolResult => toolResult.id === currentToolResult?.id)

    let nextToolResult: ToolResult | undefined = toolResults?.[editedIdx + 1]

    if (!nextToolResult) nextToolResult = await fetchNewRoundOfToolResults()

    handleSetToolResult(nextToolResult)
    setSelectedLabelIds(undefined)
  }, [currentToolResult?.id, fetchNewRoundOfToolResults, handleSetToolResult, toolResults])

  const getCurrentToolResults = useCallback(() => {
    const results = []
    if (mode === 'gallery') {
      results.push(...Object.values(selectedToolResults))
    }
    if (mode === 'focus' && currentToolResult) {
      results.push(currentToolResult)
    }
    return results
  }, [currentToolResult, mode, selectedToolResults])

  const updateToolResultsLabels = useCallback(
    async (toolResultLabel: { toolLabel?: ToolLabel } | { nonTrainableToolLabel?: NonTrainableToolLabel }) => {
      if (
        !toolLabels ||
        !defaultLabels ||
        !latestTool ||
        !toolSpecificationName ||
        updatingToolResultRef.current ||
        !('toolLabel' in toolResultLabel)
      )
        return
      const toolResults = getCurrentToolResults()

      if (toolResults.length <= 0) return

      updatingToolResultRef.current = true

      // The selectedLabelIds help us to highlight the applied label, in case we are applying the TestSet label,
      // we also want to keep the current ToolResults labels highlighted, otherwise user might think that
      // the current label was removed.
      if (toolResultLabel.toolLabel?.id) {
        const updatedSelectedLabelsIds = [toolResultLabel.toolLabel.id]

        const currentToolResultsLabels = getSelectedToolLabels(toolResults)

        if (isLabelTestSet(toolResultLabel.toolLabel)) {
          updatedSelectedLabelsIds.push(...currentToolResultsLabels)
        } else {
          const testSetLabel = defaultLabels.find(isLabelTestSet)
          // If TestSet label is applied to the current ToolResult we keep it highlited
          if (testSetLabel && currentToolResultsLabels.find(toolLabelId => toolLabelId === testSetLabel.id)) {
            updatedSelectedLabelsIds.push(testSetLabel.id)
          }
        }

        setSelectedLabelIds(updatedSelectedLabelsIds)
      }

      const updatedToolResultsDict = await updateToolResultBatchLabels({
        toolResults,
        toolLabel: toolResultLabel.toolLabel,
        toolParentId,
        dispatch,
        defaultLabels,
        goalsByLabelId: labelingProgress.goalsForThisLevelById,
        currentToolLabels: toolLabels,
        countsByLabelId: countsByLabelId || {},
        toolSpecificationName: latestTool.specification_name,
        onAllLabelGoalsMet: () => {
          success({
            id: ALL_LABELING_GOALS_MET_NOTIFICATION_KEY,
            'data-testid': 'labeling-goals-met-notification',
            title: "You've met your labeling goals",
            description: 'This is an ideal time to train and see how well your model performs.',
            duration: 0,
            children: (
              <Button type="secondary" size="small" onClick={navigateToTrainTab}>
                Train
              </Button>
            ),
          })
        },
      })

      if (!updatedToolResultsDict) {
        updatingToolResultRef.current = false
        return
      }

      const toolResultsByInspectionId = groupBy(updatedToolResultsDict, tool_result => tool_result.item?.inspection_id)
      const toolresultsByItemId = groupBy(updatedToolResultsDict, tool_result => tool_result.item?.id)

      Object.keys(toolResultsByInspectionId).forEach(inspectionId => {
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.inspectionRecentToolResults(inspectionId),
            updater: prevRes => toolResultsUpdater(updatedToolResultsDict, prevRes),
          }),
        )

        dispatch(
          Actions.getterUpdate({
            key: getterKeys.inspectionRecentItems(inspectionId),
            updater: prevRes => {
              if (prevRes) {
                const updatedResults = prevRes.data.results.map(item => {
                  if (toolresultsByItemId[item.id]) {
                    const combinedResults = uniqBy(
                      [
                        ...(toolresultsByItemId[item.id] || []),
                        ...item.pictures.flatMap(picture => picture.tool_results),
                      ],
                      tool_result => tool_result.id,
                    )

                    return {
                      ...item,

                      calculated_outcome: evaluateOutcomes(
                        combinedResults.map(tool_result => tool_result.calculated_outcome),
                      ),
                    }
                  } else return item
                })
                return { ...prevRes, data: { ...prevRes.data, results: updatedResults } }
              }
            },
          }),
        )
      })

      if (group_by && group_by !== 'smart-group') {
        const groups = getLabelingGroups(group_by, toolLabels)
        groups.forEach(group => {
          const toolResultBranch = getToolResultsBranch({
            params,
            toolParentId,
            groupId: group.id,
          })
          dispatch(
            Actions.getterUpdate({
              key: getterKeys.toolParentToolResults(toolResultBranch),
              updater: prevRes => toolResultsUpdater(updatedToolResultsDict, prevRes),
            }),
          )
        })
      } else if (group_by && group_by === 'smart-group') {
        smartGroups?.forEach((_, index) => {
          const toolResultsBranchKey = getToolResultsBranch({
            params,
            toolParentId,
            groupId: getSmartGroupId(index, groups_id, params),
            smartGroupActive: true,
          })
          dispatch(
            Actions.getterUpdate({
              key: getterKeys.toolParentToolResults(toolResultsBranchKey),
              updater: prevRes => toolResultsUpdater(updatedToolResultsDict, prevRes),
            }),
          )
        })
      } else {
        const toolResultBranch = getToolResultsBranch({ params, toolParentId })
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.toolParentToolResults(toolResultBranch),
            updater: prevRes => toolResultsUpdater(updatedToolResultsDict, prevRes),
          }),
        )
      }

      if (mode === 'gallery') onGalleryLabelingSucess()
      if (mode === 'focus') await onFocusLabelingSuccess()

      // artificial wait for better UX. When the request is super fast it looks ugly.
      await sleep(200)

      updatingToolResultRef.current = false
    },
    [
      toolLabels,
      defaultLabels,
      latestTool,
      toolSpecificationName,
      getCurrentToolResults,
      toolParentId,
      dispatch,
      labelingProgress.goalsForThisLevelById,
      countsByLabelId,
      group_by,
      mode,
      onFocusLabelingSuccess,
      navigateToTrainTab,
      params,
      smartGroups,
      groups_id,
    ],
  )

  // forceMode will help us to redirect to focus or gallery screen.
  const updateFilters = useCallback(
    (filters: QsFilters, forceMode?: LabelingScreenMode) => {
      const updatedFilters = { ...params, ...filters, tool_result_id: undefined }
      history.push(paths.labelingScreen(toolParentId, forceMode || mode, updatedFilters), {
        redirectedFromOtherMode: forceMode ? true : undefined,
      })
    },
    [history, params, mode, toolParentId],
  )

  // this would suffice on checking just one, but helps with type narrowing

  if (!toolParent || !latestTool || !toolSpecificationName) {
    return <PrismLoader fullScreen />
  }

  const getLayoutLabel = () => {
    if (selectedToolResultsCount > 0) return `${selectedToolResultsCount} selected`

    // PR_REVIEW, validate this, as the tool parent name is always defined, we might not need renderToolName anymore
    return toolParent.name
  }

  const isTrainableTool = TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(toolSpecificationName)

  return (
    <>
      <PrismLayout
        data-testid="labeling-screen-layout-label"
        data-test-attribute={selectedToolResultsCount.toString()}
        breadcrumbs={[
          {
            label: getLayoutLabel(),
          },
        ]}
        headerIcon={
          <IconButton
            icon={selectedToolResultsCount > 0 ? <PrismCloseIcon /> : <PrismNavArrowIcon direction="left" />}
            type="ghost"
            onClick={() => {
              handleGoBack()
            }}
            className={Styles.goBackButton}
            data-testid="labeling-metrics-go-back"
          />
        }
        headerRightIcon={
          isTrainableTool &&
          toolParent.is_shared && (
            <PrismTooltip
              title={
                <Token label="Shared Tool" valueClassName={Styles.tokenBody} className={Styles.tokenTitle}>
                  This tool is used in multiple views. Its labels and training are shared.
                </Token>
              }
              mouseEnterDelay={0.01}
              placement="bottom"
              overlayClassName={Styles.tooltipContainer}
              anchorClassName={Styles.headerSharedToolIcon}
            >
              <PrismSharedToolIcon />
            </PrismTooltip>
          )
        }
        menuItems={
          <LabelingHeader
            mode={mode}
            tool={latestTool}
            toolParent={toolParent}
            isShared={!!toolParent.is_shared}
            toolLabels={toolLabels}
            disableSmartGroupsButton={disableSmartGroupsButton}
            setDisableSmartGroupsButton={setDisableSmartGroupsButton}
            setGeneratingSmartGroups={setGeneratingSmartGroups}
            showInsights={showInsights}
            toggleInsights={toggleInsights}
            areSomeToolResultsSelected={selectedToolResultsCount > 0}
            setSelectedToolResults={setSelectedToolResults}
            labelMetrics={countsByLabelId}
            currentToolResult={currentToolResult}
            updateFilters={updateFilters}
          />
        }
      >
        <div className={`${Styles.labelingBodyLayout} ${mode === 'gallery' ? Styles.galleryMaxHeight : ''}`}>
          <LabelingMetrics
            tool={latestTool}
            toolParentId={toolParentId}
            customToolLabels={customToolLabels}
            countsByLabelId={countsByLabelId}
            testSetCountsByLabelId={testSetCountsByLabelId}
            metricsHaveLoaded={metricsHaveLoaded}
            setForceRefetch={setForceRefetch}
            updateFilters={updateFilters}
            labelingProgress={labelingProgress}
            totalUnlabeled={totalUnlabeled}
            trainingInProgress={trainingInProgress}
          />

          <Divider type="vertical" className={Styles.labelingDivider} />

          <section className={Styles.labelingModeSection}>
            {mode === 'gallery' && (
              <div className={Styles.labelingGalleryContainer}>
                <LabelingGallery
                  uniqueToolResults={uniqueToolResults}
                  toolParentId={toolParentId}
                  toolSpecificationName={toolSpecificationName}
                  toolLabels={toolLabels}
                  metrics={countsByLabelId}
                  testSetCountsByLabelId={testSetCountsByLabelId}
                  fetchNextPage={fetchNewRoundOfToolResults}
                  selectedToolResults={selectedToolResults}
                  setSelectedToolResults={setSelectedToolResults}
                  lastSelectedToolResultId={lastSelectedToolResultId}
                  setLastSelectedToolResultId={setLastSelectedToolResultId}
                  generatingSmartGroups={generatingSmartGroups}
                  setGeneratingSmartGroups={setGeneratingSmartGroups}
                  setDisableSmartGroupsButton={setDisableSmartGroupsButton}
                  showInsights={showInsights}
                  labelingProgress={labelingProgress}
                  customToolLabels={customToolLabels}
                />
              </div>
            )}

            {mode === 'focus' && (
              <FocusLabelScreen
                uniqueToolResults={uniqueToolResults}
                fetchNextPage={fetchNewRoundOfToolResults}
                listEndRef={listEndRef}
                toolParentId={toolParentId}
                toolSpecificationName={toolSpecificationName}
                currentToolResult={currentToolResult}
                metrics={countsByLabelId}
                parent={toolParent}
                galleryGroupingActive={galleryGroupingActive}
                toolResultsBranch={toolResultsBranchKey}
                mode={mode}
                setCurrentToolResult={setCurrentToolResult}
                handleSetToolResult={handleSetToolResult}
                showInsights={showInsights}
                setShowInsights={setShowInsights}
                showEntireImage={showEntireImage}
                setShowEntireImage={setShowEntireImage}
                routinesMap={routinesMap}
              />
            )}
          </section>

          <Divider type="vertical" className={Styles.labelingDivider} />
          <LabelingOptions
            toolParentId={toolParentId}
            toolSpecificationName={toolSpecificationName}
            toolLabels={toolLabels}
            currentToolResults={getCurrentToolResults()}
            selectedLabelIds={selectedLabelIds}
            updateToolResultsLabels={updateToolResultsLabels}
            setSelectedToolResults={setSelectedToolResults}
          />
        </div>
      </PrismLayout>
    </>
  )
}

export default LabelingScreen

/**
 * ToolResults Redux branch updater.
 * Updates all the necessary individual toolResults with its correspionding new labels.
 *
 * @param prevRes - The previous data from the toolResults branch
 *  */
const toolResultsUpdater = (
  updatedToolResultsDict: { [toolResultId: string]: ToolResult },
  prevRes?: SuccessResponseOnlyData<ToolResultsData>,
) => {
  if (prevRes) {
    const updatedToolResults = prevRes.data.results.map(toolResult => {
      const newToolResult = updatedToolResultsDict[toolResult.id]
      return newToolResult || toolResult
    })

    return { ...prevRes, data: { ...prevRes.data, results: updatedToolResults } }
  }
}

export const getToolResultCardId = (id: string) => {
  return `tool-result-labeling-card-${id}`
}

const updateToolLabelInGetterBranch = ({
  toolLabel,
  labelsGetterKey,
  dispatch,
}: {
  toolLabel: ToolLabel
  labelsGetterKey: string
  dispatch: Dispatch
}) => {
  dispatch(
    Actions.getterUpdate({
      key: getterKeys.toolLabels(labelsGetterKey),
      updater: res => {
        if (!res?.data.results) return res

        const updatedLabels = [...res.data.results]

        const foundIdx = updatedLabels.findIndex(tl => tl.id === toolLabel.id)
        if (foundIdx < 0) return

        updatedLabels[foundIdx] = toolLabel

        return { ...res, data: { ...res.data, results: updatedLabels } }
      },
    }),
  )
}

/**
 * Updates the ToolLabel in "All ToolLabels" getter branch, if an specific getterKey is provied, it is also updated
 * in that branch
 *
 * @param toolLabel - ToolLabel to update
 * @param labelsGetterKey - If provided, ToolLabel will also be updated for this getter branch
 * @param dispatch - Dispatch
 */
export const updateReduxSingleToolLabel = ({
  toolLabel,
  labelsGetterKey,
  dispatch,
}: {
  toolLabel: ToolLabel
  labelsGetterKey?: string
  dispatch: Dispatch
}) => {
  if (labelsGetterKey) {
    updateToolLabelInGetterBranch({ toolLabel, dispatch, labelsGetterKey })
  }

  updateToolLabelInGetterBranch({ toolLabel, dispatch, labelsGetterKey: ALL_TOOL_LABELS_GETTER_KEY })
}
