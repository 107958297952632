import React, { useCallback, useMemo, useRef, useState } from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { keyBy } from 'lodash'
import debounce from 'lodash/debounce'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, RecipeParentsData, service, useQuery } from 'api'
import ArchiveIconButton from 'components/ArchiveIconButton/ArchiveIconButton'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import DeployedVersion from 'components/DeployedVersion/DeployedVersion'
import { IconButton } from 'components/IconButton/IconButton'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import {
  PrismArchiveIcon,
  PrismCopyIcon,
  PrismEditIcon,
  PrismNavArrowIcon,
  PrismOverflowIcon,
  PrismProductIcon,
  PrismSearchIcon,
} from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import RecipeNameWithThumbnail from 'components/RecipeNameWithThumbnail/RecipeNameWithThumbnail'
import { useOnScreen, useStationDeployedRecipe } from 'hooks'
import { useVirtualizedTable } from 'pages/Analyze/AnalyzeBase'
import DuplicateOrMoveRecipeModal from 'pages/RoutineOverview/DuplicateOrMoveRecipeModal'
import ArchiveEntityModal from 'pages/StationList/Inspect/ArchiveSlsModal'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import { Component, Recipe, RecipeParent, Station } from 'types'
import { getRecipeParentImage, getterAddPage, getTimeAgoFromDate, unarchiveRecipe } from 'utils'
import { TABLE_HEADER_HEIGHT } from 'utils/constants'

import ChangeRecipeProductModal from './ChangeRecipeProductModal'
import Styles from './RecipesList.module.scss'
import RenameRecipeModal from './RenameRecipeModal'

const VIRTUALIZE_TABLE_TOP_PADDING = 16
const VIRTUALIZE_TABLE_BOTTOM_PADDING = 32

interface RecipeParentWithLatestDeployedVersion extends RecipeParent {
  deployedRecipe?: Recipe
}

function RecipesList({ station }: { station: Station }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [showArchivedRecipes, setShowArchivedRecipes] = useState<boolean>(false)
  const [showRecipeSearch, setShowRecipeSearch] = useState<boolean>(false)
  const [recipeSearchText, setRecipeSearchText] = useState<string>()
  const [loadingRecipes, setLoadingRecipes] = useState(true)

  const recipeParentsKey = getterKeys.filteredRecipeParents(station.id, 'all')

  const recipeParentsFetcher = useCallback(
    async (is_deleted: boolean, name?: string) => {
      setLoadingRecipes(true)
      const res = await service.getRecipeParents({ station_id: station.id, is_deleted, name, order_by: '-updated_at' })
      setLoadingRecipes(false)
      return res
    },
    [station.id],
  )

  const refetchRecipeParents = useCallback(
    (isDeleted: boolean, name: string | undefined) => {
      query(recipeParentsKey, () => recipeParentsFetcher(isDeleted, name), { dispatch })
    },
    [dispatch, recipeParentsFetcher, recipeParentsKey],
  )

  const recipeParentsData = useQuery(recipeParentsKey, () => recipeParentsFetcher(false, '')).data?.data
  const recipeParents = recipeParentsData?.results
  const next = recipeParentsData?.next

  const handleRecipeClick = async (id: string) => {
    history.push(paths.settingsRecipe(id, 'capture'))
  }

  const handleChangeRecipeParentSearch = useMemo(
    () =>
      debounce((value: string) => {
        if (!recipeParentsKey) return
        refetchRecipeParents(showArchivedRecipes, value)
        setRecipeSearchText(value)
      }, 350),
    [recipeParentsKey, refetchRecipeParents, showArchivedRecipes],
  )

  const { deployedRecipes } = useStationDeployedRecipe(station.robots.map(robot => robot.id))

  const deployedRecipesByParentId = useMemo(() => {
    return keyBy(deployedRecipes, recipe => recipe.parent_id)
  }, [deployedRecipes])

  const recipeParentsWithLastDeployedVersion = useMemo(() => {
    return recipeParents?.map(recipeParent => {
      const deployedRecipe = deployedRecipesByParentId[recipeParent.id]
      return { ...recipeParent, deployedRecipe }
    })
  }, [deployedRecipesByParentId, recipeParents])

  const columns = getColumns(() => refetchRecipeParents(showArchivedRecipes, recipeSearchText))

  const handleEndReached = useCallback(async () => {
    if (!next) return

    const res = await service.getNextPage<RecipeParentsData>(next)
    if (res.type === 'success') {
      dispatch(
        Actions.getterUpdate({
          key: recipeParentsKey,
          updater: prev => getterAddPage(prev, res.data),
        }),
      )
    }
  }, [dispatch, next, recipeParentsKey])

  const { renderVirtualTable, tableContainerRef, tableHeight, columnsWithWidths } = useVirtualizedTable(columns, {
    rowHeight: 88,
    tableCustomHeaderHeight: TABLE_HEADER_HEIGHT + VIRTUALIZE_TABLE_TOP_PADDING + VIRTUALIZE_TABLE_BOTTOM_PADDING,
    handleEndReached,
    onClick: recipeParent => {
      if (recipeParent.is_deleted) return
      handleRecipeClick(recipeParent.id)
    },
    removeAnalyzeTablePadding: true,
    getTestId: recipeParent => `${recipeParent.name}-recipe-list-table-option`,
  })

  return (
    <>
      <section className={Styles.recipeContainer}>
        <div className={Styles.recipeHeaderWrapper}>
          <div className={Styles.recipeHeaderWrapperLeft}>
            <PrismSearchInput
              name="recipeParentSearch"
              inputDataTestId="recipes-list-recipe-search"
              onInputChange={e => handleChangeRecipeParentSearch(e.target.value)}
              onSearchButtonClick={() => {
                // Refetch all recipes if user closes search input
                if (showRecipeSearch && recipeSearchText) {
                  refetchRecipeParents(showArchivedRecipes, '')
                }
                setShowRecipeSearch(!showRecipeSearch)
                setRecipeSearchText(undefined)
              }}
              className={Styles.recipeSearchField}
              size="height24"
              showIconPrefix
              placeholder="Search"
            />
          </div>

          <div className={Styles.recipeHeaderWrapperRight}>
            <ArchiveIconButton
              data-testid="recipes-list-recipes-see-archive"
              isActive={showArchivedRecipes}
              onClick={() => {
                setShowArchivedRecipes(!showArchivedRecipes)
                refetchRecipeParents(!showArchivedRecipes, recipeSearchText)
              }}
              className={Styles.archiveIconButton}
            />
          </div>
        </div>

        <div ref={tableContainerRef} className={Styles.recipeTableWrapper}>
          {tableHeight && (
            <Table
              data-testid="test-table"
              columns={columnsWithWidths}
              dataSource={recipeParentsWithLastDeployedVersion}
              scroll={{ y: !!recipeParentsWithLastDeployedVersion?.length ? tableHeight : undefined }}
              pagination={false}
              loading={{
                spinning: loadingRecipes,
                wrapperClassName: Styles.tableLoaderWrapper,
                indicator: <PrismLoader className={Styles.tableLoaderPoistion} />,
              }}
              components={{ body: renderVirtualTable }}
              locale={{
                emptyText: recipeSearchText ? (
                  <GenericBlankStateMessage header={<PrismSearchIcon />} description="No recipes match your search" />
                ) : undefined,
              }}
              className={Styles.recipesTable}
            />
          )}
        </div>
      </section>
    </>
  )
}

export default RecipesList

const RecipeNameWrapper = ({ image, recipeName }: { image?: string; recipeName: string }) => {
  const ref = useRef<HTMLDivElement>(null)

  const isOnScreen = useOnScreen(ref)

  return (
    <RecipeNameWithThumbnail
      bodyRef={ref}
      image={isOnScreen ? image : undefined}
      recipeName={recipeName}
      className={`${Styles.recipeNameWithThumbnail} ${Styles.recipeTableTypography}`}
    />
  )
}

const getColumns = (refreshRecipes: () => void): ColumnsType<RecipeParentWithLatestDeployedVersion> => [
  {
    title: 'name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (_, recipeParent: RecipeParent) => {
      const image = getRecipeParentImage(recipeParent, { preferThumbnail: true })
      return <RecipeNameWrapper image={image} recipeName={recipeParent.name} />
    },
  },
  {
    title: 'product',
    dataIndex: 'product',
    key: 'product',
    ellipsis: true,
    render: (_, recipeParent) => (
      <PrismOverflowTooltip content={recipeParent.component_name} className={Styles.recipeTableTypography} />
    ),
    width: 200,
  },
  {
    title: 'version',
    dataIndex: 'version',
    key: 'version',
    ellipsis: true,
    render: (_, recipeParent) => (
      <PrismOverflowTooltip
        content={`v${recipeParent.working_version?.version}`}
        className={`${Styles.cellAlignRight} ${Styles.recipeTableTypography}`}
      />
    ),
    width: 100,
  },
  {
    title: 'edited',
    dataIndex: 'edited',
    key: 'edited',
    ellipsis: true,
    render: (_, recipeParent) => (
      <PrismOverflowTooltip
        content={getTimeAgoFromDate(recipeParent.updated_at).text}
        className={`${Styles.cellAlignRight} ${Styles.recipeTableTypography}`}
      />
    ),
    width: 100,
  },
  {
    title: 'deployed',
    dataIndex: 'deployed',
    key: 'deployed',
    ellipsis: true,
    render: (_, recipeParent) => {
      const latestDeployedVersion = recipeParent.deployedRecipe?.version
      return (
        <DeployedVersion
          latestRecipeVersion={recipeParent.working_version?.version}
          latestDeployedVersion={latestDeployedVersion}
          className={Styles.cellAlignRight}
        />
      )
    },
    width: 100,
  },
  {
    title: '',
    dataIndex: 'optionMenu',
    key: 'optionMenu',
    render: (_, recipeParent) => {
      return recipeParent.is_deleted ? (
        <Button
          className={Styles.restoreButton}
          size="small"
          type="secondary"
          onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            const res = await service.updateRecipeParent(recipeParent.id, { is_deleted: false })
            if (res.type !== 'success') return error({ title: 'An error occurred, please try again' })
            success({ title: 'Recipe unarchived' })
            refreshRecipes()
          }}
        >
          Restore
        </Button>
      ) : (
        <RecipeOptionMenu
          recipeParent={{ ...recipeParent, station_name: recipeParent.station?.name || '' }}
          refreshRecipes={refreshRecipes}
          componentId={recipeParent.component_id}
          menuWidth={216}
        />
      )
    },
    width: 118,
  },
]

type RecipeOptionMenuOption = 'rename' | 'duplicate' | 'archive' | 'changeProduct' | 'move'

export const RecipeOptionMenu = ({
  recipeParent,
  refreshRecipes,
  iconButtonClassName = '',
  componentId,
  menuWidth,
}: {
  recipeParent: Component['recipe_parents'][number]
  refreshRecipes: () => any
  iconButtonClassName?: string
  componentId: string
  menuWidth?: number
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const [activeModalType, setActiveModalType] = useState<RecipeOptionMenuOption>()

  const handleMenuClick = async (value: RecipeOptionMenuOption) => {
    if (value === 'archive' && recipeParent.is_deleted) {
      return unarchiveRecipe({ recipeParentId: recipeParent.id, onSuccess: refreshRecipes })
    }

    setActiveModalType(value)
  }
  const options = useMemo(() => {
    const toReturn: { value: RecipeOptionMenuOption; title?: string; badge?: React.ReactNode }[] = [
      { value: 'rename', badge: <PrismEditIcon /> },
      { value: 'duplicate', badge: <PrismCopyIcon /> },
      { value: 'move', badge: <PrismNavArrowIcon /> },
      { value: 'changeProduct', title: 'Change Product', badge: <PrismProductIcon /> },
      {
        value: 'archive',
        title: recipeParent.is_deleted ? 'unarchive' : 'archive',
        badge: <PrismArchiveIcon />,
      },
    ]

    return toReturn
  }, [recipeParent.is_deleted])

  return (
    <>
      <OptionMenu
        options={options}
        openWithClick
        onOpen={() => {
          setMenuIsOpen(!menuIsOpen)
        }}
        onMenuItemClick={handleMenuClick}
        menuContainerClassName={Styles.recipeRowMenuContainer}
        closeOnClick
        renderWithPortal
        menuWidth={menuWidth}
      >
        <IconButton
          icon={<PrismOverflowIcon />}
          size="small"
          type="tertiary"
          className={`${Styles.rowMenuIcon} ${iconButtonClassName} ${menuIsOpen ? Styles.iconButtonActive : ''}`}
        />
      </OptionMenu>

      {activeModalType === 'rename' && (
        <RenameRecipeModal
          recipeParentId={recipeParent.id}
          recipeParentName={recipeParent.name}
          onClose={() => setActiveModalType(undefined)}
          onUpdate={refreshRecipes}
        />
      )}

      {activeModalType === 'archive' && (
        <ArchiveEntityModal
          entityType="recipe"
          entityId={recipeParent.id}
          onOk={refreshRecipes}
          onClose={() => setActiveModalType(undefined)}
        />
      )}

      {activeModalType === 'changeProduct' && (
        <ChangeRecipeProductModal
          recipeParentId={recipeParent.id}
          componentId={componentId}
          onClose={() => setActiveModalType(undefined)}
          onSuccess={refreshRecipes}
        />
      )}

      {(activeModalType === 'move' || activeModalType === 'duplicate') && (
        <DuplicateOrMoveRecipeModal
          id={activeModalType === 'move' ? 'recipe-list-move-recipe-modal' : 'recipe-list-duplicate-recipe'}
          isDuplicating={activeModalType === 'duplicate'}
          recipeParentId={recipeParent.id}
          defaultName={recipeParent.name}
          currentRecipeStationId={recipeParent.station_id}
          recipeParentWorkingVersionId={recipeParent.working_version?.id}
          onSubmit={refreshRecipes}
          onClose={() => setActiveModalType(undefined)}
        />
      )}
    </>
  )
}
