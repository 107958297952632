import React from 'react'

import { Button } from 'components/Button/Button'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { PrismToggleGroup } from 'components/PrismToggleGroup/PrismToggleGroup'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { OfflineTag } from 'components/Tag/Tag'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { Component, ConnectionStatus, RoutineParent, TestImagesSource, Tool, TrainingReportTab } from 'types'
import { getTimeAgoFromDate } from 'utils'

import Styles from './TrainingReport.module.scss'

type TrainingReportFiltersProps = {
  showSidebar: boolean
  inspectionId?: string | null
  isSharedTool?: boolean
  testImagesSource: TestImagesSource
  setTestImagesSource: React.Dispatch<React.SetStateAction<TestImagesSource>>
  tool?: Tool
  selectedRoutineParentId: string | undefined
  setSelectedRoutineParentId: React.Dispatch<React.SetStateAction<string | undefined>>
  selectedComponentId: string | undefined
  setSelectedComponentId: React.Dispatch<React.SetStateAction<string | undefined>>
  routineParentsForFilters: RoutineParent[] | undefined
  componentsForFilters: Component[] | undefined
  setShowSidebar: (value: React.SetStateAction<boolean>) => void
  trainingReportTab: TrainingReportTab
  setTrainingReportTab: React.Dispatch<React.SetStateAction<TrainingReportTab>>
  isTrainingReportV1: boolean
  connectionStatus: ConnectionStatus
  insightsEnabled: boolean
  setInsightsEnabled: (enabled: boolean) => void
  thresholdText?: string
}

/**
 * Renders the training report filters.
 *
 * @param showSidebar - Wheter the sidebar is open or close
 * @param setShowSidebar - State updater for sidebar
 * @param inspectionId - Inspection ID to show data from. If defined, the component asumes we're in the StationDetail Tools tab
 * @param isSharedTool - Whether the tool is shared
 * @param testImagesSource -
 * @param setTestImagesSource - State updater for test images
 * @param tool -Current tool
 * @param selectedRoutineParentId -
 * @param setSelectedRoutineParentId - State updater for selected routine parent id
 * @param routineParentsForFilters -
 * @param toggleImagesState -
 * @param setToggleImagesState - State updater for the images toggle
 */
export const TrainingReportFilters = ({
  showSidebar,
  setShowSidebar,
  inspectionId,
  isSharedTool,
  testImagesSource,
  setTestImagesSource,
  tool,
  selectedRoutineParentId,
  setSelectedRoutineParentId,
  selectedComponentId,
  setSelectedComponentId,
  routineParentsForFilters,
  componentsForFilters,
  trainingReportTab,
  setTrainingReportTab,
  isTrainingReportV1,
  connectionStatus,
  insightsEnabled,
  setInsightsEnabled,
  thresholdText,
}: TrainingReportFiltersProps) => {
  const showInsightsButton = tool?.specification_name === 'deep-svdd' || tool?.specification_name === 'graded-anomaly'

  return (
    <section className={Styles.filters}>
      {connectionStatus === 'offline' && <OfflineTag />}

      {inspectionId && connectionStatus !== 'offline' && (
        <PrismToggleGroup
          onChange={value => setTestImagesSource(value)}
          value={testImagesSource}
          options={[
            { label: 'Uploads from Batch', value: 'inspection' },
            { label: <>{isTrainingReportV1 ? 'Training' : 'Validation'} Images</>, value: 'training' },
          ]}
        />
      )}

      {!inspectionId && connectionStatus !== 'offline' && (
        <>
          <PrismToggleGroup
            onChange={value => setTrainingReportTab(value)}
            value={trainingReportTab}
            options={[
              { label: 'All', value: 'all', tooltipTitle: 'All images in model dataset' },
              {
                label: 'Train',
                value: 'train',
                tooltipTitle: isTrainingReportV1
                  ? 'Retrain this tool to access this feature'
                  : 'Random images used to teach model',
                disabled: isTrainingReportV1,
              },
              {
                label: 'Validate',
                value: 'validation',
                tooltipTitle: isTrainingReportV1
                  ? 'Retrain this tool to access this feature'
                  : 'Random images used to tune threshold',
                disabled: isTrainingReportV1,
              },
              {
                label: 'Test',
                value: 'test',
                tooltipTitle: isTrainingReportV1
                  ? 'Retrain this tool to access this feature'
                  : 'Images selected by user to test model',
                disabled: isTrainingReportV1,
              },
            ]}
          />

          {isSharedTool && (
            <>
              <PrismTooltip
                condition={isTrainingReportV1}
                title="Retrain this tool to access this feature"
                placement="bottom"
              >
                <PrismSelect
                  disabled={isTrainingReportV1}
                  className={Styles.selectContainer}
                  placeholder="Product"
                  showSearch
                  filterOption={(input, options) => options?.title?.toLowerCase().includes(input.toLowerCase())}
                  onSelect={setSelectedComponentId}
                  value={selectedComponentId}
                  size="small"
                  popupClassName={Styles.dropdownWrapper}
                  options={productListOptions(componentsForFilters)}
                />
              </PrismTooltip>

              <PrismTooltip
                condition={isTrainingReportV1}
                title="Retrain this tool to access this feature"
                placement="bottom"
              >
                <PrismSelect
                  disabled={isTrainingReportV1}
                  className={Styles.selectContainer}
                  placeholder="View"
                  showSearch
                  filterOption={(input, option) => option?.title?.toLowerCase().includes(input.toLowerCase())}
                  onSelect={setSelectedRoutineParentId}
                  value={selectedRoutineParentId}
                  size="small"
                  popupClassName={Styles.dropdownWrapper}
                  options={[
                    { value: '', content: <>&mdash;</> },
                    ...(routineParentsForFilters
                      ? routineParentsForFilters.map(routineParent => ({
                          key: routineParent.id,
                          value: routineParent.id,
                          content: (
                            <div className={Styles.viewOption}>
                              <PrismOverflowTooltip content={routineParent.recipe_parent.name} canBeResized />
                              <span>- {routineParent.name}</span>
                            </div>
                          ),
                        }))
                      : []),
                  ]}
                />
              </PrismTooltip>
            </>
          )}
        </>
      )}

      {!inspectionId && (
        <>
          <Button
            type="secondary"
            size="xsmall"
            onClick={() => setShowSidebar(!showSidebar)}
            className={`${Styles.editThresholdBtn} ${showSidebar ? Styles.hideBtn : Styles.showBtn}`}
            disabled={showSidebar}
          >
            Threshold ({thresholdText})
          </Button>

          {showInsightsButton && (
            <PrismTooltip
              condition={isTrainingReportV1}
              placement="bottom"
              title="Retrain this tool to access this feature"
            >
              <ToggleButton
                className={Styles.insightsButton}
                title="insights"
                disabled={isTrainingReportV1}
                onClick={() => setInsightsEnabled(!insightsEnabled)}
                active={insightsEnabled}
                hotkey="I"
                size="xsmall"
              />
            </PrismTooltip>
          )}
          <span className={Styles.trainingReportTimestamp}>
            {`Trained ${tool ? getTimeAgoFromDate(tool.created_at).text : '--'}`}
          </span>
        </>
      )}
    </section>
  )
}

/*
 * Creates the products list options for the prismSelect
 */
const productListOptions = (list?: Component[]) => {
  const initialValue = [{ value: '', content: <>&mdash;</> }]
  if (!list) return initialValue
  return [
    ...initialValue,
    ...list.map(listOption => ({
      title: listOption.name,
      value: listOption.id,
      key: listOption.id,
      id: listOption.id,
    })),
  ]
}
