import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { Tooltip, TooltipProps } from 'antd'
import { debounce } from 'lodash'
import { Provider, useDispatch } from 'react-redux'

import { Button, PrismButtonProps } from 'components/Button/Button'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { IconButton } from 'components/IconButton/IconButton'
import PointerTooltip from 'components/PointerTooltip/PointerTooltip'
import { PrismCloseIcon } from 'components/prismIcons'
import { PrismTabMenu } from 'components/PrismTab/PrismTab'
import * as Actions from 'rdx/actions'
import store from 'rdx/store'

import Styles from './PrismModal.module.scss'

const MODAL_DELAY_DURATION_MS = 50

const sizeClasses = {
  small: Styles.small,
  medium: Styles.medium,
  large: Styles.large,
  extraLarge: Styles.extraLarge,
  largeNarrow: Styles.largeNarrow,
  largeSimpleForm: Styles.largeSimpleForm,
  wide: Styles.wide,
}

// TYPES
interface ModalNavProps {
  items: { value: string; label?: string; 'data-testid'?: string }[]
  selectedItems: string[]
  onSelect: (value: string) => void
  modalNavClassName?: string
}

interface ModalHeaderProps {
  children?: React.ReactNode
  onClose?: () => void
  className?: string
  closeIconClassName?: string
  modalNav?: ModalNavProps
}

interface ModalFooterProps {
  children?: React.ReactNode
  tertiary?: React.ReactNode
  onOk?: () => any
  okText?: React.ReactNode
  onCancel?: () => any
  cancelText?: React.ReactNode
  danger?: boolean
  disableSave?: boolean
  className?: string
  okButtonProps?: Omit<PrismButtonProps, 'size' | 'type' | 'disabled' | 'onClick' | 'children'>
  okToolTipProps?: Omit<TooltipProps, 'children'>
  cancelToolTipProps?: Omit<TooltipProps, 'children'>
  'data-testid'?: string
}

interface BaseModalProps extends ModalHeaderProps {
  className?: string
  overlayClassName?: string
  onModalLoad?: () => any
  size?: 'small' | 'medium' | 'large' | 'largeNarrow' | 'largeSimpleForm' | 'extraLarge' | 'wide'
  ignoreAnimation?: boolean
  'data-testid'?: string
  id: string
}

export interface ModalProps extends BaseModalProps, ModalFooterProps {
  header?: React.ReactNode
  extraButtons?: React.ReactNode
  showCancel?: boolean
  hideCross?: boolean
  headerClassName?: string
  modalBodyClassName?: string
  modalFooterClassName?: string
  'data-testid'?: string
  modalBodyRef?: React.Ref<HTMLDivElement>
  showFooter?: boolean
  okButtonProps?: Omit<PrismButtonProps, 'size' | 'type' | 'disabled' | 'onClick' | 'children'>
  okToolTipProps?: Omit<TooltipProps, 'children'>
  cancelToolTipProps?: Omit<TooltipProps, 'children'>
}

interface FnModalProps extends Omit<ModalProps, 'onClose' | 'children' | 'onOk' | 'okButtonProps'> {
  content: React.ReactNode
  onClose?: () => any
  showCancel?: boolean
  onOk?: (onClose: () => any) => any
}

// HOC used for rendering things outside the current DOM
export const externalRender = <T extends { [key: string]: any }>(Component: (props: T) => JSX.Element) => {
  return (props: T) => {
    // Create a portal with react so that this component gets rendered at the root of the DOM.
    // This is important due to some issues regarding css stacking context.
    return ReactDOM.createPortal(<Component {...props} />, document.body)
  }
}

/**
 * Render a nav menu in the header
 *
 * @param items - the set of items to display in the menu
 * @param selectedItems - adds a line below the selected nav item
 * @param onSelect - function which should return the selectItem
 * @param modalNavClassName - container class name
 */
function ModalNav({ modalNav }: { modalNav: ModalNavProps }) {
  return (
    <PrismTabMenu
      items={modalNav.items}
      selectedItems={modalNav.selectedItems}
      onSelect={modalNav.onSelect}
      className={`${Styles.modalNav} ${modalNav.modalNavClassName ?? ''}`}
    />
  )
}

/**
 * Render the header of a modal, will show a close X button to close the modal
 * but won't render anything if there are no children
 *
 * @param children - use children to populate the body of the ModalHeader component
 * @param onClose - function that will be called when clicking the X icon or, if used within a modal, when clicking outside of the modal
 * @param className - container class name
 * @param closeIconClassName - Icon class name
 * @param modalNav - adds a space in the header for an internal navigation menu
 */
export const ModalHeader = ({ children, onClose, className, modalNav, closeIconClassName }: ModalHeaderProps) => {
  return (
    <div className={`${Styles.modalHeader} ${modalNav ? Styles.modalHeaderMenu : ''} ${className ?? ''}`}>
      <div className={Styles.modalTitle}>
        {children}
        {onClose && (
          <IconButton
            size="xsmall"
            type="tertiary"
            icon={<PrismCloseIcon className={Styles.closeIcon} />}
            onClick={onClose}
            data-testid="prism-modal-close"
            className={closeIconClassName ?? ''}
          />
        )}
      </div>

      {modalNav && <ModalNav modalNav={modalNav} />}
    </div>
  )
}

/**
 * Render the body of a modal
 *
 * @param children - use children to populate the body of the ModalBody component
 * @param className - Body class name
 * @param modalBodyRef - Reference to the current body container div
 */
export const ModalBody = ({
  children,
  className,
  modalBodyRef,
}: {
  children: React.ReactNode
  className?: string
  modalBodyRef?: React.Ref<HTMLDivElement>
}) => {
  if (!children) return null
  return (
    <div className={`${className ?? ''} ${Styles.modalBody}`} ref={modalBodyRef}>
      {children}
    </div>
  )
}

/**
 * Render the footer of a modal
 *
 * @param children - use children to add additional buttons or content to the footer
 * @param tertiary - use tertiary to add additional buttons or content to the footer // same as children but calling it tertiary for logical purposes
 * @param onOk - if a function is passed this will auto-generate an "accept" button with onOk as the click handler
 * @param okText - show custom text for the positive action button
 * @param onCancel - if a function is passed this will auto-generate an "cancel" button with onOk as the click handler
 * @param cancelText - show custom text for the negative "cancel" action button
 * @param danger - show the onOk button with the danger red variation
 * @param dataTestId - used to create data-testid on the buttons
 */
export const ModalFooter = ({
  onOk,
  okText,
  onCancel,
  cancelText,
  children,
  tertiary,
  danger,
  disableSave,
  className,
  okButtonProps,
  okToolTipProps,
  cancelToolTipProps,
  'data-testid': dataTestId,
}: ModalFooterProps) => {
  if (!onCancel && !onOk && !children && !tertiary) return null

  return (
    <div className={`${Styles.modalFooter} ${className ?? ''}`}>
      {tertiary}
      <span className={Styles.modalFooterSecondary}>{children}</span>

      <span className={Styles.modalFooterPrimary}>
        {onCancel && (
          <ButtonTooltipWrapper tooltipProps={cancelToolTipProps}>
            <Button
              type="tertiary"
              size="small"
              onClick={onCancel}
              data-testid={dataTestId ? `${dataTestId}-cancel` : undefined}
            >
              {cancelText || 'Cancel'}
            </Button>
          </ButtonTooltipWrapper>
        )}

        {onOk && (
          <ButtonTooltipWrapper tooltipProps={okToolTipProps} buttonDisabled={disableSave}>
            <Button
              disabled={disableSave}
              type={danger ? 'danger' : 'primary'}
              size="small"
              onClick={onOk}
              data-testid={dataTestId ? `${dataTestId}-ok` : undefined}
              {...okButtonProps}
            >
              {okText || 'Save'}
            </Button>
          </ButtonTooltipWrapper>
        )}
      </span>
    </div>
  )
}

const ButtonTooltipWrapper = ({
  children,
  tooltipProps,
  buttonDisabled,
}: {
  children: JSX.Element
  tooltipProps?: Omit<TooltipProps, 'children'>
  buttonDisabled?: boolean
}) => {
  return (
    <ConditionalWrapper
      condition={!!tooltipProps}
      wrapper={button => {
        // Disabled buttons don't trigger the normal Tooltip, we need to use our custom
        // wrapper with pointer events
        const TooltipToUse = buttonDisabled ? PointerTooltip : Tooltip

        return (
          <TooltipToUse {...(tooltipProps as TooltipProps)}>
            <div>{button}</div>
          </TooltipToUse>
        )
      }}
    >
      {children}
    </ConditionalWrapper>
  )
}

/**
 * Renders Base Prism Modal component.
 * The onModalLoad callback is useful for when we have images with absolute calculated values in a modal. Due to
 * the animation of the modal, if the content of the modal is rendered on component mount, the calculated width
 * and height of the modal will be a fraction of the total size, as the animation is still running. Using the
 * onModalLoad callback to conditionally render images is the best way to avoid this behaviour.
 *
 * @param onClose - function that will be called when clicking outside of the modal or with the close X button
 * @param onModalLoad - Callback for when the main transition event is done
 * @param children - use children to populate the body of the Modal component
 * @param className - Class name for modal wrapper
 * @param overlayClassName - Class name for modal wrapper
 * @param size - how large the modal will be, defaults to medium
 * @param ignoreAnimation - Prevent animation from triggering
 */
const PrismModalComponent = ({
  onClose,
  onModalLoad,
  children,
  className,
  overlayClassName,
  ignoreAnimation,
  size = 'medium',
  'data-testid': dataTestId,
  id,
}: BaseModalProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [loadingAnimation, setLoadingAnimation] = useState<'start' | 'end'>('start')
  const sizeClass = sizeClasses[size]

  useEffect(() => {
    const transitionEndCallback = debounce(() => onModalLoad?.(), 200)
    const containerDiv = containerRef.current
    containerDiv?.addEventListener('transitionend', transitionEndCallback)
    containerDiv?.addEventListener('oTransitionEnd', transitionEndCallback)
    containerDiv?.addEventListener('webkitTransitionEnd', transitionEndCallback)

    const timer = setTimeout(() => {
      setLoadingAnimation('end')
    }, MODAL_DELAY_DURATION_MS)

    return () => {
      clearTimeout(timer)
      containerDiv?.removeEventListener('transitionend', transitionEndCallback)
      containerDiv?.removeEventListener('oTransitionEnd', transitionEndCallback)
      containerDiv?.removeEventListener('webkitTransitionEnd', transitionEndCallback)
    }
  }, [onModalLoad])

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClose?.()
  }

  useModalsSync(id)

  // Create a portal with react so that this component gets rendered at the root of the DOM.
  // This is important due to some issues regarding css stacking context.

  // It's important to use stopPropagation in both the Overlay and the Modal.
  // There's an specific case in RecipeList where any modal that opens from a recipe `onRow` save the row as the parent,
  // then closing the modal through clicking the overlay causes a bubbling event triggering the row.
  // The modal uses stopPropagation to stop the handleClose function from the overlay.
  return (
    <ModalContext.Provider value={{ id: id.toString() }}>
      <div
        ref={containerRef}
        className={` ${Styles.prismOverlay} ${overlayClassName ?? ''} ${
          ignoreAnimation ? Styles.ignoreAnimation : ''
        } ${loadingAnimation === 'end' ? Styles.prismOverlayEnd : Styles.prismOverlayStart}`}
        onMouseDown={handleClose}
        data-testid={dataTestId + '-close'}
      >
        <div
          data-testid={dataTestId}
          className={`${className ?? ''} ${Styles.prismModal} ${
            loadingAnimation === 'end' ? Styles.prismModalEnd : Styles.prismModalStart
          } ${sizeClass}`}
          onClick={e => {
            e.stopPropagation()
          }}
          onMouseDown={e => {
            e.stopPropagation()
          }}
        >
          {children}
        </div>
      </div>
    </ModalContext.Provider>
  )
}

export const ModalContext = React.createContext<{ id?: string }>({})

export const PrismModal = externalRender(PrismModalComponent)

/**
 * This default modal has all necessary buttons and headers to hold the content of a standard modal.
 *
 * @param header - send a string of text to be rendered in the modal title
 * @param children - use children to populate the body of the Modal component
 * @param onClose - function that will be called when clicking outside of the modal or with the close X button
 * @param onOk - if a function is passed this will auto-generate an "continue" button with onOk as the click handler
 * @param okText - show custom text for the continue action button
 * @param cancelText - show custom text for the negative "cancel" action button
 * @param size - standard size of the modal. Defaults to "medium"
 * @param showCancel - Whether we should show the cancel button, which will use the same `onClose` handler. Defaults to true
 * @param extraButtons - Content to show on the bottom-left of the modal, as extra content, could be an additional button
 * @param className - The modal container class name
 * @param overlayClassName - The modal overlay container class name
 * @param disableSave - Whether the save button should be disabled
 * @param hideCross - Whether the cross on the header should be displayed or not
 * @param danger - Whether the save button should be a danger button
 * @param dataTestId - data-testid that will be used to inject these ID's to the Modal's buttons
 * @param modalBodyRef - Reference to the modal body. This body container is the one that holds the scrollbar, and it's useful for virtualized lists
 * @param modalNav - adds a space in the header modal for an internal navigation menu
 * @param id - If we want the modal to simply update and not rerender, we use this id
 */
export const Modal = ({
  header,
  children,
  onClose,
  onCancel,
  onOk,
  onModalLoad,
  okText,
  cancelText,
  size = 'medium',
  showCancel = true,
  extraButtons,
  overlayClassName,
  className,
  headerClassName,
  modalBodyClassName,
  modalFooterClassName,
  disableSave,
  hideCross,
  danger,
  'data-testid': dataTestId,
  modalBodyRef,
  modalNav,
  id,
  showFooter = true,
  okButtonProps,
  okToolTipProps,
  cancelToolTipProps,
}: ModalProps) => {
  const handleCancel = () => {
    onCancel?.()
    onClose?.()
  }

  return (
    <PrismModal
      overlayClassName={overlayClassName ?? ''}
      className={className ?? ''}
      size={size}
      onClose={onClose}
      onModalLoad={onModalLoad}
      data-testid={dataTestId}
      id={id}
    >
      {header && (
        <ModalHeader className={headerClassName ?? ''} onClose={!hideCross ? onClose : undefined} modalNav={modalNav}>
          {header}
        </ModalHeader>
      )}

      <ModalBody className={modalBodyClassName ?? ''} modalBodyRef={modalBodyRef}>
        {children}
      </ModalBody>

      {showFooter && (
        <ModalFooter
          disableSave={disableSave}
          okText={okText}
          onOk={onOk}
          onCancel={showCancel ? handleCancel : undefined}
          cancelText={cancelText}
          tertiary={extraButtons}
          danger={danger}
          data-testid={dataTestId}
          className={modalFooterClassName ?? ''}
          okButtonProps={okButtonProps}
          okToolTipProps={okToolTipProps}
          cancelToolTipProps={cancelToolTipProps}
        />
      )}
    </PrismModal>
  )
}

/**
 * Default rendering function for a modal. This code appends a div to the root element and renders the modal there.
 * When dismissed, this component also destroys that node from the render tree.
 *
 * @param content - The content to show inside the modal.
 * @param onOk - The content to show inside the modal.
 * @param content - The content to show inside the modal.
 * @param dataTestId - The base dataTestId to create the data-testid for each button.
 *
 */

const renderModal = ({ content, onOk, onClose, 'data-testid': dataTestId, size = 'small', ...rest }: FnModalProps) => {
  var div = document.createElement('div')
  document.body.appendChild(div)

  const handleClose = () => {
    onClose?.()
    ReactDOM.unmountComponentAtNode(div)
    div.remove()
  }

  // There's a slight issue with this functionality. This function has no reference of the dom, so we generate a temporary div
  // for it to stick the modal into. Further in the process of <Modal /> we use a Portal to stick the Modal outside the current
  // context, but for this modal we once again render a new div. Therefore this function creates two divs on its render, one for
  // the initial placement, and a second one for the portal
  ReactDOM.render(
    // As this is rendered "outside" the app tree, we need to setup a provider
    // so that we have access to the redux context in the Modals
    <Provider store={store}>
      <Modal
        {...rest}
        size={size}
        onClose={handleClose}
        onOk={onOk && (() => onOk(handleClose))}
        data-testid={dataTestId}
      >
        {content}
      </Modal>
    </Provider>,
    div,
  )
}

export const modal = {
  warning: (props: FnModalProps) => renderModal({ ...props, danger: true }),
  confirm: (props: FnModalProps) => renderModal({ ...props }),
  error: (props: FnModalProps) => renderModal({ ...props, onOk: close => close(), showCancel: false }),
}

/**
 * This hook is in charge of syncing the active modals state.
 * Whenever a modal is opened it is set as the last opened modal,
 * and we remove it when it is closed.
 *
 * We use this state to prevent hotkeys to trigger is a modal is active anywhere.
 *
 * @param modalId - The modal id
 */
export const useModalsSync = (modalId: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.modalsUpdate({ modalId, operation: 'add' }))

    return () => {
      dispatch(Actions.modalsUpdate({ modalId, operation: 'remove' }))
    }
  }, [dispatch, modalId])
}
