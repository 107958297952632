import React, { useMemo } from 'react'

import ImgFallback from 'components/Img/ImgFallback'
import LabelInfo from 'components/LabelInfo/LabelInfo'
import { PrismInfoIcon } from 'components/prismIcons'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { ToolLabel } from 'types'
import {
  findDefaultDescription,
  getDisplaySeverity,
  getLabelName,
  getToolLabelImagesToShow,
  showCausesAndActions,
  titleCase,
} from 'utils'

import Styles from './LabelingScreen.module.scss'

const ToolLabelDetail = ({ toolLabel }: { toolLabel: ToolLabel }) => {
  const showRootAndCorrectiveActions = showCausesAndActions(toolLabel.severity)

  const images = getToolLabelImagesToShow(toolLabel)

  const name = titleCase(getLabelName(toolLabel))

  const description = useMemo(() => {
    if (toolLabel.kind === 'default') return findDefaultDescription(toolLabel)

    return toolLabel.description || ''
  }, [toolLabel])

  return (
    <>
      {toolLabel.kind === 'default' && (
        <LabelInfo addDescriptionGap>
          <div className={Styles.standardCaption}>
            <PrismInfoIcon className={Styles.iconCaption} />
            This is a standard Elementary label and cannot be edited
          </div>
        </LabelInfo>
      )}

      <LabelInfo title="Name" addDescriptionGap>
        {name}
      </LabelInfo>

      <LabelInfo title="Severity" addDescriptionGap>
        <PrismResultButton
          severity={getDisplaySeverity(toolLabel)}
          value={toolLabel.severity}
          className={Styles.severityContainer}
          type="fill"
          size="small"
        />
      </LabelInfo>

      {images.length > 0 && (
        <LabelInfo title="Example Images" addDescriptionGap>
          <div className={Styles.examplesImagesWrapper}>
            {images.map(img => (
              <figure key={img} className={Styles.exampleImageContainer}>
                <ImgFallback src={img} retries={5} className={Styles.labelFormImage} />
              </figure>
            ))}
          </div>
        </LabelInfo>
      )}

      {description && (
        <LabelInfo title="Description" addDescriptionGap>
          <p className={Styles.itemDescription}>{description}</p>
        </LabelInfo>
      )}

      {showRootAndCorrectiveActions && toolLabel.root_cause && (
        <LabelInfo title="Root Causes">
          <p className={Styles.itemDescription}>{toolLabel.root_cause}</p>
        </LabelInfo>
      )}

      {showRootAndCorrectiveActions && toolLabel.corrective_actions && (
        <LabelInfo title="Corrective Actions">
          <p className={Styles.itemDescription}>{toolLabel.corrective_actions}</p>
        </LabelInfo>
      )}
    </>
  )
}

export default ToolLabelDetail
