import React, { useMemo, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useQuery } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import { IconButton } from 'components/IconButton/IconButton'
import {
  PrismNavArrowIcon,
  PrismNavArrowThickIcon,
  PrismSharedToolIcon,
  PrismUploadIcon,
  PrismWarningIcon,
} from 'components/prismIcons'
import { PrismCheckIcon } from 'components/prismIcons/PrismCheckIcon'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import SkeletonWrapper from 'components/SkeletonWrapper/SkeletonWrapper'
import { Token } from 'components/Token/Token'
import UploadModal from 'components/UploadModal/UploadModal'
import { useToolParentLabelCounts, useTrainingLabelsCounts, useTypedSelector } from 'hooks'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import { RecipeExpanded, RoutineWithAois, Tool, ToolFlatWithCurrentExperiment } from 'types'
import { commaSeparatedNumbers, sortByNewestFirst } from 'utils'

import { getLabeledSinceTrain } from '../Train'
import { ModelSection } from './ModelSection'
import Styles from './ToolInfoCard.module.scss'
import ToolInfoCardViewsSection from './ToolInfoCardViewsSection'
import TrainButton from './TrainButton'

interface Props {
  tool: Tool
  routine: RoutineWithAois
  recipe: RecipeExpanded
  readOnly: boolean
}

/**
 * Renders information about a selected tool in the train screen.
 *
 * @param tool - Tool to get data for
 * @param readOnly - Whether we're in readOnly mode
 * @param routine - The current routine
 * @param recipe - Recipe we are working on
 */
export const ToolInfoCard = ({ tool, routine, recipe, readOnly }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const itemUpload = useTypedSelector(state => state.itemUpload)

  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  const handleImagesUpload = (files: File[]) => {
    setUploadModalOpen(false)
    dispatch(
      Actions.itemUploadUpdate({
        files,
        routine,
        recipe,
      }),
    )
  }

  const toolParent = useQuery(getterKeys.toolParent(tool.parent_id), () => service.getToolParent(tool.parent_id)).data
    ?.data

  const orderedModelsList: ToolFlatWithCurrentExperiment[] | undefined = useMemo(
    () =>
      toolParent?.tools
        .filter(tool => toolParent.experiments.find(exp => exp.tools.includes(tool.id) && exp.state !== 'canceled'))
        .map(tool => {
          const currentExperiment = toolParent.experiments.find(experiment => experiment.id === tool.experiment_id)
          return { ...tool, currentExperiment }
        })
        .sort(sortByNewestFirst),
    [toolParent],
  )

  const lastTool = orderedModelsList?.[0]

  const { countsByLabelId, countsByComponentId, rawCounts, totalUnlabeled, totalLabeled } = useToolParentLabelCounts({
    toolParentId: tool.parent_id,
  })

  const isSharedTool = tool.is_shared

  const recipeRoutines = useQuery(isSharedTool ? getterKeys.toolParentRecipeRoutines(tool.parent_id) : undefined, () =>
    service.getRecipeRoutines({ is_working_version: true, tool_parent_id: tool.parent_id }),
  ).data?.data.results

  const { labelsAvailableForDataset } = useTrainingLabelsCounts(lastTool)

  const labeledSinceLastTrain = useMemo(() => {
    if (lastTool === undefined) return 0
    if (countsByLabelId === undefined || totalLabeled === undefined) return
    return getLabeledSinceTrain(totalLabeled, labelsAvailableForDataset)
  }, [lastTool, countsByLabelId, totalLabeled, labelsAvailableForDataset])

  // TODO: Shared tool phase 2
  const isTrainingTab = true

  return (
    <div className={Styles.toolInfoCard}>
      <section className={Styles.toolInfoHeader}>
        <div className={Styles.toolInfoTitleContainer}>
          <div className={Styles.toolInfoTitle}>
            {!isTrainingTab && (
              <IconButton
                icon={<PrismNavArrowIcon direction="left" />}
                type="ghost"
                size="small"
                className={Styles.goBack}
              />
            )}
            <PrismOverflowTooltip className={Styles.title} content={tool.parent_name} />

            {isSharedTool && (
              <PrismTooltip
                title={
                  <Token label="Shared Tool">
                    This tool is used in {recipeRoutines?.length} views. Its labels and training are shared.
                  </Token>
                }
                mouseEnterDelay={0.01}
                placement="bottom"
                overlayClassName={Styles.tooltipContainer}
                className={Styles.sharedIcon}
              >
                <PrismSharedToolIcon />
              </PrismTooltip>
            )}
          </div>

          {isTrainingTab && (
            <div className={Styles.toolStatus}>
              <div className={Styles.status}>
                <PrismCheckIcon className={Styles.statusIcon} />

                <span className={Styles.statusLabel}>
                  {orderedModelsList?.length === 0 || !tool.version
                    ? 'No active model'
                    : `Model ${tool.version} active`}
                </span>
              </div>

              {/* Shared Tools Phase 2 */}
              {false && (
                <div className={Styles.status}>
                  <PrismNavArrowThickIcon className={Styles.statusIcon} />

                  <span className={Styles.statusLabel}>Version Deployed</span>

                  <PrismWarningIcon className={Styles.warningIcon} isActive />
                </div>
              )}
            </div>
          )}
        </div>

        <span className={Styles.buttonsContainer}>
          <Button
            data-testid={toolParent ? 'tool-info-card-label' : undefined}
            disabled={readOnly}
            size="small"
            type="secondary"
            onClick={() => {
              if (!toolParent) return
              // This path shows the unlabeled tool results
              history.push(
                paths.labelingScreen(toolParent.id, 'gallery', {
                  user_label_set_isnull: true,
                  is_deleted: false,
                  ordering: '-created_at',
                  recipeParentId: routine.parent.recipe_parent.id,
                  routineParentId: routine.parent.id,
                }),
              )
            }}
          >
            Label
          </Button>

          <TrainButton
            readOnly={readOnly}
            routine={routine}
            tool={tool}
            mostRecentModel={lastTool}
            countsByLabelId={countsByLabelId}
            countsByComponentId={countsByComponentId}
            toolResultsCounts={rawCounts}
          />
        </span>
      </section>

      <Divider />
      <section className={Styles.toolInfoBody}>
        <section className={Styles.imageSetSection}>
          <div className={Styles.sectionTitleContainer}>
            <h4 className={Styles.sectionTitle}>Image Set</h4>
            <IconButton
              disabled={readOnly || !!itemUpload}
              className={Styles.uploadIcon}
              icon={<PrismUploadIcon />}
              type="tertiary"
              onClick={() => !itemUpload && setUploadModalOpen(true)}
              size="xsmall"
            />
          </div>

          <ul className={`${Styles.sectionBody} ${Styles.imageSet}`}>
            <ImageSetMetric loading={!countsByLabelId} label="Unlabeled" value={totalUnlabeled} />

            <ImageSetMetric loading={!countsByLabelId} label="Labeled" value={totalLabeled} />

            <ImageSetMetric
              loading={countsByLabelId === undefined || totalLabeled === undefined}
              label="Labeled since train"
              value={labeledSinceLastTrain === undefined ? 0 : labeledSinceLastTrain}
            />
          </ul>
        </section>

        <ModelSection
          readOnly={readOnly}
          models={orderedModelsList}
          isTrainingTab={isTrainingTab}
          routine={routine}
          recipe={recipe}
          currentTool={tool}
        />

        {isSharedTool && (
          <ToolInfoCardViewsSection
            isTrainingTab={isTrainingTab}
            tool={tool}
            routineParentId={routine.parent.id}
            readOnly={readOnly}
            recipeRoutines={recipeRoutines}
          />
        )}

        {uploadModalOpen && (
          <UploadModal
            title="Upload Images for Labeling"
            multiple
            referenceImageUrl={routine.image}
            onClose={() => setUploadModalOpen(false)}
            onUpload={handleImagesUpload}
          />
        )}
      </section>
    </div>
  )
}

/**
 * Renders a metric card in the train screen with data about the current tool
 *
 * @param label - Title of the metric
 * @param value - Value of the metric
 * @param loading - Whether we should show a loading state
 * @returns
 */
const ImageSetMetric = ({ label, value, loading }: { label: string; value?: string | number; loading?: boolean }) => {
  function renderValue(value: string | number | undefined) {
    if (value === undefined) return '--'
    if (typeof value === 'number') return commaSeparatedNumbers(value)
    return value
  }
  return (
    <li className={Styles.imageSetItem}>
      <h6 className={Styles.itemTitle}>{label}</h6>
      <span className={Styles.itemValue}>
        <SkeletonWrapper loading={!!loading} skeletonProps={{ className: Styles.skeletonLoader }}>
          <>{renderValue(value)}</>
        </SkeletonWrapper>
      </span>
    </li>
  )
}
