import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'

import Konva from 'konva'
import { Rect } from 'konva/lib/shapes/Rect'
import { Layer, Stage } from 'react-konva'

import { AreaOfInterestConfiguration, BoxWithShape, MaxBounds } from 'types'

import Styles from './AoisContainer.module.scss'
import EditableEllipse, { EllipseData } from './Shapes/EditableEllipse'
import EditablePolygon from './Shapes/EditablePolygon/EditablePolygon'
import EditableRectangle, { RectangleData } from './Shapes/EditableRectangle'
import { MaskingRectangle } from './Utils'

type AoiConfig = { aoi: AreaOfInterestConfiguration; key: string; isInactive?: boolean }

export interface Props {
  redrawKey?: string
  aoiConfigs: AoiConfig[]
  onUpdateAoiWithShape: (shape: BoxWithShape) => any
  onSelectAoi: (shape: AreaOfInterestConfiguration) => any
  disableTransform?: boolean
  disableRectangleRotation?: boolean
  showSizeBoxes?: boolean
  onDeselectAois?: () => void
  minSizeBoxWidth?: number
  minSizeBoxHeight?: number
  maxSizeBoxWidth?: number
  maxSizeBoxHeight?: number
  activeSizeBox?: 'min' | 'max'
  onScaleChange?: (scale: number) => any
  setBounds: React.Dispatch<React.SetStateAction<MaxBounds>>
  bounds: MaxBounds
  setRectangleData: (aoiId: string, rectangleData: RectangleData) => any
  rectangleData?: RectangleData
  handleTransform: (aoiId: string) => any
  handleTransformEnd: (aoiId: string) => any
  toExportAoi: () => BoxWithShape | undefined
  setEllipseData: (aoiId: string, ellipseData: EllipseData) => any
  allRectanglesData: { [aoiId: string]: RectangleData }
  allEllipseData: { [aoiId: string]: EllipseData }
  allRefs: {
    [aoiId: string]: {
      rect: RefObject<Rect>
      tranformer: RefObject<Konva.Transformer>
      ellipse: RefObject<Konva.Ellipse>
    }
  }
  showDrawNotificationRef: React.MutableRefObject<boolean>
}

/**
 * Renders a canvas (via a Konva.Stage instance) and AOIs on that canvas from a list of AOIs. Passes scaling
 * information to children to force them to re-render on resizes. (EditAoiHelper will change 'redrawKey' to trigger
 * the update.)
 *
 * @param redrawKey A key that controls when to re-render child components
 * @param aoiConfigs List of AOIs to render
 * @param onUpdateAoiWithShape Callback for when an AOI box or shape is changed
 * @param onSelectAoi Callback for when an AOI is clicked on
 * @param disableTransform Disable transforming AOIs?
 * @param disableRectangleRotation Do we disable rotation for rectangles?
 * @param onDeselectAois Callback for when we deselect all AOIs (by clicking on the stage outside any AOI)
 * @returns
 */
const AOIsContainer = ({
  redrawKey,
  aoiConfigs,
  onUpdateAoiWithShape,
  onSelectAoi,
  disableTransform,
  disableRectangleRotation = true,
  showSizeBoxes = false,
  minSizeBoxHeight,
  minSizeBoxWidth,
  maxSizeBoxWidth,
  maxSizeBoxHeight,
  onDeselectAois,
  activeSizeBox,
  onScaleChange,
  bounds,
  setBounds,
  setRectangleData,
  allRectanglesData,
  handleTransform,
  handleTransformEnd,
  toExportAoi,
  setEllipseData,
  allRefs,
  allEllipseData,
  showDrawNotificationRef,
}: Props) => {
  const stageRef = useRef<Konva.Stage>(null)
  const [scaling, setScaling] = useState<number>(1)

  useEffect(() => {
    onScaleChange?.(scaling)
  }, [onScaleChange, scaling])

  useEffect(() => {
    // We run some checks to make sure the component won't break if something fails to load
    if (!stageRef.current) return

    const stage = stageRef.current

    const imageContainer = stage.container().parentNode
    if (!imageContainer) return

    const children = [...imageContainer.children]
    const image = children.find(element => element.nodeName.toLowerCase() === 'img') as HTMLImageElement | undefined
    if (!image) return

    const newBounds = { maxWidth: image.naturalWidth, maxHeight: image.naturalHeight }

    const scaling = image.clientWidth / image.naturalWidth
    if (isNaN(scaling)) return

    setBounds(newBounds)
    stage.size({ width: newBounds.maxWidth * scaling, height: newBounds.maxHeight * scaling })

    // Finally we set the scaling
    setScaling(scaling)
  }, [redrawKey, setBounds])

  const deselectAois = useCallback(
    (stage: Konva.Stage) => {
      if (stage === stageRef.current && onDeselectAois) onDeselectAois()
    },
    [onDeselectAois],
  )

  return (
    <Stage
      className={Styles.stage}
      ref={stageRef}
      width={bounds.maxWidth * scaling}
      height={bounds.maxHeight * scaling}
    >
      {showSizeBoxes && (
        <Layer>
          <MaskingRectangle bounds={bounds} scaling={scaling} />
        </Layer>
      )}

      {aoiConfigs.map(aoiConfig =>
        editableShapeFromAoiConfig(
          aoiConfig,
          scaling,
          bounds,
          !!disableTransform,
          !!disableRectangleRotation,
          onUpdateAoiWithShape,
          onSelectAoi,
          deselectAois,
          showSizeBoxes,
          handleTransform,
          handleTransformEnd,
          toExportAoi,
          setEllipseData,
          allRectanglesData,
          allRefs,
          allEllipseData,
          showDrawNotificationRef,
          minSizeBoxHeight,
          minSizeBoxWidth,
          maxSizeBoxWidth,
          maxSizeBoxHeight,
          activeSizeBox,
          setRectangleData,
        ),
      )}
    </Stage>
  )
}

const editableShapeFromAoiConfig = (
  aoiConfig: AoiConfig,
  scaling: number,
  bounds: MaxBounds,
  disableTransform: boolean,
  disableRectangleRotation: boolean,
  onUpdateAoiWithShape: (shape: BoxWithShape) => any,
  onSelectAoi: (shape: AreaOfInterestConfiguration) => any,
  deselectAois: (stage: Konva.Stage) => void,
  showSizeBoxes: boolean,
  handleTransform: (aoiId: string) => any,
  handleTransformEnd: (aoidId: string) => any,
  toExportAoi: () => BoxWithShape | undefined,
  setEllipseData: (aoiId: string, ellipseData: EllipseData) => any,
  allRectanglesData: { [aoiId: string]: RectangleData },
  allRefs: {
    [aoiId: string]: {
      rect: RefObject<Rect>
      tranformer: RefObject<Konva.Transformer>
      ellipse: RefObject<Konva.Ellipse>
    }
  },
  allEllipseData: { [aoiId: string]: EllipseData },
  showNotificationRef: React.MutableRefObject<boolean>,
  minSizeBoxHeight?: number,
  minSizeBoxWidth?: number,
  maxSizeBoxWidth?: number,
  maxSizeBoxHeight?: number,
  activeSizeBox?: 'min' | 'max',
  setRectangleData?: (aoiId: string, rectangleData: RectangleData) => any,
) => {
  // In order to ensure that children correctly update when scaling changes
  // we give them a key that depends on the scaling
  const key = `${aoiConfig.key}:${scaling}`

  if (aoiConfig.aoi.shape?.type === 'polygon') {
    return (
      <EditablePolygon
        key={key}
        scaling={scaling}
        bounds={bounds}
        aoi={aoiConfig.aoi}
        disableTransform={disableTransform}
        onUpdateAoiWithShape={onUpdateAoiWithShape}
        onSelect={onSelectAoi}
        isInactive={aoiConfig.isInactive}
        deselectAois={deselectAois}
        showNotificationRef={showNotificationRef}
      />
    )
  } else if (aoiConfig.aoi.shape?.type === 'ellipse') {
    const ellipseRef = allRefs[aoiConfig.aoi.id]?.ellipse
    const trRef = allRefs[aoiConfig.aoi.id]?.tranformer
    const ellipseData = allEllipseData[aoiConfig.aoi.id]
    if (!trRef || !ellipseRef) return
    return (
      <EditableEllipse
        key={key}
        scaling={scaling}
        bounds={bounds}
        aoi={aoiConfig.aoi}
        disableTransform={disableTransform}
        onUpdateAoiWithShape={onUpdateAoiWithShape}
        onSelect={onSelectAoi}
        isInactive={aoiConfig.isInactive}
        deselectAois={deselectAois}
        ellipseData={ellipseData}
        ellipseRef={ellipseRef}
        setEllipseData={setEllipseData}
        handleTransform={handleTransform}
        handleTransformEnd={handleTransformEnd}
        toExportAoi={toExportAoi}
        trRef={trRef}
      />
    )
  } else {
    // This branch should be executed if
    //  1)     aoiConfig.aoi.shape.type === 'rectangle', or if
    //  2)     aoiConfig.aoi.shape === undefined, or if
    //  3)     aoiConfig.aoi.shape.type is something other than 'rectangle', 'ellipse', or 'polygon'
    const rectRef = allRefs[aoiConfig.aoi.id]?.rect
    const trRef = allRefs[aoiConfig.aoi.id]?.tranformer
    if (!rectRef || !trRef || !handleTransform || !handleTransformEnd || !toExportAoi) return null
    const rectangleData = allRectanglesData[aoiConfig.aoi.id]
    return (
      <EditableRectangle
        key={key}
        scaling={scaling}
        bounds={bounds}
        aoi={aoiConfig.aoi}
        disableTransform={disableTransform || !!activeSizeBox}
        disableRotation={disableRectangleRotation}
        onUpdateAoiWithShape={onUpdateAoiWithShape}
        onSelect={onSelectAoi}
        isInactive={aoiConfig.isInactive}
        deselectAois={deselectAois}
        showSizeBoxes={showSizeBoxes}
        minSizeBoxWidth={minSizeBoxWidth}
        minSizeBoxHeight={minSizeBoxHeight}
        maxSizeBoxWidth={maxSizeBoxWidth}
        maxSizeBoxHeight={maxSizeBoxHeight}
        activeSizeBox={activeSizeBox}
        setRectangleData={setRectangleData}
        rectangleData={rectangleData}
        rectRef={rectRef}
        trRef={trRef}
        handleTransform={handleTransform}
        handleTransformEnd={handleTransformEnd}
        toExportAoi={toExportAoi}
      />
    )
  }
}

export default AOIsContainer
