import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { groupBy, uniqBy } from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ResponsiveContainer } from 'recharts'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import { PrismElementaryCube } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import SerialList from 'components/SerialList/SerialList'
import { OfflineTag } from 'components/Tag/Tag'
import Timer from 'components/Timer/Timer'
import BarcodeToolSettingsModal from 'components/ToolSettingsModals/BarcodeToolSettingsModal/BarcodeToolSettingsModal'
import GradedAnomalyResultModal from 'components/ToolSettingsModals/GradedAnomalyResultModal/GradedAnomalyResultModal'
import MatchToolSettingsModal from 'components/ToolSettingsModals/MatchToolSettingsModal/MatchToolSettingsModal'
import OcrToolSettingsModal from 'components/ToolSettingsModals/OcrToolSettingsModal/OcrToolSettingsModal'
import {
  useAllToolLabels,
  useConnectionStatus,
  useData,
  useInspectionAndRecipeDefinition,
  useInspectionDurationRef,
  usePrevious,
  useQueryParams,
  useScrollTo,
  useStationStatus,
  useTypedSelector,
  useTypedStore,
} from 'hooks'
import { MemoTrainingReport } from 'pages/RoutineOverview/Train/TrainingReport/TrainingReport'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import {
  AoiUpdate,
  AoiWithToolResultCount,
  AreaOfInterest,
  AreaOfInterestExpanded,
  AreaOfInterestExpandedWithImage,
  GARToolThreshold,
  Inspection,
  MeasureToolSize,
  PreprocessingOptions,
  RecipeExpanded,
  RecipeRoutineExpanded,
  RoutineWithAois,
  Station,
  Threshold,
  TimeSeriesResult,
  Tool,
  ToolResult,
  ToolThreshold,
  TrainingMetrics,
  UpdateLiveToolSettingsCommandArgs,
} from 'types'
import {
  appendDataToQueryString,
  calculateMetricsCompactionParams,
  calculateOpacityByIndex,
  calculatePercentage,
  CombinedOutcomeData,
  combineOutcomeCounts,
  commaSeparatedNumbers,
  convertPreprocessingOptionsToAlt,
  getData,
  getDisplaySeverity,
  getLabelName,
  getLabelsFromPredictionLabels,
  getLastSelectedAoiId,
  getterAddOrUpdateResultsAndSort,
  getThresholdFromTool,
  getToolAoisFromRoutine,
  isThresholdFromGARTool,
  isToolMuted,
  isToolUntrained,
  matchRole,
  renderToolName,
  renderToolNameWithAoi,
  sortByNewestFirst,
  updateLiveSettings,
} from 'utils'
import { ALL_TOOL_LABELS_GETTER_KEY, MAX_RECENT_RESULTS, TRAINABLE_TOOL_SPECIFICATION_NAMES } from 'utils/constants'
import { UNTRAINED_LABEL } from 'utils/labels'

import Styles from './StationDetailTools.module.scss'
import StationDetailToolsLatestToolResults from './StationDetailToolsLatestToolResults'
import StationDetailToolsYieldGraph from './StationDetailToolsYieldGraph'
import { ToolListItem } from './ToolListItem'
import { ToolStationBlankState } from './ToolStationBlankState'

const RESPONSIVE_CHART_HEIGHT = 68

interface Props {
  inspection: Inspection | undefined
  routines: RoutineWithAois[] | undefined
  station: Station | undefined
  selectedRobotId: string | null
  isHistoricBatch: boolean
  inspectionId: string | null | undefined
  fetchedRecipe: RecipeExpanded | undefined
}

export interface FullScreenReportImage {
  imageUrl: string | undefined
  aoi: AreaOfInterest | null | undefined
  threshold: number | undefined
}

export const CONTENT_BEFORE_LATEST_CLASSIFICATIONS_HEIGHT = 360

// TODO: Document this component
export const StationDetailTools = ({
  inspection,
  routines,
  station,
  selectedRobotId,
  isHistoricBatch,
  inspectionId,
  fetchedRecipe,
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [params] = useQueryParams<
    'timeFilterStart' | 'timeFilterEnd' | 'aoiId' | 'detail_item_id' | 'detail_tool_result_id'
  >()
  const store = useTypedStore()
  const me = useData(getterKeys.me())
  const paginationContainerRef = useRef<HTMLDivElement>(null)
  const robots = station?.robots || []

  const { recipeDefinition } = useInspectionAndRecipeDefinition(robots?.map(rbt => rbt.id))

  const connectionStatus = useConnectionStatus()
  const stationStatus = useStationStatus(station)

  const [editCriteriaModalType, setEditCriteriaModalType] = useState<
    'pass_criteria' | 'expected_label' | 'expected_pass_threshold' | 'minor_anomaly_result'
  >()
  const [showNewUnitsButton, setShowNewUnitsButton] = useState(false)
  const [activePredictionLabelIds, setActivePredictionLabelIds] = useState<string[] | undefined>(undefined)

  const isLiveModeRef = useRef(true)
  const shouldResetLiveModeRef = useRef(true)
  const liveModeWaitingForToolResult = useRef(false)
  const [scrollTo, isAutoScrollingRef] = useScrollTo(paginationContainerRef)

  const { timeFilterStart, timeFilterEnd, aoiId } = params
  // When qs string times change, change time filters
  const timeFilters: TimeFilters | undefined = useMemo(() => {
    if (!timeFilterStart || !timeFilterEnd) return undefined

    return { start: +timeFilterStart, end: +timeFilterEnd }
  }, [timeFilterEnd, timeFilterStart])

  const setTimeFilters = useCallback(
    (newFilter: TimeFilters | undefined) => {
      appendDataToQueryString(history, {
        timeFilterStart: newFilter?.start,
        timeFilterEnd: newFilter?.end,
      })
    },
    [history],
  )
  const prevInspectionId = usePrevious(inspectionId)

  const uniqueRoutines = useMemo(() => uniqBy(routines, r => r.id), [routines])
  const routineAois = useMemo(
    () =>
      uniqueRoutines?.flatMap(routine => {
        return routine.aois.map(aoi => {
          return { ...aoi, image: routine.image }
        })
      }),
    [uniqueRoutines],
  )

  const { aois, views } = useMemo(() => {
    if (isHistoricBatch) return { aois: routineAois, views: [] }
    const allAois: AreaOfInterestExpandedWithImage[] = []

    recipeDefinition?.recipe_routines.forEach(view => {
      const fetchedView = fetchedRecipe?.recipe_routines.find(fetchedRecipeView => fetchedRecipeView.id === view.id)

      allAois.push(
        ...view.routine.aois.map(aoi => ({ ...aoi, image: fetchedView?.routine.image || view.routine.image })),
      )
    })

    return { aois: allAois, views: recipeDefinition?.recipe_routines || [] }
  }, [isHistoricBatch, routineAois, recipeDefinition?.recipe_routines, fetchedRecipe])

  const locationHistory = useTypedSelector(state => state.locationHistory)

  const getterKey = inspection ? getterKeys.aoiAndLabelMetrics(inspection.id) : undefined
  const aoiAndLabelMetricsData = useData(getterKey)
  const aoiData = aoiAndLabelMetricsData?.aoiMetrics
  const labelMetrics = aoiAndLabelMetricsData?.labelMetrics

  const aoisWithToolResultCounts = useAoisWithToolResultCounts({ aois, aoiMetrics: aoiData })
  const sortedAoisWithToolResultCounts = useMemo(() => {
    return getSortedAoisWithToolResults(aoisWithToolResultCounts)
  }, [aoisWithToolResultCounts])

  const setSelectedAoiId = useCallback(
    (newAoiId: string | undefined) => {
      appendDataToQueryString(history, { aoiId: newAoiId, lastSelectedId: undefined })
    },
    [history],
  )

  // This effect is in charge of setting the first aoi id in the query string
  // if there is not already a selected aoi id
  useEffect(() => {
    if (aoiId || !sortedAoisWithToolResultCounts.length) return

    if (!isHistoricBatch) {
      const lastSelectedAoiId = getLastSelectedAoiId(locationHistory)
      // The lastSelectedAoiId could be pointing to an aoi from the previous inspection
      if (lastSelectedAoiId && sortedAoisWithToolResultCounts.find(aoi => aoi.aoi.id === lastSelectedAoiId)) {
        return setSelectedAoiId(lastSelectedAoiId)
      }
    }

    if (aoiData || connectionStatus === 'offline') setSelectedAoiId(sortedAoisWithToolResultCounts[0]?.aoi.id)
  }, [
    aoiData,
    aoiId,
    isHistoricBatch,
    locationHistory,
    setSelectedAoiId,
    sortedAoisWithToolResultCounts,
    connectionStatus,
  ])

  const currentView: RecipeRoutineExpanded | undefined = useMemo(() => {
    const foundView = views.find(view => {
      return !!view.routine.aois.find(aoi => aoi.id === aoiId)
    })

    if (foundView && recipeDefinition) {
      return { ...foundView, recipe: recipeDefinition }
    }
  }, [recipeDefinition, aoiId, views])

  const { inspectionDurationMsRef } = useInspectionDurationRef(inspection)
  const { metricsCompaction, agg_s } = calculateMetricsCompactionParams(inspectionDurationMsRef.current, {
    isHistoricBatch,
  })

  // Don't poll until we have inspection, hence duration, hence we can compute `metricsCompaction`
  const aoiMetricsKey = inspection ? getterKeys.rtsMetrics('aois', inspection.id, metricsCompaction) : undefined

  const lastPolledMs = useTypedSelector(state => {
    if (aoiMetricsKey) {
      return state.getter?.[aoiMetricsKey]?.dataMs
    }
  })

  const selectedAoi = useMemo(
    () => aoisWithToolResultCounts.find(record => record.aoi.id === aoiId),
    [aoisWithToolResultCounts, aoiId],
  )

  const { results: recentToolResults } =
    useData(inspectionId ? getterKeys.inspectionRecentToolResults(inspectionId) : undefined) || {}

  const filterRecentToolResults = useCallback(
    (toolResult: ToolResult) => {
      if (toolResult.aoi?.id !== selectedAoi?.aoi.id) return false
      if (activePredictionLabelIds && activePredictionLabelIds.length > 0) {
        return toolResult.prediction_labels.some(labelId => activePredictionLabelIds.includes(labelId))
      }
      return true
    },
    [activePredictionLabelIds, selectedAoi?.aoi.id],
  )

  // These are the toolResults from WS that fit all the filters
  const filteredRecentToolResults = useMemo(
    () => recentToolResults?.filter(filterRecentToolResults) || undefined,
    [recentToolResults, filterRecentToolResults],
  )

  /**
   * Enables live mode:
   * - Hides new results button
   * - Clears time filters
   * - Scroll to start of results, if not previously in live mode
   */
  const enableLiveMode = useCallback(() => {
    // Don't enable live mode if is a historic batch
    if (isHistoricBatch) return

    if (!isLiveModeRef.current) {
      scrollTo({ top: CONTENT_BEFORE_LATEST_CLASSIFICATIONS_HEIGHT, behavior: 'smooth' })
    }

    liveModeWaitingForToolResult.current = false
    isLiveModeRef.current = true
    setShowNewUnitsButton(false)
    setTimeFilters(undefined)
    // Get recent tool results directly from store whenever this callback is executed to avoid rerenders.
    const currentRecentToolResults = getData(
      store.getState().getter,
      inspectionId ? getterKeys.inspectionRecentToolResults(inspectionId) : undefined,
    )?.results.filter(filterRecentToolResults)

    if (selectedAoi && currentRecentToolResults) {
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.stationDetailToolsLatestToolResults(selectedAoi?.aoi.id),
          updater: prevRes =>
            getterAddOrUpdateResultsAndSort(prevRes, {
              // Only add ws results that are not already in the list
              results: currentRecentToolResults,
              sliceEndIdx: MAX_RECENT_RESULTS,
              sort: sortByNewestFirst,
            }),
        }),
      )
    }
  }, [dispatch, filterRecentToolResults, inspectionId, scrollTo, selectedAoi, setTimeFilters, store, isHistoricBatch])

  const disableLiveMode = useCallback(() => {
    shouldResetLiveModeRef.current = isLiveModeRef.current
    isLiveModeRef.current = false
    liveModeWaitingForToolResult.current = true
  }, [])

  useEffect(() => {
    // Restore time filters when first loading inspection
    if (inspectionId && !prevInspectionId) {
      setTimeFilters(timeFilterStart && timeFilterEnd ? { start: +timeFilterStart, end: +timeFilterEnd } : undefined)
      setSelectedAoiId(aoiId)
    }
    if (!inspectionId && prevInspectionId) {
      appendDataToQueryString(history, {})
      setTimeFilters(undefined)
      setSelectedAoiId(undefined)
    }

    if (showNewUnitsButton) setShowNewUnitsButton(false)
  }, [inspectionId]) // eslint-disable-line

  const tool = selectedAoi?.tool
  const toolIsUntrained =
    tool && tool.state !== 'successful' && TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(tool.specification_name)

  const { allToolLabels } = useAllToolLabels()

  // When the selected aoi changes, we need to go back to live mode
  useEffect(() => {
    if (!timeFilters) enableLiveMode()
    setSelectedAoiId(aoiId)
    // eslint-disable-next-line
  }, [aoiId])

  const groupedLabelMetricsByLabelId = useMemo(() => {
    if (!tool || !labelMetrics) return
    const groupedByAoiId = groupBy(labelMetrics, data => data.labels.aoi_id)

    // We only want the label metrics corresponding to the current selected tool
    const countsByLabelId: { [labelId: string]: number } = {}

    if (!selectedAoi?.aoi.id) return countsByLabelId

    const currentToolMetrics = groupedByAoiId[selectedAoi?.aoi.id]
    const groupedByLabelId = groupBy(currentToolMetrics, data => data.labels.tool_label_id)
    for (const labelId of Object.keys(groupedByLabelId)) {
      const combinedOutcomeCounts = combineOutcomeCounts(groupedByLabelId[labelId]!)

      countsByLabelId[labelId] = combinedOutcomeCounts.reduce((prev, current) => prev + current.count, 0)
    }
    return countsByLabelId
  }, [labelMetrics, selectedAoi?.aoi.id, tool])

  const groupedLabelMetricsByLabelIdKey = groupedLabelMetricsByLabelId
    ? Object.keys(groupedLabelMetricsByLabelId).sort().join()
    : undefined

  // Figures out which label filters to render for the tool
  const toolLabelsToShow = useMemo(() => {
    if (!allToolLabels || !tool) return undefined

    if (toolIsUntrained) {
      const untrainedLabel = allToolLabels.find(
        label => label.value === UNTRAINED_LABEL.value && label.severity === UNTRAINED_LABEL.severity,
      )
      return untrainedLabel ? [untrainedLabel] : []
    }

    if (tool.metadata.prediction_labels) {
      const predictionLabels = getLabelsFromPredictionLabels(allToolLabels, tool.metadata.prediction_labels)

      if (groupedLabelMetricsByLabelId) {
        // check if there's an id here that's not a part of `metadata.prediction_labels`
        const extraLabelIds = Object.keys(groupedLabelMetricsByLabelId).filter(
          labelId => !predictionLabels.find(label => label.id === labelId),
        )

        extraLabelIds.forEach(labelId => {
          const label = allToolLabels.find(label => label.id === labelId)
          if (label) predictionLabels.push(label)
        })
      }
      return predictionLabels
    }

    return []
    // eslint-disable-next-line
  }, [tool, allToolLabels, groupedLabelMetricsByLabelIdKey, toolIsUntrained])

  // Checks if RCA pipeline generated a new label that we haven't fetched yet.
  useEffect(() => {
    if (!allToolLabels || !groupedLabelMetricsByLabelId) return

    const shouldRefetchLabels = Object.keys(groupedLabelMetricsByLabelId).some(
      labelId => !allToolLabels.find(label => label.id === labelId),
    )

    // If we find metrics for a label that hasn't been fetched, refetch custom labels
    if (shouldRefetchLabels) {
      query(getterKeys.toolLabels(ALL_TOOL_LABELS_GETTER_KEY), () => service.getToolLabels({ kind__in: 'custom' }), {
        dispatch,
      })
    }
  }, [groupedLabelMetricsByLabelId, allToolLabels, dispatch])

  useEffect(() => {
    // If this is undefined it means labels are not ready, so we don't do anything until they are.
    if (toolLabelsToShow === undefined) return

    const criticalAndMinorLabels = toolLabelsToShow.filter(
      label => label.severity === 'critical' || label.severity === 'minor',
    )

    if (criticalAndMinorLabels.length) {
      setActivePredictionLabelIds(criticalAndMinorLabels.map(label => label.id))
    } else {
      // This shows all of the labels on the match tool, or the untrained label when the tool is untrained
      setActivePredictionLabelIds(toolLabelsToShow.map(label => label.id))
    }
    // eslint-disable-next-line
  }, [toolLabelsToShow, aoiId])

  // We use `let` to later cast it to the correct type
  let toolThreshold = tool && getThresholdFromTool(tool)

  const routine = routines?.find(routine => routine.aois.find(aoi => aoi.id === selectedAoi?.aoi.id))

  const robotIdsRunning = recipeDefinition?.recipe_routines.map(recipeRoutine => recipeRoutine.robot_id)

  const isSelectedAoiUntrained = isToolUntrained(selectedAoi?.tool)

  const extraSettings = []

  if (tool?.specification_name === 'match-classifier') {
    extraSettings.push({
      value: 'expected_label',
      title: 'Edit Expected Label',
      disabled: !matchRole(me, 'inspector'),
      'data-testid': 'station-detail-tools-edit-expected-label',
    } as const)

    if (matchRole(me, 'manager'))
      extraSettings.push({
        value: 'expected_pass_threshold',
        title: 'Edit Threshold',
        disabled: isSelectedAoiUntrained,
        'data-test-attribute': isSelectedAoiUntrained ? 'disabled' : 'enabled',
        'data-testid': 'station-detail-tools-match-edit-threshold',
      } as const)
  }

  if (tool?.specification_name === 'detect-barcode') {
    extraSettings.push({
      value: 'expected_label',
      title: 'Edit Expected Barcode',
      disabled: !matchRole(me, 'inspector'),
    } as const)
  }

  if (tool?.specification_name === 'ocr') {
    extraSettings.push({
      value: 'expected_label',
      title: 'Edit Expected Text',
      disabled: !matchRole(me, 'inspector'),
      'data-testid': 'station-detail-tools-edit-expected-text',
    } as const)
  }

  if (tool?.specification_name === 'graded-anomaly' && matchRole(me, 'manager')) {
    extraSettings.push(
      {
        value: 'minor_anomaly_result',
        title: 'Edit Outcome of Minor Defects',
        disabled: isSelectedAoiUntrained,
      } as const,
      {
        value: 'expected_pass_threshold',
        title: 'Edit Threshold',
        disabled: isSelectedAoiUntrained,
      } as const,
    )
  }

  if (
    (tool?.specification_name === 'deep-svdd' || tool?.specification_name === 'classifier') &&
    matchRole(me, 'manager')
  ) {
    extraSettings.push({
      value: 'pass_criteria',
      title: 'Edit Threshold',
      disabled: isSelectedAoiUntrained,
      'data-test-attribute': isSelectedAoiUntrained ? 'disabled' : 'enabled',
      'data-testid': 'station-detail-tools-edit-threshold',
    } as const)
  }

  const handleToolListItem = useCallback(
    (newAoiId: string) => {
      if (aoiId === newAoiId) return
      setSelectedAoiId(newAoiId)
      setActivePredictionLabelIds(undefined)
      if (paginationContainerRef.current) {
        paginationContainerRef.current.scrollTo({ top: 0 })
      }
    },
    [aoiId, setSelectedAoiId],
  )

  const renderToolCards = useCallback(() => {
    return sortedAoisWithToolResultCounts.map(aoisWithToolResultCount => {
      const { count, fail, aoi, tool } = aoisWithToolResultCount
      let failRate: string
      const isUntrained = isToolUntrained(aoisWithToolResultCount?.tool)

      if (isUntrained) {
        failRate = 'Untrained'
      } else {
        const failPercentage = calculatePercentage(fail, count).toFixed(1)
        failRate = `${failPercentage}%`
      }

      return (
        <ToolListItem
          data-test="station-detail-tools-list"
          data-testid={`station-detail-tools-list-${renderToolName(tool)}`}
          data-test-attribute={isUntrained ? 'untrained' : 'trained'}
          type="transparent"
          img={
            <ImageCloseUp
              loaderType="skeleton"
              src={aoi.image}
              region={aoi}
              // We want to remount the image component when we go back online
              key={aoi.image}
              maskingRectangleFill={aoi.id === aoiId ? 'rgba(31, 36, 45, 1)' : '#111111'}
            />
          }
          toolName={`${renderToolNameWithAoi(tool, aoi)}`}
          failRate={failRate}
          onClick={() => handleToolListItem(aoi.id)}
          key={aoi.id}
          active={aoi.id === aoiId}
          muted={isToolMuted(tool)}
          untrained={isUntrained}
        />
      )
    })
  }, [sortedAoisWithToolResultCounts, handleToolListItem, aoiId])

  const getToolExpectedClasses = (): string[] => {
    return (
      tool?.inference_user_args.expected_classes?.map((labelId: string) => {
        const label = allToolLabels?.find(lbl => lbl.id === labelId)
        if (!label) return '...'
        return getLabelName(label)
      }) || []
    )
  }

  const renderPassCriteria = () => {
    const specificationName = tool?.specification_name
    const isUntrained = isToolUntrained(tool)

    if (isUntrained) {
      return (
        <p className={Styles.passCriteriaDescription}>
          Will predict <PrismResultButton severity="unknown" value="unknown" size="small" type="noFill" /> until model
          is trained and deployed.
        </p>
      )
    }

    if (specificationName === 'deep-svdd' || specificationName === 'classifier') {
      toolThreshold = toolThreshold as ToolThreshold
      return (
        <p className={Styles.passCriteriaDescription}>
          Will <PrismResultButton severity="pass" value="pass" size="small" type="noFill" />
          if score exceeds<span>{toolThreshold.userFacingThreshold}.</span>
        </p>
      )
    }

    if (specificationName === 'graded-anomaly') {
      toolThreshold = toolThreshold as GARToolThreshold
      const minorResult = tool?.inference_user_args.amber_is_pass ? 'pass' : 'fail'
      return (
        <p className={Styles.passCriteriaDescription}>
          Will <PrismResultButton severity={minorResult} value={minorResult} size="small" type="noFill" />
          if defect is <PrismResultButton severity="minor" value="minor." size="small" type="noFill" /> Threshold for
          <PrismResultButton severity="minor" value="minor" size="small" type="noFill" /> is within
          <span>{toolThreshold.userFacingLowerThreshold}</span>
          and<span>{toolThreshold.userFacingUpperThreshold}.</span>Above
          <span>{toolThreshold?.userFacingUpperThreshold}</span>
          is
          <PrismResultButton severity="good" value="good." size="small" type="noFill" />
        </p>
      )
    }

    if (specificationName === 'match-classifier') {
      toolThreshold = toolThreshold as ToolThreshold
      const expectedClasses = getToolExpectedClasses()

      if (expectedClasses.length === 0) {
        return (
          <p className={Styles.passCriteriaDescription}>
            Will be <PrismResultButton severity="unknown" value="unknown" size="small" type="noFill" />
            because there is no expected label.
          </p>
        )
      }
      return (
        <p className={Styles.passCriteriaDescription}>
          Will <PrismResultButton severity="pass" value="pass" size="small" type="noFill" />
          if prediction is {<SerialList joint="or">{expectedClasses}</SerialList>}, and score exceeds
          <span>{toolThreshold?.userFacingThreshold}.</span>
        </p>
      )
    }

    if (specificationName === 'alignment') {
      return (
        <p className={Styles.passCriteriaDescription}>
          Will <PrismResultButton severity="pass" value="pass" size="small" type="noFill" />
          if image aligns correctly.
        </p>
      )
    }

    if (specificationName === 'detect-barcode') {
      return (
        <p className={Styles.passCriteriaDescription}>
          Will <PrismResultButton severity="pass" value="pass" size="small" type="noFill" />
          if barcode matches<span>"{tool?.inference_user_args.regex || ''}".</span>
        </p>
      )
    }

    if (specificationName === 'color-check') {
      const colorArgs = convertPreprocessingOptionsToAlt(tool?.inference_args as PreprocessingOptions)
      return (
        <p className={Styles.passCriteriaDescription}>
          Will <PrismResultButton severity="pass" value="pass" size="small" type="noFill" />{' '}
          <>
            if between<span>{colorArgs?.pixel_count_min ? commaSeparatedNumbers(colorArgs.pixel_count_min) : 0}</span>
            and<span>{colorArgs?.pixel_count_max ? commaSeparatedNumbers(colorArgs.pixel_count_max) : 0}</span>pixels
            match target color, with hue of
            <span>
              {colorArgs?.hsv_min_h}-{colorArgs?.hsv_max_h},
            </span>
            saturation of
            <span>
              {colorArgs?.hsv_min_s}-{colorArgs?.hsv_max_s},
            </span>
            and value of
            <span>
              {colorArgs?.hsv_min_v}-{colorArgs?.hsv_max_v}.
            </span>
          </>
        </p>
      )
    }

    if (specificationName === 'measurement') {
      const { target_max_height, target_max_width, target_min_height, target_min_width } =
        (tool?.inference_args as MeasureToolSize | undefined) || {}
      const { width: aoiWidth = 1, height: aoiHeight = 1 } = selectedAoi?.aoi || {}

      const minWidth = target_min_width ? calculatePercentage(target_min_width, aoiWidth).toFixed(1) : 30
      const maxWidth = target_max_width ? calculatePercentage(target_max_width, aoiWidth).toFixed(1) : 30

      const minHeight = target_min_height ? calculatePercentage(target_min_height, aoiHeight).toFixed(1) : 75
      const maxHeight = target_max_height ? calculatePercentage(target_max_height, aoiHeight).toFixed(1) : 75
      return (
        <p className={Styles.passCriteriaDescription}>
          Will <PrismResultButton severity="pass" value="pass" size="small" type="noFill" />
          if dimensions are within
          <span>
            {minWidth}-{maxWidth}%
          </span>
          width of search area and
          <span>
            {minHeight}-{maxHeight}%
          </span>
          height of search area.
        </p>
      )
    }

    if (specificationName === 'ocr') {
      return (
        <p className={Styles.passCriteriaDescription}>
          Will <PrismResultButton severity="pass" value="pass" size="small" type="noFill" />
          if text matches<span>"{tool?.inference_user_args.regex || ''}".</span>
        </p>
      )
    }
  }

  const aoiSeries = useMemo(() => {
    return aoiData && combineOutcomeCounts(aoiData.filter(metrics => metrics.labels.aoi_id === aoiId))
  }, [aoiData, aoiId])

  const isSelectedToolMuted = isToolMuted(tool)
  const dataTestAction = isSelectedToolMuted ? 'unmute' : 'mute'

  const handleDropDownClick = async (
    value: 'mute' | 'pass_criteria' | 'expected_label' | 'expected_pass_threshold' | 'minor_anomaly_result',
  ) => {
    if (!robotIdsRunning) return
    if (value === 'mute') {
      if (!inspectionId || !tool || !routine || !currentView) return
      modal.confirm({
        id: 'mute-unmute-inspection-tool',
        header: 'Are you sure?',
        content: isSelectedToolMuted
          ? 'When unmuted, this tool will no longer mark Fails as Pass.'
          : 'When muted, this tool will mark Fails as Pass. This can be useful when testing or training a tool.',
        okText: isSelectedToolMuted ? 'Unmute' : 'Mute',
        'data-testid': `station-detail-tools-${dataTestAction}-modal`,
        onOk: async close => {
          const isMuted = !isSelectedToolMuted
          const currentToolAois = getToolAoisFromRoutine(routine, tool.id)
          const mutedAoisUpdates: AoiUpdate[] = currentToolAois.map(aoi => ({
            id: aoi.id,
            inference_user_args: {
              ...tool.inference_user_args,
              is_muted: isMuted,
            },
          }))
          const updateToolPromise = service.patchProtectedTool(tool.id, { aois: mutedAoisUpdates })

          let updateResType = ''
          const commandArgs: UpdateLiveToolSettingsCommandArgs = {
            tool: tool.id,
            tool_settings: [{ setting: 'is_muted', value: isMuted }],
          }

          if (connectionStatus === 'online') {
            const updateToolRes = await updateToolPromise

            updateResType = updateToolRes.type
            if (updateResType === 'success') commandArgs.update_db = false
            if (updateResType === 'error') {
              error({ title: 'There was an error connecting to the server, the update might not be applied' })
            }
          }

          const liveSettingsUpdated = await updateLiveSettings([currentView.robot_id], {
            commandName: 'update_tool_settings',
            commandArgs,
          })

          if (!liveSettingsUpdated) {
            return error({
              title: 'An error occurred, please try again',
              'data-testid': `station-detail-tools-${dataTestAction}-fail`,
            })
          }

          success({
            title: isSelectedToolMuted ? 'Tool is now unmuted' : 'Tool is now muted',
            'data-testid': `station-detail-tools-${dataTestAction}-success`,
          })

          query(getterKeys.inspectionRoutines(inspectionId), () => service.getInspectionRoutines(inspectionId), {
            dispatch,
          })

          close()
        },
      })
      return
    }

    disableLiveMode()

    setEditCriteriaModalType(value)
  }

  const handleToolSettingsModalsClose = useCallback(() => {
    if (shouldResetLiveModeRef.current) {
      enableLiveMode()
    }
    setEditCriteriaModalType(undefined)
  }, [enableLiveMode])

  const handleSetTimeFilters = useCallback(
    (filters: TimeFilters | undefined) => {
      if (filters) {
        disableLiveMode()
      } else {
        enableLiveMode()
      }
      setTimeFilters(filters)
    },
    [disableLiveMode, enableLiveMode, setTimeFilters],
  )

  if (!selectedAoi) {
    return <ToolStationBlankState />
  }

  const renderOutcomes = () => {
    let header = <>Outcomes</>

    let body = (
      <ResponsiveContainer width="99%" height={RESPONSIVE_CHART_HEIGHT}>
        <StationDetailToolsYieldGraph
          itemSeries={aoiSeries}
          aggS={agg_s}
          setTimeFilters={handleSetTimeFilters}
          timeFilters={timeFilters}
          yieldThreshold={inspection?.user_settings?.production_targets?.target_yield || 0}
        />
      </ResponsiveContainer>
    )

    let filters: React.ReactNode = (
      <div className={Styles.graphHeaderActions}>
        {timeFilters && (
          <Button
            type="link"
            onClick={() => {
              handleSetTimeFilters(undefined)
            }}
            className={Styles.clearFilterButton}
          >
            Clear Filter
          </Button>
        )}

        <div className={Styles.prismOutcomesContainer}>
          <PrismOutcome variant="iconColor" icon="fail" label={renderPercentage(selectedAoi.fail, selectedAoi.count)} />

          <PrismOutcome variant="iconColor" icon="pass" label={renderPercentage(selectedAoi.pass, selectedAoi.count)} />

          <PrismOutcome
            variant="iconColor"
            icon="unknown"
            label={renderPercentage(selectedAoi.unknown, selectedAoi.count)}
          />

          {isSelectedToolMuted && (
            <div className={Styles.outcomeMutedContainer}>
              <h6 className={Styles.outcomeMutedTitle}>muted</h6>
              <p className={Styles.outcomeMutedValue}>{renderPercentage(selectedAoi.muted, selectedAoi.count)}</p>
            </div>
          )}
        </div>
      </div>
    )

    if (connectionStatus === 'offline') {
      header = (
        <>
          Outcomes
          <OfflineTag className={Styles.offlineTag} />
        </>
      )

      body = <div className={Styles.offlineOutcomeBox} style={{ height: RESPONSIVE_CHART_HEIGHT }} />

      filters = null
    }

    const toRender = [
      <>
        <div className={Styles.graphHeader}>
          <div className={Styles.sectionTitle}>{header}</div>

          {filters}
        </div>

        <div className={Styles.graphBody}>{body}</div>
      </>,
    ]

    return <div className={`${Styles.graphSection} ${Shared.verticalChildrenGap16}`}>{toRender}</div>
  }

  const renderPredictions = () => {
    let header = <>Predictions</>

    let filters: React.ReactNode = (
      <div className={Styles.predictionsContainer}>
        {toolLabelsToShow?.map(toolLabel => {
          const isActive = activePredictionLabelIds?.includes(toolLabel.id)
          const totalToolResultsForLabel = groupedLabelMetricsByLabelId?.[toolLabel.id] || 0
          const totalToolResults = selectedAoi.count

          return (
            <PrismResultButton
              data-testid="station-detail-tools-filter"
              key={toolLabel.id}
              severity={getDisplaySeverity(toolLabel)}
              value={
                <div className={Styles.predictionItem}>
                  <p>{getLabelName(toolLabel)}</p>
                  <small>{renderPercentage(totalToolResultsForLabel, totalToolResults)}</small>
                </div>
              }
              type="ghost"
              onClick={() => {
                if (isActive) {
                  setActivePredictionLabelIds(current => current?.filter(id => id !== toolLabel.id))
                } else {
                  setActivePredictionLabelIds(current => [...(current || []), toolLabel.id])
                }
              }}
              active={isActive}
            />
          )
        })}
      </div>
    )

    if (connectionStatus === 'offline') {
      header = (
        <>
          Predictions
          <OfflineTag />
        </>
      )

      filters = null
    }

    const toRender = [
      <section key="header" className={`${Styles.predictionsSection} ${Shared.verticalChildrenGap8}`}>
        <div className={Styles.sectionTitle}>{header}</div>
        {filters}
      </section>,
    ]

    if (connectionStatus === 'offline' && stationStatus === 'running') {
      toRender.push(<OfflinePredictionCards />)
    } else {
      toRender.push(
        <StationDetailToolsLatestToolResults
          aoi={selectedAoi.aoi}
          key={selectedAoi.aoi.id}
          thresholdSliderIsOpen={
            !!editCriteriaModalType &&
            (tool?.specification_name === 'deep-svdd' || tool?.specification_name === 'classifier') &&
            !!selectedRobotId
          }
          isHistoricBatch={isHistoricBatch}
          inspection={inspection}
          timeFilters={timeFilters}
          outerContainerRef={paginationContainerRef}
          isLiveModeRef={isLiveModeRef}
          setShowNewUnitsButton={setShowNewUnitsButton}
          liveModeWaitingForToolResult={liveModeWaitingForToolResult}
          scrollTo={scrollTo}
          isAutoScrollingRef={isAutoScrollingRef}
          enableLiveMode={enableLiveMode}
          disableLiveMode={disableLiveMode}
          recentToolResults={recentToolResults}
          filteredRecentToolResults={filteredRecentToolResults}
          activePredictionLabelIds={activePredictionLabelIds}
          shouldResetLiveMode={shouldResetLiveModeRef}
        />,
      )
    }

    return toRender
  }

  return (
    <main className={Styles.mainBody}>
      <section className={Styles.toolListWrapper}>
        <div className={`${Styles.toolList} ${connectionStatus === 'recovering' ? Styles.reconnectionCoat : ''}`}>
          {renderToolCards()}
        </div>
      </section>

      <Divider type="vertical" className={Styles.divider} />
      <div className={Styles.toolMainDetails}>
        <div className={Styles.toolHeader}>
          <div className={Styles.toolHeaderTitleContainer}>
            <h1 className={Styles.toolHeaderTitle}>{`${renderToolName(tool)} - ${selectedAoi.aoi.parent?.name}`}</h1>

            <p className={Styles.lastUpdateTitle}>
              Updated{' '}
              <Timer key={lastPolledMs || 0} specialFormat="timePassed" startTime={moment(lastPolledMs).valueOf()} />{' '}
              ago
            </p>
          </div>

          <div className={Styles.toolHeaderButtons}>
            <OptionMenu
              className={Styles.dropdownContainer}
              buttonDisabled={isHistoricBatch}
              openWithClick
              options={[
                {
                  value: 'mute',
                  title: isSelectedToolMuted ? 'Unmute Tool' : 'Mute Tool',
                  disabled: !matchRole(me, 'inspector'),
                  'data-testid': !matchRole(me, 'inspector')
                    ? `station-detail-tools-${dataTestAction}-disabled`
                    : `station-detail-tools-${dataTestAction}`,
                },
                ...extraSettings,
              ]}
              onMenuItemClick={handleDropDownClick}
            >
              <Button
                disabled={isHistoricBatch}
                size="small"
                type="secondary"
                data-testid="station-detail-tools-settings"
              >
                Settings
              </Button>
            </OptionMenu>
            {/* Check that the tool can be trained to allow navigation to labeling */}
            {routine &&
              tool &&
              TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(tool.specification_name) &&
              matchRole(me, 'inspector') && (
                <Button
                  size="small"
                  type="primary"
                  disabled={connectionStatus !== 'online' || !inspection}
                  onClick={() => {
                    if (!inspection) return
                    history.push(
                      paths.labelingScreen(tool.parent_id, 'gallery', {
                        inspection_id: inspection.id,
                        ordering: '-created_at',
                        recipeParentId: routine.parent.recipe_parent.id,
                        routineParentId: routine.parent.id,
                      }),
                    )
                  }}
                  className={Styles.labelButton}
                  data-testid="station-detail-tools-label"
                >
                  Label
                </Button>
              )}
          </div>
          {showNewUnitsButton && (
            <Button onClick={enableLiveMode} className={Styles.newResultsButton} type="secondary" isOnTop>
              Live results
            </Button>
          )}
        </div>
        <div
          className={`${Styles.resultsContainer} ${connectionStatus === 'recovering' ? Styles.reconnectionCoat : ''}`}
          ref={paginationContainerRef}
        >
          <div
            className={`${Styles.toolMainBody}  ${connectionStatus === 'recovering' ? Styles.reconnectionCoat : ''} ${
              Shared.verticalChildrenGap32
            }`}
          >
            <section className={`${Styles.passCriteriaSection} ${Shared.verticalChildrenGap8}`}>
              <h3 className={Styles.sectionTitle}>Pass Criteria</h3>

              {renderPassCriteria()}
            </section>

            {renderOutcomes()}
          </div>

          {renderPredictions()}
        </div>
      </div>

      {!!editCriteriaModalType && tool && routine && selectedRobotId && robotIdsRunning && (
        <EditToolSettingsModals
          tool={tool}
          inspection={inspection}
          typeOfEdit={editCriteriaModalType}
          onClose={handleToolSettingsModalsClose}
          aoi={selectedAoi.aoi}
          robotIdsRunningRoutine={robotIdsRunning}
          inspectionId={inspectionId}
          currentView={currentView}
          currentRoutine={routine}
        />
      )}
    </main>
  )
}
export interface TimeFilters {
  start: number
  end: number
}

interface EditToolSettingsModalsProps {
  tool: Tool
  typeOfEdit: 'pass_criteria' | 'expected_label' | 'expected_pass_threshold' | 'minor_anomaly_result'
  onClose: () => any
  inspection?: Inspection
  aoi?: AreaOfInterestExpanded
  robotIdsRunningRoutine: string[]
  inspectionId: string | null | undefined
  currentView: RecipeRoutineExpanded | undefined
  currentRoutine: RoutineWithAois
}

function EditToolSettingsModals({
  tool,
  typeOfEdit,
  onClose,
  inspection,
  aoi,
  robotIdsRunningRoutine,
  inspectionId,
  currentView,
  currentRoutine,
}: EditToolSettingsModalsProps) {
  const dispatch = useDispatch()

  const connectionStatus = useConnectionStatus()

  const handleClose = useCallback(
    (refetch?: boolean) => {
      if (refetch && inspection?.id) {
        query(getterKeys.inspectionRoutines(inspection.id), () => service.getInspectionRoutines(inspection.id), {
          dispatch,
        })
      }
      onClose()
    },
    [dispatch, inspection?.id, onClose],
  )

  const robotsKey = robotIdsRunningRoutine.sort().join()

  const handleSaveThreshold = useCallback(
    async (threshold: Threshold, trainingMetrics?: TrainingMetrics, aoisUpdates?: AoiUpdate[]) => {
      if (!currentView?.robot_id) return

      const updateToolPromise = () =>
        service.patchProtectedTool(tool.id, {
          metadata: { ...tool.metadata, training_metrics: trainingMetrics },
          aois: aoisUpdates,
        })

      let updateResType = ''
      const additionalCommandArgs: { update_db?: boolean } = {}

      if (connectionStatus === 'online') {
        const res = await updateToolPromise()

        updateResType = res.type
        if (updateResType === 'success') additionalCommandArgs.update_db = false
        if (updateResType === 'error') {
          error({ title: 'There was an error connecting to the server, the update might not be applied' })
        }
      } else {
        // If the connection status isn't online, there might still be an intermittent connection, so updating Django
        // might not fail, but we don't want to wait for that request to respond, and we don't want to show an error
        updateToolPromise()
      }

      const currentToolAoiUpdate = tool.is_shared ? aoisUpdates?.find(aoiUpdate => aoiUpdate.id === aoi?.id) : undefined

      if (isThresholdFromGARTool(threshold)) {
        const liveSettingsUpdated = await updateLiveSettings([currentView.robot_id], {
          commandName: 'update_tool_settings',
          commandArgs: {
            tool: tool.id,
            tool_settings: [
              {
                setting: 'lower_threshold',
                value: currentToolAoiUpdate
                  ? currentToolAoiUpdate.inference_user_args.lower_threshold
                  : threshold.lowerThreshold,
              },
              {
                setting: 'upper_threshold',
                value: currentToolAoiUpdate
                  ? currentToolAoiUpdate.inference_user_args.upper_threshold
                  : threshold.upperThreshold,
              },
            ],

            ...additionalCommandArgs,
          },
        })

        if (!liveSettingsUpdated) return error({ title: 'An error occurred updating the pass criteria' })
      } else {
        const liveSettingsUpdated = await updateLiveSettings([currentView.robot_id], {
          commandName: 'update_tool_settings',
          commandArgs: {
            tool: tool.id,
            tool_settings: [
              {
                setting: 'threshold',
                value: currentToolAoiUpdate ? currentToolAoiUpdate.inference_user_args.threshold : threshold.threshold,
              },
            ],
            ...additionalCommandArgs,
          },
        })

        if (!liveSettingsUpdated) return error({ title: 'An error occurred updating the pass criteria' })
      }

      if (tool.specification_name === 'graded-anomaly') success({ title: 'Threshold updated' })
      else success({ title: 'Threshold updated' })
      handleClose(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tool.inference_user_args,
      tool.is_shared,
      tool.id,
      tool.specification_name,
      tool.metadata,
      connectionStatus,
      dispatch,
      handleClose,
      aoi?.id,
      currentView?.robot_id,
      robotsKey,
    ],
  )

  const aoiIds = useMemo(() => {
    return getToolAoisFromRoutine(currentRoutine, tool.id).map(aoi => aoi.id)
  }, [currentRoutine, tool.id])

  return (
    <>
      {typeOfEdit === 'expected_label' && tool?.specification_name === 'match-classifier' && currentView?.robot_id && (
        <MatchToolSettingsModal
          tool={tool}
          onClose={handleClose}
          robotIdsRunningRoutine={[currentView.robot_id]}
          aoiIds={aoiIds}
        />
      )}

      {typeOfEdit === 'expected_pass_threshold' && tool?.specification_name === 'match-classifier' && (
        <MemoTrainingReport
          onClose={handleClose}
          onCancelThreshold={onClose}
          toolId={tool.id}
          toolWithOverridesContext={tool}
          forceThreshold
          forceTitle="Edit Threshold"
          onSaveThreshold={handleSaveThreshold}
          inspectionId={inspectionId}
          viewInInspection={currentView}
        />
      )}

      {typeOfEdit === 'expected_pass_threshold' && tool?.specification_name === 'graded-anomaly' && (
        <MemoTrainingReport
          onClose={handleClose}
          onCancelThreshold={onClose}
          toolId={tool.id}
          toolWithOverridesContext={tool}
          forceTitle="Edit Threshold"
          onSaveThreshold={handleSaveThreshold}
          inspectionId={inspectionId}
          forceThreshold
          aoiParentId={aoi?.parent?.id}
          viewInInspection={currentView}
        />
      )}

      {typeOfEdit === 'minor_anomaly_result' && tool?.specification_name === 'graded-anomaly' && (
        <GradedAnomalyResultModal
          tool={tool}
          onClose={handleClose}
          robotIdsRunningRoutine={robotIdsRunningRoutine}
          aoiIds={aoiIds}
        />
      )}

      {tool?.specification_name === 'ocr' && (
        <OcrToolSettingsModal
          tool={tool}
          onClose={handleClose}
          robotIdsRunningRoutine={robotIdsRunningRoutine}
          aoiIds={aoiIds}
        />
      )}

      {tool?.specification_name === 'detect-barcode' && (
        <BarcodeToolSettingsModal
          tool={tool}
          onClose={handleClose}
          robotIdsRunningRoutine={robotIdsRunningRoutine}
          aoiIds={aoiIds}
        />
      )}

      {(tool?.specification_name === 'deep-svdd' || tool?.specification_name === 'classifier') && (
        <MemoTrainingReport
          onClose={handleClose}
          onCancelThreshold={onClose}
          toolId={tool.id}
          toolWithOverridesContext={tool}
          forceTitle="Edit Threshold"
          forceThreshold
          onSaveThreshold={handleSaveThreshold}
          inspectionId={inspectionId}
          aoiParentId={aoi?.parent?.id}
          viewInInspection={currentView}
        />
      )}
    </>
  )
}

export const useAoisWithToolResultCounts = ({
  aois,
  aoiMetrics,
}: {
  aois: AreaOfInterestExpandedWithImage[] | undefined
  aoiMetrics: TimeSeriesResult[] | undefined
}) => {
  const seriesByAoiId = useMemo(() => {
    const toReturn: { [aoiId: string]: CombinedOutcomeData[] } = {}
    if (aoiMetrics) {
      const aoiIds = [...new Set(aoiMetrics.map(result => result.labels.aoi_id))]
      for (const id of aoiIds) {
        toReturn[id!] = combineOutcomeCounts(aoiMetrics.filter(result => result.labels.aoi_id === id))
      }
    }
    return toReturn
  }, [aoiMetrics])

  const aoisWithToolResultCounts: AoiWithToolResultCount[] = useMemo(
    () =>
      (aois || []).map(aoi => {
        // TODO: if we ever move to more than one tool per AOI this needs to be refactored
        const tool = aoi.tools[0]
        const series = seriesByAoiId[aoi.id] || []
        const count = series.reduce((aggr: number, data) => aggr + (data.count || 0), 0)
        const fail = series.reduce((aggr: number, data) => aggr + (data.fail || 0), 0)
        const pass = series.reduce((aggr: number, data) => aggr + (data.pass || 0), 0)
        const unknown = series.reduce((aggr: number, data) => {
          const toAdd = (data.unknown || 0) + (data['needs-data'] || 0) + (data.error || 0)
          return aggr + toAdd
        }, 0)

        const muted = series.reduce((aggr: number, data) => aggr + (data.pass_because_muted || 0), 0)
        return { aoi, tool, count, fail, pass, unknown, muted }
      }),
    [aois, seriesByAoiId],
  )

  return aoisWithToolResultCounts
}

const renderPercentage = (value?: number, total?: number) => {
  if (!total) return '--'

  return `${(((value || 0) * 100) / total).toFixed(1)}%`
}

export const getSortedAoisWithToolResults = (aoisWithToolResults: AoiWithToolResultCount[]) => {
  return [...aoisWithToolResults].sort((a, b) => {
    let aWeight = calculatePercentage(a.fail, a.count)
    let bWeight = calculatePercentage(b.fail, b.count)
    // Once a tool is muted all its toolResult outcomes come in as pass, but this accounts for muting a tool partway through an inspection, and ensures once it's muted it gets sorted last
    if (isToolMuted(a.tool) || isToolUntrained(a.tool)) aWeight -= Infinity
    if (isToolMuted(b.tool) || isToolUntrained(b.tool)) bWeight -= Infinity
    return bWeight - aWeight
  })
}

const OfflinePredictionCards = () => {
  return (
    <div className={Styles.predictionCardsContainer}>
      {Array(4)
        .fill(undefined)
        .map((_, index) => (
          <div key={index} className={Styles.predictionCardsRow} style={{ opacity: calculateOpacityByIndex(index) }}>
            {Array(6)
              .fill(undefined)
              .map((_, i) => (
                <PrismElementaryCube key={i} className={Styles.predictionCard} />
              ))}
          </div>
        ))}
    </div>
  )
}
