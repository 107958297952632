import React, { useRef, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { PrismCard } from 'components/PrismCard/PrismCard'
import { PrismArchiveIcon, PrismGearIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { useData } from 'hooks'
import AddOrEditSiteModal from 'pages/StationDetail/Components/EntityModals/AddOrEditSiteModal'
import { Site } from 'types'
import { appendDataToQueryString, getSiteName, matchRole } from 'utils'

import Styles from '../Inspect.module.scss'
import ArchiveEntityModal from './ArchiveSlsModal'

interface SiteItemProps {
  sites: Site[]
  selectedSite?: Site
}
/**
 * Renders the sites list
 * @param selectedSite - The active or selected site from the list
 * @param setSelectedSite - Site state updater
 * @param siteList - The sites list
 */
const SitesList = ({ sites, selectedSite }: SiteItemProps) => {
  const me = useData(getterKeys.me())
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const menuItemSiteRef = useRef<Site>()

  const handleMenuItemClick = (site: Site, value: 'settings' | 'archive') => {
    menuItemSiteRef.current = site
    if (value === 'archive') setShowArchiveModal(true)
    if (value === 'settings') setShowSettingsModal(true)
  }

  return (
    <ul className={Styles.sitesList}>
      {sites.map(site => (
        <li className={Styles.siteListItem} key={site.name}>
          <PrismCard
            data-testid={`site-list-${site.name}`}
            type="transparent"
            onClick={() => {
              appendDataToQueryString(history, {
                siteId: site.id,
                ...(selectedSite?.id !== site.id ? { stationId: undefined } : {}),
              })
            }}
            optionMenuProps={
              matchRole(me, 'manager')
                ? {
                    options: [
                      { value: 'settings', 'data-testid': '', badge: <PrismGearIcon /> },
                      { value: 'archive', 'data-testid': '', badge: <PrismArchiveIcon /> },
                    ],
                    onMenuItemClick: value => handleMenuItemClick(site, value),
                    openWithClick: true,
                    iconButtonSize: 'xsmall',
                    position: 'bottomRight',
                    renderWithPortal: true,
                    menuContainerClassName: Styles.optionMenuContainer,
                  }
                : undefined
            }
            className={Styles.siteItemCard}
            active={site.id === selectedSite?.id}
          >
            <div className={Styles.siteItemCardDetails}>
              <PrismOverflowTooltip textClassName={Styles.siteName} content={site.name} canBeResized />
              <PrismOverflowTooltip textClassName={Styles.siteLocation} content={getSiteName(site)} canBeResized />
            </div>
          </PrismCard>
        </li>
      ))}
      {showArchiveModal && menuItemSiteRef.current && (
        <ArchiveEntityModal
          entityType="site"
          entityId={menuItemSiteRef.current?.id}
          onOk={async () => {
            await query(getterKeys.sites(), service.getSites, { dispatch })
          }}
          onClose={() => setShowArchiveModal(false)}
        />
      )}
      {showSettingsModal && menuItemSiteRef.current && (
        <AddOrEditSiteModal isEditMode onClose={() => setShowSettingsModal(false)} entity={menuItemSiteRef.current} />
      )}
    </ul>
  )
}

export default SitesList
