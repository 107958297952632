import React from 'react'

import { Redirect } from 'react-router-dom'

import { getterKeys } from 'api'
import Layout from 'components/Layout/Layout'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { useData, useStation, useTypedSelector } from 'hooks'
import paths from 'paths'
import { getLastStationDetailMode, matchRole } from 'utils'

import Inspect from './Inspect'

/**
 * Forces user into Station Detail screen if colocated and not high role enough to access Inspect screen.
 */
export default function ColocatedStationRedirect({ inspectTab }: { inspectTab?: 'site' | 'product' }) {
  const me = useData(getterKeys.me())
  const locationHistory = useTypedSelector(state => state.locationHistory)
  const { station, isFetching } = useStation()
  if (isFetching && !station) {
    return (
      <Layout title={null}>
        <PrismLoader fullScreen />
      </Layout>
    )
  }

  if (!station || matchRole(me, 'manager')) return <Inspect inspectTab={inspectTab || 'site'} />

  // If we have colocated robots, render the colocated station's detail page with the last mode the user was on.
  const lastStationDetailMode = getLastStationDetailMode(locationHistory, station.id)
  return <Redirect to={paths.stationDetail(lastStationDetailMode, station.id)} />
}
