import React, { useCallback, useMemo } from 'react'

import { Popover, PopoverProps } from 'antd'
import { useHistory } from 'react-router-dom'

import { getterKeys } from 'api'
import { Divider } from 'components/Divider/Divider'
import { IconButton } from 'components/IconButton/IconButton'
import ImgFallback from 'components/Img/ImgFallback'
import LabelInfo from 'components/LabelInfo/LabelInfo'
import { PrismEditIcon } from 'components/prismIcons'
import { PrismChartIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { useData, useHotkeyPress } from 'hooks'
import paths from 'paths'
import Shared from 'styles/Shared.module.scss'
import { ToolLabel } from 'types'
import { findDefaultDescription, getDisplaySeverity, getLabelName, getToolLabelImagesToShow, matchRole } from 'utils'

import Styles from './LabelingScreen.module.scss'

interface LabelInfoProps extends Omit<PopoverProps, 'title'> {
  popoverClassName?: string
  toolLabel: ToolLabel
  onClose: () => void
  onEditLabelClick: (toolLabel: ToolLabel) => void
  setSelectedImageIdx: (idx?: number) => void
  children: React.ReactNode
}

/**
 * Renders a Popover with the label info.
 *
 * https://www.figma.com/file/Jdxw39LY47tE18HWvLOe0K/Train-%26-Label-(main)?node-id=1859%3A37274
 *
 *
 * @param toolLabel - tool label to show info for
 * @param className - string that hold the css selector
 * @param onClose - function to closed the popover
 * @param onEditLabelClick - function to show the glossary in edit mode
 * @param open -  whether the button is open
 * @param onopenChange - handler called when visibility changes
 * @param children - popover children
 *
 */

export const LabelingInfoPopover = ({
  toolLabel,
  popoverClassName,
  onClose,
  onEditLabelClick,
  placement = 'bottomRight',
  open,
  onOpenChange,
  children,
  setSelectedImageIdx,
  ...rest
}: LabelInfoProps) => {
  const me = useData(getterKeys.me())
  const history = useHistory()

  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (open && e.key === 'Escape') {
        e.stopPropagation()
        onClose()
      }
    },
    [onClose, open],
  )

  useHotkeyPress(keyHandler, { capture: true })

  const description = useMemo(() => {
    if (toolLabel.kind === 'default') return findDefaultDescription(toolLabel)

    return toolLabel.description || ''
  }, [toolLabel])

  const images = getToolLabelImagesToShow(toolLabel)

  const handleViewResultsClick = () => {
    const newPath = paths.analyze({ mode: 'items', params: { prediction_label_id: toolLabel.id } })
    history.push(newPath)
  }

  const handleImageClick = (idx: number) => {
    setSelectedImageIdx(idx)
    onOpenChange?.(false)
  }

  return (
    <>
      <Popover
        open={open}
        placement={placement}
        onOpenChange={onOpenChange}
        overlayClassName={`${Styles.labelingInfoPopover} ${popoverClassName ?? ''}`}
        trigger="click"
        title={
          <>
            <header
              className={Styles.labelingInfoHeader}
              data-testid="labeling-info-popover-header"
              onClick={e => e.stopPropagation()}
            >
              <PrismOverflowTooltip
                content={getLabelName(toolLabel)}
                className={Styles.labelingInfoHeaderTitle}
                tooltipPlacement="bottom"
                data-testid="label-popover-value"
              />

              <div className={Styles.labelingInfoHeaderActions}>
                {matchRole(me, 'manager') && (
                  <PrismTooltip title="Edit Label" condition={toolLabel.kind !== 'default'}>
                    <IconButton
                      disabled={toolLabel.kind === 'default'}
                      icon={<PrismEditIcon />}
                      type="tertiary"
                      size="xsmall"
                      onClick={() => onEditLabelClick(toolLabel)}
                    />
                  </PrismTooltip>
                )}
                <PrismTooltip title="View Results">
                  <IconButton
                    icon={<PrismChartIcon />}
                    type="tertiary"
                    size="xsmall"
                    onClick={handleViewResultsClick}
                  />
                </PrismTooltip>
              </div>
            </header>
            <Divider className={Styles.labelingInfoDivider} />
          </>
        }
        content={
          <div
            className={`${Styles.labelingInfoBody} ${Shared.verticalChildrenGap24}`}
            onClick={e => e.stopPropagation()}
          >
            <LabelInfo title="Severity" addDescriptionGap>
              <PrismResultButton
                severity={getDisplaySeverity(toolLabel)}
                value={toolLabel.severity}
                type="fill"
                className={Styles.severityContainer}
              />
            </LabelInfo>

            {images.length > 0 && (
              <LabelInfo title="Example images" addDescriptionGap>
                <div className={Styles.examplesImagesWrapper}>
                  {images.map((img, idx) => (
                    <figure key={idx} className={Styles.exampleImageContainer} onClick={() => handleImageClick(idx)}>
                      <ImgFallback src={img} retries={5} className={Styles.labelFormImage} loaderType="skeleton" />
                    </figure>
                  ))}
                </div>
              </LabelInfo>
            )}

            {description && (
              <LabelInfo title="Description">
                <div data-testid="label-popover-description">{description}</div>
              </LabelInfo>
            )}

            {toolLabel.root_cause && (
              <LabelInfo title="Root Causes">
                <div data-testid="label-popover-root-causes">{toolLabel.root_cause}</div>
              </LabelInfo>
            )}

            {toolLabel.corrective_actions && (
              <LabelInfo title="Corrective Actions">
                <p className={Styles.itemDescription} data-testid="label-popover-corrective-actions">
                  {toolLabel.corrective_actions}
                </p>
              </LabelInfo>
            )}
          </div>
        }
        {...rest}
      >
        {children}
      </Popover>
    </>
  )
}
