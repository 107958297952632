import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Text Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3678%3A54
 *
 * @param hasHover - Fill the icon with $light_100 when hovering the icon.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismTextToolIcon = ({ isActive, hasHover, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${hasHover ? Styles.hoverInBlue : ''} ${isActive ? Styles.active : ''} ${
        className ?? ''
      }`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M2.25 17.5728H3.64286L5.03571 14.0907H10.6071L12 17.5728H13.3929L8.51786 6.43H7.125L2.25 17.5728ZM5.73214 12.6979L7.82143 7.82286L9.91071 12.6979H5.73214Z"
        fill="currentColor"
      />
      <path
        d="M14.0893 13.3943C14.0893 16.18 16.1786 17.5729 17.9196 17.5729C19.1231 17.5729 19.8891 17.1968 20.3571 16.5282V17.5728L21.75 17.5729V9.21569L20.3571 9.21571V10.2604C19.8891 9.59179 19.1231 9.21571 17.9196 9.21571C16.1786 9.21571 14.0893 10.6086 14.0893 13.3943ZM20.3571 13.3943C20.3571 13.7425 20.3571 16.18 18.2679 16.18C16.1786 16.18 15.4821 14.8986 15.4821 13.3943C15.4821 11.89 16.1786 10.6086 18.2679 10.6086C20.3571 10.6086 20.3571 13.0461 20.3571 13.3943Z"
        fill="currentColor"
      />
    </svg>
  )
}
