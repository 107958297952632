import React, { useEffect, useRef, useState } from 'react'

import moment from 'moment'

import { IS_QA } from 'env'

import Styles from './QaTimer.module.scss'

/**
 * Renders a box with a Unix timestamp floating on the edge of the screen. If
 * `IS_QA` env var is false, renders nothing.
 *
 * This timer is used to sync events in QA recordings of the web app with events
 * in the log files.
 */
function QaTimer() {
  const timerId = useRef<number>()
  const [time, setTime] = useState(moment.utc().format('YYYY-MM-DD HH:mm:ss'))

  useEffect(() => {
    if (!IS_QA) return

    timerId.current = window.setInterval(() => {
      setTime(moment.utc().format('YYYY-MM-DD HH:mm:ss'))
    }, 1000)

    return () => window.clearInterval(timerId.current)
  }, [])

  if (!IS_QA) return null

  return <div className={Styles.qaTimer}>{time}</div>
}

export default QaTimer
