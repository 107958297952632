import React, { useEffect, useRef, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import qs from 'qs'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import AppVersion from 'components/AppVersion/AppVersion'
import { Button } from 'components/Button/Button'
import { PrismElementaryIsotypeIcon } from 'components/prismIcons/PrismElementaryIsotypeIcon'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { error } from 'components/PrismMessage/PrismMessage'
import { info } from 'components/PrismNotification/PrismNotification'
import { info as infoNotification } from 'components/PrismNotification/PrismNotification'
import { AUTH0_AUDIENCE, AUTH0_CLIENTID, AUTH0_DOMAIN } from 'env'
import { useQueryParams, useTypedSelector } from 'hooks'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import { Auth, AuthState } from 'types'

import EulaModal from './EulaModal'
import LoginContainer from './LoginContainer'
import Styles from './LoginContainer.module.scss'

/**
 * Renders the login screen or redirects already logged in users.
 */
const Login = () => {
  const location = useLocation<{ from?: Location; showButton?: boolean }>()
  const history = useHistory()
  const [params] = useQueryParams<'showButton'>()
  const fromUrlRef = useRef(localStorage.getItem('elementary:fromUrl'))

  const auth = useTypedSelector(state => state.auth)
  const from = location.state?.from || ({ pathname: fromUrlRef.current || '/' } as Location)
  const showButton = !!params.showButton || !!location.state?.showButton

  // Only redirect user if they are already authenticated on mount,
  // since we login and then logut owners who haven't accepted eula,
  // we don't want this effect to run every time auth changes
  // we handle further redirects event based
  useEffect(() => {
    if (auth.auth) return history.push(from)
    // eslint-disable-next-line
  }, [])

  // Enable login form in app in case we're not using Auth0
  if (!AUTH0_AUDIENCE || !AUTH0_CLIENTID || !AUTH0_DOMAIN) return <LoginContainer from={from} />

  return <Auth0LoginContainer from={from} auth={auth} showButton={showButton} />
}

export default Login

function Auth0LoginContainer({ from, auth, showButton }: { from: Location; auth: AuthState; showButton: boolean }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showEulaModal, setShowEulaModal] = useState(false)
  const authData = useRef<Auth | null>(null)
  const [params] = useQueryParams<'logoutCode', LogoutMessageCode>()

  const {
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout: auth0Logout,
    isAuthenticated,
  } = useAuth0()

  useEffect(() => {
    // If the user is authenticated, remove the stored url
    if (auth.auth) return localStorage.removeItem('elementary:fromUrl')

    // stores the last visited url. If the user logs out through the logout button, don't set 'from' to be account settings screen
    if (!from.pathname.startsWith(paths.accountSettings({ onlyPrefix: true })))
      return localStorage.setItem('elementary:fromUrl', from.pathname + (from.search || ''))
  }, [auth.auth, from.pathname, from.search])

  const loginThroughAuth0 = () => {
    loginWithRedirect({ redirectUri: window.location.origin + paths.login() })
  }

  const logoutFromAuth0 = (logoutMessageCode?: LogoutMessageCode) => {
    const qsParams: { [key: string]: any } = { showButton: true }

    if (logoutMessageCode) {
      logoutMessages[logoutMessageCode]?.()
      qsParams.logoutCode = logoutMessageCode
    }

    auth0Logout({
      returnTo: window.location.origin + paths.login() + qs.stringify(qsParams, { addQueryPrefix: true }),
    })
  }

  const handleAcceptEula = async () => {
    if (!authData.current) return
    dispatch(Actions.authSet({ auth: authData.current }))
    authData.current = null

    const res = await service.acceptEula()
    if (res.type !== 'success') {
      dispatch(Actions.authUnset())
      logoutFromAuth0('errorSigningEula')
      return
    }

    query(getterKeys.me(), service.me, { dispatch })
    query(getterKeys.organization(), service.organization, { dispatch })
    history.push(from.pathname)
  }

  // We get the tokens generated by auth0 to exchange them with Django for our auth token
  // which we can then use to fetch our user.
  const exchangeAuth0Tokens = async () => {
    const access_token = await getAccessTokenSilently()
    const id_token = await getIdTokenClaims()

    if (!access_token || !id_token) return
    const authRes = await service.exchangeAuth0Token({ access_token, id_token: id_token.__raw })

    if (authRes.type !== 'success') {
      logoutFromAuth0('errorLoggingIn')
      return
    }

    dispatch(Actions.authSet({ auth: authRes.data }))
    authData.current = authRes.data

    // fetch rest of user details if user logged in successfully
    const meRes = await query(getterKeys.me(), service.me, { dispatch })
    const orgRes = await query(getterKeys.organization(), service.organization, { dispatch })

    if (meRes?.type !== 'success' || orgRes?.type !== 'success') {
      dispatch(Actions.authUnset())
      logoutFromAuth0('userNoOrg')
      return
    }
    const user = meRes.data
    const org = orgRes.data
    const userDomain = user.email.split('@')[1]
    const bypassEula = userDomain && org.email_domain_skip_eula_allowlist.includes(userDomain)

    if (org.accepted_eula || bypassEula) return history.push(from.pathname)

    // If user's org hasn't agreed to EULA, remove auth data, to prevent them from logging in by reloading app.
    dispatch(Actions.authUnset())

    if (user.role === 'owner') {
      setShowEulaModal(true)
    } else {
      logoutFromAuth0('eulaNotSigned')
    }
  }

  useEffect(() => {
    if (params.logoutCode) logoutMessages[params.logoutCode]?.()
    if (isLoading) return

    // If logged out from app but not from auth0, force logout from auth0 for security reasons.
    if (!auth.auth && auth.logoutAction && isAuthenticated) {
      logoutFromAuth0(auth.logoutAction === 'sessionExpired' ? 'sessionExpired' : undefined)
      return
    }

    // If authenticated with auth0, we now need to authenticate with django.
    if (isAuthenticated) {
      exchangeAuth0Tokens()
      return
    }

    // Redirect automatically to auth0 if not showing the login button
    if (!showButton) {
      loginThroughAuth0()
      return
    }

    // eslint-disable-next-line
  }, [isLoading])

  return (
    <div className={Styles.container}>
      <div className={`${Styles.formContainer} ${showButton ? Styles.logoutContainer : ''}`}>
        <div className={Styles.elementaryLogoContainer}>
          <PrismElementaryIsotypeIcon />
        </div>

        {!showButton && (
          <div className={Styles.gridContainer}>
            {Array(2)
              .fill(undefined)
              .map((_, i) => (
                <div key={i} className={Styles.boxLoader}>
                  <PrismSkeleton />
                </div>
              ))}
          </div>
        )}

        {showButton && (
          <div className={Styles.logout}>
            <h1 className={Styles.logoutTitle}>You Are Logged Out</h1>
            <p className={Styles.logoutDescription}>Log in to start a new session</p>
            <Button className={Styles.loginBtn} onClick={loginThroughAuth0} wide>
              Log In
            </Button>
          </div>
        )}
      </div>

      {showEulaModal && (
        <EulaModal
          onOk={handleAcceptEula}
          onClose={() => {
            setShowEulaModal(false)
            logoutFromAuth0()
          }}
        />
      )}

      {showButton && <AppVersion className={Styles.appVersion} />}
    </div>
  )
}

type LogoutMessageCode = 'sessionExpired' | 'eulaNotSigned' | 'userNoOrg' | 'errorSigningEula' | 'errorLoggingIn'
export const logoutMessages = {
  sessionExpired: () =>
    infoNotification({
      id: 'session-expired-notification',
      title: 'Your session expired',
      description: 'You were logged out because of your security settings. Log in to start a new session.',
      position: 'bottom-left',
      duration: 0,
    }),
  userNoOrg: () =>
    error({ id: 'user-no-org-error-message', title: 'You tried to log in with a user that has no organization...' }),
  eulaNotSigned: () =>
    info({
      id: 'eula-not-signed-message',
      title: 'End User Licence Agreement',
      description:
        'An Admin user in your organization must approve the End User Licence Agreement before any other user can log in.',
      position: 'bottom-left',
      duration: 0,
    }),
  errorSigningEula: () => {
    error({ id: 'eula-signing-error', title: 'There was an issue signing the EULA. Please try again.' })
  },
  errorLoggingIn: () => {
    error({ id: 'token-exchange-error', title: 'There was an issue logging in. Please contact the Administrator' })
  },
}
