import React, { useEffect, useState } from 'react'

import { InputNumber, Switch } from 'antd'
import { useDispatch } from 'react-redux'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import { PrismGalleryIcon } from 'components/prismIcons'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Token } from 'components/Token/Token'
import { useData } from 'hooks'
import * as Actions from 'rdx/actions'
import { PlcTagLayout, PlcTags } from 'types'
import { matchRole } from 'utils'

import Styles from './PlcControl.module.scss'

type Props = {
  showSettings: boolean
  robotId: string
  onClick: () => any
  tag: PlcTagLayout
}

const typeClasses = {
  text: Styles.plcString,
  button: Styles.plcButton,
  toggle: Styles.plcToggle,
  input: Styles.plcInput,
}

/**
 * Renders a single box of layout. Depending on the tag data, this box will render different types of
 * components, either a text, button, input or toggle type of box.
 *
 * @param showSettings - The current state of the screen. This indicates if the current box is editable
 * @param onClick - Handler for click on the current box when available
 * @param robotId - The current robotId
 * @param tag - The layout data of the current tag
 */
function PlcOptionBox({ showSettings, onClick, robotId, tag }: Props) {
  const dispatch = useDispatch()
  const me = useData(getterKeys.me())

  const [showButtons, setShowButtons] = useState<boolean>(false)
  const [value, setValue] = useState<string | number>()
  const [sendingCommand, setSendingCommand] = useState<boolean>(false)

  useEffect(() => {
    if (!tag.value) return

    if (typeof tag.value === 'number' || typeof tag.value === 'string') setValue(tag.value)
  }, [tag.value])

  const typeClass = typeClasses[tag.type]

  const sendPlcCommand = async (data?: string | number | boolean | null) => {
    setSendingCommand(true)

    const res = await service.atomSendCommandExtractData<PlcTags>('plc', 'write_tags', robotId, {
      command_args: {
        [tag.tag]: {
          tag_name: tag.tag,
          tag_value: data,
          tag_type: tag.dataType,
        },
      },
    })

    if (res.type === 'success') {
      success({ title: 'Save to PLC successful' })
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.plcTags(robotId),
          updater: prevRes => {
            if (!prevRes) return res
            return {
              ...prevRes,
              data: {
                ...prevRes.data,
                ...res.data,
              },
            }
          },
        }),
      )
    } else error({ title: 'Save to PLC failed. Try again' })

    // Send read command to plc when that's done
    setSendingCommand(false)
  }

  const handleSaveText = () => {
    sendPlcCommand(value)
    setShowButtons(false)
  }

  const handleCancelText = () => {
    if (typeof tag.value === 'number' || typeof tag.value === 'string') setValue(tag.value)
    setShowButtons(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleNumberInputChange = (e: string | number | null | undefined) => {
    if (typeof e === 'number') setValue(e)
  }

  const allowed = matchRole(me, tag.min_user_role)

  return (
    <>
      <div
        onClick={() => showSettings && onClick()}
        className={`${Styles.plcBox} ${typeClass} ${Styles[`${tag.color}Border`]}`}
      >
        {showSettings && tag.type && (
          <>
            <div className={Styles.plcSettings}>
              <PrismGalleryIcon />
            </div>
          </>
        )}

        {tag.type === 'text' && (
          <Token label={tag.label} align="center" className={Styles.plcText} labelClassName={Styles.plcLabel}>
            {tag.value}
          </Token>
        )}

        {tag.type === 'button' && (
          <Button
            onClick={() => sendPlcCommand(true)}
            disabled={!allowed || tag.disabled || sendingCommand}
            size="medium"
            type="secondary"
            wide
          >
            <p className={Styles.buttonTextWrapper}>{tag.label}</p>
          </Button>
        )}

        {tag.type === 'input' && (
          <>
            <Token label={tag.label} align="center">
              {typeof tag.value === 'string' && (
                <PrismInput
                  disabled={!allowed || tag.disabled || sendingCommand}
                  onFocus={() => setShowButtons(true)}
                  onChange={handleInputChange}
                  value={value}
                />
              )}

              {typeof tag.value === 'number' && (
                <InputNumber
                  disabled={!allowed || tag.disabled || sendingCommand}
                  onFocus={() => setShowButtons(true)}
                  onChange={handleNumberInputChange}
                  value={value as number}
                />
              )}
            </Token>
            {showButtons && (
              <div className={Styles.plcInputButtons}>
                <Button onClick={handleCancelText} size="small" type="tertiary">
                  Cancel
                </Button>
                <Button onClick={handleSaveText} size="small" className={Styles.inputButtonSave}>
                  Save
                </Button>
              </div>
            )}
          </>
        )}

        {tag.type === 'toggle' && (
          <Token label={tag.label} align="center">
            <Switch
              onChange={sendPlcCommand}
              checked={tag.value as boolean}
              disabled={!allowed || tag.disabled || sendingCommand}
              size="default"
            />
          </Token>
        )}
      </div>
    </>
  )
}

export default PlcOptionBox
