import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconOutcomeProps } from './prismIconsTypes'

/**
 * Prism Test Set Icon
 *
 * * Reference: {https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=9992%3A16894&mode=dev}
 *
 *
 * @param variant - adds special styles to the icon.
 * @param className - String for external styles.
 * @param viewboxSize - manipulates the viewbox value of the icon, default value is medium: 0 0 20 20;
 */
const viewboxSizeClasses = {
  xSmall: '4 4 12 12',
  small: '2 2 16 16',
  medium: '0 0 20 20',
}

const variantClasses = {
  transparent: '',
  low: Styles.lowContrast,
  high: Styles.highContrast,
  dark: Styles.dark,
  iconColor: Styles.color,
}

export const PrismTestSetIcon = ({
  variant = 'transparent',
  viewboxSize = 'medium',
  className,
}: PrismIconOutcomeProps) => {
  const variantClass = variantClasses[variant]

  const viewboxSizeClass = viewboxSizeClasses[viewboxSize]

  const iconClassName = `${Styles.icon} ${Styles.outcome}  ${variantClass} ${className ?? ''}`
  return (
    <svg className={iconClassName} viewBox={viewboxSizeClass} xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5 10C13.5 11.933 11.933 13.5 10 13.5C8.067 13.5 6.5 11.933 6.5 10C6.5 8.067 8.067 6.5 10 6.5C11.933 6.5 13.5 8.067 13.5 10ZM15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10ZM10 11.5C10.8284 11.5 11.5 10.8284 11.5 10C11.5 9.17157 10.8284 8.5 10 8.5C9.17157 8.5 8.5 9.17157 8.5 10C8.5 10.8284 9.17157 11.5 10 11.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
