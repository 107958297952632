/**
 * Our metrics is event-based.
 *
 * Items are immediately added to the compacted series, so every compacted series is up-to-date.
 *
 * There are a few caveats when fetching metrics:
 * - When aggregating metrics into time buckets, the size of the time bucket has to be a multiple of the aggregation
 *   period we are trying to fetch
 * - The aggregation period we want to fetch cannot be bigger than the time bucket
 * - Using larger aggregation periods risks allocating counts to wrong days due to how we store metrics on the compacted series using UTC
 * - If we only want to count metrics, we should use the largest aggregation period available (1 day)
 *
 * NOTE: the code in this module is subtle and hard to get right. DO NOT change
 * it without consulting the team first.
 */
import { isNil } from 'lodash'
import moment from 'moment'
import momentTz from 'moment-timezone'

import {
  DateValue,
  MetricsToolResultOutcome,
  ReadTimeSeriesBody,
  TimeSeriesDatePeriod,
  TimeSeriesResult,
  TimeSeriesType,
} from 'types'

import { SECONDS_IN_DAY, SECONDS_IN_HOUR } from './constants'
import { getSiteAndLinesBackendParams } from './utils'

export const HOUR_MS = 3600000
export const DAY_MS = 24 * HOUR_MS
export const METRICS_START_DATE_MS = 1577840400000 // Jan 1st, 2020 - There is no customer data previous to this date
export const METRICS_START_DATE_S = METRICS_START_DATE_MS / 1000
export type METRICS_COMPACTION_GETTER_KEY = '5m' | '1h' | '4h' | 'smart' | 'counts'

/**
 * Truncates all timestamps in series to nearest RTS date period value. DON'T
 * call this function with a series whose results have already been aggregated
 * to a less granular period than the RTS period passed in (there's no point).
 *
 * @param entries - RTS series
 * @param period - RTS date period
 *
 * @returns - Series with all timestamps truncated to nearest RTS period value
 */
export function truncateSeriesTimestampsRtsDatePeriod(
  entries: TimeSeriesResult['entries'],
  period: 'month' | 'week' | 'day' | 'hour' | 'minute',
): TimeSeriesResult['entries'] {
  return entries.map(data => {
    const ts = momentTz.unix(data[0]).startOf(period).unix()
    return [ts, data[1]]
  })
}

/**
 * Takes `series`, which must be ordered by ascending `ts`, and fills in gaps.
 * Gaps are determined by `period`. We start with the smallest ts in series and
 * fill in "period-sized" gaps, according to moment-js, until we get to the
 * biggest ts.
 *
 * Use this with `truncateSeriesTimestampsRtsDatePeriod`, which truncates
 * timestamps to periods >= 1m, according to moment-js, and
 * `combineOutcomeCounts`, which ensures that series ts values are deduplicated
 * and sorted in ascending order.
 *
 * If period is 'minute', make sure aggregation A satisfies A = N * 60 * 1000 *
 * numPeriods, where N >= 1, N is an integer.
 */
export function seriesFillGapsRtsDatePeriod<T extends { ts: number }>(
  series: T[],
  period: 'month' | 'week' | 'day' | 'hour' | 'minute',
  options: { fillData?: any; end?: DateValue; numPeriods?: number; start?: DateValue } = {},
) {
  const fillData = options.fillData || {
    count: 0,
    pass: 0,
    fail: 0,
    unknown: 0,
    error: 0,
    'needs-data': 0,
    pass_because_muted: 0,
  }
  const dataByTs: { [ts: number]: { ts: number } | undefined } = {}
  for (const data of series) dataByTs[data.ts] = data // build dict to speed lookups

  const startTs = options.start ? options.start.clone().startOf(period).unix() : series[0]?.ts
  const endTs = (options.end || momentTz()).clone().startOf(period).unix()

  if (!startTs) return []
  const timestamps: number[] = []
  let ts = startTs
  // Integration tests are running into an issue where the series returned by these function all have counts of 0
  // The reason is that the series passed into this function is a day ahead of the `endTs` timestamp.
  // By adding a day here, we put a temporal bandaid just for the tests to pass, but I need to figure out one of 2 options
  // 1.- Either the `series` passed into this function is incorrectly marked at a timestamp of "tomorrow"
  // 2.- The `endTs` calculated inside this function is incorrectly set at "yesterday"
  while (ts <= endTs) {
    timestamps.push(ts)
    ts = momentTz
      .unix(ts)
      .startOf(period)
      .add(options.numPeriods || 1, period)
      .unix()
  }

  // This works because all timestamps in `series` have already been truncated to startOf `period`
  const filledSeries: T[] = []
  for (const ts of timestamps) {
    filledSeries.push(dataByTs[ts] || { ts, ...fillData })
  }

  return filledSeries
}

export type CombinedOutcomeData = { ts: number; count: number } & { [V in MetricsToolResultOutcome]: number }

/**
 * Combine RTS results with various `outcome` values into a single series with
 * deduplicated `ts` values. Also sorts values in ascending order by ts.
 *
 * Note that `pass_because_muted` outcomes aren't added to cumulative `count`,
 * because for metrics purposes, any record with a outcome of
 * `pass_because_muted` has a corresponding record with a outcome of `pass`.
 */
export function combineOutcomeCounts(results: TimeSeriesResult[]): CombinedOutcomeData[] {
  // https://www.typescriptlang.org/docs/handbook/advanced-types.html#mapped-types
  const countsByTs: { [ts: number]: { count: number } & { [V in MetricsToolResultOutcome]: number } } = {}

  for (const result of results) {
    const outcome = result.labels.outcome as MetricsToolResultOutcome
    for (const data of result.entries) {
      const [ts, value] = data
      if (!countsByTs[ts]) {
        countsByTs[ts] = {
          count: 0,
          pass: 0,
          fail: 0,
          unknown: 0,
          error: 0,
          'needs-data': 0,
          pass_because_muted: 0,
        }
      }

      countsByTs[ts]![outcome] += value
      if (outcome !== 'pass_because_muted') countsByTs[ts]!.count += value
    }
  }

  const series = Object.keys(countsByTs)
    .map(key => parseInt(key))
    .map(ts => ({ ts, ...countsByTs[ts]! }))

  return series.sort((a, b) => a.ts - b.ts)
}

/**
 * Combine RTS results with various `outcome` values into different keys of a
 * dictionary, these keys are calculated according to `propertyName` specified
 * by the user. However, `property_name` must be a valid rts label.
 *
 * Note that `pass_because_muted` outcomes aren't added to cumulative `count`,
 * because for metrics purposes, any record with an outcome of
 * `pass_because_muted` has a corresponding record with an outcome of `pass`.
 */
export function combineOutcomeCountsByLabel(results: TimeSeriesResult[], propertyName: string) {
  const countsByProperty: {
    [property: string]: ({ count: number } & { [V in MetricsToolResultOutcome]: number }) | undefined
  } = {}

  results.forEach(result => {
    const property = result.labels[propertyName] || ''
    if (!countsByProperty[property]) {
      countsByProperty[property] = {
        count: 0,
        pass: 0,
        unknown: 0,
        fail: 0,
        error: 0,
        'needs-data': 0,
        pass_because_muted: 0,
      }
    }
    const outcome = result.labels.outcome as MetricsToolResultOutcome
    result.entries.forEach(series => {
      const counts = countsByProperty[property]
      if (counts) {
        counts[outcome] += series[1]
        if (outcome !== 'pass_because_muted') counts.count += series[1]
      }
    })
  })

  return countsByProperty
}

/**
 * Takes `series`, which must be ordered by ascending `ts`, and fills in gaps.
 * Gaps are determined by `bucketMs`. We start with the smallest ts in series
 * and fill in gaps of width `bucketMs` until we get to the biggest ts.
 *
 * Use this with `combineOutcomeCounts`, which ensures that series ts values
 * are deduplicated and sorted in ascending order.
 *
 * This should not be used in charts or graphs with days, weeks, months, etc on
 * the x-axis. Addition with days, weeks and months does not behave intuitively.
 * For this reason, this MUST NOT BE called with any series that has been passed
 * to `truncateSeriesTimestampsRtsDatePeriod`.
 */
export function seriesFillGaps<T extends { ts: number }>(
  series: T[],
  bucketMs: number,
  fillData: any = {
    count: 0,
    pass: 0,
    fail: 0,
    unknown: 0,
    error: 0,
    'needs-data': 0,
    pass_because_muted: 0,
  },
) {
  if (series.length === 0) return []

  const dataByTs: { [ts: number]: T | undefined } = {}
  for (const data of series) dataByTs[data.ts] = data // build dict to speed lookups

  const startTs = series[0]?.ts
  const endTs = series[series.length - 1]?.ts
  if (!startTs || !endTs) return []
  const timestamps: number[] = []
  let ts = startTs
  while (ts <= endTs) {
    timestamps.push(ts)
    ts += bucketMs
  }

  const filledSeries: T[] = []
  for (const ts of timestamps) filledSeries.push(dataByTs[ts] || { ts, ...fillData })

  return filledSeries
}

export function getRtsParams({
  inspectionDuration,
  seriesType,
  rtsDatePeriod,
  labels,
  metricParams = {},
}: {
  inspectionDuration?: number
  seriesType: TimeSeriesType
  rtsDatePeriod: { from_s?: number; to_s?: number; past_s?: number }
  labels: Record<string, any>
  metricParams: { timeframe?: number; isHistoricBatch?: boolean; onlyCounts?: boolean }
}) {
  const { metricsCompaction, pollIntervalMs, bucket_s, agg_s } = calculateMetricsCompactionParams(
    inspectionDuration,
    metricParams,
  )
  const rtsDataPeriodCopy = { ...rtsDatePeriod }

  if ('from_s' in rtsDatePeriod && !rtsDatePeriod.from_s) {
    rtsDataPeriodCopy.from_s = METRICS_START_DATE_S
  }

  const rtsParams: ReadTimeSeriesBody = {
    ...rtsDataPeriodCopy,
    bucket_s,
    agg_s,
    labels,
    series_type: seriesType,
  }

  return { rtsParams, metricsCompaction, pollIntervalMs }
}

/**
 * Calculates the necessary metrics filters in order to fetch the most granular data for
 * our inspection graphs, determined by how long an inspection has been running.
 *
 * @param inspectionDuration
 */
export function calculateMetricsCompactionParams(
  inspectionDuration: number | undefined,
  {
    timeframe,
    onlyCounts,
    isHistoricBatch = true,
  }: { timeframe?: number; isHistoricBatch?: boolean; onlyCounts?: boolean } = {},
) {
  let metricsCompaction: METRICS_COMPACTION_GETTER_KEY
  let pollIntervalMs: number | undefined

  // 5m timeframe of StationDetailOverview timeframe toggles
  if (timeframe === 1000 * 60 * 5) {
    metricsCompaction = '5m'
    pollIntervalMs = 3 * 1000
  }

  // 1h timeframe of StationDetailOverview timeframe toggles
  else if (timeframe === 1000 * 60 * 60) {
    metricsCompaction = '1h'
    pollIntervalMs = 15 * 1000
  }

  // 4h timeframe of StationDetailOverview timeframe toggles
  else if (timeframe === 1000 * 60 * 60 * 4) {
    metricsCompaction = '4h'
    pollIntervalMs = 30 * 1000
  }

  // Batch tab or regular use across the app
  else {
    metricsCompaction = 'smart'
    if ((inspectionDuration || 0) > 10 * DAY_MS) pollIntervalMs = 30 * 1000
    else if ((inspectionDuration || 0) > DAY_MS) pollIntervalMs = 20 * 1000
    else pollIntervalMs = isHistoricBatch ? undefined : 10 * 1000
  }

  if (onlyCounts) metricsCompaction = 'counts'

  const { bucket_s, agg_s } = getBucketSize(timeframe || inspectionDuration, {
    isHistoricBatch,
    onlyCounts,
  })

  return { metricsCompaction, bucket_s, agg_s, pollIntervalMs }
}

export const DAY_PERIOD_DAY_DIFFERENCE = 45
export const WEEK_PERIOD_DAY_DIFFERENCE = 100
export const HOUR_PERIOD_DAY_DIFFERENCE = 7
export const THIRTY_MIN_PERIOD_DAY_DIFFERENCE = 1

/**
 * This function takes in a start and end dates and a period and calculates
 * the an array of available periods to select from.
 *
 * @param start - Moment type start date
 * @param end - Moment type end date
 * @param period - Current selected period
 */
export const computeAvailablePeriodsForDateRange = (start: DateValue, end: DateValue): TimeSeriesDatePeriod[] => {
  if (!start) return ['month']

  const differenceMs = (end || moment()).diff(start) // If no end is specified, end defaults to now
  const dayDifference = Math.round(differenceMs / 1000 / 60 / 60 / 24)

  if (dayDifference <= THIRTY_MIN_PERIOD_DAY_DIFFERENCE) return ['30m', 'hour']
  // We can't bind the available filters with bucket size since we could have an issue due to converting UTC time to the user's timezone
  if (dayDifference <= HOUR_PERIOD_DAY_DIFFERENCE) return ['hour', 'day']
  if (dayDifference <= DAY_PERIOD_DAY_DIFFERENCE) return ['day', 'week']
  if (dayDifference <= WEEK_PERIOD_DAY_DIFFERENCE) return ['week', 'month']

  return ['month']
}

/**
 * This function lets us keep all the logic regarding the bucket size and aggregation periods for time series items in a single place.
 * According to the duration defined, this function returns the time bucket and aggregation period in seconds
 *
 * This function implements logic to make sure we are using the least granular bucket possible according to the duration
 * provided and the minimum amount of data we want to graph
 *
 * @param durationInMs - Duration in ms of the inspection
 * @param minimumSeriesLength - Minimum desired length of the resulting timeseries
 * @param timeBucketMultipleMs - Minimum desired length of the resulting timeseries
 * @param end End date of range we want to get bucket size for.
 * @returns Either 30 or 1800  corresponding to the bucket size to use
 */
export const getBucketSize = (
  intervalMs: number | undefined,
  {
    isHistoricBatch,
    onlyCounts,
    minimumSeriesLength = 15,
  }: { isHistoricBatch?: boolean; onlyCounts?: boolean; minimumSeriesLength?: number } = {},
): { bucket_s: ReadTimeSeriesBody['bucket_s']; agg_s: number } => {
  // Used just for counting metrics
  if (onlyCounts || isNil(intervalMs)) {
    if (isNil(intervalMs)) {
      return { agg_s: SECONDS_IN_DAY, bucket_s: 1800 }
    }
    const intervalS = intervalMs / 1000
    return {
      agg_s: intervalMs <= DAY_MS ? DAY_MS / 1000 : Math.floor(intervalS / SECONDS_IN_DAY) * SECONDS_IN_DAY,
      bucket_s: 1800,
    }
  }

  let timeBucketMultipleS: number
  let bucket_s: ReadTimeSeriesBody['bucket_s']

  if (intervalMs <= HOUR_MS * 8) {
    bucket_s = 30
  } else {
    bucket_s = 1800
  }
  // we can only choose a timebucket multiple if we have access to the raw series, otherwise we default to the series we have available
  if (intervalMs <= HOUR_MS * 24 && !isHistoricBatch) {
    // Sensible defaults for our live inspection graphs
    if (intervalMs >= 12 * HOUR_MS) timeBucketMultipleS = SECONDS_IN_HOUR
    else if (intervalMs >= HOUR_MS) timeBucketMultipleS = SECONDS_IN_HOUR / 12
    else if (intervalMs >= HOUR_MS / 2) timeBucketMultipleS = SECONDS_IN_HOUR / 20
    else if (intervalMs >= HOUR_MS / 4) timeBucketMultipleS = SECONDS_IN_HOUR / 30
    else timeBucketMultipleS = SECONDS_IN_HOUR / 60
  } else {
    // If this is a historical batch we can use the 30s bucket, but we need to limit it to 10m agg_s
    const fixedBucketS = isHistoricBatch && bucket_s === 30 ? 600 : bucket_s
    timeBucketMultipleS = (fixedBucketS / 60) * (SECONDS_IN_HOUR / 60)
  }
  const intervalS = intervalMs / 1000
  const agg_s =
    timeBucketMultipleS * Math.floor(intervalS / timeBucketMultipleS / minimumSeriesLength) || timeBucketMultipleS

  return { bucket_s, agg_s }
}

export const getAnalyzeMetricsLabels = (filters: Record<string, any>) => {
  const params: { [key: string]: string } = {}
  const entries = Object.entries(filters) as [keyof typeof filters, string | undefined][]

  for (const [key, value] of entries) {
    if (value) {
      let paramsKey: keyof typeof filters | 'inspection_created_by_id' = key

      if (key === 'user_id') paramsKey = 'inspection_created_by_id'

      if (key === 'subsite_id' || key === 'subsite_type_id' || key === 'station_subtype_id') continue

      params[paramsKey] = value
    }
  }

  return {
    ...params,
    ...getSiteAndLinesBackendParams(filters, true),
  }
}

// We want to grab the more granular period available to be able to graph the metrics without needing to fetch them again when selecting a `bigger` period
// The function computeAvailablePeriodsForDateRange ensures that the first item on the list, will be the more granular one.
export const getAnalyzeAggS = (periodsAvailable: TimeSeriesDatePeriod[]) => {
  const minimumPeriod = periodsAvailable[0]
  // We want to pass our own agg_s according to the period selected
  let agg_s: number = 0
  if (minimumPeriod === '30m') agg_s = SECONDS_IN_HOUR / 2
  else if (minimumPeriod === 'hour') agg_s = SECONDS_IN_HOUR
  else if (minimumPeriod === 'day') agg_s = SECONDS_IN_DAY
  else if (minimumPeriod === 'week') agg_s = 7 * SECONDS_IN_DAY
  else if (minimumPeriod === 'month') agg_s = 30 * SECONDS_IN_DAY

  return agg_s
}
