import { Action } from 'rdx/actions'
import { RootState } from 'types'

/**
 * This branch of state tree stores information about the currently
 * authenticated user. This information is also written to local storage, and
 * this branch is hydrated from local storage, so that it survives browser
 * reloads.
 */
export default function reduce(
  state: RootState['auth'] = { auth: null, logoutAction: null },
  action: Action,
): RootState['auth'] {
  switch (action.type) {
    case 'AUTH_SET': {
      const { auth } = action.payload
      return { auth, logoutAction: null }
    }
    case 'AUTH_UNSET': {
      const logoutAction = action.payload

      return { auth: null, logoutAction }
    }
    default:
      return state
  }
}
