import React, { useCallback, useMemo, useRef, useState } from 'react'

import { uniq } from 'lodash'
import { batch, shallowEqual, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { getterKeys, service, useQuery, wsPaths } from 'api'
import { Button } from 'components/Button/Button'
import InspectionSummaryPrintModal from 'components/InspectionSummaryPrintModal/InspectionSummaryPrintModal'
import { dismiss, error, success, warning } from 'components/PrismNotification/PrismNotification'
import RobotsStatusListener from 'components/RobotsStatusListener'
import StreamListener from 'components/StreamListener'
import { IS_QA } from 'env'
import { CLOUD_FASTAPI_WS_URL } from 'env'
import {
  useAoiAndLabelInspectionMetrics,
  useData,
  useGetCurrentInspectionId,
  useResults,
  useTypedSelector,
} from 'hooks'
import StationDetail from 'pages/StationDetail/StationDetail'
import * as Actions from 'rdx/actions'
import {
  ApiStreamMessage,
  ItemExpanded,
  MutedNotifications,
  Outcome,
  RecipeUserSettings,
  Routine,
  StationDetailMode,
} from 'types'
import { getRobotInspectionId, log, parsePredictionMetadata, titleCase, ulidUuid } from 'utils'

import Styles from '../StationDetail/StationDetail.module.scss'
import InspectorData, { handleInspectionMessage } from './InspectorData'

interface Props {
  stationId: string
  mode: StationDetailMode
  historicInspectionId?: string
}

const PRINT_NOTIFICATIONS_LIMIT = 3

/**
 * Renders inspector screen, where the user can see items as they're scanned.
 *
 * @param stationId - Ids of station performing inspection
 * @param mode - Station detail mode to use, whether we're in overview, tools or timeline
 * @param historicInspectionId - In case user is inspecting a previous batch, we define this inspection id
 */
function StationDetailRoot({ stationId, historicInspectionId, mode }: Props) {
  const dispatch = useDispatch()
  const station = useQuery(getterKeys.station(stationId), () => service.getStation(stationId)).data?.data
  const robotIds = useMemo(() => {
    return station?.robots.map(robot => robot.id)
  }, [station])
  const me = useData(getterKeys.me())
  const mutedNotifications = useTypedSelector(state => state.localStorage.mutedNotifications)

  const [itemToPrint, setItemToPrint] = useState<ItemExpanded | null>(null)

  const activePrintNotifications = useRef([])

  const handleInspectionMessages = useCallback(
    (
      inspectionId: string,
      messages: ApiStreamMessage[],
      { isManual, userSettings }: { isManual: boolean; userSettings: RecipeUserSettings | undefined },
    ) => {
      batch(() => {
        messages.forEach(message => {
          if (message.payload.type === 'items-create' || message.payload.type === 'items-update') {
            handleItemPrintNotification({
              items: message.payload.payload,
              setItemToPrint,
              userSettings,
              activePrintNotifications: activePrintNotifications.current,
            })

            if (me) {
              handleToolResultErrors({
                items: message.payload.payload,
                userId: me.id,
                mutedNotifications,
                inspectionId,
                dispatch,
              })
            }
          }

          handleInspectionMessage(dispatch, message, inspectionId, { isManual })
        })
      })
    },
    [dispatch, me, mutedNotifications],
  )

  const historicInspection = useQuery(
    historicInspectionId ? getterKeys.inspection(historicInspectionId) : undefined,
    historicInspectionId ? () => service.getInspection(historicInspectionId) : undefined,
  ).data?.data

  // selectedRobotIds are the robot Ids being used in the current inspection
  const [selectedRobotIds, historicRoutinesByRobotId] = useMemo(() => {
    if (historicInspectionId && !historicInspection) return [undefined, undefined]
    if (!historicInspectionId || !historicInspection) return [robotIds, undefined]

    const historicRoutinesByRobotId: { [robotId: string]: Routine } = {}

    const robotIdsInCurrentInspection = (historicInspection.inspection_routines
      .flatMap(ir => ir.robot_id)
      .filter(id => id) || []) as string[]

    const robotIdsInInspection = uniq(robotIdsInCurrentInspection)

    if (historicInspection) {
      for (const inspectionRoutine of historicInspection.inspection_routines) {
        const routineInInspection = historicInspection.routines?.find(
          routine => routine.id === inspectionRoutine.routine_id,
        )

        if (routineInInspection && inspectionRoutine.robot_id)
          historicRoutinesByRobotId[inspectionRoutine.robot_id] = routineInInspection
      }
    }
    return [robotIdsInInspection, historicRoutinesByRobotId]
  }, [historicInspectionId, historicInspection, robotIds])

  const currentInspectionId = useGetCurrentInspectionId(selectedRobotIds)

  const liveInspection = useData(currentInspectionId ? getterKeys.inspection(currentInspectionId) : undefined)

  const routines = useData(
    currentInspectionId ? getterKeys.inspectionRoutines(currentInspectionId) : undefined,
  )?.results
  const manualTrigger = routines?.[0]?.settings?.camera_trigger_mode === 'manual'

  const inspectionForMetrics = historicInspection || liveInspection

  useAoiAndLabelInspectionMetrics({
    manualTrigger,
    inspection: inspectionForMetrics,
    shouldFetchLabelMetrics: mode === 'tools',
    isHistoricBatch: !!historicInspectionId,
  })

  // Get inspection IDs, ensure we don't rerender if we don't have new IDs
  const inspectionIds = useTypedSelector(state => {
    const inspectionIds: Set<string> = new Set()
    if (!selectedRobotIds) return

    for (const robotId of selectedRobotIds) {
      if (!robotId) continue
      const inspectionId = getRobotInspectionId(robotId, state) || undefined
      if (inspectionId) inspectionIds.add(inspectionId)
    }
    return [...inspectionIds]
  }, shallowEqual)

  return (
    <>
      {itemToPrint && <InspectionSummaryPrintModal item={itemToPrint} onClose={() => setItemToPrint(null)} />}
      {robotIds && <RobotsStatusListener robotIds={robotIds} />}

      {selectedRobotIds?.map(robotId => (
        <InspectorData key={robotId} robotId={robotId} historicInspectionId={historicInspectionId} />
      ))}

      {inspectionIds?.map(inspectionId => {
        return (
          <InspectionStreamListener
            key={inspectionId}
            inspectionId={inspectionId}
            handleInspectionMessages={handleInspectionMessages}
          />
        )
      })}

      <StationDetail
        mode={mode}
        historicInspectionId={historicInspectionId}
        station={station}
        selectedRobotIds={selectedRobotIds}
        historicRoutinesByRobotId={historicRoutinesByRobotId}
      />
    </>
  )
}

export default StationDetailRoot

const InspectionStreamListener = ({
  inspectionId,
  handleInspectionMessages,
}: {
  inspectionId: string
  handleInspectionMessages: (
    inspectionId: string,
    messages: ApiStreamMessage[],
    { isManual, userSettings }: { isManual: boolean; userSettings: RecipeUserSettings | undefined },
  ) => void
}) => {
  const routines = useResults(getterKeys.inspectionRoutines(inspectionId)) || []
  const isManual = routines.some(routine => routine.settings?.camera_trigger_mode === 'manual')
  const inspection = useData(getterKeys.inspection(inspectionId))
  const userSettings = inspection?.user_settings

  return (
    <StreamListener
      mode="message"
      connect={{ url: `${CLOUD_FASTAPI_WS_URL}${wsPaths.inspectionEvents(inspectionId)}` }}
      onMessages={(messages: ApiStreamMessage[]) =>
        handleInspectionMessages(inspectionId, messages, { isManual, userSettings })
      }
      params={{ past_ms: 1000 * 5 }}
    />
  )
}

const qaErrorExplanations: { [key: number]: string } = {
  1: 'Indicates the tool was not run because the system did not have sufficient processing power',
  2: "Indicates that the tool's parent errored (errors propagate to children)",
  3: 'Indicates that the Element called by the tool did not respond.',
  4: 'Indicates that response received from the Element called by the was not in-spec.',
  5: 'Indicates that the Element called by the tool returned a nonzero error code.',
  6: 'Indicates that an image reference to be passed to the tool timed out, preventing running the tool.',
}

const handleMuteNotification = ({
  mutedNotifications,
  userId,
  inspectionId,
  errorCode,
  dispatch,
}: {
  mutedNotifications: MutedNotifications
  userId: string
  inspectionId: string
  errorCode: number
  dispatch: Dispatch
}) => {
  const mutedNotificationsUpdate: MutedNotifications = { ...mutedNotifications }
  const mutedNotificationForUser: MutedNotifications[string] = mutedNotificationsUpdate[userId] || {}
  const mutedNotificationForUserInspection = mutedNotificationForUser[inspectionId] || { errorCodes: [] }
  mutedNotificationForUserInspection.errorCodes.push(errorCode)
  mutedNotificationForUser[inspectionId] = mutedNotificationForUserInspection
  mutedNotificationsUpdate[userId] = mutedNotificationForUser
  dispatch(Actions.localStorageUpdate({ key: 'mutedNotifications', data: mutedNotificationsUpdate }))

  dismiss('station-detail-message-handler-error')

  success({
    id: 'mute-sucess',
    title: 'Error Muted',
    description:
      'This error notification is now muted. You will not see it again during this batch. If other kinds of errors occur, you will still see them.',
    closable: true,
    duration: 5000,
    position: 'top-left',
    'data-testid': 'station-detail-root-mute-error-success',
  })
}

const handleToolResultErrors = ({
  items,
  userId,
  mutedNotifications,
  inspectionId,
  dispatch,
}: {
  items: ItemExpanded[]
  userId: string
  mutedNotifications: MutedNotifications
  inspectionId: string
  dispatch: Dispatch
}) => {
  items.forEach(item => {
    item.pictures
      .flatMap(picture => picture.tool_results)
      .forEach(tool_result => {
        /*
        Error codes for various failure modes of running a tool
        PERFORMANCE_LIMIT(1): Indicates the tool was not run because the system
            did not have sufficient processing power
        PARENT_ERROR(2): Indicates that the tool's parent errored (errors
            propagate to children)
        NO_RESPONSE(3): Indicates that the Element called by the tool did not
            respond.
        INVALID_RESPONSE(4): Indicates that response received from the Element called
            by the was not in-spec.
        RUNTIME(5): Indicates that the Element called by the tool returned a
            nonzero error code.
        INVALID_REFERENCE(6): Indicates that an image reference to be passed to the
            tool timed out, preventing running the tool.
        MISSING_TOOL_JOB_RESULTS(7): Indicates that an item took too long to recieve its tool results,
            and might be incomplete
      */
        const errorCode =
          parsePredictionMetadata(tool_result.prediction_metadata?.vision_processing_err_code, 'number') || 0

        const isMuted = mutedNotifications[userId]?.[inspectionId]?.errorCodes?.includes(errorCode)

        if (isMuted || errorCode === 0 || errorCode === 2) return

        log('src/components/ToolResultErrorHandler.tsx', 'alertPerfLimit', `CLASSIFICATION ERROR. CODE: ${errorCode}`)

        // cases 2-6 are handled by this default
        let title = 'Inspection Error'
        let description =
          'An error occurred which may affect your inspection results. Stop this batch and start a new one. If the problem persists, contact support@elementaryrobotics.com.'

        if (errorCode === 1) {
          title = 'Performance Limit Error'
          description =
            'Your inspection has hit a performance limit. Some of your tools will not run as expected and will come back as Unknown instead of Pass or Fail. To improve performance, try using fewer tools in this recipe. Also consider reducing your trigger interval.'
        }

        title += ` (${errorCode})`

        if (IS_QA) {
          description += ` Error: ${qaErrorExplanations[errorCode]}`
        }

        error({
          id: 'station-detail-message-handler-error',
          title,
          description,
          position: 'top-left',
          duration: 0,
          closable: false,
          children: (
            <div className={Styles.notificationButtons} data-testid="station-detail-root-error">
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  dismiss('station-detail-message-handler-error')
                }}
                data-testid="station-detail-root-error-dismiss"
              >
                Dismiss
              </Button>
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  handleMuteNotification({ mutedNotifications, userId, inspectionId, errorCode, dispatch })
                }}
                data-testid="station-detail-root-error-mute"
              >
                Mute
              </Button>
            </div>
          ),
        })
      })
  })
}

const handleItemPrintNotification = ({
  items,
  setItemToPrint,
  userSettings,
  activePrintNotifications,
}: {
  items: ItemExpanded[]
  setItemToPrint: React.Dispatch<React.SetStateAction<ItemExpanded | null>>
  userSettings: RecipeUserSettings | undefined
  activePrintNotifications: { toastId: string; item: ItemExpanded }[]
}) => {
  items.forEach(item => {
    // We dont show the print notification if the outcome does not match any routine workflow setting
    for (const outcome of ['pass', 'fail', 'unknown'] as Outcome[]) {
      const promptKey = `prompt_print_${outcome}` as const
      if (item.calculated_outcome === outcome && !userSettings?.workflows?.[promptKey]) {
        return
      }
    }

    // items can come in more than once first when created then when updated
    if (activePrintNotifications.find(notification => notification.item.id === item.id)) {
      return
    }

    if (activePrintNotifications.length === PRINT_NOTIFICATIONS_LIMIT) {
      // if the notification limit is reached we just push items from one notification to the next one
      // instead of creating a new one.
      pushItemsToNextNotification(activePrintNotifications, item)
    } else {
      const newId = ulidUuid()
      activePrintNotifications.unshift({ item, toastId: newId })
    }

    // loop over all current notifications, if a notification is already rendered, its content just
    // get updated, if not, it is created.
    activePrintNotifications.forEach(notification => {
      printNotification({
        id: notification.toastId,
        item: notification.item,
        setItemToPrint,
        activePrintNotifications,
      })
    })
  })
}

const printNotification = ({
  id,
  item,
  activePrintNotifications,
  setItemToPrint,
}: {
  id: string
  item: ItemExpanded
  activePrintNotifications: { toastId: string; item: ItemExpanded }[]
  setItemToPrint: React.Dispatch<React.SetStateAction<ItemExpanded | null>>
}) => {
  const removeActiveNotification = () => {
    const notificationIndex = activePrintNotifications.findIndex(prev => prev.item.id === item.id)
    if (notificationIndex >= 0) activePrintNotifications.splice(notificationIndex, 1)
  }
  warning({
    id,
    title: 'Print required',
    description: (
      <span>
        The result for item {item.serial_number} was{' '}
        <span className={Styles.highlightedDescription}>{titleCase(item.calculated_outcome)}</span>. Please print a
        summary of its inspection.
      </span>
    ),
    children: (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          setItemToPrint(item)
          dismiss(id)
          removeActiveNotification()
        }}
      >
        Print
      </Button>
    ),
    position: 'top-left',
    duration: 0,
    handleClose: () => {
      removeActiveNotification()
    },
  })
}

const pushItemsToNextNotification = (
  activePrintNotifications: { toastId: string; item: ItemExpanded }[],
  newItem: ItemExpanded,
) => {
  for (let i = PRINT_NOTIFICATIONS_LIMIT - 1; i >= 0; i--) {
    let assignItem = activePrintNotifications[i - 1]?.item
    if (!assignItem) assignItem = newItem
    const activeNotification = activePrintNotifications[i]
    if (activeNotification) activeNotification.item = assignItem
  }
}
