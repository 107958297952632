import React, { useMemo } from 'react'

import { Controller, useForm } from 'react-hook-form'

import { getterKeys, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { Modal } from 'components/PrismModal/PrismModal'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { useData } from 'hooks'
import { PlcTag, PlcTagLayout, PlcTags, RobotTagLayout } from 'types'
import { matchRole, titleCase } from 'utils'

import Styles from './PlcControl.module.scss'

const AVAILABLE_COLORS = ['red', 'green', 'blue', 'yellow', 'grey']

export type AddTagModalProps = {
  onClose: () => any
  robotId: string
  onDelete?: (index: number) => any
  onUpdateSettings: (data: RobotTagLayout) => any
  index?: number
  data?: PlcTagLayout
  disabled?: boolean
}
/**
 * Renders the modal for editing or creating all fields of a layout item. the enable tag is an
 * optional field, all others are required fields. This modal uses the react-hook-form library.
 *
 * @param onClose - Handler to close the current modal
 * @param robotId - The currently selected robot
 * @param onDelete - Handler for when we want to delete the current layout item
 * @param onUpdateSettings - Handler for creating or updating settings of the current layout item
 * @param index - Index of current layout item
 * @param data - Optional data if we're updating rather than creating an item
 */
function AddTagModal({ onClose, robotId, onDelete, onUpdateSettings, index, data, disabled }: AddTagModalProps) {
  const me = useData(getterKeys.me())

  const plcTags = useQuery(
    getterKeys.plcTags(robotId),
    async () => {
      // `read_all_tags` has a slightly different format as `read_tags`, it returns an array instead of a dict
      const res = await service.atomSendCommandExtractData<PlcTag[]>('plc', 'read_all_tags', robotId, {
        command_args: null,
      })

      if (res.type === 'success') {
        const plcTags: PlcTags = {}

        res.data?.forEach(tag => {
          plcTags[tag.tag_name] = tag
        })

        // We need to create a copy of the response in order to cast PlcTag[] into PlcTags
        const copyOfRes = {
          ...res,
          data: plcTags,
          queryData: {
            ...res.queryData,
            data: plcTags,
          },
        }
        return copyOfRes
      }
      return res
    },
    {
      updater: (oldData, newData) => {
        // We must update the keys from the previous response, we don't want to lose data
        const mergedData = { ...oldData?.data, ...newData?.data }
        return { ...newData, data: mergedData }
      },
    },
  ).data?.data

  // Converts dictionary of tags into array of tags
  const plcTagsArray = useMemo(() => {
    if (!plcTags) return []

    return Object.values(plcTags)
  }, [plcTags])

  const handleSave = async () => {
    const valid = await trigger()
    if (!valid) return 'invalid'

    const { type, label, tag, enable_tag, color, min_user_role } = getValues()
    /* Saving the data is handled by the parent because this component isn't aware of the whole
     * layout, it's only aware of the current layout item.
     */
    onUpdateSettings({ type, label, tag, enable_tag, color, min_user_role, index } as RobotTagLayout)
  }

  const handleDelete = async () => {
    if (!onDelete || !index) return

    onDelete(index)
  }

  const defaultValues = getDefaultFormValues(data)

  const {
    formState: { isDirty, isValid },
    control,
    getValues,
    trigger,
    errors,
  } = useForm({ defaultValues, mode: 'onChange' })

  return (
    <Modal
      id="add-tag"
      size="largeSimpleForm"
      header="Add Card"
      onClose={onClose}
      extraButtons={
        data && (
          <Button size="small" type="tertiary" onClick={handleDelete}>
            Delete
          </Button>
        )
      }
      okText={data ? 'Update' : 'Add'}
      onOk={handleSave}
      disableSave={!isDirty || !isValid}
    >
      <div className={Styles.itemContainer}>
        <div className={Styles.label}>type</div>
        <Controller
          control={control}
          name="type"
          render={({ onChange, value }) => {
            return (
              <PrismSelect
                value={value}
                onChange={onChange}
                placeholder="Select"
                disabled={disabled}
                options={[{ value: 'input' }, { value: 'button' }, { value: 'toggle' }, { value: 'text' }]}
              />
            )
          }}
        />
      </div>
      <Controller
        control={control}
        name="label"
        rules={{ required: 'Label is required' }}
        render={props => (
          <PrismInput
            disabled={disabled}
            {...props}
            wrapperClassName={Styles.itemContainer}
            label="Label"
            placeholder="Label"
            size="small"
            errors={errors}
          />
        )}
      />
      <div className={Styles.itemContainer}>
        <div className={Styles.label}>Tag (To PLC)</div>
        <Controller
          control={control}
          name="tag"
          rules={{ required: true }}
          render={({ onChange, value }) => (
            <PrismSelect
              className={Styles.modalFilter}
              popupClassName={Styles.dropdownContainer}
              onChange={onChange}
              value={value}
              disabled={disabled}
              options={plcTagsArray.map(tag => ({ key: tag.tag_name, value: tag.tag_name }))}
            />
          )}
        />
      </div>
      <div className={Styles.itemContainer}>
        <div className={Styles.label}>Enable Tag (From PLC)</div>
        <Controller
          control={control}
          name="enable_tag"
          render={({ onChange, value }) => (
            <PrismSelect
              className={Styles.modalFilter}
              popupClassName={Styles.dropdownContainer}
              onChange={onChange}
              value={value}
              disabled={disabled}
              options={plcTagsArray
                .filter(tag => tag.tag_type === 'BOOL')
                .map(tag => ({ key: tag.tag_name, value: tag.tag_name }))}
            />
          )}
        />
      </div>

      <div className={Styles.itemContainer}>
        <div className={Styles.label}>Users</div>
        <Controller
          control={control}
          name="min_user_role"
          render={({ onChange, value }) => (
            <PrismSelect
              disabled={disabled}
              value={value}
              onChange={onChange}
              placeholder="Select"
              className={Styles.modalFilter}
              popupClassName={Styles.dropdownContainer}
              options={[
                { value: 'owner', title: 'admin only', disabled: !matchRole(me, 'owner') },
                { value: 'manager', title: 'editor and above', disabled: !matchRole(me, 'manager') },
                { value: 'inspector', title: 'operator and above' },
                { value: 'member', title: 'viewer and above' },
              ]}
            />
          )}
        />
      </div>
      <div className={Styles.itemContainer}>
        <div className={Styles.label}>Color</div>
        <Controller
          control={control}
          name="color"
          render={({ onChange, value }) => (
            <PrismSelect
              onChange={onChange}
              value={value}
              placeholder="Select"
              className={Styles.modalFilter}
              popupClassName={Styles.dropdownContainer}
              options={AVAILABLE_COLORS.map(color => ({
                key: color,
                value: color,
                content: (
                  <>
                    <span className={`${Styles.colorBox} ${Styles[`${color}Box`]}`} />
                    {titleCase(color)}
                  </>
                ),
              }))}
            />
          )}
        />
      </div>
    </Modal>
  )
}

const getDefaultFormValues = (data?: RobotTagLayout) => {
  if (!data)
    return {
      type: 'text',
      label: '',
      tag: '',
      enable_tag: '',
      color: 'grey',
      min_user_role: 'owner',
    }

  return {
    type: data.type,
    label: data.label,
    tag: data.tag,
    enable_tag: data.enable_tag,
    color: data.color,
    min_user_role: data.min_user_role,
  }
}

export default AddTagModal
