import { useEffect } from 'react'
import React from 'react'

import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'

import { getterKeys, service, useQuery } from 'api'
import { dismiss, warning } from 'components/PrismNotification/PrismNotification'
import { useAllToolLabels, useConnectionStatus, useHeap, useIsColocated, useStation } from 'hooks'
import paths from 'paths'
import { robotCapabilitesSet } from 'rdx/actions'
import typedStore, { TypedStore } from 'rdx/store'
import { RobotDiscoveriesById, User } from 'types'
import { matchRole } from 'utils'

import { Button } from './Button/Button'
import NotificationsListener from './NotificationsListener'
import RobotsStatusListener from './RobotsStatusListener'

const PRISM_IS_OFFLINE_NOTIFICATION = 'prism_is_offline_notification'

/**
 * Renders nothing. Use this for setting global state in Redux, e.g. by
 * subscribing to certain changes, setting up app wide logic that shouldn't be active on the login screen,
 * or by fetching useful data that requires the auth token to fetch. When the app mounts and user logs in.
 */
function OnLogin({ me }: { me: User }) {
  useAllToolLabels()
  useHeap()
  const { station: colocatedStation } = useStation()
  const connectionStatus = useConnectionStatus()
  const history = useHistory()
  const { isColocated } = useIsColocated()
  const dispatch = useDispatch()

  // When user is offline, we want to block refreshing and back and forward navigation as nothing would load
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (
        e.key === 'F5' ||
        (e.key === 'r' && e.ctrlKey) ||
        (e.key === 'R' && e.ctrlKey) ||
        (e.key === 'ArrowLeft' && e.altKey) ||
        (e.key === 'ArrowRight' && e.altKey)
      )
        return e.preventDefault()
    }

    if (connectionStatus === 'offline') window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [connectionStatus])

  useQuery(getterKeys.organization(), service.organization, {
    refetchKey: me.organization_id,
    intervalMs: 15 * 60 * 1000,
  })
  const stations = useQuery(getterKeys.stations('all-with-robots'), () => service.getStations({ has_robots: true }))
    .data?.data.results

  useEffect(() => {
    if (stations) {
      const robotIds = stations.flatMap(station => station.robots.flatMap(robot => robot.id))
      getAndSetRobotCapabilities(dispatch, robotIds)
    }
  }, [dispatch, stations])

  // Shows a persistent notification when the user tries to navigate while offline
  useEffect(() => {
    if (!isColocated) return
    const isAtStationScreen = history.location.pathname.startsWith('/station/')

    if (connectionStatus === 'offline') {
      warning({
        id: PRISM_IS_OFFLINE_NOTIFICATION,
        title: 'Prism is offline',
        description: 'Only inspections are available while Prism is in offline mode.',
        children: !isAtStationScreen ? (
          <Button
            type="secondary"
            size="small"
            onClick={() => {
              dismiss(PRISM_IS_OFFLINE_NOTIFICATION)
              history.push(paths.stationDetail('overview', colocatedStation?.id))
            }}
          >
            Go to Inspect
          </Button>
        ) : undefined,
        duration: !isAtStationScreen ? 0 : 10000,
      })
      return
    }
    // When back online or if navigating into inspection screen, remove notification
    dismiss(PRISM_IS_OFFLINE_NOTIFICATION)
  }, [history, history.location, connectionStatus, isColocated, colocatedStation?.id])

  return (
    <>
      {/* Global organization notifications listener */}
      {matchRole(me, 'manager') && <NotificationsListener />}
      {/* Global colocated robots listener */}
      <RobotsStatusListener />
    </>
  )
}

export default OnLogin

export async function getAndSetRobotCapabilities(
  dispatch: Dispatch,
  robotIds: string[],
  store: TypedStore = typedStore,
) {
  const state = store.getState()
  // We start with the current values
  const robotDiscoveriesById: RobotDiscoveriesById = { ...state.robotDiscoveriesById }

  const res = await service.atomGetRobotDiscovery(robotIds)
  if (res.type === 'success') {
    robotIds.forEach(robotId => {
      // We only update the value of the fetched robot discoveries, the rest will remain the same
      robotDiscoveriesById[robotId] = { basler: res.data[robotId]?.basler }
    })
  }
  if (!isEqual(robotDiscoveriesById, state.robotDiscoveriesById)) dispatch(robotCapabilitesSet(robotDiscoveriesById))
}
