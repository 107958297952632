import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Table } from 'antd'
import { groupBy } from 'lodash'

import { getterKeys, service, useQuery } from 'api'
import { BackTop, useBackTopWithClassName } from 'components/BackTop/BackTop'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismGraphProgressBar } from 'components/PrismGraphProgressBar/PrismGraphProgressBar'
import { PrismGraphWrapper } from 'components/PrismGraphWrapper/PrismGraphWrapper'
import { PrismNavArrowIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { useAllToolLabels, useAnalyticsQueryFilters, useAnalyzeItemMetrics, useLabelMetrics } from 'hooks'
import CountGraph from 'pages/StationDetail/Components/CountGraph'
import { YieldGraph } from 'pages/StationDetail/Components/YieldGraph'
import { AnalyzeDefect, Component } from 'types'
import {
  calculatePercentage,
  combineOutcomeCounts,
  convertAllFiltersToBackendQueryParams,
  getDisplaySeverity,
  getDisplayStationLocation,
  getLabelName,
  renderLargeNumber,
  seriesFillGapsRtsDatePeriod,
  titleCase,
  truncateSeriesTimestampsRtsDatePeriod,
} from 'utils'
import { PDF_TABLE_LIMIT, TABLE_HEADER_HEIGHT } from 'utils/constants'

import Styles from './Analyze.module.scss'
import {
  ANALYZE_VERTICAL_PADDING,
  AnalyzeTableMetrics,
  AnalyzeWithGalleryProps,
  dummyData,
  forceZeroPosition,
  getAnalyzeDefects,
  getAnalyzeTableColumns,
  ObjectWithMetrics,
  useVirtualizedTable,
} from './AnalyzeBase'
import { AnalyzeEmptyState } from './AnalyzeEmptyState'
import AnalyzeFilters from './AnalyzeFilters'
import { AnalyzeGallery } from './AnalyzeGallery'

export type ComponentWithMetrics = ObjectWithMetrics<Component>

export const AnalyzeProducts = ({
  isGalleryExpanded,
  setIsGalleryExpanded,
  setPdfData,
  galleryTransitionInProgress,
  setGalleryTransitionInProgress,
  tableRerenderKey,
}: AnalyzeWithGalleryProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const { allToolLabels } = useAllToolLabels()

  const [filters, { onUpdateFilters, expanded }] = useAnalyticsQueryFilters({ tab: 'products' })

  const { period, start, end, station_id, search, recipe_id, inspection_id, component_id, site_id, subsite_id } =
    filters
  const { labelMetrics, isFetchingLabelMetrics } = useLabelMetrics({
    paramFilters: filters,
  })

  const filtersKey = `${station_id}${search}${recipe_id}${inspection_id}`

  const products = useQuery(
    getterKeys.analyticsProducts(),
    async () => {
      setIsLoading(true)
      const params = convertAllFiltersToBackendQueryParams({
        name: search,
        station_id,
        inspection_id,
        component_id,
        recipe_id__in: recipe_id,
        site_id,
        subsite_id,
      })

      const res = await service.getComponents({
        ...params,
        station_id__in: params.station_id,
        site_id__in: params.site_id,
        subsite_id__in: params.subsite_id,
      })
      setIsLoading(false)
      return res
    },
    { refetchKey: filtersKey },
  ).data?.data.results

  // If we apply filters that don't include the current component, we won't have enough information to display basic component data,
  // so we must fetch the expanded component explicitly
  const expandedComponentFallback = useQuery(
    expanded ? getterKeys.analyticsProduct(expanded) : undefined,
    expanded ? () => service.getComponent(expanded) : undefined,
  ).data?.data

  const expandedComponent = products?.find(product => product.id === expanded) || expandedComponentFallback

  const { itemMetrics, isFetching: isFetchingMetrics } = useAnalyzeItemMetrics('products')

  const showLoader = isFetchingMetrics || isLoading || isFetchingLabelMetrics

  const productsWithMetrics: ComponentWithMetrics[] | undefined = useMemo(() => {
    const typedPeriod = period === '30m' ? 'minute' : period || 'day'
    const numPeriods = period === '30m' ? 30 : 1
    if (!itemMetrics || !labelMetrics || !allToolLabels || !products) return

    const groupedByComponentId = groupBy(itemMetrics, result => result.labels.component_id)
    const labelMetricsGrouped = groupBy(labelMetrics, result => result.labels.component_id)

    const groups = Object.entries(groupedByComponentId).map(([productId, results]) => {
      const productIds = products?.map(product => product.id)

      const truncatedResults = results
        .filter(results => results.labels.component_id && productIds?.includes(results.labels.component_id))
        .map(result => ({
          ...result,
          entries: truncateSeriesTimestampsRtsDatePeriod(result.entries, typedPeriod),
        }))
      const combinedSeries = combineOutcomeCounts(truncatedResults)
      const filledSeries = seriesFillGapsRtsDatePeriod(combinedSeries, typedPeriod, {
        end: end || undefined,
        start: start || undefined,
        numPeriods,
      })

      const yieldSeries = filledSeries.map(data => ({
        ...data,
        passYield: calculatePercentage(data.pass, data.count),
        failYield: calculatePercentage(data.fail, data.count),
        unknownYield: calculatePercentage(data.unknown, data.count),
      }))

      let count = 0
      let passed = 0

      for (const item of filledSeries) {
        count += item.count || 0
        passed += item.pass || 0
      }

      const curYield = calculatePercentage(passed, count)

      const product = products?.find(product => product.id === productId)

      const productLabelMetrics = labelMetricsGrouped?.[productId]
      const groupedLabelMetricsByLabelId = groupBy(productLabelMetrics, result => result.labels.tool_label_id)
      const defects: AnalyzeDefect[] = getAnalyzeDefects({ groupedLabelMetricsByLabelId, allToolLabels, count })

      return {
        ...product,
        itemsInspected: count,
        yield: curYield,
        defects,
        series: yieldSeries,
      } as ComponentWithMetrics
    })

    return groups.filter(g => g.itemsInspected)
  }, [allToolLabels, end, itemMetrics, labelMetrics, period, products, start])

  const expandedProductWithMetrics = expanded
    ? productsWithMetrics?.find(products => products.id === expanded)
    : undefined

  const mostDefectiveStationIdsWithYield = useMemo(() => {
    if (!expanded) return []
    if (!expandedProductWithMetrics) return
    const groupedByStationId = groupBy(
      itemMetrics?.filter(
        result => result.labels.station_id && result.labels.component_id === expandedProductWithMetrics.id,
      ),
      result => result.labels.station_id,
    )

    const mostDefectiveStationIdsWithYield = Object.entries(groupedByStationId)
      .filter(([stationId]) => stationId !== 'None')
      .map(([stationId, results]) => {
        const combinedSeries = combineOutcomeCounts(results)

        let count = 0
        let failed = 0

        for (const item of combinedSeries) {
          count += item.count || 0
          failed += item.fail || 0
        }

        const failYield = calculatePercentage(failed, count)

        return {
          stationId,
          failYield,
        }
      })

    return mostDefectiveStationIdsWithYield
  }, [expanded, expandedProductWithMetrics, itemMetrics])

  const stationsRes = useQuery(
    getterKeys.stations('all-with-robots'),
    () => service.getStations({ has_robots: true }),
    { noRefetch: true },
  )

  const failedStationsWithFailYield = useMemo(() => {
    const stations = stationsRes.data?.data.results

    return stations
      ?.map(station => {
        return {
          ...station,
          failYield: mostDefectiveStationIdsWithYield?.find(grp => grp.stationId === station.id)?.failYield,
        }
      })
      .filter(station => station.failYield)
      .sort((a, b) => b.failYield! - a.failYield!)
  }, [stationsRes.data?.data.results, mostDefectiveStationIdsWithYield])

  const mostCommonDefects: AnalyzeDefect[] | undefined = useMemo(() => {
    if (!expandedProductWithMetrics) return
    const labelMetricsGrouped = groupBy(labelMetrics, result => result.labels.component_id)
    const productLabelMetrics = labelMetricsGrouped?.[expandedProductWithMetrics.id]

    const groupedLabelMetricsByLabelId = groupBy(productLabelMetrics, result => result.labels.tool_label_id)

    return getAnalyzeDefects({
      groupedLabelMetricsByLabelId,
      allToolLabels,
      count: expandedProductWithMetrics.itemsInspected,
    })
  }, [allToolLabels, expandedProductWithMetrics, labelMetrics])

  const columns = useMemo(
    () => getAnalyzeTableColumns({ title: 'Product', period, isGalleryExpanded, initialSortByYield: true }),
    [period, isGalleryExpanded],
  )

  // This effect sets the chart or table data to be used on the PDF
  useEffect(() => {
    if (!productsWithMetrics) {
      setPdfData(undefined)
      return
    }

    if (expandedProductWithMetrics)
      setPdfData({
        type: 'charts',
        chartContent: {
          yield: {
            value: expandedProductWithMetrics.yield,
            data: expandedProductWithMetrics.series,
          },
          count: {
            value: expandedProductWithMetrics.itemsInspected,
            data: expandedProductWithMetrics.series,
          },
          stationsWithFailYield: {
            data: failedStationsWithFailYield,
          },
          mostCommonDefects: {
            data: mostCommonDefects,
          },
        },
      })
    else {
      const cappedProducts = productsWithMetrics.slice(0, PDF_TABLE_LIMIT)

      setPdfData({
        type: 'yieldAndCount',
        title: 'product',
        rows: cappedProducts.map(prod => ({
          name: prod.name,
          yield: prod.yield,
          count: prod.itemsInspected,
          series: prod.series,
          defects: prod.defects,
        })),
      })
    }
  }, [expandedProductWithMetrics, failedStationsWithFailYield, mostCommonDefects, productsWithMetrics, setPdfData])

  useEffect(() => {
    return () => {
      // Reset PDF data when component unmounts
      setPdfData(undefined)
    }
  }, [setPdfData])

  useBackTopWithClassName('ant-table-body')
  const handleClickRow = useCallback(
    (row: AnalyzeTableMetrics) => {
      onUpdateFilters({ expanded: row.id })
    },
    [onUpdateFilters],
  )
  const { columnsWithWidths, renderVirtualTable, tableContainerRef, tableHeight } = useVirtualizedTable(columns, {
    onClick: handleClickRow,
  })

  const parentContainerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)

  return (
    <section className={Styles.analyzeMain} ref={parentContainerRef}>
      <PrismContainer
        containerStyle={{ paddingTop: ANALYZE_VERTICAL_PADDING }}
        titleRef={titleRef}
        title={expandedComponent?.name || 'Products'}
        className={Styles.analyzeMainBody}
        headerActionsClassName={expandedComponent ? Styles.headerExpandedLayout : Styles.headerTitle}
        headerTitleAction={
          expandedComponent && (
            <IconButton
              data-testid="analyze-products-go-back"
              onClick={() => {
                onUpdateFilters({ expanded: undefined })
              }}
              icon={<PrismNavArrowIcon direction="left" />}
              type="secondary"
              size="small"
              isOnTop
              className={Styles.iconPosition}
            />
          )
        }
        actions={<AnalyzeFilters tab="products" data-testid="analyze-products-filters" />}
        data-testid="analyze-products-container"
      >
        <div className={Styles.tableWrapper} ref={tableContainerRef} key={tableRerenderKey}>
          <BackTop scrollContainerClassName="ant-table-body" />
          {!expanded && tableHeight && (
            <Table
              data-testid="analyze-products-table"
              dataSource={productsWithMetrics}
              columns={columnsWithWidths}
              className={`${Styles.tableContainer} ${Styles.tableHeaderForcedHeight} ${
                isGalleryExpanded ? Styles.adjustCellPadding : ''
              }`}
              rowClassName={Styles.tableRow}
              locale={{ emptyText: !showLoader ? <AnalyzeEmptyState tab="products" /> : <div></div> }}
              pagination={false}
              loading={{
                spinning: !productsWithMetrics || showLoader,
                wrapperClassName: Styles.tableSpinnerWrapper,
                indicator: <PrismLoader className={Styles.loaderPosition} />,
              }}
              scroll={{ y: tableHeight - TABLE_HEADER_HEIGHT }}
              // We must send undefined so that the table component falls back on its default empty state
              components={productsWithMetrics?.length ? { body: renderVirtualTable } : undefined}
            />
          )}

          {expanded && (
            <>
              <div
                className={`${Styles.analyzeGridContainer} ${isGalleryExpanded ? Styles.galleryIsExpanded : ''}`}
                data-testid="analyze-products-graphs-container"
              >
                <PrismGraphWrapper
                  graphName="Yield"
                  graphValue={expandedProductWithMetrics ? `${expandedProductWithMetrics.yield.toFixed(1)}%` : '0.0%'}
                  className={forceZeroPosition(expandedProductWithMetrics) ? Styles.forceHideTooltip : ''}
                >
                  <YieldGraph
                    chartWidth="98%"
                    chartHeight={112}
                    yieldSeries={expandedProductWithMetrics?.series || dummyData({ start, end })}
                    period={period}
                    start={start}
                    end={end}
                    mode="metrics"
                    syncId="productsDrillDown"
                    allowEscapeViewBox={{ y: true }}
                  />
                </PrismGraphWrapper>

                <PrismGraphWrapper
                  graphName="Items Inspected"
                  graphValue={
                    expandedProductWithMetrics
                      ? renderLargeNumber(expandedProductWithMetrics.itemsInspected, 1000)
                      : '0'
                  }
                  className={
                    forceZeroPosition(expandedProductWithMetrics)
                      ? `${Styles.forceHideTooltip} ${Styles.forceZeroPosition}`
                      : ''
                  }
                >
                  <CountGraph
                    chartWidth="98%"
                    chartHeight={112}
                    graphSeries={expandedProductWithMetrics?.series || dummyData({ start, end })}
                    mode="metrics"
                    period={period}
                    start={start}
                    end={end}
                    syncId="productsDrillDown"
                    allowEscapeViewBox={{ y: true }}
                  />
                </PrismGraphWrapper>

                <PrismGraphWrapper graphName="Most Common Defects" graphCaption="% of items with defect">
                  <div className={Styles.graphBodyContainer}>
                    {mostCommonDefects?.map(defect => (
                      <PrismGraphProgressBar
                        key={defect.toolLabel.id}
                        type="fail"
                        graphName={
                          <PrismResultButton
                            severity={getDisplaySeverity(defect.toolLabel)}
                            value={getLabelName(defect.toolLabel)}
                            type="pureWhite"
                            className={Styles.defectItemTitle}
                          />
                        }
                        toolTipTitle={titleCase(getLabelName(defect.toolLabel))}
                        graphPercentage={defect.percentage.toFixed(1) || 0}
                        graphCount={defect.defectCount}
                      />
                    ))}

                    {(!mostCommonDefects || mostCommonDefects.length === 0) && (
                      <div className={Styles.graphContainerEmptyState}>No defects match your filters</div>
                    )}
                  </div>
                </PrismGraphWrapper>

                <PrismGraphWrapper
                  graphName="Most Defective Stations"
                  graphCaption="% of items with defect per station"
                >
                  <div className={Styles.graphBodyContainer}>
                    {failedStationsWithFailYield?.map(station => (
                      <PrismGraphProgressBar
                        key={station.id}
                        type="fail"
                        graphName={station.name}
                        tooltipSubtitle={getDisplayStationLocation(station.site_name, station.belongs_to?.name)}
                        graphPercentage={station.failYield?.toFixed(1) || 0}
                      />
                    ))}

                    {(!failedStationsWithFailYield || failedStationsWithFailYield.length === 0) && (
                      <div className={Styles.graphContainerEmptyState}>No stations match your filters</div>
                    )}
                  </div>
                </PrismGraphWrapper>
              </div>
            </>
          )}
        </div>
      </PrismContainer>
      <AnalyzeGallery
        isGalleryExpanded={isGalleryExpanded}
        setIsGalleryExpanded={setIsGalleryExpanded}
        parentContainerRef={parentContainerRef}
        titleRef={titleRef}
        tab={'products'}
        filters={filters}
        transitionInProgress={galleryTransitionInProgress}
        setTransitionInProgress={setGalleryTransitionInProgress}
      />
    </section>
  )
}
