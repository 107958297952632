import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { groupBy } from 'lodash'

import { getterKeys, service, useQuery } from 'api'
import { BackTop, useBackTopWithClassName } from 'components/BackTop/BackTop'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismGraphProgressBar } from 'components/PrismGraphProgressBar/PrismGraphProgressBar'
import { PrismGraphWrapper } from 'components/PrismGraphWrapper/PrismGraphWrapper'
import { PrismNavArrowIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { useAllToolLabels, useAnalyticsQueryFilters, useAnalyzeItemMetrics, useLabelMetrics } from 'hooks'
import CountGraph from 'pages/StationDetail/Components/CountGraph'
import { YieldGraph } from 'pages/StationDetail/Components/YieldGraph'
import { AnalyzeDefect, Station } from 'types'
import {
  calculatePercentage,
  combineOutcomeCounts,
  convertAllFiltersToBackendQueryParams,
  getDisplaySeverity,
  getDisplayStationLocation,
  getLabelName,
  renderLargeNumber,
  seriesFillGapsRtsDatePeriod,
  titleCase,
  truncateSeriesTimestampsRtsDatePeriod,
} from 'utils'
import { PDF_TABLE_LIMIT } from 'utils/constants'

import Styles from './Analyze.module.scss'
import {
  ANALYZE_VERTICAL_PADDING,
  AnalyzeTableMetrics,
  AnalyzeWithGalleryProps,
  dummyData,
  forceZeroPosition,
  getAnalyzeDefects,
  getAnalyzeTableColumns,
  ObjectWithMetrics,
  useVirtualizedTable,
} from './AnalyzeBase'
import { AnalyzeEmptyState } from './AnalyzeEmptyState'
import AnalyzeFilters from './AnalyzeFilters'
import { AnalyzeGallery } from './AnalyzeGallery'

export type StationWithMetrics = ObjectWithMetrics<Station>

export const AnalyzeStations = ({
  isGalleryExpanded,
  setIsGalleryExpanded,
  setPdfData,
  galleryTransitionInProgress,
  setGalleryTransitionInProgress,
  tableRerenderKey,
}: AnalyzeWithGalleryProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const [filters, { onUpdateFilters, expanded, filterKey }] = useAnalyticsQueryFilters({ tab: 'stations' })

  const { period, start, end, station_id, component_id, search, recipe_id, site_id, subsite_id } = filters

  const allLabels = useAllToolLabels()
  const { allToolLabels } = allLabels

  const { labelMetrics, isFetchingLabelMetrics } = useLabelMetrics({
    paramFilters: filters,
  })

  const stations = useQuery(
    getterKeys.analyticsStations(),
    async () => {
      setIsLoading(true)
      const params = convertAllFiltersToBackendQueryParams({
        component_id,
        station_id,
        search,
        recipe_id__in: recipe_id,
        site_id,
        belongs_to: subsite_id,
      })

      const res = await service.getStations(params)
      setIsLoading(false)
      return res
    },
    {
      refetchKey: filterKey,
    },
  ).data?.data.results

  // If we apply filters that don't include the current station, we won't have enough information to display basic station data,
  // so we must fetch the expanded station explicitly
  const expandedStationFallback = useQuery(
    expanded ? getterKeys.analyticsStation(expanded) : undefined,
    expanded ? () => service.getStation(expanded) : undefined,
  ).data?.data

  const expandedStation = stations?.find(station => station.id === expanded) || expandedStationFallback

  const { itemMetrics, isFetching: isFetchingMetrics } = useAnalyzeItemMetrics('stations')
  const showLoader = isFetchingMetrics || isLoading || isFetchingLabelMetrics

  const stationsWithMetrics: StationWithMetrics[] | undefined = useMemo(() => {
    const typedPeriod = period === '30m' ? 'minute' : period || 'day'
    const numPeriods = period === '30m' ? 30 : 1
    if (!itemMetrics || !labelMetrics || !allToolLabels || !stations || !labelMetrics) return

    const groupedByStationId = groupBy(itemMetrics, result => result.labels.station_id)
    const labelMetricsGrouped = groupBy(labelMetrics, result => result.labels.station_id)
    const groups = Object.entries(groupedByStationId)
      .map(([stationId, results]) => {
        const stationIds = stations?.map(station => station.id)
        const truncatedResults = results
          .filter(results => results.labels.station_id && stationIds?.includes(results.labels.station_id))
          .map(result => ({
            ...result,
            entries: truncateSeriesTimestampsRtsDatePeriod(result.entries, typedPeriod),
          }))
        const combinedSeries = combineOutcomeCounts(truncatedResults)
        const filledSeries = seriesFillGapsRtsDatePeriod(combinedSeries, typedPeriod, {
          end: end || undefined,
          start: start || undefined,
          numPeriods,
        })

        const yieldSeries = filledSeries.map(data => ({
          ...data,
          passYield: calculatePercentage(data.pass, data.count),
          failYield: calculatePercentage(data.fail, data.count),
          unknownYield: calculatePercentage(data.unknown, data.count),
        }))

        let count = 0
        let passed = 0

        for (const item of filledSeries) {
          count += item.count || 0
          passed += item.pass || 0
        }

        const curYield = calculatePercentage(passed, count)

        const station = stations?.find(station => station.id === stationId)

        const productLabelMetrics = labelMetricsGrouped?.[stationId]
        const groupedLabelMetricsByLabelId = groupBy(productLabelMetrics, result => result.labels.tool_label_id)
        const defects: AnalyzeDefect[] = getAnalyzeDefects({ groupedLabelMetricsByLabelId, allToolLabels, count })

        return {
          ...station,
          subTitle: station && getDisplayStationLocation(station.site_name, station.belongs_to?.name),
          itemsInspected: count,
          yield: curYield,
          defects,
          series: yieldSeries,
        } as StationWithMetrics
      })
      .sort((a, b) => ((a.created_at || '') < (b.created_at || '') ? 1 : -1))

    return groups.filter(g => g.itemsInspected)
  }, [allToolLabels, end, itemMetrics, labelMetrics, period, start, stations])

  const expandedStationWithMetrics = expanded
    ? stationsWithMetrics?.find(station => station.id === expanded)
    : undefined

  useEffect(() => {
    return () => {
      // Reset PDF data when component unmounts
      setPdfData(undefined)
    }
  }, [setPdfData])

  const columns: ColumnsType<StationWithMetrics> = useMemo(
    () => getAnalyzeTableColumns({ title: 'Station', period, isGalleryExpanded, initialSortByYield: true }),
    [period, isGalleryExpanded],
  )

  const mostDefectiveComponentsIdsWithYield = useMemo(() => {
    if (!expandedStationWithMetrics) return

    const groupedByComponentId = groupBy(
      itemMetrics?.filter(
        result => result.labels.component_id && result.labels.station_id === expandedStationWithMetrics.id,
      ),
      result => result.labels.component_id,
    )

    const mostDefectiveComponentIdsWithYield = Object.entries(groupedByComponentId)
      .filter(([componentId]) => componentId !== 'None')
      .map(([componentId, results]) => {
        const combinedSeries = combineOutcomeCounts(results)

        let count = 0
        let failCount = 0

        for (const item of combinedSeries) {
          count += item.count || 0
          failCount += item.fail || 0
        }

        const failYield = calculatePercentage(failCount, count)

        return {
          componentId,
          failYield,
          failCount,
        }
      })

    return mostDefectiveComponentIdsWithYield
  }, [expandedStationWithMetrics, itemMetrics])

  const components = useQuery(getterKeys.components('analyze'), () => service.getComponents(), {
    noRefetch: true,
  }).data?.data.results

  const failedProductsWithFailYield = useMemo(() => {
    return components
      ?.map(component => {
        const componentIdWithYield = mostDefectiveComponentsIdsWithYield?.find(grp => grp.componentId === component.id)
        return {
          ...component,
          failYield: componentIdWithYield?.failYield,
          failCount: componentIdWithYield?.failCount,
        }
      })
      .filter(component => component.failYield)
      .sort((a, b) => b.failYield! - a.failYield!)
  }, [components, mostDefectiveComponentsIdsWithYield])

  const mostCommonDefects: AnalyzeDefect[] | undefined = useMemo(() => {
    if (!expandedStationWithMetrics) return
    const labelMetricsGrouped = groupBy(labelMetrics, result => result.labels.station_id)
    const productLabelMetrics = labelMetricsGrouped?.[expandedStationWithMetrics.id]
    const groupedLabelMetricsByLabelId = groupBy(productLabelMetrics, result => result.labels.tool_label_id)

    return getAnalyzeDefects({
      groupedLabelMetricsByLabelId,
      allToolLabels,
      count: expandedStationWithMetrics.itemsInspected,
    })
  }, [allToolLabels, expandedStationWithMetrics, labelMetrics])

  // This effect sets the chart or table data to be used on the PDF
  useEffect(() => {
    if (!stationsWithMetrics) {
      setPdfData(undefined)
      return
    }

    if (expandedStationWithMetrics)
      setPdfData({
        type: 'charts',
        chartContent: {
          yield: {
            value: expandedStationWithMetrics.yield,
            data: expandedStationWithMetrics.series,
          },
          count: {
            value: expandedStationWithMetrics.itemsInspected,
            data: expandedStationWithMetrics.series,
          },
          productsWithFailYield: {
            data: failedProductsWithFailYield,
          },
          mostCommonDefects: {
            data: mostCommonDefects,
          },
        },
      })
    else {
      const cappedStations = stationsWithMetrics.slice(0, PDF_TABLE_LIMIT)

      setPdfData({
        type: 'yieldAndCount',
        title: 'station',
        rows: cappedStations.map(station => ({
          name: station.name,
          yield: station.yield,
          count: station.itemsInspected,
          series: station.series,
          defects: station.defects,
        })),
      })
    }
  }, [expandedStationWithMetrics, stationsWithMetrics, setPdfData, failedProductsWithFailYield, mostCommonDefects])

  useBackTopWithClassName('ant-table-body')

  const parentContainerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)

  const handleClickRow = useCallback(
    (row: AnalyzeTableMetrics) => {
      onUpdateFilters({ expanded: row.id })
    },
    [onUpdateFilters],
  )
  const { columnsWithWidths, renderVirtualTable, tableContainerRef, tableHeight } = useVirtualizedTable(columns, {
    onClick: handleClickRow,
  })

  return (
    <section className={Styles.analyzeMain} ref={parentContainerRef}>
      <PrismContainer
        containerStyle={{ paddingTop: ANALYZE_VERTICAL_PADDING }}
        titleRef={titleRef}
        title={expandedStation?.name || 'Stations'}
        className={Styles.analyzeMainBody}
        bodyClassName={Styles.stationTableWrapper}
        headerActionsClassName={expandedStation ? Styles.headerExpandedLayout : Styles.headerTitle}
        headerTitleAction={
          expandedStation && (
            <IconButton
              data-testid="analyze-stations-go-back"
              onClick={() => {
                onUpdateFilters({ expanded: undefined })
              }}
              icon={<PrismNavArrowIcon direction="left" />}
              type="secondary"
              size="small"
              isOnTop
              className={Styles.iconPosition}
            />
          )
        }
        actions={<AnalyzeFilters tab="stations" data-testid="analyze-stations-filters" />}
        data-testid="analyze-stations-container"
      >
        <div className={Styles.tableWrapper} ref={tableContainerRef} key={tableRerenderKey}>
          <BackTop scrollContainerClassName="ant-table-body" />

          {!expanded && tableHeight && (
            <Table
              data-testid="analyze-stations-table"
              dataSource={stationsWithMetrics}
              columns={columnsWithWidths}
              className={`${Styles.tableContainer} ${Styles.tableHeaderForcedHeight} ${
                isGalleryExpanded ? Styles.adjustCellPadding : ''
              }`}
              rowClassName={Styles.tableRow}
              locale={{ emptyText: !showLoader ? <AnalyzeEmptyState tab="stations" /> : <div></div> }}
              pagination={false}
              loading={{
                spinning: !stationsWithMetrics || showLoader,
                wrapperClassName: Styles.tableSpinnerWrapper,
                indicator: <PrismLoader className={Styles.loaderPosition} />,
              }}
              scroll={{ y: tableHeight - 42 }}
              // We must send undefined so that the table component falls back on its default empty state
              components={stationsWithMetrics?.length ? { body: renderVirtualTable } : undefined}
            />
          )}

          {expanded && (
            <>
              <div
                className={`${Styles.analyzeGridContainer} ${isGalleryExpanded ? Styles.galleryIsExpanded : ''}`}
                data-testid="analyze-stations-graphs-container"
              >
                <PrismGraphWrapper
                  graphName="Yield"
                  graphValue={expandedStationWithMetrics ? `${expandedStationWithMetrics.yield.toFixed(1)}%` : '0.0%'}
                  className={forceZeroPosition(expandedStationWithMetrics) ? Styles.forceHideTooltip : ''}
                >
                  <YieldGraph
                    chartWidth="98%"
                    chartHeight={112}
                    yieldSeries={expandedStationWithMetrics?.series || dummyData({ start, end })}
                    period={period}
                    start={start}
                    end={end}
                    mode="metrics"
                    syncId="stationsDrillDown"
                    allowEscapeViewBox={{ y: true }}
                  />
                </PrismGraphWrapper>

                <PrismGraphWrapper
                  graphName="Items Inspected"
                  graphValue={
                    expandedStationWithMetrics
                      ? renderLargeNumber(expandedStationWithMetrics.itemsInspected, 1000)
                      : '0'
                  }
                  className={
                    forceZeroPosition(expandedStationWithMetrics)
                      ? `${Styles.forceHideTooltip} ${Styles.forceZeroPosition}`
                      : ''
                  }
                >
                  <CountGraph
                    chartWidth="98%"
                    chartHeight={112}
                    mode="metrics"
                    graphSeries={expandedStationWithMetrics?.series || dummyData({ start, end })}
                    period={period}
                    start={start}
                    end={end}
                    syncId="stationsDrillDown"
                    allowEscapeViewBox={{ y: true }}
                  />
                </PrismGraphWrapper>

                <PrismGraphWrapper graphName="Most Common Defects" graphCaption="% of items with defect">
                  <div className={Styles.graphBodyContainer}>
                    {mostCommonDefects?.map(defect => (
                      <PrismGraphProgressBar
                        key={defect.toolLabel.id}
                        type="fail"
                        graphName={
                          <PrismResultButton
                            severity={getDisplaySeverity(defect.toolLabel)}
                            value={getLabelName(defect.toolLabel)}
                            type="pureWhite"
                            className={Styles.defectItemTitle}
                          />
                        }
                        toolTipTitle={titleCase(getLabelName(defect.toolLabel))}
                        graphPercentage={defect.percentage.toFixed(1) || 0}
                        graphCount={defect.defectCount}
                      />
                    ))}
                    {(!mostCommonDefects || mostCommonDefects.length === 0) && (
                      <div className={Styles.graphContainerEmptyState}>No defects match your filters</div>
                    )}
                  </div>
                </PrismGraphWrapper>

                <PrismGraphWrapper
                  graphName="Most Defective Products"
                  graphCaption="% of items with defect per product"
                >
                  <div className={Styles.graphBodyContainer}>
                    {failedProductsWithFailYield?.map(component => (
                      <PrismGraphProgressBar
                        key={component.id}
                        type="fail"
                        graphName={component.name}
                        graphPercentage={component.failYield?.toFixed(1) || 0}
                        graphCount={component.failCount || 0}
                      />
                    ))}

                    {(!failedProductsWithFailYield || failedProductsWithFailYield.length === 0) && (
                      <div className={Styles.graphContainerEmptyState}>No products match your filters</div>
                    )}
                  </div>
                </PrismGraphWrapper>
              </div>
            </>
          )}
        </div>
        <div
          className={`${Styles.hiddenGraphRender} ${Styles.analyzeGridContainer}`}
          id="stations-pdf-graphs-container"
        ></div>
      </PrismContainer>
      <AnalyzeGallery
        parentContainerRef={parentContainerRef}
        titleRef={titleRef}
        isGalleryExpanded={isGalleryExpanded}
        setIsGalleryExpanded={setIsGalleryExpanded}
        tab={'stations'}
        filters={filters}
        transitionInProgress={galleryTransitionInProgress}
        setTransitionInProgress={setGalleryTransitionInProgress}
      />
    </section>
  )
}
