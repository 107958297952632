import React from 'react'

import { BreadcrumbLabel } from 'components/Breadcrumbs/Breadcrumbs'
import Layout from 'components/Layout/Layout'
import PrismSideNavLayout from 'components/PrismSideNavLayout/PrismSideNavLayout'
import paths from 'paths'

import QualityEvents from './QualityEvents'
import Styles from './QualityEvents.module.scss'
import UsersTable from './UsersTable'

const Administration = () => {
  return (
    <Layout title={<BreadcrumbLabel className={Styles.headerBreadcrumb}>Administration</BreadcrumbLabel>}>
      <PrismSideNavLayout
        getPath={paths.administrationSettings}
        data-testid="administration"
        listItems={[
          {
            label: 'Users',
            value: 'users',
            component: <UsersTable />,
          },
          {
            label: 'Quality Events',
            value: 'quality_events',
            component: <QualityEvents />,
          },
        ]}
      />
    </Layout>
  )
}

export default Administration
