import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Graded Anomaly Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=6660%3A12259
 *
 * @param hasHover - Fill the icon with $light_100 when hovering the icon.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismGradedAnomalyToolIcon = ({ isActive, hasHover, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${hasHover ? Styles.hoverInBlue : ''} ${isActive ? Styles.active : ''} ${
        className ?? ''
      }`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M19.5 13.5V19.5H13.5V13.5H19.5ZM19.5 4.5V10.5H13.5V4.5H19.5ZM10.5 4.5V10.5H4.5V4.5H10.5ZM10.5 13.5V19.5H4.5V13.5H10.5Z"
        fill="currentColor"
        fillOpacity="0.08"
        stroke="currentColor"
      />
      <path d="M13 11V4H20V11H13Z" fill="currentColor" />
      <path d="M10.3569 4.6L4.56006 10.3969" stroke="currentColor" />
    </svg>
  )
}
