import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import MultiVideoListener from 'components/MultiVideoListener/MultiVideoListener'
import ReduxVideo from 'components/MultiVideoListener/ReduxVideo'
import PrismAccordion from 'components/PrismAccordion/PrismAccordion'
import PrismAddButton from 'components/PrismAddBtn/PrismAddBtn'
import { PrismStationIcon } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { error } from 'components/PrismMessage/PrismMessage'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import ReorderingArrows from 'components/ReorderingArrows/ReorderingArrows'
import RobotsStatusListener from 'components/RobotsStatusListener'
import { Status } from 'components/Status/Status'
import {
  useData,
  useInspectionCountMetrics,
  useQueryCurrentInspection,
  useQueryParams,
  useSortedRobotsWithStatus,
  useStationsWithStatus,
} from 'hooks'
import AddOrEditStationModal from 'pages/StationDetail/Components/EntityModals/AddOrEditStationModal'
import { StationForSite, SubSiteExpanded, WithSiteId, WithStatus } from 'types'
import {
  calculatePercentage,
  matchRole,
  renderLargeNumber,
  SiteAndLineCascaderLocation,
  sleep,
  sortStationsByOrdering,
} from 'utils'
import { INDEPENDENT_STATION_LINE_NAME } from 'utils/constants'

import EntityOverflowMenu from './EntityOverflowMenu'
import Styles from './LinesList.module.scss'

export interface LinesListProps {
  siteId: string
  lines: SubSiteExpanded[] | undefined
  onStationSelect: (stationId: string | undefined) => void
  searchingLines: boolean
}

interface LineAccordionBodyProps {
  stationsList: WithStatus<WithSiteId<StationForSite>>[] | undefined
  onStationSelect: (stationId: string | undefined) => void
  onStationReorder: (stationId: string, direction: 'up' | 'down') => void
  location?: SiteAndLineCascaderLocation
  showOrderingArrows?: boolean
  wrapperRef?: React.RefObject<HTMLDivElement>
  dataTest?: string
  reorderingRow: {
    selectedStation: number
    isLoading: boolean
  }
}

interface LineAccordionProps {
  label: string
  isSearching: boolean
  location?: SiteAndLineCascaderLocation
  line?: SubSiteExpanded
  stations: WithSiteId<StationForSite>[] | undefined
  onStationSelect: (stationId: string | undefined) => void
  className?: string
  isOpen: boolean
  setIsOpen: (lineId: string | null, open: boolean) => void
  dataTestId?: string
}

const LINE_ACCORDION_HEADER_TITLES = ['status', 'yield', 'failed', 'count']

export const STATION_LIST_METRICS_SUFIX = 'station-list'

/**
 * Renders a Line Header Row, containing the line title, and other table titles
 *
 * @param label - Line row name
 * @param isOpen - Whether the table titles are visible or not
 */
const LineAccordionHeader = ({
  label,
  isOpen,
  line,
  lineHasStations,
  closeMenuRef,
  labelDataTestId,
}: {
  label: string
  isOpen: boolean
  line?: SubSiteExpanded
  lineHasStations: boolean
  closeMenuRef?: MutableRefObject<(() => void) | undefined>
  labelDataTestId?: string
}) => {
  const me = useData(getterKeys.me())
  return (
    <>
      <div className={`${Styles.lineName} ${Styles.lineTitleContainer}`} data-testid={labelDataTestId}>
        {label}
      </div>

      {LINE_ACCORDION_HEADER_TITLES.map(title => (
        <div
          className={`${Styles[title]} ${Styles.lineGridTitle} ${isOpen && lineHasStations ? Styles.showTitles : ''}`}
          key={title}
        >
          {title}
        </div>
      ))}
      {line && matchRole(me, 'manager') && (
        <EntityOverflowMenu entityType="line" entity={line} renderWithPortal closeMenuRef={closeMenuRef} />
      )}
    </>
  )
}

/**
 * Renders a list of station rows
 *
 * When the list is empty it shows the AddBtn row
 *
 * @param stationsList - The stations list
 * @param selectedStation - Whether a station row is selected or not, and opens the station preview
 * @param setSelectedStation - State updater for the selected station
 */
const LineAccordionBody = ({
  stationsList,
  onStationSelect,
  wrapperRef,
  showOrderingArrows,
  onStationReorder,
  location,
  reorderingRow,
  dataTest,
}: LineAccordionBodyProps) => {
  const me = useData(getterKeys.me())
  const [showAddStationModal, setShowAddStationModal] = useState(false)

  return (
    <div ref={wrapperRef}>
      {stationsList?.length === 0 && matchRole(me, 'manager') && (
        <PrismAddButton
          description="Start by adding a station"
          onClick={() => setShowAddStationModal(true)}
          className={Styles.stationAddButton}
        />
      )}
      {!!stationsList?.length &&
        stationsList.map((station, idx) => (
          <StationRow
            key={station.id}
            dataTest={`${dataTest}-stations`}
            dataTestId={`${dataTest}-${station.name}`}
            dataTestAttribute={idx}
            onStationReorder={onStationReorder}
            station={station}
            disableArrows={{
              upArrowIsdisabled: idx === 0 || (reorderingRow.isLoading && reorderingRow.selectedStation !== idx),
              downArrowIsdisabled:
                idx === stationsList.length - 1 || (reorderingRow.isLoading && reorderingRow.selectedStation !== idx),
            }}
            onStationSelect={onStationSelect}
            showOrderingArrows={showOrderingArrows}
            isLoading={reorderingRow.isLoading && reorderingRow.selectedStation === idx}
          />
        ))}
      {showAddStationModal && (
        <AddOrEditStationModal
          isEditMode={false}
          onClose={() => setShowAddStationModal(false)}
          defaultLocation={location}
        />
      )}
    </div>
  )
}

const StationRow = ({
  station,
  showOrderingArrows,
  disableArrows,
  onStationSelect,
  onStationReorder,
  isLoading,
  dataTest,
  dataTestId,
  dataTestAttribute,
}: {
  station: WithStatus<WithSiteId<StationForSite>>
  showOrderingArrows?: boolean
  disableArrows?: { upArrowIsdisabled?: boolean; downArrowIsdisabled?: boolean }
  onStationSelect: (stationId: string | undefined) => void
  onStationReorder: (stationId: string, direction: 'up' | 'down') => void
  isLoading?: boolean
  dataTest?: string
  dataTestId?: string
  dataTestAttribute?: string | number
}) => {
  const [params] = useQueryParams<'stationId'>()
  const me = useData(getterKeys.me())

  const isBatchLoading = station.status === 'loading'
  const isBatchRunning = station.status === 'running'
  const inspection = useQueryCurrentInspection(isBatchRunning ? station.robots.map(robot => robot.id) : [])
  const { totalCount, totalFail, totalPass } = useInspectionCountMetrics(inspection, {
    getterKeySuffix: STATION_LIST_METRICS_SUFIX,
  })

  const isStationActive = params.stationId === station.id

  const sortedRobots = useSortedRobotsWithStatus(station.robots)

  const robotToShowLiveFeed = useMemo(() => {
    return sortedRobots?.[0]
  }, [sortedRobots])

  return (
    <li
      className={`${Styles.stationRow} ${isStationActive ? Styles.isActive : ''}`}
      key={station.id}
      data-test={dataTest}
      data-testid={dataTestId}
      data-test-attribute={dataTestAttribute}
    >
      <Button
        type="default"
        childrenClassName={Styles.accordionGrid}
        className={Styles.stationButton}
        onClick={() => onStationSelect(station.id)}
      >
        {showOrderingArrows && (
          <ReorderingArrows
            dataTestId={dataTestId}
            onClickUp={() => onStationReorder(station.id, 'up')}
            onClickDown={() => onStationReorder(station.id, 'down')}
            className={`${Styles.reorderingRow} ${isLoading ? Styles.showLoader : ''}`}
            disabled={disableArrows}
            isLoading={isLoading}
          />
        )}

        <div className={`${Styles.lineName} ${Styles.stationNameContainer}`}>
          {robotToShowLiveFeed && (
            <figure className={Styles.stationImage}>
              <ReduxVideo
                element="transcoder-basler-image-thumbnail"
                stream="compressed"
                robotId={robotToShowLiveFeed.id}
              />
            </figure>
          )}
          {!robotToShowLiveFeed && (
            <i className={Styles.stationImageEmpty}>
              <PrismStationIcon />
            </i>
          )}

          <div className={Styles.stationTitle}>
            <PrismOverflowTooltip content={station.name} textClassName={Styles.stationName} />
            {isBatchRunning && (
              <PrismOverflowTooltip
                content={isBatchRunning ? inspection?.name : '--'}
                textClassName={Styles.batchName}
              />
            )}
          </div>
        </div>
        <Status status={station.status || 'disconnected'} className={Styles.status} hideLoader />
        <StationRowMetric
          className={Styles.yield}
          value={isBatchRunning ? `${calculatePercentage(totalPass || 0, totalCount).toFixed(1)}%` : '--'}
          isLoading={isBatchLoading}
        />
        <StationRowMetric
          className={Styles.failed}
          value={isBatchRunning ? renderLargeNumber(totalFail || 0) : '--'}
          isLoading={isBatchLoading}
        />
        <StationRowMetric
          className={Styles.count}
          value={isBatchRunning ? renderLargeNumber(totalCount || 0) : '--'}
          isLoading={isBatchLoading}
        />

        {matchRole(me, 'manager') && <EntityOverflowMenu entityType="station" entity={station} renderWithPortal />}
      </Button>
    </li>
  )
}

/**
 * Renders a Line According, by opening the accordion a Station List is displayed
 *
 * @param label - The line label or name
 */
export const LineAccordion = ({
  label,
  isSearching,
  line,
  stations,
  location,
  isOpen,
  setIsOpen,
  onStationSelect,
  dataTestId,
  className = '',
}: LineAccordionProps) => {
  const dispatch = useDispatch()
  const [reorderingRow, setReorderingRow] = useState({ selectedStation: 0, isLoading: false })
  const { state: locationState } = useLocation<{ lineIdToExpand?: string }>()
  const lineIdToExpand = locationState?.lineIdToExpand

  const closeMenuRef = useRef<(() => void) | undefined>()

  const accordionWrapperRef = useRef<HTMLDivElement>(null)
  const { stationsWithStatus, robotIds } = useStationsWithStatus(stations || [])
  const orderedStations = useMemo(() => {
    // this is in place
    return stationsWithStatus.sort(sortStationsByOrdering)
  }, [stationsWithStatus])

  useEffect(() => {
    const scrollToLine =
      (lineIdToExpand && line && lineIdToExpand === line.id) ||
      (label === INDEPENDENT_STATION_LINE_NAME && !line && lineIdToExpand === null)

    const doScroll = async () => {
      await sleep(0)
      accordionWrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    if (isOpen && scrollToLine) {
      doScroll()
    }
    // eslint-disable-next-line
  }, [lineIdToExpand, isOpen])

  async function handleReorderingStations(stationId: string, direction: 'up' | 'down') {
    const stationPosition = stationsWithStatus.findIndex(station => station.id === stationId)
    setReorderingRow(prevState => ({ ...prevState, selectedStation: stationPosition }))
    if (stationPosition === -1 || !line) return

    const newStationPosition = direction === 'up' ? stationPosition - 1 : stationPosition + 1

    // out of bounds
    if (newStationPosition < 0 || newStationPosition >= stationsWithStatus.length) return

    // 0 based order
    const payload = orderedStations.map((station, idx) => ({
      station_id: station.id,
      ordering: station.ordering || idx,
    }))
    setReorderingRow(prevState => ({ ...prevState, isLoading: true }))
    if (payload[newStationPosition]) payload[newStationPosition]!.ordering = stationPosition
    if (payload[stationPosition]) payload[stationPosition]!.ordering = newStationPosition

    const res = await service.orderStations(line.id, payload)
    if (res.type !== 'success') error({ title: 'An error occured, please try again' })

    await query(getterKeys.sites(), service.getSites, { dispatch })
    setReorderingRow(prevState => ({ ...prevState, isLoading: false }))
  }

  return (
    <>
      {!!robotIds.length && (
        <>
          <RobotsStatusListener robotIds={robotIds} />
          <MultiVideoListener element="transcoder-basler-image-thumbnail" stream="compressed" robotIds={robotIds} />
        </>
      )}
      <PrismAccordion
        dataTestId={`${dataTestId}-line`}
        header={
          <LineAccordionHeader
            labelDataTestId={`${dataTestId}-title`}
            label={label}
            isOpen={isOpen}
            line={line}
            lineHasStations={!!stationsWithStatus.length}
            closeMenuRef={closeMenuRef}
          />
        }
        body={
          <LineAccordionBody
            dataTest={dataTestId}
            onStationReorder={handleReorderingStations}
            stationsList={stationsWithStatus}
            onStationSelect={onStationSelect}
            showOrderingArrows={!!line && !isSearching}
            location={location}
            reorderingRow={reorderingRow}
          />
        }
        isOpen={isOpen}
        setIsOpen={open => setIsOpen(line?.id || null, open)}
        onClick={() => {
          // The accordion stops the click event propagation, so we need
          // to "manually" close the option menu if accordion is collapsed
          if (isOpen) {
            closeMenuRef.current?.()
          }
        }}
        headerClassName={`${Styles.accordionGrid} ${Styles.lineAccordionHeader}`}
        iconContainerClassName={Styles.accordionArrowIcon}
        className={`${Styles.lineAccordion} ${isOpen ? Styles.accordionIsOpen : ''} ${className}`}
        bodyClassName={Styles.lineAccordionBody}
        wrapperRef={accordionWrapperRef}
      />
    </>
  )
}

const StationRowMetric = ({
  value,
  isLoading,
  className,
}: {
  value: string
  isLoading?: boolean
  className?: string
}) => {
  return (
    <>
      <div className={className}>
        {!isLoading && value}
        {isLoading && <PrismSkeleton className={Styles.metricsLoader} />}
      </div>
    </>
  )
}
