import React from 'react'

import { useQuery } from 'react-redux-query'

import { getterKeys, service } from 'api'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { Modal } from 'components/PrismModal/PrismModal'

import Styles from './LoginContainer.module.scss'
interface Props {
  onOk: () => void
  onClose: () => void
}

/**
 * Renders a modal that fetches and shows the EULA.
 * @param onOk - Called when user clicks 'Agree'
 * @param onClose - Called when user closes modal without agreeing to EULA
 */
function EulaModal({ onOk, onClose }: Props) {
  const eulaRes = useQuery(getterKeys.eula(), service.getEulaJson)

  const eulaHtml = eulaRes.data?.data.html
  const eulaVersion = eulaRes.data?.data.version

  return (
    <Modal
      id="eula"
      header="End User License Agreement"
      onClose={onClose}
      okText="Agree"
      onOk={onOk}
      cancelText="Disagree"
      showCancel
      size="largeSimpleForm"
      className={Styles.eulaModal}
      modalBodyClassName={Styles.eulaModalBody}
      disableSave={!eulaVersion}
    >
      {!eulaHtml && <PrismLoader />}
      {/* We trust the S3 document, so this is OK */}
      {eulaHtml && <div dangerouslySetInnerHTML={{ __html: eulaHtml }}></div>}
    </Modal>
  )
}

export default EulaModal
