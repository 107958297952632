import React, { useEffect } from 'react'

import { Radio } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import {
  PrismCompositeItemIcon,
  PrismDistinctItemIcon,
  PrismElementaryCube,
  PrismHelpIcon,
  PrismInfoIcon,
  PrismMultipleItemIcon,
} from 'components/prismIcons'
import { PrismInputNumber } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { PrismSlider } from 'components/PrismSlider/PrismSlider'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Token } from 'components/Token/Token'
import Shared from 'styles/Shared.module.scss'
import { RecipeExpanded, RecipeItemCorrelationSettings } from 'types'
import { protectedOnChange, refreshRecipeAndRoutinesLinked } from 'utils'

import { defaultItemCorrelation } from '../Capture'
import Styles from './RoutineSettings.module.scss'

type Props = {
  recipe: RecipeExpanded
  readOnly?: boolean
}

/**
 * Renders item creation settings form
 *
 * @param recipe - Currently selected recipe
 * @param readOnly - Whether the form should be read only
 *
 * Business Rules:
 * - Composite tolerance window should default to interval_ms / 2
 * - Composite tolerance window should have a range from 10 to interval_ms - 10
 * - Camera offset should default to 0
 * - Camera offset should have a range from 0 to 60,000
 */

const ItemCreation = ({ recipe, readOnly }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const defaultValues = getDefaultFormValues(recipe)
  const {
    formState: { isDirty, isValid },
    control,
    getValues,
    trigger,
    reset,
  } = useForm({ defaultValues, mode: 'onChange' })

  // Update form when new recipe is fetched, this is necessary for version management
  useEffect(() => {
    reset(getDefaultFormValues(recipe))
  }, [reset, recipe])

  const isAnyRoutineCameraManual = recipe.recipe_routines.some(RecipeRoutine => {
    return RecipeRoutine.routine.settings?.camera_trigger_mode === 'manual'
  })
  const isAnyRoutineCameraAutomatic = recipe.recipe_routines.some(
    RecipeRoutine => RecipeRoutine.routine.settings?.camera_trigger_mode === 'automatic',
  )

  let defaultWindowValue: number
  const intervalMs = Math.max(
    ...recipe.recipe_routines.map(recipeRoutine => recipeRoutine.routine.settings?.interval_ms || 0),
  )
  if (isAnyRoutineCameraManual) {
    defaultWindowValue = defaultItemCorrelation.window_ms
  } else {
    defaultWindowValue = intervalMs / 2 || defaultItemCorrelation.window_ms
  }

  const handleSave = async () => {
    const valid = await trigger()
    if (!valid) return error({ title: 'Error updating item settings.' })

    const values = getValues()

    const item_correlation = values.settings.item_correlation
    const window_ms = item_correlation.window_ms || defaultWindowValue

    let itemCorrelationSettings = {} as RecipeItemCorrelationSettings

    if (item_correlation.type === 'distinct' || item_correlation.type === 'multiple_item') {
      itemCorrelationSettings = {
        type: item_correlation.type,
      }
    }

    if (item_correlation.type === 'composite') {
      itemCorrelationSettings = {
        type: item_correlation.type,
        window_ms: window_ms,
      }
    }

    if (item_correlation.type.startsWith('composite_multiple_picture')) {
      // Choose item correlation setting depending on how many cameras do we have
      if (recipe.recipe_routines.length > 1) {
        itemCorrelationSettings = {
          type: 'composite_multiple_picture_multiple_camera',
          self_window_ms: window_ms,
          window_ms: window_ms,
        }
      } else {
        itemCorrelationSettings = {
          type: 'composite_multiple_picture_single_camera',
          self_window_ms: window_ms,
        }
      }
    }

    const res = await service.patchRecipe(recipe.id, {
      user_settings: { ...recipe.user_settings, item_correlation: itemCorrelationSettings },
    })

    if (res.type !== 'success') return error({ title: 'Error updating recipe settings.' })

    // We don't need to update RecipeRoutine settings for these types of correlation
    if (item_correlation.type === 'distinct' || item_correlation.type === 'multiple_item') {
      query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), {
        dispatch,
      })
      return success({
        title: 'Settings updated',
        'data-testid': 'item-creation-settings-updated-success',
      })
    }

    const robotOffsetSettings = values.settings.robotsCameraSettings

    for (const [recipeRoutineId, settings] of Object.entries(robotOffsetSettings)) {
      const recipeRoutineRecord = recipe.recipe_routines.find(recipeRoutine => recipeRoutine.id === recipeRoutineId)

      if (!recipeRoutineRecord) continue
      if (recipeRoutineRecord.user_settings?.item_correlation?.offset_ms === settings.offset_ms) continue

      const res = await service.patchProtectedRecipeRoutine(recipeRoutineRecord.id, {
        user_settings: {
          ...recipeRoutineRecord.user_settings,
          item_correlation: { offset_ms: settings.offset_ms },
        },
      })

      if (res.type !== 'success') return error({ title: 'Error updating recipe settings.' })
    }

    success({ title: 'Settings updated', 'data-testid': 'item-creation-settings-updated-success' })
    refreshRecipeAndRoutinesLinked({ recipe, dispatch })
  }

  return (
    <>
      <div className={Shared.twoSideLayoutHeader}>
        <h1 className={Shared.twoSideLayoutHeaderTitle}>Item Creation</h1>
        <div>
          <Button
            disabled={readOnly || !isDirty || !isValid}
            size="small"
            onClick={handleSave}
            data-testid="item-creation-save"
          >
            Save
          </Button>
        </div>
      </div>

      <Divider className={Styles.twoSideHorizontalDivider} />

      <div className={Styles.layoutBody}>
        <CaptionText
          text="An item represents a physical part made in the factory. Metrics like Yield and Count are based on items.
            Depending on your inspection setup, you may want to change how items are created."
        />

        <Controller
          control={control}
          name="settings"
          render={({
            onChange,
            value,
          }: {
            onChange: (x: any) => any
            value: ReturnType<typeof getDefaultFormValues>['settings']
          }) => {
            const windowValue = value.item_correlation.window_ms || defaultWindowValue

            const windowOnChange = (windowVal: number) =>
              onChange({ ...value, item_correlation: { ...value.item_correlation, window_ms: windowVal } })

            const offsetOnChange = (offsetVal: number, recipeRoutineId: string) => {
              onChange({
                ...value,
                robotsCameraSettings: {
                  ...value.robotsCameraSettings,
                  [recipeRoutineId]: { ...value.robotsCameraSettings[recipeRoutineId], offset_ms: offsetVal },
                },
              })
            }

            const defaultWindowValues = {
              defaultValue: defaultWindowValue,
              min: 20,
              max: 2000,
              value: windowValue,
              onChange: windowOnChange,
              disabled: readOnly || isAnyRoutineCameraManual,
            }

            const defaultOffsetValues = {
              defaultValue: 0,
              min: 0,
              max: 60000,
              disabled: readOnly,
            }
            return (
              <>
                <section className={Styles.itemSelection}>
                  {itemTypes.map(itemType => {
                    // TODO: Remove this line when we want to show multiple item functionality
                    if (itemType.value === 'multiple_item') return null
                    const continuousTriggerAndComposite =
                      isAnyRoutineCameraAutomatic && itemType.value.includes('composite')
                    const disabled = readOnly || continuousTriggerAndComposite

                    const article = (
                      <article
                        data-testid={itemType['data-testid']}
                        data-test={`${itemType['data-testid']}-${disabled ? 'disabled' : 'active'}`}
                        data-test-attribute={
                          value.item_correlation.type.includes(itemType.value)
                            ? `${itemType['data-testid']}-selected`
                            : ''
                        }
                        onClick={
                          !disabled
                            ? protectedOnChange(
                                () =>
                                  onChange({
                                    ...value,
                                    item_correlation: { ...value.item_correlation, type: itemType.value },
                                  }),
                                { routine: recipe.recipe_routines[0]!.routine, recipe, history },
                              )
                            : undefined
                        }
                        className={`${Styles.item} ${disabled ? Styles.readOnly : ''} ${
                          value.item_correlation.type.includes(itemType.value) ? Styles.itemActive : ''
                        }`}
                      >
                        <div className={Styles.iconContainer}>
                          {value.item_correlation.type.includes(itemType.value) ? itemType.activeImage : itemType.image}
                        </div>

                        <Token
                          label={itemType.label}
                          align="center"
                          className={Styles.detailsContainer}
                          labelClassName={Styles.itemLabel}
                        >
                          <p className={Styles.itemCaption}>{itemType.caption}</p>
                        </Token>
                      </article>
                    )

                    return (
                      <>
                        {continuousTriggerAndComposite && (
                          <PrismTooltip
                            title="Composite Item mode is not available for continuous trigger"
                            placement="bottom"
                          >
                            {article}
                          </PrismTooltip>
                        )}
                        {!continuousTriggerAndComposite && article}
                      </>
                    )
                  })}
                </section>

                {value.item_correlation.type.includes('composite') && (
                  <section className={Styles.compositeItemSettings}>
                    <Token label="Composite Mode" labelClassName={Styles.compositeRadioLabel}>
                      <Radio.Group
                        value={value.item_correlation.type}
                        disabled={readOnly}
                        onChange={e =>
                          onChange({ ...value, item_correlation: { ...value.item_correlation, type: e.target.value } })
                        }
                        className={Shared.verticalChildrenGap8}
                      >
                        <Radio
                          value="composite"
                          className={Shared.inputRadioAndLabel}
                          data-testid="item-creation-one-img"
                        >
                          <span className={Shared.radioLabel}>Each camera captures one image of an item</span>
                        </Radio>

                        <Radio
                          disabled={isAnyRoutineCameraManual}
                          value="composite_multiple_picture_multiple_camera"
                          className={Shared.inputRadioAndLabel}
                          data-testid="item-creation-multi-img"
                        >
                          <PrismTooltip title="Use a hardware trigger to enable this option." placement="bottom">
                            <span className={Shared.radioLabel}>Each camera captures multiple images of an item</span>
                          </PrismTooltip>
                        </Radio>
                      </Radio.Group>
                    </Token>

                    <div className={Styles.compositeItem}>
                      <PrismSlider
                        label="COMPOSITE TOLERANCE WINDOW (MS)"
                        {...defaultWindowValues}
                        inputBox={
                          <PrismInputNumber
                            className={Styles.targetInput}
                            size="large"
                            {...defaultWindowValues}
                            data-testid="item-creation-tolerance-window"
                          />
                        }
                        extraTooltip={
                          isAnyRoutineCameraManual ? { title: 'Cannot adjust when using manual trigger' } : undefined
                        }
                      />

                      <p className={Styles.targetCaption}>
                        <span>
                          This creates a window around the expected frame time for grouping images from this recipe with
                          each other. Based on this recipe's trigger settings,{' '}
                          <span className={Styles.compositeDescriptionHighlighted}>
                            the recommended value is {defaultWindowValue}ms.
                          </span>
                        </span>
                        <br />
                        <br />
                        If this window is too small, images of the same item won't be grouped together. If it's too
                        large, images of one item will be grouped with images of a different item.
                      </p>
                    </div>
                    <div className={Styles.offsetSettingList}>
                      {recipe.recipe_routines.map(recipeRoutine => (
                        <OffsetSetting
                          key={recipeRoutine.id}
                          {...defaultOffsetValues}
                          value={value.robotsCameraSettings[recipeRoutine.id]?.offset_ms || 0}
                          onChange={(val: number) => offsetOnChange(val, recipeRoutine.id)}
                          readOnly={readOnly}
                          viewName={recipeRoutine.routine.parent.name}
                          image={
                            recipeRoutine.routine.image ? (
                              <ImageWithBoxes src={recipeRoutine.routine.image} />
                            ) : (
                              <PrismElementaryCube />
                            )
                          }
                        />
                      ))}
                    </div>
                  </section>
                )}
              </>
            )
          }}
        />
      </div>
      <LeavePagePrompt when={isDirty} onOk={() => reset(getDefaultFormValues(recipe))} />
    </>
  )
}

export default ItemCreation

/**
 * @param recipe - Recipe to calculate settings from
 * @returns Default form values for item creation
 */
const getDefaultFormValues = (recipe: RecipeExpanded) => {
  // This default is important to support old routines created before Item Correlation was released
  const defaultType = 'distinct' as const
  const robotsCameraSettings = recipe.recipe_routines.reduce((robotsObject, recipeRoutine) => {
    robotsObject[recipeRoutine.id] = {
      ...(recipeRoutine.user_settings?.item_correlation ? recipeRoutine.user_settings?.item_correlation : {}),
    }
    return robotsObject
  }, {} as { [key: string]: any })

  let window_ms = undefined
  if (recipe.user_settings?.item_correlation?.type === 'composite')
    window_ms = recipe.user_settings.item_correlation?.window_ms
  else if (
    recipe.user_settings?.item_correlation?.type === 'composite_multiple_picture_single_camera' ||
    recipe.user_settings?.item_correlation?.type === 'composite_multiple_picture_multiple_camera'
  )
    window_ms = recipe.user_settings?.item_correlation?.self_window_ms

  return {
    settings: {
      item_correlation: {
        type: recipe.user_settings?.item_correlation?.type || defaultType,
        window_ms,
      },
      robotsCameraSettings,
    },
  }
}

// Item type data used to render selectable boxes
const itemTypes = [
  {
    value: 'distinct',
    label: 'Distinct Item',
    caption: 'Create a distinct item for each image inspected',
    image: <PrismDistinctItemIcon />,
    activeImage: <PrismDistinctItemIcon isActive />,
    'data-testid': 'item-creation-distinct-item',
  },
  {
    value: 'composite',
    label: 'Composite Item',
    caption: 'Group multiple images from a recipe into a composite item',
    image: <PrismCompositeItemIcon />,
    activeImage: <PrismCompositeItemIcon isActive />,
    'data-testid': 'item-creation-composite-item',
  },
  {
    value: 'multiple_item',
    label: 'Multiple Item',
    caption: 'Split each image into multiple items',
    image: <PrismMultipleItemIcon />,
    activeImage: <PrismMultipleItemIcon isActive />,
    'data-testid': 'item-creation-multiple-item',
  },
]

const OffsetSetting = ({
  viewName,
  image,
  value,
  onChange,
  readOnly,
  defaultValue = 0,
  min = 0,
  max = 60000,
}: {
  viewName: string
  image: React.ReactNode
  value: number
  defaultValue?: number
  min?: number
  max?: number
  readOnly: boolean | undefined
  onChange: (val: number) => void
}) => {
  return (
    <Token
      label={
        <div className={Styles.offsetSettingHeader}>
          <div className={Styles.offsetSettingTitleContainer}>
            <PrismOverflowTooltip
              className={Styles.offsetSettingTitleName}
              content={viewName}
              tooltipPlacement="bottom"
            />
            <span className={Styles.offsetSettingTitle}> Offset (MS)</span>
          </div>
          <PrismTooltip
            overlayClassName={Styles.tooltip}
            title="This is the amount of time between when the first view in this recipe is triggered and this view is triggered. It is used when calculating the expected frame time for grouping images with each other."
            placement="bottom"
            anchorClassName={Styles.iconContainer}
          >
            <PrismInfoIcon />
          </PrismTooltip>
        </div>
      }
      className={Styles.compositeItem}
    >
      <div className={Styles.offsetSettingBody}>
        <figure className={Styles.imageContainer}>{image}</figure>
        <PrismSlider
          value={value}
          min={min}
          max={max}
          defaultValue={defaultValue}
          disabled={readOnly}
          onChange={onChange}
          className={Styles.sliderContainer}
          inputBox={
            <PrismInputNumber
              className={Styles.targetInput}
              value={value}
              min={min}
              max={max}
              defaultValue={defaultValue}
              readOnly={readOnly}
              disabled={readOnly}
              onChange={onChange}
              size="large"
              data-testid={`item-creation-offset-input-number-${viewName}`}
              data-test="item-creation-offset-input-number"
            />
          }
        />
      </div>
    </Token>
  )
}

const CAPTION_TEXT_ICONS = {
  help: PrismHelpIcon,
  info: PrismInfoIcon,
}

export const CaptionText = ({
  text,
  className = '',
  iconClassName = '',
  captionClassName = '',
  type = 'help',
}: {
  text: string
  className?: string
  iconClassName?: string
  captionClassName?: string
  type?: 'help' | 'info'
}) => {
  const IconComponent = CAPTION_TEXT_ICONS[type]
  return (
    <section className={`${Styles.captionContainer} ${className}`}>
      <IconComponent className={`${Styles.captionIcon} ${iconClassName}`} />

      <p className={`${Styles.caption} ${captionClassName}`}>{text}</p>
    </section>
  )
}
