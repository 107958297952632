import React, { useEffect, useMemo, useState } from 'react'

import { Radio, Switch } from 'antd'
import { debounce, groupBy, isNumber } from 'lodash'
import { Moment } from 'moment'
import momentTz from 'moment-timezone'
import { useHistory } from 'react-router-dom'

import { backendErrorCodes, getterKeys, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import { PrismArrowIcon, PrismSmartGroupIcon } from 'components/prismIcons'
import { PrismInputNumber } from 'components/PrismInput/PrismInput'
import { error, info } from 'components/PrismMessage/PrismMessage'
import { PrismPopover } from 'components/PrismPopover/PrismPopover'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import RangePicker from 'components/RangePicker'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { Token } from 'components/Token/Token'
import { useAllToolLabels, useLabelMetrics, useQueryParams, useToolRecipeParentsAndComponents } from 'hooks'
import {
  LabelingMetrics,
  LabelingScreenMode,
  QsFilterKey,
  QsFilters,
  SelectOption,
  ToolFlat,
  ToolLabel,
  ToolParent,
  ToolResult,
} from 'types'
import {
  appendDataToQueryString,
  areFiltersActive,
  combineOutcomeCounts,
  filterOutDerivativeLabels,
  filterOutUnusedLabels,
  getDisplaySeverity,
  getLabelingScreenFiltersBranch,
  getLabelName,
  isNonTrainingLabel,
  sortByValueAndSeverity,
} from 'utils'
import { INSIGHTS_STREAM_TOOL_SPECIFICATION_NAMES } from 'utils/constants'

import { areSmartGroupsActive, ToolResultsMapDispatch } from './LabelingGallery/LabelingGallery'
import Styles from './LabelingScreen.module.scss'

interface Props {
  mode: LabelingScreenMode
  tool?: ToolFlat
  toolParent: ToolParent
  isShared: boolean
  toolLabels?: ToolLabel[]
  setGeneratingSmartGroups: React.Dispatch<React.SetStateAction<boolean>>
  setDisableSmartGroupsButton: (disabled: boolean) => void
  disableSmartGroupsButton: boolean
  showInsights: boolean
  toggleInsights: () => any
  areSomeToolResultsSelected: boolean
  setSelectedToolResults: ToolResultsMapDispatch
  labelMetrics: LabelingMetrics | undefined
  currentToolResult?: ToolResult
  updateFilters: (filters: QsFilters, forceMode?: LabelingScreenMode) => void
}

/**
 * Renders the Labeling Screen header, that includes filters, smart groups button, labeling mode, and insights button.
 *
 * BACKEND LIMITATIONS:
 * 1. Filtering by outcome(s) and prediction label(s) simultaneously is not allowed. In other words,
 *  calculated_outcome/__in and user_outcome/__in are mutually exclusive with prediction_label_id/__in.
 *  This would require adding calculated_outcome and user_outcome to ToolResultLabel and indexing on these fields.
 *  Prediction labels are tightly correlated with outcomes, so the cost isn’t worth it.
 * 2. Predicted Labels and User Labels are limited to at most 12 different combinations, to comply with this
 *  limitation, the frontend will only allow 3 options in both of these selects, to a maximum of 3x3=9 combinations
 * 3. User Outcome and Calculated Outcome have the same limitation of 12 combinations. In case we wish to
 *  implement multiselects for these filters, we should keep this consideration in mind.
 * 4. These limitations apply for filtering items as well as tool results.
 *
 * @param mode - The labeling mode, one of 'gallery' or 'insights'.
 * @param tool - The current Tool.
 * @param toolParentId - The current tool parent id
 * @param toolLabels - The Tool Labels for the current Tool.
 * @param setGeneratingSmartGroups - A function to set the 'generating smart groups' state.
 * @param setDisableSmartGroupsButton - A function to disable the Smart Groups button.
 * @param disableSmartGroupsButton - Whether the Smart Groups button should be disabled.
 * @param showInsights - Whether the Insights should be shown.
 * @param toggleInsights - A function to toggle the Insights.
 * @param areSomeToolResultsSelected - Whether some Tool Results are selected.
 * @param setSelectedToolResults - A function to set the selected Tool Results, used to reset them when we change labeling mode.
 * @param currentToolResult - The current selected Tool Result.
 * @param updateFilters - Function called whenever the selected filters are changed
 */
const LabelingHeader = ({
  mode,
  tool,
  toolParent,
  isShared,
  toolLabels,
  setGeneratingSmartGroups,
  setDisableSmartGroupsButton,
  disableSmartGroupsButton,
  showInsights,
  toggleInsights,
  areSomeToolResultsSelected,
  labelMetrics,
  currentToolResult,
  updateFilters,
}: Props) => {
  const [params] = useQueryParams()
  const history = useHistory()
  const queryFilters = params as QsFilters
  const { group_by, groups_id } = queryFilters

  // We need to handle this state locally, since we only want to fetch when the user finishes modifying the ranges,
  // but we still want to manage the state
  const [localPredictionScoreMin, setLocalPredictionScoreMin] = useState(
    queryFilters.prediction_score_min ? parseFloat(queryFilters.prediction_score_min) : undefined,
  )
  const [localPredictionScoreMax, setLocalPredictionScoreMax] = useState(
    queryFilters.prediction_score_max ? parseFloat(queryFilters.prediction_score_max) : undefined,
  )
  const [predictionScoreError, setPredictionScoreError] = useState(
    isPredictionScoreInvalid(localPredictionScoreMin, localPredictionScoreMax),
  )

  const filtersBranch = getLabelingScreenFiltersBranch(queryFilters)

  const [showPopover, setShowPopover] = useState<'groupBy' | 'filters'>()
  const [lastGroupsId, setLastGroupsId] = useState(groups_id)
  const [lastSmartGroupFilters, setLastSmartGroupFilters] = useState(filtersBranch)
  const [showSmartGroupsFilterMessage, setShowSmartGroupsFilterMessage] = useState(true)

  const { allToolLabels } = useAllToolLabels()

  const inspections = useQuery(getterKeys.metricsFilteredInspectionsData(), () =>
    service.getInspections({ tool_parent_id: toolParent.id, has_items: true }),
  ).data?.data.results

  const { components: uniqueComponents, recipeParents } = useToolRecipeParentsAndComponents(toolParent.id)

  const currentToolsLabelIds = toolLabels?.map(lbl => lbl.id)

  const archivedOrRemovedToolLabels = useMemo(
    () => Object.keys(labelMetrics || {}).filter(labelId => !currentToolsLabelIds?.includes(labelId)),
    [currentToolsLabelIds, labelMetrics],
  )

  const additionalLabelOptions = useMemo(() => {
    const foundLabels: ToolLabel[] = []
    archivedOrRemovedToolLabels.forEach(labelId => {
      const foundLabel = allToolLabels?.find(lbl => lbl.id === labelId)
      if (foundLabel) foundLabels.push(foundLabel)
    })
    return foundLabels
  }, [allToolLabels, archivedOrRemovedToolLabels])

  const allLabelOptionsForFilters = useMemo(() => {
    const filtersToUse = [...(toolLabels || []), ...(additionalLabelOptions || [])]

    return filtersToUse.sort((lblA, lblB) => lblA.value.localeCompare(lblB.value))
  }, [additionalLabelOptions, toolLabels])

  const smartGroupsActive = areSmartGroupsActive(queryFilters)
  const { predictionScoreSortingActive, predictionScoreFiltersActive, dateFiltersActive } =
    areFiltersActive(queryFilters)

  const groupByCount = queryFilters.group_by ? 1 : 0
  const filtersCount = useMemo(() => {
    return getFiltersCount(params)
  }, [params])

  const showInsightsButton =
    tool?.specification_name && INSIGHTS_STREAM_TOOL_SPECIFICATION_NAMES.includes(tool.specification_name)

  const labelValue = useMemo(() => {
    return getUserLabelValue(queryFilters)
  }, [queryFilters])

  const startValue = queryFilters.start ? momentTz(queryFilters.start) : null
  const endValue = queryFilters.end ? momentTz(queryFilters.end) : null

  const { labelMetrics: predictionLabelMetrics } = useLabelMetrics({
    paramFilters: { tool_parent_id: toolParent.id },
    type: 'tool_result_label_site',
  })

  // Add options to the prediction label filter in case there are metrics for those labels
  const extraLabelsPredictionFilter = useMemo(() => {
    if (!predictionLabelMetrics) return []

    const countsByLabelId: { [labelId: string]: number } = {}

    const groupedByLabelId = groupBy(predictionLabelMetrics, data => data.labels.tool_label_id)
    for (const labelId of Object.keys(groupedByLabelId)) {
      const combinedOutcomeCounts = combineOutcomeCounts(groupedByLabelId[labelId]!)

      countsByLabelId[labelId] = combinedOutcomeCounts.reduce((prev, current) => prev + current.count, 0)
    }

    const extraLabelIds = Object.keys(countsByLabelId).filter(
      labelId => !allLabelOptionsForFilters.find(label => label.id === labelId),
    )
    const extraLabels = allToolLabels?.filter(label => extraLabelIds.includes(label.id)) || []

    return extraLabels
  }, [predictionLabelMetrics, allLabelOptionsForFilters, allToolLabels])

  // This effect is in charge of disabling the smart groups button until the filters change,
  // it also handles the filter info message
  useEffect(() => {
    if (lastSmartGroupFilters !== filtersBranch) {
      if (lastGroupsId && smartGroupsActive && showSmartGroupsFilterMessage) {
        info({ title: 'Run Smart Group again to see accurate filter results' })
        setShowSmartGroupsFilterMessage(false)
      }

      setDisableSmartGroupsButton(false)
    } else {
      if (smartGroupsActive) setDisableSmartGroupsButton(true)
    }
  }, [
    disableSmartGroupsButton,
    filtersBranch,
    lastGroupsId,
    lastSmartGroupFilters,
    setDisableSmartGroupsButton,
    showSmartGroupsFilterMessage,
    smartGroupsActive,
  ])

  const handleSmartGroupsGeneration = async () => {
    if (!toolParent) return
    const { group_by, groups_id, ...restParams } = queryFilters

    setGeneratingSmartGroups(true)
    updateFilters({ group_by: 'smart-group' }, 'gallery')

    // We want to consider deleted for smart groups only if no label is selected, or if discarded label is selected
    const res = await service.generateSmartGroups(toolParent.id, { ...restParams })
    if (res.type !== 'success') {
      appendDataToQueryString(history, { group_by: undefined })
      setGeneratingSmartGroups(false)
      if (res.type === 'error' && res.data.code === backendErrorCodes.smartGroupsNotEnoughResults) {
        return error({ title: 'Not enough images to generate smart groups' })
      }
      return error({ title: 'Failed to generate smart groups.' })
    }

    // Here we want to use this util to navigate, instead of the updateFilters prop, as we don't want to push an entry to the
    // location history, doing that would cause the browser "back" button to return to a smart groups blank state
    const updatedParams = appendDataToQueryString(history, { groups_id: res.data.groups_id })

    setLastSmartGroupFilters(getLabelingScreenFiltersBranch(updatedParams))
    setLastGroupsId(res.data.groups_id)
    setDisableSmartGroupsButton(true)
  }

  const handleGroupBySelect = (value: string) => {
    let filtersToApply: QsFilters = { group_by: value || undefined }
    if (value === 'label') {
      filtersToApply = { ...filtersToApply, ...getUpdatedUserLabelFilters(params) }
    }
    if (value === 'predicted') {
      filtersToApply.prediction_label_id__in = undefined
      filtersToApply.calculated_outcome__in = undefined
      filtersToApply.user_label_set_isnull = undefined
    }

    filtersToApply.groups_id = undefined

    if (value === 'smart-group') {
      if (lastGroupsId && lastSmartGroupFilters === filtersBranch) {
        filtersToApply.groups_id = lastGroupsId
        filtersToApply.group_by = 'smart-group'
      } else {
        setShowPopover(undefined)
        return handleSmartGroupsGeneration()
      }
    }

    setShowPopover(undefined)
    updateFilters(filtersToApply, 'gallery')
  }

  const handleUserLabelSelect = (value: string) => {
    updateFilters(getUpdatedUserLabelFilters(params, value))
  }

  const handlePredictedLabelSelect = (value: string) => {
    updateFilters({ prediction_label_id__in: value || undefined })
  }

  const handleBatchSelect = (value: string) => {
    updateFilters({ inspection_id: value || undefined })
  }

  const handleOutcomeSelect = (value: string) => {
    updateFilters({ calculated_outcome__in: value || undefined })
  }

  const handleDateChange = (value: [Moment | null, Moment | null] | undefined) => {
    if (!value || (value[0] === null && value[1] === null)) {
      // This means user clicked on the x to reset values, or on all time filter
      updateFilters({ start: undefined, end: undefined })
      return
    }

    const start = value[0]?.startOf('day')
    const end = value[1]?.endOf('day')
    if (!start || !end) return
    updateFilters({
      start: start ? start.toISOString(true) : undefined,
      end: end ? end.toISOString(true) : undefined,
    })
  }

  const handleRecipeSelect = (value: string) => {
    updateFilters({ recipe_parent_id: value || undefined })
  }

  const handleComponentSelect = (value: string) => {
    updateFilters({ component_id: value })
  }

  const handleScoreBySelect = (value: string) => {
    updateFilters({ ordering: value })
  }

  const predictionFilterOptions = useMemo(() => {
    return [
      ...filterOutUnusedLabels(allLabelOptionsForFilters).filter(label => !isNonTrainingLabel(label)),
      ...extraLabelsPredictionFilter,
    ].sort(sortByValueAndSeverity)
  }, [allLabelOptionsForFilters, extraLabelsPredictionFilter])

  const userLabels = useMemo(() => {
    return filterOutDerivativeLabels(allLabelOptionsForFilters).sort(sortByValueAndSeverity)
  }, [allLabelOptionsForFilters])

  const userLabelSelectOptions = useMemo(() => {
    const labelFilterOptionsList = getToolLabelOptions(userLabels, 'gallery-filters-label')
    return [
      { value: '', dataTestId: 'gallery-filters-blank-option', content: <>&mdash;</> },
      { value: 'unlabeled', dataTestId: 'gallery-filters-label-unlabeled-option' },
      ...labelFilterOptionsList,
    ]
  }, [userLabels])

  const predictionSelectOptions = () => {
    const filterOptionsList = getToolLabelOptions(predictionFilterOptions, 'gallery-filters-predicted-label')
    return [
      { value: '', dataTestId: 'gallery-filters-predicted-blank-option', content: <>&mdash;</> },
      ...filterOptionsList,
    ]
  }

  const productSelectOptions = useMemo(() => {
    const uniqueComponentsOptions =
      uniqueComponents?.map<SelectOption>(component => {
        return {
          key: component.id,
          value: component.id,
          dataTest: 'gallery-filters-product-option',
          dataTestId: `gallery-filters-product-${component.component_name}-option`,
          content: component.component_name,
        }
      }) || []

    return [
      { value: '', content: <>&mdash;</>, dataTestId: 'gallery-filters-component-blank-option' },
      ...uniqueComponentsOptions,
    ]
  }, [uniqueComponents])

  const insightsButtonDisabled = useMemo(() => {
    if (mode === 'gallery') return false
    return !currentToolResult?.insight_image
  }, [currentToolResult?.insight_image, mode])

  // See top of the file for backend limitations
  const outcomeDisabled = !!queryFilters.prediction_label_id__in?.length || group_by === 'predicted'

  const predictionScoreDisabled = useMemo(() => {
    if (!queryFilters.showPredictionScore) {
      return true
    }
    return dateFiltersActive
  }, [dateFiltersActive, queryFilters.showPredictionScore])

  const datePickerDisabled = !!queryFilters.showPredictionScore || predictionScoreFiltersActive

  const enableSmartGroups = () => {
    if (!tool) return false
    // Smart groups are enabled if key is true, or key is missing
    return !!tool.inference_user_args.generate_embeddings || tool.inference_user_args.generate_embeddings === undefined
  }

  const onShowPredictionScoreChange = (activateScore: boolean) => {
    const newFilters: QsFilters = {}
    newFilters.showPredictionScore = activateScore ? 'true' : undefined

    if (activateScore && !dateFiltersActive && queryFilters.ordering !== '-prediction_score') {
      info({ title: 'Sorting by score: High Score to Low' })
      newFilters.ordering = '-prediction_score'
    }

    if (!activateScore) {
      newFilters.prediction_score_max = undefined
      newFilters.prediction_score_min = undefined
      setLocalPredictionScoreMax(undefined)
      setLocalPredictionScoreMin(undefined)
    }

    updateFilters(newFilters)
  }

  const debouncedSetFiltersInQs = useMemo(() => {
    return debounce((filters: QsFilters) => {
      updateFilters(filters)
    }, 800)
  }, [updateFilters])

  const onPredictionScoreChange = (option: 'min' | 'max', newValue?: number) => {
    const filters: QsFilters = {}
    let newPredictionScoreInvalid = false
    if (!predictionScoreSortingActive) {
      filters.ordering = '-prediction_score'
    }
    if (option === 'min') {
      setLocalPredictionScoreMin(newValue)
      filters.prediction_score_min = isNumber(newValue) ? newValue.toString() : undefined
      if (isPredictionScoreInvalid(newValue, localPredictionScoreMax)) newPredictionScoreInvalid = true
    }
    if (option === 'max') {
      setLocalPredictionScoreMax(newValue)
      filters.prediction_score_max = isNumber(newValue) ? newValue.toString() : undefined
      if (isPredictionScoreInvalid(localPredictionScoreMin, newValue)) newPredictionScoreInvalid = true
    }

    if (newPredictionScoreInvalid) {
      return setPredictionScoreError(true)
    } else {
      setPredictionScoreError(false)
    }

    debouncedSetFiltersInQs(filters)
  }

  const GROUP_BY_OPTIONS = [
    ...(enableSmartGroups() ? [{ id: 'smart-group', label: 'Smart Group' }] : []),
    { id: 'predicted', label: 'Prediction' },
    { id: 'label', label: 'Label' },
  ]

  const sortByFilterOptions = useMemo<SelectOption[]>(() => {
    const disabledByPredictionScore = !!queryFilters.showPredictionScore
    return [
      {
        value: '-created_at',
        dataTestId: 'gallery-filters-sort-by-new-to-old',
        disabled: disabledByPredictionScore,
        content: 'New to Old',
        tooltip: {
          tooltipOverlayClassName: Styles.tooltipSortBy,
          tooltipTitle: getSortFilterTooltipTitle({ sortType: 'date', filter: 'score' }),
          tooltipCondition: disabledByPredictionScore,
        },
      },
      {
        value: 'created_at',
        dataTestId: 'gallery-filters-sort-by-old-to-new',
        disabled: disabledByPredictionScore,
        content: 'Old to New',
        tooltip: {
          tooltipOverlayClassName: Styles.tooltipSortBy,
          tooltipTitle: getSortFilterTooltipTitle({ sortType: 'date', filter: 'score' }),
          tooltipCondition: disabledByPredictionScore,
        },
      },
      {
        value: '-prediction_score',
        dataTestId: 'gallery-filters-sort-by-high-to-low',
        disabled: dateFiltersActive,
        content: 'High Score to Low',
        tooltip: {
          tooltipOverlayClassName: Styles.tooltipSortBy,
          tooltipTitle: getSortFilterTooltipTitle({ sortType: 'score', filter: 'date' }),
          tooltipCondition: dateFiltersActive,
        },
      },
      {
        value: 'prediction_score',
        dataTestId: 'gallery-filters-sort-by-low-to-high',
        disabled: dateFiltersActive,
        content: 'Low Score to High',
        tooltip: {
          tooltipOverlayClassName: Styles.tooltipSortBy,
          tooltipTitle: getSortFilterTooltipTitle({ sortType: 'score', filter: 'date' }),
          tooltipCondition: dateFiltersActive,
        },
      },
    ]
  }, [dateFiltersActive, queryFilters.showPredictionScore])

  return (
    <div className={Styles.headerActionsContainer}>
      <div className={Styles.headerButtons}>
        {!areSomeToolResultsSelected && (
          <>
            {enableSmartGroups() && (
              <PrismTooltip title="Group images by similarity">
                <Button
                  badge={<PrismSmartGroupIcon />}
                  size="small"
                  type="secondary"
                  disabled={disableSmartGroupsButton}
                  onClick={handleSmartGroupsGeneration}
                  data-testid="labeling-header-smart-group"
                >
                  Smart Group
                </Button>
              </PrismTooltip>
            )}
            <Button
              badge={
                groupByCount > 0 ? (
                  <div className={Styles.countContainer}>{groupByCount}</div>
                ) : (
                  <PrismArrowIcon direction={showPopover === 'groupBy' ? 'up' : 'down'} />
                )
              }
              invertBadgePosition
              size="small"
              type="secondary"
              onClick={() => setShowPopover(showPopover !== 'groupBy' ? 'groupBy' : undefined)}
              className={`${Styles.parentPosition} ${showPopover === 'groupBy' ? Styles.activeButton : ''}`}
              data-testid="labeling-header-group-by"
            >
              Group By
              {showPopover === 'groupBy' && (
                <PrismPopover
                  className={Styles.popoverRadioContainer}
                  onClose={() => setShowPopover(undefined)}
                  bodyClassName={Styles.filtersPopoverBody}
                >
                  <Radio.Group value={queryFilters.group_by || ''} onChange={e => handleGroupBySelect(e.target.value)}>
                    <Radio value="" className={Styles.groupByRadioButton}>
                      Off
                    </Radio>

                    {GROUP_BY_OPTIONS.map(option => (
                      <Radio
                        key={option.id}
                        value={option.id}
                        className={Styles.groupByRadioButton}
                        data-testid={`labeling-header-group-by-${option.label.toLowerCase()}`}
                      >
                        {option.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </PrismPopover>
              )}
            </Button>

            <Button
              badge={
                filtersCount > 0 ? (
                  <div className={Styles.countContainer}>{filtersCount}</div>
                ) : (
                  <PrismArrowIcon direction={showPopover === 'filters' ? 'up' : 'down'} />
                )
              }
              invertBadgePosition
              size="small"
              type="secondary"
              className={`${Styles.parentPosition} ${showPopover === 'filters' ? Styles.activeButton : ''}`}
              onClick={() => setShowPopover(showPopover !== 'filters' ? 'filters' : undefined)}
              data-testid="labeling-screen-filters-button"
              data-test-attribute={filtersCount.toString()}
            >
              Filters
              {showPopover === 'filters' && (
                <PrismPopover
                  className={Styles.filtersPopoverPosition}
                  onClose={e => {
                    const target = e.target as HTMLElement
                    // We want to avoid closing the popover if the user is clicking on the date range picker
                    if (target.closest('.ant-picker-panel-container')) return
                    setShowPopover(undefined)
                  }}
                  bodyClassName={Styles.filtersPopoverBody}
                >
                  <Token label="sort by" labelClassName={Styles.selectLabel}>
                    <PrismSelect
                      data-testid="gallery-filters-sort-by"
                      value={queryFilters.ordering}
                      onSelect={handleScoreBySelect}
                      options={sortByFilterOptions}
                    />
                  </Token>

                  <Token label="label" labelClassName={Styles.selectLabel}>
                    <PrismSelect
                      data-testid="gallery-filters-label"
                      value={labelValue}
                      onSelect={handleUserLabelSelect}
                      options={userLabelSelectOptions}
                      className={Styles.selectWithIcon}
                    />
                  </Token>

                  <Token label="prediction" labelClassName={Styles.selectLabel}>
                    <PrismTooltip
                      condition={!!queryFilters.calculated_outcome__in?.length}
                      title="Cannot filter by prediction and outcome at the same time"
                    >
                      <PrismSelect
                        data-testid="gallery-filters-predicted-label"
                        value={queryFilters.prediction_label_id__in || ''}
                        onSelect={handlePredictedLabelSelect}
                        disabled={!!queryFilters.calculated_outcome__in?.length}
                        options={predictionSelectOptions()}
                        wrapperClassName={Styles.selectLabelWrapper}
                        className={Styles.selectWithIcon}
                      />
                    </PrismTooltip>
                  </Token>

                  <Token
                    label="score"
                    secondaryLabel={
                      <Switch
                        data-testid="gallery-filters-score-toggle"
                        checked={!!queryFilters.showPredictionScore}
                        onChange={activateScore => {
                          onShowPredictionScoreChange(activateScore)
                        }}
                      />
                    }
                    labelClassName={Styles.selectLabel}
                  >
                    <div className={`${Styles.scoreContainer} ${predictionScoreError ? Styles.inputError : ''}`}>
                      <PrismTooltip
                        condition={dateFiltersActive}
                        title="Cannot filter by score and date at the same time"
                      >
                        <PrismInputNumber
                          data-testid="gallery-filters-score-min"
                          onChange={value => {
                            onPredictionScoreChange('min', isNumber(value) ? value : undefined)
                          }}
                          value={isNumber(localPredictionScoreMin) ? localPredictionScoreMin : undefined}
                          placeholder="Low"
                          max={100}
                          disabled={predictionScoreDisabled}
                          size="small"
                          className={Styles.inputContainer}
                        />
                      </PrismTooltip>
                      <PrismTooltip
                        condition={dateFiltersActive}
                        title="Cannot filter by score and date at the same time"
                      >
                        <PrismInputNumber
                          data-testid="gallery-filters-score-max"
                          onChange={value => {
                            onPredictionScoreChange('max', isNumber(value) ? value : undefined)
                          }}
                          value={isNumber(localPredictionScoreMax) ? localPredictionScoreMax : undefined}
                          placeholder="High"
                          max={100}
                          disabled={predictionScoreDisabled}
                          size="small"
                          className={Styles.inputContainer}
                        />
                      </PrismTooltip>
                    </div>

                    {predictionScoreError && (
                      <div className={Styles.formTextError}>Invalid prediction score values</div>
                    )}
                  </Token>

                  {isShared && (
                    <>
                      <Token label="Product" labelClassName={Styles.selectLabel}>
                        <PrismSelect
                          data-testid="gallery-filters-component"
                          value={queryFilters.component_id}
                          onSelect={handleComponentSelect}
                          options={productSelectOptions}
                        />
                      </Token>

                      <Token label="Recipe" className={Styles.selectWrapper} labelClassName={Styles.selectLabel}>
                        <PrismSelect
                          data-testid="gallery-filters-recipe"
                          value={queryFilters.recipe_parent_id}
                          onSelect={handleRecipeSelect}
                          options={[
                            { value: '', content: <>&mdash;</>, dataTestId: 'gallery-filter-recipe-blank-option' },
                            ...(recipeParents?.map<SelectOption>(recipeParent => ({
                              title: recipeParent.name,
                              value: recipeParent.id,
                              id: recipeParent.id,
                              dataTestId: `gallery-filters-batch-${recipeParent.name}-option`,
                              dataTest: 'gallery-filters-batch-option',
                            })) || []),
                          ]}
                        />
                      </Token>
                    </>
                  )}

                  <Token label="Outcome" labelClassName={Styles.selectLabel}>
                    <PrismTooltip
                      title={
                        group_by === 'predicted'
                          ? 'Cannot filter by outcome and group by prediction at the same time'
                          : 'Cannot filter by outcome and prediction at the same time'
                      }
                      condition={outcomeDisabled}
                    >
                      <PrismSelect
                        data-testid="gallery-filters-outcome"
                        value={queryFilters.calculated_outcome__in}
                        onSelect={handleOutcomeSelect}
                        disabled={outcomeDisabled}
                        options={[
                          { value: '', dataTestId: 'gallery-filters-outcome-blank-option', content: <>&mdash;</> },
                          ...['pass', 'fail', 'unknown'].map(outcome => ({
                            key: outcome,
                            value: outcome,
                            dataTestId: `gallery-filters-outcome-${outcome.toLocaleLowerCase()}-option`,
                          })),
                        ]}
                      />
                    </PrismTooltip>
                  </Token>

                  <Token label="batch" labelClassName={Styles.selectLabel}>
                    <PrismSelect
                      data-testid="gallery-filters-batch"
                      value={queryFilters.inspection_id}
                      onSelect={handleBatchSelect}
                      options={[
                        { value: '', content: <>&mdash;</>, dataTestId: 'gallery-filter-batch-blank-option' },
                        ...(inspections?.map<SelectOption>(inspection => ({
                          title: inspection.name,
                          value: inspection.id,
                          id: inspection.id,
                          dataTestId: `gallery-filters-batch-${inspection.name}-option`,
                          dataTest: 'gallery-filters-batch-option',
                        })) || []),
                      ]}
                    />
                  </Token>

                  <Token
                    label="date inspected"
                    labelClassName={Styles.selectLabel}
                    valueClassName={!!predictionScoreFiltersActive ? Styles.selectWrapperDisabled : ''}
                  >
                    <PrismTooltip
                      title="Cannot filter or sort by date and score at the same time"
                      overlayClassName={Styles.dateTooltipPosition}
                      condition={datePickerDisabled}
                    >
                      <span>
                        <RangePicker
                          disabled={datePickerDisabled}
                          data-testid="gallery-filters-date-inspected"
                          className={Styles.datePickerInPopover}
                          placeholder={['Start', 'End']}
                          value={[startValue, endValue]}
                          onChange={handleDateChange}
                          size="large"
                        />
                      </span>
                    </PrismTooltip>
                  </Token>
                </PrismPopover>
              )}
            </Button>
          </>
        )}
        {showInsightsButton && (
          <ToggleButton
            className={showInsights ? undefined : Styles.insightsButton}
            title="insights"
            onClick={toggleInsights}
            active={showInsights}
            hotkey="I"
            data-testid="gallery-filters-insights"
            disabled={insightsButtonDisabled}
          />
        )}
      </div>
    </div>
  )
}

export default LabelingHeader

const getSortFilterTooltipTitle = ({ sortType, filter }: { sortType: string; filter: string }) =>
  `Cannot sort by ${sortType} and filter by ${filter} at the same time`

const getToolLabelOptions = (labels: ToolLabel[], testIdPrefix: string) => {
  return labels.map(toolLabel => {
    const dataTestId = `${testIdPrefix}-${toolLabel.severity}-${toolLabel.value.toLowerCase()}-option`
    const labelName = getLabelName(toolLabel)
    return {
      key: toolLabel.id,
      value: toolLabel.id,
      dataTestId,
      content: (
        <PrismResultButton
          severity={getDisplaySeverity(toolLabel)}
          value={labelName}
          size="small"
          type="pureWhite"
          className={Styles.prismResultInDropdown}
        />
      ),
    }
  })
}

const getFiltersCount = (params: QsFilters) => {
  const singleFilters: QsFilterKey[] = ['inspection_id', 'calculated_outcome__in']

  // count starts at 1, because the unlabeled option counts as one filter
  let count = 1

  singleFilters.forEach(filter => {
    if (params[filter]) count++
  })

  if (params.start || params.end) count++

  if (params.prediction_score_max || params.prediction_score_max) count++

  if (params.prediction_label_id__in) count++

  if (params.recipe_parent_id) count++

  if (params.component_id) count++

  // This option means we fetch all toolResults without filter, so, it should be removed from the count
  if (!params.user_label_set_isnull && !params.user_label_id__in) count--

  return count
}

export const getUpdatedUserLabelFilters = (params: QsFilters, newValue?: string): QsFilters => {
  const filters: QsFilters = {
    user_label_set_isnull: undefined,
    user_label_id__in: undefined,
    user_outcome: undefined,
  }

  filters.ordering = params?.ordering || '-created_at'

  if (newValue === 'unlabeled') filters.user_label_set_isnull = 'true'
  else if (newValue) filters.user_label_id__in = newValue

  return filters
}

export const getUserLabelValue = (filterParams: QsFilters) => {
  if (filterParams.user_label_set_isnull === 'true') return 'unlabeled'

  return filterParams.user_label_id__in || ''
}

const isPredictionScoreInvalid = (min?: number, max?: number) => {
  if (min === undefined || max === undefined) {
    return false
  }

  return min > max
}
