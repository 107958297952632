import { useEffect, useState } from 'react'
import React from 'react'

import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { Line as KonvaLine } from 'konva/lib/shapes/Line'
import { clamp } from 'lodash'
import { Circle, Group, Line } from 'react-konva'

import {
  ACTIVE_STROKE_WIDTH,
  BORDER_STROKE_WIDTH,
  BOUNDARY_SELECTED_STROKE,
  BOUNDARY_STROKE_HIT_WIDTH,
  BOUNDARY_UNSELECTED_STROKE,
  INACTIVE_STROKE,
  SHADOW_HOVER_STROKE,
  SHADOW_HOVER_STROKE_WIDTH,
  SHADOW_STROKE,
  SHADOW_STROKE_WIDTH,
  VERTEX_DEFAULT_RADIUS,
  VERTEX_FILL,
  VERTEX_HOVER_FILL,
  VERTEX_HOVER_STROKE,
  VERTEX_SELECTED_FILL,
  VERTEX_SELECTED_RADIUS,
  VERTEX_SELECTED_STROKE,
  VERTEX_STROKE,
  VERTEX_STROKE_WIDTH,
} from '../EditableShape'
import { Point } from './Point'

type KonvaEventHandler<Event> = (e: KonvaEventObject<Event>) => void

export interface VertexProps {
  point: Point
  selected?: boolean
  onDragMove?: KonvaEventHandler<DragEvent>
  provisional?: boolean
  onClick?: KonvaEventHandler<MouseEvent>
  onDragEnd?: KonvaEventHandler<DragEvent>
  scaledWidth: number
  scaledHeight: number
}

/**
 * Renders an circle representing a polygon vertex that can be dragged and selected.
 * @param point The location of the vertex.
 * @param selected Is the vertex currently selected
 * @param onDragMove A callback called when dragging the vertex
 * @param provisional A provisional vertex is one that hasn't been added to the polygon yet. It's pinned to the mouse
 * and only gets added to the polygon on a mouse down event. A provisional vertex shouldn't have any event listeners on
 * it and shouldn't be draggable.
 * @param onClick A callback used when the vertex is clicked on.
 * @param onDragEnd A callback called at the end of dragging the vertex
 * @returns
 */
export function Vertex({
  point,
  selected,
  onDragMove,
  provisional,
  onClick,
  onDragEnd,
  scaledWidth,
  scaledHeight,
}: VertexProps) {
  const [mouseOver, setMouseOver] = useState(false)
  const circleRef = React.useRef<Konva.Circle>(null)

  useEffect(() => {
    if (mouseOver) {
      circleRef.current!.getStage()!.container()!.style.cursor = 'move'
    } else {
      circleRef.current!.getStage()!.container()!.style.cursor = 'default'
    }
  }, [mouseOver])

  return (
    <Circle
      radius={selected ? VERTEX_SELECTED_RADIUS : VERTEX_DEFAULT_RADIUS}
      fill={mouseOver ? VERTEX_HOVER_FILL : selected ? VERTEX_SELECTED_FILL : VERTEX_FILL}
      stroke={mouseOver ? VERTEX_HOVER_STROKE : selected ? VERTEX_SELECTED_STROKE : VERTEX_STROKE}
      strokeWidth={VERTEX_STROKE_WIDTH}
      x={point.x}
      y={point.y}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
      onDragMove={onDragMove}
      draggable={!provisional}
      dragBoundFunc={pos => {
        const clampedX = clamp(pos.x, 0, scaledWidth)
        const clampedY = clamp(pos.y, 0, scaledHeight)
        return { x: clampedX, y: clampedY }
      }}
      listening={!provisional}
      onClick={onClick}
      onDragEnd={onDragEnd}
      ref={circleRef}
    />
  )
}

export interface PolygonProps {
  points: Point[]
  onDragMove?: KonvaEventHandler<DragEvent>
  onDragEnd?: KonvaEventHandler<DragEvent>
  draggable?: boolean
  onTransform?: KonvaEventHandler<Event>
  onMouseDown?: KonvaEventHandler<MouseEvent>
  onMouseOver?: KonvaEventHandler<MouseEvent>
  onMouseOut?: KonvaEventHandler<MouseEvent>
  ref: React.RefObject<KonvaLine>
  dragBoundFunc?: (pos: Konva.Vector2d) => Konva.Vector2d
  onClick?: KonvaEventHandler<MouseEvent>
}

/**
 * Renders the interior of a polygon and handles interaction with the interior of the polygon. The interior here will
 * not be visible, since it is rendered using the global compositing mode 'destination-out'. This mode simply erases any
 * content over the area that would be filled by the shape. By first applying a rectangular mask over the whole image
 * and then rendering a polygon with this mode we achieve the effect of our polygon cutting a hole in a shadow that
 * covers the rest of the image.
 * @param draggable Can we drag this polygon?
 * @param onDragMove Callback for dragmove events
 * @param onDragEnd Callback for dragend events
 * @param onMouseDown Callback for mousedown events
 * @param onMouseOver Callback for mouseover events
 * @param onMouseOut Callback for mouseout events
 * @param onClick Callback for click events
 * @param dragBoundFunc Callback called when dragging to constrain the polygon's movements
 * @param ref A ref to the polygon that is forwarded to the Konva node for the polygon
 * @param points The vertices of the polygon in order.
 */
export const Polygon = React.forwardRef<KonvaLine, PolygonProps>((props: PolygonProps, externalRef) => {
  const {
    draggable = true,
    onDragMove,
    onDragEnd,
    onTransform,
    onMouseDown,
    dragBoundFunc,
    onClick,
    onMouseOver,
    onMouseOut,
    points,
  } = props

  const flatPoints = Point.flattenPoints(points)

  const handleMouseOver = (e: KonvaEventObject<MouseEvent>) => {
    onMouseOver && onMouseOver(e)
  }

  const handleMouseOut = (e: KonvaEventObject<MouseEvent>) => {
    onMouseOut && onMouseOut(e)
  }

  return (
    <Line
      points={flatPoints}
      fill={'black'}
      draggable={draggable}
      closed
      ref={externalRef}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onTransform={onTransform}
      onMouseDown={onMouseDown}
      globalCompositeOperation={'destination-out'}
      name={'aoi-fill'}
      dragBoundFunc={dragBoundFunc}
      onClick={onClick}
    />
  )
})

export interface SegmentProps {
  startEndPoints: [number, number, number, number]
  onHover?: KonvaEventHandler<MouseEvent>
  onEndHover?: KonvaEventHandler<MouseEvent>
  onMouseDown?: KonvaEventHandler<MouseEvent>
  selected?: boolean
}

/**
 * Renders a single segment of the polygon boundary. Having each segment rendered separately allows us to differentiate
 * between hover events on the different segments and ensure that when we add a new vertex on an existing segment we
 * update the polygon's list of points in the right place.
 * @param startEndPoints The start and end points in [x1, y1, x2, y2] format
 * @param onHover A callback for when we begin hovering our cursor over the segment
 * @param onEndHover A callback for when we end hovering our cursor over the segment
 * @param onMouseDown A callback for mousedown events on the segment
 * @param selected Is the segment selected? Currently, selection just changes the color of the segment and is not
 * directly controllable by mouse interaction
 */
export function Segment({ startEndPoints, onHover, onEndHover, onMouseDown, selected }: SegmentProps) {
  const stroke = selected ? BOUNDARY_SELECTED_STROKE : BOUNDARY_UNSELECTED_STROKE
  return (
    <Line
      points={startEndPoints}
      stroke={stroke}
      strokeWidth={ACTIVE_STROKE_WIDTH}
      hitStrokeWidth={BOUNDARY_STROKE_HIT_WIDTH}
      onMouseMove={onHover}
      onMouseOver={onHover}
      onMouseOut={onEndHover}
      onMouseDown={onMouseDown}
    />
  )
}

/**
 * Renders a closed boundary that doesn't itself have any interaction capabilities. This will only be rendered for completed polygons. This will be rendered when
 *  1) We have duplicated an AOI and as a result have multiple copies of the AOI, only one of which can be selected and dragged at a time. Each such polygon will have one of these boundaries rendered.
 *  2) We click on a polygon in the vertex editing mode and activate the transformer, allowing us to scale the polygon and drag it around. Again here we don't want any direct interaction with the boundary.
 * @param selected Is the polygon currently selected? This changes the boundary color
 * @param inactive An inactive AOI should have a shadow appear upon mouseover
 * @param points The vertices of the polygon
 * @param mouseOver Is the mouse currently over the boundary?
 */
export function InertClosedBoundary({
  selected,
  inactive,
  points,
  mouseOver,
}: {
  selected: boolean
  inactive: boolean
  points: Point[]
  mouseOver?: boolean
}) {
  const n = points.length
  const segments: [number, number, number, number][] = []

  if (n > 1) {
    for (let i = 0; i < points.length - 1; i++) {
      segments.push([points[i]!.x, points[i]!.y, points[i + 1]!.x, points[i + 1]!.y])
    }
    segments.push([points[n - 1]!.x, points[n - 1]!.y, points[0]!.x, points[0]!.y])
  }

  return (
    <>
      {segments.map((coords, ix) => {
        return (
          <React.Fragment key={ix}>
            {inactive && (
              <Line
                points={coords}
                stroke={mouseOver ? SHADOW_HOVER_STROKE : SHADOW_STROKE}
                strokeWidth={mouseOver ? SHADOW_HOVER_STROKE_WIDTH : SHADOW_STROKE_WIDTH}
                closed
                lineJoin={'round'}
                key={'' + ix + ':shadow'}
              />
            )}
            <Line
              points={coords}
              stroke={selected ? BOUNDARY_SELECTED_STROKE : inactive ? INACTIVE_STROKE : BOUNDARY_UNSELECTED_STROKE}
              strokeWidth={BORDER_STROKE_WIDTH}
              closed
              lineJoin={'round'}
              key={ix}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

export interface LiveBoundaryProps {
  points: Point[]
  closed: boolean
  onHover: (ix: number, e: KonvaEventObject<MouseEvent>) => void
  onEndHover?: KonvaEventHandler<MouseEvent>
  onMouseDown: (ix: number, e: KonvaEventObject<MouseEvent>) => void
  selectedSegmentIx?: number
}

/**
 * This renders a boundary as a collection of line segments between adjacent vertices each of which can be interacted
 * with separately
 * @param points The vertices of the boundary
 * @param closed Is the boundary closed, i.e. a completed polygon. If so we need to add an additional segment from the
 * last point to the first point
 * @param onHover A callback for when we hover over a segment which takes in the index of the segment over which we are
 * hovering
 * @param onEndHover A callback for when we stop hovering over a segment
 * @param onMouseDown A callback for mousedown events
 * @param selectedSegmentIx The index of a selected segment, used to set a flag on each segment.
 * @returns
 */
export function LiveBoundary({
  points,
  closed,
  onHover,
  onEndHover,
  onMouseDown,
  selectedSegmentIx,
}: LiveBoundaryProps) {
  const segments = points.slice(0, points.length - 1).map((_, ix) => {
    const startEndPoints: [number, number, number, number] = [
      points[ix]!.x,
      points[ix]!.y,
      points[ix + 1]!.x,
      points[ix + 1]!.y,
    ]
    return (
      <Segment
        selected={selectedSegmentIx !== undefined && ix === selectedSegmentIx}
        startEndPoints={startEndPoints}
        key={ix.toString()}
        onHover={e => onHover(ix, e)}
        onEndHover={onEndHover}
        onMouseDown={e => onMouseDown(ix, e)}
      />
    )
  })
  if (closed) {
    const startEndPoints: [number, number, number, number] = [
      points[points.length - 1]!.x,
      points[points.length - 1]!.y,
      points[0]!.x,
      points[0]!.y,
    ]
    const ix = points.length - 1
    segments.push(
      <Segment
        selected={false}
        startEndPoints={startEndPoints}
        key={ix.toString()}
        onHover={e => onHover(ix, e)}
        onEndHover={onEndHover}
        onMouseDown={e => onMouseDown(ix, e)}
      />,
    )
  }
  return <Group>{segments}</Group>
}
