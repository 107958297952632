import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { History } from 'history'
import { uniqBy } from 'lodash'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'

import { getterKeys, ItemsExpandedData, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import ImgFallback from 'components/Img/ImgFallback'
import { LabelCard, LoadingLabelCardList } from 'components/LabelCard/LabelCard'
import LabelsList from 'components/LabelList/LabelsList'
import { PrismElementaryCube, PrismInfoIcon } from 'components/prismIcons'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import VirtualizedCarousel from 'components/VirtualizedCarousel/VirtualizedCarousel'
import {
  useAllToolLabels,
  useConnectionStatus,
  useData,
  useDateTimePreferences,
  useQueryParams,
  useStationStatus,
  useToolLabels,
} from 'hooks'
import { DetailModal } from 'pages/ItemDetail/DetailModal'
import * as Actions from 'rdx/actions'
import {
  Inspection,
  ItemExpanded,
  RoutineWithAois,
  Station,
  SuccessResponseOnlyData,
  TimeSeriesResult,
  ToolLabel,
} from 'types'
import {
  appendItemOrToolResultIdPictureIdOrLastSelectedToQs,
  getAoisAndToolsFromRoutine,
  getImageFromPicture,
  getterAddOrUpdateResultsAndSort,
  getterAddPage,
  getToolResultLabels,
  sortByLabelKind,
  sortByNewestFirst,
  sortBySeverity,
} from 'utils'
import { MAX_RECENT_RESULTS } from 'utils/constants'

import { useTimeFrameInMs } from '../StationDetail'
import Styles from './StationDetailOverview.module.scss'

interface Props {
  inspection: Inspection | undefined
  itemsMetrics: TimeSeriesResult[] | undefined
  station: Station | undefined
  routines: RoutineWithAois[] | undefined
}

/**
 * Renders a carousel with Recent failed items
 *
 * https://www.figma.com/file/bJmWHlYXuZVaCJZF1MZ0mk/Station-Detail-(main)?node-id=5513%3A40500.
 *
 * @param inspection - current Inspection.
 * @param itemMetrics - current item metrics.
 * @param station - current Station.
 * @param routine - current inspection routines.
 */
export const RecentDefects = ({ inspection, itemsMetrics, station, routines }: Props) => {
  const dispatch = useDispatch()
  const { allToolLabels } = useAllToolLabels()
  const history = useHistory()
  const { timeZone, timeWithSecondsFormat } = useDateTimePreferences()

  const carouselRef = useRef<HTMLDivElement>(null)
  const scrollCarouselRef = useRef<(idx: number) => Promise<void>>()

  const [params] = useQueryParams()
  const { detail_item_id, detail_tool_result_id } = params

  const [liveModeActive, setLiveModeActive] = useState(true)

  const timeFrameInMs = useTimeFrameInMs()
  const connectionStatus = useConnectionStatus()

  const inspectionId = inspection?.id

  const inspectionTools = useMemo(() => {
    if (!routines) return
    return uniqBy(
      routines.flatMap(routine => {
        const { tools } = getAoisAndToolsFromRoutine(routine)
        return tools
      }),
      tool => tool.id,
    )
  }, [routines])

  const inspectionLabels = useToolLabels(inspectionTools)
  const minorLabels = useMemo(() => {
    return inspectionLabels?.filter(label => label.severity === 'minor')
  }, [inspectionLabels])

  const minorAnomalyInspectionLabelsIds = useMemo(() => {
    return inspectionLabels?.filter(toolLabel => toolLabel.severity === 'minor').map(toolLabel => toolLabel.id)
  }, [inspectionLabels])

  const areItemsWithMinorDefectsNeeded = useMemo(() => {
    // As we use this const to know if we should fetch items with minor defects (minor anomaly predicted labels),
    // we return true until labels are loaded and we are sure that there are no minor anomaly labels in the inspection tools.
    if (!minorAnomalyInspectionLabelsIds) return true
    return minorAnomalyInspectionLabelsIds.length > 0
  }, [minorAnomalyInspectionLabelsIds])

  const start = useMemo(() => {
    if (!timeFrameInMs) return
    const startMs = Date.now() - timeFrameInMs
    const start = moment(startMs).format()
    return start
  }, [timeFrameInMs])

  const refetchKey = `${timeFrameInMs}${connectionStatus}`

  const failedItemsRes = useQuery(
    inspection ? getterKeys.stationOverviewFailedItems(inspection.id) : undefined,
    inspection
      ? () =>
          service.getItemsExpanded({
            inspection_id: inspection.id,
            calculated_outcome__in: 'fail',
            start,
          })
      : undefined,
    { refetchKey },
  )
  const itemsWithMinorDefectsRes = useQuery(
    inspection && minorAnomalyInspectionLabelsIds && areItemsWithMinorDefectsNeeded
      ? getterKeys.stationOverviewFailedItems(`${inspection.id}-minor-defects`)
      : undefined,
    inspection
      ? () =>
          service.getItemsExpanded({
            inspection_id: inspection.id,
            prediction_label_id__in: minorAnomalyInspectionLabelsIds?.join(),
            start,
          })
      : undefined,
    { refetchKey },
  )
  const nextFailedItems = failedItemsRes.data?.data.next
  const nextItemsWithMinorDefects = itemsWithMinorDefectsRes.data?.data.next

  const recentItems = useData(inspection ? getterKeys.inspectionRecentItems(inspection.id) : undefined)
  const recentFailedItems = useMemo(() => {
    return recentItems?.results.filter(item => {
      if (item.calculated_outcome === 'fail') return true

      const itemMinorLabels = minorLabels ? getItemMinorSeverityLabels(item, minorLabels) : []

      return itemMinorLabels.length > 0
    })
  }, [minorLabels, recentItems?.results])

  const defectiveItems = useMemo(() => {
    // We want to show all the defective items in the recent defects carousel, this includes items with outcome === fail and items with some tool results that predicted a minor anomaly label.
    // So, we use 2 useQuery instances to fetch items with those conditions, and we merge them to avoid duplications.
    if (!failedItemsRes.data) return
    if (areItemsWithMinorDefectsNeeded && !itemsWithMinorDefectsRes.data) return

    if (itemsWithMinorDefectsRes.data) {
      return mergeItemsLists(failedItemsRes.data.data.results, itemsWithMinorDefectsRes.data.data.results)
    }
    return failedItemsRes.data.data.results
  }, [failedItemsRes, areItemsWithMinorDefectsNeeded, itemsWithMinorDefectsRes])

  const carouselKey = `${inspectionId}-${params.timeFrame}`
  // Reset livemode when switching to another metrics tab
  useEffect(() => {
    setLiveModeActive(true)
  }, [carouselKey])

  useEffect(() => {
    if (!inspectionId || !recentFailedItems || !liveModeActive) return

    const itemsToAdd = timeFrameInMs ? removeItemsOutOfTimeframe(recentFailedItems, timeFrameInMs) : recentFailedItems

    dispatch(
      Actions.getterUpdate({
        key: getterKeys.stationOverviewFailedItems(inspectionId),
        updater: prevRes =>
          getterAddOrUpdateResultsAndSort(prevRes, {
            results: itemsToAdd,
            sort: sortByNewestFirst,
            sliceEndIdx: MAX_RECENT_RESULTS,
          }),
      }),
    )
  }, [recentFailedItems, inspectionId, dispatch, liveModeActive, timeFrameInMs])

  const removeResultsOutOfTimeframe = useCallback(() => {
    removeItemsOutOfTimeframeFromGetterBranch({
      dispatch,
      itemsGetterKey: inspection?.id,
      items: failedItemsRes.data?.data.results,
      timeFrameInMs,
    })
    if (areItemsWithMinorDefectsNeeded) {
      removeItemsOutOfTimeframeFromGetterBranch({
        dispatch,
        itemsGetterKey: inspection ? `${inspectionId}-minor-defects` : undefined,
        items: itemsWithMinorDefectsRes.data?.data.results,
        timeFrameInMs,
      })
    }
  }, [
    areItemsWithMinorDefectsNeeded,
    dispatch,
    failedItemsRes.data?.data.results,
    inspection,
    inspectionId,
    itemsWithMinorDefectsRes.data?.data.results,
    timeFrameInMs,
  ])

  useEffect(() => {
    // we want to clean items out of the timeframe at the same time we update the metrics
    removeResultsOutOfTimeframe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsMetrics])

  const handleEndReached = async () => {
    if (!inspectionId) return

    const promises: Promise<void>[] = []

    if (nextFailedItems) {
      promises.push(fetchNextItemsPage(nextFailedItems, inspectionId, dispatch))
    }

    if (nextItemsWithMinorDefects) {
      promises.push(fetchNextItemsPage(nextItemsWithMinorDefects, `${inspectionId}-minor-defects`, dispatch))
    }

    await Promise.all(promises)
  }

  const stationStatus = useStationStatus(station)

  const showDefectsCarousel = defectiveItems && defectiveItems.length > 0

  const emptyState = useMemo(() => {
    if (showDefectsCarousel) return null
    if (stationStatus === 'loading' || (stationStatus === 'running' && !defectiveItems)) return <DefectsSkeleton />

    return <NoDefectsPlaceholder />
  }, [defectiveItems, showDefectsCarousel, stationStatus])

  return (
    <>
      {emptyState}

      {showDefectsCarousel && (
        <VirtualizedCarousel
          key={carouselKey}
          scrollRef={scrollCarouselRef}
          containerRef={carouselRef}
          title="Defective Items"
          carouselWrapperClassName={Styles.carouselWrapper}
          carouselHeaderClassName={Styles.carouselHeader}
          carouselActionsClassName={Styles.carouselActionsContainer}
          enableEdgesInteraction={false}
          onStartReached={async () => {
            setLiveModeActive(true)
          }}
          onEndReached={handleEndReached}
          onScroll={direction => {
            if (direction === 'right' && liveModeActive) {
              setLiveModeActive(false)
            }
          }}
          cards={defectiveItems || []}
          renderer={(item, firstCardRef) => {
            return (
              <RecentDefectCard
                firstCardRef={firstCardRef}
                item={item}
                key={item.id}
                data-test="recent-defects-card"
                allToolLabels={allToolLabels}
                history={history}
                timeZone={timeZone}
                timeWithSecondsFormat={timeWithSecondsFormat}
              />
            )
          }}
          description={
            <>
              {!liveModeActive && (
                <Button
                  type="tertiary"
                  size="small"
                  onClick={() => {
                    scrollCarouselRef.current?.(0)
                    if (liveModeActive || !scrollCarouselRef.current) return
                    setLiveModeActive(true)
                  }}
                >
                  View Most Recent
                </Button>
              )}
            </>
          }
        />
      )}

      {inspection && (detail_item_id || detail_tool_result_id) && (
        <DetailModal
          onRefresh={updatedItem => {
            // update items in both recent defective items lists
            dispatch(
              Actions.getterUpdate({
                key: getterKeys.stationOverviewFailedItems(inspection.id),
                updater: prevRes => itemRefreshUpdater(prevRes, updatedItem),
              }),
            )

            dispatch(
              Actions.getterUpdate({
                key: getterKeys.stationOverviewFailedItems(`${inspection.id}-minor-defects`),
                updater: prevRes => itemRefreshUpdater(prevRes, updatedItem),
              }),
            )
          }}
          itemsInitialRes={defectiveItems ? { results: defectiveItems } : undefined}
        />
      )}
    </>
  )
}

/**
 * Renders a Recent failed items card
 *
 * https://www.figma.com/file/bJmWHlYXuZVaCJZF1MZ0mk/Station-Detail-(main)?node-id=5513%3A40500.
 *
 * @param item - Failed item
 */
export const RecentDefectCard = ({
  item,
  'data-test': dataTest,
  firstCardRef,
  allToolLabels,
  history,
  timeZone,
  timeWithSecondsFormat,
}: {
  item: ItemExpanded
  'data-test'?: string
  firstCardRef?: React.Ref<HTMLDivElement>
  allToolLabels: ToolLabel[] | undefined
  history: History<unknown>
  timeZone: string
  timeWithSecondsFormat: string
}) => {
  const defectivePicture = useMemo(
    () => item.pictures.find(picture => picture.calculated_outcome === 'fail') || item.pictures[0],
    [item.pictures],
  )

  const imageSrc = useMemo(() => {
    // prefer defective image
    return getImageFromPicture(defectivePicture, { forceThumbnail: true })
  }, [defectivePicture])

  const imageEl = !imageSrc ? (
    <PrismElementaryCube addBackground />
  ) : (
    <ImgFallback src={imageSrc} loaderType="skeleton" useCache={false} />
  )

  const defectLabels = useMemo(() => {
    const sortedLabelsByKind = getItemCriticalAndMinorLabels(item, allToolLabels).sort(sortByLabelKind)
    return sortBySeverity(sortedLabelsByKind)
  }, [allToolLabels, item])

  return (
    <div ref={firstCardRef}>
      <LabelCard
        data-test={dataTest}
        type="ghost4"
        className={Styles.recentDefectCard}
        figureClassName={Styles.recentDefectImageContainer}
        imageClassName={Styles.recentDefectImage}
        label={
          <>
            <div className={Styles.cardTimeStamp}>
              {moment(item.created_at).tz(timeZone).format(timeWithSecondsFormat)}
            </div>
            <PrismOutcome icon={item.calculated_outcome} variant="dark" className={Styles.cardOutcome} />

            <LabelsList labels={defectLabels} popoverPlacement="top" />
          </>
        }
        image={imageEl}
        onMouseDown={() =>
          appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
            itemId: item.id,
            pictureId: defectivePicture.id,
          })
        }
      />
    </div>
  )
}

const getItemCriticalAndMinorLabels = (item: ItemExpanded, allToolLabels?: ToolLabel[]) => {
  const toolResults = item.pictures.flatMap(pic => pic.tool_results)

  const criticalAndMinorLabels =
    allToolLabels?.filter(toolLabel => toolLabel.severity === 'critical' || toolLabel.severity === 'minor') || []

  const toolResultLabels: ToolLabel[] = toolResults
    .flatMap(toolResult => getToolResultLabels(toolResult, criticalAndMinorLabels))
    .filter((toolLabel): toolLabel is ToolLabel => !!toolLabel)

  const uniqueTooloolResultLabels = uniqBy(toolResultLabels, label => label.id)
  const sortedLabelsByKind = uniqueTooloolResultLabels.sort(sortByLabelKind)
  return sortBySeverity(sortedLabelsByKind)
}

/**
 * Renders a skeleton for a LabelCard list
 */
const DefectsSkeleton = () => (
  <LoadingLabelCardList
    listDirection={'horizontal'}
    className={Styles.recentDefectSkeletonList}
    cardClassName={Styles.skeletonCard}
  />
)

/**
 * Renders the No Defects placeholder
 */
const NoDefectsPlaceholder = () => (
  <div className={Styles.recentDefectsEmptyState}>
    <PrismInfoIcon className={Styles.emptyStateIconInfo} />
    <h6 className={Styles.noDefectsHeader}> No Defects Found</h6>
    <p className={Styles.noDefectsDescription}>When a defect is found it will show up here.</p>
  </div>
)

/**
 * merges two item arrays, and sorts them by newest.
 * @param itemsA - first item list
 * @param itemsB - second item list
 */
const mergeItemsLists = (itemsA: ItemExpanded[], itemsB: ItemExpanded[]) => {
  const uniqueItems = uniqBy([...itemsA, ...itemsB], item => item.id)

  return uniqueItems.sort(sortByNewestFirst)
}

/**
 *
 * Fetches the provided next items page.
 *
 * @param next - next page url
 * @param failedItemsGetterKey - getter key to store the fetched page
 * @param dispatch - redux dispatch
 */
const fetchNextItemsPage = async (next: string | undefined, failedItemsGetterKey: string, dispatch: Dispatch) => {
  if (!next) return
  const res = await service.getNextPage<ItemsExpandedData>(next)

  if (res.type === 'success') {
    dispatch(
      Actions.getterUpdate({
        key: getterKeys.stationOverviewFailedItems(failedItemsGetterKey),
        updater: prevRes => getterAddPage(prevRes, res.data),
      }),
    )
  }
}

/**
 * Returns only the minor severity labels from the provided item tool results.
 *
 * @param item - Item
 * @param allToolLabels - all org tool labels
 */
const getItemMinorSeverityLabels = (item: ItemExpanded, minorLabels: ToolLabel[]) => {
  const toolResults = item.pictures.flatMap(pic => pic.tool_results)
  const labels: ToolLabel[] = toolResults
    .flatMap(toolResult => getToolResultLabels(toolResult, minorLabels))
    .filter((toolLabel): toolLabel is ToolLabel => !!toolLabel)

  const uniqueLabels = uniqBy(labels, label => label.id)

  return uniqueLabels
}

const removeItemsOutOfTimeframe = (items: ItemExpanded[], timeFrameInMs: number) => {
  // reverse the array so that we start removing older Items first
  const reversedItems = [...items].reverse()

  const cutoffIsoDate = moment().add(timeFrameInMs, 'milliseconds').toISOString()

  // Compare ISO strings directly, if we try to compare the dates through moment.diff this becomes a performance sink
  const firstValidIdx = reversedItems.findIndex(item => cutoffIsoDate > item.created_at)
  // if the first valid index is 0, we don't need to update anything
  if (firstValidIdx === 0) return items

  // if we don't find any valid index, we return an empty array
  const updatedItems = firstValidIdx <= -1 ? [] : items.slice(0, items.length - firstValidIdx)

  return updatedItems
}

/**
 * This function is in charge of removing Items with `created_at` dates that are out of the selected timeframe range.
 *
 * @param itemsGetterKey - getter key to be used
 * @param items - list to remove items from
 * @param timeFrameInMs - selected timeframe in milliseconds
 * @param dispatch - redux dispatch
 */
const removeItemsOutOfTimeframeFromGetterBranch = ({
  itemsGetterKey,
  items,
  timeFrameInMs,
  dispatch,
}: {
  itemsGetterKey: string | undefined
  items: ItemExpanded[] | undefined
  timeFrameInMs: number | undefined | null
  dispatch: Dispatch
}) => {
  if (!itemsGetterKey || !items || !timeFrameInMs) return

  const updatedItems = removeItemsOutOfTimeframe(items, timeFrameInMs)

  // Only update if some items were removed
  if (updatedItems.length === items.length) return

  dispatch(
    Actions.getterUpdate({
      key: getterKeys.stationOverviewFailedItems(itemsGetterKey),
      updater: prevRes => {
        if (!prevRes) return
        return { ...prevRes, data: { ...prevRes.data, results: updatedItems } }
      },
    }),
  )
}

const itemRefreshUpdater = (
  prevRes: SuccessResponseOnlyData<ItemsExpandedData> | undefined,
  updatedItem: ItemExpanded,
) => {
  if (!prevRes) return

  const updatedResults = [...prevRes.data.results].map(item => {
    if (item.id === updatedItem.id) return updatedItem
    return item
  })

  return { ...prevRes, data: { ...prevRes.data, results: updatedResults } }
}
