import React, { useRef, useState } from 'react'
import { useMemo } from 'react'

import { useDispatch } from 'react-redux'

import { getterKeys, query, service } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import GridTableHeader from 'components/GridTableHeader/GridTableHeader'
import PrismAccordion from 'components/PrismAccordion/PrismAccordion'
import { PrismSearchIcon } from 'components/prismIcons/PrismSearchIcon'
import { success } from 'components/PrismMessage/PrismMessage'
import RecipeNameWithThumbnail from 'components/RecipeNameWithThumbnail/RecipeNameWithThumbnail'
import { useOnScreen } from 'hooks'
import { Component } from 'types'
import { getRecipeParentImage, getTimeAgoFromDate, sortByTimestampKeyFirst } from 'utils'

import { ARCHIVED_ENTITY_ICONS } from './ArchiveView'
import Styles from './ArchiveView.module.scss'

const ARCHIVED_PRODUCTS_TABLE_TITLES = [{ title: 'name' }, { title: 'archive date' }, { title: 'type' }]

type ProductListEntry =
  | { entityType: 'product'; product: Component }
  | { entityType: 'recipe'; recipe: Component['recipe_parents'][number] }

const getArchivedProductsAndRecipesList = (products: Component[]): ProductListEntry[] => {
  return products.flatMap<ProductListEntry>(product => {
    if (product.is_deleted) {
      return [
        {
          entityType: 'product',
          // We need to filter Recipes because they could be restored without restoring the product (e.g. from manage station)
          product: { ...product, recipe_parents: product.recipe_parents.filter(recipe => recipe.is_deleted) },
        },
      ]
    }

    return product.recipe_parents
      .filter(recipeParent => recipeParent.is_deleted)
      .map(recipeParent => ({ entityType: 'recipe', recipe: recipeParent }))
  })
}

export const ArchivedProducts = ({
  searchText,
  products,
}: {
  searchText: string | undefined
  products: Component[]
}) => {
  const archivedProductsAndRecipesList = useMemo(() => {
    if (!products) return
    return getArchivedProductsAndRecipesList(products)
  }, [products])

  const filteredProductsAndRecipes = useMemo(
    () => filterArchivedProductsAndRecipesBySearch({ archivedProductsAndRecipesList, searchText }),
    [archivedProductsAndRecipesList, searchText],
  )

  return (
    <>
      {filteredProductsAndRecipes && !!filteredProductsAndRecipes.length && (
        <>
          <li className={Styles.stickyTableHeader}>
            <GridTableHeader
              columns={ARCHIVED_PRODUCTS_TABLE_TITLES}
              size="small"
              className={Styles.archiveGridTableHeader}
            />
          </li>
          {filteredProductsAndRecipes.map(productOrRecipe => {
            if (productOrRecipe.entityType === 'product') {
              return <ArchivedProductAccordion key={productOrRecipe.product.id} product={productOrRecipe.product} />
            }

            if (productOrRecipe.entityType === 'recipe') {
              return (
                <ProductRecipe key={productOrRecipe.recipe.id} recipeParent={productOrRecipe.recipe} nested={false} />
              )
            }

            return null
          })}
        </>
      )}
      {searchText && !filteredProductsAndRecipes.length && (
        <GenericBlankStateMessage header={<PrismSearchIcon />} description="No results match your search" />
      )}
    </>
  )
}

const ProductHeader = ({ product }: { product: Component }) => {
  const dispatch = useDispatch()
  const headerRef = useRef<HTMLDivElement>(null)

  const restoreProduct = async () => {
    const res = await service.updateComponent(product.id, { is_deleted: false })
    if (res.type !== 'success') return

    await Promise.all([
      query(getterKeys.components('archived'), () => service.getComponents({ is_deleted: true }), { dispatch }),
      query(getterKeys.components('all-unarchived'), () => service.getComponents({ is_deleted: false }), { dispatch }),
    ])
    success({ title: 'Product unarchived', 'data-testid': 'product-unarchive-success' })
  }

  const isOnScreen = useOnScreen(headerRef)

  const productImage = product.image

  const imageToUse = useMemo(() => {
    if (!isOnScreen) return ARCHIVED_ENTITY_ICONS['product']

    return productImage || ARCHIVED_ENTITY_ICONS['product']
  }, [isOnScreen, productImage])
  return (
    <>
      <RecipeNameWithThumbnail
        image={imageToUse}
        recipeName={product.name || '--'}
        className={Styles.archiveTitleContainer}
        textClassName={Styles.title}
        bodyRef={headerRef}
      />
      <div className={Styles.archiveDate}>{getTimeAgoFromDate(product.updated_at).text}</div>
      <div className={Styles.archiveType}>product</div>

      <Button
        className={Styles.restoreButton}
        size="small"
        type="secondary"
        onClick={async (e: MouseEvent) => {
          e.preventDefault()
          e.stopPropagation()
          await restoreProduct()
        }}
      >
        restore
      </Button>
    </>
  )
}

const ArchivedProductAccordion = ({ product }: { product: Component }) => {
  return (
    <ArchiveAccordion
      key={product.id}
      header={<ProductHeader product={product} />}
      body={product.recipe_parents
        .sort((a, b) => sortByTimestampKeyFirst(a, b, 'updated_at'))
        .map(recipeParent => (
          <ProductRecipe key={recipeParent.id} recipeParent={recipeParent} nested />
        ))}
      className={Styles.multiLevelAccordion}
      hideHoverChanges={!product.recipe_parents.length}
    />
  )
}

/**
 * Renders an archived Product
 *
 * @param isOpen - Whether the Archvie accordion is open or not
 * @returns
 */
const ArchiveAccordion = ({
  className = '',
  accordionBodyRef,
  hideHoverChanges,
  ...props
}: {
  header: React.ReactNode
  body: React.ReactNode
  accordionBodyRef?: any
  showArrow?: boolean
  className?: string
  hideHoverChanges?: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <PrismAccordion
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(!isOpen)}
      iconContainerClassName={Styles.archiveAccordionArrow}
      headerClassName={`${Styles.archiveAccordionHeader} ${Styles.archiveGrid} ${
        hideHoverChanges ? Styles.hideRowStates : ''
      }`}
      className={className}
      showArrow={!hideHoverChanges}
      {...props}
    />
  )
}

const ProductRecipe = ({
  recipeParent,
  nested,
}: {
  recipeParent: Component['recipe_parents'][number]
  nested: boolean
}) => {
  const dispatch = useDispatch()
  const recipeImage = getRecipeParentImage(recipeParent, { preferThumbnail: true })
  const ref = useRef<HTMLDivElement>(null)

  const isOnScreen = useOnScreen(ref)

  const imageToUse = useMemo(() => {
    if (!isOnScreen && nested) return undefined
    if (!isOnScreen && !nested) return ARCHIVED_ENTITY_ICONS['recipe']

    if (nested) return recipeImage

    return recipeImage || ARCHIVED_ENTITY_ICONS['recipe']
  }, [isOnScreen, nested, recipeImage])

  const restoreRecipe = async () => {
    const res = await service.updateRecipeParent(recipeParent.id, { is_deleted: false })
    if (res.type !== 'success') return

    await Promise.all([
      query(getterKeys.components('archived'), () => service.getComponents(), { dispatch }),
      query(getterKeys.components('all-unarchived'), () => service.getComponents({ is_deleted: false }), { dispatch }),
    ])
    success({ title: 'Recipe unarchived', 'data-testid': 'recipe-unarchive-success' })
  }

  return (
    <div
      ref={ref}
      className={`${Styles.archiveGrid} ${Styles.archiveAccordionBodyRow} ${nested ? Styles.nestedRow : ''}`}
    >
      <RecipeNameWithThumbnail
        image={imageToUse}
        recipeName={recipeParent.name || '--'}
        className={Styles.archiveTitleContainer}
        textClassName={Styles.title}
      />
      <div className={Styles.archiveDate}>{getTimeAgoFromDate(recipeParent.updated_at).text}</div>
      <div className={Styles.archiveType}>Recipe</div>

      {!nested && (
        <Button
          className={Styles.restoreButton}
          size="small"
          type="secondary"
          onClick={async (e: MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            await restoreRecipe()
          }}
        >
          restore
        </Button>
      )}
    </div>
  )
}

const filterArchivedProductsAndRecipesBySearch = ({
  searchText,
  archivedProductsAndRecipesList,
}: {
  searchText: string | undefined
  archivedProductsAndRecipesList: ProductListEntry[] | undefined
}) => {
  if (!archivedProductsAndRecipesList) return []

  let filteredProductsAndRecipes: ProductListEntry[] = []

  if (!searchText) filteredProductsAndRecipes = archivedProductsAndRecipesList

  if (searchText) {
    const lowerCasedSearchText = searchText.toLowerCase()

    archivedProductsAndRecipesList?.forEach(productOrRecipe => {
      const entityName =
        productOrRecipe.entityType === 'product'
          ? productOrRecipe.product.name.toLowerCase()
          : productOrRecipe.recipe.name.toLowerCase()
      const matchedRecipes =
        productOrRecipe.entityType === 'product'
          ? productOrRecipe.product.recipe_parents.filter(recipe => {
              return recipe.name.toLowerCase().includes(lowerCasedSearchText) && recipe.is_deleted
            })
          : []

      const entityMatches = entityName.includes(lowerCasedSearchText)

      if (!matchedRecipes.length && !entityMatches) return

      if (productOrRecipe.entityType === 'product') {
        filteredProductsAndRecipes.push({
          ...productOrRecipe,
          product: {
            ...productOrRecipe.product,
            recipe_parents: entityMatches ? productOrRecipe.product.recipe_parents : matchedRecipes,
          },
        })
      }

      if (productOrRecipe.entityType === 'recipe') {
        filteredProductsAndRecipes.push(productOrRecipe)
      }
    })
  }

  return filteredProductsAndRecipes.sort((a, b) => {
    const aWithUpdatedAt =
      a.entityType === 'product' ? { updated_at: a.product.updated_at } : { updated_at: a.recipe.updated_at }
    const bWithUpdatedAt =
      b.entityType === 'product' ? { updated_at: b.product.updated_at } : { updated_at: b.recipe.updated_at }
    return sortByTimestampKeyFirst(aWithUpdatedAt, bWithUpdatedAt, 'updated_at')
  })
}
