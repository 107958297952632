import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Defect Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3678%3A42
 *
 * @param hasHover - Fill the icon with $light_100 when hovering the icon.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismDefectToolIcon = ({ isActive, hasHover, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${hasHover ? Styles.hoverInBlue : ''} ${isActive ? Styles.active : ''} ${
        className ?? ''
      }`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 4.5H18.7616L13.5 9.76164V4.5ZM13.5 10.5V10.1875L13.8125 10.5H13.5ZM19.5 10.5H14.1759L19.5 5.17586V10.5ZM19.5 4.59375L19.4062 4.5H19.5V4.59375ZM4.5 13.5H9.76164L4.5 18.7616V13.5ZM4.5 19.5V19.1875L4.8125 19.5H4.5ZM10.5 19.5H5.17586L10.5 14.1759V19.5ZM10.5 13.5938L10.4062 13.5H10.5V13.5938ZM19.5 13.5V19.5H13.5V13.5H19.5ZM10.5 4.5V10.5H4.5V4.5H10.5Z"
        fill="currentColor"
        fillOpacity="0.04"
        stroke="currentColor"
      />
    </svg>
  )
}
