import React, { useEffect, useMemo, useState } from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { debounce } from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { service } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import GridTableHeader from 'components/GridTableHeader/GridTableHeader'
import ReduxVideo from 'components/MultiVideoListener/ReduxVideo'
import PrismAddButton from 'components/PrismAddBtn/PrismAddBtn'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismAddIcon, PrismBellIcon, PrismSearchIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { discard, error, info, mute } from 'components/PrismMessage/PrismMessage'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import { PrismTabNav } from 'components/PrismTab/PrismTab'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Tag } from 'components/Tag/Tag'
import { useDateTimePreferences, useKeepEventSubsInSync } from 'hooks'
import { useVirtualizedTable } from 'pages/Analyze/AnalyzeBase'
import paths from 'paths'
import {
  Component,
  CreateUpdateDeleteSubsBody,
  EventSub,
  NotificationCounts,
  Station,
  subscriptionTabsType,
} from 'types'
import { isEventMuted, refreshEventSubsBranches } from 'utils'

import Shared from '../../../styles/Shared.module.scss'
import AddSubscriptionModal from './AddSubscriptionModal'
import Styles from './Subscriptions.module.scss'
import SubscriptionOptionMenu, { muteOptions } from './SubscriptionsOptionMenu'

type SubscriptionTable = {
  id: string
  name: string
  robotId: string | undefined
  lastNotification: string
  lastHour: number
  mutedUntil: number | null | undefined
}

type MenuOptions = 'unsubscribe' | 'mute' | 'unmute'

function SubscriptionsBody({
  subscriptionTab,
  subscriptionList,
  notificationCounts,
  stations,
  components,
  userEventSubs,
  eventSubTargetTemplates,
  eventSubTypeTemplates,
  subscriptionRefetchData,
}: {
  subscriptionTab: subscriptionTabsType
  subscriptionList: Station[] | Component[] | undefined
  notificationCounts: NotificationCounts['results'] | undefined
  stations: Station[] | undefined
  components: Component[] | undefined
  userEventSubs: EventSub[] | undefined
  eventSubTypeTemplates: EventSub[] | undefined
  eventSubTargetTemplates: EventSub[] | undefined
  subscriptionRefetchData: (searchValue?: string) => Promise<void>
}) {
  const dispatch = useDispatch()
  const [showAddSubscriptionModal, setShowAddSubscriptionModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState<string>()

  useKeepEventSubsInSync({ userEventSubs, eventSubTypeTemplates, eventSubTargetTemplates })

  const { timeZone, dateFormat, timeFormat } = useDateTimePreferences()

  // Refetch data when component unmounts in case we had a search active when switching tabs
  useEffect(() => {
    return () => {
      subscriptionRefetchData()
    }
    // eslint-disable-next-line
  }, [])

  async function refetchData() {
    // Refetch EventSubs
    await Promise.allSettled([subscriptionRefetchData(searchInputValue), refreshEventSubsBranches({ dispatch })])
  }

  async function handleSubscriptionOptions({
    option,
    muteOption,
    targetTable,
  }: {
    option: MenuOptions
    muteOption?: muteOptions
    targetTable: SubscriptionTable
  }) {
    const currentTimestamp = moment().unix()
    let timeToMute: number | null = null
    if (muteOption === '5m') timeToMute = currentTimestamp + 60 * 5
    if (muteOption === '30m') timeToMute = currentTimestamp + 60 * 30
    if (muteOption === '1h') timeToMute = currentTimestamp + 60 * 60
    if (muteOption === 'until tomorrow') timeToMute = currentTimestamp + 60 * 60 * 24
    if (muteOption === 'indefinitely') timeToMute = currentTimestamp + 60 * 60 * 24 * 365 * 10
    if (option === 'unmute') timeToMute = null

    const body = prepareBatchCreateUpdateDeleteSubsBody({
      userEventSubs,
      eventSubTargetTemplates,
      eventTargetId: targetTable.id,
      option,
      timeToMute,
    })

    const res = await service.createUpdateDeleteCurrentUserSubs(body)

    if (res.type !== 'success') return error({ title: 'An error occurred, please try again' })
    await refetchData()

    if (option === 'unsubscribe')
      discard({ title: `Unsubscribed from ${targetTable.name}`, 'data-testid': `unsubscribe-${targetTable.name}` })
    if (option === 'mute')
      mute({
        title: `${targetTable.name} muted ${
          ['until tomorrow', 'indefinitely'].includes(muteOption || '') ? muteOption : `for ${muteOption}`
        }`,
      })
    if (option === 'unmute') info({ title: `${targetTable.name} unmuted` })
  }

  const handleSearch = useMemo(
    () =>
      debounce(async (stationSearch?: string) => {
        setIsLoading(true)
        await subscriptionRefetchData(stationSearch)
        setIsLoading(false)
      }, 500),
    [subscriptionRefetchData],
  )

  const columns: ColumnsType<SubscriptionTable> = [
    {
      title: subscriptionTab,
      dataIndex: subscriptionTab,
      key: subscriptionTab,
      ellipsis: true,
      render: (_, record) => {
        const { isMuted, tooltipMessage } = isEventMuted(record.mutedUntil, timeZone)
        return (
          <SubscriptionTitle
            dataTestId={`${record.name}-row-name`}
            title={record.name}
            robotId={record.robotId}
            isMuted={isMuted}
            tooltipMessage={tooltipMessage}
          />
        )
      },
    },
    {
      title: 'last notification',
      dataIndex: 'lastNotification',
      key: 'lastNotification',
      width: 200,
      render: (_, record) =>
        renderDarkTableCell({ value: record.lastNotification, dataTestId: `${record.name}-row-last-notification` }),
    },
    {
      title: 'last 24h',
      dataIndex: 'lastHour',
      key: 'lastHour',
      width: 108,
      render: (_, record) =>
        renderDarkTableCell({ value: record.lastHour, dataTestId: `${record.name}-row-last-hour` }),
    },
    {
      title: '',
      dataIndex: 'row_menu',
      key: 'row_menu',
      width: 64,
      render: (_, record) => {
        const { isMuted } = isEventMuted(record.mutedUntil, timeZone)
        return (
          <SubscriptionOptionMenu
            dataTestId={`${record.name}-row-menu`}
            isMuted={isMuted}
            onClick={(option, muteOption) => handleSubscriptionOptions({ option, muteOption, targetTable: record })}
          />
        )
      },
    },
  ]

  const dataSource = useMemo(() => {
    if (!subscriptionList?.length) return []

    const dataSource: SubscriptionTable[] = []
    for (const stationOrComponent of subscriptionList) {
      let robotId: string | undefined = undefined
      if ('robots' in stationOrComponent) robotId = stationOrComponent.robots[0]?.id
      const notifCount = notificationCounts?.find(
        notifRes =>
          notifRes.event__meta__targets__component_ids === stationOrComponent.id ||
          notifRes.event__meta__targets__station_ids === stationOrComponent.id,
      )
      const eventSub = eventSubTargetTemplates?.find(
        eventSub =>
          eventSub.targets.component_id === stationOrComponent.id ||
          eventSub.targets.station_id === stationOrComponent.id,
      )
      dataSource.push({
        id: stationOrComponent.id,
        name: stationOrComponent.name,
        lastNotification: notifCount?.last_notification
          ? moment(notifCount.last_notification).format(`${dateFormat}, ${timeFormat}`)
          : '--',
        robotId,
        mutedUntil: eventSub?.disabled_until_s,
        lastHour: notifCount?.count || 0,
      })
    }

    return dataSource
  }, [dateFormat, eventSubTargetTemplates, notificationCounts, subscriptionList, timeFormat])

  const showLoadingState = useMemo(() => {
    if (isLoading) return true

    if (subscriptionTab === 'stations') {
      return !stations
    }

    if (subscriptionTab === 'products') {
      return !components
    }
  }, [components, isLoading, stations, subscriptionTab])

  const { columnsWithWidths, tableContainerRef, renderVirtualTable, tableHeight } = useVirtualizedTable(columns, {
    rowHeight: 80,
    rowClassName: Styles.tableRow,
    tableCustomHeaderHeight: 9,
    hideHoverStyles: true,
    getTestId: record => `${record.name}-row`,
  })

  return (
    <>
      <PrismContainer
        title="Subscriptions"
        headerTitleAction={
          <div className={Styles.headerSearchAndButton}>
            <PrismSearchInput
              value={searchInputValue}
              onInputChange={e => {
                setSearchInputValue(e.target.value)
                handleSearch(e.target.value)
              }}
              onSearchButtonClick={showInput => {
                if (!showInput) {
                  setSearchInputValue(undefined)
                  handleSearch(undefined)
                }
              }}
              addAnimation
              autoFocus
            />
            <Button
              size="small"
              type="secondary"
              data-testid="add-subscription"
              onClick={() => setShowAddSubscriptionModal(true)}
              badge={<PrismAddIcon />}
            >
              add
            </Button>
          </div>
        }
        actions={
          <>
            <p className={Styles.headerDescription}>Select which stations and products send you notifications.</p>

            <PrismTabNav
              className={Styles.headerNavTabs}
              items={[
                {
                  path: paths.settingsNotify({ mode: 'subscriptions', params: { tab: 'stations' } }),
                  forceState: subscriptionTab === 'stations',
                  label: 'Stations',
                  'data-testid': 'subscription-station',
                },
                {
                  path: paths.settingsNotify({ mode: 'subscriptions', params: { tab: 'products' } }),
                  forceState: subscriptionTab === 'products',
                  label: 'Products',
                  'data-testid': 'subscription-product',
                },
              ]}
            />
          </>
        }
        invertTitleAndActions
        className={`${Shared.rightSectionContainer} ${Styles.subscriptionsWrapper}`}
        headerActionsClassName={Styles.headerWrapper}
        bodyClassName={Styles.subscriptionsBodyWrapper}
      >
        <div ref={tableContainerRef} className={Styles.subscriptionsBody}>
          <>
            {!!dataSource.length && (
              <GridTableHeader columns={columns} size="small" className={Styles.gridTableHeader} />
            )}

            {tableHeight && (
              <Table
                rowKey="id"
                columns={columnsWithWidths}
                dataSource={dataSource}
                className={Styles.tableContainer}
                showHeader={false}
                pagination={false}
                loading={{
                  spinning: showLoadingState,
                  wrapperClassName: Styles.tableSpinnerWrapper,
                  indicator: <PrismLoader className={Styles.tableLoaderPoistion} />,
                }}
                scroll={{ y: tableHeight }}
                components={{
                  body: (rows, info) => {
                    if (showLoadingState) {
                      return <div />
                    }
                    if (!rows.length && searchInputValue !== undefined) {
                      return (
                        <GenericBlankStateMessage
                          description={`No ${subscriptionTab} match your search`}
                          header={<PrismSearchIcon />}
                          className={`${Styles.emptyContainerHeight} ${Styles.noMatchWrapper}`}
                        />
                      )
                    }
                    if (!rows.length) {
                      return (
                        <PrismAddButton
                          description={`Subscribe to a ${subscriptionTab.slice(0, -1)}  to get started`}
                          onClick={() => setShowAddSubscriptionModal(true)}
                        />
                      )
                    }
                    return renderVirtualTable(rows, info)
                  },
                }}
              />
            )}
          </>
        </div>

        {showAddSubscriptionModal && (
          <AddSubscriptionModal
            onClose={() => setShowAddSubscriptionModal(false)}
            eventSubTypeTemplates={eventSubTypeTemplates}
            eventSubTargetTemplates={eventSubTargetTemplates}
            mode={subscriptionTab}
            onOk={async () => {
              await refetchData()
              setShowAddSubscriptionModal(false)
            }}
          />
        )}
      </PrismContainer>
    </>
  )
}

/*
 * Renders the First column of the table (Stations/Products)
 *
 * @param robotId - robot id to get the stream from
 * @param title - station or product title
 * @param isMuted - changes the looks of the image container, and adds a muted tag
 * @param hasImage - a boolean to hide the image container
 * @param tooltipMessage - a message for the muted tooltip
 */
const SubscriptionTitle = ({
  robotId,
  title,
  isMuted,
  tooltipMessage,
  dataTestId,
}: {
  robotId?: string
  title: string
  isMuted?: boolean
  hasImage?: boolean
  tooltipMessage?: string
  dataTestId?: string
}) => {
  return (
    <div className={Styles.firstColumnWrapper}>
      {robotId && (
        <figure className={`${Styles.imageWrapper} ${isMuted ? Styles.isMuted : ''}`}>
          {isMuted && <PrismBellIcon className={Styles.imageMuteIcon} />}
          <ReduxVideo key={robotId} element="transcoder-basler-image-thumbnail" stream="compressed" robotId={robotId} />
        </figure>
      )}

      <PrismOverflowTooltip data-testid={dataTestId} className={Styles.title} content={title} />

      {isMuted && (
        <PrismTooltip title={tooltipMessage} className={Styles.mutedTag}>
          <Tag>Muted</Tag>
        </PrismTooltip>
      )}
    </div>
  )
}

export function prepareBatchCreateUpdateDeleteSubsBody<T extends MenuOptions>({
  userEventSubs,
  eventSubTargetTemplates,
  eventTargetId,
  option,
  timeToMute,
}: {
  userEventSubs: EventSub[] | undefined
  eventSubTargetTemplates: EventSub[] | undefined
  eventTargetId: string
  option: T
  timeToMute: T extends 'mute' ? number | null : undefined
}): CreateUpdateDeleteSubsBody {
  const allEventSubs = []
  if (userEventSubs) allEventSubs.push(...userEventSubs)
  if (eventSubTargetTemplates) allEventSubs.push(...eventSubTargetTemplates)

  const targetTableSubs = allEventSubs?.filter(
    eventSub => eventSub.targets.station_id === eventTargetId || eventSub.targets.component_id === eventTargetId,
  )

  const CRUDBody: CreateUpdateDeleteSubsBody = {
    update: [],
    create: [],
    delete: [],
  }

  if (option === 'unsubscribe') {
    // Delete all subscriptions with that target table
    targetTableSubs?.forEach(targetTableSub => CRUDBody.delete?.push({ id: targetTableSub.id }))
  }

  if (option === 'mute') {
    // Mute all subscriptions with that target table
    targetTableSubs?.forEach(targetTableSub =>
      CRUDBody.update?.push({
        ...targetTableSub,
        disabled_until_s: timeToMute,
      }),
    )
  }

  if (option === 'unmute') {
    // Unmute all subscriptions with that target table
    targetTableSubs?.forEach(targetTableSub =>
      CRUDBody.update?.push({
        ...targetTableSub,
        disabled_until_s: null,
      }),
    )
  }
  return CRUDBody
}
export default SubscriptionsBody

const renderDarkTableCell = ({ value, dataTestId }: { value: string | number; dataTestId?: string }) => (
  <span data-testid={dataTestId} className={Styles.darkTableCell}>
    {value}
  </span>
)
