import React, { useMemo, useState } from 'react'

import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-redux-query'

import { getAllPages, getterKeys, query, service } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import ReduxVideo from 'components/MultiVideoListener/ReduxVideo'
import { PrismInfoIcon, PrismPassIcon, PrismSearchIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal, ModalProps } from 'components/PrismModal/PrismModal'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import { Component, EventSub, EventTargets, Station, subscriptionTabsType } from 'types'
import { getAddSubscriptionPayload, getEventSubTargetTemplatesPayload } from 'utils'

import Styles from './Subscriptions.module.scss'

type AddSubscriptionProps = {
  mode: subscriptionTabsType
  eventSubTypeTemplates: EventSub[] | undefined
  eventSubTargetTemplates: EventSub[] | undefined
} & Pick<ModalProps, 'onClose' | 'onOk'>

type ListItemProps = {
  listItem: Station | Component
  selected: boolean
  onSelectedChange: (selected: boolean) => void
  modalLoaded: boolean
  dataTestId?: string
}

const stationKey = getterKeys.stations('not_subscribed')
const componentKey = getterKeys.components('not_subscribed')

/**
 * Renders the Add Subscription Modal
 *
 * @param mode - Switch the modals title between stations and products
 * @param onClose - handler for closing the modal
 * @param onOk -
 */
const AddSubscriptionModal = ({
  mode,
  eventSubTypeTemplates,
  eventSubTargetTemplates,
  onClose,
  onOk,
}: AddSubscriptionProps) => {
  const [refetchIsLoading, setRefetchIsLoading] = useState(false)
  const [modalLoaded, setModalLoaded] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()

  const [selectedTargetIds, setSelectedTargetIds] = useState<Set<string>>(new Set())

  // Need to fetch all stations/products again in order for us to be able to handle searching them
  const stations = useQuery(stationKey, () => getAllPages(service.getStations({ has_robots: true })))?.data?.data
    .results
  const components = useQuery(componentKey, () => getAllPages(service.getComponents({ is_deleted: false })))?.data?.data
    .results

  const isLoading = !stations || !components || refetchIsLoading

  const elementsToUse = useMemo(() => {
    const elemToCompareAgainst = mode === 'stations' ? stations : components
    const table = mode === 'stations' ? 'station' : 'component'

    const subscribedStationsOrComponentIds = eventSubTargetTemplates
      ?.filter(eventSub => {
        if (table === 'station') {
          return !!eventSub.targets.station_id
        }
        if (table === 'component') {
          return !!eventSub.targets.component_id
        }
        return false
      })
      .map(eventsub => eventsub.targets.station_id || eventsub.targets.component_id)

    return ((elemToCompareAgainst as { id: string }[])?.filter(
      element => !subscribedStationsOrComponentIds?.includes(element.id),
    ) || []) as (Component | Station)[]
  }, [mode, stations, components, eventSubTargetTemplates])

  const selectionsCount = useMemo(() => {
    return selectedTargetIds.size
  }, [selectedTargetIds])

  const disableSelectAll = useMemo(() => {
    return elementsToUse.every(element => selectedTargetIds.has(element.id))
  }, [elementsToUse, selectedTargetIds])

  const refetchData = useMemo(
    () =>
      debounce(async (searchValue?: string) => {
        setRefetchIsLoading(true)

        if (mode === 'stations')
          await query(stationKey, () => service.getStations({ search: searchValue, has_robots: true }), { dispatch })
        if (mode === 'products')
          await query(componentKey, () => service.getComponents({ name: searchValue, is_deleted: false }), {
            dispatch,
          })
        setSearchValue(searchValue || '')
        setRefetchIsLoading(false)
      }, 500),
    [dispatch, mode],
  )

  async function handleSubmit() {
    const targetIdsToSubscribe = [...selectedTargetIds].map<EventTargets>(targetId => {
      if (mode === 'stations') {
        return { station_id: targetId }
      }

      return { component_id: targetId }
    })

    const payload = getAddSubscriptionPayload({
      eventTargetsToSubscribeTo: targetIdsToSubscribe,
      eventSubTypeTemplates,
    })
    const eventSubTargetsTemplateToCreate = getEventSubTargetTemplatesPayload(payload.create)
    payload.create = [...(payload?.create || []), ...eventSubTargetsTemplateToCreate]

    const res = await service.createUpdateDeleteCurrentUserSubs(payload)
    if (res.type !== 'success') return error({ title: 'An error occurred, please try again' })

    let title = ''
    if (targetIdsToSubscribe.length > 1) {
      title = `Subscribed to ${targetIdsToSubscribe.length} ${mode}`
    }
    if (targetIdsToSubscribe.length === 1) {
      const element = (elementsToUse as { id: string; name: string }[])?.find(elem => {
        if (mode === 'stations') {
          return elem.id === targetIdsToSubscribe[0]?.station_id
        }
        if (mode === 'products') {
          return elem.id === targetIdsToSubscribe[0]?.component_id
        }

        return false
      })
      title = `Subscribed to ${element?.name}`
    }

    await onOk?.()
    success({ title, 'data-testid': 'subscription-added' })

    // Fetch not subscribed stations/component in the background
    query(
      getterKeys.stations('not_subscribed'),
      async () => await getAllPages(service.getStations({ is_subscribed: false, has_robots: true })),
      { dispatch },
    )

    query(
      getterKeys.components('not_subscribed'),
      async () => await getAllPages(service.getComponents({ is_subscribed: false, is_deleted: false })),
      { dispatch },
    )
  }

  return (
    <Modal
      id="add-subscription"
      data-testid="add-subscription-modal"
      header={`Add ${mode === 'stations' ? 'Stations' : 'Products'}`}
      onOk={handleSubmit}
      okText={`add ${selectionsCount || ''}`}
      onClose={onClose}
      size="largeSimpleForm"
      modalBodyClassName={Styles.addSubscriptionModalBody}
      className={Styles.modalWrapper}
      disableSave={!selectionsCount}
      onModalLoad={() => setModalLoaded(true)}
    >
      <div className={Styles.bodyTitle}>
        <PrismSearchInput
          placeholder="Search"
          onInputChange={e => refetchData(e.target.value)}
          size="small"
          showIconPrefix
        />

        <Button
          className={Styles.selectAll}
          size="small"
          type="ghost"
          onClick={() =>
            setSelectedTargetIds(prevTargets => new Set([...prevTargets, ...elementsToUse.map(element => element.id)]))
          }
          disabled={disableSelectAll}
        >
          Select All
        </Button>
      </div>

      <AddSubscriptionModalSearch
        isLoading={isLoading}
        list={elementsToUse}
        mode={mode}
        searchValue={searchValue}
        modalLoaded={modalLoaded}
        selectedTargetIds={selectedTargetIds}
        setSelectedTargetIds={setSelectedTargetIds}
      />
    </Modal>
  )
}

/**
 * Renders the list item row
 *
 * @param listItem -
 */
const ListItem = ({ listItem, selected, onSelectedChange, modalLoaded, dataTestId }: ListItemProps) => {
  return (
    <li
      data-testid={dataTestId}
      className={`${Styles.listItemRow} ${selected ? Styles.isActive : ''}`}
      onClick={() => onSelectedChange(!selected)}
      id={listItem.id}
    >
      {'robots' in listItem && listItem.robots?.[0]?.id && modalLoaded && (
        <figure className={Styles.imageWrapper}>
          <ReduxVideo element="transcoder-basler-image-thumbnail" stream="compressed" robotId={listItem.robots[0].id} />
        </figure>
      )}
      <PrismOverflowTooltip content={listItem.name} className={Styles.addStationRowTitle} />

      {selected && <PrismPassIcon className={Styles.checkIcon} />}
    </li>
  )
}

export default AddSubscriptionModal

const AddSubscriptionModalSearch = ({
  isLoading,
  mode,
  list,
  searchValue,
  modalLoaded,
  setSelectedTargetIds,
  selectedTargetIds,
}: {
  isLoading: boolean
  mode: subscriptionTabsType
  searchValue: string
  modalLoaded: boolean
  list: (Station | Component)[]
  setSelectedTargetIds: React.Dispatch<React.SetStateAction<Set<string>>>
  selectedTargetIds: Set<string>
}) => {
  if (isLoading)
    return (
      <div className={Styles.modalIsLoading}>
        <PrismLoader />
      </div>
    )

  if (list.length === 0)
    return (
      <GenericBlankStateMessage
        description={searchValue ? `No ${mode} match your search` : `You have subscribed to all your ${mode}`}
        header={searchValue ? <PrismSearchIcon /> : <PrismInfoIcon />}
        className={Styles.modalEmptySearch}
      />
    )

  return (
    <ul className={Styles.listItems}>
      {list.map(element => (
        <ListItem
          dataTestId={`${element.name}-option`}
          listItem={element}
          selected={selectedTargetIds.has(element.id)}
          onSelectedChange={selected => {
            if (selected && !selectedTargetIds.has(element.id)) {
              setSelectedTargetIds(prev => new Set([...prev, element.id]))
            }

            if (!selected && selectedTargetIds.has(element.id)) {
              setSelectedTargetIds(prev => new Set([...prev].filter(id => id !== element.id)))
            }
          }}
          key={element.id}
          modalLoaded={modalLoaded}
        />
      ))}
    </ul>
  )
}
