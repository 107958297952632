import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { getterKeys, query, service, wsPaths } from 'api'
import { dismiss, error, success } from 'components/PrismNotification/PrismNotification'
import { CLOUD_FASTAPI_WS_URL } from 'env'
import { useData } from 'hooks'
import paths from 'paths'
import { OrganizationStreamMessage } from 'types'

import { Button } from './Button/Button'
import StreamListener from './StreamListener'

/**
 * Renders nothing. Listens for organization events, and shows notifications for
 * certain events, e.g. when tools finish training, or fail to train.
 */
export default function NotificationsListener() {
  const history = useHistory()
  const dispatch = useDispatch()

  const me = useData(getterKeys.me())

  const handleOrganizationEvent = async (event: OrganizationStreamMessage[]) => {
    if (event[0]?.payload.type !== 'tool-update') return
    const changedTool = event[0]?.payload.payload

    if (!changedTool) return
    // TODO: Once we build the tool screen, we can just redirect the user to that screen and don't need to fetch the routine parent here
    const routineParentId = changedTool.experiment_via_routine_parent_id
    const routineParentRes = await query(
      getterKeys.routineParent(routineParentId),
      () => service.getRoutineParent(routineParentId),
      { dispatch },
    )
    if (routineParentRes?.type !== 'success') return null
    const routineParent = routineParentRes.data

    const toolTrainingState = changedTool.state
    const notificationKey = `${changedTool.id}_training_notif`

    if (changedTool.parent_id) {
      const toolParentRes = await query(
        getterKeys.toolParent(changedTool.parent_id),
        () => service.getToolParent(changedTool.parent_id),
        { dispatch },
      )
      if (toolParentRes?.type !== 'success') return null
    }

    const modelVersionText = changedTool.version ? `model v${changedTool.version} of` : ''

    const showTrainingFailedNotification = () => {
      const seeResultsButton = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            dismiss(notificationKey)
            history.push(
              paths.settingsRecipe(routineParent.recipe_parent_id, 'train', {
                toolParentId: changedTool.parent_id,
                routineParentId: routineParent.id,
              }),
            )
          }}
        >
          See Details
        </Button>
      )

      const errorDescription = `An error has occured while training ${modelVersionText} ${changedTool.parent_name}. Contact support @elementaryml.com for help.`

      error({
        id: notificationKey,
        title: 'Training Failed',
        description: errorDescription,
        position: 'bottom-left',
        duration: 0,
        children: seeResultsButton,
      })
    }

    const showTrainingSuccessfulNotification = () => {
      const seeReportButton = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            dismiss(notificationKey)
            history.push(
              paths.settingsRecipe(routineParent.recipe_parent_id, 'train', {
                toolParentId: changedTool.parent_id,
                reportModelId: changedTool.id,
                routineParentId: routineParent.id,
              }),
            )
          }}
        >
          View Report
        </Button>
      )

      const successDescription = `Training complete for ${modelVersionText} ${changedTool.parent_name}.`

      success({
        id: notificationKey,
        title: 'Training Complete',
        description: successDescription,
        position: 'bottom-left',
        duration: 0,
        children: seeReportButton,
      })
    }

    if (changedTool.train_triggered_by_user_id !== me?.id) return

    if (toolTrainingState === 'failed') {
      showTrainingFailedNotification()
    }
    if (toolTrainingState === 'successful') {
      showTrainingSuccessfulNotification()
    }
  }

  return (
    <StreamListener
      connect={{ url: `${CLOUD_FASTAPI_WS_URL}${wsPaths.organizationEvents()}` }}
      onMessages={handleOrganizationEvent}
      mode="message"
    />
  )
}
