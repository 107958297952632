import React, { useEffect, useState } from 'react'

import { Divider } from 'components/Divider/Divider'
import FetchingColocatedRobot from 'components/FetchingColocatedRobot/FetchingColocatedRobot'
import MultiVideoListener from 'components/MultiVideoListener/MultiVideoListener'
import RobotsStatusListener from 'components/RobotsStatusListener'
import { useStation } from 'hooks'

import PlcConfiguration from './PlcConfiguration'
import Styles from './PlcControl.module.scss'
import RobotCard from './RobotCard'

type Props = { stationId?: string }

/**
 * Renders the main container for the PLC Control tools. Figures out the current station data
 * from either the query string id or the colocated robots. Optionally renders a list of robots
 * on the left side if the current station is a multi-cam station.
 *
 * @param stationId - Optional station ID returned from the query string
 *
 * Business Rules:
 * - A multi-cam station is considered to be any station with more than one camera
 * - Robots will be sorted by name on the list.
 * - Only users with an ENV var set to true and who are colocated with a robot will have access
 *   to this screen
 * - By default, three empty boxes are shown on the screen, and users can add a control element to
 *   any one of those three. Subsequently, there will always be three empty boxes after the last
 *   element on the list.
 * - The layout data is stored in json inside every robot instance. Each layout item must contain a
 *   tag, which will be used to then send a command to the edge and figure out the state of each
 *   of those tags.
 * - Only boolean tags are shown on the enable tag section.
 * - Tags are refreshed every 10s automatically
 * - A tag will be actionable if three conditions are met: The user has a role with permissions to edit
 *   the tag, the tag has returned a value successfully from the edge, meaning the tag exists and is
 *   available, and three, the `enable_tag` defined has returned a value of `true`.
 */
export function PlcControl({ stationId }: Props) {
  const [selectedRobotId, setSelectedRobotId] = useState<string>()
  const { station } = useStation(stationId)

  useEffect(() => {
    if (!station?.robots) return
    const sortedRobots = [...station.robots].sort((robotA, robotB) => {
      if (!robotA.serial_number || !robotB.serial_number) return -1
      if (robotA.serial_number < robotB.serial_number) return 1
      else return -1
    })
    // Automatically select the first robot on the list
    setSelectedRobotId(sortedRobots[0]?.id)
  }, [station])

  if (!station) return <FetchingColocatedRobot station={station} />

  const isMultiCam = station.robots.length > 1

  return (
    <div className={`${Styles.plcContainer} ${isMultiCam ? Styles.plcContainerMulti : Styles.plcContainerSingle}`}>
      {<RobotsStatusListener robotIds={station.robots.map(robot => robot.id)} />}

      {isMultiCam && (
        <>
          <MultiVideoListener
            element="transcoder-basler-image-thumbnail"
            stream="compressed"
            robotIds={station.robots.map(robot => robot.id)}
          />

          <div className={Styles.leftPanelContainer}>
            {station.robots.map(robot => (
              <RobotCard
                key={robot.id}
                onSelect={() => setSelectedRobotId(robot.id)}
                active={selectedRobotId === robot.id}
                robotId={robot.id}
              />
            ))}
          </div>
          <Divider type="vertical" className={Styles.plcDivider} />
        </>
      )}

      {selectedRobotId && <PlcConfiguration key={selectedRobotId} robotId={selectedRobotId} />}
    </div>
  )
}
