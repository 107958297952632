import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Table } from 'antd'
import { groupBy } from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { getterKeys, InspectionsData, service, useQuery } from 'api'
import { BackTop, useBackTopWithClassName } from 'components/BackTop/BackTop'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismGraphProgressBar } from 'components/PrismGraphProgressBar/PrismGraphProgressBar'
import { PrismGraphWrapper } from 'components/PrismGraphWrapper/PrismGraphWrapper'
import { PrismNavArrowIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { useAllToolLabels, useAnalyticsQueryFilters, useData, useStateAndRef } from 'hooks'
import CountGraph from 'pages/StationDetail/Components/CountGraph'
import { YieldGraph } from 'pages/StationDetail/Components/YieldGraph'
import * as Actions from 'rdx/actions'
import { AnalyzeDefect, Inspection, TimeSeriesDatePeriod, TimeSeriesResult } from 'types'
import {
  calculatePercentage,
  combineOutcomeCounts,
  convertAllFiltersToBackendQueryParams,
  getAnalyzeAggS,
  getAnalyzeMetricsLabels,
  getDisplaySeverity,
  getDisplayStationLocation,
  getLabelName,
  getRtsParams,
  getterAddPage,
  METRICS_START_DATE_MS,
  momentToString,
  renderLargeNumber,
  seriesFillGapsRtsDatePeriod,
  titleCase,
  truncateSeriesTimestampsRtsDatePeriod,
} from 'utils'
import { MAX_RTS_METRICS_LABELS_DICTS, PDF_TABLE_LIMIT, TABLE_HEADER_HEIGHT } from 'utils/constants'

import Styles from './Analyze.module.scss'
import {
  ANALYZE_VERTICAL_PADDING,
  AnalyzeTableMetrics,
  AnalyzeWithGalleryProps,
  dummyData,
  forceZeroPosition,
  getAnalyzeDefects,
  getAnalyzeTableColumns,
  ObjectWithMetrics,
  useVirtualizedTable,
} from './AnalyzeBase'
import { AnalyzeEmptyState } from './AnalyzeEmptyState'
import AnalyzeFilters, { Filters } from './AnalyzeFilters'
import { AnalyzeGallery } from './AnalyzeGallery'

export type InspectionWithMetrics = ObjectWithMetrics<Inspection>

export const AnalyzeBatches = ({
  isGalleryExpanded,
  setIsGalleryExpanded,
  setPdfData,
  galleryTransitionInProgress,
  setGalleryTransitionInProgress,
  tableRerenderKey,
}: AnalyzeWithGalleryProps) => {
  const { allToolLabels } = useAllToolLabels()

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const [filters, { onUpdateFilters, expanded, filterKey, periodsAvailable }] = useAnalyticsQueryFilters({
    tab: 'batches',
  })

  const {
    period,
    start,
    end,
    station_id,
    component_id,
    search,
    recipe_id,
    user_id,
    inspection_id,
    site_id,
    subsite_id,
  } = filters

  const batchesData = useQuery(
    getterKeys.analyticsBatches(),
    async () => {
      setIsLoading(true)

      const params = convertAllFiltersToBackendQueryParams({
        component_id,
        station_id,
        start: momentToString(start, 'start'),
        end: momentToString(end, 'end'),
        name: search,
        recipe_id,
        user_id,
        id__in: inspection_id,
        has_items: true,
        site_id,
        subsite_id,
      })

      const res = await service.getInspections(params)
      setIsLoading(false)
      return res
    },
    { refetchKey: filterKey },
  ).data?.data

  const batches = batchesData?.results
  const next = batchesData?.next

  // If we apply filters that don't include the current batch, we won't have enough information to display basic batch data,
  // so we must fetch the expanded inspection explicitly
  const expandedInspectionFallback = useQuery(
    expanded ? getterKeys.analyticsBatch(expanded) : undefined,
    expanded ? () => service.getInspection(expanded) : undefined,
  ).data?.data

  const expandedInspection = batches?.find(batch => batch.id === expanded) || expandedInspectionFallback

  const handleEndReached = useCallback(async () => {
    if (!next) return

    const res = await service.getNextPage<InspectionsData>(next)
    if (res.type === 'success') {
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.analyticsBatches(),
          updater: prevRes => getterAddPage(prevRes, res.data),
        }),
      )
    }
  }, [dispatch, next])

  const { metricsByInspection, fetchingMetrics } = useAnalyzeBatchesMetrics('items', filters, periodsAvailable, batches)
  const { metricsByInspection: labelMetricsByInspection, fetchingMetrics: isFetchingLabelMetrics } =
    useAnalyzeBatchesMetrics('labels', filters, periodsAvailable, batches)

  const showLoader = fetchingMetrics || isLoading || isFetchingLabelMetrics

  const inspectionsWithMetrics: InspectionWithMetrics[] | undefined = useMemo(() => {
    const typedPeriod = period === '30m' ? 'minute' : period || 'day'
    const numPeriods = period === '30m' ? 30 : 1

    if (!metricsByInspection || !labelMetricsByInspection || !allToolLabels || !batches) return

    const batchIds = batches?.map(batch => batch.id)

    const inspections = Object.entries(metricsByInspection)
      .map(([inspectionId, results]) => {
        const truncatedResults = results
          .filter(results => results.labels.inspection_id && batchIds?.includes(results.labels.inspection_id))
          .map(result => ({
            ...result,
            entries: truncateSeriesTimestampsRtsDatePeriod(result.entries, typedPeriod),
          }))
        const combinedSeries = combineOutcomeCounts(truncatedResults)
        const filledSeries = seriesFillGapsRtsDatePeriod(combinedSeries, typedPeriod, {
          end: end || undefined,
          start: start || undefined,
          numPeriods,
        })

        const yieldSeries = filledSeries.map(data => ({
          ...data,
          passYield: calculatePercentage(data.pass, data.count),
          failYield: calculatePercentage(data.fail, data.count),
          unknownYield: calculatePercentage(data.unknown, data.count),
        }))

        let count = 0
        let passed = 0

        for (const item of filledSeries) {
          count += item.count || 0
          passed += item.pass || 0
        }

        const curYield = calculatePercentage(passed, count)

        const inspection = batches?.find(inspection => inspection.id === inspectionId)

        const productLabelMetrics = labelMetricsByInspection?.[inspectionId]
        const groupedLabelMetricsByLabelId = groupBy(productLabelMetrics, result => result.labels.tool_label_id)

        const defects = getAnalyzeDefects({ groupedLabelMetricsByLabelId, allToolLabels, count })

        return {
          ...inspection,
          subTitle: inspection?.site?.name
            ? getDisplayStationLocation(inspection.site.name, inspection.subsites?.[0]?.name) +
              ' / ' +
              inspection.station_name
            : '--',
          itemsInspected: count,
          yield: curYield,
          series: yieldSeries,
          defects,
        } as InspectionWithMetrics
      })
      .sort((a, b) => ((a.created_at || '') < (b.created_at || '') ? 1 : -1))

    return inspections.filter(g => g.itemsInspected)
  }, [allToolLabels, batches, end, labelMetricsByInspection, metricsByInspection, period, start])

  const expandedInspectionWithMetrics = expanded
    ? inspectionsWithMetrics?.find(inspection => inspection.id === expanded)
    : undefined

  useEffect(() => {
    return () => {
      // Reset PDF data when component unmounts
      setPdfData(undefined)
    }
  }, [setPdfData])

  const columns = useMemo(
    () => getAnalyzeTableColumns({ title: 'Batch', period, isGalleryExpanded, initialSortByYield: true }),
    [period, isGalleryExpanded],
  )

  useBackTopWithClassName('ant-table-body')

  const handleClickRow = useCallback(
    (row: AnalyzeTableMetrics) => {
      onUpdateFilters({ expanded: row.id })
    },
    [onUpdateFilters],
  )

  const { columnsWithWidths, renderVirtualTable, tableContainerRef, tableHeight } = useVirtualizedTable(columns, {
    onClick: handleClickRow,
    handleEndReached,
  })

  const mostCommonDefects: AnalyzeDefect[] | undefined = useMemo(() => {
    if (!expandedInspectionWithMetrics) return
    const productLabelMetrics = labelMetricsByInspection?.[expandedInspectionWithMetrics.id]

    const groupedLabelMetricsByLabelId = groupBy(productLabelMetrics, result => result.labels.tool_label_id)

    return getAnalyzeDefects({
      groupedLabelMetricsByLabelId,
      allToolLabels,
      count: expandedInspectionWithMetrics.itemsInspected,
    })
  }, [allToolLabels, expandedInspectionWithMetrics, labelMetricsByInspection])

  // This effect sets the chart or table data to be used on the PDF
  useEffect(() => {
    if (!inspectionsWithMetrics) {
      setPdfData(undefined)
      return
    }

    if (expandedInspectionWithMetrics)
      setPdfData({
        type: 'charts',
        chartContent: {
          yield: {
            value: expandedInspectionWithMetrics.yield,
            data: expandedInspectionWithMetrics.series,
          },
          count: {
            value: expandedInspectionWithMetrics.itemsInspected,
            data: expandedInspectionWithMetrics.series,
          },
          productInspected: {
            value: expandedInspectionWithMetrics.component.name,
          },
          mostCommonDefects: {
            data: mostCommonDefects,
          },
        },
      })
    else {
      const cappedInspections = inspectionsWithMetrics.slice(0, PDF_TABLE_LIMIT)

      setPdfData({
        type: 'yieldAndCount',
        title: 'batch',
        rows: cappedInspections.map(insp => ({
          name: insp.name,
          yield: insp.yield,
          count: insp.itemsInspected,
          series: insp.series,
          defects: insp.defects,
        })),
      })
    }
  }, [expandedInspectionWithMetrics, inspectionsWithMetrics, mostCommonDefects, setPdfData])

  const parentContainerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)

  return (
    <section className={Styles.analyzeMain} ref={parentContainerRef}>
      <PrismContainer
        containerStyle={{ paddingTop: ANALYZE_VERTICAL_PADDING }}
        titleRef={titleRef}
        title={expandedInspection?.name || 'Batches'}
        className={Styles.analyzeMainBody}
        headerActionsClassName={expandedInspection ? Styles.headerExpandedLayout : Styles.headerTitle}
        headerTitleAction={
          expandedInspection && (
            <IconButton
              data-testid="analyze-batches-go-back"
              onClick={() => {
                onUpdateFilters({ expanded: undefined })
              }}
              icon={<PrismNavArrowIcon direction="left" />}
              type="secondary"
              size="small"
              isOnTop
              className={Styles.iconPosition}
            />
          )
        }
        actions={<AnalyzeFilters tab="batches" data-testid="analyze-batches-filters" />}
        data-testid="analyze-batches-container"
      >
        <div className={Styles.tableWrapper} ref={tableContainerRef} key={tableRerenderKey}>
          <BackTop scrollContainerClassName="ant-table-body" />
          {!expanded && tableHeight && (
            <Table
              data-testid="analyze-batches-table"
              dataSource={inspectionsWithMetrics}
              columns={columnsWithWidths}
              className={`${Styles.tableContainer} ${Styles.tableHeaderForcedHeight} ${
                isGalleryExpanded ? Styles.adjustCellPadding : ''
              }`}
              rowClassName={Styles.tableRow}
              locale={{ emptyText: !showLoader ? <AnalyzeEmptyState tab="batches" /> : <div></div> }}
              rowKey="id"
              pagination={false}
              loading={{
                spinning: !inspectionsWithMetrics || showLoader,
                wrapperClassName: Styles.tableSpinnerWrapper,
                indicator: <PrismLoader className={Styles.loaderPosition} />,
              }}
              scroll={{ y: tableHeight - TABLE_HEADER_HEIGHT }}
              // We must send undefined so that the table component falls back on its default empty state
              components={inspectionsWithMetrics?.length ? { body: renderVirtualTable } : undefined}
            />
          )}

          {expanded && (
            <>
              <div
                className={`${Styles.analyzeGridContainer} ${isGalleryExpanded ? Styles.galleryIsExpanded : ''}`}
                data-testid="analyze-batches-graphs-container"
              >
                <PrismGraphWrapper
                  graphName="Yield"
                  graphValue={
                    expandedInspectionWithMetrics ? `${expandedInspectionWithMetrics.yield.toFixed(1)}%` : '0.0%'
                  }
                  className={forceZeroPosition(expandedInspectionWithMetrics) ? Styles.forceHideTooltip : ''}
                >
                  <YieldGraph
                    yieldSeries={expandedInspectionWithMetrics?.series || dummyData({ start, end })}
                    chartWidth="98%"
                    chartHeight={112}
                    period={period}
                    start={start}
                    end={end}
                    mode="metrics"
                    syncId="batchesDrillDown"
                    allowEscapeViewBox={{ y: true }}
                  />
                </PrismGraphWrapper>

                <PrismGraphWrapper
                  graphName="Items Inspected"
                  graphValue={
                    expandedInspectionWithMetrics
                      ? renderLargeNumber(expandedInspectionWithMetrics.itemsInspected, 1000)
                      : '0'
                  }
                  className={
                    forceZeroPosition(expandedInspectionWithMetrics)
                      ? `${Styles.forceHideTooltip} ${Styles.forceZeroPosition}`
                      : ''
                  }
                >
                  <CountGraph
                    chartWidth="98%"
                    chartHeight={112}
                    graphSeries={expandedInspectionWithMetrics?.series || dummyData({ start, end })}
                    period={period}
                    mode="metrics"
                    start={start}
                    end={end}
                    syncId="batchesDrillDown"
                    allowEscapeViewBox={{ y: true }}
                  />
                </PrismGraphWrapper>

                <PrismGraphWrapper
                  graphName="Product Inspected"
                  graphValue={expandedInspection?.component.name || '--'}
                  className={Styles.productInspectedContainer}
                />

                <PrismGraphWrapper graphName="Most Common Defects" graphCaption="% of items with defect">
                  <div className={Styles.graphBodyContainer}>
                    {mostCommonDefects?.map(defect => (
                      <PrismGraphProgressBar
                        key={defect.toolLabel.id}
                        type="fail"
                        graphName={
                          <PrismResultButton
                            severity={getDisplaySeverity(defect.toolLabel)}
                            value={getLabelName(defect.toolLabel)}
                            type="pureWhite"
                            className={Styles.defectItemTitle}
                          />
                        }
                        toolTipTitle={titleCase(getLabelName(defect.toolLabel))}
                        graphPercentage={defect.percentage.toFixed(1) || 0}
                        graphCount={defect.defectCount}
                      />
                    ))}

                    {(!mostCommonDefects || mostCommonDefects.length === 0) && (
                      <div className={Styles.graphContainerEmptyState}>No defects match your filters</div>
                    )}
                  </div>
                </PrismGraphWrapper>
              </div>
            </>
          )}
        </div>

        <div
          className={`${Styles.hiddenGraphRender} ${Styles.analyzeGridContainer}`}
          id="batches-pdf-graphs-container"
        ></div>
      </PrismContainer>
      <AnalyzeGallery
        parentContainerRef={parentContainerRef}
        titleRef={titleRef}
        isGalleryExpanded={isGalleryExpanded}
        setIsGalleryExpanded={setIsGalleryExpanded}
        tab={'batches'}
        filters={filters}
        transitionInProgress={galleryTransitionInProgress}
        setTransitionInProgress={setGalleryTransitionInProgress}
      />
    </section>
  )
}

const getObjectRefetchKey = (object: Record<string, any>) => {
  return Object.keys(object)
    .sort()
    .map(key => `${key}=${object[key as keyof typeof object]}`)
    .join()
}

const useAnalyzeBatchesMetrics = (
  type: 'items' | 'labels',
  paramFilters: Partial<Filters>,
  periodsAvailable: TimeSeriesDatePeriod[],
  inspections?: Inspection[],
) => {
  const dispatch = useDispatch()

  const metricsByInspection = useData(getterKeys.inspectionsMetricsByInspectionId(type))
  const [fetchingMetrics, setFetchingMetrics, fetchingMetricsRef] = useStateAndRef(false)

  const seriesType = type === 'items' ? 'item_outcome_inspection' : 'item_label_inspection'

  const { start, end, inspection_id, period, search, ...filters } = paramFilters

  const momentStart = start || moment(METRICS_START_DATE_MS)
  const momentEnd = end || moment()
  const durationMs = momentEnd.diff(momentStart)

  const metricsLabels = getAnalyzeMetricsLabels(filters)

  const { rtsParams } = getRtsParams({
    inspectionDuration: durationMs,
    labels: metricsLabels,
    seriesType,
    metricParams: { onlyCounts: true },
    rtsDatePeriod: {
      from_s: start?.unix(),
      to_s: end?.unix(),
    },
  })

  const agg_s = getAnalyzeAggS(periodsAvailable)

  const filtersKey = getObjectRefetchKey(metricsLabels)
  const inspectionIdsKey = inspections
    ?.map(inspection => inspection.id)
    .sort()
    .join()

  const refetchMetricsKey = `${filtersKey}${inspectionIdsKey}`

  useEffect(() => {
    if (!inspections || fetchingMetricsRef.current) return

    if (!inspections.length) {
      // Set inital empty data if there aren't inspections needed
      if (!metricsByInspection) {
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.inspectionsMetricsByInspectionId(type),
            updater: prev => {
              return {
                ...prev,
                data: {},
              }
            },
          }),
        )
      }

      return
    }

    const inspectionIds = inspections.map(inspection => inspection.id)

    const fetchInspectionsMetrics = async () => {
      if (fetchingMetricsRef.current) return
      setFetchingMetrics(true)
      const inspectionsChunks = []

      for (let idx = 0; idx < inspectionIds.length; idx += MAX_RTS_METRICS_LABELS_DICTS) {
        const chunk = inspectionIds.slice(idx, idx + MAX_RTS_METRICS_LABELS_DICTS)
        inspectionsChunks.push(chunk)
      }

      const allResponses = await Promise.allSettled(
        inspectionsChunks.map(chunk => {
          return service.readTimeSeries({
            series_type: seriesType,
            ...rtsParams,
            agg_s,
            labels: chunk?.map(inspectionId => ({ inspection_id: inspectionId, ...((rtsParams.labels as {}) || {}) })),
          })
        }),
      )

      const allResults = allResponses.flatMap(response => {
        if (response.status !== 'fulfilled' || response.value.type !== 'success') {
          return []
        }

        return response.value.data.results
      })

      const groupedResults = groupBy(allResults, result => result.labels.inspection_id)

      const newMetricsByInspection = inspectionIds.reduce((all, inspectionId) => {
        const results = groupedResults[inspectionId] || []
        return { ...all, [inspectionId]: results }
      }, {} as Record<string, TimeSeriesResult[]>)

      dispatch(
        Actions.getterUpdate({
          key: getterKeys.inspectionsMetricsByInspectionId(type),
          updater: prev => {
            return {
              ...prev,
              data: newMetricsByInspection,
            }
          },
        }),
      )

      setFetchingMetrics(false)
    }

    fetchInspectionsMetrics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchMetricsKey])

  return { metricsByInspection, fetchingMetrics }
}
