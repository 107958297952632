import React, { useMemo, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import Card from 'components/Card/Card'
import { Divider } from 'components/Divider/Divider'
import FullScreen, { FullScreenFooter, FullScreenHeader } from 'components/FullScreen/FullScreen'
import { GoldenImageWithAois } from 'components/GoldenImageWithAois/GoldenImageWithAois'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { OptionMenuProps } from 'components/OptionMenu/OptionMenu'
import { PrismCard, PrismCardBlankState } from 'components/PrismCard/PrismCard'
import { PrismAddIcon, PrismElementaryCube } from 'components/prismIcons'
import { error } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import paths from 'paths'
import Shared from 'styles/Shared.module.scss'
import { RecipeExpanded, RoutineWithAois, Tool } from 'types'
import { deleteTool, duplicateTool, getAoisAndToolsFromRoutine, renderToolName } from 'utils'

import ViewHeader from '../ViewHeader'
import Styles from './Configure.module.scss'
import ToolCreationModal from './ToolCreationModal'

export interface Props {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  recipeVersion?: string
  readOnly: boolean
  routineParentId: string
}

/**
 * Renders the configure tab of the routine setup flow.
 *
 * Every tool is separated into its own 2 components, one that handles settings
 * and one that handles boxes. Although there can be quite a bit of code
 * repetition here, the goal is to prevent special casing anything!
 *
 * @param routine - Currently selected routine.
 * @param recipe - The Recipe we are working on.
 * @param readOnly - Is the screen in read only mode.
 * @param routineParentId - The routine parent we're editing.
 * @param recipeVersion - Recipe Version currently selected. If a recipe version is passed,
 * it means that the RecipeDrawerVersion is open
 */
const Configure = ({ routine, recipe, recipeVersion, routineParentId, readOnly }: Props) => {
  const dispatch = useDispatch()

  const [hoveredTool, setHoveredTool] = useState<Tool>()
  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [showFullscreen, setShowFullscreen] = useState(false)

  const history = useHistory()

  const goldenImageElement = (
    <GoldenImageWithAois routine={routine} showAois hoveredTool={hoveredTool} readOnly={readOnly} />
  )

  const routineHasImage = !!routine.image
  const FullscreenToggle = ({ className = '' }: { className?: string }) => {
    return (
      <ToggleButton
        title="Full Screen"
        active={showFullscreen}
        hotkey="F"
        onClick={() => setShowFullscreen(!showFullscreen)}
        isOnTop
        className={className}
      />
    )
  }

  if (showFullscreen) {
    return (
      <FullScreen id="fullscreen-reference-image" onClose={() => setShowFullscreen(false)}>
        <FullScreenHeader onCloseClick={() => setShowFullscreen(false)} />
        {goldenImageElement}
        <FullScreenFooter>
          <FullscreenToggle />
        </FullScreenFooter>
      </FullScreen>
    )
  }

  const { tools } = getAoisAndToolsFromRoutine(routine)

  return (
    <div className={`${Styles.configureLayout} ${readOnly ? Styles.versionDrawerIsOpen : ''}`}>
      <ToolList
        openModal={() => setModalOpened(true)}
        routine={routine}
        recipe={recipe}
        setHoveredTool={setHoveredTool}
        hoveredToolId={hoveredTool?.id}
        readOnly={readOnly}
        routineHasImage={routineHasImage}
        recipeVersion={recipeVersion}
        routineParentId={routineParentId}
      />

      <Divider type="vertical" className={Styles.configureDivider} />

      <div className={`${Styles.toolsAoiContainer} ${Shared.verticalChildrenGap32}`}>
        {routineHasImage && (
          <>
            <div className={Styles.videoFeedContainer}>{goldenImageElement}</div>

            <FullscreenToggle className={Styles.toolsAoiControlFullScreen} />
          </>
        )}
        {!routineHasImage && (
          <div className={Styles.noImageBlankState}>
            <GenericBlankStateMessage
              header={<PrismElementaryCube />}
              title="No reference image"
              description={`Save a reference in the Image tab in order to ${tools.length > 0 ? 'edit' : 'add'} tools.`}
            />
          </div>
        )}
      </div>

      <ToolCreationModal
        closeModal={() => setModalOpened(false)}
        visible={modalOpened}
        routine={routine}
        recipe={recipe}
        onCreateTool={(toolId, routineParentId, recipeId) => {
          if (toolId && routineParentId && recipeId) {
            query(getterKeys.recipe(recipeId), () => service.getRecipe(recipeId), { dispatch })
            history.push(paths.settingsTool(recipeId, toolId, { routineParentId: routineParentId }))
          }
        }}
      />
    </div>
  )
}

export default Configure

interface ToolListProps {
  openModal: () => any
  routine: RoutineWithAois
  recipe: RecipeExpanded
  setHoveredTool: (tool: Tool | undefined) => any
  hoveredToolId?: string
  readOnly: boolean
  routineHasImage?: boolean
  recipeVersion?: string
  routineParentId: string
}

/**
 * Renders the default left side of the configure tab.
 *
 * This shows all tools the user has created in a list and allows the user to
 * add new tools to their routine.
 *
 * @param openModal - Callback to open the ToolCreationModal.
 * @param routine - The routine we are working on.
 * @param recipe - The recipe we are working on
 * @param setHoveredTool - Callback to highlight tool the user is hovering
 *     over.
 * @param readOnly - Is the screen in read only mode.
 * @param hoveredToolId - The current hovered tool id
 * @param routineHasImage - Indicates if the current routine has a reference image
 * @param routineParentId - The routine parent we're editing.
 * @param recipeVersion - Recipe Version currently selected. If a recipe version is passed,
 * it means that the RecipeDrawerVersion is open
 */
const ToolList = ({
  openModal,
  routine,
  recipe,
  setHoveredTool,
  readOnly,
  hoveredToolId,
  routineHasImage,
  recipeVersion,
  routineParentId,
}: ToolListProps) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [tools, aois] = useMemo(() => {
    const aoisAndTools = getAoisAndToolsFromRoutine(routine)
    return [
      aoisAndTools.tools.sort((toolA, toolB) => {
        if (renderToolName(toolA).toLowerCase() > renderToolName(toolB).toLowerCase()) return 1
        if (renderToolName(toolB).toLowerCase() > renderToolName(toolA).toLowerCase()) return -1
        return 0
      }),
      aoisAndTools.aois,
    ]
  }, [routine])

  const [menuItemsOpen, setMenuItemsOpen] = useState<string[]>([])

  // Wrap in useCallback
  const getToolOptionMenuProps = (tool: Tool): Omit<OptionMenuProps<'duplicate' | 'delete'>, 'children'> => {
    return {
      options: [
        {
          value: 'duplicate',
          title: 'Duplicate',
          disabled: tool.is_shared,
          tooltipProps: tool.is_shared
            ? { title: 'You can not duplicate a shared tool', placement: 'right' }
            : undefined,
        },
        { title: 'Delete', value: 'delete', 'data-test': 'configure-tool-option-delete' },
      ],
      onMenuItemClick: async option => {
        if (option === 'duplicate') {
          await duplicateTool({
            routineId: routine.id,
            routineParentId: routine.parent.id,
            toolId: tool.id,
            toolParentId: tool.parent_id,
            recipe,
            history,
            dispatch,
          })
        }
        if (option === 'delete') {
          handleDeleteTool(tool)
        }
      },
    }
  }

  const getAlignmentOptionMenuProps = (tool: Tool): Omit<OptionMenuProps<'delete' | 'duplicate'>, 'children'> => {
    return {
      options: [{ title: 'Delete', value: 'delete', 'data-test': 'configure-tool-option-delete' }],
      onMenuItemClick: () => {
        handleDeleteTool(tool)
      },
    }
  }

  const handleDeleteTool = (tool: Tool) => {
    modal.warning({
      id: 'configure-delete-tool-confirmation',
      'data-testid': 'configure-delete-tool-modal',
      content: 'Are you sure you want to delete this tool?',
      okText: 'Delete',
      onOk: close => {
        deleteTool({
          routineId: routine.id,
          toolId: tool.id,
          history,
          recipe,
          dispatch,
          close,
          onDelete: () => {
            query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), { dispatch })
          },
        })
        close()
      },
    })
  }

  const handleToolClick = (tool: Tool) => {
    if (routine?.image)
      history.push(paths.settingsTool(recipe.id, tool.id, { routineParentId: routine.parent.id, recipeVersion }))
    else error({ title: 'Take a reference photo to edit this tool' })
  }

  const noRefImageAndNoTools = !routineHasImage && tools.length === 0

  return (
    <Card
      className={Styles.toolListContainer}
      headerClassName={Styles.toolListCardHeader}
      bodyClassName={Styles.toolListCardBody}
      footerClassName={Styles.toolListCardFooter}
      actionButton={
        <ViewHeader
          recipe={recipe}
          routine={routine}
          routineParentId={routineParentId}
          className={Styles.viewHeader}
          readoOnly={readOnly}
          mode="configure"
        />
      }
      footer={
        <Button
          className={Styles.addToolButton}
          onClick={openModal}
          disabled={readOnly || !routineHasImage || noRefImageAndNoTools}
          data-testid="configure-add-tool-button"
        >
          Add Tool
        </Button>
      }
    >
      {noRefImageAndNoTools &&
        [0, 1, 2].map((_, i) => <PrismCardBlankState key={i} className={Styles.toolCardEmpty} />)}

      {routineHasImage && tools.length === 0 && (
        <GenericBlankStateMessage
          className={Styles.emptyToolList}
          header={<PrismAddIcon className={Styles.addIcon} />}
          title="Add a tool"
          description="Click below to add your first tool to this recipe."
        />
      )}

      {tools.map(tool => {
        const currentActive = hoveredToolId === tool.id
        const defaultMenuProps =
          tool.specification_name === 'alignment' ? getAlignmentOptionMenuProps(tool) : getToolOptionMenuProps(tool)

        const currentToolAoi = aois.find(aoi => aoi.tools.find(innerTool => innerTool.id === tool.id))
        return (
          <PrismCard
            className={Styles.toolItemContainer}
            type="ghost4"
            size="medium"
            data-test="configure-tool-card"
            data-testid={`configure-tool-card-${renderToolName(tool).split(' ').join('-').toLowerCase()}`}
            optionMenuProps={{
              ...defaultMenuProps,
              onOpen: () => {
                setHoveredTool(tool)
                /* TODO: We're forced to use this menuItems: Array approach as there's an issue with the OptionMenu that
                 * requires some refactoring. Right now, the Option menu sends many requests to close the menu, and the
                 * order in which onOpen and onClose run is variable.
                 */
                setMenuItemsOpen(prevState => {
                  if (prevState.find(id => id === tool.id)) return prevState
                  return [...prevState, tool.id]
                })
              },
              onClose: () => {
                setMenuItemsOpen(prevState => {
                  const stateCopy = [...prevState]
                  if (stateCopy.length === 1) setHoveredTool(undefined)

                  const idx = prevState.findIndex(id => id === tool.id)
                  if (idx !== -1) {
                    stateCopy.splice(idx, 1)
                  }

                  return stateCopy
                })
              },
              openWithClick: true,
              closeOnClick: true,
              bottomOffset: 92,
            }}
            optionsPosition="centerRight"
            onClick={() => handleToolClick(tool)}
            onMouseEnter={() => !menuItemsOpen.length && setHoveredTool(tool)}
            onMouseLeave={() => !menuItemsOpen.length && setHoveredTool(undefined)}
            key={tool.id}
            image={
              routineHasImage && currentToolAoi ? (
                <ImageCloseUp
                  loaderType="skeleton"
                  src={routine.image}
                  region={currentToolAoi}
                  maskingRectangleFill={currentActive ? '#2A2A2A' : '#1D1D1D'}
                />
              ) : (
                <PrismElementaryCube />
              )
            }
            disabled={!routineHasImage}
            imageContainerClassName={Styles.cardImageContainer}
          >
            <PrismOverflowTooltip content={renderToolName(tool)} className={Styles.toolItemName} />
          </PrismCard>
        )
      })}
    </Card>
  )
}
