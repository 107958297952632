import React from 'react'

import { Redirect, Route } from 'react-router-dom'

import { getterKeys } from 'api'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { useData, useTypedSelector } from 'hooks'
import paths from 'paths'
import { UserRole } from 'types'
import { matchRole } from 'utils'

export type Props = { component: (args: any) => JSX.Element; requiredRole?: UserRole } & { [p: string]: any }

/**
 * Renders `component` if `auth` branch of state tree is truthy, else redirects
 * user to login. If auth is set but token is invalid, this still renders
 * `component`; it's not this component's responsibility to validate auth token.
 *
 * @param component - Component to render if user is authenticated
 */
const PrivateRoute = ({ component: Component, requiredRole, ...rest }: Props) => {
  const auth = useTypedSelector(state => state.auth)
  const me = useData(getterKeys.me())
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth.auth) {
          return (
            <Redirect
              push
              to={{
                pathname: paths.login(),
                state: { from: props.location, showButton: auth.logoutAction ? true : false },
              }}
            />
          )
        }

        if (!me) return <PrismLoader fullScreen />

        if (requiredRole && !matchRole(me, requiredRole)) {
          return <Redirect to={{ pathname: paths.inspect({ mode: 'site' }) }} />
        }

        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
