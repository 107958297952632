import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumbs'
import { Button } from 'components/Button/Button'
import EntityButton from 'components/EntityButton/EntityButton'
import OptionMenu, { Option } from 'components/OptionMenu/OptionMenu'
import { PlcControl } from 'components/PlcControl/PlcControl'
import { PrismCameraViewIcon, PrismGearIcon } from 'components/prismIcons'
import { PrismLayout } from 'components/PrismLayout/PrismLayout'
import { dismiss, loading, warning } from 'components/PrismMessage/PrismMessage'
import { PrismTabNav } from 'components/PrismTab/PrismTab'
import QaTimer from 'components/QATimer/QaTimer'
import { ENABLE_COMMUNICATIONS, ENABLE_PLC_CONTROL } from 'env'
import {
  useConnectionStatus,
  useData,
  useGetCurrentInspectionId,
  useInspectionAndRecipeDefinition,
  useIsColocated,
  useQueryParams,
  useResults,
  useRobotAndInspectionStatus,
  useSortedRobotsWithStatus,
  useStation,
  useTypedSelector,
} from 'hooks'
import CommunicationsList from 'pages/RecipesList/CommunicationsList'
import RecipesList from 'pages/RecipesList/RecipesList'
import EditStationCamerasModal from 'pages/StationList/EditStationCamerasModal'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import { Routine, Station, StationDetailMode } from 'types'
import { getRobotInspectionId, matchRole, searchLocationHistory } from 'utils'

import BatchSelector from './BatchSelector'
import AdjustAlignmentNotifier from './Components/AdjustAlignmentNotifier'
import AddOrEditStationModal from './Components/EntityModals/AddOrEditStationModal'
import StationDetailSideBar from './Components/StationDetailSideBar'
import MemoizedStationDetailSidebar from './Components/StationDetailSideBar'
import Styles from './StationDetail.module.scss'
import { StationDetailOverview } from './StationDetailOverview/StationDetailOverview'
import StationDetailTimeline from './StationDetailTimeline/StationDetailTimeline'
import { StationDetailTools } from './StationDetailTools/StationDetailTools'

export const START_BATCH_MESSAGE_ID = 'starting-inspection'
const LOADING_BATCH_MESSAGE_ID = 'loading_batch_message'
const STOP_BATCH_MESSAGE_ID = 'stop-batch'

interface Props {
  station: Station | undefined
  historicInspectionId?: string
  selectedRobotIds?: string[]
  mode: StationDetailMode
  historicRoutinesByRobotId?: { [robotId: string]: Routine }
}

type StationMenuOption = 'settings' | 'editCameras'

const MANAGE_STATION_MODES: StationDetailMode[] = ['recipes', 'communications']

/**
 * Renders the station detail screen, which allows users to see the cameras'
 * live feeds and if any inspection is running, displays information about it.
 *
 * @param robotIds - List of ids of robots to show in the screen
 * @param mode - Station detail mode
 */
function StationDetail({ station, selectedRobotIds, mode, historicRoutinesByRobotId, historicInspectionId }: Props) {
  const me = useData(getterKeys.me())
  const dispatch = useDispatch()
  const history = useHistory()

  const [showBatchSelection, setShowBatchSelection] = useState(false)
  const [isLoadingCurrentItem, setIsLoadingCurrentItem] = useState(false)
  const [activeModalType, setActiveModalType] = useState<StationMenuOption>()

  const manualTriggerDisabledTimeoutRef = useRef<number>()

  const robots = station?.robots
  const robotIds = robots?.map(robot => robot.id) || []

  const connectionStatus = useConnectionStatus()

  const selectedRobotId = useTypedSelector(state => state.inspector.selectedRobotId)

  // We must fetch the current inspection ID for all robots in the current station
  const currentInspectionId = useGetCurrentInspectionId(selectedRobotIds)

  const selectedInspectionId = historicInspectionId || currentInspectionId

  const { isColocated } = useIsColocated()

  const isHistoricBatch = !!historicInspectionId
  const isOverview = mode === 'overview'

  const { recipeDefinition } = useInspectionAndRecipeDefinition(robotIds)
  // We want to be using this fetchedRecipe anywhere we might render images.
  // As the urls in the recipeDefinition are more than likely already expired
  const fetchedRecipe = useQuery(
    recipeDefinition ? getterKeys.recipe(recipeDefinition.id) : undefined,
    () => service.getRecipe(recipeDefinition!.id),
    { refetchKey: connectionStatus === 'online' },
  ).data?.data

  const inspection = useData(selectedInspectionId ? getterKeys.inspection(selectedInspectionId) : undefined)

  const historicRoutines = useResults(
    historicInspectionId ? getterKeys.inspectionRoutines(historicInspectionId) : undefined,
  )
  const liveRoutines = useMemo(
    () => recipeDefinition?.recipe_routines.map(recipeRoutine => recipeRoutine.routine),
    [recipeDefinition],
  )

  const historicRecipe = useQuery(
    historicInspectionId && inspection?.recipe_id ? getterKeys.recipe(inspection.recipe_id) : null,
    historicInspectionId && inspection?.recipe_id ? () => service.getRecipe(inspection.recipe_id!) : null,
  ).data?.data

  const recipe = historicRecipe || recipeDefinition

  const routines = historicRoutines || liveRoutines
  // Get inspection IDs, ensure we don't rerender if we don't have new IDs

  const { inspectionIdByRobotId } = useTypedSelector(state => {
    const inspectionIdByRobotId: { [robotId: string]: string | undefined } = {}
    if (!robots) return { inspectionIdByRobotId }

    for (const robot of robots) {
      const inspectionId = getRobotInspectionId(robot.id, state) || undefined
      inspectionIdByRobotId[robot.id] = inspectionId
    }

    return { inspectionIdByRobotId }
  }, isEqual)

  const manageModeActive = MANAGE_STATION_MODES.includes(mode)

  const robotsSortedByInspectionFirst = useSortedRobotsWithStatus(robots)

  // If robots changed, we may have an old selectedRobotId, so select the first robot in this station (preferring running inspection)
  useEffect(() => {
    dispatch(Actions.inspectorUpdate({ selectedRobotId: robotsSortedByInspectionFirst?.[0]?.id }))
  }, [dispatch, robotsSortedByInspectionFirst?.[0]?.id]) // eslint-disable-line

  const { inspectionLoading, inspectionStopping, robotIsRunning, robotIsReady } = useRobotAndInspectionStatus(robotIds)

  // If robot has current inspection, which we read from event stream,
  // but inspection has no started_at timestamp yet due to race condition,
  // refetch inspection until it has a started_at timestamp
  useEffect(() => {
    if (inspection && !inspection.started_at) {
      setTimeout(
        () => query(getterKeys.inspection(inspection.id), () => service.getInspection(inspection.id), { dispatch }),
        5000,
      )
    }
  }, [inspection, dispatch])

  const manualTrigger = routines?.[0]?.settings?.camera_trigger_mode === 'manual'
  const inspectionItemsByRobot = useData(
    inspection?.id && manualTrigger ? getterKeys.inspectionItemsByRobot(inspection.id) : undefined,
  )

  const setIsInspectingManualPhoto = useCallback(
    (isInspecting: boolean) => {
      dispatch(Actions.inspectorUpdate({ isInspectingManualPhoto: isInspecting }))
    },
    [dispatch],
  )

  // This useEffect solves the edge case where the user stops a manual inspection in the "inspecting" state and
  // then starts a new inspection. Without this code, the user could incorrectly land into inspecting state.
  // Also if inspection changes, reset local insights state to off
  useEffect(() => {
    if (selectedInspectionId) {
      dispatch(
        Actions.inspectorUpdate({
          isInspectingManualPhoto: false,
          isManualTriggerDisabled: false,
        }),
      )
    }
  }, [selectedInspectionId, dispatch])

  const manualTriggerDisabled = useTypedSelector(state => state.inspector.isManualTriggerDisabled)

  const setManualTriggerDisabled = useCallback(
    (manualTriggerDisabled: boolean) => {
      dispatch(Actions.inspectorUpdate({ isManualTriggerDisabled: manualTriggerDisabled }))
    },
    [dispatch],
  )

  const stopBatchButtonEnabled = useMemo(
    () => robotIsRunning || inspectionStopping,
    [inspectionStopping, robotIsRunning],
  )

  const newBatchButtonEnabled = useMemo(() => !stopBatchButtonEnabled, [stopBatchButtonEnabled])

  useEffect(() => {
    if (!inspection && isHistoricBatch)
      loading({
        title: 'Loading batch...',
        id: LOADING_BATCH_MESSAGE_ID,
        duration: 0,
      })
    if (inspection && isHistoricBatch) {
      setShowBatchSelection(false)
      dismiss(LOADING_BATCH_MESSAGE_ID)
    }
  }, [inspection, isHistoricBatch])

  // This handles case where PLC starts inspection, or user on a different screen starts inspection
  useEffect(() => {
    if (inspectionLoading) {
      loading({ title: 'Starting batch...', id: START_BATCH_MESSAGE_ID, duration: 0 })
    } else {
      dismiss(START_BATCH_MESSAGE_ID)
    }
  }, [inspectionLoading])

  // This handles case where PLC stops inspection, or user on a different screen stops inspection
  useEffect(() => {
    if (inspectionStopping) {
      loading({ title: 'Stopping batch...', id: STOP_BATCH_MESSAGE_ID, duration: 0 })
    } else {
      dismiss(STOP_BATCH_MESSAGE_ID)
    }
  }, [inspectionStopping])

  // This dismisses notifications that appear in this screen 1s after the user leaves Station Details, just to be sure
  useEffect(() => {
    return () => {
      setTimeout(() => {
        dismiss(STOP_BATCH_MESSAGE_ID)
        dismiss(START_BATCH_MESSAGE_ID)
      }, 1000)
    }
  }, [])

  useEffect(() => {
    // When we get the latestItem, transition to "inspecting" state

    if (!manualTrigger || !inspectionItemsByRobot || !manualTriggerDisabled) return

    if (manualTriggerDisabledTimeoutRef.current) clearTimeout(manualTriggerDisabledTimeoutRef.current)
    const picturesHaveLoaded = Object.values(inspectionItemsByRobot).every(item =>
      item.pictures.every(picture => picture.image),
    )
    // TODO: Handle images coming in 1 by 1 instead of waiting for all of them

    if (picturesHaveLoaded) {
      setManualTriggerDisabled(false)
      setIsInspectingManualPhoto(true)
      setIsLoadingCurrentItem(false)
    }
    // When changing cameras the button is reenabled immediately
  }, [inspectionItemsByRobot, manualTrigger]) // eslint-disable-line

  const cleanInspectionItemsByRobot = useCallback(() => {
    if (!inspection) return
    dispatch(Actions.getterSave({ key: getterKeys.inspectionItemsByRobot(inspection.id), data: { data: {} } }))
  }, [dispatch, inspection])

  const handleSelectInspection = useCallback(
    (historicInspectionId?: string) => {
      const currentMode = mode === 'plc' ? 'overview' : mode
      setShowBatchSelection(false)
      history.push(paths.stationDetail(currentMode, station?.id, { historicInspectionId }))
    },
    [history, mode, station?.id],
  )

  const handleClickManualTrigger = useCallback(async () => {
    if (!robotsSortedByInspectionFirst) return
    cleanInspectionItemsByRobot()
    setManualTriggerDisabled(true)
    setIsInspectingManualPhoto(true)
    setIsLoadingCurrentItem(true)

    const robotsRunningInspections = robotsSortedByInspectionFirst.filter(robot => !!inspectionIdByRobotId[robot.id])
    const promises = robotsRunningInspections.map(robot => {
      return service.atomSendCommand('vision-processing', 'trigger', robot.id, {
        command_args: {},
      })
    })
    const manualTriggerResponses = await Promise.all(promises)
    if (manualTriggerResponses.some(r => r.type !== 'success' || !r.data.success)) {
      warning({ title: 'An error occurred, please try again' })
      setManualTriggerDisabled(false)
    } else {
      if (manualTriggerDisabledTimeoutRef.current) clearTimeout(manualTriggerDisabledTimeoutRef.current)
      manualTriggerDisabledTimeoutRef.current = window.setTimeout(() => {
        setManualTriggerDisabled(false)
      }, 5000)
    }
  }, [
    cleanInspectionItemsByRobot,
    inspectionIdByRobotId,
    robotsSortedByInspectionFirst,
    setIsInspectingManualPhoto,
    setManualTriggerDisabled,
  ])

  const closeHandler = useCallback(() => {
    setShowBatchSelection(false)
  }, [])

  const getStationDetailPath = (mode: StationDetailMode, includeHistoricInspectionId: boolean = true) => {
    return paths.stationDetail(mode, station?.id, {
      historicInspectionId: includeHistoricInspectionId ? historicInspectionId : undefined,
    })
  }

  const getStationTabNavItems = (manageModeActive: boolean) => {
    if (manageModeActive) {
      const basePaths = [{ path: getStationDetailPath('recipes'), label: 'Recipes', 'data-testid': '' }]

      if (ENABLE_COMMUNICATIONS) {
        basePaths.push({ path: getStationDetailPath('communications'), label: 'Communication', 'data-testid': '' })
      }

      return basePaths
    }

    return [
      {
        path: getStationDetailPath('overview'),
        label: 'Overview',
        'data-testid': 'station-detail-overview-link',
      },
      {
        path: getStationDetailPath('timeline'),
        label: 'timeline',
        'data-testid': 'station-detail-timeline-link',
      },
      {
        path: getStationDetailPath('tools'),
        label: 'tools',
        'data-testid': 'station-detail-tools-link',
      },
      ...(ENABLE_PLC_CONTROL && !!station && isColocated && !isHistoricBatch
        ? [
            {
              path: getStationDetailPath('plc', false),
              label: 'PLC Control',
              'data-testid': 'station-detail-plc-link',
            },
          ]
        : []),
    ]
  }

  const breadcrumbs = useStationBreadcrumbs({ isColocated, station })
  const stationMenuOptions = useMemo(() => {
    const options: Option<StationMenuOption>[] = [
      { value: 'settings', title: 'Settings', badge: <PrismGearIcon /> },
      { value: 'editCameras', title: 'Edit Cameras', disabled: !robots?.length, badge: <PrismCameraViewIcon /> },
    ]
    return options
  }, [robots?.length])

  return (
    <div className={Styles.flexLayout}>
      <PrismLayout
        className={Styles.inspectLayoutWrapper}
        breadcrumbs={breadcrumbs}
        menuItems={
          <>
            <div className={Styles.headerButtonsContainer}>
              {matchRole(me, 'manager') && (
                <OptionMenu
                  options={stationMenuOptions}
                  openWithClick
                  buttonDisabled={connectionStatus !== 'online'}
                  onMenuItemClick={setActiveModalType}
                  menuContainerClassName={Styles.optionMenuList}
                  iconButtonType="tertiary"
                  iconButtonDataTestId="station-detail-option-menu"
                />
              )}

              {isHistoricBatch && (
                <Button
                  data-testid="station-detail-view-live-button"
                  size="small"
                  type="secondary"
                  onClick={() => history.push(paths.stationDetail(mode, station?.id))}
                >
                  View Live
                </Button>
              )}

              {!manageModeActive && (
                <Button
                  data-testid="station-detail-view-batches-button"
                  size="small"
                  type="secondary"
                  disabled={connectionStatus !== 'online'}
                  onClick={() => setShowBatchSelection(!showBatchSelection)}
                >
                  View Batches
                </Button>
              )}

              {showBatchSelection && station && (
                <BatchSelector
                  robotIsRunning={robotIsRunning}
                  setSelectedInspectionId={handleSelectInspection}
                  onClose={closeHandler}
                  station={station}
                  selectedInspectionId={inspection?.id}
                />
              )}
              {manageModeActive && matchRole(me, 'manager') && (
                <EntityButton
                  disabled={connectionStatus !== 'online'}
                  siteId={station?.site_id}
                  lineId={station?.belongs_to_id || undefined}
                  stationId={station?.id}
                  menuPosition="bottomLeft"
                />
              )}

              {matchRole(me, 'manager') && (
                <Button
                  data-testid=""
                  size="small"
                  type="secondary"
                  disabled={connectionStatus !== 'online' && !manageModeActive}
                  onClick={() => {
                    if (manageModeActive) {
                      history.push(getStationDetailPath('overview'))
                    } else {
                      history.push(getStationDetailPath('recipes'))
                    }
                  }}
                >
                  {manageModeActive ? 'Operate' : 'Manage'} Station
                </Button>
              )}
            </div>
            <QaTimer />
          </>
        }
        tabs={<PrismTabNav className={Styles.tabNavWrapper} items={getStationTabNavItems(manageModeActive)} />}
      >
        {mode === 'tools' && (
          <StationDetailTools
            selectedRobotId={selectedRobotId}
            station={station}
            isHistoricBatch={isHistoricBatch}
            inspection={inspection}
            routines={routines}
            inspectionId={selectedInspectionId}
            fetchedRecipe={fetchedRecipe}
          />
        )}
        {mode === 'timeline' && (
          <StationDetailTimeline
            station={station}
            isHistoricBatch={isHistoricBatch}
            inspection={inspection}
            routines={routines}
          />
        )}
        {mode === 'overview' && (
          <StationDetailOverview
            inspection={inspection}
            routines={routines}
            station={station}
            selectedRobotId={selectedRobotId}
            isHistoricBatch={isHistoricBatch}
            historicRoutinesByRobotId={historicRoutinesByRobotId}
            handleClickManualTrigger={handleClickManualTrigger}
            isLoadingCurrentItem={isLoadingCurrentItem}
            fetchedRecipe={fetchedRecipe}
          />
        )}

        {mode === 'plc' && <PlcControl stationId={station?.id} />}

        {station && (
          <>
            {mode === 'recipes' && <RecipesList station={station} />}
            {mode === 'communications' && <CommunicationsList station={station} />}
          </>
        )}
      </PrismLayout>

      {activeModalType === 'editCameras' && station && (
        <EditStationCamerasModal station={station} handleClose={() => setActiveModalType(undefined)} />
      )}

      {activeModalType === 'settings' && station && (
        <AddOrEditStationModal
          isEditMode
          entity={station}
          onClose={() => setActiveModalType(undefined)}
          preventNavigation
        />
      )}

      <StationDetailSideBar
        robots={robotsSortedByInspectionFirst || []}
        recipe={recipe}
        inspection={inspection}
        selectedRobotId={selectedRobotId}
        routines={routines}
        isOverview={isOverview}
        inspectionStopping={inspectionStopping}
        inspectionLoading={inspectionLoading}
        newBatchButtonEnabled={newBatchButtonEnabled}
        stopBatchButtonEnabled={stopBatchButtonEnabled}
        robotIsReady={robotIsReady}
        station={station}
        isHistoricBatch={isHistoricBatch}
        historicRoutinesByRobotId={historicRoutinesByRobotId}
        inspectionIdByRobotId={inspectionIdByRobotId}
        currentItemByRobotId={inspectionItemsByRobot}
        handleClickManualTrigger={handleClickManualTrigger}
        isLoadingCurrentItem={isLoadingCurrentItem}
        isManageMode={manageModeActive}
      />

      <AdjustAlignmentNotifier
        inspection={inspection}
        manualTrigger={manualTrigger}
        isHistoricBatch={isHistoricBatch}
      />
    </div>
  )
}

export const useTimeFrameInMs = () => {
  const [params] = useQueryParams()
  const { timeFrame } = params

  const timeFrameInMs = useMemo(() => {
    if (!timeFrame) return

    // return the timeframe in milliseconds
    if (timeFrame === '5m') return 1000 * 60 * 5

    if (timeFrame === '1h') return 1000 * 60 * 60

    if (timeFrame === '4h') return 1000 * 60 * 60 * 4

    // we return null to make this case different from the timeFrame param not being present
    if (timeFrame === 'batch') return null
  }, [timeFrame])

  return timeFrameInMs
}

export const useStationBreadcrumbs = ({
  station,
  isColocated,
}: {
  station?: Station
  isColocated: boolean
}): Breadcrumb[] => {
  const history = useHistory()
  const me = useData(getterKeys.me())
  const { station: colocatedStation } = useStation()
  const line = station?.belongs_to
  const locationHistory = useTypedSelector(state => state.locationHistory)

  const colocatedStationPath = paths.stationDetail('overview', colocatedStation?.id)

  return [
    {
      label: 'Inspect',
      onClick: () => {
        // If colocated, we will already be at path stations for users that are role below manager, if we navigate to it again we mess up local state
        if (isColocated && !matchRole(me, 'manager')) return history.push(colocatedStationPath)

        history.push(paths.inspect({ mode: 'site', params: { siteId: station?.site_id } }))
      },
      'data-testid': 'station-detail-inspect',
    },
    ...(line
      ? [
          {
            label: line.name,
            onClick: () => {
              if (isColocated && !matchRole(me, 'manager')) return history.push(colocatedStationPath)
              history.push(paths.inspect({ mode: 'site', params: { siteId: station.site_id } }), {
                lineIdToExpand: line.id,
              })
            },
          },
        ]
      : []),
    ...(station
      ? [
          {
            label: station.name,
            onClick: () => {
              const lastStationDetailPath = searchLocationHistory(locationHistory.history, historyEntry => {
                const { pathname } = historyEntry
                return pathname.includes('/station/') && pathname.includes(station.id)
              })

              if (lastStationDetailPath) {
                return history.push(lastStationDetailPath)
              }
              history.push(paths.stationDetail('overview', station.id))
            },
          },
        ]
      : []),
  ]
}

const MemoizedStationDetail = React.memo(StationDetail)

MemoizedStationDetailSidebar.displayName = 'StationDetail'

export default MemoizedStationDetail
