import React from 'react'

import { PrismHelpIcon } from 'components/prismIcons'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import SkeletonWrapper from 'components/SkeletonWrapper/SkeletonWrapper'
import { Token } from 'components/Token/Token'
import { TestImagesSource, Tool } from 'types'
import { calculatePercentage } from 'utils'

import Styles from './TrainingReport.module.scss'

type Props = {
  testImagesSource: TestImagesSource
  tool?: Tool
  trainingMetrics?: {
    succesful: number
    falseNegative: number
    falsePositive: number
    total: number
  }
}

/**
 * Renders the training report filters.
 *
 * @param testImagesSource - If we are on a training report opened from a live inspection, we don't want to show the metrics card
 * @param trainingMetrics - Training metrics of the current tool
 * @param tool - Current tool
 */
export const TrainingReportMetrics = ({ testImagesSource, trainingMetrics, tool }: Props) => {
  return (
    // We always render this component and hide it with css in order to be able to have an animation when hiding it
    <section className={`${Styles.metrics} ${testImagesSource === 'inspection' ? Styles.hideContainer : ''}`}>
      <MetricItem
        label="accuracy"
        loading={!trainingMetrics}
        value={`${calculatePercentage(trainingMetrics?.succesful || 0, trainingMetrics?.total).toFixed(1)}%`}
        hasTooltipCaption={`Percentage of images predicted with the correct ${
          tool?.specification_name === 'match-classifier' ? 'label and above the threshold' : 'severity'
        } `}
      />

      {tool?.specification_name === 'match-classifier' && (
        <MetricItem
          label="False match rate"
          loading={!tool}
          value={`${calculatePercentage(trainingMetrics?.falsePositive || 0, trainingMetrics?.total).toFixed(1)}%`}
          hasTooltipCaption="Percentage of images predicted with the incorrect label or below the threshold"
        />
      )}

      {(tool?.specification_name === 'graded-anomaly' ||
        tool?.specification_name === 'deep-svdd' ||
        tool?.specification_name === 'classifier') && (
        <>
          <MetricItem
            label="false defect rate"
            loading={!trainingMetrics}
            value={`${calculatePercentage(trainingMetrics?.falseNegative || 0, trainingMetrics?.total).toFixed(1)}%`}
            hasTooltipCaption={
              <div className={Styles.metricTooltipContent}>
                <PrismResultButton
                  type="noFill"
                  severity="good"
                  value="good"
                  size="small"
                  className={Styles.metricPrismResult}
                />
                <span>images incorrectly predicted </span>
                <PrismResultButton
                  type="noFill"
                  severity="minor"
                  value="minor"
                  size="small"
                  className={Styles.metricPrismResult}
                />
                <span>or</span>
                <PrismResultButton
                  type="noFill"
                  severity="critical"
                  value="critical"
                  size="small"
                  className={Styles.metricPrismResult}
                />
              </div>
            }
          />
          <MetricItem
            label="defect escape rate"
            loading={!trainingMetrics}
            value={`${calculatePercentage(trainingMetrics?.falsePositive || 0, trainingMetrics?.total).toFixed(1)}%`}
            hasTooltipCaption={
              <div className={Styles.metricTooltipContent}>
                <PrismResultButton
                  type="noFill"
                  severity="minor"
                  value="minor"
                  size="small"
                  className={Styles.metricPrismResult}
                />
                <span>and</span>
                <PrismResultButton
                  type="noFill"
                  severity="critical"
                  value="critical"
                  size="small"
                  className={Styles.metricPrismResult}
                />
                <span>images incorrectly predicted </span>

                <PrismResultButton
                  type="noFill"
                  severity="good"
                  value="good"
                  size="small"
                  className={Styles.metricPrismResult}
                />
              </div>
            }
          />
        </>
      )}

      <MetricItem label="images" loading={!trainingMetrics} value={trainingMetrics?.total + ''} />
    </section>
  )
}

/**
 * Renders a Box with a label and a value. This component also holds a loading state
 *
 * @param label - The label to show
 * @param value - The value to use
 * @param loading - Whether a skeleton should be shown instead of the value
 * @param hasTooltipCaption - Changes the label container by adding an icon to the right of the label, it also receives text to be added in the tooltip.
 *  */
const MetricItem = ({
  label,
  value,
  loading,
  hasTooltipCaption,
}: {
  label: string
  value: string
  loading?: boolean
  hasTooltipCaption?: React.ReactNode
}) => {
  return (
    <div className={Styles.metricItem}>
      <Token
        label={
          hasTooltipCaption ? (
            <div className={Styles.metricInfo}>
              <span>{label}</span>
              <PrismTooltip
                title={hasTooltipCaption}
                placement="right"
                overlayClassName={Styles.metricTooltipMessage}
                anchorClassName={Styles.metricIconContainer}
              >
                <PrismHelpIcon />
              </PrismTooltip>
            </div>
          ) : (
            label
          )
        }
        align="center"
        className={Styles.metricItemDescription}
        valueClassName={Styles.metricItemValue}
        labelClassName={Styles.metricItemLabel}
      >
        <SkeletonWrapper loading={!!loading} skeletonProps={{ className: Styles.skeletonLoader }}>
          <>{value}</>
        </SkeletonWrapper>
      </Token>
    </div>
  )
}
