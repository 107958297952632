import React from 'react'

import { Input, InputNumber, InputNumberProps, InputProps, InputRef } from 'antd'

import Styles from './PrismInput.module.scss'

const getErrorMessageFromErrors = (errors: any, name: string) => {
  return errors && errors[name] && errors[name].message
}

const statusClass = {
  error: Styles.errorStatus,
  warning: Styles.warningStatus,
  initial: '',
}

const sizeClasses = {
  height24: Styles.height24,
  extraSmall: Styles.extraSmall,
  small: Styles.small,
  large: Styles.large,
}

/**
 * Render a label for use within a PrismInput component
 *
 * @param name - so it can be associated with the `for` property for it's correct input
 * @param children - content of the label, ideally only a string
 * @param className - add a class name
 * @param marginless - remove default margins from Prism UI
 */
export const Label = ({
  children,
  name,
  className,
  marginless,
}: {
  children?: string
  name?: string
  className?: string
  marginless?: boolean
}) => {
  if (!children) return null
  return (
    <label htmlFor={name} className={`${Styles.label} ${className ?? ''}  ${marginless ? Styles.labelMarginless : ''}`}>
      {children}
    </label>
  )
}

/**
 * Renders custom error message if it exists
 *
 * @param children - the error message
 */
export const InputError = ({ children, className = '' }: { children?: React.ReactNode; className?: string }) => {
  if (!children) return null
  return <p className={`${Styles.inputError} ${className}`}>{children}</p>
}

/**
 * Renders custom field component that wraps label, input and error
 *
 * @param children - the error message
 * @param className - custom className
 */
export const Field = ({ children, className }: { children?: React.ReactNode; className?: string }) => {
  if (!children) return null
  return <div className={`${Styles.prismField} ${className ?? ''}`}>{children}</div>
}

export interface PrismInputProps extends Omit<InputProps, 'size' | 'status'> {
  wrapperClassName?: string
  name?: string
  label?: string
  errors?: any
  'data-testid'?: string
  size?: 'height24' | 'extraSmall' | 'small' | 'large'
  status?: 'warning' | 'error' | 'initial'
  statusMessage?: string
  labelClassName?: string
}

/**
 * Renders a custom input field with optional label and errors
 */
// forwardRef allow us to accept a prop named ref, this makes easier to pass props by spreading the given controller render props.
export const PrismInput = React.forwardRef<InputRef, PrismInputProps>((props, ref) => {
  const {
    className,
    wrapperClassName,
    name = '', // it should have the same as the controller name prop
    label,
    errors,
    'data-testid': dataTestId,
    type = 'text',
    size = 'large',
    status = 'initial',
    statusMessage,
    labelClassName,
    ...rest
  } = props
  const innerErrors = getErrorMessageFromErrors(errors, name)
  const hasErrors = !!innerErrors
  const sizeClass = sizeClasses[size]

  const hasAffix = props.suffix || props.prefix

  return (
    <InputFieldWrapper
      wrapperClassName={wrapperClassName}
      name={name}
      label={label}
      errors={errors}
      statusMessage={statusMessage}
      labelClassName={labelClassName}
    >
      <Input
        {...rest}
        className={`${hasAffix ? Styles.textInputWithAffix : Styles.textInput}  ${className ?? ''} ${sizeClass} ${
          hasErrors ? Styles.hasErrors : ''
        } ${statusClass[status]}`}
        name={name}
        type={type}
        data-testid={dataTestId}
        ref={ref}
        autoComplete="off"
      />
    </InputFieldWrapper>
  )
})

export interface PrismInputNumberProps extends Omit<InputNumberProps, 'size' | 'status'> {
  wrapperClassName?: string
  name?: string
  label?: string
  errors?: any
  'data-testid'?: string
  size?: 'extraSmall' | 'small' | 'large'
  onChange?: (value: any) => void
  status?: 'warning' | 'error' | 'initial'
  statusMessage?: string
  labelClassName?: string
}

export interface ExtendedInputNumberProps extends PrismInputNumberProps {
  blur?: () => void
}

// Without this, on React devtools forwardRef components are show as Anonymous.
PrismInput.displayName = 'PrismInput'

/**
 * Renders a custom input field with optional label and errors
 */
// forwardRef allow us to accept a prop named ref, this makes easier to pass props by spreading the given controller render props.
export const PrismInputNumber = React.forwardRef<HTMLInputElement, ExtendedInputNumberProps>((props, ref) => {
  const {
    className,
    wrapperClassName = '',
    name = '', // it should have the same as the controller name prop
    label,
    errors,
    'data-testid': dataTestId,
    type = 'text',
    size = 'large',
    status = 'initial',
    statusMessage,
    labelClassName,
    ...rest
  } = props

  const innerErrors = getErrorMessageFromErrors(errors, name)
  const hasErrors = !!innerErrors
  const sizeClass = sizeClasses[size]

  return (
    <InputFieldWrapper
      wrapperClassName={wrapperClassName}
      name={name}
      label={label}
      errors={errors}
      statusMessage={statusMessage}
      labelClassName={labelClassName}
    >
      <InputNumber
        {...rest}
        className={`${Styles.numberInput} ${className ?? ''} ${sizeClass} ${hasErrors ? Styles.hasErrors : ''} ${
          statusClass[status]
        } `}
        name={name}
        type={type}
        data-testid={dataTestId}
        ref={ref}
        autoComplete="off"
      />
    </InputFieldWrapper>
  )
})

// Without this, on React devtools forwardRef components are show as Anonymous.
PrismInputNumber.displayName = 'PrismInputNumber'

interface InputFieldWrapperProps {
  wrapperClassName?: string
  name?: string
  label?: string
  errors?: any
  statusMessage?: string
  children?: React.ReactNode
  labelClassName?: string
}

const InputFieldWrapper = ({
  wrapperClassName,
  name = '',
  label,
  children,
  errors,
  statusMessage,
  labelClassName,
}: InputFieldWrapperProps) => {
  return (
    <Field className={wrapperClassName}>
      <Label name={name} className={labelClassName}>
        {label}
      </Label>
      {children}

      <InputError>{getErrorMessageFromErrors(errors, name)}</InputError>
      {statusMessage && <div className={Styles.statusMessage}>{statusMessage}</div>}
    </Field>
  )
}
