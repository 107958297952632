/* eslint-disable no-inline-styles/no-inline-styles */
import React, { useCallback, useMemo, useState } from 'react'

import { uniq, uniqBy } from 'lodash'
import { useDispatch } from 'react-redux'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import { PrismInfoIcon, PrismWarningIcon } from 'components/prismIcons'
import { error } from 'components/PrismMessage/PrismMessage'
import RobotToolsetsFetcher from 'components/RobotToolsetsFetcher'
import RobotToolsetsListener from 'components/RobotToolsetsListener'
import { showCancelMessage } from 'pages/RoutineOverview/DeployModal/DeployModal'
import { Toolset, Toolsets } from 'types'
import { fetchRobotToolsets } from 'utils'

import DeployProgressBar from './DeployProgressBar'
import Styles from './StaticInspector.module.scss'

/**
 * Renders a modal with download progress for toolsets/recipes being downloaded
 * to robot. Subscribes to a download progress stream so user can see download %
 * in real time.
 *
 * @param robotIds - Ids of robots to which assets are being downloaded
 * @param downloadingOrQueuedToolsetsByRobot - All recipes on download queue
 */
function DownloadModal({
  robotIds,
  downloadingOrQueuedToolsetsByRobot,
}: {
  robotIds: string[]
  downloadingOrQueuedToolsetsByRobot: { [robotId: string]: Toolset[] }
}) {
  const dispatch = useDispatch()

  const [status, setStatus] = useState<'normal' | 'confirmCancel'>('normal')
  const [cancelingCount, setCancelingCount] = useState<number>()

  const downloadingOrQueuedToolsets = useMemo(() => {
    const downloadingOrQueuedToolsets = Object.values(downloadingOrQueuedToolsetsByRobot).flatMap(
      downloadingOrQueuedToolsets => downloadingOrQueuedToolsets,
    )

    return uniqBy(downloadingOrQueuedToolsets, toolset => toolset.recipe_status.name)
  }, [downloadingOrQueuedToolsetsByRobot])

  const queuedToolsets = useMemo(
    () => downloadingOrQueuedToolsets.filter(toolset => toolset.recipe_status.state === 'QUEUED'),
    [downloadingOrQueuedToolsets],
  )
  const queuedRecipeIds = uniq(queuedToolsets.map(toolset => toolset.recipe_status.name))

  const downloadingToolsets = useMemo(
    () => downloadingOrQueuedToolsets.filter(toolset => toolset.recipe_status.state === 'DOWNLOADING'),
    [downloadingOrQueuedToolsets],
  )
  const downloadingRecipeIds = uniq(downloadingToolsets.map(toolset => toolset.recipe_status.name))

  const recipesProgress = downloadingRecipeIds.map(recipeId => {
    let totalProgress = 0
    const recipeToolsets = downloadingToolsets.filter(toolset => toolset.recipe_status.name === recipeId)
    recipeToolsets.forEach(toolset => {
      const progress = Object.values(toolset.tool_sets)[0]?.tool_set_status.metadata.progress || 0
      totalProgress += progress
    })

    return {
      recipeId,
      recipeName: recipeToolsets[0]?.recipe.parent.name,
      recipeVersion: recipeToolsets[0]?.recipe.version,
      recipeProgress: (totalProgress / recipeToolsets.length) * 100,
    }
  })

  const cancelDownload = useCallback(async () => {
    setCancelingCount(downloadingOrQueuedToolsets.length)
    const cancelPromises = robotIds
      .filter(robotId => downloadingOrQueuedToolsetsByRobot[robotId]?.length)
      .flatMap(robotId => {
        return [
          service.atomSendCommand('asset-management', 'download_cancel', robotId, {
            command_args: {},
          }),
          // We must also remove all tool_sets from the robot that are either downloading or queued
          service.atomSendCommand('asset-management', 'remove_recipes', robotId, {
            command_args: {
              ids: downloadingOrQueuedToolsetsByRobot[robotId]!.map(toolset => toolset.recipe_status.name),
            },
          }),
        ]
      })
    const responses = await Promise.all(cancelPromises)

    if (responses.some(response => response?.type !== 'success' || response.data.success === false)) {
      error({ title: 'An error occurred canceling the recipe deploy' })
      setCancelingCount(undefined)
      return
    }
    setCancelingCount(undefined)
    showCancelMessage()

    robotIds.forEach(robotId => {
      query(
        getterKeys.robotToolsets(robotId),
        () =>
          service.atomSendCommandExtractData<Toolsets>('asset-management', 'status', robotId, {
            command_args: { all: true },
          }),
        { dispatch },
      )
    })
  }, [robotIds, downloadingOrQueuedToolsetsByRobot, downloadingOrQueuedToolsets, dispatch])

  const pluralizeQueuedRecipes = !!queuedRecipeIds.length
  const queuedRecipesText = `There ${pluralizeQueuedRecipes ? 'are' : 'is'} ${queuedRecipeIds.length} other recipe${
    pluralizeQueuedRecipes ? 's' : ''
  } queued to deploy afterward`

  const cancelCount = cancelingCount || downloadingOrQueuedToolsets.length
  const pluralizeCancelConfirm = !!cancelCount
  const cancelConfirmText = `There ${pluralizeCancelConfirm ? 'are' : 'is'} ${cancelCount} recipe${
    pluralizeCancelConfirm ? 's' : ''
  } deploying. If you cancel, you will lose your progress.`

  return (
    <>
      {/* Our own notification element */}
      {!!downloadingOrQueuedToolsets.length && (
        <div className={Styles.notificationContainer}>
          <span className={Styles.notificationIcon}>
            {status === 'normal' ? <PrismInfoIcon isActive /> : <PrismWarningIcon isActive />}
          </span>
          <div className={Styles.notificationTitle}>{status === 'normal' ? 'Deploy In Progress' : 'Are you sure?'}</div>
          <div className={Styles.notificationContentContainer}>
            {status === 'normal' && (
              <>
                {recipesProgress.map(progress => (
                  <DeployProgressBar
                    key={progress.recipeId}
                    recipeProgress={progress.recipeProgress}
                    recipeName={progress.recipeName}
                    recipeVersion={progress.recipeVersion}
                  />
                ))}

                {queuedRecipeIds.length > 0 && <div className={Styles.notificationContent}>{queuedRecipesText}</div>}

                <Button
                  className={Styles.notificationButtonsContainer}
                  onClick={() => setStatus('confirmCancel')}
                  type="secondary"
                  size="small"
                >
                  Cancel
                </Button>
              </>
            )}

            {status === 'confirmCancel' && (
              <>
                <div className={Styles.notificationContent}>{cancelConfirmText}</div>
                <div className={Styles.notificationButtonsContainer}>
                  <Button size="small" onClick={cancelDownload} type="danger">
                    Yes, Cancel
                  </Button>
                  <Button size="small" onClick={() => setStatus('normal')} disabled={!!cancelingCount} type="secondary">
                    No, Continue
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {robotIds.map(robotId => (
        <div key={robotId}>
          <RobotToolsetsFetcher robotId={robotId} intervalMs={5 * 1000} />
          {/* Use past_ms to make it very unlikely we miss any messages while toolsets fetcher is fetching data */}
          <RobotToolsetsListener
            robotId={robotId}
            onMessages={messages => {
              // If anything interesting just happened, let user know...
              for (const msg of messages) {
                if (msg.payload.status === 'downloading') {
                  fetchRobotToolsets(robotId, dispatch)
                }
              }
            }}
          />
        </div>
      ))}
    </>
  )
}

export default DownloadModal
