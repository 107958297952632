import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Elementary Logo
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1450%3A5075
 *
 */
export const PrismElementaryLogo = ({ className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${className ?? ''} `}
      viewBox="0 0 426 69"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M95.3246 55.7511C92.9352 55.7511 90.7379 55.3458 88.7326 54.5351C86.7272 53.6818 84.9992 52.5298 83.5486 51.0791C82.0979 49.5858 80.9672 47.8151 80.1566 45.7671C79.3459 43.7191 78.9406 41.5005 78.9406 39.1111C78.9406 36.7645 79.3246 34.5671 80.0926 32.5191C80.9032 30.4711 82.0126 28.7218 83.4206 27.2711C84.8712 25.7778 86.5992 24.6045 88.6046 23.7511C90.6526 22.8978 92.9139 22.4711 95.3885 22.4711C97.8206 22.4711 100.061 22.9191 102.109 23.8151C104.157 24.7111 105.885 25.9698 107.293 27.5911C108.743 29.2125 109.831 31.1538 110.557 33.4151C111.282 35.6338 111.559 38.1085 111.389 40.8391H85.9166C85.9592 42.0765 86.2152 43.2285 86.6846 44.2951C87.1966 45.3191 87.8579 46.2365 88.6686 47.0471C89.5219 47.8151 90.5246 48.4338 91.6765 48.9031C92.8712 49.3298 94.1726 49.5431 95.5806 49.5431C97.3726 49.5431 98.9726 49.1805 100.381 48.4551C101.831 47.7298 102.919 46.5991 103.645 45.0631H111.005C110.535 46.5565 109.831 47.9645 108.893 49.2871C107.997 50.5671 106.866 51.6978 105.501 52.6791C104.178 53.6178 102.663 54.3645 100.957 54.9191C99.2499 55.4738 97.3726 55.7511 95.3246 55.7511ZM95.1966 28.3591C92.9779 28.3591 91.0152 28.9991 89.3086 30.2791C87.6446 31.5165 86.5779 33.3725 86.1086 35.8471H103.837C103.282 33.2445 102.194 31.3458 100.573 30.1511C98.9512 28.9565 97.1592 28.3591 95.1966 28.3591Z"
        fill="#F8F8F9"
      />
      <path d="M118.714 7.11112H125.754V55.1111H118.714V7.11112Z" fill="#F8F8F9" />
      <path
        d="M149.454 55.7511C147.065 55.7511 144.867 55.3458 142.862 54.5351C140.857 53.6818 139.129 52.5298 137.678 51.0791C136.227 49.5858 135.097 47.8151 134.286 45.7671C133.475 43.7191 133.07 41.5005 133.07 39.1111C133.07 36.7645 133.454 34.5671 134.222 32.5191C135.033 30.4711 136.142 28.7218 137.55 27.2711C139.001 25.7778 140.729 24.6045 142.734 23.7511C144.782 22.8978 147.043 22.4711 149.518 22.4711C151.95 22.4711 154.19 22.9191 156.238 23.8151C158.286 24.7111 160.014 25.9698 161.422 27.5911C162.873 29.2125 163.961 31.1538 164.686 33.4151C165.411 35.6338 165.689 38.1085 165.518 40.8391H140.046C140.089 42.0765 140.345 43.2285 140.814 44.2951C141.326 45.3191 141.987 46.2365 142.798 47.0471C143.651 47.8151 144.654 48.4338 145.806 48.9031C147.001 49.3298 148.302 49.5431 149.71 49.5431C151.502 49.5431 153.102 49.1805 154.51 48.4551C155.961 47.7298 157.049 46.5991 157.774 45.0631H165.134C164.665 46.5565 163.961 47.9645 163.022 49.2871C162.126 50.5671 160.995 51.6978 159.63 52.6791C158.307 53.6178 156.793 54.3645 155.086 54.9191C153.379 55.4738 151.502 55.7511 149.454 55.7511ZM149.326 28.3591C147.107 28.3591 145.145 28.9991 143.438 30.2791C141.774 31.5165 140.707 33.3725 140.238 35.8471H157.966C157.411 33.2445 156.323 31.3458 154.702 30.1511C153.081 28.9565 151.289 28.3591 149.326 28.3591Z"
        fill="#F8F8F9"
      />
      <path
        d="M213.612 37.1271C213.612 34.6098 213.142 32.6045 212.204 31.1111C211.265 29.6178 209.836 28.8711 207.916 28.8711C205.697 28.8711 203.884 29.7458 202.476 31.4951C201.11 33.2018 200.385 35.6551 200.3 38.8551V55.1111H193.26V37.1271C193.26 34.6098 192.79 32.6045 191.852 31.1111C190.913 29.6178 189.484 28.8711 187.564 28.8711C185.26 28.8711 183.404 29.7885 181.996 31.6231C180.588 33.4151 179.884 35.9965 179.884 39.3671V55.1111H172.844V23.1111H179.884V27.4631C180.78 25.9698 181.996 24.7751 183.532 23.8791C185.11 22.9405 186.924 22.4711 188.972 22.4711C191.361 22.4711 193.388 23.0258 195.052 24.1351C196.758 25.2445 198.038 26.8018 198.892 28.8071C199.83 26.7591 201.238 25.2018 203.116 24.1351C204.993 23.0258 206.998 22.4711 209.132 22.4711C212.758 22.4711 215.574 23.7085 217.58 26.1831C219.628 28.6151 220.652 31.8791 220.652 35.9751V55.1111H213.612V37.1271Z"
        fill="#F8F8F9"
      />
      <path
        d="M243.896 55.7511C241.507 55.7511 239.309 55.3458 237.304 54.5351C235.299 53.6818 233.571 52.5298 232.12 51.0791C230.669 49.5858 229.539 47.8151 228.728 45.7671C227.917 43.7191 227.512 41.5005 227.512 39.1111C227.512 36.7645 227.896 34.5671 228.664 32.5191C229.475 30.4711 230.584 28.7218 231.992 27.2711C233.443 25.7778 235.171 24.6045 237.176 23.7511C239.224 22.8978 241.485 22.4711 243.96 22.4711C246.392 22.4711 248.632 22.9191 250.68 23.8151C252.728 24.7111 254.456 25.9698 255.864 27.5911C257.315 29.2125 258.403 31.1538 259.128 33.4151C259.853 35.6338 260.131 38.1085 259.96 40.8391H234.488C234.531 42.0765 234.787 43.2285 235.256 44.2951C235.768 45.3191 236.429 46.2365 237.24 47.0471C238.093 47.8151 239.096 48.4338 240.248 48.9031C241.443 49.3298 242.744 49.5431 244.152 49.5431C245.944 49.5431 247.544 49.1805 248.952 48.4551C250.403 47.7298 251.491 46.5991 252.216 45.0631H259.576C259.107 46.5565 258.403 47.9645 257.464 49.2871C256.568 50.5671 255.437 51.6978 254.072 52.6791C252.749 53.6178 251.235 54.3645 249.528 54.9191C247.821 55.4738 245.944 55.7511 243.896 55.7511ZM243.768 28.3591C241.549 28.3591 239.587 28.9991 237.88 30.2791C236.216 31.5165 235.149 33.3725 234.68 35.8471H252.408C251.853 33.2445 250.765 31.3458 249.144 30.1511C247.523 28.9565 245.731 28.3591 243.768 28.3591Z"
        fill="#F8F8F9"
      />
      <path
        d="M267.286 23.1111H274.326V27.4631C275.307 25.8418 276.651 24.6045 278.358 23.7511C280.064 22.8978 281.963 22.4711 284.054 22.4711C285.888 22.4711 287.552 22.7911 289.046 23.4311C290.582 24.0711 291.883 24.9671 292.95 26.1191C294.016 27.2711 294.827 28.6791 295.382 30.3431C295.979 31.9645 296.278 33.7991 296.278 35.8471V55.1111H289.238V36.9991C289.238 34.4391 288.64 32.4551 287.446 31.0471C286.251 29.5965 284.63 28.8711 282.582 28.8711C280.235 28.8711 278.272 29.7671 276.694 31.5591C275.115 33.3085 274.326 35.9111 274.326 39.3671V55.1111H267.286V23.1111Z"
        fill="#F8F8F9"
      />
      <path
        d="M318.895 55.4311C317.359 55.4311 315.929 55.2178 314.607 54.7911C313.284 54.3645 312.111 53.7245 311.087 52.8711C310.105 51.9751 309.337 50.8658 308.783 49.5431C308.228 48.1778 307.951 46.5991 307.951 44.8071V29.3831H301.295V23.1111H307.951V14.2151H314.991V23.1111H324.143V29.3831H314.991V43.6551C314.991 45.6605 315.417 47.0685 316.271 47.8791C317.167 48.6898 318.532 49.0951 320.367 49.0951C321.049 49.0951 321.711 49.0525 322.351 48.9671C323.033 48.8391 323.631 48.6685 324.143 48.4551V54.7271C323.417 54.9405 322.628 55.1111 321.775 55.2391C320.964 55.3671 320.004 55.4311 318.895 55.4311Z"
        fill="#F8F8F9"
      />
      <path
        d="M343.598 55.7511C341.422 55.7511 339.374 55.3245 337.454 54.4711C335.576 53.5751 333.955 52.3805 332.59 50.8871C331.224 49.3511 330.136 47.5805 329.326 45.5751C328.558 43.5698 328.174 41.4151 328.174 39.1111C328.174 36.8071 328.558 34.6525 329.326 32.6471C330.136 30.6418 331.224 28.8925 332.59 27.3991C333.955 25.8631 335.576 24.6685 337.454 23.8151C339.374 22.9191 341.422 22.4711 343.598 22.4711C345.902 22.4711 347.95 22.9831 349.742 24.0071C351.576 24.9885 352.942 26.1191 353.838 27.3991V23.1111H360.878V55.1111H353.838V50.8231C352.942 52.1031 351.576 53.2551 349.742 54.2791C347.95 55.2605 345.902 55.7511 343.598 55.7511ZM344.878 49.3511C346.286 49.3511 347.566 49.0951 348.718 48.5831C349.912 48.0285 350.915 47.3031 351.726 46.4071C352.579 45.4685 353.24 44.3805 353.71 43.1431C354.179 41.9058 354.414 40.5618 354.414 39.1111C354.414 37.6605 354.179 36.3165 353.71 35.0791C353.24 33.8418 352.579 32.7751 351.726 31.8791C350.915 30.9405 349.912 30.2151 348.718 29.7031C347.566 29.1485 346.286 28.8711 344.878 28.8711C343.47 28.8711 342.168 29.1485 340.974 29.7031C339.822 30.2151 338.819 30.9405 337.966 31.8791C337.155 32.7751 336.515 33.8418 336.046 35.0791C335.576 36.3165 335.342 37.6605 335.342 39.1111C335.342 40.5618 335.576 41.9058 336.046 43.1431C336.515 44.3805 337.155 45.4685 337.966 46.4071C338.819 47.3031 339.822 48.0285 340.974 48.5831C342.168 49.0951 343.47 49.3511 344.878 49.3511Z"
        fill="#F8F8F9"
      />
      <path
        d="M370.511 23.1111H377.551V28.8071C377.85 27.9111 378.276 27.1005 378.831 26.3751C379.428 25.6071 380.09 24.9671 380.815 24.4551C381.583 23.9005 382.415 23.4951 383.311 23.2391C384.207 22.9405 385.103 22.7911 385.999 22.7911C386.98 22.7911 387.898 22.8765 388.751 23.0471V30.2791C388.154 30.0658 387.578 29.9165 387.023 29.8311C386.468 29.7458 385.871 29.7031 385.231 29.7031C384.207 29.7031 383.226 29.9165 382.287 30.3431C381.391 30.7698 380.58 31.3885 379.855 32.1991C379.172 33.0098 378.618 34.0338 378.191 35.2711C377.764 36.5085 377.551 37.9165 377.551 39.4951V55.1111H370.511V23.1111Z"
        fill="#F8F8F9"
      />
      <path
        d="M397.415 68.4871L404.263 53.3831L390.504 23.1111H398.247L408.04 45.7671L417.896 23.1111H425.703L405.159 68.4871H397.415Z"
        fill="#F8F8F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2057 61.8838L52.7586 55.0368V45.9082L27.2057 52.7552V61.8838Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.78393 55.0026L27.2067 61.8838V52.7552L1.65497 45.9082L1.78393 55.0026Z"
        fill="#F8F8F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7595 45.9067L27.2067 39.0597L1.65497 45.9067L27.2067 52.7537L52.7595 45.9067Z"
        fill="#3D80ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2057 45.9108L52.7586 39.0638V13.9596L27.2057 20.8066V45.9108Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.78393 39.0296L27.2067 45.9108V20.8066L1.65497 13.9596L1.78393 39.0296Z"
        fill="#F8F8F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7595 13.9581L27.2067 7.11111L1.65497 13.9581L27.2067 20.8051L52.7595 13.9581Z"
        fill="#3B3A3A"
      />
    </svg>
  )
}
