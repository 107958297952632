import React from 'react'

import { ProgressBar } from 'components/ProgressBar/ProgressBar'

import Styles from './StaticInspector.module.scss'

interface Props {
  recipeName?: string
  recipeProgress?: number
  recipeVersion?: number
  className?: string
}

/**
 * Renders a custom Progress bar
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12-159&t=SnBOEy7XNpBT7rX9-0
 *
 *
 * @param recipeName – Recipe name
 * @param recipeProgress – Recipe progress value
 * @param recipeVersion – Recipe version
 * @param className – Optional class to be appended
 */

function DeployProgressBar({ recipeName, recipeProgress, recipeVersion, className = '' }: Props) {
  return (
    <div className={`${Styles.deployProgressBar} ${className}`}>
      <div className={Styles.deployProgressBarTitle}>
        v{recipeVersion} of {recipeName} is deploying to this station
      </div>

      <ProgressBar progress={recipeProgress ? Math.floor(recipeProgress) : 0} height="small" />
    </div>
  )
}

export default DeployProgressBar
