import React, { useEffect, useMemo, useReducer, useRef } from 'react'

import { Radio, Switch } from 'antd'
import { useHistory } from 'react-router-dom'

import { Button } from 'components/Button/Button'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import PrismCollapse from 'components/PrismCollapse/PrismCollapse'
import { warning } from 'components/PrismMessage/PrismMessage'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { PrismSlider } from 'components/PrismSlider/PrismSlider'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import ProtectedInputNumber from 'components/ProtectedInputNumber/ProtectedInputNumber'
import { Token } from 'components/Token/Token'
import ViewHeader from 'pages/RoutineOverview/ViewHeader'
import { Capabilities, RecipeExpanded, RoutineSettings, RoutineWithAois, SettingsRules } from 'types'
import { getAoisAndToolsFromRoutine, protectedOnChange, renderTriggerMode } from 'utils'

import Styles from './CameraSettings.module.scss'

export interface Props {
  robotCapabilities: Capabilities | null | undefined
  routine: RoutineWithAois
  routineParentId: string
  recipe: RecipeExpanded
  routineSettings?: RoutineSettings
  setRoutineSettings: (settings: Partial<RoutineSettings>) => void
  resetRoutineSettings: () => void
  rules?: SettingsRules | null
  controlsDisabled: boolean
  formIsDirty: boolean
  onApplySettings: () => Promise<boolean | void>
  applyButtonDisabled: boolean
  versionDrawerOpen?: boolean
}

/**
 * Renders the control panel of the static inspection setup screen. This lets
 * the user configure camera-specific settings.
 *
 * @param robot - The current robot
 * @param robots - Array of colocated robots
 * @param routine - The routine we're editing
 * @param routineParentId - The routine parent we're editing
 * @param setRobotId - Function to set the robot id to control
 * @param routineSettings - routine settings
 * @param setRoutineSettings: - function that sets the routine settings when
 *   changing values
 * @param rules - Rules for max, min values for the different controls
 * @param formIsDirty - Whether the current form is dirty
 * @param controlsDisabled - Disable the controls
 * @param onApplySettings - Function that runs when clicking on the apply
 *     settings button
 * @param onEnableAutofocus - Sets the parent state to enable autofocus
 * @param applyButtonDisabled - Whether to disable the apply settings button
 * @param mostRecentVideoFrameImageElement - An image element for most recent frame we got from the video feed
 * @param versionDrawerOpen - Whether the version drawer is open
 * @param disableZoom Disables the camera feed zoom

 */
function CameraSettings({
  robotCapabilities,
  routine,
  routineParentId,
  recipe,
  routineSettings,
  setRoutineSettings,
  resetRoutineSettings,
  rules,
  controlsDisabled,
  onApplySettings,
  applyButtonDisabled,
  formIsDirty,
  versionDrawerOpen,
}: Props) {
  const history = useHistory()
  const timeoutIdRef = useRef<Map<SettingRule, number | undefined>>(new Map())
  const [cannotShowErrors, setCannotShowErrors] = useReducer(
    (state: Set<SettingRule>, setting: SettingRule | 'reset') => {
      if (setting === 'reset') return new Set<SettingRule>()

      const newSet = new Set(state)
      newSet.add(setting)
      return newSet
    },
    new Set<SettingRule>(),
  )

  const routineHasTools = getAoisAndToolsFromRoutine(routine).tools.length > 0

  const rotationDegreesValue = useMemo(() => {
    if (routineSettings?.image_rotation_degrees !== undefined) {
      return routineSettings.image_rotation_degrees
    }
    if (routineSettings?.rotate_180) return 180

    return 0
  }, [routineSettings?.image_rotation_degrees, routineSettings?.rotate_180])

  type SettingRule = keyof SettingsRules
  // Don't use rules to automatically adjust values, but rather just show user that value is invalid / outside range

  useEffect(() => {
    setCannotShowErrors('reset')
  }, [routineSettings?.camera_trigger_mode])

  useEffect(() => {
    if (!rules || !routineSettings) return

    for (const rule of Object.keys(rules)) {
      const ruleName = rule as SettingRule
      const value = rules[ruleName]
      if (value) {
        window.clearTimeout(timeoutIdRef.current.get(ruleName)) // Clear previous timeout for the settings that changed

        if (cannotShowErrors.has(ruleName)) continue // Notification already shown once, don't spam users

        let showError = false
        let errorMessage: string | undefined

        // If the setting is outside the valid range
        if (value.max && routineSettings[ruleName] > value.max.value) {
          showError = true

          errorMessage = value.max.message
        } else if (value.min && routineSettings[ruleName] < value.min.value) {
          showError = true
          errorMessage = value.min.message
        }

        if (showError)
          timeoutIdRef.current.set(
            ruleName,
            window.setTimeout(() => {
              warning({ title: errorMessage || 'Out of range value' })
              setCannotShowErrors(ruleName)
            }, 500),
          )
      }
    }

    // Clear all timeouts
    const timeoutIds = timeoutIdRef.current
    return () => {
      for (const ruleName of timeoutIds.keys()) window.clearTimeout(timeoutIds.get(ruleName as SettingRule))
    }
  }, [rules, routineSettings, cannotShowErrors])

  let disableButton = controlsDisabled || applyButtonDisabled
  const outOfRangeSettings = new Set<SettingRule>()

  // This allows us to give input a red border if settings for this input are invalid, this is not debounced
  if (rules && routineSettings) {
    for (const key of Object.keys(rules)) {
      const ruleName = key as keyof SettingsRules
      const value = rules[ruleName]
      if (value && 'max' in value && value.max && routineSettings[ruleName] > value.max.value) {
        disableButton = true
        outOfRangeSettings.add(ruleName)
      }
      if (value && 'min' in value && value.min && routineSettings[ruleName] < value.min.value) {
        disableButton = true
        outOfRangeSettings.add(ruleName)
      }
    }
  }

  function getSettingStyle(rule: SettingRule) {
    return {
      flex: 1,
      ...(outOfRangeSettings.has(rule)
        ? {
            outline: '1px solid orangered',
            border: 'none',
          }
        : {}),
    }
  }

  return (
    <div className={Styles.settingsWrapper}>
      <LeavePagePrompt when={formIsDirty} onOk={() => resetRoutineSettings()} />
      <div className={Styles.cardContainer}>
        <div className={Styles.cardBodyContainer}>
          <ViewHeader
            recipe={recipe}
            routine={routine}
            routineParentId={routineParentId}
            className={Styles.cardHeader}
            readoOnly={versionDrawerOpen}
            mode="capture"
          />
          <div className={Styles.cardBody}>
            <div className={Styles.cardDivider}>
              <div className={Styles.cameraSettings}>
                <div>
                  <div className={Styles.inputLabel}>Exposure (ms)</div>
                  <div className={Styles.flexboxContainer}>
                    <ProtectedInputNumber
                      size="small"
                      style={{
                        ...getSettingStyle('exposure_ms'),
                      }}
                      precision={3}
                      value={routineSettings?.exposure_ms || 0}
                      onPressEnter={e => e.currentTarget.blur()}
                      onChange={exposure_ms => {
                        if (!exposure_ms) return
                        const v = typeof exposure_ms === 'number' ? exposure_ms : parseFloat(exposure_ms)
                        if (isNaN(v)) return
                        setRoutineSettings({
                          exposure_ms: v,
                        })
                      }}
                      disabled={controlsDisabled}
                      routine={routine}
                      recipe={recipe}
                    />
                  </div>
                </div>

                <PrismSlider
                  className={Styles.settingsSliderContainer}
                  label="Gain"
                  value={Math.abs(routineSettings?.gain || robotCapabilities?.cam_min_gain || 0)}
                  min={robotCapabilities?.cam_min_gain || 0}
                  max={robotCapabilities?.cam_max_gain || 0}
                  onChange={protectedOnChange(
                    (gain: number) => {
                      if (typeof gain !== 'number') gain = 0

                      setRoutineSettings({ gain })
                    },
                    { routine, history, recipe },
                  )}
                  step={1}
                  disabled={controlsDisabled}
                />

                <PrismCollapse label="ADVANCED SETTINGS" contentClassName={Styles.advancedSettingsContentWrapper}>
                  <PrismSlider
                    className={Styles.settingsSliderContainer}
                    label="Gamma"
                    value={routineSettings?.gamma || 0}
                    min={0}
                    max={4}
                    onChange={protectedOnChange(
                      (gamma: number) => {
                        if (typeof gamma !== 'number') gamma = 0

                        setRoutineSettings({ gamma })
                      },
                      { routine, history, recipe },
                    )}
                    step={0.01}
                    disabled={controlsDisabled}
                  />

                  <PrismTooltip
                    condition={routineHasTools}
                    title={'Delete your tools in order to rotate the camera feed'}
                  >
                    <PrismSlider
                      className={Styles.settingsSliderContainer}
                      label="Rotation"
                      userFacingValue={`${rotationDegreesValue}°`}
                      value={rotationDegreesValue}
                      min={0}
                      max={270}
                      marks={{ 0: '0', 90: '90', 180: '180', 270: '270' }}
                      step={null}
                      onChange={protectedOnChange(
                        (rotationDegrees: number) => {
                          if (typeof rotationDegrees !== 'number') rotationDegrees = 0

                          setRoutineSettings({ image_rotation_degrees: rotationDegrees })
                        },
                        { routine, history, recipe },
                      )}
                      disabled={controlsDisabled || routineHasTools}
                      hideMarksLabel
                    />
                  </PrismTooltip>

                  {robotCapabilities?.settings_supported?.image_flip_vertical && (
                    <div className={Styles.cameraOptions}>
                      <span>Flip Vertical</span>
                      <Switch
                        size="default"
                        checked={routineSettings?.image_flip_vertical || false}
                        onChange={protectedOnChange(
                          image_flip_vertical => {
                            if (!routineSettings || !routineSettings.camera_properties) return
                            setRoutineSettings({
                              image_flip_vertical,
                              sensor_aoi: {
                                ...routineSettings.sensor_aoi,
                                // Basler only flips the image, not the crop, so we have to manually flip the crop to
                                // keep it in the same place
                                y:
                                  routineSettings.camera_properties.cam_max_height_pixels -
                                  (routineSettings.sensor_aoi.y + routineSettings.sensor_aoi.height),
                              },
                            })
                          },
                          { routine, history, recipe },
                        )}
                        disabled={controlsDisabled}
                      />
                    </div>
                  )}

                  {robotCapabilities?.is_color && (
                    <div className={Styles.cameraOptions}>
                      <span>Monochrome</span>
                      <Switch
                        size="default"
                        checked={routineSettings?.grayscale || false}
                        onChange={protectedOnChange(
                          grayscale => {
                            setRoutineSettings({ grayscale })
                          },
                          { routine, history, recipe },
                        )}
                        disabled={controlsDisabled}
                      />
                    </div>
                  )}

                  {robotCapabilities?.lineselector_values_out.length && (
                    <Token label={'Exposure Output'} labelClassName={Styles.tokenLabel}>
                      <PrismSelect
                        disabled={controlsDisabled}
                        value={routineSettings?.exposure_output}
                        onChange={protectedOnChange(
                          exposure_output => {
                            setRoutineSettings({ exposure_output })
                          },
                          { routine, history, recipe },
                        )}
                        options={robotCapabilities?.lineselector_values_out
                          // Exposure output cannot be the same as trigger input
                          .filter(value => value !== routineSettings?.trigger_input)
                          .map(value => ({ value }))}
                        className={Styles.cameraSettingsSelect}
                      />
                    </Token>
                  )}

                  {robotCapabilities?.lineselector_values_out.length && (
                    <div className={Styles.cameraOptions}>
                      <span>Exposure Output Invert</span>
                      <Switch
                        size="default"
                        checked={routineSettings?.exposure_output_invert || false}
                        onChange={protectedOnChange(
                          exposure_output_invert => {
                            setRoutineSettings({ exposure_output_invert })
                          },
                          { routine, history, recipe },
                        )}
                        disabled={controlsDisabled}
                      />
                    </div>
                  )}
                </PrismCollapse>
              </div>
            </div>

            <div className={Styles.cardDivider}>
              <div className={Styles.cardTitle}>Trigger</div>
              <div className={Styles.modeOptionsContainer}>
                <Radio.Group
                  value={routineSettings?.camera_trigger_mode}
                  data-testid="camera-settings-radio-group"
                  data-test-attribute={`${routineSettings?.camera_trigger_mode}-trigger`}
                  className={`${Styles.triggerOptions} ${Styles.triggerOptionsWrapper}`}
                  onChange={protectedOnChange(
                    e => {
                      const triggerMode = e.target.value

                      const interval_ms = triggerMode === 'manual' ? 200 : routineSettings?.interval_ms || 200

                      setRoutineSettings({
                        camera_trigger_mode: triggerMode,
                        interval_ms,
                      })
                    },
                    { routine, history, recipe },
                  )}
                  disabled={controlsDisabled}
                >
                  <Radio
                    value="hardware"
                    className={Styles.radioContainer}
                    disabled={controlsDisabled}
                    data-testid="camera-settings-hardware-trigger"
                  >
                    <span className={Styles.settingsOptions}>{renderTriggerMode('hardware')}</span>
                  </Radio>

                  {routineSettings?.camera_trigger_mode === 'hardware' && (
                    <div className={`${Styles.cameraSettings} ${Styles.triggerSubmenuContainer}`}>
                      <Token label="Delay (ms)" labelClassName={Styles.tokenLabel}>
                        <ProtectedInputNumber
                          size="small"
                          style={{ ...getSettingStyle('trigger_delay_ms') }}
                          value={routineSettings?.trigger_delay_ms || 0}
                          onChange={trigger_delay_ms => {
                            if (typeof trigger_delay_ms !== 'number') return
                            setRoutineSettings({ trigger_delay_ms })
                          }}
                          onPressEnter={e => e.currentTarget.blur()}
                          disabled={controlsDisabled}
                          routine={routine}
                          recipe={recipe}
                        />
                      </Token>

                      <Token label="Min interval (ms)" labelClassName={Styles.tokenLabel}>
                        <ProtectedInputNumber
                          data-testid="interval-ms-input"
                          size="small"
                          style={{ ...getSettingStyle('interval_ms') }}
                          value={routineSettings?.interval_ms || 0}
                          onChange={interval_ms => {
                            if (!interval_ms || typeof interval_ms === 'string') return
                            setRoutineSettings({ interval_ms })
                          }}
                          onPressEnter={e => e.currentTarget.blur()}
                          disabled={controlsDisabled}
                          routine={routine}
                          recipe={recipe}
                        />
                      </Token>

                      <Token label="Edge" labelClassName={Styles.tokenLabel}>
                        <Radio.Group
                          className={Styles.triggerOptionsContainer}
                          onChange={protectedOnChange(
                            e => {
                              setRoutineSettings({ trigger_edge: e.target.value })
                            },
                            { routine, history, recipe },
                          )}
                          value={routineSettings.trigger_edge}
                          disabled={controlsDisabled}
                        >
                          <Radio value className={Styles.radioContainer}>
                            <span className={Styles.settingsOptions}>Rising</span>
                          </Radio>
                          <Radio value={false} className={Styles.radioContainer}>
                            <span className={Styles.settingsOptions}>Falling</span>
                          </Radio>
                        </Radio.Group>
                      </Token>

                      <Token label="Debounce (ms)" labelClassName={Styles.tokenLabel}>
                        <ProtectedInputNumber
                          size="small"
                          value={routineSettings?.trigger_debounce_ms || 0}
                          onChange={trigger_debounce_ms => {
                            if (!trigger_debounce_ms || typeof trigger_debounce_ms === 'string') return
                            setRoutineSettings({ trigger_debounce_ms })
                          }}
                          onPressEnter={e => e.currentTarget.blur()}
                          disabled={controlsDisabled}
                          routine={routine}
                          recipe={recipe}
                        />
                      </Token>

                      {robotCapabilities?.lineselector_values_in.length && (
                        <>
                          <Token label="Input" labelClassName={Styles.tokenLabel}>
                            <PrismSelect
                              disabled={controlsDisabled}
                              value={routineSettings?.trigger_input}
                              onChange={protectedOnChange(
                                trigger_input => {
                                  setRoutineSettings({ trigger_input })
                                },
                                { routine, history, recipe },
                              )}
                              options={robotCapabilities?.lineselector_values_in
                                // Exposure output cannot be the same as trigger input
                                .filter(value => value !== routineSettings?.exposure_output)
                                .map(value => ({ value }))}
                              className={Styles.cameraSettingsSelect}
                            />
                          </Token>
                        </>
                      )}
                    </div>
                  )}

                  <Radio
                    value="automatic"
                    className={Styles.radioContainer}
                    disabled={controlsDisabled}
                    data-testid="camera-settings-automatic-trigger"
                  >
                    <span className={Styles.settingsOptions}>{renderTriggerMode('automatic')}</span>
                  </Radio>

                  {routineSettings?.camera_trigger_mode === 'automatic' && (
                    <div
                      className={`${Styles.cameraProperties} ${Styles.triggerSubmenuContainer} ${Styles.continousContainer}`}
                    >
                      <span>Interval (ms)</span>
                      <ProtectedInputNumber
                        data-testid="interval-ms-input"
                        size="small"
                        style={{ ...getSettingStyle('interval_ms') }}
                        value={routineSettings?.interval_ms || 0}
                        disabled={controlsDisabled}
                        onChange={interval_ms => {
                          if (typeof interval_ms !== 'number') return
                          setRoutineSettings({ interval_ms })
                        }}
                        onPressEnter={e => e.currentTarget.blur()}
                        routine={routine}
                        recipe={recipe}
                      />
                    </div>
                  )}

                  <Radio
                    value="manual"
                    className={Styles.radioContainer}
                    disabled={controlsDisabled}
                    data-testid="camera-settings-manual-trigger"
                  >
                    <span className={Styles.settingsOptions}>{renderTriggerMode('manual')}</span>
                  </Radio>

                  {routineSettings?.camera_trigger_mode === 'manual' && (
                    <div
                      className={`${Styles.cameraProperties} ${Styles.triggerSubmenuContainer} ${Styles.continousContainer}`}
                    >
                      <span>Min Interval (ms)</span>
                      <ProtectedInputNumber
                        data-testid="interval-ms-input"
                        size="small"
                        style={{ ...getSettingStyle('interval_ms') }}
                        value={routineSettings?.interval_ms || 0}
                        disabled={controlsDisabled}
                        onChange={interval_ms => {
                          if (typeof interval_ms !== 'number') return
                          setRoutineSettings({ interval_ms })
                        }}
                        onPressEnter={e => e.currentTarget.blur()}
                        routine={routine}
                        recipe={recipe}
                      />
                    </div>
                  )}
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.applySettingsContainer}>
          <Button
            type="secondary"
            wide
            disabled={disableButton}
            data-testid="camera-settings-apply-settings"
            onClick={protectedOnChange(onApplySettings, { routine, history, recipe })}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CameraSettings
