import React, { useEffect, useMemo, useState } from 'react'

import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { query } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, service, useQuery } from 'api'
import ImgFallback from 'components/Img/ImgFallback'
import MultiVideoListener from 'components/MultiVideoListener/MultiVideoListener'
import ReduxVideo from 'components/MultiVideoListener/ReduxVideo'
import { PrismElementaryCube, PrismNavArrowIcon, PrismPassIcon } from 'components/prismIcons'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal, ModalProps } from 'components/PrismModal/PrismModal'
import { PrismSelect, PrismSelectPlaceholder } from 'components/PrismSelect/PrismSelect'
import RobotsStatusListener from 'components/RobotsStatusListener'
import { Token } from 'components/Token/Token'
import { useQueryEntityFromSites, useRobotDiscovery, useStationsWithStatus, useStatusByRobotId } from 'hooks'
import {
  getSiteLineAndStationIdsFromCascaderValue,
  SitesAndLinesCascader,
} from 'pages/StationDetail/Components/EntityModals/SitesAndLinesCascader'
import paths from 'paths'
import Shared from 'styles/Shared.module.scss'
import { Capabilities, RecipeExpanded, Station } from 'types'
import { cameraResolutionIsCompatibleWithRoutine, getRobotDisplayName, SiteAndLineCascaderLocation } from 'utils'

import Styles from './DuplicateOrMoveRecipeModal.module.scss'

type Props = {
  recipeParentId: string
  defaultName: string
  currentRecipeStationId: string
  recipeParentWorkingVersionId: string | null | undefined
  isDuplicating?: boolean
  recipe?: RecipeExpanded
  onSubmit?: (recipe: RecipeExpanded) => any
} & ModalProps

/**
 * Renders a wrapper over antd's Modal, which allows us to duplicate a routine with its parents, and set a new name to that routine parent
 * @param recipeParentId - Current recipe parent id
 * @param defaultName - Default name for duplicated recipe
 * @param currentRecipeStationId - Station id from the current recipe
 * @param recipeParentWorkingVersionId - Current recipe parent working version id
 * @param onSubmit - Function called when recipe is duplicated.
 * @param onClose - Handler to close the modal
 * @param rest - Any additional params that can be sent to the modal component of antd
 */
function DuplicateOrMoveRecipeModal({
  recipeParentId,
  defaultName,
  currentRecipeStationId,
  recipeParentWorkingVersionId,
  isDuplicating = false,
  recipe,
  onSubmit,
  onClose,
  ...rest
}: Props) {
  const history = useHistory()

  // Won't use react hook form since robotIdByRoutineId is hard to handle
  const [name, setName] = useState(`Duplicate of ${defaultName}`)
  const [newStationId, setNewStationId] = useState<string>()
  const [newSiteId, setNewSiteId] = useState<string>()
  const [robotIdByRoutineId, setRobotIdByRoutineId] = useState<{ [routineId: string]: string }>({})

  // Wait until the modal is fully opened before rendering images
  const [modalLoaded, setModalLoaded] = useState(false)

  const currentStation = useQuery(getterKeys.station(currentRecipeStationId), () =>
    service.getStation(currentRecipeStationId),
  ).data?.data

  // Use noRefetch to use existing data from cascader
  const stations = useQueryEntityFromSites({ entity: 'station', siteId: newSiteId, noRefetch: true })
  const { stationsWithStatus, robotIds } = useStationsWithStatus(stations || [])
  const robotsInNewStation = stationsWithStatus.find(station => station.id === newStationId)?.robots

  const statusByRobotId = useStatusByRobotId(robotsInNewStation?.map(robot => robot.id))

  const discoveries = useRobotDiscovery(robotIds)
  const capabilitiesByRobotId: { [robotId: string]: Capabilities | null | undefined } = {}
  Object.entries(discoveries || {}).forEach(([robotId, discovery]) => {
    capabilitiesByRobotId[robotId] = discovery?.basler?.capabilities
  })

  const ERROR_MSG = `There was an error ${isDuplicating ? 'duplicating' : 'moving'} the recipe, please try again`

  // reset view selects in station to duplicate/move into changes
  useEffect(() => {
    setRobotIdByRoutineId({})
  }, [newStationId])

  const fetchedRecipe = useQuery(
    !recipe && recipeParentWorkingVersionId ? getterKeys.recipe(recipeParentWorkingVersionId) : null,
    recipeParentWorkingVersionId ? () => service.getRecipe(recipeParentWorkingVersionId) : undefined,
  ).data?.data
  const recipeToUse = fetchedRecipe || recipe

  const handleSubmit = async () => {
    if (!newStationId) return
    const body: {
      name?: string
      copy_parents?: boolean
      copy_family?: boolean
      station_id: string
      robot_id_by_routine_id: {}
      recipe_id?: string
    } = { station_id: newStationId, robot_id_by_routine_id: robotIdByRoutineId }

    if (isDuplicating) {
      body.name = name
      body.copy_parents = true
      body.recipe_id = recipe?.id
    } else {
      body.copy_family = true
      body.name = defaultName
    }

    const res = await service.duplicateRecipeParent(recipeParentId, body)

    if (res.type !== 'success') {
      return error({
        title: ERROR_MSG,
      })
    }

    if (!isDuplicating) {
      // When moving a recipe, we need to archive the previous recipe parent
      const recipeRes = await service.updateRecipeParent(recipeParentId, { is_deleted: true })
      if (recipeRes.type !== 'success') {
        return error({
          title: ERROR_MSG,
        })
      }
    }

    success({
      title: `Recipe ${isDuplicating ? 'duplicated' : 'moved'}`,
      buttonText: 'VIEW',
      onButtonClick: () => {
        history.push(paths.settingsRecipe(res.data.parent_id, 'capture'))
      },
      'data-testid': `${isDuplicating ? 'duplicate' : 'move'}-success-notification`,
    })
    await onSubmit?.(res.data)
    onClose?.()
  }

  return (
    <Modal
      header={isDuplicating ? 'Duplicate Recipe' : 'Move Recipe'}
      {...rest}
      onOk={handleSubmit}
      onClose={onClose}
      okText={isDuplicating ? 'Duplicate' : 'Move'}
      size="largeSimpleForm"
      data-testid="duplicate-modal"
      modalBodyClassName={Shared.verticalChildrenGap24}
      headerClassName={Styles.recipeModalHeader}
      modalFooterClassName={Styles.recipeModalFooter}
      className={Styles.recipeDuplicateModal}
      disableSave={!newStationId}
      onModalLoad={() => setModalLoaded(true)}
    >
      {isDuplicating && (
        <PrismInput
          label="Recipe Name"
          className={Styles.input}
          value={name}
          onChange={e => setName(e.target.value)}
          data-testid="duplicate-modal-input"
        />
      )}

      <div className={Styles.duplicateOptionGrid}>
        <Token label="current station" labelClassName={Styles.selectLabel}>
          <SitesAndLinesCascader
            disabled
            showStations
            value={
              currentStation
                ? [
                    `${currentStation.site_id}_${currentStation.type_id}`,
                    currentStation.belongs_to_id || null,
                    currentStation.id,
                  ]
                : undefined
            }
            className={Styles.modalCascaderHeight}
            size="large"
          />
        </Token>

        <PrismNavArrowIcon className={Styles.arrowIcon} />

        <Token label="new station" labelClassName={Styles.selectLabel}>
          <SitesAndLinesCascader
            showStations
            stationFilterFn={!isDuplicating ? station => station.id !== currentStation?.id : undefined}
            onChange={(value: (string | number)[]) => {
              const { stationId, siteId } = getSiteLineAndStationIdsFromCascaderValue(
                value as SiteAndLineCascaderLocation,
              )
              setNewStationId(stationId)
              setNewSiteId(stationId ? siteId : undefined)
            }}
            className={Styles.modalCascaderHeight}
            data-testid="station-duplicate-move-cascader"
            size="large"
          />
        </Token>

        {recipeToUse?.recipe_routines.map(recipeRoutine => {
          const image = recipeRoutine.routine.image_thumbnail || recipeRoutine.routine.image
          return (
            <>
              <Token label="view" labelClassName={Styles.selectLabel}>
                <PrismSelect
                  size="large"
                  value={recipeRoutine.id}
                  disabled
                  className={Styles.duplicateSelect}
                  placeholder={<PrismSelectPlaceholder title="view" hideImageBorder />}
                  options={[
                    {
                      key: recipeRoutine.id,
                      value: recipeRoutine.id,
                      content: recipeRoutine.routine.parent.name,
                      image: {
                        hideImageBorder: !image,
                        content:
                          modalLoaded && image ? (
                            <ImgFallback loaderType="skeleton" src={image} alt="" />
                          ) : (
                            <PrismElementaryCube />
                          ),
                      },
                    },
                  ]}
                />
              </Token>

              <PrismNavArrowIcon className={Styles.arrowIcon} />

              <Token label="new camera" labelClassName={Styles.selectLabel}>
                <PrismSelect
                  size="large"
                  value={robotIdByRoutineId[recipeRoutine.routine.id]}
                  onChange={val =>
                    setRobotIdByRoutineId(robotIdByRoutineId => ({
                      ...robotIdByRoutineId,
                      [recipeRoutine.routine.id]: val,
                    }))
                  }
                  className={Styles.duplicateSelect}
                  placeholder={<PrismSelectPlaceholder title="camera" hideImageBorder />}
                  disabled={!newStationId}
                  clearable
                  showSearch
                  showArrow
                  stopPropagationOnDropdownClick
                  data-testid={`${recipeRoutine.robot_id}-select-duplicate-move-modal`}
                  filterOption={(input, option) =>
                    ((option?.title as string) || '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={robotsInNewStation?.map(robot => {
                    const robotName = getRobotDisplayName(robot) || ''
                    const compatibleWithRoutine = cameraResolutionIsCompatibleWithRoutine(
                      capabilitiesByRobotId?.[robot.id],
                      recipeRoutine.routine?.settings,
                    )
                    const isRobotAlreadySelected = Object.values(robotIdByRoutineId).includes(robot.id)

                    const disabled = isRobotAlreadySelected || !compatibleWithRoutine

                    return {
                      key: robot.id,
                      value: robot.id,
                      content: robotName,
                      title: robotName,
                      disabled: disabled,
                      dataTestId: `${recipeRoutine.robot_id}-duplicate-move-modal-${robot.id}`,
                      dataTestAttribute: disabled ? 'disabled' : 'active',
                      tooltip: {
                        tooltipCondition: disabled,
                        tooltipOverlayClassName: Styles.tooltipContainer,
                        tooltipTitle: isRobotAlreadySelected
                          ? 'This camera was already selected'
                          : 'This camera has a different resolution than the View',
                      },
                      image: {
                        cameraStatus: statusByRobotId?.[robot.id],
                        content: modalLoaded ? (
                          <ReduxVideo
                            element="transcoder-basler-image-thumbnail"
                            stream="compressed"
                            robotId={robot.id}
                          />
                        ) : (
                          <PrismElementaryCube />
                        ),
                      },
                    }
                  })}
                />
              </Token>
            </>
          )
        })}
      </div>

      {robotIds && <RobotsStatusListener robotIds={robotIds} />}
      {robotIds && (
        <MultiVideoListener element="transcoder-basler-image-thumbnail" stream="compressed" robotIds={robotIds} />
      )}
    </Modal>
  )
}

export default DuplicateOrMoveRecipeModal

export const SearchableStationsWithStatus = ({
  stationId,
  setStationId,
  modalLoaded,
  filterStations,
  mode,
  'data-testid': dataTestId,
  'data-test': dataTest,
  multiClassName = '',
  status,
  placeholderTitle,
  hidePlaceholderImage = false,
  hideSelectImageBorder = false,
}: {
  stationId: string | undefined
  setStationId: (stationId: string) => void
  modalLoaded: boolean
  filterStations?: (station: Station) => boolean
  mode?: 'multiple'
  'data-testid'?: string
  'data-test'?: string
  multiClassName?: string
  status?: 'warning' | 'error'
  placeholderTitle?: string
  hidePlaceholderImage?: boolean
  hideSelectImageBorder?: boolean
}) => {
  const dispatch = useDispatch()
  const [isLoadingStations, setIsLoadingStations] = useState<boolean>(false)

  const stations = useQuery(getterKeys.stations('select-options'), () => service.getStations()).data?.data.results
  const { stationsWithStatus } = useStationsWithStatus(stations || [])

  const refetchStations = useMemo(
    () =>
      debounce(async (stationSearch?: string) => {
        if (stationSearch !== undefined) setIsLoadingStations(true)
        await query(getterKeys.stations('select-options'), () => service.getStations({ search: stationSearch }), {
          dispatch,
        })
        if (stationSearch !== undefined) setIsLoadingStations(false)
      }, 500),
    [dispatch],
  )

  const filteredStations = filterStations ? stationsWithStatus.filter(filterStations) : stationsWithStatus

  return (
    <PrismSelect
      mode={mode}
      size="large"
      value={stationId}
      onChange={setStationId}
      className={Styles.duplicateSelect}
      placeholder={
        <PrismSelectPlaceholder
          title={placeholderTitle || 'station'}
          hideImage={hidePlaceholderImage}
          hideImageBorder={hideSelectImageBorder}
        />
      }
      showArrow
      showSearch
      clearable
      stopPropagationOnDropdownClick
      onSearch={searchValue => refetchStations(searchValue)}
      dropdownRender={isLoadingStations ? () => <PrismLoader /> : undefined}
      filterOption={false}
      data-testid={dataTestId}
      multiClassName={multiClassName}
      menuItemSelectedIcon={mode === 'multiple' && <PrismPassIcon className={Styles.checkIcon} />}
      status={status}
      options={filteredStations.map(station => {
        // First robot is guaranteed to be prioritized by status thanks to `useStationWithStatus`
        const robotId = station.robots[0]?.id
        return {
          key: station.id,
          value: station.id,
          dataTest: dataTest,
          content: station.name,
          status: station.status,
          image: {
            hideImageBorder: !robotId,
            content:
              modalLoaded && robotId ? (
                <ReduxVideo element="transcoder-basler-image-thumbnail" stream="compressed" robotId={robotId} />
              ) : (
                <PrismElementaryCube />
              ),
          },
        }
      })}
    />
  )
}
